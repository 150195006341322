export default new Map([
  ['ATH', 'city=-814876'],
  ['PIR', 'city=-814876'],
  ['LAV', 'city=-814876'],
  ['JTR', 'region=1513'],
  ['JMK', 'region=2813'],
  ['PAS', 'region=2815'],
  ['JNX', 'region=2814'],
  ['RAF', 'city=-814876'],
  ['MLO', 'region=2261'],
  ['AEG', 'region=2807'],
  ['AEG00', 'region=2807'],
  ['AAM', 'region=2807'],
  ['SVA', 'region=2807'],
  ['HER', 'city=-818172'],
  ['TIN', 'region=1910'],
  ['PRJ', 'city=-113213'],
  ['BEV', 'city=-122902'],
  ['PRO', 'city=-125857'],
  ['POS', 'city=-125592'],
  ['AMA', 'city=-110339'],
  ['IBZ', 'region=1408'],
  ['FOR', 'region=1406']
]);
