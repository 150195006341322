import * as types from '../mutation-types';
import { getPageLanguage, message, roundPrice, getConfigurationArray } from '@/logic/helpers/utils';
import { makeRepriceRequest } from '@/logic/services/ApiCRS';
import { createCouponValidation, createTripWithCoupon, updateTripWithCoupon, applyCouponToTrips } from '@/logic/models/booking/TripWithCoupon';
import { COUPON_STATE, isFailedCoupon, isSuccesfulCoupon } from '@/logic/BL/coupons';
import { REPRICING_ERRORS, hasRepricingError, getRepricingErrors } from '@/logic/BL/repricing';
import { logException } from '@/logic/services/events/errorLogging';
import { handlePromise } from '@/logic/helpers/handlePromise';
import { EXTRA_SERVICES, EXTRA_SERVICE_OPTIONS, isExtraServiceSelected, getDefaultServiceProvider, isExtraServiceActivated, tripsAllowExtraService } from '@/logic/BL/extraServices';
import GenericErrorCodes from '@/logic/services/GenericErrorCodes';
import TripsFilterer from '../../logic/filterers/TripsFilterer';
import { eventSelectedTravelInsurance, eventSelectedRefundProtect, eventSelectedSupportPlus, eventSelectedFlexi } from '../../logic/services/events/createBookingEvents';
import { getAffiliationCode } from '../../logic/BL/serviceFee';
import { ga4Event } from '../../logic/services/events/EventService';
import { createSelectedCompaniesString } from '@/logic/generators/phrases';
import { mergeTripWithTravelerDetails } from '@/logic/services/booking/mergeTripsWithTravelerDetails';
import { createPricingRequest } from '@/logic/services/pricing/createPricingRequest';
import { isPriceableTrip, isPricedTrip, isPriceErrorTrip, makePricingRequest } from '@/logic/services/pricing/makePricingRequest';
import { updateTripBeforePricingImmutable, updateTripPricingImmutable } from '@/logic/models/booking/updateTripPricingImmutable';
import { isPriceablePassenger } from '@/components/book/isPriceablePassenger';
import { isEmpty } from '@/logic/helpers/utils';
import { storageGet } from '@/logic/services/storage/Storage';
import { getMaximumPassengerRequirements, getMaximumVehiclesRequirements, getMaximumPetsRequirements } from '@/logic/models/trips/getMaximumTravelerRequirements';

let invoiceDetailsLocales = getConfigurationArray('addon_invoice_details', []);

const getDefaultExtraServiceState = (extraServiceEnum) => {
  return {
    available: isExtraServiceActivated(extraServiceEnum),
    applicable: false,
    price: 0,
    details: undefined,
    error: '',
    selected: EXTRA_SERVICE_OPTIONS.STANDARD,
  };
};
export const getActiveUserId = (user) => {
  if (!isEmpty(user)) return user.id;
  const guestUserId = storageGet('fh.guestUserId', true);
  return guestUserId;
};

export const bookingModule = {
  namespaced: true,
  state: {
    trips: [],
    tripRequestsCombinations: [],
    tripsWithCoupons: [],
    passengerDetailsRequirements: {
      birthday: false,
      birthplace: false,
      nationality: false,
      document: false,
      docExpiration: false,
    },
    vehicleDetailsRequirements: {
      vehicleBrand: false,
    },
    petDetailsRequirements: {
      petDocument: false,
    },
    couponCode: '',
    isCouponApplied: false,
    couponValidationStatus: COUPON_STATE.UNDETERMINED,
    couponValidationMessage: '',
    // refund protection fields
    refundProtect: {
      available: isExtraServiceActivated(EXTRA_SERVICES.REFUND_PROTECT),
      applicable: false,
      price: 0,
      details: undefined,
      error: '',
      selected: EXTRA_SERVICE_OPTIONS.STANDARD,
    },
    // travel insurance fields
    travelInsurance: {
      available: isExtraServiceActivated(EXTRA_SERVICES.TRAVEL_INSURANCE),
      applicable: false,
      price: 0,
      details: undefined,
      error: '',
      selected: EXTRA_SERVICE_OPTIONS.STANDARD,
    },
    // support plus fields
    supportPlus: {
      available: isExtraServiceActivated(EXTRA_SERVICES.SUPPORT_PLUS),
      applicable: false,
      price: 0,
      details: undefined,
      error: '',
      selected: EXTRA_SERVICE_OPTIONS.STANDARD,
    },
    // flexi fields
    flexi: {
      available: isExtraServiceActivated(EXTRA_SERVICES.FLEXI),
      applicable: false,
      price: 0,
      details: undefined,
      error: '',
      selected: EXTRA_SERVICE_OPTIONS.STANDARD,
    },
    ticketDeliveryAmount: 0,
    serviceFee: {
      id: undefined,
      serviceFeeCost: 0,
    },
    invoiceDetailsEnabled: invoiceDetailsLocales.includes(getPageLanguage()),
    selectedSpecialOffers: {
      earlyBooking: false,
    },
  },
  getters: {
    hasUnpricedTrips: (state) => state.trips.some((trip) => !isPricedTrip(trip)),
    tripPricingErrors: (state) => state.trips.filter(isPriceErrorTrip).reduce((acc, trip) => [...acc, ...trip.pricingErrors], []),
    hasTripPricingErrors: (state, getters) => getters.tripPricingErrors.length > 0,
    // travel insurance
    isTravelInsuranceActivated: (state) => state.travelInsurance.available && state.travelInsurance.applicable,
    travelInsuranceSelected: (state) => isExtraServiceSelected(state.travelInsurance.selected),
    travelInsuranceCost: (state, getters) => (getters.travelInsuranceSelected ? state.travelInsurance.price : 0),
    getTravelInsuranceDetails: (state, getters) => {
      if (!state.travelInsurance.details) return undefined;
      return { ...state.travelInsurance.details, isAccepted: getters.travelInsuranceSelected };
    },
    getTravelInsuranceProvider: (state, getters) => {
      if (!getters.getTravelInsuranceDetails) return undefined;
      return getters.getTravelInsuranceDetails.serviceProviderName || getDefaultServiceProvider(EXTRA_SERVICES.TRAVEL_INSURANCE);
    },
    hasSelectedFailedTravelInsurance: (state, getters) => state.travelInsurance.error.length > 0 && getters.travelInsuranceSelected,
    hasTravelInsuranceError: (state) => state.travelInsurance.error.length > 0,
    // refund protect
    isRefundProtectActivated: (state) => state.refundProtect.available && state.refundProtect.applicable,
    refundProtectSelected: (state) => isExtraServiceSelected(state.refundProtect.selected),
    refundProtectCost: (state, getters) => (getters.refundProtectSelected ? state.refundProtect.price : 0),
    getRefundProtectDetails: (state, getters) => {
      if (!state.refundProtect.details) return undefined;
      return { ...state.refundProtect.details, isAccepted: getters.refundProtectSelected };
    },
    getRefundProtectProvider: (state, getters) => {
      if (!getters.getRefundProtectDetails) return undefined;
      return getters.getRefundProtectDetails.serviceProviderName || getDefaultServiceProvider(EXTRA_SERVICES.REFUND_PROTECT);
    },
    hasSelectedFailedRefundProtect: (state, getters) => state.refundProtect.error.length > 0 && getters.refundProtectSelected,
    hasRefundProtectError: (state) => state.refundProtect.error.length > 0,
    // support plus
    isSupportPlusActivated: (state, getters) => state.supportPlus.available && state.supportPlus.applicable,
    supportPlusSelected: (state) => isExtraServiceSelected(state.supportPlus.selected),
    supportPlusCost: (state, getters) => (getters.supportPlusSelected ? state.supportPlus.price : 0),
    getSupportPlusDetails: (state, getters) => {
      if (!state.supportPlus.details) return undefined;
      return { ...state.supportPlus.details, isAccepted: getters.supportPlusSelected };
    },
    getSupportPlusProvider: (state, getters) => {
      if (!getters.getSupportPlusDetails) return undefined;
      return getters.getSupportPlusDetails.serviceProviderName || getDefaultServiceProvider(EXTRA_SERVICES.SUPPORT_PLUS);
    },
    hasSelectedFailedSupportPlus: (state, getters) => state.supportPlus.error.length > 0 && getters.supportPlusSelected,
    hasSupportPlusError: (state, getters) => state.supportPlus.error.length > 0,
    // flexi hopper
    isFlexiActivated: (state, getters) => state.flexi.available && state.flexi.applicable,
    flexiSelected: (state) => isExtraServiceSelected(state.flexi.selected),
    flexiCost: (state, getters) => (getters.flexiSelected ? state.flexi.price : 0),
    getFlexiDetails: (state, getters) => {
      if (!state.flexi.details) return undefined;
      return { ...state.flexi.details, productOfferings: state.flexi.details.productOfferings.filter((po) => po.eligible).map((trip) => ({ ...trip, isAccepted: getters.flexiSelected })) };
    },
    getFlexiProvider: (state, getters) => {
      if (!getters.getFlexiDetails) return undefined;
      return getters.getFlexiDetails.serviceProviderName || getDefaultServiceProvider(EXTRA_SERVICES.FLEXI);
    },
    hasSelectedFailedFlexi: (state, getters) => state.flexi.error.length > 0 && getters.flexiSelected,
    hasFlexiError: (state, getters) => state.flexi.error.length > 0,
    //
    overallPriceForTrips: (state) =>
      state.tripsWithCoupons.reduce((acc, trip) => {
        return acc + parseFloat(trip.TripPrice);
      }, 0),
    overallPriceForExtras: (state, getters) => {
      return roundPrice(getters.refundProtectCost + getters.travelInsuranceCost + getters.supportPlusCost + getters.flexiCost + state.serviceFee.serviceFeeCost + state.ticketDeliveryAmount, 2);
    },
    overallDiscountForTrips: (state) =>
      roundPrice(
        state.tripsWithCoupons.reduce((acc, item) => acc + parseFloat(item.Discount), 0),
        2
      ),
    overallDiscountedPriceForTrips: (state, getters) => roundPrice(getters.overallPriceForTrips - getters.overallDiscountForTrips, 2),
    expectedPriceBeforeDiscount: (state, getters) => roundPrice(getters.overallPriceForTrips + getters.overallPriceForExtras, 2),
    expectedFinalPrice: (state, getters) => roundPrice(getters.overallDiscountedPriceForTrips + getters.overallPriceForExtras, 2),
    couponsForCheckout: (state) => (isSuccesfulCoupon(state.couponValidationStatus) ? state.tripsWithCoupons : []),
    hasSelectedFailedCoupon: (state) => isFailedCoupon(state.couponValidationStatus),
    departurePorts: (state) => TripsFilterer.getDeparturePorts(state.trips),
    marketingCompanies: (state) => TripsFilterer.getMarketingCompanies(state.trips),
    marketingCompaniesNames: (state) => createSelectedCompaniesString(TripsFilterer.getMarketingCompanyNames(state.trips)),
  },
  mutations: {
    [types.SET_FINAL_TRIP_REQUESTS_COMBINATIONS](state, combinations) {
      state.tripRequestsCombinations = combinations;
    },
    [types.SET_FINAL_SELECTED_SPECIAL_OFFERS](state, selectedSpecialOffers) {
      state.selectedSpecialOffers = selectedSpecialOffers;
    },
    [types.SET_TRIPS_FOR_BOOKING](state, initialTrips) {
      state.trips = initialTrips;
      state.passengerDetailsRequirements = getMaximumPassengerRequirements(state.trips);
      state.vehicleDetailsRequirements = getMaximumVehiclesRequirements(state.trips);
      state.petDetailsRequirements = getMaximumPetsRequirements(state.trips);
      state.tripsWithCoupons = initialTrips.map((trip) => createTripWithCoupon(trip, state.couponCode));
      state.refundProtect.applicable = tripsAllowExtraService(initialTrips, state.passengerDetailsRequirements, EXTRA_SERVICES.REFUND_PROTECT);
      if (!state.refundProtect.applicable) state.refundProtect = getDefaultExtraServiceState(EXTRA_SERVICES.REFUND_PROTECT);
      state.travelInsurance.applicable = tripsAllowExtraService(initialTrips, state.passengerDetailsRequirements, EXTRA_SERVICES.TRAVEL_INSURANCE);
      if (!state.travelInsurance.applicable) state.travelInsurance = getDefaultExtraServiceState(EXTRA_SERVICES.TRAVEL_INSURANCE);
      state.supportPlus.applicable = tripsAllowExtraService(initialTrips, state.passengerDetailsRequirements, EXTRA_SERVICES.SUPPORT_PLUS);
      if (!state.supportPlus.applicable) state.supportPlus = getDefaultExtraServiceState(EXTRA_SERVICES.SUPPORT_PLUS);
      state.flexi.applicable = tripsAllowExtraService(initialTrips, state.passengerDetailsRequirements, EXTRA_SERVICES.FLEXI);
      if (!state.flexi.applicable) state.flexi = getDefaultExtraServiceState(EXTRA_SERVICES.FLEXI);
    },
    async [types.PREPARE_TRIPS_PRICING](state, { passengersDetails, vehiclesDetails, petsDetails, selectedSpecialOffers }) {
      state.trips = state.trips.map((trip) => {
        let pricingRequest = createPricingRequest(
          mergeTripWithTravelerDetails(trip, {
            passengersDetails,
            vehiclesDetails,
            petsDetails,
          }),
          selectedSpecialOffers
        );
        return updateTripBeforePricingImmutable(trip, pricingRequest);
      });
    },
    [types.SET_TRIPS_WITH_COUPONS](state, couponCode) {
      state.tripsWithCoupons = applyCouponToTrips(state.tripsWithCoupons, couponCode);
    },
    [types.SET_COUPON_APPLIED](state, payload) {
      state.isCouponApplied = payload;
    },
    [types.SET_COUPON_CODE](state, payload) {
      state.couponCode = payload;
    },
    [types.SET_COUPON_VALIDATION](state, payload) {
      const { status, msg } = payload;
      state.couponValidationStatus = status;
      state.couponValidationMessage = msg;
    },
    // RP
    [types.SET_REFUND_PROTECT_ERROR](state, errorMessage) {
      state.refundProtect.details = undefined;
      state.refundProtect.error = errorMessage;
      state.refundProtect.price = 0;
    },
    [types.SET_REFUND_PROTECT_SELECTED](state, payload) {
      state.refundProtect.selected = payload;
    },
    // TI
    [types.SET_TRAVEL_INSURANCE_ERROR](state, errorMessage) {
      state.travelInsurance.details = undefined;
      state.travelInsurance.error = errorMessage;
      state.travelInsurance.price = 0;
    },
    [types.SET_TRAVEL_INSURANCE_SELECTED](state, payload) {
      state.travelInsurance.selected = payload;
    },
    // SP
    [types.SET_SUPPORT_PLUS_ERROR](state, errorMessage) {
      state.supportPlus.details = undefined;
      state.supportPlus.error = errorMessage;
      state.supportPlus.price = 0;
    },
    [types.SET_SUPPORT_PLUS_SELECTED](state, payload) {
      state.supportPlus.selected = payload;
    },
    // FH
    [types.SET_FLEXI_ERROR](state, errorMessage) {
      state.flexi.details = undefined;
      state.flexi.error = errorMessage;
      state.flexi.price = 0;
    },
    [types.SET_FLEXI_SELECTED](state, payload) {
      state.flexi.selected = payload;
    },
    //
    [types.SET_REPRICING_DATA](state, payload) {
      const { couponPayload, refundProtection, travelInsurance, supportPlus, flexihopper, serviceFee } = payload || {};
      const { isSuccessful: isRefundProtectionSuccessful } = refundProtection || { isSuccessful: false };
      const { isSuccessful: isTravelInsuranceSuccessful } = travelInsurance || { isSuccessful: false };
      const { isSuccessful: isSupportPlusSuccessful } = supportPlus || { isSuccessful: false };
      const { isSuccessful: isFlexiSuccessful } = flexihopper || { isSuccessful: false };

      state.tripsWithCoupons = state.tripsWithCoupons.map((trip) => {
        const tripFromResponse = couponPayload.trips.find((item) => item.id === trip.id);
        if (!tripFromResponse) throw Error('Trip not found in response');
        return updateTripWithCoupon(trip, tripFromResponse);
      });

      state.serviceFee = serviceFee;

      if (state.serviceFee.serviceFeeCost > 0) {
        ga4Event('entered_service_fee_scenario_charge', {
          field_name: state.serviceFee.id,
          field_value: state.serviceFee.serviceFeeCost,
          trip_basket_value: state.tripsWithCoupons.reduce((acc, trip) => acc + parseFloat(trip.TripPrice), 0),
        });
      }

      if (state.serviceFee.cg) {
        ga4Event('entered_service_fee_scenario_control', {
          field_name: state.serviceFee.id,
          field_value: state.serviceFee.cgValue,
          trip_basket_value: state.tripsWithCoupons.reduce((acc, trip) => acc + parseFloat(trip.TripPrice), 0),
        });
      }

      if (state.isCouponApplied) {
        state.couponValidationStatus = COUPON_STATE.SUCCESS;
        state.couponValidationMessage = message('COUPON.IS_VALID');
      }

      if (isRefundProtectionSuccessful) {
        state.refundProtect.error = '';
        state.refundProtect.details = refundProtection;
        state.refundProtect.price = roundPrice(refundProtection.productOffering.offerPrice, 2);
      }

      if (isTravelInsuranceSuccessful) {
        state.travelInsurance.error = '';
        state.travelInsurance.details = travelInsurance;
        state.travelInsurance.price = roundPrice(travelInsurance.productOffering.offerPrice, 2);
      }

      if (isSupportPlusSuccessful) {
        state.supportPlus.error = '';
        state.supportPlus.details = supportPlus;
        state.supportPlus.price = roundPrice(supportPlus.productOffering.offerPrice, 2);
      }

      if (isFlexiSuccessful) {
        state.flexi.error = '';
        state.flexi.details = flexihopper;
        const eligibleOfferPrices = state.flexi.details.productOfferings.filter((po) => po.eligible).map((po) => po.offerPrice);
        const offerPriceSum = eligibleOfferPrices.reduce((sum, num) => sum + num, 0);
        state.flexi.price = roundPrice(offerPriceSum, 2);
      }
    },
  },
  actions: {
    setFinalBookingProperties({ commit }, payload) {
      const { tripRequestsCombinations, selectedSpecialOffers } = payload;
      commit(types.SET_FINAL_TRIP_REQUESTS_COMBINATIONS, tripRequestsCombinations);
      commit(types.SET_FINAL_SELECTED_SPECIAL_OFFERS, selectedSpecialOffers);
    },
    setTripsForBooking({ commit, dispatch }, payload) {
      commit(types.SET_TRIPS_FOR_BOOKING, payload);
      dispatch('getRepricingData');
    },
    async updateTripPrices({ commit, state, dispatch }, payload) {
      if (payload.passengersDetails.some((p) => !isPriceablePassenger(p))) {
        return;
      }

      commit(types.PREPARE_TRIPS_PRICING, payload);

      if (state.trips.some((trip) => !isPriceableTrip(trip))) {
        return;
      }

      await dispatch('makeCombinedPricing');

      if (state.trips.every((trip) => isPricedTrip(trip))) {
        dispatch('getRepricingData');
      }
    },
    async makeCombinedPricing({ commit, dispatch, state }) {
      dispatch('uiModule/changeIsFetchingForPrices', true, { root: true });
      const tripsCombinations = state.tripRequestsCombinations.map((c) => c.map((i) => state.trips[i]));

      // make the pricing requests
      let pricingPromises = tripsCombinations.map(async (trips) => {
        return await makePricingRequest(trips.map(({ tripIndex, PricingData }) => ({ tripIndex, ...PricingData.PricingRequest })));
      });

      // await for all pricing responses
      const pricingResponses = (await Promise.all(pricingPromises)).flat();

      const newTrips = [];
      pricingResponses.forEach((tripPricingResponse) => {
        const { tripIndex } = tripPricingResponse;
        newTrips[tripIndex] = updateTripPricingImmutable(state.trips[tripIndex], tripPricingResponse);
      });

      commit(types.SET_TRIPS_FOR_BOOKING, newTrips);
      dispatch('uiModule/changeIsFetchingForPrices', false, { root: true });
    },
    setRefundOptionSelected({ commit, getters }, { ancillaryMode, ancillaryOrder, totalAncillaries, ancillaryUIMode }) {
      commit(types.SET_REFUND_PROTECT_SELECTED, ancillaryMode);

      if (!getters.refundProtectSelected) return;
      eventSelectedRefundProtect(getters.getRefundProtectProvider, getters.overallPriceForTrips, ancillaryOrder, totalAncillaries, ancillaryUIMode);
    },
    setTravelInsuranceSelected({ commit, getters }, { ancillaryMode, ancillaryOrder, totalAncillaries, ancillaryUIMode }) {
      commit(types.SET_TRAVEL_INSURANCE_SELECTED, ancillaryMode);

      if (!getters.travelInsuranceSelected) return;
      eventSelectedTravelInsurance(getters.getTravelInsuranceProvider, getters.overallPriceForTrips, ancillaryOrder, totalAncillaries, ancillaryUIMode);
    },
    setSupportPlusSelected({ commit, getters }, { ancillaryMode, ancillaryOrder, totalAncillaries, ancillaryUIMode }) {
      commit(types.SET_SUPPORT_PLUS_SELECTED, ancillaryMode);

      if (!getters.supportPlusSelected) return;
      eventSelectedSupportPlus(getters.overallPriceForTrips, ancillaryOrder, totalAncillaries, ancillaryUIMode);
    },
    setFlexiSelected({ commit, getters }, { ancillaryMode, ancillaryOrder, totalAncillaries, ancillaryUIMode }) {
      commit(types.SET_FLEXI_SELECTED, ancillaryMode);

      if (!getters.flexiSelected) return;
      eventSelectedFlexi(getters.overallPriceForTrips, ancillaryOrder, totalAncillaries, ancillaryUIMode);
    },
    setCouponCode({ commit, state, dispatch }, payload) {
      commit(types.SET_COUPON_CODE, payload);
      const shouldReprice = state.couponValidationStatus === COUPON_STATE.SUCCESS;
      if (state.couponValidationStatus !== COUPON_STATE.UNDETERMINED) {
        commit(types.SET_COUPON_APPLIED, false);
        commit(types.SET_TRIPS_WITH_COUPONS, '');
        commit(types.SET_COUPON_VALIDATION, createCouponValidation(COUPON_STATE.UNDETERMINED));
        if (shouldReprice) dispatch('getRepricingData');
      }
    },
    applyCouponCode({ commit, dispatch, state }) {
      commit(types.SET_COUPON_APPLIED, true);
      commit(types.SET_TRIPS_WITH_COUPONS, state.couponCode);
      commit(types.SET_COUPON_VALIDATION, createCouponValidation(COUPON_STATE.UNDETERMINED));
      dispatch('getRepricingData');
    },
    removeCouponCode({ commit, dispatch, state }) {
      commit(types.SET_COUPON_APPLIED, false);
      commit(types.SET_COUPON_CODE, '');
      commit(types.SET_TRIPS_WITH_COUPONS, '');
      commit(types.SET_COUPON_VALIDATION, createCouponValidation(COUPON_STATE.UNDETERMINED));
      dispatch('getRepricingData');
    },
    async getRepricingData({ commit, dispatch, rootState, state, getters }) {
      const { ticketDeliveryAmount, tripsWithCoupons } = state;
      if (!tripsWithCoupons.length) return;

      dispatch('uiModule/changeIsFetchingForPrices', true, { root: true });

      if (state.isCouponApplied) {
        commit(types.SET_COUPON_VALIDATION, createCouponValidation(COUPON_STATE.VALIDATING));
      }

      let extraServices = [];

      if (getters.isRefundProtectActivated) {
        extraServices.push(EXTRA_SERVICES.REFUND_PROTECT);
      }

      if (getters.isTravelInsuranceActivated) {
        extraServices.push(EXTRA_SERVICES.TRAVEL_INSURANCE);
      }

      if (getters.isSupportPlusActivated) {
        extraServices.push(EXTRA_SERVICES.SUPPORT_PLUS);
      }

      if (getters.isFlexiActivated) {
        extraServices.push(EXTRA_SERVICES.FLEXI);
      }

      const data = {
        userId: getActiveUserId(rootState.userModule.user),
        affiliateCode: getAffiliationCode(),
        ticketDelivery: ticketDeliveryAmount,
        couponPayload: {
          locale: getPageLanguage(),
          bookingPlatform: 'WEB',
          trips: tripsWithCoupons,
          requestedFields: {
            firstName: true,
            lastName: true,
            email: true,
            phone: true,
            ...state.passengerDetailsRequirements,
          },
        },
        extraServices,
      };

      const { response, error: failure } = await handlePromise(makeRepriceRequest(data));

      if (failure) {
        dispatch('onGetRepricingDataFailure', failure);
        return;
      }

      if (hasRepricingError(response)) {
        dispatch('onGetRepricingDataError', response);
      } else {
        dispatch('onGetRepricingDataSuccess', response);
      }

      dispatch('uiModule/changeIsFetchingForPrices', false, { root: true });
    },
    onGetRepricingDataSuccess({ commit, dispatch }, payload) {
      commit(types.SET_REPRICING_DATA, payload);
    },
    /**
     * Executes when repricing fails partially, eg due to an invalid coupon or an invalid extra service
     */
    onGetRepricingDataError({ commit }, payload) {
      const errors = getRepricingErrors(payload);

      const couponError = errors.find((error) => error.errorType === REPRICING_ERRORS.COUPON);
      const refundError = errors.find((error) => error.errorType === REPRICING_ERRORS.REFUND);
      const insuranceError = errors.find((error) => error.errorType === REPRICING_ERRORS.INSURANCE);
      const supportPlusError = errors.find((error) => error.errorType === REPRICING_ERRORS.SUPPORT_PLUS);
      const flexiError = errors.find((error) => error.errorType === REPRICING_ERRORS.FLEXI);

      // coupon validation is blocking so we do not SET_REPRICING_DATA
      if (couponError) {
        commit(types.SET_COUPON_VALIDATION, createCouponValidation(COUPON_STATE.FAILED, couponError.error));
      }

      // refund protection is non-blocking so we SET_REPRICING_DATA
      if (refundError) {
        commit(types.SET_REFUND_PROTECT_ERROR, refundError.error);
        commit(types.SET_REPRICING_DATA, payload);
      }

      // travel insurance is non-blocking so we SET_REPRICING_DATA
      if (insuranceError) {
        commit(types.SET_TRAVEL_INSURANCE_ERROR, insuranceError.error);
        commit(types.SET_REPRICING_DATA, payload);
      }

      // support plus is non-blocking so we SET_REPRICING_DATA
      if (supportPlusError) {
        commit(types.SET_SUPPORT_PLUS_ERROR, supportPlusError.error);
        commit(types.SET_REPRICING_DATA, payload);
      }

      // flexi hopper is non-blocking so we SET_REPRICING_DATA
      if (flexiError) {
        commit(types.SET_FLEXI_ERROR, flexiError.error);
        commit(types.SET_REPRICING_DATA, payload);
      }
    },
    /**
     * Executes when repricing fails completely (probably HTTP) errors
     */
    onGetRepricingDataFailure({ commit, dispatch, state }, error) {
      if (state.isCouponApplied) {
        commit(types.SET_COUPON_VALIDATION, createCouponValidation(COUPON_STATE.FAILED, GenericErrorCodes.get('generic')));
      }

      commit(types.SET_REFUND_PROTECT_ERROR, GenericErrorCodes.get('generic'));
      commit(types.SET_TRAVEL_INSURANCE_ERROR, GenericErrorCodes.get('generic'));
      commit(types.SET_SUPPORT_PLUS_ERROR, GenericErrorCodes.get('generic'));
      commit(types.SET_FLEXI_ERROR, GenericErrorCodes.get('generic'));

      dispatch('uiModule/changeIsFetchingForPrices', false, { root: true });
      logException('RepricingException', error);
    },
  },
};
