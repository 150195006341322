<template>
  <div :class="['fh-radio', { 'fh-radio--disabled': disabled }]" @click="$emit('click')">
    <label class="fh-radio__label">
      <input :class="inputClass" type="radio" :checked="checked" />
      <span :class="[checkMarkClass, { 'fh-radio__checkmark--error': hasError }]"></span>
    </label>
    <span v-if="text !== ''" class="fh-radio__text">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: 'FhRadio',
  props: {
    checked: Boolean,
    text: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click'],
  computed: {
    inputClass() {
      const mainClass = 'fh-radio__input';
      if (this.globalCobrandedVariant === 'aegeanair') return `${mainClass} fh-radio__input--aegean`;
      if (this.globalCobrandedVariant === 'easyferry') return `${mainClass} fh-radio__input--easyferry`;

      return `${mainClass} fh-radio__input--fh`;
    },
    checkMarkClass() {
      const mainClass = 'fh-radio__checkmark';
      if (this.globalCobrandedVariant === 'aegeanair') return `${mainClass} fh-radio__checkmark--aegean`;
      if (this.globalCobrandedVariant === 'easyferry') return `${mainClass} fh-radio__checkmark--easyferry`;

      return `${mainClass} fh-radio__checkmark--fh`;
    }
  }
};
</script>

<style lang="scss">
@import './fh-radio';
</style>
