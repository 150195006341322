
/**
 * @typedef ResidenceDiscount
 * 
 * @property {String} schemeName
 * @property {String} residenceNumber
 * @property {boolean} isResDiscountEnabled
 * @property {boolean} isEnabled
 * @property {String} STATUS
 */

import { createOfferValidationPayload } from "../common/passengerOffers/createOfferValidationPayload";
import { createPassengerOffer, PASSENGER_OFFER_STATUS } from "../common/passengerOffers/passengerOffer";

/**
 * @returns {ResidenceDiscount}
 */
export const createPassengerResidenceOffer = () => ({
  schemeName: '',
  residenceNumber: '',
  ...createPassengerOffer()
});


/**
 * Create residenceDiscount validation payload
 */
export const createResidenceValidationPayload = (residenceNumber, trip, passenger) => createOfferValidationPayload(
  { residenceNumber: residenceNumber },
  trip, passenger
)

/**
 * Returns the residenceDiscount state that should be enforced to all passengers of a trip,
 * depending on the latest change, the scheme specifics (allowSingleUse) and each passengers current state
 * @param {Object} residenceChange 
 * @param {Object} residenceDiscount 
 * @param {Object[]} passengers 
 */
export const getEnforcedResidenceDiscountState = (residenceChange, residenceDiscount, passengers) => (passengerResidenceDiscount) => {
  const shouldEnforceCommonState = !residenceDiscount.allowSingleUse;
  const shouldDisableAll = residenceChange.isEnabled === false;

  if (shouldEnforceCommonState && shouldDisableAll) return false;

  const shouldEnableAll = !!passengers.find(p => p.residenceDiscount.isEnabled);
  if (shouldEnforceCommonState && shouldEnableAll) return true;

  return passengerResidenceDiscount.isEnabled;
}
  
export const applyEnforcedResidenceDiscount = (discount, enforcedStateProvider) => {
  const isEnabled = enforcedStateProvider(discount);
  const newNumber = isEnabled ? discount.residenceNumber : '';
  const newStatus = isEnabled ? discount.STATUS : PASSENGER_OFFER_STATUS.UNDETERMINED;

  return {
    ...discount,
    STATUS: newStatus,
    residenceNumber: newNumber,
    isEnabled
  }
}
