<template>
  <div :class="[$style.selectableCardSkeleton]">
    <div :class="[$style.selectableCardSkeleton__item, $style.selectableCardSkeleton__header, 'skeleton-animation']"></div>
    <div :class="[$style.selectableCardSkeleton__item, $style.selectableCardSkeleton__image, 'skeleton-animation']"></div>
    <div :class="[$style.selectableCardSkeleton__item, $style.selectableCardSkeleton__text, 'skeleton-animation']"></div>
    <div :class="[$style.selectableCardSkeleton__item, $style.selectableCardSkeleton__footer, 'skeleton-animation']"></div>
  </div>
</template>

<script>
export default {
  name: 'SelectableCardSkeleton'
};
</script>

<style module lang="scss">
@import './selectable-card-skeleton.scss';
</style>
