import { PASSENGER_OFFER_STATUS, isPricableOffer } from '../../BL/common/passengerOffers/passengerOffer';
import { isPassengerSeating, isPetSeating, isVehicleSeating } from '../../models/pricing/seatingAnalysisItem';
import { groupBy as _groupBy } from 'lodash-es';
import { getExtraOffersAndDiscountsForPassenger } from './getExtraOffersAndDiscountsForPassenger';
import { message } from '@/logic/helpers/utils';

export const createTripsForBooking = (trips) => trips.map(createTripForBooking);

export const createTripForBooking = (bookableTrip) => {
  const {
    // passengerAccommodations,
    nonInfantOptions,
    infantOptions,
    // vehicleAccommodations,
    // petAccommodations,
    PricesPerCategory,
    // passengerDiscounts,
    // PetTypes,
    defaultAccommodation,
    infantAccommodation,
    defaultDiscount,
    // allowMultipleAccommodations,
    minPrice,
    maxPrice,
    // tripAnalysis, // needed for aegean booking app, cannot remove it yet
    unavailableReason,
    unavailableReasonCode,
    unavailableReasonTitle,
    vesselDetails,
    stops,
    ...tripForBooking
  } = bookableTrip;

  return {
    ...tripForBooking,
    passengers: tripForBooking.passengers.map(createTripPassengerForBooking),
    vehicles: tripForBooking.vehicles.map(createTripVehicleForBooking),
    pets: tripForBooking.pets.map(createTripPetForBooking),
  };
};

const createTripPassengerForBooking = (passenger) => {
  const {
    seatSelectionMessage, // not needed
    isTypeTouched, // not needed
    // selectedAccommodation, // not needed
    ...passengerForBooking
  } = passenger;

  return {
    ...passengerForBooking,
  };
};

const createTripVehicleForBooking = (vehicle) => {
  const { ...vehicleForBooking } = vehicle;

  return {
    ...vehicleForBooking,
  };
};

const createTripPetForBooking = (pet) => {
  const { ...petForBooking } = pet;

  return {
    ...petForBooking,
  };
};

export const createPassengersDetailsForBooking = (trips) => {
  if (trips.length === 0) return [];

  return trips[0].passengers.map((passenger) => {
    const passengerInstances = getPassengerInstances(trips, passenger);
    const anyValidResidenceDiscount = passengerInstances.find((p) => isPricableOffer(p.residenceDiscount));
    const passport = anyValidResidenceDiscount ? anyValidResidenceDiscount.residenceDiscount.residenceNumber : '';

    return {
      uid: passenger.uid,
      isLeader: passenger.isLeader,
      isNewPassenger: true,
      shouldSaveDetails: false,
      passengerIndex: passenger.passengerIndex,
      ...createPassengerDetailsForBooking(),
      passport,
      gender: undefined,
      selectionsPerTrip: getTripSelectionsForPassenger(trips, passenger),
    };
  });
};

const getTripSelectionsForPassenger = (trips, passenger) => {
  return trips.reduce((acc, trip) => {
    const seatingAnalysisItem = trip.SeatingAnalysis.find((i) => isPassengerSeating(i) && i.occupantIndexes.includes(passenger.passengerIndex));

    const tripPassenger = trip.passengers.find((p) => p.uid === passenger.uid);
    const passengerDiscountType = tripPassenger.companySpecificDiscount.DiscountDescription;

    if (!seatingAnalysisItem) return acc;
    return [
      ...acc,
      {
        extraOffersAndDiscounts: getExtraOffersAndDiscountsForPassenger(tripPassenger),
        passengerDiscountType,
        companyIcon: trip.Details.CompanyIcon,
        selectionIcon: seatingAnalysisItem.icon,
        selectionDescription: seatingAnalysisItem.description,
      },
    ];
  }, []);
};

const getTripSelectionsForVehicle = (trips, vehicle) => {
  return trips.reduce((acc, trip) => {
    const tripVehicle = trip.vehicles.find((v) => v.uid === vehicle.uid);
    const seatingAnalysisItem = trip.SeatingAnalysis.find((i) => isVehicleSeating(i) && i.occupantIndexes.includes(vehicle.vehicleIndex));
    if (!seatingAnalysisItem) return acc;

    return [
      ...acc,
      {
        driverIndex: tripVehicle.driverIndex,
        companyIcon: trip.Details.CompanyIcon,
        selectionIcon: seatingAnalysisItem.icon,
        selectionDescription: seatingAnalysisItem.description,
      },
    ];
  }, []);
};

const getTripSelectionsForPet = (trips, pet) => {
  return trips.reduce((acc, trip) => {
    const seatingAnalysisItem = trip.SeatingAnalysis.find((i) => isPetSeating(i) && i.occupantIndexes.includes(pet.petIndex));
    if (!seatingAnalysisItem) return acc;
    return [
      ...acc,
      {
        companyIcon: trip.Details.CompanyIcon,
        selectionIcon: seatingAnalysisItem.icon,
        selectionDescription: seatingAnalysisItem.description,
      },
    ];
  }, []);
};

const getPassengerInstances = (trips, passenger) => {
  return trips.map((trip) => trip.passengers.find((p) => p.passengerIndex === passenger.passengerIndex));
};

const createPassengerDetailsForBooking = () => ({
  /** name and surname */
  firstName: '',
  lastName: '',

  /* passport (or document number) - mutated afterwards in case of residence discount */
  passport: '',

  /** place of birth */
  birthplace: '',

  /** nationality */
  nationality: '',

  /** birthday */
  birthday: '',
  birthmonth: '',
  birthyear: '',
  birthDateFull: '',

  /** document expiration */
  docexpday: '',
  docexpmonth: '',
  docexpyear: '',
  documentDateFull: '',

  // `islanderCodeMessage` stores the success/error validation message.
  // They are probably not needed as properties on the model.
  // Add here to make them reactive.
  islanderCode: '',
  islanderCodeMessage: '',
  islanderCodeStatus: PASSENGER_OFFER_STATUS.UNDETERMINED,
  islanderCodeEnabled: false,
});

export const createVehiclesDetailsForBooking = (trips) => {
  if (trips.length === 0) return [];

  return trips[0].vehicles.map((vehicle) => {
    const item = trips[0].SeatingAnalysis.find((i) => isVehicleSeating(i) && i.occupantIndexes.includes(vehicle.vehicleIndex));
    return {
      uid: vehicle.uid,
      vehicleIndex: vehicle.vehicleIndex,
      vehicleIcon: item.icon,
      licencePlate: '',
      vehicleBrand: '',
      vehicleModel: '',
      vehicleCategoryType: vehicle.vehicleCategoryType,
      selectionsPerTrip: getTripSelectionsForVehicle(trips, vehicle),
      isNewVehicle: true,
      shouldSaveDetails: false,
    };
  });
};

export const createPetsDetailsForBooking = (trips) => {
  if (trips.length === 0) return [];

  return trips[0].pets.map((pet) => {
    return {
      petIndex: pet.petIndex,
      petName: '',
      petDocument: '',
      selectionsPerTrip: getTripSelectionsForPet(trips, pet),
    };
  });
};
