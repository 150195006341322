/**
 * This verifier is responsible for checking that an indirect itinerary,
 * created by cached data from FerryhopperCRS, actually exists.
 *
 * To achieve this, it asks the ferry provider for both segments that are
 * included in the trip, and compares the results with the relevant IDS codes
 *
 * On top of verification, this class is responsible for understanding opportunities,
 * reducing segments on triple itinerary, singe the full list of locations that
 * are involved is fetched and hence, more complex logic can be applied
 *
 * indirectTrip: Input trip, as produced by MultitripPipe
 */
import PortsRepository from '../repositories/PortsRepository';
import DirectTripsCollection from '@/logic/collections/DirectTripsCollection';
import { each as _each, cloneDeep as _cloneDeep } from 'lodash-es';
import { isNull, notNull } from '@/logic/helpers/utils';
import { hasDuplicates } from '@/logic/helpers/arrayFunctions';
import { getPortCodesForSegments } from '../models/trips/getPortCodesForSegments';
import { compareIDS } from '../models/trips/createIDS';
import { from } from 'rxjs';
import { map, mergeMap, toArray } from 'rxjs/operators'
import { logException } from '../services/events/errorLogging';
import dayjs from '@/logic/services/date/dateService';
import { createVerifiedIndirectTrip } from '../models/trips/createVerifiedIndirectTrip';
import { JOURNEY_MODE } from '../models/trips/journeyModes';

// returns true if an indirect trips passes through the same destination twice
export const hasDuplicatePortStopover = indirectTrip => {
  // return true if a destination is found more than once
  return hasDuplicates(getPortCodesForSegments(indirectTrip.segments));
};

export const hasSegmentsReachingEndDestination = indirectTrip => {
  const finalSegment = indirectTrip.segments[indirectTrip.segments.length - 1];  
  let destination = finalSegment.Details.ArrStation;
  
  for (let segmentIndex = 0; segmentIndex < indirectTrip.segments.length - 1; segmentIndex++) {
    
    // either second or third segment also begins from origin
    if (indirectTrip.segments[segmentIndex].tripAnalysis.between.includes(destination)) {
      return true;
    }

    // either second or third segment passes through origin
    if (indirectTrip.segments[segmentIndex].tripAnalysis.after.includes(destination)) {
      return true;
    }
  }

  return false;
}

// returns true if an indirect trip passes through the same island complex twice
export const hasDuplicateIslandStopover = indirectTrip => {
  for (let i = 0; i < indirectTrip.stopCodes.length - 1; i++) {
    for (let j = i + 1; j < indirectTrip.stopCodes.length; j++) {
      if (PortsRepository.isSameIsland(indirectTrip.stopCodes[i], indirectTrip.stopCodes[j])) {
        return true;
      }
    }
  }

  return false;
};

// returns true if any of the secondary segments include the original origin
export const hasCommonOriginSegments = indirectTrip => {
  let origin = indirectTrip.segments[0].Details.DepStation;

  for (let segmentIndex = 1; segmentIndex < indirectTrip.segments.length; segmentIndex++) {
    // either second or third segment also begins from origin
    if (indirectTrip.segments[segmentIndex].tripAnalysis.before.includes(origin)) {
      return true;
    }

    // either second or third segment passes through origin
    if (indirectTrip.segments[segmentIndex].tripAnalysis.between.includes(origin)) {
      return true;
    }
  }

  return false;
};


//----------------------------------------------------------------------------
// returns true if a triple-segment trip could be performed with only
// the first two segments. For this to happen, the second segment should
// directly lead to the final desitnation
export const hasSecondSegmentOpportunity = indirectTrip => {
  if (indirectTrip.segments.length < 3) {
    return false;
  }
  // get the intermediate segment
  let middleSegment = indirectTrip.segments[1];
  const middleSegmentPath = [...middleSegment.tripAnalysis.between, ...middleSegment.tripAnalysis.after];

  // get the final destination
  let finalDestination = indirectTrip.segments[2].Details.ArrStation;
  // chech that intermediate segment goes to the final destination
  return middleSegmentPath.includes(finalDestination);
};

// on http success, we want to verify trips that our FerryhopperCRS returned,
// with results obtained from ferry providers. To do so, on the constructor,
// we already issued queries for all trips involve. In this function, we
// compare these results to our expectations, and identify those that exist
// and those that don't exist in the 'official' results from providers
const validateIndirectTripFrom = (expectedSegments, verifiedDirectTrips) => {
  try {

    // loop through the responses, each corresponding to a segment of the double
    // trip, and check if the IDSCode exists in the results. If so, substitute
    // the FerryhopperCRS segment to a full DirectItinerary, ready to be
    // selected for booking.
    const verifiedSegments = expectedSegments
      .map(expectedSegment => {
        return verifiedDirectTrips.find(verifiedSegment => compareIDS(verifiedSegment.IDSCode, expectedSegment.IDSCode));
      })
      .filter(s => notNull(s));

    // If the validation fails (missing segments), return
    if (verifiedSegments.length !== expectedSegments.length) {
      return null;
    }

    const indirectTrip = createVerifiedIndirectTrip(verifiedSegments, JOURNEY_MODE.INDIRECT);
    return isRationalIndirectTrip(indirectTrip) ? indirectTrip : null;

  } catch (exception) {
    logException('IndirectTripsVerifier', exception);
    return null;
  }
};

const isRationalIndirectTrip = (indirectTrip) => {

    if (null === indirectTrip) {
      return;
    }
  
    // Check that secondary segments don't pass through initial origin port
    if (hasCommonOriginSegments(indirectTrip)) {
      return false;
    }

    // Check if the trip passes through an island twice
    if (hasDuplicateIslandStopover(indirectTrip)) {
      return false;
    }

    if (hasSegmentsReachingEndDestination(indirectTrip)) {
      return false;
    }

    // Check if the trip passes through a destination twice
    if (hasDuplicatePortStopover(indirectTrip)) {
      return false;
    }

    return true;
}

// create a promise for each segment that requires verification
const createSegmentRequests = segments => {
  return segments.map(s => {
    return new DirectTripsCollection(s.Details.DepStation, s.Details.ArrStation, s.timings.DepDate, false);
  });
};

const createSuggestionRequests = suggestion => {
  const oneDayAfter = dayjs(suggestion.depDate, 'YYYYMMDD').add(1, 'days').format('YYYYMMDD');

    return [
      new DirectTripsCollection(
        suggestion.depStation,
        suggestion.intStation,
        suggestion.depDate
      ),
      new DirectTripsCollection(
        suggestion.intStation,
        suggestion.arrStation,
        suggestion.depDate
      ),
      new DirectTripsCollection(
        suggestion.intermediate,
        suggestion.arrStation,
        oneDayAfter
      )
    ];
};

const createIndirectTripsFromQuery = (suggestionQuery, verifiedDirectTrips) => {

  const { depStation, arrStation, intStation } = suggestionQuery;
  let firstSegments = verifiedDirectTrips.filter(t => t.Details.DepStation === depStation && t.Details.ArrStation === intStation);
  let secondSegments = verifiedDirectTrips.filter(t => t.Details.DepStation === intStation && t.Details.ArrStation === arrStation);

  let indirectTrips = [];

  // loop through results and create indirect trips
  firstSegments.forEach(firstLeg => {
    secondSegments.forEach(secondLeg => {
      const segments = [_cloneDeep(firstLeg), _cloneDeep(secondLeg)];
      let indirectTrip = createVerifiedIndirectTrip(segments, JOURNEY_MODE.INDIRECT);

      if (isRationalIndirectTrip(indirectTrip)) {
        indirectTrips.push(indirectTrip);
      }        
    });
  });

  return indirectTrips;
};

export const getVerifiedIndirectTrip$ = indirectTrip => {
  const isSuggestedTrip = isNull(indirectTrip.segments);
  let collections = isSuggestedTrip ? 
    createSuggestionRequests(indirectTrip.suggestionQuery):
    createSegmentRequests(indirectTrip.segments);

  return from(collections).pipe(
    mergeMap(c => c.get().toPromise()),
    toArray(),
    // flatten results and keep only direct trips
    map(verifiedTrips => {
      return verifiedTrips
        .reduce((acc, chunk) => [...acc, ...chunk], [])
        .filter(r => r.isDirectTrip)
        .filter(r => !r.alternate);
    }),
    // convert to verified indirect trips
    map(verifiedDirectTrips => {
      return isSuggestedTrip ? 
        createIndirectTripsFromQuery(indirectTrip.suggestionQuery, verifiedDirectTrips) :
        [validateIndirectTripFrom(indirectTrip.segments, verifiedDirectTrips)];
    }),
    // remove null values
    map((trips) => trips.filter(t => !!t))
  );
};


