<template>
  <div class="tab-switcher" data-test="tabSwitcher" id="fh-search-mode-switcher">
    <h4 class="tab-switcher__option" @click="switchMode('single')" :class="{ 'tab-switcher__option--active': mode === 'single' }">{{ trans('singletrip') }}</h4>
    <h4 class="tab-switcher__option" @click="switchMode('multi')" :class="{ 'tab-switcher__option--last': !isMobile, 'tab-switcher__option--active': mode === 'multi' }" data-test="multiTripsTab">
      {{ trans('islandhopping') }}
    </h4>
  </div>
</template>

<script>
import { trans } from '@/filters';

export default {
  name: 'SearchModeSwitcher',
  props: {
    mode: {
      type: String,
      required: true,
    },
  },
  emits: ['switchMode'],
  methods: {
    trans,
    switchMode: function (mode) {
      this.$emit('switchMode', mode);
    },
  },
};
</script>
