import { logException } from './errorLogging';
import { ga4ShippingInfo, fbPayment, ga4ViewPromotion, ga4SelectPromotion } from './EventService';
import { eventPaymentClicked } from '@/logic/services/events/createPaymentEvents';

export const eventCheckoutBookingStep = (totalAmount, trips, couponInfo, allSearchPorts) => {
  try {
    fbPayment(totalAmount);
    ga4ShippingInfo(totalAmount, trips, couponInfo, allSearchPorts);
    eventPaymentClicked(totalAmount);
  } catch (error) {
    logException('eventCheckoutBookingStep', { error });
  }
};

export const eventViewPromotion = (trip, searchPorts) => {
  ga4ViewPromotion(trip, searchPorts, tripToLabelsArray(trip));
};

export const eventSelectPromotion = (trip, searchPorts) => {
  ga4SelectPromotion(trip, searchPorts, tripToLabelsArray(trip));
};

const tripToLabelsArray = (trip) => {
  let labels = [];
  if (trip.fastest) labels.push('FASTEST');
  trip.tripLabels.forEach((l) => labels.push(l.label));
  return labels;
};
