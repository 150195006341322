<template>
  <div :class="['travelers-control', { 'travelers-control--disabled': isOccupied }]">
    <LabelWithIcon class="travelers-control__label-with-icon" :disabled="!travelerSupported" :icon="icon" :label="label" :labelSize="'big'" />
    <div v-if="travelerSupported" :class="`travelers-control__buttons travelers-control__buttons--${buttonsBg}`">
      <button :class="['travelers-control__btn', { 'travelers-control__btn--disabled': minTravelersReached }]" @click.stop="removeTraveler" :data-test="'removeTravelerBtn'">
        <i class="fh fh-minus travelers-control__sign"></i>
      </button>
      <div class="travelers-control__number-wrapper">
        <span class="travelers-control__number">{{ travelersCount }}</span>
      </div>
      <button :class="['travelers-control__btn', { 'travelers-control__btn--disabled': maxTravelersReached }]" @click.stop="addTraveler" :data-test="'addTravelerBtn'">
        <i class="fh fh-plus travelers-control__sign"></i>
      </button>
    </div>
    <div v-else class="travelers-control__msg">
      <span>{{ trans('travelerType.notSupported') }}</span>
    </div>
  </div>
</template>

<script>
import LabelWithIcon from '@/components/shared/LabelWithIcon';
import { trans } from '@/filters';

export default {
  name: 'TravelersControl',
  components: {
    LabelWithIcon
  },
  props: {
    icon: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    travelerType: {
      type: String,
      required: true
    },
    travelersCount: {
      type: Number,
      required: true
    },
    travelerSupported: {
      type: Boolean,
      default: true
    },
    minTravelersReached: {
      type: Boolean,
      required: true
    },
    maxTravelersReached: {
      type: Boolean,
      required: true
    },
    isOccupied: {
      type: Boolean,
      default: false
    },
    buttonsBg: {
      type: String,
      default: 'gray'
    }
  },
  emits: ['addTraveler', 'removeTraveler'],
  methods: {
    trans,
    addTraveler() {
      this.$emit('addTraveler', this.travelerType);
    },
    removeTraveler() {
      this.$emit('removeTraveler', this.travelerType);
    }
  }
};
</script>
