<template>
  <div class="results-app__status">
    <div v-if="showSelectTripLabel" class="results-app__select-trip-label">{{ trans('results.directIndirectRoutes.heading') }}</div>
    <span class="results-app__status__text" v-html="content"></span>
    <img class="py10" :src="fhImage('assets/results/NoRoutesIcon.svg')" v-if="!hasDirectResults && !isLoadingDirect && !hasError" />
  </div>
</template>

<script>
import { message } from '@/logic/helpers/utils';
import { SEARCH_STATUS } from '@/logic/services/results/resultsService';
import { fhImage, trans } from '@/filters';

export default {
  name: 'ResultsAppStatus',
  components: {},
  props: {
    isLoadingDirect: Boolean,
    isDirectlyConnected: Boolean,
    hasDirectResults: Boolean,
    hasIndirectResults: Boolean,
    directTripsTotal: Number,
    directTripsSearchStatus: String,
    hasSelection: Boolean,
  },
  methods: {
    fhImage,
    trans,
  },
  computed: {
    hasError() {
      return this.directTripsSearchStatus === SEARCH_STATUS.ERROR;
    },
    hasNoDirectPortsConnection() {
      return !this.hasDirectResults && !this.isDirectlyConnected && !this.isLoadingDirect && this.directTripsSearchStatus === SEARCH_STATUS.COMPLETED;
    },
    hasNoDirectTrips() {
      return this.isDirectlyConnected && !this.hasDirectResults && this.directTripsSearchStatus === SEARCH_STATUS.COMPLETED;
    },
    content() {
      if (this.hasError) {
        return `<div class="results-app__status--network-error">  ${message('failDirect')}</div>`;
      }

      if (this.isLoadingDirect) {
        return `<i class="fh fh-notch3 fh-spin"></i> ${message('searchingspinner')}`;
      }

      // NO DIRECT TRIPS
      if (this.hasNoDirectTrips) {
        return `<i aria-hidden="true"></i> ${message('nodirectroutes')}`;
      }

      // NO DIRECT CONNECTION
      if (this.hasNoDirectPortsConnection) {
        return `<i aria-hidden="true"></i> ${message('notDirectlyConnected')}`;
      }

      if (this.directTripsTotal === 1) {
        return ` ${this.directTripsTotal} ${message('founddirect')}`;
      }

      if (this.directTripsTotal > 1) {
        return ` ${this.directTripsTotal} ${message('founddirects')}`;
      }
    },
    showSelectTripLabel() {
      return !this.hasSelection && (this.hasDirectResults || this.hasIndirectResults);
    },
  },
};
</script>
