export const PRICING_STATUSES = {
  // This value is coming from the api
  SUCCESS: '000',
  ERROR: 'ERROR',
  CONNECTION_ERROR: 'CONNECTION_ERROR'
};

/**
 * @typedef PricingResponse
 * @property {string} Status
 * @property {string} StatusMessage
 */


/**
 * @param {PricingResponse} pricingResponse
 */
export const isFailedPricing = pricingResponse => !isSuccessfulPricing(pricingResponse);

/**
 * @param {PricingResponse} pricingResponse
 */
export const isSuccessfulPricing = pricingResponse => pricingResponse.Status === PRICING_STATUSES.SUCCESS;


          