// @ts-check
import { logException } from '@/logic/services/events/errorLogging';
import PortsRepository from '../repositories/PortsRepository';
import { getDirectTrips$ } from './Polling';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { createVerifiedIndirectTrip } from '../models/trips/createVerifiedIndirectTrip';
import { createDirectTripFromApi } from '../models/trips/createDirectTripFromAPI';
import { JOURNEY_MODE } from '../models/trips/journeyModes';
import { notNull } from '@/logic/helpers/utils'

const tripDtoToTrip = (tripDto) => {  
  try {
    const isVerifiedIndirectTrip = notNull(tripDto.segments);
    return isVerifiedIndirectTrip ? 
      createVerifiedIndirectTrip(tripDto.segments.map(createDirectTripFromApi), JOURNEY_MODE.MULTISEGMENT) :
      createDirectTripFromApi(tripDto);   
  } catch (exception) {
    logException('Unparsable trip', {
      trip: tripDto,
      message: exception.message
    });
    return null;
  }
};

const createVerifiedTrips = (trips) => {
  return trips.reduce((acc, rawTrip) => {
      let trip = tripDtoToTrip(rawTrip);     
      if (!trip) return acc;      
      return [...acc, trip];    
  }, []);
};


/**
 * A collection for fetching direct trips from Ferryhopper API
 * @param {string} origin
 * @param {string} destination
 * @param {string} date
 */
let Collection = function(origin, destination, date) {
  // collection general settings
  this.depStation = origin;
  this.arrStation = destination;
  this.depDate = date;
};

Collection.prototype = {
  /**
   * Performs the search request and returns a promise that will be resolved/rejected
   * depending on the search response status
   */
  get() {
    const searchQueries = PortsRepository.getSearchAnalysis(this.depStation, this.arrStation, false);
    if (searchQueries.length === 0) { 
      return of([]);
    }

    const observable$ = getDirectTrips$(
      this.depStation, 
      this.arrStation, 
      this.depDate, 
      PortsRepository.getCombinationProviders(this.depStation, this.arrStation)
    );

    return observable$.pipe(
      map(data => {
        const { trips, multiSegmentTrips } = data;
        return createVerifiedTrips([...trips, ...(multiSegmentTrips || [])]);
      })
    );
  }
};

export default Collection;
