export const getMaximumPassengerRequirements = (trips) => {
    return trips.reduce((acc, trip) => {
        return logicalOR(acc, trip.passengerDetailsRequirements);
    }, trips[0].passengerDetailsRequirements)
}

export const getMaximumVehiclesRequirements = (trips) => {
    return trips.reduce((acc, trip) => {
        return logicalOR(acc, trip.vehicleDetailsRequirements);
    }, trips[0].vehicleDetailsRequirements)
}

export const getMaximumPetsRequirements = (trips) => {
    return trips.reduce((acc, trip) => {
        return logicalOR(acc, trip.petDetailsRequirements);
    }, trips[0].petDetailsRequirements)
}

const logicalOR = (reqA, reqB) => {
    let result = {};
    for (let key in reqA) {
        if (!reqB.hasOwnProperty(key)) {
            result[key] = reqA[key];
            continue;
        }
        result[key] = reqA[key] || reqB[key];
    }

    return result;
}