<template>
  <div class="results-app">
    <ResultsAppTopBar :searchIndex="searchIndex" :disabled="hasSelection" :date="activeDate" :origin="origin" :destination="destination" @nextDay="nextDay" @previousDay="previousDay" />

    <div v-if="!hasNoResults">
      <ResultsAppStatus
        :isLoadingDirect="isLoadingDirect"
        :directTripsTotal="activeDirectTripsCount"
        :isDirectlyConnected="isDirectlyConnected"
        :hasDirectResults="hasDirectResults"
        :directTripsSearchStatus="directTripsSearchStatus"
      />

      <!-- Alternate ports and dates suggestion -->
      <transition name="itinerary-animation" appear>
        <ResultsAppSuggestion
          v-if="!hasSelection"
          :key="`${origin}-${destination}-suggested`"
          :origin="origin"
          :destination="destination"
          :hasDirectResults="hasDirectResults"
          :isLoadingDirect="isLoadingDirect"
          :isChildPortSearch="isChildPortSearch"
          :closestAlternateDates="closestAlternateDates"
          :hasAlternateDates="hasAlternateDates"
          :directTripsSearchStatus="directTripsSearchStatus"
          @suggestionClicked="updateSearch"
        />
      </transition>

      <!-- DIRECT TRIPS -->
      <div v-if="activeDirectTripsCount > 0">
        <transition-group name="itinerary-animation" tag="div" :class="[resultsGroupClass, { 'ml15 mr15': isMobile }]" appear>
          <SelectableItinerary
            v-for="trip in activeDirectTrips"
            :key="trip.id"
            :trip="trip"
            :available="trip.available"
            :selected="selectedTripId === trip.id"
            :isSuperPortSearch="isSuperPortSearch"
            @onInfoButtonClick="onShowTripModal(trip)"
            @click="onItinerarySelect(trip.id, trip.available)"
          />
        </transition-group>
      </div>
      <div v-if="isLoadingDirect && !hasSelection">
        <ItinerarySkeleton :items="2" />
      </div>
      <!-- DIRECT API CALL ERROR BUTTON -->
      <ResultsAppRetryCta v-if="!hasSelection" :status="directTripsSearchStatus" @retry="initializeSearch()" />
    </div>
    <div v-else>
      <ResultsAppNoResults />
    </div>
  </div>
</template>

<script>
import { uniqBy as _uniqBy } from 'lodash-es';
import emitter from '@/emitter';
import ResultsAppSection from '@/components/results/ResultsAppSection';
import ResultsAppTopBar from '@/affiliates/aegeanair/components/results/ResultsAppTopBar';
import ResultsAppStatus from '@/affiliates/aegeanair/components/results/ResultsAppStatus';
import ResultsAppSuggestion from '@/affiliates/aegeanair/components/results/ResultsAppSuggestion';
import ResultsAppNoResults from '@/affiliates/aegeanair/components/results/ResultsAppNoResults';
import ResultsAppRetryCta from '@/components/results/ResultsAppRetryCta';
import { scrollToElementId } from '../../../../logic/dom/scrollTo';
import { isElementInViewport } from '@/logic/dom/isElementInViewport';
import { SEARCH_STATUS } from '@/logic/services/results/resultsService';

export default {
  name: 'AegeanResultsAppSection',
  extends: ResultsAppSection,
  components: {
    ResultsAppTopBar,
    ResultsAppStatus,
    ResultsAppSuggestion,
    ResultsAppNoResults,
    ResultsAppRetryCta,
  },
  methods: {
    /**
     * This method is overwritten for Aegean, due to the fact that we use
     * non-collabible results and hence, we need to search on a different computed
     * array to find the user selection
     */

    onItinerarySelect: function (id, available) {
      // do not respond to clicks of unavailable trips
      if (!available) return;

      if (this.selectedTripId !== id) {
        // for non-collapsible results, if the selected itinerary is changed by
        // clicking on another one, then we should manually deselect the current selection
        // before adding the new one
        if (this.selectedTripId !== '') {
          this.resetSelection();
        }

        const selection = [..._uniqBy(this.activeDirectTrips, 'IDSCode')].find((o) => o.id === id);
        if (selection === undefined) {
          // todo: Sentry error 811442286 has been thrown here. Hence, under certain
          // conditions, the selection might be undefined. Probably this occures
          // when the user fast clicks on a itinerary while this is collapsing
          // after a selection of another itinerary, and hence it does not
          // belong to the 'results array'
          return;
        }

        if (selection.available) {
          this.selectedTripId = id;
          this.hasSelection = true;
          emitter.$emit('onItinerarySelection', { selection, index: this.searchIndex });
          if (!isElementInViewport(`aegean-action-bar-${this.searchIndex}`)) scrollToElementId(`aegean-action-bar-${this.searchIndex}`);
        }
      } else {
        this.resetSelection();
      }
    },
  },
  computed: {
    hasNoResults() {
      return !this.hasDirectResults && !this.hasAlternateDates && !this.isLoadingDirect && this.directTripsSearchStatus !== SEARCH_STATUS.ERROR;
    },
  },
};
</script>
