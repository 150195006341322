<template>
  <div>
    <LoyaltyCardInput
      v-if="trip.loyaltyScheme.supportsLoyalty"
      :disable="disable"
      :passenger="passenger"
      :trip="trip"
      :tripOffer="trip.loyaltyScheme"
      :passengerOffer="passenger.loyalty"
      :changeEvent="'onLoyaltyChange'"
      @onLoyaltyChange="onLoyaltyChange"
    />

    <ResidenceDiscountInput
      v-if="trip.residenceDiscount.supportsResidenceDiscount"
      :disable="disable"
      :passenger="passenger"
      :trip="trip"
      :tripOffer="trip.residenceDiscount"
      :passengerOffer="passenger.residenceDiscount"
      :changeEvent="'onResidenceDiscountChange'"
      @onResidenceDiscountChange="onResidenceDiscountChange"
    />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';

const LoyaltyCardInput = defineAsyncComponent(() => import('@/components/seats/passenger-offers/LoyaltyCardInput'));
const ResidenceDiscountInput = defineAsyncComponent(() => import('@/components/seats/passenger-offers/ResidenceDiscountInput'));

export default {
  name: 'PassengerOffersComponent',
  components: {
    LoyaltyCardInput,
    ResidenceDiscountInput
  },
  props: {
    trip: Object,
    passenger: Object,
    disable: Boolean
  },
  emits: ['onLoyaltyChange', 'onResidenceDiscountChange'],
  methods: {
    onLoyaltyChange(loyaltyCard) {
      this.$emit('onLoyaltyChange', loyaltyCard);
    },
    onResidenceDiscountChange(residenceDiscount) {
      this.$emit('onResidenceDiscountChange', residenceDiscount);
    }
  }
};
</script>
