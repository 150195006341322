<template>
<span v-html="vehicleIcon"></span>
</template>

<script>
import settings from '@/settings';
export default {
    name: 'VehicleIcon',
    props: {
        vehicle: {
            type: Object,
            required: true
        },
        categories: {
            type: Array,
            required: true
        }
    },
    computed: {
        selectedCategory() {
            return this.categories.find(c => c.categoryCode === this.vehicle.TypeCategory);
        },
        vehicleIcon() {
            return this.selectedCategory && this.selectedCategory.icon || settings.icons.vehicles.CAR;
        }
    }
}
</script>