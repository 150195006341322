<template>
  <div>
    <div class="seating-travelers-picker-mob">
      <div class="seating-travelers-picker-mob__top" @click="toggle">
        <span class="seating-travelers-picker-mob__header">{{ trans('travelerType.control.heading') }}</span>
        <button class="seating-travelers-picker-mob__btn">
          <i :class="['fh fh-down-arrow seating-travelers-picker-mob__arrow', { 'seating-travelers-picker-mob__arrow--rotated': expanded }]"></i>
        </button>
      </div>
      <div :class="['pt12', { pt30: expanded }]">
        <div v-if="expanded">
          <TravelersControl
            :buttonsBg="'white'"
            :icon="'users-fill'"
            :label="trans('passengers')"
            :travelerType="'passenger'"
            :travelersCount="passengers"
            :minTravelersReached="minPassengersReached"
            :maxTravelersReached="maxPassengersReached"
            :isOccupied="isOccupied"
            @addTraveler="addTraveler"
            @removeTraveler="removeTraveler"
          />
          <TravelersControl
            v-if="isPetsBookingActivated"
            :buttonsBg="'white'"
            :icon="'pet'"
            :label="trans('pets')"
            :travelerType="'pet'"
            :travelersCount="pets"
            :minTravelersReached="minPetsReached"
            :maxTravelersReached="maxPetsReached"
            :isOccupied="isOccupied"
            :travelerSupported="petsAllowed"
            @addTraveler="addTraveler"
            @removeTraveler="removeTraveler"
          />
          <TravelersControl
            :buttonsBg="'white'"
            :icon="'vehicles'"
            :label="trans('vehicles')"
            :travelerType="'vehicle'"
            :travelersCount="vehicles"
            :minTravelersReached="minVehiclesReached"
            :maxTravelersReached="maxVehiclesReached"
            :isOccupied="isOccupied"
            :travelerSupported="!hasCarsRestriction"
            @addTraveler="addTraveler"
            @removeTraveler="removeTraveler"
          />
        </div>
        <TravelersCounter v-else @onTravelerClick="toggle" :grayNumber="true" />
      </div>
    </div>
    <div class="seating-travelers-picker" :class="{ 'seating-travelers-picker--fill-gaps': !isPetsBookingActivated }">
      <TravelersControl
        :icon="'users-fill'"
        :label="trans('passengers')"
        :travelerType="'passenger'"
        :travelersCount="passengers"
        :minTravelersReached="minPassengersReached"
        :maxTravelersReached="maxPassengersReached"
        :isOccupied="isOccupied"
        @addTraveler="addTraveler"
        @removeTraveler="removeTraveler"
      />
      <TravelersControl
        v-if="isPetsBookingActivated"
        :icon="petsIcon"
        :label="trans('pets')"
        :travelerType="'pet'"
        :travelersCount="pets"
        :travelerSupported="petsAllowed"
        :minTravelersReached="minPetsReached"
        :maxTravelersReached="maxPetsReached"
        :isOccupied="isOccupied"
        @addTraveler="addTraveler"
        @removeTraveler="removeTraveler"
      />
      <TravelersControl
        :icon="vehiclesIcon"
        :label="trans('vehicles')"
        :travelerType="'vehicle'"
        :travelersCount="vehicles"
        :minTravelersReached="minVehiclesReached"
        :maxTravelersReached="maxVehiclesReached"
        :isOccupied="isOccupied"
        :travelerSupported="!hasCarsRestriction"
        @addTraveler="addTraveler"
        @removeTraveler="removeTraveler"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import TravelersControl from '@/components/shared/Travelers/TravelersControl';
import TravelersCounter from '@/components/shared/Travelers/TravelersCounter';
import { PET_POLICIES } from '@/logic/repositories/PetsRepository';
import { trans } from '@/filters';
import { eventSeatingTravelersClicked } from '@/logic/services/events/createSeatingEvents';

export default {
  name: 'SeatingTravelersPicker',
  components: {
    TravelersControl,
    TravelersCounter,
  },
  data() {
    return {
      expanded: false,
    };
  },
  props: {
    isOccupied: {
      type: Boolean,
      default: false,
    },
    hasCarsRestriction: {
      type: Boolean,
      required: false,
    },
    trips: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState({
      passengers: (state) => state.searchModule.passengers,
      vehicles: (state) => state.searchModule.vehicles,
      pets: (state) => state.searchModule.pets,
      isPetsBookingActivated: (state) => state.searchModule.isPetsBookingActivated,
    }),
    ...mapGetters({
      minVehiclesReached: 'searchModule/minVehiclesReached',
      minPetsReached: 'searchModule/minPetsReached',
      minPassengersReached: 'searchModule/minPassengersReached',
      maxVehiclesReached: 'searchModule/maxVehiclesReached',
      maxPetsReached: 'searchModule/maxPetsReached',
      maxPassengersReached: 'searchModule/maxPassengersReached',
    }),
    petsAllowed() {
      return this.trips.every((trip) => trip.petPolicy.type !== PET_POLICIES.NOTALLOWED);
    },
    petsIcon() {
      return this.petsAllowed ? 'pet' : 'no-pet';
    },
    vehiclesIcon() {
      return this.hasCarsRestriction ? 'no-vehicles' : 'vehicles';
    },
  },
  methods: {
    ...mapActions({
      addNewPassenger: 'ticketConfigurationModule/addNewPassenger',
      increasePassengerCount: 'searchModule/increasePassengerCount',
      resetSeatSelectionMessage: 'ticketConfigurationModule/resetSeatSelectionMessage',
      removeLastPassenger: 'ticketConfigurationModule/removeLastPassenger',
      decreasePassengerCount: 'searchModule/decreasePassengerCount',
      handleCabinsOnPassengerRemoval: 'ticketConfigurationModule/handleCabinsOnPassengerRemoval',
      addNewVehicle: 'ticketConfigurationModule/addNewVehicle',
      removeLastVehicle: 'ticketConfigurationModule/removeLastVehicle',
      increaseVehicleCount: 'searchModule/increaseVehicleCount',
      decreaseVehicleCount: 'searchModule/decreaseVehicleCount',
      addNewPet: 'ticketConfigurationModule/addNewPet',
      increasePetCount: 'searchModule/increasePetCount',
      removeLastPet: 'ticketConfigurationModule/removeLastPet',
      decreasePetCount: 'searchModule/decreasePetCount',
    }),
    trans,
    isPassenger(type) {
      return type === 'passenger';
    },
    isVehicle(type) {
      return type === 'vehicle';
    },
    isPet(type) {
      return type === 'pet';
    },
    addTraveler(traveler) {
      if (this.isPassenger(traveler)) {
        this.addNewPassenger();
        this.increasePassengerCount();
        this.resetSeatSelectionMessage();
        this.sendTravelerAddedEvents('PASSENGER');
      }

      if (this.isVehicle(traveler)) {
        this.addNewVehicle();
        this.increaseVehicleCount();
        this.sendTravelerAddedEvents('VEHICLE');
      }

      if (this.isPet(traveler)) {
        this.addNewPet();
        this.increasePetCount();
        this.sendTravelerAddedEvents('PET');
      }
    },
    removeTraveler(traveler) {
      if (this.isPassenger(traveler)) {
        this.removeLastPassenger();
        this.decreasePassengerCount();
        this.resetSeatSelectionMessage();
        this.handleCabinsOnPassengerRemoval();
      }

      if (this.isVehicle(traveler)) {
        this.removeLastVehicle();
        this.decreaseVehicleCount();
      }

      if (this.isPet(traveler)) {
        this.removeLastPet();
        this.decreasePetCount();
      }
    },
    toggle() {
      this.expanded = !this.expanded;
    },
    sendTravelerAddedEvents(travelerType) {
      this.trips.forEach((trip) => eventSeatingTravelersClicked(travelerType, 'ADDED', trip));
    },
  },
};
</script>
