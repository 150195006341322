import CompaniesRepository from "@/logic/repositories/CompaniesRepository";
import PortsRepository from "@/logic/repositories/PortsRepository"
import { eventErrorUnknownVessel } from "@/logic/services/events/createResultsEvents";

export const createTripDetails = (data) => {

    let {
        company: companyCode,
        vessel: vesselCode,
        origin: originCode,
        destination: destinationCode
    } = data;

    let origin = PortsRepository.getPort(originCode);
    let destination = PortsRepository.getPort(destinationCode);

    const vessel = CompaniesRepository.getCompanyVessel(companyCode, vesselCode);

    if (vessel.isUnknown) {
        eventErrorUnknownVessel({ ...data, vesselName: vessel.VesselName });
    }

    // check if the specific vessel has a preferred company
    // note: the reason we need to know both original as well as preferred
    // companies, is that we need to make issuing with the original company
    // but we need to show booking info (eg. collection spots) for the
    // preferred one
    // examples:
    // ANSF used by both ATC and SUP (anek superfast). The latter does not even
    // exist as a company, it is a mock one created by us allow for the mechanism to work
    // BAL used as FredOlsen on some routes
    let hasPreferredCompany = vessel.PrefCompany !== null;

    // get preferred company code
    let preferredCompany = hasPreferredCompany ? vessel.PrefCompany.CompanyAbbr : undefined;

    // get preferred visualization icon for this vessel
    let preferredCompanyIcon = CompaniesRepository.getPreferredIcon(vessel);

    // get preferred company name for this vessel
    let preferredCompanyName = CompaniesRepository.getPreferredCompanyName(vessel);

    // check if the company is supported
    // todo: discuss what would happen if the original company was supported
    // but the preferred one was not, or vice versa (unlikely though)
    let isCompanySupported = CompaniesRepository.isSupported(companyCode);
    // check if the company has e-ticket
    // todo: check what would happen if the original company had no e-ticket
    // but the preferred one had, or vice versa (unlikely though)
    let hasETicket = CompaniesRepository.hasETicket(companyCode);
    
    return {
        origin: origin,
        destination: destination,
        DepStationName: origin.short || origin.LocationAbbr,
        ArrStationName: destination.short || destination.LocationAbbr,
        DepStation: origin.LocationAbbr,
        ArrStation: destination.LocationAbbr,
        DepCountry: origin.country,
        ArrCountry: destination.country,
        Company: companyCode,
        HasPrefCompany: hasPreferredCompany,
        PrefCompany: preferredCompany,
        CompanyIcon: preferredCompanyIcon,
        CompanyName: preferredCompanyName,
        ParentCompanyName: hasPreferredCompany ? CompaniesRepository.getParentCompanyName(vessel) : preferredCompany,
        VesselID: vesselCode,
        VesselName: vessel.VesselName,
        hasCabins: data.cabins || false,
        hasGaraze: data.garaze || false,
        eTicket: hasETicket,
        supported: isCompanySupported,
        hasCountries: [
            origin.country,
            destination.country
        ],
        hasCountryChange: origin.country !== destination.country
    }
}