
/**
 * @typedef Offer
 * 
 * @property {boolean} isEnabled
 * @property {String} STATUS
 */

export const PASSENGER_OFFER_STATUS = {
  UNDETERMINED: 'UNDETERMINED',
  VALIDATING: 'VALIDATING',
  VALID: 'VALID',
  INVALID: 'INVALID'
};

/**
 * @returns {Offer}
 */
export const createPassengerOffer = () => ({
  STATUS: PASSENGER_OFFER_STATUS.UNDETERMINED,
  isEnabled: false
});

export const createTripOffer = (scheme) => {
  return {
    ...scheme,
    docName: scheme.docName || scheme.schemeName,
    schemeName: scheme.schemeName
  }
}

/**
 * @param {Offer} offer 
 */
export const isPendingOffer = offer => offer.isEnabled && (offer.STATUS === PASSENGER_OFFER_STATUS.UNDETERMINED || offer.STATUS === PASSENGER_OFFER_STATUS.VALIDATING);

/**
 * @param {Offer} offer 
 */
export const isValidOffer = offer => offer.STATUS === PASSENGER_OFFER_STATUS.VALID;

/**
 * @param {Offer} offer 
 */
export const isInvalidOffer = offer => offer.STATUS === PASSENGER_OFFER_STATUS.INVALID;

/**
 * @param {Offer} offer 
 */
export const isPricableOffer = offer => offer && offer.isEnabled && isValidOffer(offer);

/**
 * @param {Offer} offer 
 */
export const isNonPricableOffer = offer => offer.isEnabled && (isPendingOffer(offer) || isInvalidOffer(offer));

export const isWaitingValidation = offer => offer.isEnabled && offer.STATUS === PASSENGER_OFFER_STATUS.VALIDATING;


  
