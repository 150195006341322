export const mergeTripsWithTravelerDetails = (trips, { passengersDetails, vehiclesDetails, petsDetails }) => {

  const tripsWithDetails = trips.map(trip => {
    return mergeTripWithTravelerDetails(trip, { passengersDetails, vehiclesDetails, petsDetails });
  })

  return tripsWithDetails;
}

export const mergeTripWithTravelerDetails = (trip, { passengersDetails, vehiclesDetails, petsDetails }) => {
  const tripsPassengersWithDetails = trip.passengers.map(passenger => {
    const passengerDetails = passengersDetails.find(details => details.passengerIndex === passenger.passengerIndex);
    return {
      ...passenger,
      ...passengerDetails
    };
  });

  const tripsVehiclesWithDetails = trip.vehicles.map(vehicle => {

    const vehicleDetails = vehiclesDetails.find(details => details.vehicleIndex === vehicle.vehicleIndex);

    return {
      ...vehicle,
      ...vehicleDetails
    };
  });

  const tripsPetsWithDetails = trip.pets.map(pet => {
    const petDetails = petsDetails.find(details => details.petIndex === pet.petIndex);
    return {
      ...pet,
      ...petDetails
    };
  });

  return {
    ...trip,
    passengers: tripsPassengersWithDetails,
    vehicles: tripsVehiclesWithDetails,
    pets: tripsPetsWithDetails
  };
}
