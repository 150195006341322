import { isNull, message, notNull } from "@/logic/helpers/utils";
import { LABEL_CATEGORIES } from "@/logic/models/trips/adaptTripLabels";

export const getActiveRoundTripOfferLabel = (selectedTrips, selectedTripsCount) => {

    if (selectedTripsCount < 1) return null;

    const outboundTrip = selectedTrips[0];
    const inboundTrip = selectedTrips[1];

    const outboundOffer = getRoundTripOfferLabel(outboundTrip || { tripLabels: [] });
    const inboundOffer = getRoundTripOfferLabel(inboundTrip || { tripLabels: [] });

    // no offers
    if (isNull(inboundOffer) && isNull(outboundOffer)) return null;

    // no inbound selection & available outbound offer
    if (isNull(inboundTrip) && notNull(outboundOffer)) {
        return {
            description: outboundOffer.description,
            placement: 0
        }
    };

    // no outbound selection & available inbound offer
    if (isNull(outboundTrip) && notNull(inboundOffer)) {
        return {
            description: inboundOffer.description,
            placement: 1
        }
    };

    // no inbound or outbound offer
    if (isNull(inboundOffer) || isNull(outboundOffer)) return null;
    if (inboundOffer.id !== outboundOffer.id) return null;

    return {
        description: message('results.cart.discountSuccessMessage'),
        placement: 'footer'
    }
}

const getRoundTripOfferLabel = (trip) => {
    const { tripLabels } = trip;
    return tripLabels
        .filter(label => label.category = LABEL_CATEGORIES.OFFER)
        .find(l => l.isForRoundTrip);
}