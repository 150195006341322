<template>
  <div :class="dropdownClass" :id="id" :tabindex="enableFocus ? '0' : '-1'" @keypress.enter="toggleDropdown" @click="$emit('click')">
    <FhDropdownToggle
      @click="toggleDropdown"
      :option="selectedOption"
      :optionKeyToShow="optionKeyToShow"
      :optionExtraKeyToShow="optionExtraKeyToShow"
      :showDropdown="showDropdown"
      :placeholder="placeholder"
      :grayPlaceholder="grayPlaceholder"
      :truncate="truncate"
      :commaSeparated="commaSeparated"
    />
    <div :class="dropdownContentClass" ref="dropdownContent" v-if="showDropdown">
      <FhDropdownSearch v-if="hasSearch" v-model="inputValue" :optionKeyToShow="optionKeyToShow" :showError="showInputError" @focus="onFocus" @blur="onBlur" @enter="onEnter" />
      <div :class="$style['fhDropdown__options-wrapper']" ref="dropdownOptionsWrapper">
        <slot v-if="showDropdown" name="options" :select="select" :dropdownOptions="dropdownOptions" :hasInputValue="hasInputValue"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import FhDropdownToggle from './FhDropdownToggle';
import FhDropdownSearch from './FhDropdownSearch';
import FhDropdownMixin from './FhDropdownMixin';
import { message } from '@/logic/helpers/utils';
import { removeDuplicateOptions } from '@/logic/helpers/FhDropdownUtils';
import { convertAccentedString } from '@/logic/helpers/stringUtils';

export default {
  name: 'FhDropdown',
  mixins: [FhDropdownMixin],
  components: {
    FhDropdownToggle,
    FhDropdownSearch,
  },
  data() {
    return {
      showDropdown: false,
      computedOptions: [],
      inputValue: {},
    };
  },
  props: {
    value: String | Number,
    valueType: {
      type: String,
      default: 'String',
    },
    hasSearch: Boolean,
    disabled: Boolean,
    hasError: Boolean,
    grayPlaceholder: Boolean,
    options: Array,
    textSize: String,
    id: String,
    hasBorder: {
      type: Boolean,
      default: true,
    },
    truncate: {
      type: Boolean,
      default: false,
    },
    commaSeparated: {
      type: Boolean,
      default: false,
    },
    optionKeyToTrack: {
      type: String,
      required: true,
    },
    optionKeyToShow: {
      type: String,
      required: true,
    },
    optionExtraKeyToShow: {
      type: String,
      default: '',
    },
    optionExtraKeyToSearch: {
      type: String,
      default: '',
    },
    dropdownContentUp: {
      type: Boolean,
      default: false,
    },
    enableFocus: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default() {
        return message('actionselect');
      },
    },
  },
  emits: ['focus', 'blur', 'input', 'click'],
  mounted() {
    this.computedOptions = this.options;
  },
  methods: {
    onFocus() {
      this.$emit('focus');
    },
    onBlur() {
      this.$emit('blur');
    },
    onEnter() {
      if (this.dropdownOptions.length === 0) return;
      document.getElementById(this.id).focus();
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    select(option, index) {
      this.toggleDropdown();
      this.setInputValue(option);
      this.setSelectedOptionIndex(index);
      this.$emit('input', option);
    },
    setInputValue(value) {
      this.inputValue = value;
    },
    optionValueIncludesQuery(option, key, query) {
      if (key !== '') return convertAccentedString(option[key].toLowerCase()).includes(query);
      return false;
    },
  },
  computed: {
    selectedOption() {
      return this.options.find((o) => o[this.optionKeyToTrack] === this.value);
    },
    dropdownOptions() {
      return this.hasSearch ? this.computedOptions : this.options;
    },
    showInputError() {
      return this.computedOptions.length === 0;
    },
    dropdownClass() {
      const textSizeClass = this.textSize === 'small' ? this.$style['fhDropdown--small'] : '';
      const borderClass = this.hasBorder ? '' : this.$style['fhDropdown--no-border'];
      const disabledClass = this.disabled ? this.$style['fhDropdown--disabled'] : '';
      const errorClass = this.hasError ? this.$style['fhDropdown--error'] : '';
      const focusClass = this.enableFocus ? this.$style['fhDropdown--focus-enabled'] : '';
      let cobrandedFocusClass = this.enableFocus && this.globalCobrandedVariant === 'easyferry' ? this.$style['fhDropdown--easyferry-focus'] : '';
      cobrandedFocusClass = this.enableFocus && this.globalCobrandedVariant === 'aegeanair' ? this.$style['fhDropdown--aegeanair-focus'] : cobrandedFocusClass;
      const cobrandedClass = this.globalCobrandedVariant === 'easyferry' ? this.$style['fhDropdown--round-border'] : '';
      return `noselect ${this.$style.fhDropdown} ${textSizeClass} ${borderClass} ${disabledClass} ${errorClass} ${focusClass} ${cobrandedFocusClass} ${cobrandedClass}`;
    },
    dropdownContentClass() {
      let truncateClass = this.truncate ? this.$style['fhDropdown__content--truncate'] : '';
      return `${this.$style.fhDropdown__content} ${truncateClass}`;
    },
    hasInputValue() {
      return this.inputValue && this.inputValue[this.optionKeyToShow] && this.inputValue[this.optionKeyToShow] !== '' ? true : false;
    },
  },
  watch: {
    inputValue() {
      if (!this.hasSearch) return;
      if (!this.hasInputValue) {
        this.computedOptions = this.options;
        return;
      }

      // Remove Diacritics from userInput and convert to lowercase
      const queryString = convertAccentedString(this.inputValue[this.optionKeyToShow].toLowerCase());
      const filteredOptions = this.options.filter((o) => this.optionValueIncludesQuery(o, this.optionKeyToShow, queryString) || this.optionValueIncludesQuery(o, this.optionExtraKeyToSearch, queryString));
      this.computedOptions = removeDuplicateOptions(filteredOptions);
      this.resetActiveOption();
    },
    showDropdown() {
      if (this.showDropdown) {
        this.setInputValue({});
        this.computedOptions = this.options;
        this.handleDropdownToggle();
      }
    },
  },
};
</script>

<style module lang="scss">
@import '@/assets/sass/components/fh-dropdown/_fh-dropdown.scss';
</style>
