<template>
  <div>
    <FhModal v-if="showModal" @close="showModal = false" :title="title">
      <template #body>
        <LoadingSpinner v-if="content === ''"/>
        <div v-else v-html="content"></div>
      </template>
    </FhModal>
  </div>
</template>

<script>
import emitter from '@/emitter';

export default {
  name: 'InfoModal',
  data() {
    return {
      showModal: false,
    };
  },
  created: function () {
    emitter.$on('openInfoModal', this.openInfoModal);
    emitter.$on('closeInfoModal', this.closeInfoModal);
  },
  unmounted: function () {
    emitter.$off('openInfoModal', this.openInfoModal);
    emitter.$off('closeInfoModal', this.closeInfoModal);
  },
  methods: {
    openInfoModal() {
      this.showModal = true;
    },
    closeInfoModal() {
       this.showModal = false;
    }
  },
  computed: {
    content: function () {
      return this.$store.state.navigationModule.infoModalContent;
    },
    title: function () {
      return this.$store.state.navigationModule.infoModalTitle;
    },
  },
};
</script>
