function ContactInfoModel(data) {
  const {
    Email = '',
    email = '',
    PhoneCountryCode = '',
    Phone = '',
  } = data || {};

  // reservation information
  // Email is obtained either through state reconcilers, when returning from bank,
  // where the property is Capitalized, or through logged user details, when loading
  // the page, when the email is lowercase
  this.Email = Email || email;   
  this.PhoneCountryCode = PhoneCountryCode;
  this.Phone = Phone;
}

ContactInfoModel.prototype = {
  setPhoneCountryCode(code) {
    this.PhoneCountryCode = code;
  }
}

export default ContactInfoModel;
