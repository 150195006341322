<template>
  <div v-if="showIndirectCTA">
    <div class="results-app__footer results-app__footer--action" @click="getIndirectResultsClicked()" data-test="getIndirectTripsCta">
      <span class="text-center"><i class="fh fh-multiple-markers" v-if="!isShowMoreMode"></i> {{ trans(`${ctaMessage}`) }} </span>
    </div>
  </div>
</template>

<script>
import { SEARCH_STATUS } from '../../logic/services/results/resultsService';
import { trans } from '@/filters';

export default {
  name: 'ResultsAppIndirectCta',
  props: {
    prefetchedIndirectsCount: {
      type: Number,
      default: 0,
    },
    indirectSearchStatus: {
      type: String,
      required: true,
    },
    activeIndirectTripsCount: {
      type: Number,
      required: true,
    },
  },
  emits: ['getIndirectResultsClicked'],
  methods: {
    trans,
    getIndirectResultsClicked() {
      this.$emit('getIndirectResultsClicked');
    },
  },
  computed: {
    isShowMoreMode() {
      return this.activeIndirectTripsCount > 0;
    },
    ctaMessage() {
      return this.isShowMoreMode ? 'searchMoreIndirectRoutes' : 'searchIndirectRoutes';
    },
    showIndirectCTA() {
      return this.indirectSearchStatus === SEARCH_STATUS.IDLE && this.prefetchedIndirectsCount > 0;
    },
  },
};
</script>