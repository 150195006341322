import { isPricableOffer } from '../../BL/common/passengerOffers/passengerOffer';


// Here, we gather all offers (residence or loyalty) selected for this passenger in a trip. 
// We then use this array purely for visualization reasons. This seems very messy.
// Note: We do not show offers for infant instances. This is strange for many reasons
//       and I propose to fix in any future opportunity
// point 1: a passenger can be infant in one trip and not infant in other.
// point 2: what if we add an offer that is indeed applicable in infants
// point 3: we should reset offers when a passenger is switching between infant and non-infant state
// point 4: what will happen with offers if an infant saved passenger is selected after an offer has been applied?
//          point 3 could be a solution to this problem
export const getExtraOffersAndDiscountsForPassenger = passenger => {

    if (passenger.isInfant) return [];
  
    return [
      isPricableOffer(passenger.loyalty)
        ? {
            key: `${passenger.loyalty.schemeName}${passenger.loyalty.loyaltyNumber}`,
            extraDiscountName: passenger.loyalty.schemeName,
            extraDiscountNumber: passenger.loyalty.loyaltyNumber
          }
        : null,
      isPricableOffer(passenger.residenceDiscount)
        ? {
            key: `${passenger.residenceDiscount.schemeName}${passenger.residenceDiscount.residenceNumber}`,
            extraDiscountName: passenger.residenceDiscount.schemeName,
            extraDiscountNumber: passenger.residenceDiscount.residenceNumber
          }
        : null
    ].filter(offer => !!offer);
  }