<template>
  <div class="row">
    <div class="col-xs-12">
      <div class="booking-summary mb20">
        <div class="booking-summary__left">
          <BookingSummaryItem v-for="(trip, tripIndex) in trips" :trip="trip" :tripIndex="tripIndex" :key="trip.id" />
        </div>
        <div class="booking-summary__right" v-if="!isMobile">
          <BookingSummaryPrice :price="overallPriceForTrips" @onBackClicked="onBackClicked" />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import BookingSummaryItem from './BookingSummaryItem';
import BookingSummaryVessel from './BookingSummaryVessel';
import BookingSummaryPrice from './BookingSummaryPrice';
// Vuex
import { mapGetters, mapState  } from 'vuex';

export default {
  name: 'BookingSummary',
  components: {
    BookingSummaryItem,
    BookingSummaryVessel,
    BookingSummaryPrice,
  },
  emits: ['onBackClicked'],
  methods: {
    onBackClicked() {
      this.$emit('onBackClicked');
    },
  },
  computed: {
    ...mapState({
      trips: state => state.bookingModule.trips
    }),
    ...mapGetters({
      overallPriceForTrips: 'bookingModule/overallPriceForTrips',
    })
  },
};
</script>
