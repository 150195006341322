<template>
  <div class="booking-flow-box--grey-bg">
    <h4 :class="$style.refundAlt__header" class="booking-flow-box__header">{{ trans('passDet.FHsupport.title.oneCard') }}</h4>
    <span :class="[$style['refundAlt__header-sub'], 'booking-flow-box__subtitle', 'mb16']"><i v-if="!isMobile" class="fh fh-info mr8 mt3" />{{ trans('passDet.FHsupport.subtitle') }}</span>

    <div v-show="!showWaitingState">
      <SelectableCardAlt
        v-if="showCard"
        :amount="currencyStringPre(supportPlusPrice)"
        :imageSrc="supportPlusImageUrl"
        :selected="supportPlusSelected && !hasSupportPlusError"
        :hasError="hasSupportPlusError"
        :isLoading="fetchingForPrices"
        :badgeText="trans('generic.recommended')"
        :errorMsg="supportPlusError"
        :footerMsg="trans('passDet.FHsupport.plus.oneCard.radioButton.text')"
        @click="setSupportPlusSelected({ ancillaryMode: supportPlusOptions.EXTENDED, ancillaryOrder, totalAncillaries: totalAncillaries, ancillaryUIMode: 'single' })"
      >
        <SupportPlusAlt @fetchCompleted="fetchCompleted" />
      </SelectableCardAlt>

      <SelectableCardSimple :selected="!supportPlusSelected" @click="setSupportPlusSelected({ ancillaryMode: supportPlusOptions.STANDARD })" :hasError="hasSupportPlusError">
        {{ trans('passDet.FHsupport.standard.oneCard.radioButton.text.1') }}
        <span :class="[$style.refundAlt__text, $style['refundAlt__text-list'], $style.refundAlt__text__msg, 'text-primary']" @click.stop="showStandardSupportModal = true">
          {{ trans('passDet.FHsupport.standard.oneCard.radioButton.text.2') }}
        </span>
        {{ trans('passDet.FHsupport.standard.oneCard.radioButton.text.3') }}
      </SelectableCardSimple>
    </div>
    <div v-show="showWaitingState">
      <SelectableCardSkeletonAlt />
      <SelectableCardSimpleSkeleton />
    </div>
    <FhModal v-if="showStandardSupportModal" @close="showStandardSupportModal = false" :title="trans('passDet.FHsupport.standard.modal.heading')">
      <template #body>
        <p>{{ trans('passDet.FHsupport.standard.modal.text') }}</p>
        <ul>
          <li>{{ trans('passDet.FHsupport.standard.modal.bullet.1') }}</li>
          <li>{{ trans('passDet.FHsupport.standard.modal.bullet.2') }}</li>
          <li>{{ trans('passDet.FHsupport.standard.modal.bullet.3') }}</li>
          <li>{{ trans('passDet.FHsupport.standard.modal.bullet.4') }}</li>
        </ul>
      </template>
    </FhModal>
  </div>
</template>

<script>
import SelectableCardAlt from '@/components/shared/SelectableCardAlt/SelectableCardAlt.vue';
import SelectableCardSimple from '@/components/shared/SelectableCardSimple/SelectableCardSimple.vue';
import SelectableCardSkeletonAlt from '@/components/shared/SelectableCardAlt/SelectableCardSkeletonAlt.vue';
import SelectableCardSimpleSkeleton from '@/components/shared/SelectableCardSimple/SelectableCardSimpleSkeleton.vue';
import SupportPlus from '@/components/book/supportPlus/SupportPlus.vue';
import SupportPlusAlt from '@/components/book/supportPlusAlt/SupportPlusAlt.vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import { EXTRA_SERVICE_OPTIONS, EXTRA_SERVICE_PROVIDERS } from '@/logic/BL/extraServices';
import { trans, currencyStringPre, fhImage } from '@/filters';
import { eventSupportPlusShown } from '@/logic/services/events/createBookingEvents';

export default {
  name: 'SupportPlusAltComponent',
  components: {
    SelectableCardAlt,
    SelectableCardSkeletonAlt,
    SelectableCardSimpleSkeleton,
    SupportPlus,
    SupportPlusAlt,
    SelectableCardSimple,
  },
  props: {
    ancillaryOrder: {
      type: Number,
    },
    totalAncillaries: {
      type: Number,
    },
  },
  data() {
    return {
      supportPlusOptions: EXTRA_SERVICE_OPTIONS,
      ferryhopperProvider: EXTRA_SERVICE_PROVIDERS.FERRYHOPPER,
      isFetchCompleted: false,
      showStandardSupportModal: false,
    };
  },
  methods: {
    trans,
    currencyStringPre,
    ...mapActions({
      setSupportPlusSelected: 'bookingModule/setSupportPlusSelected',
    }),
    fetchCompleted(value) {
      this.isFetchCompleted = value;
      if (value) eventSupportPlusShown(this.ancillaryOrder, this.totalAncillaries);
    },
  },
  computed: {
    ...mapState({
      fetchingForPrices: (state) => state.uiModule.fetchingForPrices,
      supportPlusPrice: (state) => state.bookingModule.supportPlus.price,
      supportPlusError: (state) => state.bookingModule.supportPlus.error,
    }),
    ...mapGetters({
      supportPlusProvider: 'bookingModule/getSupportPlusProvider',
      supportPlusSelected: 'bookingModule/supportPlusSelected',
      hasSupportPlusError: 'bookingModule/hasSupportPlusError',
    }),
    showWaitingState() {
      return !this.isFetchCompleted && !this.hasSupportPlusError;
    },
    supportPlusImageUrl() {
      return this.hasSupportPlusError ? fhImage('assets/support-plus/DisabledSaver.svg') : fhImage('assets/support-plus/HasSaver.svg');
    },
    showCard() {
      return this.supportPlusProvider === this.ferryhopperProvider || this.hasSupportPlusError;
    },
  },
};
</script>

<style module lang="scss">
@import '../refundAlt/refund-alt.scss';
</style>
