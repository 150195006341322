import { SEARCH_STATUS } from '@/logic/services/results/resultsService';
import { portFullName } from '@/filters';
import { mapGetters } from 'vuex';
import { JOURNEY_MODE } from '@/logic/models/trips/journeyModes';
import { eventUnavailableTrip, eventResultsFinished, eventAltPortSelected, eventAltDateSelected } from '@/logic/services/events/createResultsEvents';
import { ga4ViewItemList } from '@/logic/services/events/EventService';
import { eventViewPromotion, eventSelectPromotion } from '@/logic/services/events/createEcommerceEvents';

export default {
  data() {
    return {
      journeyMode: JOURNEY_MODE,
    };
  },
  methods: {
    portFullName,
    onDirectResultsCompleteEvents() {
      const searchPorts = { origin: this.origin, destination: this.destination };
      if (this.activeIndirectTripsCount > 0) {
        let activeMultisegmentTrips = this.activeIndirectTrips.map((trip) => trip.segments).flat();
        activeMultisegmentTrips = activeMultisegmentTrips.filter((t) => t.subJourney.type === JOURNEY_MODE.MULTISEGMENT);
        ga4ViewItemList(activeMultisegmentTrips, searchPorts, JOURNEY_MODE.MULTISEGMENT);
      }
      ga4ViewItemList(this.displayableDirectTrips, searchPorts, JOURNEY_MODE.DIRECT);

      this.displayableDirectTrips.forEach((trip) => {
        if (!trip.available) eventUnavailableTrip(trip);
        if (trip.fastest || trip.tripLabels.length > 0) eventViewPromotion(trip, searchPorts);
      });
    },
    onIndirectResultsCompleteEvents() {
      eventResultsFinished({ ...this.routeInfo, result_scenario: this.resultScenario });

      const fastestTrip = this.displayableIndirectTrips.find((t) => t.fastest);
      const searchPorts = { origin: this.origin, destination: this.destination };
      if (fastestTrip !== undefined) eventViewPromotion(fastestTrip, searchPorts);

      const segments = this.displayableIndirectTrips.map((trip) => trip.segments).flat();

      segments.forEach((segment) => {
        if (!segment.available) eventUnavailableTrip(segment);
      });
      ga4ViewItemList(segments, searchPorts, this.journeyMode.INDIRECT);
    },
    sendAltPortSelectedEvent(newOrigin, newDestination) {
      const portName = this.destination === newDestination ? portFullName(newOrigin) : portFullName(newDestination);
      const portCode = this.destination === newDestination ? newOrigin : newDestination;
      eventAltPortSelected(this.date, this.origin, this.destination, this.resultScenario, portCode, portName);
    },
    sendAltDateSelectedEvent(newDate) {
      const userAction = this.date.isBefore(newDate) ? 'Future alt date selected' : 'Past alt date selected';
      eventAltDateSelected(this.date, this.origin, this.destination, this.resultScenario, userAction);
    },
    checkForLabelsEvent(trip) {
      if (!trip.fastest && trip.tripLabels.length === 0) return;
      eventSelectPromotion(trip, { origin: this.origin, destination: this.destination });
    },
  },
  computed: {
    ...mapGetters({
      selectedPortsAbbr: 'searchModule/selectedPortsAbbr',
    }),
    routeInfo() {
      let departureDate = this.date ? this.date.format('YYYYMMDD') : '';
      return {
        departure_port_name: portFullName(this.origin),
        destination_port_name: portFullName(this.destination),
        departure_port_code: this.origin,
        destination_port_code: this.destination,
        departure_date: departureDate,
        route_id: `${this.origin}_${this.destination}_${departureDate}`,
      };
    },
    resultScenario() {
      if (this.hasDirectResults) return 'direct';
      if (this.hasIndirectResults && this.hasAlternateDates && this.isChildPortSearch) return 'noDirect_altDates_altPorts_indirect';
      if (this.hasIndirectResults && this.isChildPortSearch) return 'noDirect_altPorts_indirect';
      if (this.hasIndirectResults && this.hasAlternateDates) return 'noDirect_altDates_indirect';
      if (this.hasIndirectResults) return 'noDirect_indirect';
      if (!this.isDirectlyConnected && this.prefetchedIndirectsCount > 0 && this.isChildPortSearch) return 'noConnection_altPorts_indirect';
      if (!this.isDirectlyConnected && this.prefetchedIndirectsCount > 0) return 'noConnection_indirect';
      if (!this.isDirectlyConnected && this.isChildPortSearch) return 'noConnection_altPorts';
      if (this.isChildPortSearch) return 'noDirect_altPorts';
      if (this.hasAlternateDates) return 'noDirect_altDates';
      if (this.directTripsSearchStatus === SEARCH_STATUS.ERROR || this.indirectTripsSearchStatus === SEARCH_STATUS.ERROR) return 'error';
      if (this.hasNoResults) return 'noDirect';
      return 'cannot be calculated';
    },
  },
};
