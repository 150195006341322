<template>
  <div class="islander-code-input" :id="id">
    <div class="islander-code-input__controls">
      <ToggleSwitch :class="'islander-code-input__toggle'" :value="passenger.islanderCodeEnabled" @input="handleToggle" :disabled="isLoading" :text="trans('islandercodebutton')" />
      <InfoModalItem :slug="slug" @openInfoModalClicked="openInfoModalClicked" />
    </div>

    <transition name="slide" appear>
      <div v-if="passenger.islanderCodeEnabled">
        <FhInput
          type="tel"
          :name="name"
          :model-value="passenger.islanderCode"
          @update:modelValue="handleInput"
          rules="required|numeric|length:9"
          :maxlength="islanderCodeRequiredLength"
          :placeholder="trans('islandercodeplaceholder')"
          :iconClass="iconClass"
          :disabled="isLoading"
          :shouldValidateOnEmitter="false"
          :customErrorMessage="hasError && hasIcErrorsBackend ? passenger.islanderCodeMessage : ''"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { eventBookingModalOpened, eventIslanderCodeValidated, eventIslanderCodeValidationFailed } from '@/logic/services/events/createBookingEvents';
import { PASSENGER_OFFER_STATUS } from '@/logic/BL/common/passengerOffers/passengerOffer';
import TripsFilterer from '@/logic/filterers/TripsFilterer';
import { debouncedLong } from '@/logic/helpers/debounce';
import { ISLANDER_CODE_LENGTH } from '@/logic/managers/discounts/IslanderCode/IslanderCode';
import validateIC from '@/logic/managers/discounts/IslanderCode/validateIC';
import GenericErrorCodes from '@/logic/services/GenericErrorCodes';
import { trans } from '@/filters';
import emitter from '@/emitter';
import { nextTick } from 'vue';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'IslanderCodeInput',
  props: {
    firstNameField: { type: String, required: true },
    lastNameField: { type: String, required: true },
    name: { type: String, required: true },
    passenger: { type: Object, required: true },
    id: { type: String, default: '' },
  },
  emits: ['toggleIslanderField', 'islanderDetailsChange', 'input'],
  data() {
    return {
      slug: 'monadikos-arithmos-nisioti',
      islanderCodeRequiredLength: 9,
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      trips: (state) => state.bookingModule.trips,
      errors: (state) => state.validationModule.errors,
    }),
    hasValidIslanderCode() {
      return this.passenger.islanderCodeStatus === PASSENGER_OFFER_STATUS.VALID;
    },
    hasIcErrorsBackend() {
      return this.passenger.islanderCodeStatus === PASSENGER_OFFER_STATUS.INVALID;
    },
    hasError() {
      if (this.isLoading) return false;
      return this.hasIcErrorsBackend || this.errors.some((error) => error.name === this.name);
    },
    iconClass() {
      if (this.isLoading) return 'fh fh-notch fh-spin';
      if (this.hasValidIslanderCode) return 'fh fh-check text-success';
      if (this.hasError) return 'fh fh-notification text-warning';
      return '';
    },
  },
  methods: {
    trans,
    ...mapActions({
      removeError: 'validationModule/removeError',
    }),
    openInfoModalClicked() {
      eventBookingModalOpened('MAN_monadikos_arithmos_nisioti');
    },
    handleToggle(value) {
      this.$emit('toggleIslanderField', value);

      if (!value) {
        this.removeError({ name: this.name });
        this.$emit('islanderDetailsChange', 'islanderCodeStatus', PASSENGER_OFFER_STATUS.UNDETERMINED);
        this.$emit('islanderDetailsChange', 'islanderCodeMessage', '');
        this.$emit('islanderDetailsChange', 'islanderCode', '');
      }
    },
    async handleInput(value) {
      this.$emit('input', value);
      await nextTick();
      this.handleIslanderCodeInput();
    },
    handleIslanderCodeInput() {
      this.$emit('islanderDetailsChange', 'islanderCodeStatus', PASSENGER_OFFER_STATUS.UNDETERMINED);

      if (this.passenger.islanderCode.length !== ISLANDER_CODE_LENGTH) return;

      emitter.$emit('validateField', this.name);

      if (this.errors.some((error) => error.name === this.name)) return;

      emitter.$emit('validateField', this.firstNameField);
      emitter.$emit('validateField', this.lastNameField);

      if (this.errors.some((error) => error.name === this.firstNameField || error.name === this.lastNameField)) return;

      this.validateIslanderCode();
    },
    async validateIslanderCode() {
      // get the first trip that supports islander code discount, if any
      const islanderCodeTrip = TripsFilterer.getIslanderCodeTrip(this.trips);

      if (!islanderCodeTrip) return;

      this.isLoading = true;

      try {
        const [response] = await validateIC(this.passenger, islanderCodeTrip);
        this.setPassengerProperties(response);
        eventIslanderCodeValidated(response, this.passenger.islanderCode);
      } catch (error) {
        this.setPassengerProperties({ status: false, message: GenericErrorCodes.get('generic') });
        eventIslanderCodeValidationFailed(GenericErrorCodes.get('generic'), this.passenger.islanderCode);
      } finally {
        this.isLoading = false;
      }
    },
    setPassengerProperties(response) {
      this.$emit('islanderDetailsChange', 'islanderCodeMessage', response.message);

      if (response.status === true) {
        this.$emit('islanderDetailsChange', 'islanderCodeStatus', PASSENGER_OFFER_STATUS.VALID);
        this.$emit('islanderDetailsChange', 'firstName', response.firstName);
        this.$emit('islanderDetailsChange', 'lastName', response.lastName);
      }

      if (response.status === false) {
        this.$emit('islanderDetailsChange', 'islanderCodeStatus', PASSENGER_OFFER_STATUS.INVALID);
      }
    },
  },
  watch: {
    passenger: {
      handler(newValue, oldValue) {
        if (!this.passenger.islanderCodeEnabled) return;

        if (newValue.firstName !== oldValue.firstName || newValue.lastName !== oldValue.lastName) {
          debouncedLong(this.handleIslanderCodeInput);
        }
      },
      deep: true,
    },
  },
};
</script>
