import { isNull } from '@/logic/helpers/utils';
import { REGEX_FIND_WORD_DAY, REGEX_QUERY_PARAMETERS_KEY_VALUES } from './regexes';

const URL_ENCODED_COMMA = '%2C';
const DAYS_TO_NUM = { SUN: 0, MON: 1, TUE: 2, WED: 3, THU: 4, FRI: 5, SAT: 6 };
const SEARCH = { BY_DAY_NUMBER: 'dayNumber', BY_DAY_NAME: 'dayName' };

let firstTripDistance = 0;
let firstTripDay = 0;
let firstTripExtraDays = 0;

/**
 * Extracts parameters from a string (url)
 * @return {[type]} [description]
 */
export const extractParameters = input => {
  // Search can be done throught the search mask with String '&dates=' included in the url
  // Or through affiliates/managers by using url links that include '&day='
  const isSearchByDate = input.includes('dates');
  let convertedInput = input;

  // The url strings to replace (https://ferryhoppers.myjetbrains.com/youtrack/issue/FH-1374)
  if (!isSearchByDate) convertedInput = replaceDayWordInUrl(input);

  return { ...objectFromQueryParams(convertedInput), isSearchByDate };
};

const replaceDayWordInUrl = input => {
  const urlSegment = { day: 'dates' };
  return input.replace(REGEX_FIND_WORD_DAY, m => urlSegment[m]);
};

const objectFromQueryParams = urlInput => {
  let keyValueParams = {};
  urlInput.replace(REGEX_QUERY_PARAMETERS_KEY_VALUES, (paramPair, paramName, paramValue) => {
    keyValueParams[paramName.toLowerCase()] = paramValue.toUpperCase();
  });

  return keyValueParams;
};

/**
 * Extract url parameters from window.location.href
 * @return {[type]} [description]
 */
export const extractUrlParameters = () => {
  return extractParameters(window.location.href);
};

/**
 * Replace comma (,) by url encoded %2C for backwards compatibility
 * Return array of values
 * @param {string} param
 */
export const splitUrlParamByComma = param => (param && param.replace(/,/g, URL_ENCODED_COMMA).split(URL_ENCODED_COMMA)) || undefined;

// If url includes '&day=1' or '&day=FR' or '&day=FR+2' instead of '&dates=20220815' it must be converted
export const mapDatesFromUrlParams = (dateStrings, isSearchByDate) => {
  return dateStrings.map((dateString, tripDateIndex) => {
    return isSearchByDate ? dateString : getDaysFromUrl(dateString, tripDateIndex);
  });
};

function getDaysFromUrl(dayString, tripDateIndex) {
  const { day, extraDays } = splitComplexDateUrls(dayString);
  switch (day.toUpperCase()) {
    case 'SUN':
      return convertDate(DAYS_TO_NUM.SUN, SEARCH.BY_DAY_NAME, extraDays, tripDateIndex);
    case 'MON':
      return convertDate(DAYS_TO_NUM.MON, SEARCH.BY_DAY_NAME, extraDays, tripDateIndex);
    case 'TUE':
      return convertDate(DAYS_TO_NUM.TUE, SEARCH.BY_DAY_NAME, extraDays, tripDateIndex);
    case 'WED':
      return convertDate(DAYS_TO_NUM.WED, SEARCH.BY_DAY_NAME, extraDays, tripDateIndex);
    case 'THU':
      return convertDate(DAYS_TO_NUM.THU, SEARCH.BY_DAY_NAME, extraDays, tripDateIndex);
    case 'FRI':
      return convertDate(DAYS_TO_NUM.FRI, SEARCH.BY_DAY_NAME, extraDays, tripDateIndex);
    case 'SAT':
      return convertDate(DAYS_TO_NUM.SAT, SEARCH.BY_DAY_NAME, extraDays, tripDateIndex);
    default:
      return convertDate(day, SEARCH.BY_DAY_NUMBER);
  }
}

function splitComplexDateUrls(dayString) {
  if (!dayString.includes('+')) return { day: dayString };

  const splitDayUrl = dayString.split('+');
  const day = splitDayUrl[0];
  const extraDays = isNull(parseInt(splitDayUrl[1])) ? 0 : parseInt(splitDayUrl[1]);
  return { day: day, extraDays: extraDays };
}

const getCurrentDay = date => date.getDay();

export const calculateDistance = (day, search, extraDays, tripDateIndex, date) => {
  const hasExtraDays = !isNull(extraDays);
  extraDays = hasExtraDays ? extraDays : 0;
  const daytoset = day + extraDays;
  const currentDay = getCurrentDay(date);
  let distance = parseInt(day);
  if (search !== SEARCH.BY_DAY_NAME) {
    return distance;
  }

  // Examples:
  // (day=FRI) Current day = FRI (5), daytoset = FRI (5)  => distance = 0
  // (day=SAT) Current day = FRI (5), daytoset = SAT (6)  => distance = 1
  // (day=SUN+2) Current day = MON (1), daytoset = SUN (0)+2  => distance = 1  (results-> 8 days distance)
  // (day=SAT+2) Current day = MON (1), daytoset = SAT (0)+2  => distance = 7  (results-> 7 days distance)
  distance = daytoset - currentDay;
  if (distance < 0) {
    // Examples:
    // (day=SUN) Current day = FRI (5), daytoset = SUN (0)  => distance = -5  (results-> 2 days distance)
    // (day=SUN) Current day = MON (1), daytoset = SUN (0)  => distance = -1  (results-> 6 days distance)
    distance = (daytoset + 7 - currentDay) % 7;
  } else if (distance === 0 && hasExtraDays) {
    if (tripDateIndex > 0) {
      // Not first trip
      // Examples:
      // (day=SUN,SUN+1) Current day = MON (1), daytoset = SUN (0)+1  => firstTripDistance = 6 extraDays=1  (results-> 7 days distance)
      distance = extraDays + firstTripDistance - firstTripExtraDays;
    }
    // Examples:
    // (day=SUN+1) Current day = MON (1), daytoset = SUN (0)+1  => distance = 7  (results-> 7 days distance)
    else {
      distance = 7;
    }
  } else if (distance > 0 && hasExtraDays) {
    if (tripDateIndex > 0) {
      // Not first trip
      // Examples:
      // (day=SUN+1,SUN+2) Current day = MON (1), daytoset = SUN (0)+2  => firstTripDistance = 7 extraDays=2 firstTripExtraDays=1 (results-> 8 days distance)
      distance = extraDays + firstTripDistance - firstTripExtraDays;
    }
    // Examples:
    // (day=MON+1) Current day = MON (1), daytoset = MON (1)+1  => distance = 1  (results-> 1 day distance)    currentDay === day
    // (day=SAT+2) Current day = MON (1), daytoset = SAT (6)+2  => distance = 1  (results-> 1 day distance)
    // (day=THU+6) Current day = MON (1), daytoset = THU (4)+6  => distance = 4  (results-> 4 day distance)
    else if (currentDay === day && (extraDays < 7 || daytoset < 8)) {
      distance = (daytoset + 7 - currentDay) % 7;
    } else if (day < currentDay) {
      // (day=MON+14) Current day = TUE (2), daytoset = MON (1)+14  => distance = 13  (results-> 20 day distance)   day=1, currentDay=2
      // (day=SUN+14) Current day = TUE (2), daytoset = MON (0)+14  => distance = 12  (results-> 19 day distance)   day=0, currentDay=2
      distance = distance + 7;
    }
  }

  // Keep the first trip values in order to calculate the return trip or hopping trips dates
  if (tripDateIndex === 0) firstTripDistance = distance;
  if (tripDateIndex === 0) firstTripExtraDays = extraDays;
  if (tripDateIndex === 0) firstTripDay = day;
  // If the user enters different days should fail. e.g. day=FRI,SUN
  if (tripDateIndex === 1 && firstTripDay !== day) distance = -1;

  return distance;
};

const convertDate = (day, dateSearchMode, extraDays, tripDateIndex) => {
  // distance variable keeps the distance value from current day.
  const date = new Date();
  const distance = calculateDistance(day, dateSearchMode, extraDays, tripDateIndex, date);
  date.setDate(date.getDate() + distance).toString();
  return date
    .toISOString()
    .slice(0, 10)
    .replace(/-/g, '');
};
