import dayjs from '@/logic/services/date/dateService';
import settings from '@/settings'
import { roundPrice } from '@/logic/helpers/utils'
import PortsRepository from '@/logic/repositories/PortsRepository'
import CompaniesRepository from '@/logic/repositories/CompaniesRepository'
import { createTripTimings } from '@/logic/models/trips/createTripTimings'
import { createIDS } from './createIDS'

/**
 * A light version of direct trips, allowing to visualize itineraries
 * without knowledge of classes, discounts and other booking-related information
 * @param {any} data 
 */
function DirectTripModelLight(data) {
  
  this.isDirectTrip = true;
  this.available = data.available;
  this.unavailableReason = '';


  // get company and vessel codes
  let companyCode = data.companies[0].code;
  let vesselCode = data.vessels[0].code;

  // get origin port
  let originCode = data.origin;
  let origin = PortsRepository.getPort(originCode);

  // get destination port
  let destinationCode = data.destination;
  let destination = PortsRepository.getPort(destinationCode);

  // get vessel name
  let vessel = CompaniesRepository.getCompanyVessel(companyCode, vesselCode);
    
  this.Details = {
    DepStationName: origin && origin.short || originCode,
    DepStation: originCode,
    ArrStationName: destination && destination.short || destinationCode,
    ArrStation: destinationCode,
    Company: companyCode,
    CompanyIcon:  CompaniesRepository.getPreferredIcon(vessel),
    CompanyName: CompaniesRepository.getCompanyName(companyCode),
    VesselName: vessel.VesselName,
    VesselID: vesselCode,
    eTicket: CompaniesRepository.hasETicket(companyCode),
    supported: CompaniesRepository.isSupported(companyCode)
  };

  // convert server generated departure and arrival to dayjs objects
  let departure = dayjs(data.departure.date, settings.formats.microApiDateTimeFormat);
  let arrival = dayjs(data.arrival.date, settings.formats.microApiDateTimeFormat);
  this.timings = createTripTimings(departure, arrival, data);
  
  // create ids
  this.IDSCode = createIDS(this);

  this.minPrice = roundPrice(data.minPrice / 100.0, 2) ;

  this.tripAnalysis = {
    before: [],
    between: [],
    after: []
  }
    
  return this;
}

export default DirectTripModelLight;
