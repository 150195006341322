import PortsRepository from '@/logic/repositories/PortsRepository';
import { isNull, notNull } from '@/logic/helpers/utils';

export default {
  data: () => ({
    portWithNoDestinations: null,
    selectedPortInput: null
  }),
  computed: {
    matchingLocations() {
      // when no filter is selected, provide suggestions based current selections
      // or based on popularity
      if (this.filter === '') {
        // Upon deleting the port the lastSuggestion must be set with the previous or next port
        this.setLastSuggestion();
        return this.getSuggestion();
      }
      return this.engine.sync(this.filter, this.siblingPort);
    },

    matchingAllLocations() {
      // Display only the connected routes
      if (!isNull(this.locationsByAlias)) {
        return this.locationsByAlias.filter(port => {
          if (this.siblingPort && this.siblingPort.region) {
            const selectedPort = port.LocationAbbr;
            const selectedSiblingPort = this.siblingPort.LocationAbbr;
            return PortsRepository.isConnectionPossible(port, this.siblingPort) && selectedPort !== selectedSiblingPort;
          }
          return true;
        });
      }
      return [];
    },

    matchingReturnLocations() {
      // Do not show suggestions when user clicks into the first two inputs
      if (this.target === 0 || this.target === 1) return [];
      // Last port input
      const lastReturn = this.selections.filter(el => el.short !== '').pop();
      // Get all the input values except the last one
      const returnPorts = this.selections.filter((val, index) => index < this.target - 1 && val.short !== '');
      // Remove the last port that also appears in another port input as duplicate. e.g Naxos -> Paros -> Naxos -> ... (it should display as returning port Paros)
      const returnPortsNoDuplicates = returnPorts.filter(r => r.LocationAbbr !== lastReturn.LocationAbbr);
      return [...new Set(returnPortsNoDuplicates)];
    },
    isDirectPortsShown() {
      if (!isNull(this.nextPort) && this.nextPort.LocationAbbr) return true;
      if (!isNull(this.previousPort) && this.previousPort.LocationAbbr) return true;
      return false;
    },
    hasReturnLocations() {
      return this.searchMode === 'multi' && this.filter === '' && this.matchingReturnLocations.length > 0;
    },
    hasOnlyAllCategory() {
      return this.filter === '' && this.matchingReturnLocations.length === 0 && this.matchingLocations.length === 0;
    },
    selectedPort() {
      this.selectedPortInput = this.selections.find((s, index) => this.target === index && s.short !== '');
      return isNull(this.selectedPortInput) ? null : this.selectedPortInput.LocationAbbr;
    },
    lastSuggestion() {
      return this.engine.lastSuggestion;
    }
  },
  methods: {
    //------------------------------------------------------------------------
    // provides a suggestion for a focused input when nothing has been typed
    // inside it. Depending on the previous or next location selections, it
    // will return linked locations, otherwise it will return the most popular
    // choises between all of our locations
    getSuggestion() {
      if (this.previousPort && this.previousPort.alias) {
        return this.engine.getDestinationSuggestions(this.previousPort);
      } else if (this.nextPort && this.nextPort.alias) {
        return this.engine.getOriginSuggestions(this.nextPort);
      } else {
        return this.engine.getPopularSuggestions();
      }
    },
    setLastSuggestion() {
      if (this.previousPort && this.previousPort.alias) {
        this.engine.lastSuggestion = this.previousPort;
      } else if (this.nextPort && this.nextPort.alias) {
        this.engine.lastSuggestion = this.nextPort;
      }
    },
    hasNoDestinations(lastSuggestion) {
      if (notNull(lastSuggestion) && isNull(lastSuggestion.destinations)) {
        this.portWithNoDestinations = lastSuggestion;
        return true;
      }
      this.portWithNoDestinations = null;
      return false;
    },
    hasFocusOnSiblingPort(previousPort, nextPort) {
      const previous = notNull(previousPort) ? previousPort.alias : null;
      const next = notNull(nextPort) ? nextPort.alias : null;
      return notNull(this.portWithNoDestinations) ? this.portWithNoDestinations.alias === (previous || next) : false;
    }
  }
};
