/* eslint-disable no-unused-vars */
import { ga4Event } from '@/logic/services/events/EventService';
import { eventStepReached } from '@/logic/services/events/createSharedEvents';
import { getConfigurationNumber, isMobile } from '@/logic/helpers/utils';
import LocaleAnchors from '@/logic/dom/LocaleAnchors';
import { debounce } from '@/logic/helpers/debounce';
import SearchUrlGenerator from '@/logic/models/SearchUrlGenerator';
import { scrollToElementId } from '../../logic/dom/scrollTo';

export const NAVIGATION_TABS = Object.freeze({
  BACK: 'back',
  SEARCH: 'search',
  RESULTS: 'results',
  PASSENGERS: 'passengers',
  BOOK: 'book',
});

const isCancelledBooking = window.bookingCancelled || false;

const findTabById = (tabId) => navigationModule.state.appTabs.find((tab) => tab.id === tabId);

let debouncedCommit;

const refreshTimeout = getConfigurationNumber('modal_refresh_timeout', 90 * 60 * 1000);

export const navigationModule = {
  namespaced: true,
  state: {
    initialLoad: !isCancelledBooking,
    bookingCancelled: isCancelledBooking,
    bookingCancelledReason: window.bookingCancelledReason || '',
    bookingDisabled: window.fh_disable_booking || false,
    bookingDisableNotification: window.fh_active_notification || ' ',
    appReady: false,
    activeTab: NAVIGATION_TABS.SEARCH,
    appTabs: [
      {
        index: 0,
        title: '',
        icon: 'fh fh-arrow',
        id: NAVIGATION_TABS.BACK,
        enabled: true,
        completed: false,
      },
      {
        index: 1,
        title: isMobile() ? 'stepper.mobile.search' : 'stepper.desktop.search',
        icon: 'fh fh-search',
        id: NAVIGATION_TABS.SEARCH,
        enabled: true,
        completed: false,
      },
      {
        index: 2,
        title: isMobile() ? 'stepper.mobile.results' : 'stepper.desktop.results',
        icon: 'fh fh-menu2',
        id: NAVIGATION_TABS.RESULTS,
        enabled: false,
        completed: false,
      },
      {
        index: 3,
        title: isMobile() ? 'stepper.mobile.tickets' : 'stepper.desktop.tickets',
        icon: 'fh fh-users',
        id: NAVIGATION_TABS.PASSENGERS,
        enabled: false,
        completed: false,
      },
      {
        index: 4,
        title: isMobile() ? 'stepper.mobile.passengerDetails' : 'stepper.desktop.passengerDetails',
        icon: 'fh fh-check',
        id: NAVIGATION_TABS.BOOK,
        enabled: isCancelledBooking,
        completed: false,
      },
    ],
    infoModalTitle: '',
    infoModalContent: '',
    shouldAskForPageRefresh: false,
    resultsHash: '',
  },
  getters: {
    bookingCancelledReason: (state) => state.bookingCancelledReason,
    activeTabDetails: (state) => findTabById(state.activeTab),
    isActiveTabSearch: (state, getters) => getters.activeTabDetails.id === NAVIGATION_TABS.SEARCH,
  },
  mutations: {
    setAppReady(state) {
      state.appReady = true;
    },
    setInitialLoad(state, status) {
      state.initialLoad = status;
    },
    setActiveTab(state, tabId) {
      state.activeTab = tabId;
    },
    enableΝavTab(state, tabIndex) {
      state.appTabs[tabIndex].enabled = true;
    },
    setCompletedNavTab(state, payload) {
      const { tabId, completed } = payload;
      let tab = findTabById(tabId);
      tab.completed = completed;
    },
    disableNavTabs(state, tabIds) {
      state.appTabs = state.appTabs.map((tab) => {
        if (tabIds.includes(tab.id)) {
          tab.enabled = false;
          tab.completed = false;
        }
        return tab;
      });
    },
    resetCancelledFlag(state) {
      state.bookingCancelled = false;
    },
    updateModal(state, values) {
      state.infoModalTitle = values.title;
      state.infoModalContent = values.content;
    },
    shouldAskForPageRefresh(state, value) {
      state.shouldAskForPageRefresh = value;
    },
    saveResultsHash(state, value) {
      state.resultsHash = value;
    },
  },
  actions: {
    setInitialLoad({ commit }, payload) {
      commit('setInitialLoad', payload);
    },
    setTimer({ commit, state }) {
      if (!debouncedCommit) {
        debouncedCommit = debounce(() => {
          if (state.activeTab === 'results' || state.activeTab === 'passengers' || state.activeTab === 'book') {
            commit('shouldAskForPageRefresh', true);
            ga4Event('refresh_modal_shown', {});
          }
        }, refreshTimeout);
      }
      debouncedCommit();
    },
    updateInfoModal({ commit }, values) {
      commit('updateModal', values);
    },
    enableΝavTab({ commit }, tabId) {
      let tab = findTabById(tabId);
      commit('enableΝavTab', tab.index);
    },
    setCompletedNavTab({ commit }, payload) {
      commit('setCompletedNavTab', payload);
    },
    disableNavTabs({ commit }, tabIds) {
      commit('disableNavTabs', tabIds);
    },
    changeNavTab({ commit, state, dispatch, rootState }, tabId) {
      let tab = state.appTabs.find((tab) => tab.enabled === true && tab.id === tabId);
      if (!tab) return;

      let hash = `#${tabId}`;
      // specifically add search url in results
      if (tabId === 'results') {
        const searchUrlParams = new SearchUrlGenerator().createUrlFromSearches(rootState.searchModule.searchQueries);
        hash = `#${tabId}${searchUrlParams}`;

        // Configure locale anchor elements to redirect to the results tab.
        LocaleAnchors.replaceHashAll(hash);

        // save results hash to use it for refresh modal.
        commit('saveResultsHash', hash);
      }

      history.pushState(null, null, hash);

      scrollToElementId('navtop');

      if (tabId === 'search') {
        // Configure locale anchor elements to redirect to the search tab.
        LocaleAnchors.replaceHashAll(hash);
      }

      commit('setActiveTab', tabId);
      eventStepReached();

      // setTimer for asking to refresh page modal when loading results tab.
      if (tabId === 'results') dispatch('setTimer');
    },
    handleNavTabBackClick({ state, getters, dispatch }) {
      const newTabIndex = getters.activeTabDetails.index - 1;
      if (newTabIndex === 0) return;
      const newTabId = state.appTabs.find((tab) => tab.index === newTabIndex).id;
      dispatch('changeNavTab', newTabId);
    },
  },
};
