<template>
  <div>
    <div :class="['location-selection-list__title location-selection-list__title--grey', { pt14: isMobile }]">{{ trans('nosuchport') }}</div>
    <div class="location-selection-list__item location-selection-list__item--direct" @click="onSelect(lastSuggestion.LocationAbbr, lastSuggestion.alias)">
      <span class="location-selection-list__item--highlight">{{ lastSuggestion.alias }}</span>
      <span class="location-selection-list__item__country">{{ lastSuggestion.countryName }}</span>
    </div>
  </div>
</template>

<script>
import { eventSearchWrongInput } from '@/logic/services/events/createSearchEvents';
import { trans } from '@/filters';

export default {
  name: 'NotFoundLocationSearchList',
  props: {
    lastSuggestion: Object,
    lastUserInput: String
  },
  mounted() {
    eventSearchWrongInput(this.lastSuggestion, this.lastUserInput);
  },
  emits: ['onListItemClick'],
  methods: {
    trans,
    onSelect(abbreviation, alias) {
      //0 is sent as index as this component has only one list item
      this.$emit('onListItemClick', abbreviation, alias, 'notFoundLocations', 0);
    }
  }
};
</script>
