<template>
  <div class="ae-primary-filled-box">
    <div class="ae-primary-filled-box__heading">
      <span>{{ trans('contactdetails') }}<InfoModalItem :slug="'personal-data-ticket'" /></span>
    </div>
    <div class="ae-primary-filled-box__body">
      <FhInput
        name="reservationEmail"
        id="reservationEmail"
        :placeholder="trans('generic.email')"
        :model-value="modelValue.Email"
        @update:modelValue="update('Email', $event.trim())"
        rules="required|email|min:6|max:190"
        :shouldShowError="false"
      />

      <div class="contact-details-phone pt10">
        <div class="contact-details-phone__left mr6">
          <CountryCodeInput
            :value="modelValue.PhoneCountryCode"
            id="countryCode"
            :name="`country-code`"
            :rules="'required'"
            :placeholder="trans('passDet.contact.countryCode.placeholder')"
            @update:modelValue="update('PhoneCountryCode', $event)"
          />
        </div>
        <div class="contact-details-phone__right">
          <FhInput
            class="contact-details__phone"
            name="reservationPhone"
            id="reservationPhone"
            :placeholder="trans('passDet.contact.phone.placeholder')"
            :model-value="modelValue.Phone"
            @update:modelValue="update('Phone', $event)"
            rules="required|numericAllowDashes|customPhone"
            :sanitizingFunction="replaceSpaceWithDash"
            :shouldShowError="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountryCodeInput from '@/components/shared/inputs/CountryCodeInput';
import { updateObjectWithProp } from '@/logic/helpers/objectManipulation';
import { trans } from '@/filters';
import { replaceSpaceWithDash } from '@/logic/helpers/stringUtils';

export default {
  name: 'AegeanContactDetailsForm',
  components: {
    CountryCodeInput,
  },
  props: {
    modelValue: Object,
  },
  emits: ['update:modelValue'],
  methods: {
    trans,
    replaceSpaceWithDash,
    update(key, value) {
      this.$emit('update:modelValue', updateObjectWithProp(this.modelValue, key, value ? value : ''));
    },
  },
};
</script>
