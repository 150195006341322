import { ga4Event } from './EventService';
import { eventInfoModalOpened } from './createSharedEvents';
import { eventPricingError } from './createPricingEvents';
import { tripToTripInfo } from './sharedAdapters';
import { isNull } from '@/logic/helpers/utils';
import { getConfigurationValue } from '@/logic/helpers/utils';

export const eventClickedOnBackButton = () => {
  //KilledEvent(EVENTS_FAMILY, 'Clicked on back button');
};

export const eventWrongBookingDetails = (field, message) => {
  ga4Event('validation_error_shown', {
    field_name: field,
    error_description: message,
  });
};

export const eventCheckoutCouponFailed = () => {
  ga4Event('validation_error_shown', { field_name: 'Coupon' });
};

export const eventCheckoutPricingFailed = () => {
  ga4Event('pricing_error_shown', { error_description: 'non-priced trips' });
};

export const eventCheckoutBookingRestrictionError = (restriction) => {
  ga4Event('pricing_error_shown', { error_description: restriction });
};

export const eventCheckoutBookingValidationError = (error) => {
  ga4Event('validation_error_shown', {
    error_description: error,
  });
};

export const eventValidationErrorShown = (fieldName, fieldValue, errorMessage) => {
  ga4Event('validation_error_shown', {
    field_name: fieldName,
    field_value: fieldValue,
    error_description: errorMessage,
  });
};

export const eventSelectedRefundProtect = (provider, amount, ancillaryOrder, totalAncillaries, ancillaryUIMode) => {
  ga4Event('ancillary_added_to_cart', {
    user_action: `added_cancellation-protection-${provider}_${ancillaryUIMode}_${ancillaryOrder}_${totalAncillaries}`,
    trip_basket_value: amount,
    ancillary_name: `CancellationProtection-${provider}`,
  });
};

export const eventSelectedTravelInsurance = (provider, amount, ancillaryOrder, totalAncillaries, ancillaryUIMode) => {
  ga4Event('ancillary_added_to_cart', {
    user_action: `added_travel-insurance-${provider}_${ancillaryUIMode}_${ancillaryOrder}_${totalAncillaries}`,
    trip_basket_value: amount,
    ancillary_name: `TravelInsurance-${provider}`,
  });
};

export const eventSelectedSupportPlus = (amount, ancillaryOrder, totalAncillaries, ancillaryUIMode) => {
  ga4Event('ancillary_added_to_cart', {
    user_action: `added_support-plus_${ancillaryUIMode}_${ancillaryOrder}_${totalAncillaries}`,
    trip_basket_value: amount,
    ancillary_name: 'SupportPlus',
  });
};

export const eventSelectedFlexi = (amount, ancillaryOrder, totalAncillaries, ancillaryUIMode) => {
  ga4Event('ancillary_added_to_cart', {
    user_action: `added_flexi_${ancillaryUIMode}_${ancillaryOrder}_${totalAncillaries}`,
    trip_basket_value: amount,
    ancillary_name: 'Flexi',
  });
};

export const eventTravelInsuranceShown = (provider, ancillaryOrder, totalAncillaries) => {
  // ga4Event('ancillary_shown_to_user', {
  //   user_action: `shown_travel-insurance-${provider}_${getAncillaryUiMode()}_${ancillaryOrder}_${totalAncillaries}`,
  //   ancillary_name: `TravelInsurance-${provider}`,
  // });
};

export const eventRefundProtectShown = (provider, ancillaryOrder, totalAncillaries) => {
  // ga4Event('ancillary_shown_to_user', {
  //   user_action: `shown_cancellation-protection-${provider}_${getAncillaryUiMode()}_${ancillaryOrder}_${totalAncillaries}`,
  //   ancillary_name: `CancellationProtection-${provider}`,
  // });
};

export const eventSupportPlusShown = (ancillaryOrder, totalAncillaries) => {
  // ga4Event('ancillary_shown_to_user', {
  //   user_action: `shown_support-plus_${getAncillaryUiMode()}_${ancillaryOrder}_${totalAncillaries}`,
  //   ancillary_name: `SupportPlus`,
  // });
};

export const eventFlexiShown = (ancillaryOrder, totalAncillaries) => {
  // ga4Event('ancillary_shown_to_user', {
  //   user_action: `shown_flexi_${getAncillaryUiMode()}_${ancillaryOrder}_${totalAncillaries}`,
  //   ancillary_name: 'Flexi',
  // });
};

export const eventSaveDetailsClicked = (eventLabel) => {
  ga4Event('save_contact_details_switch_clicked', {
    value_of_switch: eventLabel ? 'On' : 'Off',
  });
};

export const eventIslanderCodeValidated = (response, value) => {
  if (response.status) {
    ga4Event('man_validated', {});
    return;
  }
  ga4Event('validation_error_shown', {
    field_name: 'islanderCode',
    field_value: value,
    error_description: response.message,
  });
};

export const eventIslanderCodeValidationFailed = (error, value) => {
  ga4Event('validation_error_shown', {
    field_name: 'islanderCode',
    error_description: error,
    field_value: value,
  });
};

export const eventBookingModalOpened = (modalName, trip) => {
  if (isNull(trip)) {
    eventInfoModalOpened(`Passenger Details:${modalName}`);
    return;
  }
  eventInfoModalOpened(`Passenger Details:${modalName}`, { ...tripToTripInfo(trip) });
};

export const eventBookingPricingError = (trip, pricingResponse) => {
  const { provider } = trip;
  eventPricingError(trip, pricingResponse, provider);
};

export const eventSavedPassengerSelected = () => {
  ga4Event('saved_passenger_selected', {});
};

export const eventNewPassengerSelected = () => {
  ga4Event('unload_a_selected_saved_passenger', {});
};

export const eventSavedVehicleSelected = () => {
  ga4Event('saved_vehicle_selected', {});
};

export const eventNewVehicleSelected = () => {
  ga4Event('unload_a_selected_saved_vehicle', {});
};

const getAncillaryUiMode = () => {
  return getConfigurationValue('ancillaries_cards_design', 'multiple');
};
