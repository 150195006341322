<template>
  <div class="booking-flow-box--grey-bg">
    <h4 class="booking-flow-box__header">{{ trans('passDet.ticketFlexibility.title') }}</h4>
    <span class="booking-flow-box__subtitle mb24"><i v-if="!isMobile" class="fh fh-info mr8 mt3" />{{ trans('passDet.ticketFlexibility.subtitle') }}</span>

    <div :class="$style.refund__cards">
      <SelectableCard
        :title="trans('passDet.ticketFlexibility.standard.title')"
        :amount="currencyStringPre(standardPrice)"
        :footerMsg="trans('generic.noExtraCharge')"
        :imageSrc="standardImageUrl"
        :selected="!flexiSelected"
        @click="setFlexiSelected({ ancillaryMode: flexiOptions.STANDARD })"
      >
        <StandardSupport />
      </SelectableCard>

      <div v-show="showWaitingState">
        <SelectableCardSkeleton />
      </div>
      <div v-show="!showWaitingState">
        <SelectableCard
          v-if="flexiProvider === ferryhopperProvider"
          :title="trans('passDet.ticketFlexibility.flexi.title')"
          :amount="currencyStringPre(flexiPrice)"
          :footerMsg="trans('generic.inTotal')"
          :imageSrc="flexiImageUrl"
          :selected="flexiSelected"
          @click="setFlexiSelected({ ancillaryMode: flexiOptions.EXTENDED, ancillaryOrder, totalAncillaries, ancillaryUIMode: 'multiple' })"
          :badgeText="trans('generic.recommended')"
          :hasError="hasFlexiError"
          :isLoading="fetchingForPrices"
        >
          <Flexi @fetchCompleted="fetchCompleted" />
        </SelectableCard>

        <SelectableCard
          v-if="hasFlexiError"
          :title="trans('passDet.ticketFlexibility.flexi.title')"
          :amount="currencyStringPre(standardPrice)"
          :footerMsg="trans('generic.inTotal')"
          :imageSrc="fhImage('assets/flexi/DisabledFlexi.svg')"
          :hasError="hasFlexiError"
          :errorMsg="flexiError"
        >
        </SelectableCard>
      </div>
    </div>
  </div>
</template>

<script>
import SelectableCard from '@/components/shared/SelectableCard/SelectableCard.vue';
import SelectableCardSkeleton from '@/components/shared/SelectableCard/SelectableCardSkeleton.vue';
import Flexi from '@/components/book/flexi/Flexi.vue';
import StandardSupport from '@/components/book/flexi/StandardSupport.vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import { EXTRA_SERVICE_OPTIONS, EXTRA_SERVICE_PROVIDERS } from '@/logic/BL/extraServices';
import { trans, currencyStringPre, fhImage } from '@/filters';
import { eventFlexiShown } from '@/logic/services/events/createBookingEvents';

export default {
  name: 'FlexiComponent',
  components: {
    SelectableCard,
    SelectableCardSkeleton,
    Flexi,
    StandardSupport,
  },
  props: {
    ancillaryOrder: {
      type: Number,
    },
    totalAncillaries: {
      type: Number,
    },
  },
  data() {
    return {
      standardPrice: 0,
      flexiOptions: EXTRA_SERVICE_OPTIONS,
      ferryhopperProvider: EXTRA_SERVICE_PROVIDERS.FERRYHOPPER,
      isFetchCompleted: false,
    };
  },
  methods: {
    trans,
    currencyStringPre,
    fhImage,
    ...mapActions({
      setFlexiSelected: 'bookingModule/setFlexiSelected',
    }),
    fetchCompleted(value) {
      this.isFetchCompleted = value;
      if (value) eventFlexiShown(this.ancillaryOrder, this.totalAncillaries);
    },
  },
  computed: {
    ...mapState({
      fetchingForPrices: (state) => state.uiModule.fetchingForPrices,
      flexiPrice: (state) => state.bookingModule.flexi.price,
      flexiError: (state) => state.bookingModule.flexi.error,
    }),
    ...mapGetters({
      flexiProvider: 'bookingModule/getFlexiProvider',
      flexiSelected: 'bookingModule/flexiSelected',
      hasFlexiError: 'bookingModule/hasFlexiError',
    }),
    showWaitingState() {
      return !this.isFetchCompleted && !this.hasFlexiError;
    },
    standardImageUrl() {
      return fhImage('assets/flexi/Standard.svg');
    },
    flexiImageUrl() {
      return this.hasFlexiError ? fhImage('assets/flexi/DisabledFlexi.svg') : fhImage('assets/flexi/Flexi.svg');
    },
  },
};
</script>

<style module lang="scss">
@import '../refund/refund.scss';
</style>
