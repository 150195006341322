import PricingPipe from '@/logic/pipes/PricingPipe';
import { tripSupportsEarlyBooking } from '../../managers/discounts/EarlyBooking';

export const createPricingRequest = (trip, selectedSpecialOffers) => {
  const { passengers, vehicles, pets } = trip;
  const { earlyBooking } = selectedSpecialOffers;
  const wantsEarlyBooking = earlyBooking && tripSupportsEarlyBooking(trip);

  return PricingPipe.begin(trip)
    .process(trip, passengers, vehicles, pets)
    .getPricingRequest(trip, { wantsEarlyBooking });
};
