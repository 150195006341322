<template>
  <div :class="[selectableCardAltClass, 'mb12']" @click="handleClick">
    <div :class="$style.selectableCardAlt__footer">
      <div :class="[disabledClass, $style['selectableCardAlt__radio']]">
        <FhRadio :checked="selected" :disabled="isLoading" class="pt12" />
        <div :class="$style['selectableCardAlt__radio-text']">
          <span :class="[$style['selectableCardAlt__footer-msg'], 'pl8']"
            >{{ footerMsg }}&nbsp;|&nbsp;<i v-if="isLoading" class="fh fh-notch3 fh-spin mx8"></i><span v-else :class="$style['selectableCardAlt__amount']">+{{ amount }}</span>
            <span :class="$style['selectableCardAlt__footer-msg__total']">&nbsp;{{ trans('generic.inTotal') }}</span>
          </span>
        </div>
      </div>
      <span v-if="badgeText !== ''" :class="[$style['selectableCardAlt__badge'], disabledClass]">{{ badgeText }}</span>
    </div>
    <div :class="$style.selectableCardAlt__main">
      <div v-if="hasError" :class="$style.selectableCardAlt__error">
        <i class="fh fh-notification mr10"></i>
        <p class="m0">{{ errorMsg }}</p>
      </div>
      <slot v-else></slot>
      <div :class="$style.selectableCardAlt__image"><img :src="imageSrc" /></div>
    </div>
  </div>
</template>

<script>
import { trans } from '@/filters';

export default {
  name: 'SelectableCardAlt',
  props: {
    selected: {
      type: Boolean,
      default: false
    },
    amount: {
      type: String,
      default: ''
    },
    imageSrc: {
      type: String,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    badgeText: {
      type: String,
      default: ''
    },
    hasError: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: ''
    },
    footerMsg: {
      type: String
    }
  },
  emits: ['click'],
  methods: {
    trans,
    handleClick() {
      this.$emit('click');
    }
  },
  computed: {
    selectableCardAltClass() {
      const selectedClass = this.selected ? this.$style['selectableCardAlt--selected'] : '';
      const noEventsClass = this.hasError ? this.$style['selectableCardAlt--no-events'] : '';
      return `${this.$style.selectableCardAlt} ${selectedClass} ${noEventsClass}`;
    },
    disabledClass() {
      return this.hasError ? this.$style['selectableCardAlt--disabled'] : '';
    }
  }
};
</script>

<style module lang="scss">
@import './selectable-card-alt.scss';
</style>
