<template>
  <div>
    <div class="aegean-cart-item" v-if="isSelected">
      <div class="aegean-cart-item-heading">
        <h3 class="aegean-cart-item-title">
          <JourneyTitle :ports="[departureLocation, arrivalLocation]" paddingClass="pt3" />
        </h3>
        <span class="aegean-cart-item-date">{{ trip.timings.DepDateStr }} - {{ firstSegment.timings.DepTime }}</span>
      </div>
      <div class="aegean-cart-item-details">
        <template v-for="(segment, index) in segments" :key="index">
          <div class="aegean-cart-item-vessel-row">
            <div class="aegean-cart-item-company-logo">
              <img :src="segment.Details.CompanyIcon" :alt="segment.Details.CompanyName" @error="setDefaultImage" />
            </div>
            <span class="aegean-cart-item-vessel-name">{{ segment.Details.VesselName }}</span>
          </div>
        </template>
      </div>
      <div class="aegean-cart-item-seating-analysis" v-if="seats">
        <span class="block" v-for="(selection, selectionIndex) in trip.SeatingAnalysis" :key="'seat-selection-item-' + selectionIndex">
          <span v-html="selection.icon" v-if="false"></span> {{ selection.occupantIndexes.length }}x {{ selection.description }}
        </span>
      </div>
    </div>
    <div class="aegean-cart-item thin" v-if="isSelected">
      <div class="aegean-cart-item-price-row clearfix">
        <span class="cart-price-text cart-price-label pull-left">{{ trans('tripprice') }}</span>
        <span class="cart-price-text cart-price-value pull-right" :class="{ invisible: hideTripPrice }" v-html="aegeanCurrency(price)"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { isNull, notNull } from '@/logic/helpers/utils';
import { trans, aegeanCurrency, setDefaultImage } from '@/filters';

export default {
  name: 'AegeanCartItem',
  props: {
    seats: Array,
    trip: Object,
    showOverallPrice: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    trans,
    aegeanCurrency,
    setDefaultImage,
  },
  computed: {
    isSelected() {
      return notNull(this.trip);
    },
    firstSegment() {
      return this.segments[0];
    },
    // When showing overall price (instead of minimum trip price that is the
    // default), we can hide the price until it becomes verified (eg after
    // price verification)
    hideTripPrice() {
      return isNull(this.trip.OverallPrice) && this.showOverallPrice;
    },
    price() {
      if (!this.trip) {
        return undefined;
      }
      return true === this.showOverallPrice ? this.trip.OverallPrice : this.trip.minPrice;
    },
    departureLocation() {
      return this.trip.Details.DepStationName;
    },
    arrivalLocation() {
      return this.trip.Details.ArrStationName;
    },
    segments() {
      return [this.trip];
    },
  },
};
</script>
