/* eslint-disable no-unused-vars */

import { sortBy as _sortBy } from 'lodash-es';
import PortsRepository from '@/logic/repositories/PortsRepository';

//------------------------------------------------------------------------
// Location rating is the core sorting mechanism for suggesting locations
// according to the users input, used both in mobile and desktop version.
// Given a list of ports (filtered out a bit according to their alternates
// description), this function will provide a rating on these ports using
// extra checks
var SuggestionsEngine = function(locations, limit) {
  this.lastSuggestion = undefined;
  this.limit = limit;
  this.filterStr = '';

  // initialize locations array only if locations list is provided
  // during construction
  if (locations.length > 0) {
    this.setLocations(locations);
  }
};

SuggestionsEngine.prototype = {
  //----------------------------------------------------------------------
  createLocationRating(location, filter) {
    let score = 0;

    // matching alias exactly matching filter gets a head start
    if (location.alias.toLowerCase() === filter) {
      score += 1000;
    }

    // If the alias of a location starts with what the user is typing
    if (location.alias.toLowerCase().startsWith(filter)) {
      score += 1000;
    }

    // If location alternates starts with filter
    if (location.alternates.startsWith(filter)) {
      score += 100;
    }

    // If any location alternate starts filter
    if (location.alternates.includes(`, ${filter}`)) {
      score += 20;
    }

    // If filter is anyhow included in the full string of alternates
    if (location.alternates.includes(filter)) {
      score += 10;
    }

    // add popularity to distinguish between equally rated locations
    score += location.popularity;

    // bring ports of same regions (direct or indirect connection) higher on the list
    if (location.isConnectionPossible) {
      score += 5000;
    }

    // second check
    // If a connection between a location and a target location exists
    if (location.direct) {
      score += 1000;
    }

    return score;
  },
  //----------------------------------------------------------------------
  // provide a list of locations for the suggestions engine. These are the
  // direct output of PortsRepository
  setLocations(locations) {
    this.locations = locations;
  },
  //----------------------------------------------------------------------
  // returns only the locations that contain the filter string in the
  // fulldescription, in order to rule out unwanted locations
  filterByQuery() {
    return this.locations.filter(location => {
      return location.alternates.includes(this.filterStr) || location.LocationAbbr.toLowerCase() === this.filterStr;
    });
  },
  //----------------------------------------------------------------------
  // changes the order of a locations list, based on the rating these
  // obtain in the createLocationRating function
  orderByRating(ports, target) {
    const updatedPortsForTarget = ports.map(p => {
      let isConnectionPossible = target && target.region ? PortsRepository.isConnectionPossible(p, target) : true;
      let direct = target && target.destinations ? PortsRepository.isDirectlyConnected(p, target) : true;

      return {
        ...p,
        direct,
        isConnectionPossible
      };
    });
    return _sortBy(updatedPortsForTarget, p => this.createLocationRating(p, this.filterStr)).reverse();
  },
  //----------------------------------------------------------------------
  // given two ports, this function will return true if the root port has a
  // connection either with the target port, or with it's parent island
  areConnected(origin, destination, mode) {

    if (mode === 'origin') {
      return destination.destinationPortCodes.includes(origin.LocationAbbr) || destination.destinationPortCodes.includes(origin.parentIsland);
    }

    if (mode === 'destination') {
      return origin.destinationPortCodes.includes(destination.LocationAbbr) || origin.destinationPortCodes.includes(destination.parentIsland);
    }

    return false;
  },
  // returns the list of popular ports to show to the user
  getPopularSuggestions() {
    // return the popular suggestions, but by reseting the isConnection possible
    // flag, since we use popular suggestions when no other port is selected as an
    // origin or destination
    const popularPorts = PortsRepository.getPopularPorts();

    return this.toShortList(popularPorts);
  },
  getSuggestionsForPort(input, mode) {
    const suggestionsForPort = _sortBy(this.locations, l => l.popularity)
      .reverse()
      .filter(location => {
        return mode === 'origin' ? this.areConnected(location, input, mode) : this.areConnected(input, location, mode);
      });
    return this.toShortList(suggestionsForPort);
  },
  toShortList(portsList) {
    return portsList.slice(0, this.limit).map(location => ({
      ...location,
      isConnectionPossible: true,
      direct: true
    }));
  },
  //----------------------------------------------------------------------
  // returns the list of suggestions that lead to a destination
  // based on popularity
  getDestinationSuggestions(input) {
    return this.getSuggestionsForPort(input, 'destination');
  },
  //----------------------------------------------------------------------
  // returns the list of suggestions that are linked to an origin
  // based on popularity
  getOriginSuggestions(input) {
    return this.getSuggestionsForPort(input, 'origin');
  },
  //----------------------------------------------------------------------
  // performs the syncing between what the user types and the locations
  // that are currently loaded. The target is another port that the
  // user has selected, and it is used to bring directly connected locations
  // at the top of the suggestions list
  sync(query, target) {
    this.filterStr = query.toLowerCase();

    // filter by query, then order by rating, the splice
    let results = this.orderByRating(this.filterByQuery(), target).slice(0, this.limit);
    // when we have results, we store the last of them for reference
    if (results.length > 0) {
      this.lastSuggestion = results[0];
    }
    return results;
  }
};

export default SuggestionsEngine;
