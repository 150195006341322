import { keys as _keys } from 'lodash-es'
import { isDeckOrSeatType } from '@/logic/filterers/SeatOptionsFilterer'
import settings from '@/settings'
import { isPassengerSeating } from '../models/pricing/seatingAnalysisItem';

export default {

    /**
     * Check if the SeatingAnalysis for a given list of passengerAccommodations has an error 
     * related to exceeding the availability of an accommodation. At the moment, cabins and cars 
     * are not taken into account 
     * 
     * @param {Object[]} seatingAnalysisItems SeatingAnalysis from the PricingPipe
     * @param {Accommodation[]} passengerAccommodations passengerAccommodations array of a DirectTripModel
     * @param {boolean} returnSelectionsArray Boolean flag to return complete results or not
     */
    checkSelectionsAvailability (seatingAnalysisItems, passengerAccommodations, returnSelectionsArray) {
        
        // remove vehicles since we do not know the exact seat availability
        // and map the rest with code and requested quantity
        let selectedSeats = this.mapSelections(seatingAnalysisItems, passengerAccommodations);

        let availabilityExceeded = false;
        let selections = [];
        selectedSeats.forEach((seat) => {   
            // add seat selection to a key=>value array with abbreviation
            selections[seat.abbreviation] = (selections[seat.abbreviation] || seat);
            // increase requested quantity each time a new item with this abbreviation is found on the seat selections
            selections[seat.abbreviation].requested = (selections[seat.abbreviation].requested || 0) + seat.quantity;
            selections[seat.abbreviation].description = seat.description;
            // if any selection quantity is larger than the availability, set the availabilityExceeded flag to true
            if (selections[seat.abbreviation].requested > selections[seat.abbreviation].availability) {
                availabilityExceeded = true;
                selections[seat.abbreviation].exceeded = true;
            }
        });
        
        return (true === returnSelectionsArray) ? selections : availabilityExceeded;
    },

    /**
     * Returns a { hasExceededAvailabilityError: bool, seatDescription: string }
     * structure that describes if the current selections for the given accommodations have 
     * any error in accommodationCapacity (= a class accommodationCapacity is exceeded)
     * 
     * The returned description is the description of the exceeded seatType
     * 
     * @param {Accommodation[]} passengerAccommodations 
     */
    validateSelections: function (seatingAnalysisItems, passengerAccommodations) {

        // create the response object
        let result = {
            hasExceededAvailabilityError: false,
            seatDescription: ''
        };

        // perform availability checking
        let selections = this.checkSelectionsAvailability(seatingAnalysisItems, passengerAccommodations, true);
        // get the current selection keys
        let selectionKeys = _keys(selections);
        
        // loop through selections and check if there is an accommodation that exceeds availability
        selectionKeys.some((key) => {
            if (selections[key].exceeded === true) {
                result.hasExceededAvailabilityError = true;
                result.seatDescription = selections[key].seatDescription
                return true;
            }
        });

        return result;
    },

    /**
     * Map seating analysis selections into structures that contain abbreviation, seatType, quantity, and availability
     * 
     * @param {Accommodation[]} passengerAccommodations 
     */
    mapSelections: function (seatingAnalysisItems, passengerAccommodations) {
        let mappedSelections = seatingAnalysisItems.
            filter((item) => (isPassengerSeating(item))).
            // map selections into a simpler structure
            map((seatSelection) => {  
    
                if (seatSelection.code === settings.constants.NO_SELECTION) {
                    return seatSelection
                }

                // get the actual availability for the selected accommodation (by abbreviation)
                // on infants, we use InfantAbbr while on adults we use the ClassAbbr
                let accommodation = (!seatSelection.isInfantGroup) ? 
                    passengerAccommodations.find((c) => c.ClassAbbr === seatSelection.code) :
                    passengerAccommodations.find((c) => c.InfantAbbr === seatSelection.code);
                    
                // return a structure that contains requested quantity and current availability,
                // along with the abbreviation and seatType that will help us filter out garages and cabins
                return {
                    abbreviation: seatSelection.code,
                    quantity: seatSelection.quantity,
                    seatType: accommodation && accommodation.seatType,
                    availability: accommodation && accommodation.AvailWhole,
                    seatDescription: accommodation.seatDescription
                };
            }).
            // filter out cabins and garages since we cannot directly correlate availability 
            // explanation: cabins have male, female, whole & garages have no availability in numbers
            filter((item) => isDeckOrSeatType(item.seatType));
        
        return mappedSelections;
    }
}