import { logException } from '@/logic/services/events/errorLogging'
import { extractUrlParameters, splitUrlParamByComma } from '../../helpers/urlUtils';

// instance of cart, since we need this to be a singleton object during
// a user session
let instance = null;


var UrlParametersManager = function() {
  if (instance !== null) {
    throw new Error('Use UrlParametersManager.getInstance()');
  }

  this.initialUrlParameters = undefined;
}

UrlParametersManager.newInstance = function() {
  if (null === instance) {
    instance = new UrlParametersManager();
  }
  return instance;
};


/**
 * Prototype definition of UrlParametersManager
 */
UrlParametersManager.prototype = {
  /**
    Fetch and store the url parameters on initial page load.
    This function only runs once on page loading, and removes uneccessary
    parameters from the url to produce meaningful data from url, and create
    a more appealing visual outcome
   */
  getParameters() {
    try {
      if (typeof this.initialUrlParameters === 'undefined') {
        this.initialUrlParameters = extractUrlParameters();
      }
    } catch (exception) {      
      logException('UrlParametersManager: Unparsable parameters', exception)
    }

    return this.initialUrlParameters;
  },
  /**
   * Cleans the initial landing page url from any extra url parameters that
   * exist on the users browsers. This way, we end up with clean urls in all
   * subsequent actions
   *
   */
  cleanUrl() {
    try {
      // get current window location
      let uri = window.location.toString();

      // check if url parameters exist, and if so, trim them using
      // historyReplace so that the browser won't refresh
      if (uri.indexOf('?') > 0) {
        let clean_uri = uri.substring(0, uri.indexOf('?'));
        history.replaceState && history.replaceState({}, document.title, clean_uri);
      } else if (uri.indexOf('#') > 0) {
        let clean_uri = uri.substring(0, uri.indexOf('#'));
        history.replaceState && history.replaceState({}, document.title, clean_uri);
      }
    } catch (exception) {
      // non-critical action, hence we do not need to handle errors here
    }
  },

  /*
    Create a search data structure from url parameters. Using this structure,
    we can force initial search. The format that must be provided
    to the outside world is:

    let initialSearch = {
      locations: ['PIR', 'AEG', 'PIR'],
      dates: ['20181031', '20181031']
    };

    We can achieve this by creating a url with these parameters:
    ?itinerary=PIR,JNX,PAS,PIR&dates=20181111,20181112,20181111

  */
  getSearchParameters() {
    
    try {
      let urlParameters = this.getParameters();
      let locations = splitUrlParamByComma(urlParameters.itinerary);
      let dates = splitUrlParamByComma(urlParameters.dates);
      let initial = splitUrlParamByComma(urlParameters.initial);
      const { passengers, vehicles, pets } = this.getTravelers();

      let urlParametersSearch = {
        locations: locations || initial || [],
        dates: dates,
        source: 'URL',
        passengers,
        vehicles,
        pets,
        url: window.location.toString(),
        isSearchByDate: urlParameters.isSearchByDate
      }     

      // clear the url after having fetched whatever was needed
      this.cleanUrl();

      return urlParametersSearch;
      
    } catch (exception) {
      // silently catch exception and load the page normally
      return undefined;
    }
  },

  /*
    Extract passenger and vehicle count from url parameters
   */
  getTravelers() {

    // default passengers and vehicles
    let initialPassengersCount = 1;
    let initialVehiclesCount = 0;
    let initialPetsCount = 0;

    // fetch passengers and vehicles from url parameters
    try {
      let urlParameters = this.getParameters();      
      let urlPassCount = urlParameters && Number(urlParameters.passengers) || initialPassengersCount;
      let urlVehCount = urlParameters && Number(urlParameters.vehicles) || initialVehiclesCount;
      let urlPetsCount = urlParameters && Number(urlParameters.pets) || initialPetsCount;

      initialPassengersCount = urlPassCount;
      initialVehiclesCount = urlVehCount;
      initialPetsCount = urlPetsCount;

    } catch (exception) {
      initialPassengersCount = 1;
      initialVehiclesCount = 0;
      initialPetsCount = 0;
    }

    return {
      passengers: initialPassengersCount,
      vehicles: initialVehiclesCount,
      pets: initialPetsCount
    }
  }
}

export default UrlParametersManager.newInstance();
