import { LOCALISED_DATE_FORMAT } from '@/logic/helpers/dateUtils';
import { message } from '@/logic/helpers/utils';
import { VEHICLE_TYPES } from '@/logic/adapters/vehicleTypes';

export const NEW_VEHICLE_ID = 'newVehicleId';

const adaptUserContactDetails = ({ name = '', surname = '', email = '', phoneCode = '', phone = '' }) => {
  return { name, surname, email, phoneCode, phone };
};

const adaptPassenger = (passenger) => {
  const {
    id,
    sex,
    loyaltyCards,
    timesTravelled,
    details: { firstName, lastName, document, birthplace, nationality, dateOfBirth, dateOfDocumentExp, mkn },
  } = passenger;

  return {
    id: id,
    gender: sex === 'MALE' ? 'M' : 'F',
    firstName,
    lastName,
    passport: document,
    birthplace,
    nationality,
    birthDateFull: dateOfBirth.replaceAll('-', '/'),
    documentDateFull: dateOfDocumentExp.replaceAll('-', '/'),
    man: mkn,
    loyaltyCards,
    timesTravelled,
  };
};

const adaptUserPassengers = (passengers) => passengers.map(adaptPassenger).sort((a, b) => (a.lastName > b.lastName ? 1 : -1));

const adaptVehicle = (vehicle) => {
  const {
    id,
    createdAt,
    type,
    details: { brand = '', licensePlate, model = '' },
    timesTravelled,
  } = vehicle;
  return { id, createdAt, brand, licencePlate: licensePlate, model, type, timesTravelled };
};

const adaptUserVehicles = (vehicles) => vehicles.map(adaptVehicle);

export const adaptUser = (payload) => {
  payload.contactDetails = adaptUserContactDetails(payload.contactDetails);
  payload.passengers = adaptUserPassengers(payload.passengers);
  payload.vehicles = adaptUserVehicles(payload.vehicles);
  return payload;
};

export const adaptPassengerPayload = ({ gender, firstName, lastName, birthDateFull, birthplace, documentDateFull, nationality, passport, islanderCode, timesTravelled, loyaltyCards }) => {
  return {
    timesTravelled: timesTravelled ? timesTravelled : 0,
    sex: gender === 'M' ? 'MALE' : 'FEMALE',
    details: {
      firstName,
      lastName,
      dateOfBirth: birthDateFull === LOCALISED_DATE_FORMAT ? '' : birthDateFull.replaceAll('/', '-'),
      birthplace,
      dateOfDocumentExp: documentDateFull === LOCALISED_DATE_FORMAT ? '' : documentDateFull.replaceAll('/', '-'),
      nationality,
      document: passport,
      mkn: islanderCode,
    },
    loyaltyCards: loyaltyCards ? loyaltyCards : undefined,
  };
};

export const adaptVehiclePayload = ({ vehicleCategoryType, licencePlate, vehicleModel, vehicleBrand, timesTravelled }) => {
  return {
    type: vehicleCategoryType,
    timesTravelled: timesTravelled ? timesTravelled : 0,
    details: {
      licensePlate: licencePlate,
      model: vehicleModel,
      brand: vehicleBrand,
    },
  };
};

export const adaptProfileVehiclePayload = ({ type, licencePlate, model, brand, timesTravelled }) => {
  return {
    type,
    timesTravelled: timesTravelled ? timesTravelled : 0,
    details: {
      licensePlate: licencePlate,
      model,
      brand,
    },
  };
};

export const createPassenger = () => {
  return {
    gender: '',
    firstName: '',
    lastName: '',
    passport: '',
    birthplace: '',
    nationality: '',
    birthday: '',
    birthmonth: '',
    birthyear: '',
    birthDateFull: '',
    docexpday: '',
    docexpmonth: '',
    docexpyear: '',
    documentDateFull: '',
    islanderCode: '',
    islanderCodeMessage: '',
    islanderCodeEnabled: false,
    extraOffersAndDiscounts: [],
    man: '',
  };
};

export const createSavedPassenger = ({ id, timesTravelled, firstName, lastName, gender, birthDateFull, birthplace, documentDateFull, nationality, passport, man, loyaltyCards }, index) => {
  return {
    id,
    targetPassengerUid: '',
    passengerIndex: index,
    firstName,
    lastName,
    fullName: `${firstName} ${lastName}`,
    timesTravelled,
    gender,
    birthDateFull,
    birthplace,
    documentDateFull,
    nationality,
    passport,
    man,
    loyaltyCards,
  };
};

export const createSavedVehicle = ({ id, timesTravelled, createdAt, brand, licencePlate, model, type }, index) => {
  return {
    id,
    timesTravelled,
    targetVehicleUid: '',
    vehicleIndex: index,
    createdAt,
    brand,
    licencePlate,
    model,
    type,
  };
};

export const createDefaultSavedVehicle = () => {
  return { id: 'newVehicleId', licencePlate: message('passDet.vehicles.dropdown.newVehicle') };
};

export const createDefaultSavedPassenger = () => {
  return { id: 'newPassengerId', fullName: message('passDet.passengers.dropdown.newPassenger') };
};

export const createVehicle = () => {
  return {
    brand: '',
    licencePlate: '',
    model: '',
    type: VEHICLE_TYPES.CAR,
  };
};
