<template>
  <div :class="$style.refund">
    <div :class="$style.refund__text">
      {{ trans('passDet.refProt.standard.explanation.text.t1') }}
      <span class="text-primary" @click.stop><InfoModalItem :customTitle="trans('passDet.refProt.standard.explanation.text.t2')" :includeIcon="false" :slug="'indicative-cancellation-policies'" @openInfoModalClicked="openInfoModalClicked"/></span>
      {{ trans('passDet.refProt.standard.explanation.text.t3') }}
    </div>
  </div>
</template>

<script>
import { eventBookingModalOpened } from '@/logic/services/events/createBookingEvents';
import { trans } from '@/filters';

export default {
  name: 'StandardRefund',
  methods: {
    trans,
    openInfoModalClicked() {
      eventBookingModalOpened('indicative_cancellation_policies');
    }
  }
};
</script>

<style module lang="scss">
@import './refund.scss';
</style>
