import DirectTripModel from "./DirectTripModel";

export const createDirectTripFromApi = (rawTrip) => {
  let directTrip = new DirectTripModel(rawTrip);

  if (false === directTrip.isValidDirectTrip) {
    return null;
  }
  
  return directTrip;
}