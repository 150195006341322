// @ts-check

import { isJsonPropTrue } from "@/logic/helpers/utils";

/**
 * Convert raw classes data into meaningful object, and remove those
 * that failed to convert (undefined)
 * 
 * @param {object[]} classes 
 * @param {object[]} categories 
 * @param {string} locale 
 */
export const adaptClasses = (classes, categories, locale) => {
  return classes.map(c => adaptClass(c, categories, locale)).filter(c => !!c);
}

/**
 * @param {object} classData 
 * @param {any[]} categories 
 * @param {string} locale
 */
export const adaptClass = (classData, categories, locale) => {

  // find class category. If not found, ignore the class. This happens
  // because in all locales we get all classes, but some of them might not 
  // have a defined category on the current locale, and hence, should not be shown
  let category = categories.find(c => c.classCategory == classData.classCategory);
  if (!category) return undefined;

  return {
    // class code, unique per company
    ClassAbbr: adaptClassAbbr(classData),
    // the number of passengers that a cabin can hold
    accommodationCapacity: adaptCapacity(classData),
    // when set to true, passengers can book a single berth in the cabin
    singleBerth: adaptSingleBerth(classData),
    // when set to true, the class is a special discount that is non-cancellable
    isDiscount: adaptIsDiscount(classData),
    isPet: adaptIsPet(classData),
    isInfant: adaptIsInfant(classData),
    classCategory: adaptClassCategory(classData),
    // company code for this class
    company: adaptCompany(classData),
    // description of the class
    className: adaptClassName(classData, locale),
    // extra description (details) for the class
    classSubDescription: adaptClassSubDescription(classData, locale),
    // classType, eg. SEAT, DECK, CABIN
    classType: adaptClassType(classData),
    // description of class category
    classCategoryDescription: category.classCategoryDescription,
    // order of class category, used for sorting
    categoryOrder: category.categoryOrder
  }
};

/**
 * @param {{ ClassAbbr: string; }} classData
 */
const adaptClassAbbr = (classData) => {
  return classData.ClassAbbr;
}

/**
 * @param {{ capacity: string; }} classData
 */
const adaptCapacity = (classData) => {
  return Number(classData.capacity);
}

/**
 * @param {{ singleBerth: string | boolean; }} classData
 */
const adaptSingleBerth = (classData) => isJsonPropTrue(classData.singleBerth);

/**
 * @param {{ isDiscount: string | boolean; }} classData
 */
const adaptIsDiscount = (classData) => isJsonPropTrue(classData.isDiscount);

/**
 * @param {{ isPet: string | boolean; }} classData
 */
const adaptIsPet = (classData) => isJsonPropTrue(classData.isPet);

/**
 * @param {{ isInfant: string | boolean; }} classData
 */
const adaptIsInfant = (classData) => isJsonPropTrue(classData.isInfant);

/**
 * @param {{ classCategory: string; }} classData
 */
const adaptClassCategory = (classData) => classData.classCategory;

/**
 * @param {{ classType: string; }} classData
 */
const adaptClassType = (classData) => {
  return classData.classType;
}

/**
 * @param {{ company: string; }} classData
 */
const adaptCompany = (classData) => {
  return classData.company;
}

/**
 * @param {{ className: any[]; }} classData
 * @param {string} locale
 */
const adaptClassName = (classData, locale) => {
  if (Array.isArray(classData.className)) {
    let description = classData.className.find(c => c.languageCode === locale);
    return description && description.text || 'No description';
  } else {
    return classData.className;
  }
}

/**
 * @param {{ classSubDescription: any[]; }} classData
 * @param {string} locale
 */
const adaptClassSubDescription = (classData, locale) => {
  if (Array.isArray(classData.classSubDescription)) {
    let subDescription = classData.classSubDescription.find(c => c.languageCode === locale);
    return subDescription && subDescription.text || '';
  } else {
    return classData.classSubDescription;
  }
}