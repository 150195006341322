<template>
  <div :class="$style.refund">
    <div :class="$style.refund__main">
      <div :class="$style.refund__text">
        {{ trans('passDet.travInsIntam.on.explanation.text') }}
        <ul :class="[$style.refund__list, 'mb0']">
          <li class="mb4">{{ trans('passDet.travInsIntam.on.explanation.text.bullet.1') }}</li>
          <li class="mb4">{{ trans('passDet.travInsIntam.on.explanation.text.bullet.2') }}</li>
          <li>{{ trans('passDet.travInsIntam.on.explanation.text.bullet.3') }}</li>
        </ul>
      </div>
      <span :class="[$style.refund__text, 'text-primary']" @click.stop="showTravelInsuranceModal = true">{{ trans('generic.viewFullList') }} </span>
    </div>

    <FhModal v-if="showTravelInsuranceModal" @close="showTravelInsuranceModal = false" :title="trans('passDet.travInsIntam.on.explanation.infoModalHeading')">
      <template #body>
        <div class="fh-loader" v-if="!hasDescription">
          <div class="loader"></div>
        </div>
        <div v-else v-html="travelInsuranceDescription"></div>
      </template>
    </FhModal>
  </div>
</template>

<script>
import { EXTRA_SERVICES, getExtraServiceWithRetry, EXTRA_SERVICE_PROVIDERS } from '@/logic/BL/extraServices';
import { logException } from '@/logic/services/events/errorLogging';
import { trans } from '@/filters';

export default {
  name: 'Interamerican',
  data: () => ({
    travelInsuranceDescription: '',
    showTravelInsuranceModal: false,
  }),
  emits: ['fetchCompleted'],
  created() {
    this.$emit('fetchCompleted', false);
    getExtraServiceWithRetry(EXTRA_SERVICES.TRAVEL_INSURANCE, EXTRA_SERVICE_PROVIDERS.INTERAMERICAN)
      .then((response) => (this.travelInsuranceDescription = response.staticHtml))
      .catch((error) => logException('TravelInsurance', error))
      .finally(() => this.$emit('fetchCompleted', true));
  },
  methods: {
    trans,
  },
  computed: {
    hasDescription() {
      return this.travelInsuranceDescription !== '';
    },
  },
};
</script>

<style module lang="scss">
@import '../refund/refund.scss';
</style>
