import { getPageName } from '@/logic/helpers/utils';
import { ga4Event } from './EventService';
import { tripToTripInfo } from './sharedAdapters';

export const eventPricingError = (trip, pricingResponse, provider = '') => {
  ga4Event('pricing_error_shown', {
    ...tripToTripInfo(trip),
    error_description: `${getPageName()}_${provider}_${pricingResponse.StatusMessage}`,
    error_code: pricingResponse.Status,
  });
};

const createEventLabel = (trip, pricingResponse) => {
  const { Status } = pricingResponse;
  const { DepStation, ArrStation, VesselName, Company } = trip.Details;
  const { DepDate } = trip.timings;

  return `${Status}_${Company}_${VesselName}_${DepStation}:${ArrStation}_${DepDate}`;
};
