import CompaniesRepository from '@/logic/repositories/CompaniesRepository';

/**
 * Return simple garaze availability from raw data
 * if conditions are met
 * @param {object} availability 
 */
export const isTripWithGaraze = availability => {
  const hasGarazeClass = !!availability.classes.find(c => c.abbr === 'IX');
  return availability.garaze && hasGarazeClass;
};

/**
 * Return vehicle options for a trip
 * @param {object} tripDetails 
 */
export const createVehicleOptions = tripDetails => {
  const { Company, hasCountryChange } = tripDetails;
  let allowMotorhomes = hasCountryChange || CompaniesRepository.hasDomesticMotorhomes(
    Company
  );
  
  let companyVehicles = CompaniesRepository.getCompanyVehicles(Company) || [];
  return companyVehicles.filter(v => allowMotorhomes || !v.isMotorhome).sort(vehicleSorter);
};

/**
 * Used for sorting vehicle options of trips based on the category order
 * @param {Vehicle} a 
 * @param {Vehicle} b 
 */
const vehicleSorter = (a, b) => a.categoryOrder - b.categoryOrder;
