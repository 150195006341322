<template>
  <div class="booking-flow-box--grey-bg">
    <h4 :class="$style.refundAlt__header" class="booking-flow-box__header">{{ trans('passDet.travInsIntam.title.oneCard') }}</h4>
    <span :class="[$style['refundAlt__header-sub'], 'booking-flow-box__subtitle', 'mb16']"><i v-if="!isMobile" class="fh fh-info mr8 mt3" />{{ trans('passDet.travInsIntam.subtitle') }}</span>

    <div v-show="!showWaitingState">
      <SelectableCardAlt
        v-if="showCard"
        :amount="currencyStringPre(travelInsurancePrice)"
        :imageSrc="travelInsuranceImageUrl"
        :selected="travelInsuranceSelected && !hasTravelInsuranceError"
        :isLoading="fetchingForPrices"
        :badgeText="trans('generic.recommended')"
        :hasError="hasTravelInsuranceError"
        :errorMsg="travelInsuranceError"
        :footerMsg="trans('passDet.travInsIntam.on.oneCard.radioButton.text')"
        @click="setTravelInsuranceSelected({ ancillaryMode: insuranceOptions.EXTENDED, ancillaryOrder, totalAncillaries: totalAncillaries, ancillaryUIMode: 'single' })"
      >
        <InteramericanAlt @fetchCompleted="fetchCompleted" />
      </SelectableCardAlt>

      <SelectableCardSimple :selected="!travelInsuranceSelected" @click="setTravelInsuranceSelected({ ancillaryMode: insuranceOptions.STANDARD })" :hasError="hasTravelInsuranceError">
        {{ trans('passDet.travInsIntam.off.oneCard.radioButton.text') }}
      </SelectableCardSimple>
    </div>
    <div v-show="showWaitingState">
      <SelectableCardSkeletonAlt />
      <SelectableCardSimpleSkeleton />
    </div>
  </div>
</template>

<script>
import InteramericanAlt from '@/components/book/insuranceAlt/InteramericanAlt.vue';
import SelectableCardAlt from '@/components/shared/SelectableCardAlt/SelectableCardAlt.vue';
import SelectableCardSimple from '@/components/shared/SelectableCardSimple/SelectableCardSimple.vue';
import SelectableCardSkeletonAlt from '@/components/shared/SelectableCardAlt/SelectableCardSkeletonAlt.vue';
import SelectableCardSimpleSkeleton from '@/components/shared/SelectableCardSimple/SelectableCardSimpleSkeleton.vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import { EXTRA_SERVICE_OPTIONS, EXTRA_SERVICE_PROVIDERS } from '@/logic/BL/extraServices';
import { trans, currencyStringPre, fhImage } from '@/filters';
import { eventTravelInsuranceShown } from '@/logic/services/events/createBookingEvents';

export default {
  name: 'TravelInsuranceOptionsAltComponent',
  components: {
    SelectableCardAlt,
    InteramericanAlt,
    SelectableCardSimple,
    SelectableCardSkeletonAlt,
    SelectableCardSimpleSkeleton,
  },
  props: {
    ancillaryOrder: {
      type: Number,
    },
    totalAncillaries: {
      type: Number,
    },
  },
  data() {
    return {
      insuranceOptions: EXTRA_SERVICE_OPTIONS,
      interamericanProvider: EXTRA_SERVICE_PROVIDERS.INTERAMERICAN,
      isFetchCompleted: false,
    };
  },
  methods: {
    trans,
    currencyStringPre,
    fhImage,
    ...mapActions({
      setTravelInsuranceSelected: 'bookingModule/setTravelInsuranceSelected',
    }),
    fetchCompleted(value) {
      this.isFetchCompleted = value;
      if (value) eventTravelInsuranceShown(this.travelInsuranceProvider, this.ancillaryOrder, this.totalAncillaries);
    },
  },
  computed: {
    ...mapState({
      fetchingForPrices: (state) => state.uiModule.fetchingForPrices,
      travelInsurancePrice: (state) => state.bookingModule.travelInsurance.price,
      travelInsuranceError: (state) => state.bookingModule.travelInsurance.error,
    }),
    ...mapGetters({
      travelInsuranceProvider: 'bookingModule/getTravelInsuranceProvider',
      travelInsuranceSelected: 'bookingModule/travelInsuranceSelected',
      hasTravelInsuranceError: 'bookingModule/hasTravelInsuranceError',
    }),
    showWaitingState() {
      return !this.isFetchCompleted && !this.hasTravelInsuranceError;
    },
    travelInsuranceImageUrl() {
      return this.hasTravelInsuranceError ? fhImage('assets/travel-insurance/DisabledInsurance.svg') : fhImage('assets/travel-insurance/HasInsurance.svg');
    },
    showCard() {
      return this.travelInsuranceProvider === this.interamericanProvider || this.hasTravelInsuranceError;
    },
  },
};
</script>

<style module lang="scss">
@import '../refundAlt/refund-alt.scss';
</style>
