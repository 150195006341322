<template>
  <div>
    <BookingFlowGrid :class="[isMobile ? 'mt20' : 'mt50']">
      <template #col>
        <SeatingTravelersPicker :isOccupied="isOccupied" :hasCarsRestriction="hasCarsRestriction" :trips="trips" />

        <div v-for="(trip, tripIndex) in trips" :key="'seating-app-trip-section-' + tripIndex" class="mb40">
          <JourneyWithDate :origin="trip.Details.DepStationName" :destination="trip.Details.ArrStationName" :showArrows="false" ref="journeyWithDate">
            {{ trip.timings.DepDateLocal }}, {{ trip.timings.DepTime }}
          </JourneyWithDate>

          <!-- trip errors -->
          <transition-group name="height-fade-out" tag="div" appear>
            <Notification
              v-for="(error, errorIndex) in trip.pricingErrors"
              :key="`${error.code}-${errorIndex}`"
              :message="error.message"
              class="error-wrapper"
              :id="`trip-header-${tripIndex}`"
              :data-trip-header-id="`trip-header-${tripIndex}`"
            />
          </transition-group>

          <!-- trip passengers forms -->
          <transition-group name="fade" tag="div" appear>
            <div :key="'passenger-wrapper'">
              <LabelWithIcon :key="'passengers-label'" :icon="'users-fill'" :label="trans('passengers')" class="seating-app-label-with-icon" v-show="trip.passengers.length > 0" />
              <PassengerSeatingComponent
                v-for="passenger in trip.passengers"
                :key="`${tripIndex}passenger-seating-${passenger.passengerIndex}`"
                :trip="trip"
                :passengerIndex="passenger.passengerIndex"
                :passenger="passenger"
                :tripIndex="tripIndex"
                :isOccupied="isOccupied"
                @onLoyaltyChange="onLoyaltyChange"
                @onResidenceDiscountChange="onResidenceDiscountChange"
              />
            </div>

            <div :key="'pet-wrapper'">
              <LabelWithIcon
                v-show="trip.pets.length > 0"
                class="seating-app-label-with-icon"
                :key="'pets-label'"
                :icon="'pet'"
                :label="trans('pets')"
                :hasModal="trip.petPolicy.summary !== ''"
                :modalContent="trip.petPolicy.summary"
                :modalTitle="trans('ticketSelection.pets.infoModalHeading')"
              />
              <div v-if="showPetInfoBox(trip)" class="booking-flow-box__info mb12">
                <p class="m0">{{ trans('ticketSelection.pets.description') }}</p>
              </div>
              <PetSeatingComponent
                v-for="pet in trip.pets"
                :key="`${tripIndex}pet-seating-${pet.petIndex}`"
                :trip="trip"
                :tripIndex="tripIndex"
                :pet="pet"
                :petTypes="trip.PetTypes"
                :petAccommodations="trip.petAccommodations"
                :isOccupied="isOccupied"
                :passengers="passengers"
              />
            </div>

            <div :key="'vehicle-wrapper'">
              <LabelWithIcon class="seating-app-label-with-icon" :key="'car-label'" :icon="'vehicles'" :label="trans('vehicles')" v-show="trip.vehicles.length > 0" />
              <div v-if="trip.vehicles.length > 0" class="booking-flow-box__info mb12">
                <p class="mt0 mb4">{{ trans('ticketSelection.vehicles.description.text.1') }}</p>
                <p class="m0">{{ trans('ticketSelection.vehicles.description.text.2') }}</p>
              </div>
              <VehicleSeatingComponent
                v-for="vehicle in trip.vehicles"
                :key="`${tripIndex}vehicle-seating-${vehicle.vehicleIndex}`"
                :trip="trip"
                :tripIndex="tripIndex"
                :vehicle="vehicle"
                :isOccupied="isOccupied"
                :vehicleOptions="vehicleAccommodations"
                :passengers="passengers"
              />
            </div>
          </transition-group>
          <!-- <TripDebugger :trip="trip" /> -->
        </div>
      </template>
      <template #col-sm>
        <FhSeatingCart
          :trips="trips"
          :isWaiting="isWaitingForPrices"
          :overall="overall"
          :hasVerifiedAvailability="hasVerifiedAvailability"
          :isPricingDisabled="isPricingDisabled"
          :hasConnectionError="hasNetworkError"
          :isOccupied="isOccupied"
          @getEarlyBookingTerms="getEarlyBookingTerms"
          @onCheckoutClick="onFinalCheckout"
          @onPricingRequested="onPricingRequested"
        />
      </template>
    </BookingFlowGrid>

    <FhModal v-if="showEarlyBookingModal" @close="showEarlyBookingModal = false" :title="trans('earlybooking')">
      <template #body>
        <LoadingSpinner v-if="earlyBookingTerms === ''" />
        <div v-else v-html="earlyBookingTerms"></div>
      </template>
    </FhModal>
  </div>
</template>

<script>
import emitter from '@/emitter';
import { ga4Event, ga4BeginCheckout } from '@/logic/services/events/EventService';
import TripsFilterer from '@/logic/filterers/TripsFilterer';
import JourneyWithDate from '@/components/shared/JourneyWithDate';

// passenger & vehicle selectors
import PassengerSeatingComponent from '@/components/seats/PassengerSeatingComponent';
import VehicleSeatingComponent from '@/components/seats/VehicleSeatingComponent';
import PetSeatingComponent from '@/components/seats/PetSeatingComponent/PetSeatingComponent';
import SeatingTravelersPicker from './SeatingTravelersPicker';
import BookingFlowGrid from '@/components/shared/BookingFlowGrid';

// import TripDebugger from './TripDebugger';
import { scrollToElementId } from '../../logic/dom/scrollTo';
import LabelWithIcon from '@/components/shared/LabelWithIcon';

// Vuex
import { mapGetters, mapState, mapActions } from 'vuex';
import { NAVIGATION_TABS } from '../../store/modules/navigation.module';

import FhSeatingCart from '@/components/cart/SeatingCart/FhSeatingCart';
import CheckoutSingleButtonComponent from '@/affiliates/aegeanair/components/shared/CheckoutButtons/CheckoutSingleButtonComponent';
import { PET_POLICIES } from '@/logic/repositories/PetsRepository';
import { trans } from '@/filters';
import { nextTick } from 'vue';
import { cloneDeep as _cloneDeep, each as _each } from 'lodash-es';
import { clean } from '@/logic/helpers/utils';
import { getSpecialOfferTerms } from '@/logic/services/ApiGateway';

export default {
  name: 'SeatingApp',
  components: {
    JourneyWithDate,
    SeatingTravelersPicker,
    PassengerSeatingComponent,
    VehicleSeatingComponent,
    PetSeatingComponent,
    LabelWithIcon,
    FhSeatingCart,
    CheckoutSingleButtonComponent,
    BookingFlowGrid,
    // TripDebugger
  },
  data: () => ({
    shouldScroll: false,
    // is false if selected trips do not allow vehicles
    hasCarsRestriction: false,
    // array of vehicle options that are common between selected trips
    vehicleAccommodations: [],
    showEarlyBookingModal: false,
    earlyBookingTerms: '',
  }),
  created() {
    emitter.$on('onCartCheckout', async (trips) => {
      await this.initializeSeatingApp(trips);
      this.enableΝavTab(NAVIGATION_TABS.PASSENGERS);
      this.changeNavTab(NAVIGATION_TABS.PASSENGERS);
    });

    // reset component when redirected here from booking tab
    // todo: find a way to implement this call when the component is visited
    // by navigation events
    emitter.$on('onBookingTabBackClick', () => {
      this.resetAvailabilityAndPrices(true);
    });
  },
  computed: {
    ...mapState({
      isWaitingForPrices: (state) => state.ticketConfigurationModule.isWaitingForPrices,
      hasNetworkError: (state) => state.ticketConfigurationModule.hasNetworkError,
      trips: (state) => state.ticketConfigurationModule.trips,
      tripRequestsCombinations: (state) => state.ticketConfigurationModule.tripRequestsCombinations,
      selectedSpecialOffers: (state) => state.ticketConfigurationModule.selectedSpecialOffers,
    }),
    ...mapGetters({
      passengersCount: 'searchModule/passengersCount',
      vehiclesCount: 'searchModule/vehiclesCount',
      petsCount: 'searchModule/petsCount',
      hasVerifiedAvailability: 'ticketConfigurationModule/hasVerifiedAvailability',
      isWaitingPassengerOfferValidation: 'ticketConfigurationModule/isWaitingPassengerOfferValidation',
      overall: 'ticketConfigurationModule/overallPrice',
      selectedPortsAbbr: 'searchModule/selectedPortsAbbr',
    }),
    activeTab() {
      return this.$store.state.navigationModule.activeTab;
    },
    isOccupied() {
      return this.isWaitingForPrices || this.isWaitingPassengerOfferValidation;
    },
    isPricingDisabled() {
      return this.isWaitingPassengerOfferValidation;
    },
    passengers() {
      return this.trips[0].passengers;
    },
  },
  watch: {
    activeTab() {
      this.resetAvailabilityAndPrices(false);
    },
    trips: {
      async handler() {
        if (!this.shouldScroll) return;
        if (!this.trips.some((trip) => trip.pricingErrors.length > 0)) return;
        await nextTick();
        const errorNotifications = document.querySelectorAll('.error-wrapper');
        if (errorNotifications.length === 0) return;
        const errorWrapperId = errorNotifications[0].getAttribute('data-trip-header-id');
        const navigationTabsHeight = document.getElementById('navigationTabs').offsetHeight;
        const journeyWithDateHeight = this.$refs.journeyWithDate[0].$el.clientHeight;
        const scrollingOffset = navigationTabsHeight + journeyWithDateHeight;
        scrollToElementId(errorWrapperId, 'smooth', scrollingOffset);
        this.shouldScroll = false;
      },
      deep: true,
    },
  },
  methods: {
    trans,
    ...mapActions({
      disableNavTabs: 'navigationModule/disableNavTabs',
      enableΝavTab: 'navigationModule/enableΝavTab',
      changeNavTab: 'navigationModule/changeNavTab',
      setTimer: 'navigationModule/setTimer',
      setTripsForSeating: 'ticketConfigurationModule/setTripsForSeating',
      resetAvailabilityAndPrices: 'ticketConfigurationModule/resetAvailabilityAndPrices',
      resetSeatSelection: 'ticketConfigurationModule/resetSeatSelection',
      addNewPassenger: 'ticketConfigurationModule/addNewPassenger',
      addNewPet: 'ticketConfigurationModule/addNewPet',
      addNewVehicle: 'ticketConfigurationModule/addNewVehicle',
      performPricingWithRetry: 'ticketConfigurationModule/performPricingWithRetry',
      changePassengerResidenceDiscount: 'ticketConfigurationModule/changePassengerResidenceDiscount',
      changePassengerLoyaltyNumber: 'ticketConfigurationModule/changePassengerLoyaltyNumber',
    }),
    getEarlyBookingTerms() {
      if (this.earlyBookingTerms === '') {
        getSpecialOfferTerms('early-booking').then((response) => (this.earlyBookingTerms = response.content));
      }
      this.showEarlyBookingModal = true;
    },
    onResidenceDiscountChange(changes, passenger, changedTrip) {
      this.changePassengerResidenceDiscount({ changedTrip, passenger, changes });
    },
    onLoyaltyChange(changes, passenger, changedTrip) {
      this.changePassengerLoyaltyNumber({ changedTrip, passenger, changes });
    },
    showPetInfoBox(trip) {
      return trip.pets.length > 0 && trip.petPolicy.type === PET_POLICIES.TICKET;
    },
    // returns to the previous tab (results)
    onBackClicked: function () {
      this.changeNavTab(NAVIGATION_TABS.RESULTS);
      this.disableNavTabs([NAVIGATION_TABS.PASSENGERS]);
      //KilledEvent('Passengers section', 'Clicked on back button');
    },
    //--------------------------------------------------------------------------
    //--------------------------------------------------------------------------
    // get the raw trips, passengers, vehicles (if any) and initialize
    async initializeSeatingApp(rawTrips) {
      this.setTripsForSeating(rawTrips);
      this.initializePassengers();
      this.initializeVehicles();
      this.initializePets();

      return true;
    },
    initializePassengers() {
      for (let i = 0; i < this.passengersCount; i++) {
        this.addNewPassenger();
      }
    },
    initializePets() {
      for (let i = 0; i < this.petsCount; i++) {
        this.addNewPet();
      }
    },
    initializeVehicles() {
      // Keep the common vehicle options between the currently loaded trips
      this.vehicleAccommodations = TripsFilterer.findCommonVehicles(this.trips);
      // keep an array of maximum vehicle options for this trip
      // this.vehicleAccommodations = TripsFilterer.findMaxVehicleOptions(bookableItems)
      try {
        // check if all trips accept cars, else disable car selections
        this.hasCarsRestriction = TripsFilterer.hasVehicleRestrictions(this.trips) || this.vehicleAccommodations.length === 0;
        if (!this.hasCarsRestriction) {
          // add a new vehicle if vehicles have been defined in the firt page
          for (let i = 0; i < this.vehiclesCount; i++) {
            this.addNewVehicle();
          }
        }
      } catch (exception) {
        this.hasCarsRestriction = true;
      }
    },

    onPricingRequested: function () {
      emitter.$emit('checkForUnfilledSeats');

      this.shouldScroll = true;

      // setTimer for asking to refresh page modal
      this.setTimer();

      // send ga event
      ga4Event('verify_prices_clicked', {});

      this.resetAvailabilityAndPrices(false);
      this.performPricingWithRetry(false);
    },

    onFinalCheckout: function () {
      ga4BeginCheckout(this.overall, this.trips, this.selectedPortsAbbr);
      nextTick(() => {
        emitter.$emit('SeatingPricingFinished');
        emitter.$emit('onSeatsCheckout', { trips: _cloneDeep(this.trips), tripRequestsCombinations: clean(this.tripRequestsCombinations), selectedSpecialOffers: clean(this.selectedSpecialOffers) });
      });
    },
  },
};
</script>
