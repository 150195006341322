<template>
  <div :class="$style.refundAlt">
    <div :class="$style.refundAlt__text">
      {{ trans('passDet.FHsupport.plus.explanation.text') }}
    </div>
    <div :class="$style['refundAlt__text--bullets']">
      <ul class="pl16">
        <li class="mb4">{{ trans('passDet.FHsupport.plus.explanation.bullet.1') }}</li>
        <li class="mb4">{{ trans('passDet.FHsupport.plus.explanation.bullet.2') }}</li>
        <li>{{ trans('passDet.FHsupport.plus.explanation.bullet.3') }}</li>
      </ul>
    </div>
    <span :class="[$style.refundAlt__text, $style['refundAlt__text-list'], 'text-primary']" @click.stop="showSupportPlusModal = true">{{ trans('generic.viewFullList') }} </span>

    <FhModal v-if="showSupportPlusModal" @close="showSupportPlusModal = false" :title="trans('generic.supportPlus')">
      <template #body>
        <div class="fh-loader" v-if="!hasDescription">
          <div class="loader"></div>
        </div>
        <div v-else v-html="supportPlusDescription"></div>
      </template>
    </FhModal>
  </div>
</template>

<script>
import { EXTRA_SERVICES, getExtraServiceWithRetry, EXTRA_SERVICE_PROVIDERS } from '@/logic/BL/extraServices';
import { logException } from '@/logic/services/events/errorLogging';
import { trans } from '@/filters';

export default {
  name: 'SupportPlusAlt',
  data: () => ({
    supportPlusDescription: '',
    showSupportPlusModal: false,
  }),
  emits: ['fetchCompleted'],
  created() {
    this.$emit('fetchCompleted', false);
    getExtraServiceWithRetry(EXTRA_SERVICES.SUPPORT_PLUS, EXTRA_SERVICE_PROVIDERS.FERRYHOPPER)
      .then((response) => (this.supportPlusDescription = response.staticHtml))
      .catch((error) => logException('SupportPlus', error))
      .finally(() => this.$emit('fetchCompleted', true));
  },
  methods: {
    trans,
  },
  computed: {
    hasDescription() {
      return this.supportPlusDescription !== '';
    },
  },
};
</script>

<style module lang="scss">
@import '../refundAlt/refund-alt.scss';
</style>
