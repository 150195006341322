<template>
  <div>
    <div v-if="option.categoryTitle && !hasInputValue">
      <span :class="['fh-dropdown-option__title', extraClass]" @click.stop>{{ option.categoryTitle }}<br /></span>
    </div>
    <div :class="['fh-dropdown-option', `fh-dropdown-option-${index}`]" @click="handleClick">
      <span>{{ option.name }}</span>
    </div>
  </div>
</template>

<script>
import { message } from '@/logic/helpers/utils';

export default {
  name: 'FhDropdownNationalityOption',
  props: {
    option: Object,
    index: Number,
    hasInputValue: Boolean
  },
  emits: ['click'],
  methods: {
    handleClick() {
      this.$emit('click');
    },
  },
  computed: {
    extraClass() {
      return this.option.categoryTitle === message('allCountriesLabel') ? 'fh-dropdown-option__title--spaced' : '';
    },
  },
};
</script>
