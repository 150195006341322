import { message, roundPrice, uniqueId } from '@/logic/helpers/utils'
import PortsRepository from '@/logic/repositories/PortsRepository'
import DirectTripModelLight from '@/logic/models/trips/DirectTripModelLight'
import { durationInMinutes } from '../../helpers/dateUtils'
import { getPortCodesForSegments } from './getPortCodesForSegments'
import { JOURNEY_MODE } from './journeyModes'



const getMinPriceForSegments = (segments) => {
  return roundPrice(segments.reduce((acc, p) => {
    return acc + p.minPrice;
  }, 0.0), 2);
}

function IndirectTripModel(data) {

  // create unique identifier
  this.id = uniqueId(11);
  this.indirectTripType = data.indirectTripType || JOURNEY_MODE.INDIRECT;
  
  // initialize flags to default states
  this.IDSCode = this.id;
  this.isDirectTrip = false;
  this.available = true;
  this.alternate = false;
  this.overnight = false;
  // trip labels not implemented in indirect trips
  this.tripLabels = [];

  if (!data.segments) {
    return;
  }

  // get initial segments and create light models to load
  const unverifiedSegments = data.segments.map(s => new DirectTripModelLight(s));
  this.setTripSegments(unverifiedSegments);
}

IndirectTripModel.prototype = {
  // Update availability from segments
  updateAvailability() {
    // check individual segments availability, and restrict indirect trip 
    // if any of the segments is not available
    const hasUnavailableSegment = this.segments.some(s => !s.available);
    if (hasUnavailableSegment) {
      this.available = false;
      const unavailableSegment = this.segments.find((s) => !s.available);
      this.unavailableReason = unavailableSegment.unavailableReason;
      this.unavailableReasonTitle = message(unavailableSegment.unavailableReasonTitle);
      this.unavailableReasonCode = unavailableSegment.unavailableReasonCode;
    }    
  },
  // Build indirect trip from an array of segments
  setTripSegments(singleSegments) {
    
    // create IDSCode that will allow to filter out duplicate indirect trips
    this.IDSCode = singleSegments.map(s => s.IDSCode).join('');

    this.segments = singleSegments.map(segment => {
      segment.subJourney = {
        type: this.indirectTripType,
        IDScode: this.IDSCode
      };
      return segment;
    });

    // update duration
    const firstSegment = this.segments[0];
    const lastSegment = this.segments[this.segments.length - 1];
    
    // check if trip is overnight, comparing final arrival to first departure
    this.overnight = lastSegment.timings.ArrivalDateTime.isAfter(firstSegment.timings.DepartureDateTime, 'days');

    // loop through segments and caluclate wait time between this and the
    // previous segment, in order to evaluate possible restrictions
    for (let segmentIndex = 1; segmentIndex < this.segments.length; segmentIndex++) {
      // calculate segment wait time in minutes
      this.segments[segmentIndex].timings.waitTime = durationInMinutes(
        this.segments[segmentIndex - 1].timings.ArrivalDateTime,
        this.segments[segmentIndex].timings.DepartureDateTime
      );
    }

    let totalTravelTime = this.segments.reduce((total, segment) => total + segment.timings.duration, 0);
    let totalWaitTime = this.segments.reduce((total, segment) => total + segment.timings.waitTime, 0);

    this.duration = totalTravelTime + totalWaitTime;

    this.stopCodes = getPortCodesForSegments(this.segments);
    this.stops = this.stopCodes.map((portCode) => {
      return PortsRepository.getPort(portCode).alias;
    });

    // set supported flag to itinerary based on the supported status of each 
    // individual segment. We can do this at this stage, without having to verify the itinerary,
    // since we already know if the companies are supported or not
    this.supported = this.segments.every((segment) => segment.supported);

    // get min price sum for segments
    this.minPrice = getMinPriceForSegments(this.segments);


    // update availability
    this.updateAvailability();
  },
  setSuggestionQueries(depStation, arrStation, intStation, depDate) {
    this.suggestionQuery = {
      depStation,
      arrStation,
      intStation,
      depDate
    }
  }
}



export default IndirectTripModel;
