import * as types from '../mutation-types';
import { updateObjectWithProp } from '@/logic/helpers/objectManipulation';

export const invoiceModule = {
    namespaced: true,
    state: {
        invoiceSelected: false,
        invoiceDetails: {
            invoiceName: '',
            invoiceCountry: '',
            invoiceTaxNumber: '',
            invoiceTaxOffice: '',
            invoiceAddress: '',
            invoiceCity: '',
            invoicePostalCode: ''
        }
    },
    getters: {
        showtaxOfficeInput: state => state.invoiceDetails.invoiceCountry === 'GR',
        getInvoiceDetails: (state) => state.invoiceSelected ? state.invoiceDetails : undefined
    },
    mutations: {
        [types.SET_INVOICE_SELECTED](state, payload) {
            state.invoiceSelected = payload
        },
        [types.SET_INVOICE_DETAILS](state, payload) {
            state.invoiceDetails = updateObjectWithProp(state.invoiceDetails, Object.keys(payload)[0], Object.values(payload)[0]);
        },
    },
    actions: {
        setInvoiceSelected({ commit }, payload) {
            commit(types.SET_INVOICE_SELECTED, payload);
        },
        setInvoiceDetails({ commit }, payload) {
            commit(types.SET_INVOICE_DETAILS, payload);
        },
    }
}