<template>
  <div :class="$style.fhSeatingCartItem">
    <div v-for="(segment, index) in segments" :key="index" :class="$style.fhSeatingCartItem__segments">
      <div>
        <div :class="$style.fhSeatingCartItem__journey">
          <JourneyTitle :ports="[segment.Details.DepStationName, segment.Details.ArrStationName]" :classlist="$style['fhSeatingCartItem__trip-stations']" :cropText="false" />
        </div>
        <div :class="$style.fhSeatingCartItem__timings">
          <span :class="$style.fhSeatingCartItem__timing">{{ segment.timings.DepDateLocal }}, {{ segment.timings.DepTime }}</span>
          <i v-if="segment.timings.overnight" class="fh fh-moon ml5"></i>
        </div>
      </div>
      <VesselCard :companyIconUrl="segment.Details.CompanyIcon" :vesselName="segment.Details.VesselName" :companyName="segment.Details.CompanyName" />
      <FhCartSeatingAnalysis :trip="segment" :isWaiting="isWaiting" />
      <div :class="$style.fhSeatingCartItem__price" v-if="showPrice">
        <span>{{ trans('tripprice') }}</span>
        <span>{{ currencyStringPre(trip.OverallPrice) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { isPricedTrip } from '@/logic/services/pricing/makePricingRequest';
import { trans, currencyStringPre } from '@/filters';
import FhCartSeatingAnalysis from '@/components/cart/FhCartSeatingAnalysis';

export default {
  name: 'FhSeatingCartItem',
  props: {
    trip: Object,
    isWaiting: Boolean
  },
  components: {
    FhCartSeatingAnalysis
  },
  methods: {
    trans,
    currencyStringPre
  },
  computed: {
    isIndirect() {
      return this.trip && this.trip.isDirectTrip === false;
    },
    segments() {
      if (this.isIndirect === false) return [this.trip];
      return this.trip.segments;
    },
    showPrice() {
      return isPricedTrip(this.trip);
    }
  }
};
</script>

<style module lang="scss">
@import './fh-seating-cart-item.scss';
</style>
