import { handlePromise } from '../../helpers/handlePromise';
import { makeCombinedPricingRequest } from '../ApiCRS';
import { userTimingEvent } from '../events/EventService';
import { TRIP_STATUS } from '../../models/trips/TripStatus';
import { PRICING_STATUSES } from '../../models/pricing/PricingResponse';

export const TICKET_VIOLATIONS = {
  NONE: 'NONE',
  NO_SEAT_SELECTION: 'selectSeatForAllPassengers',
  NO_ADULTS: 'adultsViolation',
  MANY_VEHICLES: 'vehiclesViolation',
  CABIN_CAPACITY_VIOLATION: 'cabinCapacityViolation',
  PASSENGER_OFFER_VIOLATION: 'passengerOffer.violation',
  ACCOMMODATION_AVAILABILITY_VIOLATION: 'availabilityViolation',
  SINGLE_ACCOMMODATION_VIOLATION: 'singleAccommodation',
  SAME_PASSENGER_ASSIGNED_TO_VEHICLES: 'samePassengerToVehicleViolation',
  PET_CABIN_WITHOUT_PET_VIOLATION: 'petCabinWithoutPetViolation',
  PET_IN_CABIN_WITHOUT_CABIN_VIOLATION: 'petInCabinWithoutCabinViolation',
  PET_IN_VEHICLE_WITHOUT_VEHICLE: 'petInVehicleWithoutVehicleViolation'
};

export const isPricedTrip = trip => trip.STATUS === TRIP_STATUS.PRICED;
export const isUnpriceableTrip = trip => trip.STATUS === TRIP_STATUS.UNPRICEABLE;
export const isPriceableTrip = trip => trip.STATUS === TRIP_STATUS.PRICEABLE;
export const isPriceErrorTrip = trip => trip.STATUS === TRIP_STATUS.PRICING_ERROR;

export const makePricingRequest = async pricingRequestsCombination => {
  try {
    let pricingStartTime = new Date();
    const { response: pricingResponses, error } = await handlePromise(makeCombinedPricingRequest(pricingRequestsCombination));

    // early return on network errors
    if (error) return pricingRequestsCombination.map(createNetworkError);

    // record timing event only if no network error occurs
    let pricingEndTime = new Date();
    const companyCode = pricingRequestsCombination[0].ItineraryPricingRequestExt.Company;
    userTimingEvent('Pricing Request', companyCode, pricingEndTime - pricingStartTime);

    // validate format
    if (!Array.isArray(pricingResponses)) throw 'Invalid format';
    // validate length
    if (pricingResponses.length !== pricingRequestsCombination.length) throw 'Invalid response length';

    return pricingResponses.map((response, responseIndex) => createSuccessResponse(pricingRequestsCombination[responseIndex], response));
  } catch (exception) {
    return pricingRequestsCombination.map(createUnknownError);
  }
};

const createSuccessResponse = ({ tripIndex }, response) => ({
  tripIndex,
  ...response
});

const createNetworkError = ({ tripIndex }) => ({
  tripIndex,
  Status: PRICING_STATUSES.CONNECTION_ERROR
});

const createUnknownError = ({ tripIndex }) => ({
  tripIndex,
  Status: PRICING_STATUSES.ERROR
});
