<template>
  <div :class="selectableCardClass" @click="handleClick">
    <FhRadio :checked="selected" :disabled="isLoading" class="mr8 pt12" />
    <span>
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'SelectableCardSimple',
  props: {
    selected: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click'],
  computed: {
    selectableCardClass() {
      const selectedClass = this.selected ? this.$style['selectableCardSimple--selected'] : '';
      return `${this.$style.selectableCardSimple} ${selectedClass}`;
    }
  },
  methods: {
    handleClick() {
      this.$emit('click');
    }
  }
};
</script>

<style module lang="scss">
@import './selectable-card-simple.scss';
</style>
