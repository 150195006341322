<template>
  <div class="mt10 mb10 checkout-buttons checkout-buttons--separation">
    <div class="checkout-buttons__back">
      <button class="btn btn-dark checkout-buttons__button" @click="onBackClicked()"><i class="icon-back" aria-hidden="true"></i> {{ backButtonText }}</button>
    </div>
    <div class="checkout-buttons__next">
      <button
        class="btn btn-accent checkout-buttons__button ladda-button"
        @click="onNextClicked()"
        :disabled="disable"
        :id="id"
        data-style="zoom-in"
        data-spinner-color="#FFF"
        data-spinner-lines="18"
      >
        {{ nextButtonText }} <i class="fh fh-right-arrow" aria-hidden="true" v-if="showNextIcon"></i>
      </button>
    </div>
  </div>
</template>

<script>
import * as Ladda from 'ladda';
import { uniqueId } from 'lodash-es';

export default {
  name: 'CheckoutButtonsComponent',
  props: {
    backButtonText: String,
    nextButtonText: String,
    checkoutButtonText: String,
    isWaiting: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    isLadda: {
      type: Boolean,
      default: false,
    },
    showNextIcon: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    id: `ladda-${uniqueId(15)}`,
    laddaButton: undefined,
  }),
  emits: ['onBackClicked', 'onNextClicked', 'onCheckoutClicked'],
  watch: {
    isWaiting(newValue, oldValue) {
      if (!this.isLadda) return;
      if (newValue) {
        this.laddaButton.start();
      } else {
        this.laddaButton.stop();
      }
    },
    id(newVal, oldVal) {
      this.laddaButton = Ladda.create(document.querySelector(`#${this.id}`));
    },
  },
  mounted() {
    this.laddaButton = Ladda.create(document.querySelector(`#${this.id}`));
  },
  beforeUnmount() {
    this.laddaButton.remove();
  },
  methods: {
    onBackClicked() {
      this.$emit('onBackClicked');
    },
    onNextClicked() {
      this.$emit('onNextClicked');
    },
    onCheckoutClicked() {
      this.$emit('onCheckoutClicked');
    },
  }
};
</script>