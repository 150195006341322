import settings from '../../../settings';
import { getTotalNominalPriceForTravelers } from '../booking/getTotalPriceForTravelers';

export const createSeatingAnalysisItem = (type, code, quantity, icon, description, travelingEntities, occupantsString) => {
  if (!settings.constants[type]) {
    throw `${type} is not a known seating analysis type`;
  }

  if (travelingEntities.length <= 0) {
    throw `travelingEntities cannot be empty`;
  }

  // from the list of travelingEntities for this item (either passengers, vehicles or pets)
  // keep the information that are necessary in order to map back to the
  // proper entities
  const occupantIndexes = travelingEntities.map(o => {
    switch (type) {
      case settings.constants.PASSENGER:
        return o.passengerIndex;
      case settings.constants.VEHICLE:
        return o.vehicleIndex;
      case settings.constants.PET:
        return o.petIndex;
    }
  });

  return {
    type,
    code,
    quantity,
    icon,
    description,
    occupantsString,
    isInfantGroup: travelingEntities[0].isInfant || false,
    occupantIndexes,
    itemPrice: getTotalNominalPriceForTravelers(travelingEntities)
  };
};

export const isPassengerSeating = seatingAnalysisItem => {
  return seatingAnalysisItem.type === settings.constants.PASSENGER;
};

export const isVehicleSeating = seatingAnalysisItem => {
  return seatingAnalysisItem.type === settings.constants.VEHICLE;
};

export const isPetSeating = seatingAnalysisItem => {
  return seatingAnalysisItem.type === settings.constants.PET;
};
