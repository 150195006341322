export const getDetailedPricing = item => {

    const { hasPriceBreakdown, Price, Taxes, Vat, AddFees1, AddFees2, FareCode } = item;
    return {
      hasPriceBreakdown, 
      Price, 
      Taxes, 
      Vat, 
      AddFees1, 
      AddFees2, 
      FareCode
    }
  }