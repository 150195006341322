<template>
  <span :class="toggleClass" @click="handleClick">
    <span v-if="noOptionProvided" :class="placeholderClass">{{ placeholder }}</span>
    <span v-else class="text-ellipsis">{{ computedOption }}</span>
    <i class="fh fh-down-arrow p5"></i>
  </span>
</template>

<script>
import { isNull } from '@/logic/helpers/utils';

export default {
  name: 'FhDropdownToggle',
  props: {
    option: Object,
    showDropdown: Boolean,
    optionKeyToShow: String,
    optionExtraKeyToShow: String,
    placeholder: String,
    truncate: Boolean,
    commaSeparated: Boolean,
    grayPlaceholder: Boolean,
  },
  emits: ['click'],
  methods: {
    handleClick() {
      this.$emit('click');
    },
  },
  computed: {
    toggleClass() {
      let truncateClass = this.truncate ? `${this.$style['fhDropdownToggle--truncate']}` : '';
      const cobrandedClass = this.globalCobrandedVariant === 'easyferry' ? this.$style['fhDropdownToggle--round-border'] : '';
      return `${this.$style.fhDropdownToggle} ${truncateClass} ${cobrandedClass}`;
    },
    placeholderClass() {
      return this.grayPlaceholder ? `${this.$style['fhDropdownToggle__placeholder--gray']}` : '';
    },
    computedOption() {
      if (!this.extarKeyExists) return this.optionMainKey;
      if (this.commaSeparated) return `${this.optionMainKey}, ${this.optionExtraKey}`;
      return `${this.optionMainKey} ${this.optionExtraKey}`;
    },
    noOptionProvided() {
      return isNull(this.option);
    },
    optionMainKey() {
      return `${this.option[this.optionKeyToShow]}`;
    },
    optionExtraKey() {
      return `${this.option[this.optionExtraKeyToShow]}`;
    },
    extarKeyExists() {
      return this.optionExtraKeyToShow !== '' && this.option[this.optionExtraKeyToShow] !== '';
    },
  },
};
</script>

<style module lang="scss">
@import '@/assets/sass/components/fh-dropdown/_fh-dropdown-toggle.scss';
</style>
