<template>
  <div class="fh-loader mt100">
    <div class="loader"></div>
  </div>
</template>

<script>
export default {
  name: 'LoadingSpinner',
  props: {
    text: String,
    subtitle: String,
  },
};
</script>
