
import { each as _each } from 'lodash-es'
import { roundPrice } from '@/logic/helpers/utils'
import settings from '@/settings'
import ClosestTripPipe from '@/logic/pipes/ClosestTripPipe'
import { PET_POLICIES } from '@/logic/repositories/PetsRepository';

const tripsHavePetSupport = (trips) => {    
  const allTripPolicies = trips.reduce((acc, trip) => {

    // if the trip has not been selected yet, we are optimistic
    if (!trip) return acc;

    if (trip.isDirectTrip) {
      return [
        ...acc,
        trip.petPolicy.type
      ];
    } 
    
    return [
      ...acc,
      ...trip.segments.map(segment => segment.petPolicy.type)
    ];
  }, []);
  
  return allTripPolicies.every(policy => policy !== PET_POLICIES.NOTALLOWED);
};

// instance of cart, since we need this to be a singleton object during
// a user session
let instance = null;

var CartManager = function() {
  if (instance !== null) {
    throw new Error('Use CartManager.getInstance()');
  }
  this.initialize();
}

CartManager.newInstance = function() {
  if (null === instance) {
    instance = new CartManager();
  }
  return instance;
};

CartManager.prototype = {
  
  initialize: function() {
    this.expectedTripsCount = 0;
    this.selectedTripsCount = 0;
    this.overallPrice = 0.0;
    this.hasGarage = true;
    this.hasPets = true;
    this.hasCloseDateTrip = false;
    this.hasTimeOverlaps = false;
    this.hasReachedMaxTrips = false;
    this.selectedTrips = [];
  },
  
  reset: function() {
    this.initialize();
  },
  
  // check that all selected segments have a garage
  checkGarage: function() {
    let segmentHasGaraze = true;
    _each(this.selectedTrips, (trip) => {
      if (typeof trip !== 'undefined') {
        if (trip.isDirectTrip === true) {
          segmentHasGaraze = segmentHasGaraze && ((trip.Details.hasGaraze === true) && (trip.vehicleAccommodations.length > 0));
        } else {
          _each(trip.segments, (segment) => {
            segmentHasGaraze = segmentHasGaraze && ((segment.Details.hasGaraze === true) && (segment.vehicleAccommodations.length > 0));
          });
        }
      }
    });
    return segmentHasGaraze;
  },
    
  getSelectionCount: function() {
    return this.selectedTripsCount;
  },
  
  checkSelectionCount: function() {
    return this.selectedTripsCount === this.expectedTripsCount;
  },
  
	// Check if a time overlap between selected routes exists
	checkTimeOverlaps: function() {

		var arrivalTimes = [];
		var departureTimes = [];

		// push each trip (direct or indirect) into a flat array
		_each(this.selectedTrips, (trip) => {
			if (typeof trip !== 'undefined') {
        const departureTime = trip.isDirectTrip ? trip.timings.DepartureDateTime
          : trip.segments[0].timings.DepartureDateTime;

        const arrivalTime = trip.isDirectTrip ? trip.timings.ArrivalDateTime 
          : trip.segments[trip.segments.length - 1].timings.ArrivalDateTime;
          
        departureTimes.push(departureTime);
        arrivalTimes.push(arrivalTime);
			}
		});

		var timeConflicts = [];
		_each(departureTimes, function(dep, index) {

			if (index > 0) {
				var arr = arrivalTimes[index-1];
				var isFirstTripAfterSecond = arr.isAfter(dep, 'minutes');
				var waitMinutes = dep.diff(arr, 'minutes');
        
				if ((waitMinutes < settings.limits.MINIMUM_PORT_WAIT_TIME) || (isFirstTripAfterSecond === true)) {
					timeConflicts.push(index);
				}
			}
		});

		// if a conflict exists, render the notification warning of the first
		// warning and return false. this way, the user cannot checkout
    if (timeConflicts.length > 0) {
      return true;
    }

		return false;
	},
  
  addTrip: function(tripItem, tripIndex) {
    this.selectedTrips[tripIndex] = tripItem;
    this.selectedTripsCount++;
    this.updateCart();
    return tripItem;
  },
  
  removeTrip: function(tripIndex) {
    
    let tripItem = this.selectedTrips[tripIndex];
    this.selectedTrips[tripIndex] = undefined;
    this.selectedTripsCount--;
    this.updateCart();

    return tripItem;
  },
  
  deselectAll: function() {
    for (let i = 0; i < this.expectedTripsCount; i++) {
      this.selectedTrips[i] = undefined;
    }
    this.selectedTripsCount = 0;
    this.updateCart();
  },
  
  // loop through selected items (direct or indirect trips) and update cart
  // properties such as overall price, days to first departure etc.
  updateCart: function() {

    let overallPrice = 0.0;
    let hasCloseDaySelection = false;
    let segmentsCount = 0;

    _each(this.selectedTrips, (trip) => {
      // check that a trip is selected for this index
      if (typeof trip !== 'undefined') {

        // update the price
        overallPrice += trip.minPrice;

        // check for close day departure
        if (trip.isDirectTrip === true) {
          segmentsCount++;
          hasCloseDaySelection = hasCloseDaySelection || (ClosestTripPipe.fromTripsArray([trip]).difference < settings.limits.CLOSE_TRIP_WARNING_LIMIT);
        } else {
          segmentsCount += trip.segments.length;
          hasCloseDaySelection = hasCloseDaySelection || (ClosestTripPipe.fromTripsArray(trip.segments).difference < settings.limits.CLOSE_TRIP_WARNING_LIMIT);
        }
      }
    });

    // update overall price
    this.overallPrice = roundPrice(overallPrice, 2);
    // update close departure date flag
    this.hasCloseDateTrip = hasCloseDaySelection;
    // check if the selected trips have conflicing time overlaps
    this.hasTimeOverlaps = this.checkTimeOverlaps();
    // check that all trips have garage
    this.hasGarage = this.checkGarage();
    // check that all trips have pet support
    this.hasPets = tripsHavePetSupport(this.selectedTrips);
    // check if the maximum number of single segments has been exceeded
    this.hasReachedMaxTrips = (segmentsCount > settings.limits.MAXIMUM_TRIPS_PER_BOOKING);
  },
  
  getFlatTrips() {
    return this.selectedTrips.reduce((acc, trip) => {
      if (!trip) return acc;
      if (trip.isDirectTrip) return [...acc, trip];
      return [...acc, ...trip.segments];
    }, []);
  }
};

export default CartManager.newInstance();
