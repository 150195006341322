import { createStore } from 'vuex';
import { searchModule } from '@/store/modules/search.module';
import { navigationModule } from '@/store/modules/navigation.module';
import { dataModule } from '@/store/modules/data.module';
import { bookingModule } from './modules/booking.module';
import { ticketConfigurationModule } from './modules/ticketConfiguration.module'; 
import { uiModule } from './modules/ui.module';
import { invoiceModule } from './modules/invoice.module';
import { userModule } from './modules/user.module';
import { validationModule } from './modules/validation.module';
import VuexPersist from 'vuex-persist';

// initial state Object
let initialState = {
  dataModule: { ...dataModule.state },
  ticketConfigurationModule: { ...ticketConfigurationModule.state },
  bookingModule: { ...bookingModule.state },
  uiModule: { ...uiModule.state },
  invoiceModule: { ...invoiceModule.state },
  userModule: { ...userModule.state },
};

const vuexSessionStorage = new VuexPersist({
  storage: window.sessionStorage,
  modules: ['bookingModule', 'uiModule', 'invoiceModule', 'dataModule', 'userModule']
});

export const store = createStore({
  modules: {
    searchModule,
    navigationModule,
    dataModule,
    ticketConfigurationModule,
    bookingModule,
    uiModule,
    invoiceModule,
    userModule,
    validationModule,
  },
  mutations: {
    ['RESET_STATE'](state) {
      Object.keys(state).forEach((key) => {
        Object.assign(state[key], initialState[key]);
      });
    },
  },
  plugins: [vuexSessionStorage.plugin],
});
