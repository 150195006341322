<template>
  <div :class="$style.refund">
    <div :class="$style.refund__main">
      <div :class="$style.refund__text">
        {{ trans('passDet.FHsupport.standard.explanation.text') }}
      </div>
      <span :class="[$style.refund__text, 'text-primary']" @click.stop="showStandardSupportModal = true">{{ trans('generic.viewDetails') }} </span>
    </div>

    <FhModal v-if="showStandardSupportModal" @close="showStandardSupportModal = false" :title="trans('passDet.FHsupport.standard.modal.heading')">
      <template #body>
        <p>{{ trans('passDet.FHsupport.standard.modal.text') }}</p>
        <ul>
          <li>{{ trans('passDet.FHsupport.standard.modal.bullet.1') }}</li>
          <li>{{ trans('passDet.FHsupport.standard.modal.bullet.2') }}</li>
          <li>{{ trans('passDet.FHsupport.standard.modal.bullet.3') }}</li>
          <li>{{ trans('passDet.FHsupport.standard.modal.bullet.4') }}</li>
        </ul>
      </template>
    </FhModal>
  </div>
</template>

<script>
import { trans } from '@/filters';

export default {
  name: 'StandardSupport',
  data: () => ({
    showStandardSupportModal: false,
  }),
  methods: {
    trans,
  },
};
</script>

<style module lang="scss">
@import '../refund/refund.scss';
</style>
