<template>
  <span :class="$style.passengerDetailsSelection">
    <span :class="$style['passengerDetailsSelection__no-wrap']">
      <img height="14" width="14" :src="iconUrl" alt="Company icon" class="mr8 mb2" />
      <span :class="$style.passengerDetailsSelection__description">{{ passengerDiscountType }},&nbsp;</span>
    </span>
    <span :class="$style.passengerDetailsSelection__description">
      <span>{{ description }}</span>
      <span v-for="discount in extraOffersAndDiscounts" :key="discount.key">
        <span class="mx8">|</span>
        <span>{{ discount.extraDiscountName }}: {{ discount.extraDiscountNumber }} </span>
      </span>
    </span>
  </span>
</template>

<script>
export default {
  name: 'PassengerDetailsSelection',
  props: {
    iconUrl: String,
    description: String,
    passengerDiscountType: String,
    extraOffersAndDiscounts: {
      type: Array,
      default() {
        return [];
      }
    }
  }
};
</script>

<style module lang="scss">
@import './passenger-details-selection.scss';
</style>
