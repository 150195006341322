import * as types from '../mutation-types';
import { fetchCountries } from '@/logic/services/ApiCRS';
import { logException } from '@/logic/services/events/errorLogging';
import { fetchWithRetry } from '../../logic/helpers/fetchWithRetry';
import { addLabelsToNationalityOptions } from '@/components/shared/inputs/nationalityUtils';

export const dataModule = {
  namespaced: true,
  state: {
    categorizedCountries: [],
    allCountries: []
  },
  getters: {
    countriesWithPhoneCode: state => state.allCountries.filter((c) => c.phoneCode !== null),
  },
  mutations: {
    [types.SET_COUNTRIES](state, payload) {
      if (payload.countriesList.length !== 0) {
        state.allCountries = payload.countriesList.allCountries;
        state.categorizedCountries = addLabelsToNationalityOptions(payload.countriesList);
      }
    },
  },
  actions: {
    /**
    * Fetch countries list array from server
    */
    async getCountries({ commit }) {
      await fetchWithRetry(fetchCountries)
        .then((res) => commit(types.SET_COUNTRIES, res))
        .catch((error) => logException('API:Failed to load countries', error));
    }

  }
}