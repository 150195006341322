<template>
  <FhDropdown
    :id="id"
    :hasError="hasError"
    :value="value"
    :placeholder="`+${placeholder}`"
    :options="countriesWithPhoneCode"
    :optionKeyToTrack="'phoneCode'"
    :optionExtraKeyToSearch="'name'"
    :optionKeyToShow="'phoneCode'"
    :hasInputValue="true"
    :hasSearch="true"
    :textSize="'small'"
    :grayPlaceholder="true"
    :dropdownContentUp="dropdownContentUp"
    @click="handleClick"
    @blur="handleBlur"
    @focus="handleFocus"
    @input="handlePhoneCodeInput"
  >
    <template #options="{ select, dropdownOptions }">
      <FhDropdownOption
        v-for="(country, countryIndex) in dropdownOptions"
        :key="`country-dropdown-${countryIndex}`"
        :text="`${country.name} (${country.phoneCode})`"
        :index="countryIndex"
        @click="select(country, countryIndex)"
      />
    </template>
  </FhDropdown>
</template>

<script>
import { trans } from '@/filters';
import { mapActions, mapGetters } from 'vuex';
import validationMixin from '@/logic/validation/validationMixin';

export default {
  name: 'CountryCodeInput',
  mixins: [validationMixin],
  props: {
    id: String,
    placeholder: {
      type: String,
      default: '',
    },
    dropdownContentUp: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      isInputActive: false,
    };
  },
  computed: {
    ...mapGetters({
      countriesWithPhoneCode: 'dataModule/countriesWithPhoneCode',
    }),
    hasError() {
      return !this.isInputActive && this.errors.some((error) => error.name === this.name);
    },
  },
  methods: {
    trans,
    ...mapActions({
      removeError: 'validationModule/removeError',
    }),
    handlePhoneCodeInput($event) {
      this.$emit('update:modelValue', $event ? $event.phoneCode : '');
    },
    handleClick() {
      this.removeError({ name: this.name });
    },
    handleBlur() {
      this.isInputActive = false;
      this.validate();
    },
    handleFocus() {
      this.isInputActive = true;
      this.removeError({ name: this.name });
    },
  },
};
</script>
