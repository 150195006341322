<template>
  <div class="pet-kennel">
    <ToggleSwitch :value="isToggleOn" @input="onChangePetAccomodation" :disabled="false" :text="toggleText" />
  </div>
</template>

<script>
export default {
  name: 'PetAccomodationSelect',
  props: ['pet', 'options'],
  emits: ['selectedAccommodation'],
  computed: {
    defaultAccommodation() {
      return this.options.find((o) => o.isDefault);
    },
    upgradedAccommodation() {
      return this.options.find((o) => !o.isDefault);
    },
    isToggleOn() {
      return this.pet.selectedAccommodation !== this.defaultAccommodation.abbr;
    },
    toggleText() {
      return this.upgradedAccommodation.petAccommodationDescription;
    },
  },
  methods: {
    onChangePetAccomodation() {
      const newAccommodationAbbr = this.isToggleOn ? this.defaultAccommodation : this.upgradedAccommodation;
      this.$emit('selectedAccommodation', newAccommodationAbbr);
    },
  },
};
</script>