import { isJsonPropTrue } from './../helpers/utils';

const companyDetailsRequirementsFlags = [
  'domesticBirthday',
  'internationalBirthday',
  'domesticBirthplace',
  'internationalBirthplace',
  'domesticNationality',
  'internationalNationality',
  'domesticPassport',
  'internationalPassport',
  'domesticDocExpiration',
  'internationalDocExpiration'
];

/**
 * Convert company objects that we receive from the API into usable structures
 * @param {any} company
 */
export const adaptCompany = company => {
  const {
    CompanyAbbr,
    CompanyName,
    supported,
    provider,
    needsFare,
    hasElectronicTicket,
    separatePNRs,
    supportsResidentCode,
    allowsDomesticMotorhomes,
    allowsSupportPlus,
    allowsFlexihopper,
    petDocumentRequired
  } = company;

  let basicCompanydata = {
    // company code, unique per company
    CompanyAbbr: CompanyAbbr,
    // official company name
    CompanyName: CompanyName,
    // when this flag is true, booking is allowed for this company
    supported: isJsonPropTrue(supported),
    // the current company provider, used for using/discarding an itinerary
    provider: provider,
    // when this flag is true, fare quotation is applied to pricing requests
    requiresFareQuotation: isJsonPropTrue(needsFare),
    // when this flag is true, company is shown as supporting e-ticket
    hasElectronicTicket: isJsonPropTrue(hasElectronicTicket),
    // when this flag is true, PNRs are not combined for the company
    separatePNRs: isJsonPropTrue(separatePNRs),
    // when this flag is true, resident code discounts are enabled for the company
    supportsResidentCode: isJsonPropTrue(supportsResidentCode),
    // if true, we show motorhomes and COB in domestic trips
    allowsDomesticMotorhomes: isJsonPropTrue(allowsDomesticMotorhomes),
    // if true, bookings with this company can include support plus
    allowsSupportPlus: isJsonPropTrue(allowsSupportPlus),
    // if true, bookings with this company can include flexi hopper
    allowsFlexi: isJsonPropTrue(allowsFlexihopper),
    // if true, we need to provide pet document for booking a pet ticket
    isPetDocumentRequired: isJsonPropTrue(petDocumentRequired)
  };

  return {
    // add basic company data
    ...basicCompanydata,
    // add company booking flags
    ...adaptCompanyRequirementsKeys(company),
    // create an array of empty company discounts
    discounts: []
  };
};

/**
 * Create an object that contains a list of booking requirements
 * for domestic and international trips
 *
 * @param {any} company
 */
const adaptCompanyRequirementsKeys = company => {
  let requirements = {};
  companyDetailsRequirementsFlags.forEach(flag => (requirements[flag] = isJsonPropTrue(company[flag])));
  return requirements;
};
