import { getPageLanguage } from '@/logic/helpers/utils';

/**
 * Creates an Islander Code request body that can be sent to the server for validation.
 * @param {Object[]} filtered An array of passengers that have a valid islander's code.
 * @param {Object} trip
 * @returns {{provider: string, companyCode: string, passengers: Object[]}} The islander code request body.
 */
export function createValidationRequest(filteredPsg, trip) {
  // Creates an array of objects with only the required passenger properties.
  const passengers = filteredPsg.map(psg => ({
    firstName: psg.firstName || '',
    lastName: psg.lastName || '',
    islanderCode: psg.islanderCode || ''
  }));

  //TODO Multiple trips with different company codes?
  const companyCode = trip.Details.Company;
  const { provider } = trip;

  const lang = getPageLanguage();

  return { companyCode, provider, lang, passengers };
}
