<template>
  <div :class="[$style.selectableCardSkeletonAlt]">
    <div :class="[$style.selectableCardSkeletonAlt__main]">
      <div :class="[$style.selectableCardSkeletonAlt__item, $style.selectableCardSkeletonAlt__text, 'skeleton-animation']"></div>
      <div :class="[$style.selectableCardSkeletonAlt__item, $style.selectableCardSkeletonAlt__image, 'skeleton-animation']"></div>
    </div>
    <hr :class="$style.selectableCardSkeletonAlt__hr" />
    <div :class="[$style.selectableCardSkeletonAlt__item, $style.selectableCardSkeletonAlt__footer, 'skeleton-animation']"></div>
  </div>
</template>

<script>
export default {
  name: 'SelectableCardSkeletonAlt'
};
</script>

<style module lang="scss">
@import './selectable-card-skeleton-alt.scss';
</style>
