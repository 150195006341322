
import { createTripIssueRequest } from './createTripIssueRequest';
import { hydrateTrip } from '../../hydrators/bookableTripHydrator';

export const createCombinedIssueRequests = (trips, tripsCombinations, contactInfo) => {

  let hydratedTrips = trips.map(trip => hydrateTrip(trip));
  let issueRequests = trips.map(trip => createTripIssueRequest(trip, contactInfo))

  // Add hydrated trips in proper order in a flat array
  let tripDescriptions = tripsCombinations.reduce((acc, combination) => {
    return [
      ...acc,
      ...combination.map((index) => hydratedTrips[index])
    ]
  }, []);

  // Add issue requests in a proper order in an array of arrays
  let combinedIssueRequests = tripsCombinations.reduce((acc, combination) => {
    return [
      ...acc,
      combination.map((index) => issueRequests[index])
    ]
  }, []);
  

  return {
    combinedIssueRequests,
    tripDescriptions
  }
}