import { VEHICLE_TYPES } from "./vehicleTypes";

const isNumeric = (value) => !isNaN(value);


export const adaptCompanyVehicles = (vehicles) => {
  let cars = vehicles.filter((v) => v.isCar);
  let motorbikes = vehicles.filter((v) => v.isMotorbike);
  let bicycles = vehicles.filter((v) => v.isBicycle);
  let motorhomes = vehicles.filter((v) => v.isMotorhome);
  let onBoardOptions = vehicles.filter((v) => v.isCampingOnBoard).filter((v) => isNumeric(v.meters));

  // Onboard option is created for every motorhome. This is achieved by comparing
  // meter values (numeric) of motorhomes and onBoardOptions
  motorhomes.map((m) => {
    m.onBoardOption = onBoardOptions.find((o) => Number(o.meters) === Number(m.meters));
    m.hasOnBoardOption = typeof m.onBoardOption !== 'undefined';
  });

  // return the concatenated array but remove the isCampingOnBoard flag that we
  // don't want to carry to the application
  return [...cars, ...motorbikes, ...motorhomes, ...bicycles].map((v) => {
    delete v.isCampingOnBoard;
    return v;
  });
};

/**
 * Converts an array of vehicles
 * @param {any} vehicles
 * @param {any} categories
 */
export const adaptVehicles = (vehicles, categories) => {
  return vehicles.map((v) => adaptVehicle(v, categories)).filter((v) => v.isSupported);
};

/**
 * Returns a composite object that contains both information about the vehicle
 * as well as information about it's category
 * @param {any} vehicle
 * @param {any} categories
 */
export const adaptVehicle = (vehicle, categories) => {
  // then extract vehicle details
  const vehicleDetails = {
    company: adaptCompany(vehicle),
    meters: adaptMeters(vehicle),
    vehicleTypeAbbr: adaptVehicleTypeAbbr(vehicle),
    hasOnBoardOption: false,
  };

  // extract details related to the vehicle category
  const vehicleCategoryDetails = adaptCategory(vehicle, categories);

  return {
    ...vehicleDetails,
    ...vehicleCategoryDetails,
  };
};

/**
 * @param {any} vehicle
 */
const adaptCompany = (vehicle) => {
  return vehicle.company;
};

/**
 * @param {any} vehicle
 */
const adaptMeters = (vehicle) => {
  return vehicle.meters;
};

/**
 * @param {any} vehicle
 */
const adaptVehicleTypeAbbr = (vehicle) => {
  return vehicle.vehicleTypeAbbr;
};

/**
 * Extract vehicle category information
 * @param {any} vehicle
 * @param {any[]} categories
 */
const adaptCategory = (vehicle, categories) => {
  const categoryCode = vehicle.categoryCode;
  const categoryDetails = categories[categoryCode];
  if (typeof categoryDetails === 'undefined') {
    return {
      isSupported: false,
    };
  }
  return {
    categoryCode: categoryCode,
    categoryDescrition: categoryDetails.categoryDescrition,
    categoryOrder: categoryDetails.categoryOrder,
    extraDetails: categoryDetails.extraDetails,
    isCar: categoryDetails.isCar,
    isMotorbike: categoryDetails.isMotorbike,
    isMotorhome: categoryDetails.isMotorhome,
    isBicycle: categoryDetails.isBicycle,
    isCampingOnBoard: categoryDetails.isCampingOnBoard,
    vehicleCategoryType: mapVehicleCategoryToType(categoryDetails),
    icon: categoryDetails.icon,
    isSupported: true,
  };
};

const mapVehicleCategoryToType = (categoryDetails) => {
  if (categoryDetails.isCar) return VEHICLE_TYPES.CAR;
  if (categoryDetails.isMotorbike) return VEHICLE_TYPES.MOTORBIKE;
  if (categoryDetails.isMotorhome) return VEHICLE_TYPES.MOTORHOME;
  if (categoryDetails.isBicycle) return VEHICLE_TYPES.BICYCLE;
  if (categoryDetails.isCampingOnBoard) return VEHICLE_TYPES.CAMPINGONBOARD;
  if (categoryDetails.isTrailer) return VEHICLE_TYPES.TRAILER;
  return VEHICLE_TYPES.OTHER;
};

export const mergeVehicleWithSavedVehicle = (vehicle, savedVehicle) => {
  const { brand, licencePlate, model, timesTravelled } = savedVehicle;

  return {
    ...vehicle,
    licencePlate,
    vehicleBrand: brand,
    vehicleModel: model,
    isNewVehicle: false,
    shouldSaveDetails: false,
    timesTravelled,
  };
};

export const getDefaultVehicleDetails = (vehicle) => {
  return {
    ...vehicle,
    licencePlate: '',
    vehicleBrand: '',
    vehicleModel: '',
    isNewVehicle: true,
    shouldSaveDetails: false,
  };
};
