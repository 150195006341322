import settings from '../../../settings';
import PortsRepository from '../../repositories/PortsRepository';
import { getLastSavedSearch } from '../../services/storage/saveLastSearch';
import { isBeforeToday, isDatesOrderInvalid } from '../../helpers/dateUtils';
import UrlParametersManager from './UrlParametersManager';
import { getConfigurationNumber } from '../../helpers/utils';
import { mapDatesFromUrlParams } from '../../helpers/urlUtils';

// Dayjs
import dayjs from '@/logic/services/date/dateService';
import { dateIsBeforeToday } from '@/logic/helpers/dateUtils';

/**
 * Scenarios
 *
 * Scenario 1: Search from url
 * Search state is initialized from the search url, given that (a) itinerary exists and (b) dates exist
 * If both (a) and (b) are valid, then we need to verify that
 * - Port codes are correct
 * - Dates are not in the past
 * - Port codes and dates are sufficient to build trips -> If number of ports is X, number of dates is X-1
 *
 * Scenario 2: Initialize form from url
 * Search state is initialized with default dates, while ports are filled from initial= param
 * In order to create this state, ports must be known
 *
 * Scenario 3: Initialize form from saved search (mobile only)
 * In order to achieve this, the rules are the same as in scenario 1, with the only difference being that
 * we do not auto-perform the search
 *
 * Scenario 4: Default scenario
 * Search state is initialized to default state with empty ports and fixed dates
 * The trips are either one (single trip) or two (round trip) depending on the window setting
 * while the searchMode is set to 'single'
 */
const getSearchStateFromWindow = () => {
  const windowSearchSetup = window.initial_search_setup || {};
  let defaultSearchState = {
    isDefaultState: true,
    isAutoSearch: false, // Indicates that the current search can be automatically applied for search
    isSavedSearch: false,
    searchMode: windowSearchSetup.mode || 'single',
    isRoundTrip: windowSearchSetup.roundTrip || false,
    tripsCount: windowSearchSetup.tripsCount || 1,
    passengers: 1,
    vehicles: 0,
    pets: 0
  };

  let outboundDate = dayjs().add(getConfigurationNumber('default_search_date_distance', 2), 'days');
  let inboundDate = defaultSearchState.isRoundTrip ? outboundDate.clone().add(3, 'days') : undefined;

  return {
    ...defaultSearchState,
    selectedPorts: Array(5).fill({ short: '' }),
    selectedDates: [outboundDate, inboundDate, undefined, undefined]
  };
};

export const DEFAULT_STATE = getSearchStateFromWindow();
const emptyFallbackState = { locations: [], dates: [], passengers: 1, vehicles: 0, pets: 0 };

export const getInitialSearchState = () => {
  // Implement scenario 1: search state from url, with fallback to default state
  return getSearchStatefromUrl();
};

export const getSearchStatefromStorage = () => {
  const { locations, dates } = getLastSavedSearch() || emptyFallbackState;
  const statefromStorage = createSearchState(locations, dates);
  return { ...statefromStorage, source: 'STORAGE', isSavedSearch: !statefromStorage.isDefaultState };
};

export const getSearchStatefromUrl = () => {
  const urlState = UrlParametersManager.getSearchParameters();
  const { locations, dates, passengers, vehicles, pets, isSearchByDate } = urlState || emptyFallbackState;
  const statefromUrl = createSearchState(locations, dates, passengers, vehicles, pets, isSearchByDate);

  const initialLocationsFromUrl = locations || [];
  const initialValidPorts = getValidPortsFromPortCodes(initialLocationsFromUrl);

  if (statefromUrl.isDefaultState && initialValidPorts.length > 0) {
    return { ...statefromUrl, selectedPorts: mergeSearchItems(statefromUrl.selectedPorts, initialValidPorts), source: 'URL', isAutoSearch: !statefromUrl.isDefaultState };
  }
  return { ...statefromUrl, source: 'URL', isAutoSearch: !statefromUrl.isDefaultState };
};

export const createSearchState = (locations, dates, passengers = 1, vehicles = 0, pets = 0, isSearchByDate = true) => {
  const selectedPorts = getValidPortsFromPortCodes(locations || []);
  const selectedDates = getValidDatesFromStringDates(dates || [], isSearchByDate);

  if (!isValidPortsDatesCombination(selectedPorts, selectedDates)) {
    return DEFAULT_STATE;
  }

  const tripsCount = selectedDates.length;
  const isRoundTrip = tripsCount === 2 && selectedPorts[0].LocationAbbr === selectedPorts[2].LocationAbbr;
  const searchMode = tripsCount > 1 && !isRoundTrip ? 'multi' : 'single';

  return {
    ...DEFAULT_STATE,
    isDefaultState: false,
    passengers,
    vehicles,
    pets,
    selectedPorts: mergeSearchItems(DEFAULT_STATE.selectedPorts, selectedPorts),
    selectedDates: mergeSearchItems(DEFAULT_STATE.selectedDates, selectedDates),
    searchMode,
    isRoundTrip,
    tripsCount
  };
};

const isValidPortsDatesCombination = (ports, dates) => {
  return ports.length === dates.length + 1 && ports.length > 1;
};

/**
 * Returns a list of Ports only if all port codes included in the array
 * actually refers to a known port
 * @param {String[]} portCodes
 * @returns
 */
const getValidPortsFromPortCodes = portCodes => {
  const ports = portCodes.map(code => PortsRepository.getPort(code));
  if (ports.some(p => !p)) return [];
  return ports;
};

/**
 * Returns a list of dayjs dates from a list of YYYYMMDD dates,
 * only if all dates found are valid candidates for search dates.
 * - Belong to today or future
 * - Have proper format
 * - Are in proper order
 *
 * @param {String[]} dateStrings
 * @returns {Object[]}
 */
const getValidDatesFromStringDates = (dateStrings, isSearchByDate) => {
  const dates = mapDatesFromUrlParams(dateStrings, isSearchByDate);
  const dayjsDates = dates.map(d => dayjs(d, settings.formats.dayjsToFCRS));
  if (dayjsDates.some(d => !isValidSearchDate(d))) return [];
  if (isDatesOrderInvalid(dayjsDates)) return [];

  return dayjsDates;
};

const mergeSearchItems = (defaultItems, newItems) => {
  return defaultItems.map((defaultItem, itemIndex) => {
    return newItems[itemIndex] || defaultItem;
  });
};

/**
 * Returns true if a dayjs date is actually a dayjs object that
 * is not before today
 * @param {Object} date
 * @returns {boolean}
 */

const isValidSearchDate = date => {
  return dayjs.isDayjs(date) && !dateIsBeforeToday(date);
};
