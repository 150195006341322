<template>
  <p class="cart-notification"><i :class="classColor"></i> {{ message }}</p>
</template>

<script>
export default {
  name: 'CartNotification',
  props: ['message', 'level'],
  computed: {
    classColor() {
      if (this.level === 'info') {
        return 'fh fh-notification2 text-success';
      }

      if (this.level === 'warning') {
        return 'fh fh-notification2 text-accent';
      }

      if (this.level === 'danger') {
        return 'fh fh-notification text-danger';
      }
    }
  }
};
</script>
