<template>
  <div>
    <div :class="['location-selection-list__title', extraClass]" data-test="allLocationListTitle">{{ trans('searchmaskallportstitle') }}</div>
    <div v-for="(location, locationIndex) in allLocations" :key="'location-item-' + location.LocationAbbr" class="location-selection-list__item" @click="onListItemClick(location.LocationAbbr, location.alias, locationIndex)">
      <span>{{ location.alias }}</span>
      <span class="location-selection-list__item__country">{{ location.countryName }}</span>
    </div>
  </div>
</template>

<script>
import { trans } from '@/filters';

export default {
  name: 'AllLocationsSearchList',
  props: {
    allLocations: Array,
    hasOnlyAllCategory: Boolean
  },
  emits: ['onListItemClick'],
  computed: {
    extraClass() {
      // leave more space above All ports label in case there are other port categories on top or is mobile view
      return !this.hasOnlyAllCategory || this.isMobile ? 'mt20' : null;
    }
  },
  methods: {
    trans,
    onListItemClick(abbreviation, alias, locationIndex) {
      this.$emit('onListItemClick', abbreviation, alias, 'All Ports port selected', locationIndex);
    }
  }
};
</script>
