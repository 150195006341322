// @ts-check

import settings from '@/settings'
/**
 * Adapt list of vehicle categories
 * @param {any[]} categories 
 */
export const adaptVehicleCategories = (categories) => {  
    let vehicleCategories = [];
    categories
        .map(c => adaptVehicleCategory(c))
        .forEach(c => vehicleCategories[c.categoryCode] = c) 
    return vehicleCategories;
}

/**
 * Adapt vehicle category
 * @param {any} category 
 */
const adaptVehicleCategory = (category) => {
    return {
        categoryCode: category.categoryCode,
        categoryDescrition: category.categoryDescrition,
        categoryOrder: parseInt(category.categoryOrder),
        extraDetails: category.extraDetails,
        isCar: category.isCar,
        isMotorbike: category.isMotorbike,
        isMotorhome: category.isMotorhome,
        isCampingOnBoard: category.isConBoard,
        isBicycle: category.isBicycle,
        icon: adaptVehicleCategoryIcon(category)
    }
}

/**
 * Returns the proper vehicle category icon
 * @param {any} category 
 */
export const adaptVehicleCategoryIcon = (category) => {
    if (category.isCar) return settings.icons.vehicles.CAR;
    if( category.isMotorbike) return settings.icons.vehicles.MOTORBIKE;
    if( category.isMotorhome) return settings.icons.vehicles.MOTORHOME;
    if( category.isConBoard) return settings.icons.vehicles.ONBOARD;
    if( category.isBicycle) return settings.icons.vehicles.BICYCLE;
}