import { getConfigurationValue, getPageLanguage } from '@/logic/helpers/utils';
import { httpGet, httpPost } from './httpService';


let microRoot = getConfigurationValue('api', '');

if (process.env.NODE_ENV === 'development') microRoot = `http://micro.test/`;


//------------------------------------------------------------------------//
export const getSpecialOfferTerms = discountSlug => httpGet(`/${getPageLanguage()}/special-offers-api/details/${discountSlug}`, {});

export const getFaqInfo = slug => httpGet(`/${getPageLanguage()}/faq/api/get/${slug}`, {});

var termsFailureCounter = 0;
//------------------------------------------------------------------------//
// fetches the terms and conditions content asynchronously
// if the process fails, it will be reapeated three times
export const getBookingTerms = successFcn => {
  httpGet(`/${getPageLanguage()}/booking-terms`, {})
    .then(res => successFcn(res))
    .catch(() => {
      termsFailureCounter++;
      if (termsFailureCounter <= 3) {
        getBookingTerms(successFcn);
      }
    });
};

// Validate a coupon code
export const validateCoupon = payload => httpPost(`${microRoot}coupons/get-coupon`, payload);
