import { getAvailableAdultSeats, getAvailableInfantSeats } from '@/logic/filterers/SeatOptionsFilterer';
import { roundPrice } from '../../helpers/utils';
import GenericErrorCodes from '../../services/GenericErrorCodes';
import { ERROR_TYPES, createError, isErrorInBag } from '../errorBag/errorBag';
import { isFailedPricing } from '../pricing/PricingResponse';
import { isPassengerSeating, isPetSeating, isVehicleSeating } from '../pricing/seatingAnalysisItem';
import { TRIP_STATUS } from '../trips/TripStatus';
import { getDetailedPricing } from './getDetailedPricing';
import { getTotalPriceInCentsForTravelers } from './getTotalPriceForTravelers';
import { getTotalTicketPrice } from './getTotalTicketPrice';
import { eventBookingPricingError } from '@/logic/services/events/createBookingEvents';
import { getCategorizedSeats } from '@/logic/filterers/getCategorizedSeats';


export const resetTripPricingImmutable = (trip) => {
  return {
    ...trip,
    OverallPrice: undefined,
    totalTicketsPriceInCents: undefined,
    passengers: trip.passengers.map(p => ({
      ...p,
      ticketPriceInCents: undefined,
      detailedPricing: undefined
    })),
    vehicles: trip.vehicles.map(v => ({
      ...v,
      ticketPriceInCents: undefined,
      detailedPricing: undefined
    })),
    pets: trip.pets.map(p => ({
      ...p,
      ticketPriceInCents: undefined,
      detailedPricing: undefined
    })),
    SeatingAnalysis: trip.SeatingAnalysis.map(item => ({
      ...item,
      itemPrice: undefined
    })),
  }
}

export const setSeatingOptionsImmutable = (trip) => {
  let adultPassengers = trip.passengers.filter(passenger => !passenger.isInfant);
  let adultClasses = getAvailableAdultSeats(trip, adultPassengers.length);
  let infantClasses = getAvailableInfantSeats(trip);

  return {
    ...trip,
    nonInfantOptions: getCategorizedSeats(adultClasses),
    infantOptions: getCategorizedSeats(infantClasses)
  }
}

export const updateTripBeforePricingImmutable = (trip, { SeatingAnalysis, PricingErrors, ...pricingData }) => {
  
  const STATUS = PricingErrors.length > 0 ? TRIP_STATUS.PRICING_ERROR : TRIP_STATUS.PRICEABLE;
  const pricingErrors = PricingErrors.length > 0 ? PricingErrors : [];
  
  return {
    ...trip,
    pricingErrors,
    STATUS,
    PricingData: pricingData,
    SeatingAnalysis
  };
};

export const updateTripPricingImmutable = (trip, pricingResponse) => {
  if (isFailedPricing(pricingResponse)) {
    eventBookingPricingError(trip, pricingResponse);
    const { Status, StatusMessage, Company } = pricingResponse;
    const error = createError(Status, StatusMessage || GenericErrorCodes.get(Status), ERROR_TYPES.REMOTE, Company);

    const pricingErrors = [...trip.pricingErrors];
    if (!isErrorInBag(trip.pricingErrors, error)) {
      pricingErrors.push(error);
    }

    return {
      ...trip,
      STATUS: TRIP_STATUS.PRICING_ERROR,
      pricingErrors
    };
  }

  try {
    const passengers = getPassengersWithPrices(trip, pricingResponse);
    const vehicles = getVehiclesWithPrices(trip, pricingResponse);
    const pets = getPetsWithPrices(trip, pricingResponse);

    // on successful pricing response, we collect the additional extra attributes
    let extraAttributes = { ...trip.extraAttributes };
    if (typeof pricingResponse.extraAttributes !== 'undefined') {
      extraAttributes = {
        ...extraAttributes,
        ...pricingResponse.extraAttributes
      };
    }
    
    return {
      ...trip,
      STATUS: TRIP_STATUS.PRICED,
      passengers,
      vehicles,
      pets,
      SeatingAnalysis: getSeatingAnalysisWithPrices(trip, { passengers, vehicles, pets }),
      OverallPrice: getOverallTripPrice(passengers, vehicles, pets),
      totalTicketsPriceInCents: getTotalPriceInCentsForTravelers([...passengers, ...vehicles, ...pets]),
      pricingErrors: [],
      PricingData: {
        ...trip.PricingData,
        PricingResponse: pricingResponse
      },
      extraAttributes
    };
  } catch (exception) {
    const unexpectedError = createError('003', GenericErrorCodes.get('003'), ERROR_TYPES.REMOTE);

    const pricingErrors = [...trip.pricingErrors];
    if (!isErrorInBag(trip.pricingErrors, unexpectedError)) {
      pricingErrors.push(unexpectedError);
    }
    
    return {
      ...trip,
      STATUS: TRIP_STATUS.PRICING_ERROR,
      pricingErrors
    };
  }
};

export const getPassengersWithPrices = (trip, { PricesPerPassenger }) => {
  // get the insertion sequence of passengers
  const passengerInsertionSequence = trip.PricingData.InsertionSequence;

  if (PricesPerPassenger.length !== trip.passengers.length) {
    throw 'Missing passenger from pricing response';
  }

  // for each passenger in the trip's pricing response
  const passengers = [];
  PricesPerPassenger.forEach((item, pricingPerPassengerIndex) => {
    const passengerIndex = passengerInsertionSequence[pricingPerPassengerIndex];

    // get passenger pricing based on insertion sequence
    const originalPassenger = trip.passengers[passengerIndex];
    const totalPrice = getTotalTicketPrice(item);

    passengers[passengerIndex] = {
      ...originalPassenger,
      detailedPricing: getDetailedPricing(item),
      ticketPriceInCents: totalPrice
    };
  });

  return passengers;
};

export const getVehiclesWithPrices = (trip, { PricesPerVehicle }) => {
  // get the insertion sequence of vehicles
  const vehicleInsertionSequence = trip.PricingData.VehicleInsertionSequence;

  if (PricesPerVehicle.length !== trip.vehicles.length) {
    throw 'Missing vehicle from pricing response';
  }

  // for each vehicle in the trip's pricing response
  const vehicles = [];
  PricesPerVehicle.forEach((item, pricingPerVehicleIndex) => {
    const vehicleIndex = vehicleInsertionSequence[pricingPerVehicleIndex];

    // get vehicle pricing based on insertion sequence
    const originalVehicle = trip.vehicles[vehicleIndex];
    const totalPrice = getTotalTicketPrice(item);

    vehicles[vehicleIndex] = {
      ...originalVehicle,
      detailedPricing: getDetailedPricing(item),
      ticketPriceInCents: totalPrice
    };
  });

  return vehicles;
};

export const getPetsWithPrices = (trip, { PricesPerPet }) => {
  // get the insertion sequence of pets
  const petInsertionSequence = trip.PricingData.PetInsertionSequence;

  // for each pet in the trip's pricing response
  const pets = [];
  PricesPerPet.forEach((item, pricingPerPetIndex) => {
    const petIndex = petInsertionSequence[pricingPerPetIndex];

    // get pet pricing based on insertion sequence
    const originalPet = trip.pets[petIndex];
    const totalPrice = getTotalTicketPrice(item);

    pets[petIndex] = {
      ...originalPet,
      detailedPricing: getDetailedPricing(item),
      ticketPriceInCents: totalPrice
    };
  });

  return pets;
};

/**
 * After having fetched the prices for passengers, vehicles and pets update the
 * seating analysis prices
 */
export const getSeatingAnalysisWithPrices = (trip, { passengers, vehicles, pets }) => {
  return trip.SeatingAnalysis.map(item => {
    let itemPrice = 0;
    if (isPassengerSeating(item)) itemPrice = getTotalPriceInCentsForTravelers(item.occupantIndexes.map(i => passengers[i]));
    if (isVehicleSeating(item)) itemPrice = getTotalPriceInCentsForTravelers(item.occupantIndexes.map(i => vehicles[i]));
    if (isPetSeating(item)) itemPrice = getTotalPriceInCentsForTravelers(item.occupantIndexes.map(i => pets[i]));
    return {
      ...item,
      itemPrice: roundPrice(itemPrice / 100, 2)
    };
  });
};

const getOverallTripPrice = (passengers, vehicles, pets) => {
  let overallPassengersPrice = getTotalPriceInCentsForTravelers(passengers);
  let overallVehiclesPrice = getTotalPriceInCentsForTravelers(vehicles);
  let overallPetPrice = getTotalPriceInCentsForTravelers(pets);
  let overallPrice = roundPrice((overallPassengersPrice + overallVehiclesPrice + overallPetPrice) / 100, 2);
  return overallPrice;
};
