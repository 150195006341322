<template>
  <nav class="aegean-main-navigation" id="navtop">
    <div class="aegean-main-navigation__desktop">
      <div class="aegean-main-navigation__wrapper">
        <div class="aegean-main-navigation__logo-wrapper">
          <a :href="'/' + selectedLocale.code + '/'" title="aegeanFerry | Online ferry tickets for Greece & Italy">
            <img
              class="aegean-main-navigation__logo"
              :src="fhImage('assets/cobranded/aegeanferry/aegeanferry_logo.png')"
              alt="aegeanFerry | Online ferry tickets for Greece &amp; Italy"
            />
          </a>
          <div class="aegean-main-navigation__logo-slogan">
            <span>{{ trans('aegeanlogoslogan') }}</span>
          </div>
        </div>
        <button class="aegean-main-navigation__mobile-toggle">
          <span class="aegean-main-navigation__mobile-toggle-burger-bar"></span>
          <span class="aegean-main-navigation__mobile-toggle-burger-bar"></span>
          <span class="aegean-main-navigation__mobile-toggle-burger-bar"></span>
        </button>
        <div class="aegean-main-navigation__links-wrapper">
          <div class="aegean-main-navigation__fh-logo-wrapper" v-if="showFerryhopperLogo">
            <a href="https://www.ferryhopper.com?utm_source=page&amp;utm_medium=navbar_logo&amp;utm_campaign=aegeanair" class="aegean-main-navigation__link">
              POWERED BY
            </a>
            <div>
              <img class="aegean-main-navigation__fh-logo" :src="fhImage('assets/cobranded/aegeanferry/ferryhopper-top-logo.png')"/>
            </div>
          </div>
          <template v-else>
            <a :href="'/' + selectedLocale.code + '/my-booking'" class="aegean-main-navigation__link">{{ trans('aegeanmybookingmenu') }}</a>
            <div class="aegean-main-navigation__dropdown-wrapper">
              <div class="aegean-main-navigation__dropdown-header">
                <button class="aegean-main-navigation__dropdown-toggle wrapper-1">
                  <span>{{ trans('aegeanairmenumain') }}</span>
                  <i class="fh fh-down-arrow aegean-main-navigation__dropdown-angle-down" aria-hidden="true"></i>
                </button>
              </div>
              <div class="aegean-main-navigation__dropdown for-wrapper-1">
                <a :href="'/' + selectedLocale.code + '/about'" class="aegean-main-navigation__dropdown-link">{{ trans('aegeanairmenuabout') }}</a>
                <a :href="'/' + selectedLocale.code + '/contact'" class="aegean-main-navigation__dropdown-link">{{ trans('aegeanairmenucontact') }}</a>
                <a :href="'/' + selectedLocale.code + '/frequently-asked-questions'" class="aegean-main-navigation__dropdown-link">{{ trans('aegeanairmenufaq') }}</a>
                <a :href="'/' + selectedLocale.code + '/terms-of-use'" class="aegean-main-navigation__dropdown-link">{{ trans('aegeanairmenuterms') }}</a>
                <a :href="'/' + selectedLocale.code + '/cookies-privacy'" class="aegean-main-navigation__dropdown-link">{{ trans('aegeanairmenucookies') }}</a>
              </div>
            </div>
            <div class="aegean-main-navigation__dropdown-wrapper">
              <div class="aegean-main-navigation__dropdown-header">
                <button class="aegean-main-navigation__dropdown-toggle wrapper-2">
                  <i class="fh fh-globe aegean-main-navigation__dropdown-globe" aria-hidden="true"></i>
                  <span>{{ selectedLocale.description }}</span>
                </button>
              </div>
              <div class="aegean-main-navigation__dropdown for-wrapper-2">
                <ul class="aegean-main-navigation__dropdown-list">
                  <li v-for="locale in locales" :key="'locale' + locale.code" class="aegean-main-navigation__dropdown-list-item">
                    <a :href="'/' + locale.code + '/'">
                      <i class="language-sprite xs" :class="'icon-' + locale.code"></i>
                      <span class="aegean-main-navigation__dropdown-list-item-text">{{ locale.description }}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- Mobile navigation -->
      <div class="aegean-main-navigation__mobile">
          <div class="aegean-main-navigation__mobile-menu">
              <a :href="'/' + selectedLocale.code + '/my-booking'" class="aegean-main-navigation__link aegean-main-navigation__link--mobile">{{ trans('aegeanmybookingmenu') }}</a>
              <div class="aegean-main-navigation__dropdown-wrapper--mobile">
                  <div class="aegean-main-navigation__dropdown-header">
                      <button class="aegean-main-navigation__dropdown-toggle wrapper-1-mobile">
                          <span>{{ trans('aegeanairmenumain') }}</span>
                          <i class="fh fh-down-arrow aegean-main-navigation__dropdown-angle-down"
                              aria-hidden="true"></i>
                      </button>
                  </div>
                  <div class="aegean-main-navigation__dropdown--mobile for-wrapper-1-mobile">
                    <a :href="'/' + selectedLocale.code + '/about'" class="aegean-main-navigation__dropdown-link--mobile">{{ trans('aegeanairmenuabout') }}</a>
                    <a :href="'/' + selectedLocale.code + '/contact'" class="aegean-main-navigation__dropdown-link--mobile">{{ trans('aegeanairmenucontact') }}</a>
                    <a :href="'/' + selectedLocale.code + '/frequently-asked-questions'" class="aegean-main-navigation__dropdown-link--mobile">{{ trans('aegeanairmenufaq') }}</a>
                    <a :href="'/' + selectedLocale.code + '/terms-of-use'" class="aegean-main-navigation__dropdown-link--mobile">{{ trans('aegeanairmenuterms') }}</a>
                    <a :href="'/' + selectedLocale.code + '/cookies-privacy'" class="aegean-main-navigation__dropdown-link--mobile">{{ trans('aegeanairmenucookies') }}</a>
                  </div>
              </div>
              <div class="aegean-main-navigation__dropdown-wrapper--mobile">
                  <div class="aegean-main-navigation__dropdown-header">
                      <button class="aegean-main-navigation__dropdown-toggle wrapper-2-mobile">
                        <i class="fh fh-globe aegean-main-navigation__dropdown-globe" aria-hidden="true"></i>
                        <span>{{ selectedLocale.description }}</span>
                      </button>
                  </div>
                  <div class="aegean-main-navigation__dropdown--mobile for-wrapper-2-mobile">
                      <ul class="aegean-main-navigation__dropdown-list">
                        <li v-for="locale in locales" :key="'locale' + locale.code" class="aegean-main-navigation__dropdown-list-item">
                          <a :href="'/' + locale.code + '/'">
                            <i class="language-sprite xs" :class="'icon-' + locale.code"></i>
                            <span class="aegean-main-navigation__dropdown-list-item-text-mobile">{{ locale.description }}</span>
                          </a>
                        </li>
                      </ul>
                  </div>
              </div>
          </div>
      </div>
  </nav>
</template>

<script>
import { createMenuCallbacks } from './navigation'
import { getPageLanguage } from '@/logic/helpers/utils';
import { trans, fhImage } from '@/filters';
let currentLanguage = getPageLanguage();
export default {
  name: 'AegeanNavbar',
  data: function () {
    return {
      locales: [
        {
          code: 'el',
          enabled: currentLanguage === 'el',
          description: 'ΕΛΛΗΝΙΚΑ',
        },
        {
          code: 'en',
          enabled: currentLanguage === 'en',
          description: 'ENGLISH',
        },
        {
          code: 'de',
          enabled: currentLanguage === 'de',
          description: 'DEUTSCH',
        },
        {
          code: 'fr',
          enabled: currentLanguage === 'fr',
          description: 'FRANCAIS',
        },
        {
          code: 'it',
          enabled: currentLanguage === 'it',
          description: 'ITALIANO',
        },
        {
          code: 'es',
          enabled: currentLanguage === 'es',
          description: 'ESPANOL',
        },
      ],
    };
  },
  mounted() {    
    createMenuCallbacks();
  },
  methods: {
    trans,
    fhImage
  },
  computed: {
    showFerryhopperLogo: function () {
      return this.$store.state.navigationModule.activeTab !== 'search';
    },
    selectedLocale: function () {
      return this.locales.find((o) => {
        return o.code === currentLanguage;
      });
    },
  },
};
</script>
