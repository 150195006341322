<template>
  <span class="fh-tooltip">
    <span class="fh-tooltip__message">{{ message }}</span>
    <span class="fh-tooltip__trigger">
      <slot></slot>
    </span>
  </span>
</template>

<script>
export default {
  name: 'FhTooltip',
  props: {
    message: {
      type: String,
      required: true,
    },
  },
};
</script>