<template>
  <span :class="$style.petDetailsSelection">
    <span data-test="petDetailsText">
      <img height="14" width="14" :src="iconUrl" alt="Company icon" class="mr8 mb2" data-test="providerIcon" />
      <span :class="$style.petDetailsSelection__description">{{ description }}</span>
    </span>
  </span>
</template>

<script>
export default {
  name: 'PetDetailsSelection',
  props: {
    iconUrl: String,
    description: String
  }
};
</script>

<style module lang="scss">
@import './pet-details-selection.scss';
</style>
