import { ga4Event } from './EventService';
import { eventTravelersClicked } from './createSharedEvents';
import { tripToTripInfo } from './sharedAdapters';

export const eventPricingSuccess = (payload) => {
  //ga4Event('prices_verified', { ...tripToTripInfo(trip) });
  ga4Event('prices_verified', payload);
};

export const eventSelectedMotorhome = (trip, motorhomeCode) => {
  ga4Event('motorhome_selected', {
    motorhome_code: motorhomeCode,
    ...tripToTripInfo(trip),
  });
};

export const eventSelectedCOB = (trip) => {
  ga4Event('camping_on_board_selected', { ...tripToTripInfo(trip) });
};

export const eventChangedPetType = (trip) => {
  ga4Event('pet_ticket_selected', {
    ...tripToTripInfo(trip),
  });
};

export const eventLoyaltyValidationSuccess = (cardName, trip) => {
  ga4Event('loyalty_card_validated', {
    loyalty_card_name: cardName,
    ...tripToTripInfo(trip),
  });
};

export const eventSeatingTravelersClicked = (traveler, action, trip) => {
  eventTravelersClicked(traveler, action, tripToTripInfo(trip));
};

export const eventPetCabinSelected = (trip) => {
  ga4Event('pet_cabin_selected', { ...tripToTripInfo(trip) });
};

export const eventCabinSelected = (trip) => {
  ga4Event('cabin_selected', { ...tripToTripInfo(trip) });
};
