<template>
  <div :class="$style.refund">
    <div :class="$style.refund__text">
      {{ trans('passDet.travInsIntam.off.explanation.text') }}
    </div>
  </div>
</template>

<script>
import { trans } from '@/filters';

export default {
  name: 'StandardInsurance',
  methods: {
    trans
  }
};
</script>

<style module lang="scss">
@import '../refund/refund.scss';
</style>
