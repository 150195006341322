// @ts-check
import settings from '@/settings'
import { createPassengerGroupString } from '../../generators/phrases';
import { createSeatingAnalysisItem, isPassengerSeating, isPetSeating, isVehicleSeating } from './seatingAnalysisItem';

class SeatingAnalysisModel {
    
    constructor () {        
        this.items =  [];
    }

    /**
     * 
     * @param {string} code 
     * @param {number} quantity 
     * @param {string} icon 
     * @param {string} description 
     * @param {any[]} passengers 
     */
    addPassenger (code, quantity, icon, description, passengers) {   
        const occupantsString = createPassengerGroupString(passengers);
        this.addItem(
            settings.constants.PASSENGER,
            code, quantity, icon, description, passengers, occupantsString
        );
    }

    /**
     * 
     * @param {string} code 
     * @param {string} icon 
     * @param {string} description 
     * @param {object} vehicle
     */
    addVehicle (code, icon, description, vehicle) {      
        this.addItem(
            settings.constants.VEHICLE, 
            code, 1, icon, description, [vehicle], description
        );
    }


    /**
     * 
     * @param {string} code 
     * @param {string} description 
     * @param {Object} pet
     */
    addPet(code, description, pet) {       
        this.addItem(
            settings.constants.PET, 
            code, 1, '<i class="fh fh-pet"></i>', description, [pet], description
        );
    }

    /**
     * 
     * @param {string} type 
     * @param {string} code 
     * @param {number} quantity 
     * @param {string} icon 
     * @param {string} description 
     * @param {any[]} travelingEntities 
     * @param {string} occupantsString 
     */
    addItem(type, code, quantity, icon, description, travelingEntities, occupantsString) {        
        this.items.push(
            createSeatingAnalysisItem(
                type, code, quantity, icon, description, travelingEntities, occupantsString
            )
        );
    }

    /**
     * Return a sorted version of the seating analysis array, with 
     * passengers first, then infants, then pets, then vehicles
     */
    getSortedSeatingAnalysis () {
        return [
            ...this.items.filter(item => isPassengerSeating(item) && !item.isInfantGroup),
            ...this.items.filter(item => item.isInfantGroup),
            ...this.items.filter(item => isPetSeating(item)),
            ...this.items.filter(item => isVehicleSeating(item))
        ]
    }

}

export default SeatingAnalysisModel;