<template>
  <div class="mt10 checkout-buttons" role="group">
    <div class="checkout-buttons__single-button">
      <button @click="onCheckoutClick()" :disabled="disable" class="btn btn-primary checkout-buttons__button ladda-button" :id="id" data-style="zoom-in" data-spinner-lines="24" data-spinner-color="#FFF">
        <slot> {{ checkoutButtonText }} <i class="fh fh-right-arrow" aria-hidden="true" data-test="checkoutButton"></i> </slot>
      </button>
    </div>
  </div>
</template>

<script>
import * as Ladda from 'ladda';
import { uniqueId } from 'lodash-es';

export default {
  name: 'CheckoutSingleButtonComponent',
  props: {
    checkoutButtonText: String,
    isLadda: {
      type: Boolean,
      default: false,
    },
    isWaiting: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    id: `ladda-${uniqueId(15)}`,
    laddaButton: undefined,
  }),
  emits: ['onCheckoutClick'],
  watch: {
    isWaiting(newValue, oldValue) {
      if (!this.isLadda) return;

      if (newValue) {
        this.laddaButton.start();
      } else {
        this.laddaButton.stop();
      }
    },
    id(newVal, oldVal) {
      this.laddaButton = Ladda.create(document.querySelector(`#${this.id}`));
    },
  },
  mounted() {
    this.laddaButton = Ladda.create(document.querySelector(`#${this.id}`));
  },
  beforeUnmount() {
    this.laddaButton.remove();
  },
  methods: {
    onCheckoutClick() {
      this.$emit('onCheckoutClick');
    },
  },
};
</script>