import { createPassengerLoyaltyOffer } from '@/logic/BL/loyaltyCards';
import { createPassengerResidenceOffer } from '@/logic/BL/residenceDiscount';
import settings from '@/settings';

export const createPassengerForSeating = (passengerIndex, uid) => {
    return {
      uid: uid,
      id: `PD-PASSENGER-${uid}`,
      ...createPassengerTicket(),
      // if passenger is an infant
      isInfant: false,
      // this will be true if the selected passenger type needs a document
      needsDocument: false,
      // this becomes true if the passenger type is changed by the user
      isTypeTouched: false,
      passengerIndex,
      isLeader: passengerIndex === 0,
      // Loyalty card information
      loyalty: createPassengerLoyaltyOffer(),
      residenceDiscount: createPassengerResidenceOffer(),
      seatSelectionMessage: ''
    };
  };


  // Gender defaults to something until set in the booking flow
  const createPassengerTicket = () => ({
    gender: settings.defaults.GENDER, 
    type: '',
    ticketPriceInCents: undefined
  });
  
  