<template>
  <div :class="$style.fhCart" id="results-cart">
    <div :class="$style.fhCart__header">
      <h5 :class="['m0', $style['fhCart__header-title']]">{{ headerText }}</h5>
    </div>
    <div v-if="!isMobile" :class="$style.fhCart__main">
      <div :class="$style.fhCart__items">
        <template v-for="(trip, tripIndex) in cart.selectedTrips">
          <FhResultsCartItem
            :key="'fh-results-cart-item-' + tripIndex"
            v-if="tripDefined(trip)"
            :trip="trip"
            @onTripDeselected="onTripDeselected(tripIndex)"
            :showLabel="showRoundTripOfferLabel && roundTripOfferLabel.placement === tripIndex"
            :label="roundTripOfferLabel"
          />
        </template>
      </div>
    </div>
    <div v-else>
      <div :class="$style.fhCart__items" v-if="showRoundTripOfferLabel && roundTripOfferLabel.placement !== 'footer'">
        <Notification :warning="false" :hasIcon="false" :success="true" :class="'mb8'" :message="roundTripOfferLabel.description" :hasRadius="true" />
      </div>
    </div>
    <div :class="[$style.fhCart__footer, { [$style['fhCart__footer--no-pt']]: isMobile || cart.selectedTripsCount === 0 }]">
      <div :class="[$style.fhCart__price, { [$style['fhCart__price--mobile']]: isMobile }]" v-if="cart.selectedTripsCount > 0">
        <span :class="$style.fhCart__description">{{ trans('results.cart.indicativePrice') }}</span>
        <span :class="$style.fhCart__sum">{{ currencyStringPre(overallPrice) }}</span>
      </div>
      <FhCta :showArrow="true" @click="onCheckoutClicked">{{ trans('continue') }}</FhCta>

      <FhCartNotification v-if="bookingDisabled" :message="bookingDisableNotification" />
      <template v-else>
        <Notification
          v-if="showRoundTripOfferLabel && roundTripOfferLabel.placement === 'footer'"
          :warning="false"
          :hasIcon="true"
          :customIconClass="'fh-check'"
          :success="true"
          :class="'mb8'"
          :message="roundTripOfferLabel.description"
          :hasRadius="true"
        />

        <template v-if="hasTripWarnings">
          <FhCartNotification v-if="showTimeOverlapsWarning" :message="trans('conflictingconnections')" />
          <FhCartNotification v-if="showMaxTripsWarning" :message="trans('triplimitswarning')" />
          <FhCartNotification v-if="showGarageWarning" :message="trans('novehiclesupport')" />
          <FhCartNotification v-if="showPetsWarning" :message="trans('nopetsupport')" />
        </template>
        <Notification v-else-if="hasCheckoutError" :id="'select-trips-error'" :message="checkoutErrorMsg" class="error-wrapper" data-test="resultsCheckoutError" />
        <FhCartNotification v-if="showDateWarning" :message="trans('closetripdatewarning')" :warning="false" />
      </template>
    </div>
  </div>
</template>

<script>
import FhResultsCartItem from '@/components/cart/ResultsCart/FhResultsCartItem';
import FhResultsCartMixin from '@/components/cart/ResultsCart/FhResultsCartMixin';
import FhCartMixin from '@/components/cart/FhCartMixin';
import FhCartNotification from '@/components/cart/FhCartNotification';
import { message, notNull } from '@/logic/helpers/utils';
import emitter from '@/emitter';
import { NAVIGATION_TABS } from '@/store/modules/navigation.module';
import { trans, currencyStringPre } from '@/filters';
import { mapState } from 'vuex';

export default {
  name: 'FhResultsCart',
  mixins: [FhResultsCartMixin, FhCartMixin],
  components: {
    FhResultsCartItem,
    FhCartNotification,
  },
  data: () => ({
    hasCheckoutError: false,
  }),
  created() {
    const eventsToListen = ['onSearchDatesChange', 'onItineraryDeselection', 'onItinerarySelection', 'onTripDeselectedFromCart', 'resetCartCheckoutError'];
    eventsToListen.forEach((event) => emitter.$on(event, () => this.resetCheckoutError()));
  },
  watch: {
    activeTab() {
      if (this.activeTab !== NAVIGATION_TABS.RESULTS) this.resetCheckoutError();
    },
  },
  computed: {
    ...mapState({
      activeTab: (state) => state.navigationModule.activeTab,
    }),
    headerText() {
      const postFix = this.tripsCount === 1 ? 'one' : 'other';
      return `
        ${message(`results.cart.numberOfTripsSelected.before.${postFix}`)}
        ${this.cart.selectedTripsCount}
        ${message(`results.cart.numberOfTripsSelected.main.${postFix}`)}
        ${this.tripsCount}
        ${message(`results.cart.numberOfTripsSelected.after.${postFix}`)}`;
    },
    checkoutErrorMsg() {
      if (this.tripsCount === 1 && this.cart.selectedTripsCount === 0) return `${message('selectTripToProceed')}`;
      if (this.tripsCount > 1 && this.cart.selectedTripsCount === 0) return `${message('selectTripsToProceed')}`;
      if (this.tripsCount > 1 && this.cart.selectedTripsCount > 0) return `${message('selectAllTripsToProceed')}`;
    },
    hasTripWarnings() {
      return this.showTimeOverlapsWarning || this.showMaxTripsWarning || this.showGarageWarning || this.showPetsWarning;
    },
    hasWarnings() {
      return this.hasTripWarnings || this.hasCheckoutError;
    },
    showRoundTripOfferLabel() {
      if (this.hasWarnings) return false;
      return notNull(this.roundTripOfferLabel);
    },
  },
  methods: {
    trans,
    currencyStringPre,
    resetCheckoutError() {
      this.hasCheckoutError = false;
    },
  },
};
</script>

<style module lang="scss">
@import '../fh-cart.scss';
</style>
