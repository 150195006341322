//@ts-check

/**
 * @typedef ClassAnalysisItem
 * @property {string} ClassAbbr 
 * @property {string} ClassResType 
 * @property {number} Quantity 
 * @property {string} Category 
 * @property {number} Capacity 
 * @property {string} UpDownBed 
 * @property {number[]} PassengerIndexes 
 */

/**
 *
 * @param {string} abbreviation
 * @param {string} resType
 * @param {number} quantity
 * @param {{passengerIndex: number}[]} passengers
 * @param {string} category
 * @param {number} capacity
 * 
 * @returns {ClassAnalysisItem}
 */
export const createClassAnalysisItem = (abbreviation, resType, quantity, passengers, category, capacity) => {
    return {
        ClassAbbr: abbreviation,
        ClassResType: resType,
        Quantity: quantity,
        Category: category,
        Capacity: capacity,
        UpDownBed: '',
        PassengerIndexes: passengers.map(passenger => passenger.passengerIndex)
    }
};
