import settings from '@/settings';

/**
 * Returns true if a passenger is BABY, by type
 * @param {string} passengerType
 */
export const isBabyType = passengerType => passengerType.startsWith('BABY');

/**
 * Returns true if a passenger is INFANT, by type
 * This function expects a convention: all infant types in our system should start with IN
 * and only infant types should start with IN
 * @param {String} passengerType
 */
export const isInfantType = passengerType => passengerType.startsWith('IN');

/**
 * Returns true if a passenger is NOT INFANT, by type
 * @param {String} passengerType
 */
export const isNotInfantType = passengerType =>
  isInfantType(passengerType) === false;

/**
 * Returns true if a passenger is CHILD, by type
 * This function expects a convention: all CHILD types in our system should start with CH
 * and only child types should start with CH
 * @param {String} passengerType
 */
export const isChildType = passengerType => passengerType.startsWith('CH');

/**
 * Returns true if a passenger is ADULT, by type
 * @param {String} passengerType
 */
export const isAdultType = passengerType => {
  return (
    false === isInfantType(passengerType) &&
    false === isChildType(passengerType) &&
    false === isBabyType(passengerType)
  );
};

/**
 * Returns true if a passenger is NOT ADULT, by type
 * @param {String} passengerType
 */
export const isNotAdultType = passengerType =>
  isAdultType(passengerType) === false;

/**
 * Returns the first default discount from a list of discounts
 * @param {array} discounts
 */
export const getDefaultDiscount = discounts => {
  return discounts.find(d => d.isDefaultDiscount);
};

/**
 * Returns the discounts that can be assigned to adults from a list of discounts
 * @param {array} discounts
 */
export const getAdultDiscounts = discounts => {
  return discounts.filter(discount => isAdultType(discount.DiscountCategory));
};
