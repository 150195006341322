import { getInfantPassengers } from '@/logic/filterers/PassengersFilterer';

/**
 * Returns the camping on board seat type for Minoan Lines
 */
export const getCampingOnBoardAbbreviation = passengers => {
  return passengers.length > 4 ? 'AB4' : passengers.length < 2 ? 'AB2' : `AB${passengers.length}`;
};

/**
 * Returns true if the trip has camping on board availability for a given set of passengers
 */
export const hasCampingOnBoard = (trip, passengers, vehicles) => {
  // allowed classes for camping on board are AB2-AB3-AB4
  let classTypeForOnBoard = getCampingOnBoardAbbreviation(passengers);
  return trip.extraAttributes.hasCampingOnBoard && trip.passengerAccommodations.find(option => option.ClassAbbr === classTypeForOnBoard) !== undefined;
};


/**
 * Returns true if all passengers of a given trip have selections that can
 * be applied in camping on board
 */
export const isCampingOnBoardEnforcable = (trip, passengers, vehicles) => {
  let rules = [getInfantPassengers(passengers).length === 0, vehicles.length === 1];

  // return false if any rule is set to false
  return rules.find(r => r === false) === undefined;
};

/**
 * Returns messages related to the camping on board - all inclusive
 * offer of minoan
 */
export const getMessages = () => {
  return {
    title: 'coballinclusivetitle',
    checkbox: 'coballinclusivecheckbox',
    warning: 'coballinclusivewarning',
    blocking: 'coballinclusivewarninginfants',
    slug: 'cob-all-inclusive'
  };
};

