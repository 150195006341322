<template>
  <div>
    <FhSeatingCartTraveler v-for="(item, seatIndex) in passengerGroups" :key="`passenger-group-${seatIndex}`" :item="item" :isPassenger="true" :hasError="hasError" />
    <FhSeatingCartTraveler v-for="(item, seatIndex) in petGroups" :key="`pet-group-${seatIndex}`" :item="item" :isPet="true" :isOfflineTicket="isOfflineTicket" :hasError="hasError" />
    <FhSeatingCartTraveler v-for="(item, seatIndex) in vehicleGroups" :key="`vehicle-group-${seatIndex}`" :item="item" :isVehicle="true" :hasError="hasError" />
  </div>
</template>

<script>
import FhSeatingCartTraveler from '@/components/cart/SeatingCart/FhSeatingCartTraveler';
import { PET_POLICIES } from '@/logic/repositories/PetsRepository';
import { isPassengerSeating, isVehicleSeating, isPetSeating } from '../../logic/models/pricing/seatingAnalysisItem';

export default {
  name: 'FhCartSeatingAnalysis',
  components: {
    FhSeatingCartTraveler
  },
  props: {
    trip: {
      type: Object,
      required: true
    },
    isWaiting: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    passengerGroups() {
      return this.trip.SeatingAnalysis.filter(g => isPassengerSeating(g));
    },
    vehicleGroups() {
      return this.trip.SeatingAnalysis.filter(g => isVehicleSeating(g));
    },
    petGroups() {
      return this.trip.SeatingAnalysis.filter(g => isPetSeating(g));
    },
    isOfflineTicket() {
      return this.trip.petPolicy.type === PET_POLICIES.OFFLINE;
    }
  }
};
</script>
