import { getConfigurationValue } from '@/logic/helpers/utils';

export const isICFeatureEnabled = () => {
  return !getConfigurationValue('cobranded_page', false);
};

/**
 * @param {*} trips
 */
export const check = trips => {
  if (!trips) return false;

  if (!isICFeatureEnabled()) return false;

  return trips.some(t => t.islanderDiscount.supportsIslanderCode);
};

export const ISLANDER_CODE_LENGTH = 9;