import { uniqueId } from '../helpers/utils';
import settings from '@/settings';
import dayjs from '@/logic/services/date/dateService';

export const alternativeDateToFakeTrip = dateIso => {
  let departure = getIsodateInBrowserTimezone(dateIso);
  
  if (departure.isToday()) return null;
  return {
    alternate: true,
    isDirectTrip: true,
    isValidDirectTrip: true,
    IDSCode: uniqueId(10),
    timings: {
      DepartureDateTime: departure
    }
  };
};


const dateFromIso = date => dayjs.utc(date).format('YYYY-MM-DD')
const timeFromIso = date => dayjs.utc(date).format('HH:mm');

/**
 * The CRS endpoint responsible for returning available departure datetimes is in UTC. However all of our 
 * functions use local times (local to the user) and to achieve this, we remove all timezone information
 * from dates by converting them to plain string an parsing them again.
 * 
 * @param {string} dateIso 
 * @returns 
 */
const getIsodateInBrowserTimezone = (dateIso) => {
  const datePart = dateFromIso(dateIso);
  const timePart = timeFromIso(dateIso);
  return dayjs(`${datePart} ${timePart}`, settings.formats.microApiDateTimeFormat);
}

