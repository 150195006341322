<template>
  <div>
    <div :class="[isGridSingleCol ? 'py8' : 'py16', { [$style['couponInput--bordered']]: !isGridSingleCol }]">
      <h4 v-if="!isGridSingleCol" class="mt0 mb16 text-medium">{{ trans('passDet.discountCoupon.heading') }}</h4>
      <button :class="toggleClass" @click="toggleCodeInput">
        <i :class="[$style['couponInput__toggle__icon'], 'fh fh-plus', { [$style['couponInput__toggle__icon--rotate']]: couponCodeInputVisible }]"></i>
        <span v-if="couponCodeInputVisible">{{ trans('removecouponinputbtn') }}</span>
        <span v-else>{{ trans('passDet.discountCoupon.text') }}</span>
      </button>
      <div :class="$style.couponInput__box" v-if="couponCodeInputVisible">
        <div :class="$style['couponInput__input-wrapper']">
          <div :class="$style['couponInput__input-wrapper-inner']">
            <div :class="$style['couponInput__input-with-error']">
              <InputWithValidationIndicator
                :value="couponCode"
                :placeholder="trans('couponcodeinputplaceholder')"
                :isValidating="isValidatingCoupon"
                :isSuccess="hasSuccessfulCoupon"
                :isError="hasFailedCoupon"
                :disabled="isValidatingCoupon"
                :hasBorderRadius="hasBorderRadius"
                :extraClass="$style.couponInput__input"
                @input="onCouponCodeInput"
              />
              <small class="input__feedback-text text-danger" v-if="hasFailedCoupon">{{ couponValidationMessage }}</small>
            </div>
            <div :class="$style['couponInput__discount-amount']" v-if="overallDiscountForTrips">
              <span class="price">
                <strong>- {{ currencyStringPre(overallDiscountForTrips) }}</strong>
              </span>
            </div>
            <div :class="$style['couponInput__btn-wrapper']" v-else>
              <button v-if="hasFailedCoupon" :class="[$style.couponInput__btn, btnExtraClass]" @click="onClearCodeInput">{{ trans('clearcouponbtn') }}</button>
              <button v-else :class="[$style.couponInput__btn, btnExtraClass]" @click="onCouponCodeSubmit">{{ trans('applycouponbtn') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputWithValidationIndicator from '@/components/shared/inputs/InputWithValidationIndicator';
import { mapActions, mapState, mapGetters } from 'vuex';
import { COUPON_STATE } from '@/logic/BL/coupons';
import { trans, currencyStringPre } from '@/filters';

export default {
  name: 'CouponInput',
  components: {
    InputWithValidationIndicator,
  },
  props: {
    isGridSingleCol: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      fetchingForPrices: (state) => state.uiModule.fetchingForPrices,
      couponCodeInputVisible: (state) => state.uiModule.couponCodeInputVisible,
      couponValidationStatus: (state) => state.bookingModule.couponValidationStatus,
      couponValidationMessage: (state) => state.bookingModule.couponValidationMessage,
      couponCode: (state) => state.bookingModule.couponCode,
      activeTab: (state) => state.navigationModule.activeTab,
    }),
    ...mapGetters({
      overallDiscountForTrips: 'bookingModule/overallDiscountForTrips',
    }),
    iconClass() {
      if (this.isValidatingCoupon) return 'fh-notch fh-spin';
      if (this.hasSuccessfulCoupon && this.isCobrandedEasyferry) return 'fh-check';
      if (this.hasSuccessfulCoupon) return 'fh-check text-success';
      if (this.hasFailedCoupon) return 'fh-notification';
      return '';
    },
    toggleClass() {
      let cobrandedClass = this.isCobrandedEasyferry ? this.$style['couponInput__toggle--easyferry'] : '';
      return [this.$style.couponInput__toggle, cobrandedClass];
    },
    btnExtraClass() {
      if (this.disableSubmit) return [this.$style['couponInput__btn--disabled']];
      if (this.isCobrandedEasyferry) return this.$style['couponInput__btn--easyferry'];
    },
    disableSubmit() {
      return this.fetchingForPrices || this.couponCode === '';
    },
    hasBorderRadius() {
      // if the component is rendered inside the easyferry app, give it a border radius
      return this.isCobrandedEasyferry;
    },
    hasSuccessfulCoupon() {
      return this.couponValidationStatus === COUPON_STATE.SUCCESS;
    },
    hasFailedCoupon() {
      return this.couponValidationStatus === COUPON_STATE.FAILED;
    },
    isValidatingCoupon() {
      return this.couponValidationStatus === COUPON_STATE.VALIDATING;
    },
    isCobrandedEasyferry() {
      return this.globalCobrandedVariant === 'easyferry';
    },
  },
  methods: {
    trans,
    currencyStringPre,
    ...mapActions({
      setCouponCode: 'bookingModule/setCouponCode',
      applyCouponCode: 'bookingModule/applyCouponCode',
      removeCouponCode: 'bookingModule/removeCouponCode',
      changeCouponInputVisibility: 'uiModule/changeCouponInputVisibility',
    }),
    toggleCodeInput() {
      if (this.couponCodeInputVisible) {
        this.onClearCodeInput();
      }
      this.changeCouponInputVisibility(!this.couponCodeInputVisible);
    },
    onCouponCodeInput(value) {
      this.setCouponCode(value);
    },
    onCouponCodeSubmit() {
      this.applyCouponCode();
    },
    onClearCodeInput() {
      this.removeCouponCode();
    },
  },
};
</script>

<style module lang="scss">
@import './coupon-input.scss';
</style>
