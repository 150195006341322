import settings from '@/settings';
import { orderBy as _orderBy, groupBy as _groupBy, keys as _keys } from 'lodash-es';
import { isJsonPropTrue } from '../helpers/utils';

/**
 * Returns true if pets are added on a trip
 * @param {DirectTripModel} trip
 */
export const hasPets = trip => {
  return trip.pets && trip.pets.length > 0;
};

/**
 * Returns true if a seat is only for infants
 * @param {any} seat
 */
export const isInfantSeat = seat => isJsonPropTrue(seat.isInfant);

/**
 * Returns true if a seat is for pets
 * @param {any} seat
 */
export const isPetSeat = seat => isJsonPropTrue(seat.isPet);

/**
 * Returns true if a seatOption is an actual seat or deck (not a cabin)
 * @param {any} seatType
 */
export const isDeckOrSeatType = seatType => {
  return (
    seatType === settings.constants.DECK || seatType === settings.constants.SEAT
  );
};

/**
 * Returns true if a seatOption is a bed
 * @param {any} seatType
 */
export const isBedType = seatType => {
  return seatType === settings.constants.BED;
};

/**
 * Returns true if a seatOption is a cabin
 * @param {any} seatType
 */
export const isCabinType = seatType => {
  return seatType === settings.constants.CABIN;
};

/**
 * Returns true if a seat is a cabin
 * @param {any} seat
 */
export const isCabin = seat => {
  return isCabinType(seat.seatType);
};

/**
 * Returns true if a cabin has capacity for more than one person
 * @param {any} seat
 */
 export const isCabinForMany = seat => {
  return isCabin(seat) && seat.accommodationCapacity > 1 && !isSingleBerth(seat);
};

/**
 * Returns true if a cabin is single berth
 * @param {any} seat
 */
 export const isSingleBerth = seat => {
  return seat.singleBerth && seat.singleBerth === true;
};

/**
 * Returns true if a accommodation can be selected given a number of passengers.
 * Eg. for a 4-berth cabin that must be booked as a full cabin, a user that wants to travel
 * with three passengers in total, cannot select it
 *
 * @param {any} accommodation
 * @param {int} passengersCount
 */
export const isAccommodationApplicable = (accommodation, passengersCount) => {
  
  if ((accommodation.accommodationCapacity > passengersCount) && !isSingleBerth(accommodation)) {
    return false
  }
  // single accommodations and single berths are always applicable
  if (!isCabin(accommodation) || accommodation.singleBerth) {
    return true;
  }

  return accommodation.accommodationCapacity <= passengersCount;
};

/**
 * Return the list of available seat options on a trip, according to specific
 * BI logic
 *
 * @param {DirectTripModel} trip
 * @param {int} nonInfantsCount
 */
export function getAvailableAdultSeats(trip, nonInfantsCount) {
  // if the trip has pets
  const hasPet = hasPets(trip);

  let filteredSeatOptions = _orderBy(trip.passengerAccommodations, 'seatDescription')
    // remove infant seats
    .filter(seat => !isInfantSeat(seat))
    // remove pet seats when no pet is present
    .filter(seat => hasPet || !isPetSeat(seat))
    // remove non-applicable options
    .filter(seat => isAccommodationApplicable(seat, nonInfantsCount));


  return _orderBy(filteredSeatOptions, 'categoryOrder');
}

/**
 * Returns the list of available seat options for infants on a trip, according
 * to specific BI logic. If the data are populated in a correct way, we should
 * find only a single seat option for infants per trip
 *
 * @param {DirectTripModel} trip
 */
export const getAvailableInfantSeats = trip => {
  return trip.passengerAccommodations.filter(s => s.isInfant);
};

/**
 * Returns the infant seat for a trip
 * @param {DirectTripModel} trip
 */
export const getTripInfantsSeat = trip => {
  return trip.passengerAccommodations.find(s => s.isInfant);
};

/**
 * Returns a trip seat option based on a given abbreviation
 * @param {DirectTripModel} trip
 * @param {string} abbr
 */
export const getTripSeatOptionFromAbbr = (trip, abbr) => trip.passengerAccommodations.find(o => o.ClassAbbr === abbr);

