<template>
  <div>
    <div class="label-with-icon">
      <i :class="`fh fh-${icon}`"></i>
      <span :class="`pl5 label-with-icon__label--${labelSize}`">{{ label }}</span>
      <i v-if="hasModal" class="fh fh-info label-with-icon__info pl5" @click="showLabelModal"></i>
    </div>

    <FhModal v-if="showModal" @close="showModal = false" :title="modalTitle">
      <template #body>
        {{ modalContent }}
      </template>
    </FhModal>
  </div>
</template>

<script>
export default {
  name: 'LabelWithIcon',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    labelSize: {
      type: String,
      default: 'small',
    },
    hasModal: {
      type: Boolean,
      default: false,
    },
    modalContent: {
      type: String,
      required: false,
    },
    modalTitle: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    showLabelModal() {
      this.showModal = true;
    },
  },
};
</script>
