export const getTotalTicketPrice = (ticketPricingResponse) => {
  const { 
    AddFees1, 
    AddFees2, 
    Taxes, 
    Vat, 
    Price, 
    hasPriceBreakdown 
  } = ticketPricingResponse;

  const totalFees = getCentsValue(AddFees1) + getCentsValue(AddFees2);
  const totalTaxes = getCentsValue(Taxes) + getCentsValue(Vat);
  const totalNet = getCentsValue(Price);

  const totalPrice = hasPriceBreakdown ? 
    parseInt(totalNet + totalTaxes + totalFees) :
    getCentsValue(Price);

  return isNaN(totalPrice) ? undefined : totalPrice;  
}

const getCentsValue = (value) => parseInt(value) || 0;