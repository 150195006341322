<template>
  <div :class="{ 'search-box__footer': !isMobile }">
    <FhCta data-test="searchBtn" :showArrow="true" :isLadda="true" :isWaiting="isWaiting" @click="handleClick">{{ trans('searchbtn') }}</FhCta>
    <Notification v-if="hasSearchError" :warning="isWarning" class="mt2" :message="error.errorMessage" />
  </div>
</template>

<script>
import { SEARCH_ERROR_TYPES } from '@/logic/models/search/createSearchQueriesError';
import { trans } from '@/filters';

export default {
  name: 'SearchButtonBar',
  props: {
    isWaiting: Boolean,
    error: Object,
  },
  emits: ['onSearchClicked'],
  computed: {
    isWarning() {
      return this.error.searchErrorType === SEARCH_ERROR_TYPES.WARNING;
    },
    hasSearchError() {
      return this.error.searchErrorType !== SEARCH_ERROR_TYPES.NONE;
    },
  },
  methods: {
    trans,
    handleClick() {
      this.$emit('onSearchClicked');
    },
  },
};
</script>
