<template>
  <button :class="$style.travelersCounter" @click.stop="handleClick">
    <div :class="$style.travelersCounter__box">
      <i :class="['fh fh-users-fill', $style.travelersCounter__icon]" />
      <span :class="numberClass">{{ passengers }}</span>
    </div>
    <div :class="[$style.travelersCounter__box, 'mx25']" v-if="isPetsBookingActivated">
      <i :class="['fh fh-pet', $style.travelersCounter__icon]" />
      <span :class="numberClass">{{ pets }}</span>
    </div>
    <div :class="[$style.travelersCounter__box, { ml25: !isPetsBookingActivated }]">
      <i :class="['fh fh-vehicles', $style.travelersCounter__icon]" />
      <span :class="numberClass">{{ vehicles }}</span>
    </div>
  </button>
</template>

<script>
// Vuex
import { mapState } from 'vuex';

export default {
  name: 'TravelersCounter',
  props: {
    grayNumber: Boolean,
  },
  emits: ['onTravelerClick'],
  methods: {
    handleClick() {
      this.$emit('onTravelerClick');
    },
  },
  computed: {
    ...mapState({
      passengers: (state) => state.searchModule.passengers,
      vehicles: (state) => state.searchModule.vehicles,
      pets: (state) => state.searchModule.pets,
      isPetsBookingActivated: (state) => state.searchModule.isPetsBookingActivated,
    }),
    numberClass() {
      return `${this.$style.travelersCounter__number} ${this.grayNumber ? this.$style['travelersCounter__number--gray'] : ''}`;
    },
  },
};
</script>

<style module lang="scss">
@import './travelers-counter.module.scss';
</style>
