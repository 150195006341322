import { replaceHashAll } from './helpers';

export default class LocaleAnchors {
  /** @param {string} hash Prettify again */
  static replaceHashAll(hash) {
    replaceHashAll(this.elements, hash);
  }

  /** Returns an array of anchor elements, or an empty array if the DOM isn't available */
  static get elements() {
    if (!document) return [];

    // querySelectorAll returns a NodeList. Convert to a Array to make it a functor data type.
    return [...document.querySelectorAll('a.locale-link')];
  }
}
