import { createGenericHashForTrip, createUniqueHashForTrip } from './createHashForTrip';
import { groupBy as _groupBy } from 'lodash-es';
import { JOURNEY_MODE } from '../models/trips/journeyModes';


/**
 * Given a list of trips, this function returns a list of trip combinations that should
 * be booked together. These are trips belonging to the same company, having the
 * same number of infant passengers*, and a company that has separatePNRs flag
 * set to false.
 * 
 * Why infants play a role in grouping?
 * On the same company, in some cases infants occupy a seat and obtain a ticket,
 * while on some other cases (depending on the ferry) they don't. Hence, we cannot
 * group two trips that have different setting regarding infants, because this would
 * lead to a booking with inconsistent number of passengers accross segments.
 * 
 * What are groupsEnforcedByCarrier
 * Some carriers offer trips that can only be booked as a group. For example, Seajets provides the
 * so called tripsWithConjunction. These need to be groupped regardless of any business logic
 * that we apply on Ferryhopper
 * 
 * @param {Object[]} trips The list of trips to group
 * @param {Boolean} forceSeparation Force trip separation regardless of any other business rule
 */
export const createTripCombinations = (trips, forceSeparation = false) => {
  
  if (!trips) return {}

  const groupsEnforcedByCarrier = getGroupsEnforcedByCarriers(trips);
  
  const groupsEnforcedByFH = getGroupsEnforcedByFerryhopper(trips, forceSeparation);

  return {
    ...groupsEnforcedByCarrier,
    ...groupsEnforcedByFH
  }
};

const getGroupsEnforcedByCarriers = (trips) => {
  const tripsForGroupping = trips.filter(t => t.subJourney.type === JOURNEY_MODE.MULTISEGMENT);
  const groupsEnforcedByCarrier = _groupBy(tripsForGroupping, trip => {
    return trip.subJourney.IDScode;
  })
  return groupsEnforcedByCarrier;
}

const getGroupsEnforcedByFerryhopper = (trips, forceSeparation) => {
  
  const tripsForGroupping = trips.filter(t => t.subJourney.type !== JOURNEY_MODE.MULTISEGMENT);
  
  let groupedRoundTrips = [];
  return _groupBy(tripsForGroupping, trip => {
    // avoid grouping if forceSeparation has been explicitely requests
    if (forceSeparation) return createUniqueHashForTrip(trip);

    // avoid grouping for companies that require PNR separation
    if (trip.bookingRules.requiresPNRSeparation) {
      return createUniqueHashForTrip(trip);
    }
    if (!groupedRoundTrips.length) groupedRoundTrips = getRoundTripGroup(tripsForGroupping);
    
    return (groupedRoundTrips.some(roundTrip => roundTrip.id === trip.id)) ? createGenericHashForTrip(trip) : createUniqueHashForTrip(trip);
  });
}


const isRoundTripValidToGroup = (previousValue, currentValue) => (previousValue.Details.Company === currentValue.Details.Company) &&
  (previousValue.Details.ArrStation === currentValue.Details.DepStation) &&
  (previousValue.Details.DepStation === currentValue.Details.ArrStation);

const isTripContainsMultipleCompanies = (comp) => new Set(comp).size !== 1;

/**
* Provide grouping for round trips by generating one PNR per round trip. Only one round trip group is allowed.
* examples: 
* PORTA -> PORTB -> PORTA  1 PNR   
* PORTA -> [PORTB -> PORTC -> PORTB]  2 PNRs
* exception:
* for this determenistic case we do not provide grouping
* PORTA -> PORTB -> PORTA -> PORTB  4 PNRs
* 
* @param {Object[]} trips The list of trips to group
*/
const getRoundTripGroup = (trips) => {
  let groupedRoundTripList = [];
  const companyCodesList = trips.map(item => item.Details.Company);

  trips.reduce(function (previousValue, currentValue) {
    
    if (!isRoundTripValidToGroup(previousValue, currentValue)) return currentValue;

    if (isTripContainsMultipleCompanies(companyCodesList)) {
      // if we have more than one round trips under different companies then do group the round trips.
      groupedRoundTripList = [...groupedRoundTripList, previousValue, currentValue];
    } else if (!isTripContainsMultipleCompanies(companyCodesList)) {
      //if we have more than one round trips under the same company (groupedRoundTripList.length > 0) then do not group any round trip at all
      groupedRoundTripList = (groupedRoundTripList.length === 0) ? [previousValue, currentValue] : [];
    }
    return currentValue;
  })
  return groupedRoundTripList;
}