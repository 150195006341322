import { uniqBy as _uniqBy, sortBy as __sortBy } from 'lodash-es'
import PortsRepository from '@/logic/repositories/PortsRepository';
import { findClosestAfterActiveDate, findClosestPrevActiveDate, isBeforeToday } from '../../logic/helpers/dateUtils';
import { getConfigurationNumber } from '@/logic/helpers/utils';

export const getUniqueSortedTrips = (trips) => {

  const uniqueTrips = _uniqBy(trips, (t) => {
    return t.IDSCode;
  });
  
  return __sortBy(uniqueTrips, (t) => {
    if (t.isDirectTrip) return t.timings.unix;
    else return t.duration;
  });
}

// indicates that one of the two destinations involved on the search is a
// child port of an island
export const hasParentPort = (port) => {
  return parentPort(port) !== port;
}


export const parentPort = (port) => {
  let parentIsland = PortsRepository.getParentIsland(port);
  if (!parentIsland) {
    return port;
  }  

  // some islands have a parent island with multiple ports. The reason
  // is that we have assigned Greece, Turkey and Italy to large 'islands'
  // If that is the case, we do not want to return the huge array of
  // children and search for them
  if (parentIsland.children.length > getConfigurationNumber('broaden_search_child_ports_limit', 4)) {
    return port;
  }

  return parentIsland.LocationAbbr;
}

export const calculateClosestAlternateDates = (directTrips, activeDate) => {
  const alternativeTrips = directTrips.filter((t) => t.alternate).filter(t => !isBeforeToday(t.timings.DepartureDateTime));
  if (alternativeTrips.length === 0) {
    return [];
  }
  
  const alternateDates = alternativeTrips.map((at) => at.timings.DepartureDateTime);
  return [findClosestPrevActiveDate(alternateDates, activeDate), findClosestAfterActiveDate(alternateDates, activeDate)];
}
