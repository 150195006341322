import { adaptPetAccommodation } from "../adapters/petsAdapter";

let petTypes = [];
let petAccommodations = [];

export const PET_POLICIES = {
  TICKET: 'TICKET',
  NOTICKET: 'NOTICKET',
  NOTALLOWED: 'NOTALLOWED',
  OFFLINE: 'OFFLINE'
}

const companyClassKey = (company, abbr) => `${company}:${abbr}`;

export default {
  initialize: function (data) {
    if (data.petTypes) petTypes = data.petTypes;
  },

  addPetClasses(petClasses) {
    const adapted = petClasses
      .map(adaptPetAccommodation)
      .filter((a) => !a.isInvalid);
      
    adapted.forEach(a => {
      const accommKey = companyClassKey(a.companyAbbr, a.abbr);
      petAccommodations[accommKey] = a;
    });
  },
  
  getPetClass(companyAbbr, abbr) {
    return petAccommodations[companyClassKey(companyAbbr, abbr)];
  },

  getPetTypes() {
    return petTypes;
  }
}

export const createPetPolicy = (policy, petOptions, petTypes) => {
  const isPetOptionsInvalid = petOptions.some(o => o.isInvalid) || petOptions.length === 0;
  const isPetTypesInvalid = petTypes.some(o => o.isInvalid) || petTypes.length === 0;
  const { type = PET_POLICIES.NOTICKET, summary = '', ticketDescription = '' } = policy;

  return {
    type: (isPetOptionsInvalid || isPetTypesInvalid) ? PET_POLICIES.NOTALLOWED : type,
    summary,
    ticketDescription
  }
}