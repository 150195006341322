//@ts-check

import { createPricingPerPet } from '@/logic/models/pricing/PricingPerPet';
import DirectTripModel from '@/logic/models/trips/DirectTripModel';
import { removeColon } from '../../helpers/stringUtils';
import { createClassAnalysisItem } from './ClassAnalysisItem';
import { createPricingPerPassenger } from './PricingPerPassenger';
import { createPricingPerVehicle } from './PricingPerVehicle';

/**
 * @typedef ItineraryPricingExt
 *
 * @property {string} Source
 * @property {string} DepDate
 * @property {string} Deptime
 * @property {string} DepStation
 * @property {string} ArrStation
 * @property {string} Company
 * @property {string} VesselID
 * @property {string} TravelIns
 * @property {string} SpecialCode
 * @property {string} PromoCode
 *
 * @property {import('@/logic/models/pricing/ClassAnalysisItem').ClassAnalysisItem[]} ClassAnalysis
 * @property {import('@/logic/models/pricing/PricingPerPassenger').PricingPerPassenger[]} PricingPerPassenger
 * @property {import('@/logic/models/pricing/PricingPerVehicle').PricingPerVehicle[]} PricingPerVehicle
 * @property {any[]} PricingPerService
 */

class ItineraryPricingExtModel {
  constructor() {}

  /**
   * Initialize using a Direct trip model
   * @param {Object} itinerary
   */
  createFromItinerary(itinerary) {
    // save itinerary source
    this.Source = itinerary.source;

    // Then, collect basic trip details such as vessel, company, ports and date
    // (checking the source allows us to understand if the results have been
    // passed through our convertor, or the source is RAW forthcrs data)
    this.DepDate = itinerary.timings.DepDate;

    // be aware of the this DepTime to Deptime conversion
    // it is a typo that we have to respect (we also have a test for this)
    this.Deptime = removeColon(itinerary.timings.DepTime);
    this.DepStation = itinerary.Details.DepStation;
    this.ArrStation = itinerary.Details.ArrStation;
    this.Company = itinerary.Details.Company;
    this.VesselID = itinerary.Details.VesselID;

    // currently unused variables
    this.TravelIns = '';
    this.SpecialCode = '';
    this.PromoCode = '';

    /** @type {import('@/logic/models/pricing/PricingPerPassenger').PricingPerPassenger[]} */
    this.PricingPerPassenger = [];

    /** @type {import('@/logic/models/pricing/PricingPerVehicle').PricingPerVehicle[]} */
    this.PricingPerVehicle = [];

    /** @type {import('@/logic/models/pricing/PricingPerPet').PricingPerPet[]} */
    this.PricingPerPet = [];

    /** @type {any[]} */
    this.PricingPerService = [];

    /** @type {import('@/logic/models/pricing/ClassAnalysisItem').ClassAnalysisItem[]} */
    this.ClassAnalysis = [];
  }

  /**
   * Add class analysis item to the model
   * @param {string} abbreviation
   * @param {string} type
   * @param {number} quantity
   * @param {import('@/logic/models/Models').PassengerModel[]} passengers
   * @param {string} category
   * @param {number} capacity
   */
  addClassAnalysis(abbreviation, type, quantity, passengers, category, capacity) {
    this.ClassAnalysis.push(createClassAnalysisItem(abbreviation, type, quantity, passengers, category, capacity));
  }

  /**
   * Add an item to price per passenger array
   * @param {Object} passenger
   * @param {boolean} useCabinAlias
   */
  addPricePerPassenger(passenger, useCabinAlias) {
    this.PricingPerPassenger.push(createPricingPerPassenger(passenger, useCabinAlias));
  }

  /**
   * Add an item to price per vehicle array
   * @param {string} type Vehicle type abbreviation
   * @param {string} meters Vehicle meters
   * @param {string} discount Discount code for the vehicle
   * @param {import('@/logic/models/Models').PassengerModel} driver Discount code for the vehicle
   */
  addPricePerVehicle(type, meters, discount, driver) {
    this.PricingPerVehicle.push(createPricingPerVehicle(type, meters, discount, driver));
  }

  addPricingPerPet(pet) {
    this.PricingPerPet.push(createPricingPerPet(pet));
  }

  /**
   * Get the class analysis
   */
  getClassAnalysis() {
    return this.ClassAnalysis;
  }

  /**
   * Get pricing per passenger
   */
  getPricingPerPassenger() {
    return this.PricingPerPassenger;
  }

  /**
   * Get pricing per vehicle
   */
  getPricingPerVehicle() {
    return this.PricingPerVehicle;
  }
}

export default ItineraryPricingExtModel;
