<template>
  <div :class="$style.travelerDetailsForm">
    <div :class="travelEntityClass">
      <div class="form-grid__traveler-title pl0">
        <span>
          <FhTooltip v-if="passenger.isLeader" :message="trans('reservationleaderexplanation')">
            <i class="fh fh-user-fill"></i>
          </FhTooltip>
          <i v-else class="fh fh-user-fill"></i>
          {{ passDescription(passenger) }}
        </span>
      </div>

      <div class="form-grid__inner-grid mb16">
        <PassengerDetailsSelection
          v-for="(item, selectionIndex) in passenger.selectionsPerTrip"
          :key="`selection-${passenger.passengerIndex}-${selectionIndex}`"
          :iconUrl="item.companyIcon"
          :description="item.selectionDescription"
          :passengerDiscountType="item.passengerDiscountType"
          :extraOffersAndDiscounts="item.extraOffersAndDiscounts"
        />
      </div>

      <div class="form-grid__inputs">
        <template v-if="showSavedPassengersDropdown">
          <FhDropdown
            :value="selectedSavedPassenger.id"
            :optionKeyToTrack="'id'"
            :optionKeyToShow="'fullName'"
            :options="filteredSavedPassengers"
            :id="`saved-passengers-dropdown-${passenger.passengerIndex + 1}`"
            @input="handleClick"
          >
            <template #options="{ select }">
              <div>
                <FhDropdownOption
                  :class="$style['travelerDetailsForm__saved-traveler-option']"
                  v-for="(savedPassenger, index) in filteredSavedPassengers"
                  :key="`saved-passengers-dropdown-${passenger.passengerIndex + 1}-option-${index}`"
                  :text="savedPassenger.fullName"
                  :index="index"
                  @click="select(savedPassenger, index)"
                />
              </div>
            </template>
          </FhDropdown>
          <div />
        </template>

        <GenderInput :value="passenger.gender" @input="handleInput('gender', $event)" :id="`gender${passenger.passengerIndex + 1}`" :name="`gender${passenger.passengerIndex + 1}`" rules="required" />
        <div />

        <!-- FIRST NAME -->
        <FhInput
          :model-value="passenger.firstName"
          @update:modelValue="handleInput('firstName', $event)"
          :name="`name-passenger${passenger.passengerIndex + 1}`"
          :id="`name-passenger${passenger.passengerIndex + 1}`"
          :placeholder="trans('firstnameplaceholder')"
          rules="required|min:2|max:40|customLatinText"
          :disabled="hasValidIslanderCode"
          :sanitizingFunction="sanitizeUserInput"
          :autocomplete="'given-name'"
        />

        <!-- LAST NAME -->
        <FhInput
          :model-value="passenger.lastName"
          @update:modelValue="handleInput('lastName', $event)"
          :name="`last-name-passenger${passenger.passengerIndex + 1}`"
          :id="`last-name-passenger${passenger.passengerIndex + 1}`"
          :placeholder="trans('surnameplaceholder')"
          rules="required|min:2|max:40|customLatinText"
          :disabled="hasValidIslanderCode"
          :sanitizingFunction="sanitizeUserInput"
          :autocomplete="'family-name'"
        />

        <!-- BIRTHPLACE -->
        <FhInput
          v-if="requiredDetails.birthplace"
          :model-value="passenger.birthplace"
          @update:modelValue="handleInput('birthplace', $event)"
          :name="`birthplace-passenger${passenger.passengerIndex + 1}`"
          :id="`birthplace-passenger${passenger.passengerIndex + 1}`"
          :placeholder="trans('birthplaceplaceholder')"
          rules="required|min:3|customLatinText"
          :sanitizingFunction="sanitizeUserInput"
        />

        <!-- BIRTHDATE -->
        <DateInput
          v-if="requiredDetails.birthday"
          :isBirthDateInput="true"
          :placeholder="trans('birthdateplaceholder')"
          rules="required|customBirthdate"
          :name="`birthDate-passenger${passenger.passengerIndex + 1}`"
          :id="`birthDate-passenger${passenger.passengerIndex + 1}`"
          :value="passenger.birthDateFull"
          @input="setBirthDate"
        />

        <!-- NATIONALITY -->
        <NationalityInput
          v-if="requiredDetails.nationality"
          :inputId="`nationality-passenger${passenger.passengerIndex + 1}`"
          :value="passenger.nationality"
          @input="handleInput('nationality', $event)"
          :placeholder="trans('nationality')"
          :name="`nationality-passenger${passenger.passengerIndex + 1}`"
          :id="`nationality-passenger${passenger.passengerIndex + 1}`"
          rules="required"
        />

        <!-- PASSPORT -->
        <FhInput
          v-if="requiredDetails.document"
          :model-value="passenger.passport"
          @update:modelValue="handleInput('passport', $event)"
          :name="`passport-passenger${passenger.passengerIndex + 1}`"
          :id="`passport-passenger${passenger.passengerIndex + 1}`"
          :placeholder="trans('passportplaceholder')"
          rules="required|min:5"
          :sanitizingFunction="sanitizeUserInput"
          :disabled="lockDocumentNumber"
        />

        <!-- PASSPORT EXPIRATION-->
        <DateInput
          v-if="requiredDetails.docExpiration"
          :isBirthDateInput="false"
          :isDocDateInput="true"
          :placeholder="trans('expirationdateplaceholder')"
          rules="required|customDocumentExpiration"
          :name="`docDate-passenger${passenger.passengerIndex + 1}`"
          :id="`docDate-passenger${passenger.passengerIndex + 1}`"
          :value="passenger.documentDateFull"
          @input="setDocDate"
        />
      </div>

      <!-- ISLANDER CODE -->
      <IslanderCodeInput
        class="pb8"
        v-if="isIslanderCodeDiscountAvailable && !passenger.isInfant"
        :passenger="passenger"
        :firstNameField="firstNameField"
        :lastNameField="lastNameField"
        :name="`islanderCode${this.passenger.passengerIndex + 1}`"
        :id="`islanderCode${this.passenger.passengerIndex + 1}`"
        @toggleIslanderField="(value) => handleInput('islanderCodeEnabled', value)"
        @input="(value) => handleInput('islanderCode', value)"
        @islanderDetailsChange="(key, value) => handleInput(key, value)"
      />
    </div>

    <div :class="$style.travelerDetailsForm__toggle" v-if="showSavedPassengersDropdown">
      <ToggleSwitch :hasFixedHeight="false" :value="passenger.shouldSaveDetails" :text="toggleSwitchText" @input="handleToggleClick" />
    </div>
  </div>
</template>

<script>
import { trans, passDescription } from '@/filters';
import PassengerDetailsSelection from '@/components/book/PassengerDetailsSelection/PassengerDetailsSelection';
import GenderInput from '@/components/shared/inputs/GenderInput/GenderInput';
import DateInput from '@/components/shared/inputs/DateInput/DateInput';
import IslanderCodeInput from '@/components/book/IslanderCodeInput';
import NationalityInput from '@/components/shared/inputs/NationalityInput';
import { sanitizeUserInput } from '@/logic/helpers/sanitizers';
import { isPassengerChangeAffectingPrice } from './trackTravelerChange';
import { isPricableOffer, PASSENGER_OFFER_STATUS } from '@/logic/BL/common/passengerOffers/passengerOffer';
import { DATE_FORMAT, isValidBirthDate, isValidExpDate } from '@/logic/helpers/dateUtils';
import { getConfigurationValue } from '@/logic/helpers/utils';
import { message } from '@/logic/helpers/utils';
import emitter from '@/emitter';
import { mapState } from 'vuex';

export default {
  name: 'PassengerDetailsForm',
  components: {
    PassengerDetailsSelection,
    GenderInput,
    DateInput,
    IslanderCodeInput,
    NationalityInput,
  },
  props: {
    passenger: Object,
    savedPassengers: Array,
    isIslanderCodeDiscountAvailable: Boolean,
  },
  data() {
    return {
      firstNameField: `name-passenger${this.passenger.passengerIndex + 1}`,
      lastNameField: `last-name-passenger${this.passenger.passengerIndex + 1}`,
    };
  },
  emits: ['savedPassengerSelected', 'input', 'onPriceAffectingChange'],
  created() {
    emitter.$on('islanderDetailsChange', ({ passengerUid, key, value }) => {
      if (passengerUid === this.passenger.uid) this.handleInput(key, value);
    });
  },
  watch: {
    passenger: {
      handler(n, o) {
        if (isPassengerChangeAffectingPrice(o, n)) {
          this.$emit('onPriceAffectingChange', 'gender');
        }
      },
      deep: true,
    },
    hasUpdatedSavedPassengerDetails() {
      this.handleInput('shouldSaveDetails', this.hasUpdatedSavedPassengerDetails);
    },
  },
  methods: {
    trans,
    passDescription,
    sanitizeUserInput,
    handleToggleClick(value) {
      this.handleInput('shouldSaveDetails', value);
    },
    handleClick(savedPassenger) {
      this.$emit('savedPassengerSelected', savedPassenger.id, this.passengerUid);
    },
    handleInput(key, value) {
      this.$emit('input', this.passengerUid, key, value);
    },
    setBirthDate(value) {
      if (isValidBirthDate(value)) {
        let date = this.$dayjs(value, DATE_FORMAT);
        this.handleInput('birthday', date.format('DD'));
        this.handleInput('birthmonth', date.format('MM'));
        this.handleInput('birthyear', date.format('YYYY'));
      } else {
        this.handleInput('birthday', '');
        this.handleInput('birthmonth', '');
        this.handleInput('birthyear', '');
      }
      this.handleInput('birthDateFull', value);
    },
    setDocDate(value) {
      if (isValidExpDate(value)) {
        let date = this.$dayjs(value, DATE_FORMAT);
        this.handleInput('docexpday', date.format('DD'));
        this.handleInput('docexpmonth', date.format('MM'));
        this.handleInput('docexpyear', date.format('YYYY'));
      } else {
        this.handleInput('docexpday', '');
        this.handleInput('docexpmonth', '');
        this.handleInput('docexpyear', '');
      }
      this.handleInput('documentDateFull', value);
    },
  },
  computed: {
    ...mapState({
      requiredDetails: (state) => state.bookingModule.passengerDetailsRequirements,
      errors: (state) => state.validationModule.errors,
    }),
    passengerUid() {
      return this.passenger.uid;
    },
    filteredSavedPassengers() {
      // Options for the saved passengers dropdown. We want to show a saved passenger only if he has been selected
      // by this passenger, has not yet been selected or is a new passenger
      return this.savedPassengers.filter((p) => p.targetPassengerUid === this.passengerUid || p.targetPassengerUid === '' || p.id === 'newPassengerId');
    },
    defaultSavedPassenger() {
      return this.savedPassengers.find((p) => p.id === 'newPassengerId');
    },
    selectedSavedPassenger() {
      return this.filteredSavedPassengers.find((p) => p.targetPassengerUid === this.passengerUid) || this.defaultSavedPassenger;
    },
    showSavedPassengersDropdown() {
      return this.filteredSavedPassengers.length > 0 && this.savedPassengersEnabled;
    },
    savedPassengersEnabled() {
      const activeUserProfileMenus = getConfigurationValue('active_user_profile_menus', '');
      return activeUserProfileMenus.includes('passengers');
    },
    hasValidIslanderCode() {
      return this.passenger.islanderCodeStatus === PASSENGER_OFFER_STATUS.VALID && this.passenger.islanderCode !== '';
    },
    lockDocumentNumber() {
      return isPricableOffer(this.passenger.residenceDiscount);
    },
    toggleSwitchText() {
      if (this.selectedSavedPassenger.id === 'newPassengerId') return message('passDet.passengers.savePassengerSwitch');
      return message('generic.saveChangesToAccount');
    },
    hasUpdatedSavedPassengerDetails() {
      if (this.filteredSavedPassengers.length === 0) return false;
      if (this.selectedSavedPassenger.id === 'newPassengerId') return false;
      if (this.passenger.gender !== this.selectedSavedPassenger.gender) return true;
      if (this.passenger.firstName !== this.selectedSavedPassenger.firstName) return true;
      if (this.passenger.lastName !== this.selectedSavedPassenger.lastName) return true;
      if (this.passenger.birthplace !== this.selectedSavedPassenger.birthplace) return true;
      if (this.passenger.birthDateFull !== this.selectedSavedPassenger.birthDateFull) return true;
      if (this.passenger.nationality !== this.selectedSavedPassenger.nationality) return true;
      if (this.passenger.passport !== this.selectedSavedPassenger.passport) return true;
      if (this.passenger.documentDateFull !== this.selectedSavedPassenger.documentDateFull) return true;
      if (this.passenger.islanderCode !== this.selectedSavedPassenger.man) return true;
      return false;
    },
    travelEntityClass() {
      if (this.isIslanderCodeDiscountAvailable && this.showSavedPassengersDropdown) return this.$style['travelerDetailsForm__main__passenger--save-isIslander'];
      if (this.isIslanderCodeDiscountAvailable) return this.$style['travelerDetailsForm__main__passenger--isIslander'];
      if (this.showSavedPassengersDropdown) return this.$style['travelerDetailsForm__main--save'];
      return this.$style.travelerDetailsForm__main;
    },
  },
};
</script>

<style module lang="scss">
@import './traveler-details-form.scss';
</style>
