<template>
  <div>
    <h1 class="aegean-landing-mobile-title" v-if="isMobile && initialLoad">{{ trans('mobilelandingheading') }}</h1>
  </div>
</template>

<script>
import DesktopNavigationTabs from '@/components/navigation/DesktopNavigationTabs/DesktopNavigationTabs'; 
import { trans } from '@/filters';

export default {
  extends: DesktopNavigationTabs,
  name: 'AegeanNavigationTabs',
  methods: {
    trans
  }
};
</script>
