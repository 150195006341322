<template>
  <div class="results-app__footer results-app__footer--error" v-if="status === 'ERROR'">
    <i class="fh fh-notification text-danger results-app__footer--error-icon pb20"></i>
    <button class="btn btn-link btn-md mt0" @click="retry()">{{ trans('tryagain') }}</button>
  </div>
</template>

<script>
import { trans } from '@/filters';

export default {
  name: 'ResultsAppRetryCta',
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  emits: ['retry'],
  methods: {
    trans,
    retry() {
      this.$emit('retry');
    },
  },
};
</script>