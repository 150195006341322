import { arrayUnique } from '@/logic/helpers/arrayFunctions';

/**
 * Returns the list of unique accommodation codes included in a pricing request
 * @param {ItinerarypricingPayloadExtModel} pricingPayload 
 */
const getUniqueAccommodations = (pricingPayload) => arrayUnique(pricingPayload.PricingPerPassenger.map(p => p.ClassAbbr));

/**
 * Returns true if only a single accommodation type is included in a pricing request
 * @param {ItinerarypricingPayloadExtModel} pricingPayload 
 */
export const hasUniqueAccommodations = (pricingPayload) => getUniqueAccommodations(pricingPayload).length === 1;

/**
 * Returns true if multiple accommodations are selected
 * @param {ItinerarypricingPayloadExtModel} pricingPayload 
 */
export const hasMultipleAccommodations = (pricingPayload) => !hasUniqueAccommodations(pricingPayload);