import dayjs from '@/logic/services/date/dateService';

// TODO: add tests for this
let ClosestTripPipe = {
  //--------------------------------------------------------------------------
  // Return the date of a trip item (direct or indirect)
  getTripDate: function(trip) {
    // we don't know if the trip is direct or double, so check first
    if (!trip.firstLeg) return trip.timings.DepartureDateTime;
    return trip.firstLeg.timings.DepartureDateTime;
  },

  //--------------------------------------------------------------------------
  // From a list of trips (Itinerary format), this function returns the
  // date time of the closest one
  fromTripsArray: function(trips) {
    let self = this;

    // initialize current date
    let today = dayjs();

    // set minimumDiff to a very high value
    let minimumDiff = 10000;

    // create the result object
    let result = {
      datetime: undefined,
      difference: 0
    };

    let _closest = undefined;
    let _diff = minimumDiff;

    trips.forEach(trip => {
      _closest = self.getTripDate(trip);
      _diff = _closest.diff(today, 'days');

      if (_diff < minimumDiff) {
        minimumDiff = _diff;
        result.difference = _diff;
      }
    });

    return result;
  }
};

export default ClosestTripPipe;
