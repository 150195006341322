/**
 * This class is responsible for getting the list of indirect trips from
 * FerryhopperCRS. It performs the HTTP call, and returns a promise that once
 * resolved, it contains a list of Multitrips (generated through cached data).
 *
 * These multitrips are then passed to the results section for further
 * processing/verification etc.
 *
 */
import IndirectTripModel from '@/logic/models/trips/IndirectTripModel'
import { fetchCRSItineraries } from '@/logic/services/ApiCRS'
import { userTimingEvent } from '@/logic/services/events/EventService'
import { logException } from '@/logic/services/events/errorLogging'
import { defer } from 'rxjs'
import { map, retry, tap } from 'rxjs/operators'
import { getVerifiedIndirectTrip$ } from './IndirectTripsVerifier'


const createUnverifiedIndirectTrips = (unverifiedIndirectTrips) => {
  return unverifiedIndirectTrips.reduce((acc, uTrip) => {
    try {
      return [...acc, new IndirectTripModel(uTrip)];
    } catch (exception) {
      if (typeof exception !== 'string') {
        logException('FerryhopperCRS: Unparsable response', exception);
      }
      return acc;
    }
  }, []);

};

const createIndirectSuggestions = (suggestions, depStation, arrStation, depDate) => {
  return suggestions.map(suggestion => {
    let indirectTripSuggestion = new IndirectTripModel({})
    indirectTripSuggestion.setSuggestionQueries(depStation, arrStation, suggestion, depDate);
    return indirectTripSuggestion;
  })
}


/**
 * @constructor
 * @param  {[string]} origin      [Location abbreviation]
 * @param  {[string]} destination [Location abbreviation]
 * @param  {[string]} date        [Date in FCRS format]
 */
let Collection = function (origin, destination, date) {
  // collection settings
  this.depStation = origin;
  this.arrStation = destination;
  this.depDate = date;
};

Collection.prototype = {
  
  // Queries FerryhopperCRS for the requested data, and returns a promise
  // that will be resolved/rejected depending on the response
  get() {
    let startTime = new Date();

    const indirectsObservable$ = defer(() => fetchCRSItineraries(this.depStation, this.arrStation, this.depDate))
      .pipe(
        retry(2),
        map(response => {          
          const { itineraries, suggestions } = response;

          if (itineraries.length > 0) {
            return createUnverifiedIndirectTrips(itineraries);
          } 
          
          if (suggestions.length > 0) {
            return createIndirectSuggestions(suggestions, this.depStation, this.arrStation, this.depDate);
          } 

          return [];
        }),
        map((uTrips) => uTrips.map((uTrip, i) => getVerifiedIndirectTrip$(uTrip, i))),
        tap(() => {
          // get the time needed to fullfil the request and send event to google analytics
          let endTime = new Date();
          let timeDiff = endTime - startTime;
          userTimingEvent('Indirect results', `${this.depStation}:${this.arrStation}`, timeDiff);
        })
      );
      
    return indirectsObservable$;
  }
};

export default Collection;


