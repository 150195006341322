<template>
  <div :class="[$style.indirectItinerarySkeleton]">
    <div v-if="isMobile" :class="[$style.indirectItinerarySkeleton__box, $style['indirectItinerarySkeleton__box--right'], 'skeleton-animation', 'mb15']" />
    <div v-else class="pb25" />
    <div :class="[$style.indirectItinerarySkeleton__element]">
      <div :class="[$style.indirectItinerarySkeleton__left, 'skeleton-animation']">
        <div :class="$style.indirectItinerarySkeleton__row">
          <div :class="[$style.indirectItinerarySkeleton__item, $style['indirectItinerarySkeleton__item--short'], $style['indirectItinerarySkeleton__item--md']]" />
        </div>
        <div :class="$style.indirectItinerarySkeleton__row">
          <div :class="[$style.indirectItinerarySkeleton__item, $style['indirectItinerarySkeleton__item--tall'], $style['indirectItinerarySkeleton__item--sm'], 'mr8']" />
          <div :class="$style.indirectItinerarySkeleton__column">
            <div :class="['mb4', $style.indirectItinerarySkeleton__item, $style['indirectItinerarySkeleton__item--short'], $style['indirectItinerarySkeleton__item--md']]" />
            <div :class="[$style.indirectItinerarySkeleton__item, $style['indirectItinerarySkeleton__item--short'], $style['indirectItinerarySkeleton__item--md']]" />
          </div>
        </div>
      </div>
      <div v-if="!isMobile" :class="$style.indirectItinerarySkeleton__right">
        <div :class="[$style.indirectItinerarySkeleton__box, 'skeleton-animation']" />
      </div>
    </div>
    <div :class="$style.indirectItinerarySkeleton__spacer">
      <div v-if="!isMobile" :class="[$style.indirectItinerarySkeleton__box, 'skeleton-animation']" />
    </div>
    <div :class="[$style.indirectItinerarySkeleton__element]">
      <div :class="[$style.indirectItinerarySkeleton__left, 'skeleton-animation']">
        <div :class="$style.indirectItinerarySkeleton__row">
          <div :class="[$style.indirectItinerarySkeleton__item, $style['indirectItinerarySkeleton__item--short'], $style['indirectItinerarySkeleton__item--md']]" />
        </div>
        <div :class="$style.indirectItinerarySkeleton__row">
          <div :class="[$style.indirectItinerarySkeleton__item, $style['indirectItinerarySkeleton__item--tall'], $style['indirectItinerarySkeleton__item--sm'], 'mr8']" />
          <div :class="$style.indirectItinerarySkeleton__column">
            <div :class="['mb4', $style.indirectItinerarySkeleton__item, $style['indirectItinerarySkeleton__item--short'], $style['indirectItinerarySkeleton__item--md']]" />
            <div :class="[$style.indirectItinerarySkeleton__item, $style['indirectItinerarySkeleton__item--short'], $style['indirectItinerarySkeleton__item--md']]" />
          </div>
        </div>
      </div>
      <div v-if="!isMobile" :class="$style.indirectItinerarySkeleton__right">
        <div :class="[$style.indirectItinerarySkeleton__box, 'skeleton-animation']" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndirectItinerarySkeleton'
};
</script>

<style module lang="scss">
@import './indirect-itinerary-skeleton.scss';
</style>
