<template>
<div>
  
  <div class="seating-app-mobile-top-bar" v-if="isMobile">
    <div class="column passengers-count-column">
      <h5 class="passengers-count-heading">
        <i class="fh fh-users-fill"></i> {{trans('passengers')}}:
      </h5>
      <div class="btn-bar">
        <span class="txt-value txt-button"  @click="removeTraveler('passenger')" :disabled="minPassengersReached || isOccupied">
          <i class="fh fh-minus" aria-hidden="true"></i>
        </span>
        <span class="txt-value center">{{passengersCount}}</span>
        <span class="txt-value txt-button" @click="addTraveler('passenger')" :disabled="maxPassengersReached || isOccupied">
          <i class="fh fh-plus" aria-hidden="true"></i>
        </span>
      </div>
    </div>
    <div class="column vehicles-count-column">
      <h5 class="vehicles-count-heading">
        <i class="fh fh-vehicles"></i><span v-if="!hasCarsRestriction"> {{trans('vehicles')}}:</span>
      </h5>
      <div class="btn-bar" v-if="hasCarsRestriction">
        <span class="txt-value center not-supported">{{trans('vehiclesnotsupported')}}</span>
      </div>
      <div class="btn-bar" v-else>
        <span class="txt-value txt-button" @click="removeTraveler('vehicle')" :disabled="minVehiclesReached || isOccupied">
          <i class="fh fh-minus" aria-hidden="true"></i>
        </span>
        <span class="txt-value center" >{{vehiclesCount}}</span>
        <span class="txt-value txt-button" @click="addTraveler('vehicle')" :disabled="maxVehiclesReached || hasCarsRestriction || isOccupied">
          <i class="fh fh-plus" aria-hidden="true"></i>
        </span>
      </div>
    </div>
  </div>

<div class="aegean-action-bar aegean-action-bar--borderless" v-else>
  <button class="btn btn-primary flex-button aegean-action-bar__button" @click="removeTraveler('passenger')" :disabled="minPassengersReached || isOccupied">
    <i class="fh fh-minus" aria-hidden="true"></i>
  </button>
  <span class="aegean-action-bar__secondary_title">    
    <i class="fh" :class="[passengersCount > 1 ? 'fh-users-fill' : 'fh-user-fill']"></i> 
    <span class="hidden-xs hidden-sm text-uppercase" v-if="passengersCount > 1">{{trans('passengers')}} : </span>
    <span class="hidden-xs hidden-sm text-uppercase" v-else>{{ trans('passenger') }} : </span>
    {{ passengersCount }}
  </span>
  <button class="btn btn-primary flex-button aegean-action-bar__button" @click="addTraveler('passenger')" :disabled="maxPassengersReached || isOccupied">
    <i class="fh fh-plus" aria-hidden="true"></i>
  </button>
  <div class="aegean-action-bar__space-fill"></div>  
  <template v-if="hasCarsRestriction">
    <span class="aegean-action-bar__secondary_title">
      <small><i class="fh fh-no-vehicles"></i> {{ trans('vehiclesnotsupported') }}</small>
    </span>
  </template>
  <template v-else>
    <button class="btn btn-primary flex-button aegean-action-bar__button" @click="removeTraveler('vehicle')" :disabled="minVehiclesReached || isOccupied">
      <i class="fh fh-minus" aria-hidden="true"></i>
    </button>
    <span class="aegean-action-bar__secondary_title">
      <i class="fh fh-vehicles"></i>     
      <span class="hidden-xs hidden-sm text-uppercase" v-if="vehiclesCount > 1">{{ trans('vehicles') }} : </span>
      <span class="hidden-xs hidden-sm text-uppercase" v-else>{{ trans('vehicle') }} : </span>
      {{ vehiclesCount }}
    </span>
    <button class="btn btn-primary flex-button aegean-action-bar__button" @click="addTraveler('vehicle')" :disabled="maxVehiclesReached || hasCarsRestriction || isOccupied">
      <i class="fh fh-plus" aria-hidden="true"></i>
    </button>
  </template>
</div>
</div>
</template>


<script>

import { mapActions, mapGetters } from 'vuex';
import { trans } from '@/filters';

export default {
  name: 'AegeanSeatingAppTopBar',
  props: ['isOccupied', 'hasCarsRestriction'],
  emits: [],
  computed: {
    ...mapGetters({
      passengersCount: 'searchModule/passengersCount',
      vehiclesCount: 'searchModule/vehiclesCount',
      maxPassengersReached: 'searchModule/maxPassengersReached',
      maxVehiclesReached: 'searchModule/maxVehiclesReached',
      minPassengersReached: 'searchModule/minPassengersReached',
      minVehiclesReached: 'searchModule/minVehiclesReached',
    }),
  },
  methods: {
    ...mapActions({
      addNewPassenger: 'ticketConfigurationModule/addNewPassenger',
      increasePassengerCount: 'searchModule/increasePassengerCount',
      resetSeatSelectionMessage: 'ticketConfigurationModule/resetSeatSelectionMessage',
      removeLastPassenger: 'ticketConfigurationModule/removeLastPassenger',
      decreasePassengerCount: 'searchModule/decreasePassengerCount',
      handleCabinsOnPassengerRemoval: 'ticketConfigurationModule/handleCabinsOnPassengerRemoval',
      addNewVehicle: 'ticketConfigurationModule/addNewVehicle',
      removeLastVehicle: 'ticketConfigurationModule/removeLastVehicle',
      increaseVehicleCount: 'searchModule/increaseVehicleCount',
      decreaseVehicleCount: 'searchModule/decreaseVehicleCount',
      addNewPet: 'ticketConfigurationModule/addNewPet',
      increasePetCount: 'searchModule/increasePetCount',
      removeLastPet: 'ticketConfigurationModule/removeLastPet',
      decreasePetCount: 'searchModule/decreasePetCount',
    }),
    trans,
    isPassenger(type) {
      return type === 'passenger';
    },
    isVehicle(type) {
      return type === 'vehicle';
    },
    isPet(type) {
      return type === 'pet';
    },
    addTraveler(traveler) {
      if (this.isPassenger(traveler)) {
        this.addNewPassenger();
        this.increasePassengerCount();
        this.resetSeatSelectionMessage();
      }

      if (this.isVehicle(traveler)) {
        this.addNewVehicle();
        this.increaseVehicleCount();
      }

      if (this.isPet(traveler)) {
        this.addNewPet();
        this.increasePetCount();
      }
    },
    removeTraveler(traveler) {
      if (this.isPassenger(traveler)) {
        this.removeLastPassenger();
        this.decreasePassengerCount();
        this.resetSeatSelectionMessage();
        this.handleCabinsOnPassengerRemoval();
      }

      if (this.isVehicle(traveler)) {
        this.removeLastVehicle();
        this.decreaseVehicleCount();
      }

      if (this.isPet(traveler)) {
        this.removeLastPet();
        this.decreasePetCount();
      }
    },
  },
}
</script>
