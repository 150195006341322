//@ts-check
import { isPricableOffer } from '../../BL/common/passengerOffers/passengerOffer';
import { isNotInfantType } from '../../filterers/DiscountsFilterer';

/**
 * @typedef PricingPerVehicle
 * @property {string} VehicleType
 * @property {string} Meters
 * @property {string} Discount
 * @property {string} Height
 * @property {string} FareCode
 * @property {string} SpecialType
 * @property {string} Loyalty
 * @property {number} DriverIndex
 */

/**
 * Create pricing per vehicle
 * @param {string} type
 * @param {string} meters
 * @param {string} discount
 * @param {import('@/logic/models/Models').PassengerModel} driver
 *
 * @returns {PricingPerVehicle}
 */
export const createPricingPerVehicle = (type, meters, discount, driver) => ({
  VehicleType: type,
  Meters: meters,
  Discount: discount,
  Height: '',
  FareCode: '',
  SpecialType: '',
  Loyalty: isPricableOffer(driver.loyalty) && isNotInfantType(driver.type) ? driver.loyalty.loyaltyNumber : '',
  DriverIndex: driver.passengerIndex
});
