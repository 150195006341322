export const addClassToElement = (element, className) => {
    if (element) element.classList.add(className)
};

export const removeClassFromElement = (element, className) => {
    if (element) element.classList.remove(className);
}

export const getElement = (className) => document.querySelector(className);

export const nodesExist = (className) => document.querySelectorAll(className).length > 0;