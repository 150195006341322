<template>
  <div class="has-feedback">
    <div :class="$style.feedbackInputGender__group">
      <div :class="$style.feedbackInputGender__main">
        <div :class="$style.feedbackInputGender__radios">
          <FhRadio
            v-for="(option, optionIndex) in genders"
            class="mr24"
            :key="'cm-option-' + optionIndex"
            :checked="value === option.value"
            :text="option.text"
            :hasError="hasError"
            @click="$emit('input', option.value)"
          />
        </div>
        <span class="text-error" v-if="hasError">{{ errorMessage }}</span>
      </div>

      <i :class="[infoIconClass, 'fh fh-info mt2']" @click="showModal = true"></i>
    </div>

    <FhModal v-if="showModal" @close="showModal = false" :title="trans('passDet.personalDetails.genderModal.heading')">
      <template #body>
        {{ trans('passDet.personalDetails.genderModal.text') }}
      </template>
    </FhModal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import emitter from '@/emitter';
import { trans } from '@/filters';
import settings from '@/settings';
import { isNull, notNull, message } from '@/logic/helpers/utils';

export default {
  name: 'GenderInput',
  props: {
    value: String,
    name: String,
  },
  emits: ['input'],
  beforeUnmount() {
    emitter.$off('validate', this.validate);
    this.removeError({ name: this.name });
  },
  data: () => ({
    showModal: false,
    genders: [...settings.variables.genders],
  }),
  created() {
    emitter.$on('validate', this.validate);
  },
  watch: {
    value(newValue, oldValue) {
      if (isNull(newValue) && notNull(oldValue)) return;
      this.validate();
    },
    hasError(newValue, oldValue) {
      if (newValue === false) return;
      emitter.$emit('onValidationErrorShown', { name: this.name, value: this.value, error: this.errors.find((error) => error.name === this.name) });
    },
  },
  methods: {
    trans,
    ...mapActions({
      addError: 'validationModule/addError',
      removeError: 'validationModule/removeError',
    }),
    validate() {
      if (!this.value) this.addError({ name: this.name, message: message('generic.error.requiredField') });
      else this.removeError({ name: this.name });
    },
  },
  computed: {
    hasError() {
      return this.errorMessage !== '';
    },
    ...mapState({
      errors: (state) => state.validationModule.errors,
    }),
    errorMessage() {
      const error = this.errors.find((error) => error.name === this.name);
      return error ? error.message : '';
    },
    infoIconClass() {
      let cobrandedClass = '';
      if (this.globalCobrandedVariant === 'easyferry') {
        cobrandedClass = this.$style['feedbackInputGender__info--easyferry'];
      }
      if (this.globalCobrandedVariant === 'aegeanair') {
        cobrandedClass = this.$style['feedbackInputGender__info--aegeanair'];
      }
      return [this.$style.feedbackInputGender__info, cobrandedClass];
    },
  },
};
</script>

<style module lang="scss">
@import './gender-input.scss';
</style>
