<template>
  <div class="booking-flow-box--grey-bg">
    <h4 class="booking-flow-box__header">{{ trans('passDet.travInsIntam.title') }}</h4>
    <span class="booking-flow-box__subtitle mb24"><i v-if="!isMobile" class="fh fh-info mr8 mt3" />{{ trans('passDet.travInsIntam.subtitle') }}</span>

    <div :class="$style.refund__cards">
      <SelectableCard
        :title="trans('passDet.travInsIntam.off.title')"
        :amount="currencyStringPre(standardInsurancePrice)"
        :footerMsg="trans('generic.noExtraCharge')"
        :imageSrc="standardInsuranceImageUrl"
        :selected="!travelInsuranceSelected"
        @click="setTravelInsuranceSelected({ ancillaryMode: insuranceOptions.STANDARD })"
      >
        <StandardInsuranceNew />
      </SelectableCard>

      <div v-show="showWaitingState">
        <SelectableCardSkeleton />
      </div>
      <div v-show="!showWaitingState">
        <SelectableCard
          v-if="travelInsuranceProvider === interamericanProvider"
          :title="trans('passDet.travInsIntam.on.title')"
          :amount="currencyStringPre(travelInsurancePrice)"
          :footerMsg="trans('generic.inTotal')"
          :imageSrc="travelInsuranceImageUrl"
          :selected="travelInsuranceSelected"
          @click="setTravelInsuranceSelected({ ancillaryMode: insuranceOptions.EXTENDED, ancillaryOrder, totalAncillaries, ancillaryUIMode: 'multiple' })"
          :badgeText="trans('generic.recommended')"
          :isLoading="fetchingForPrices"
          :hasError="hasTravelInsuranceError"
          :errorMsg="travelInsuranceError"
        >
          <InteramericanNew @fetchCompleted="fetchCompleted" />
        </SelectableCard>

        <SelectableCard
          v-if="hasTravelInsuranceError"
          :title="trans('passDet.refProt.extended.title')"
          :amount="currencyStringPre(standardInsurancePrice)"
          :footerMsg="trans('generic.inTotal')"
          :imageSrc="fhImage('assets/travel-insurance/DisabledInsurance.svg')"
          :hasError="hasTravelInsuranceError"
          :errorMsg="travelInsuranceError"
        >
        </SelectableCard>
      </div>
    </div>
  </div>
</template>

<script>
import SelectableCard from '@/components/shared/SelectableCard/SelectableCard.vue';
import SelectableCardSkeleton from '@/components/shared/SelectableCard/SelectableCardSkeleton.vue';
import InteramericanNew from '@/components/book/insurance/Interamerican.vue';
import StandardInsuranceNew from '@/components/book/insurance/StandardInsurance.vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import { EXTRA_SERVICE_OPTIONS, EXTRA_SERVICE_PROVIDERS } from '@/logic/BL/extraServices';
import { trans, currencyStringPre, fhImage } from '@/filters';
import { eventTravelInsuranceShown } from '@/logic/services/events/createBookingEvents';

export default {
  name: 'TravelInsuranceOptionsComponent',
  components: {
    SelectableCard,
    SelectableCardSkeleton,
    InteramericanNew,
    StandardInsuranceNew,
  },
  props: {
    ancillaryOrder: {
      type: Number,
    },
    totalAncillaries: {
      type: Number,
    },
  },
  data() {
    return {
      standardInsurancePrice: 0,
      insuranceOptions: EXTRA_SERVICE_OPTIONS,
      interamericanProvider: EXTRA_SERVICE_PROVIDERS.INTERAMERICAN,
      isFetchCompleted: false,
    };
  },
  methods: {
    trans,
    currencyStringPre,
    fhImage,
    ...mapActions({
      setTravelInsuranceSelected: 'bookingModule/setTravelInsuranceSelected',
    }),
    fetchCompleted(value) {
      this.isFetchCompleted = value;
      if (value) eventTravelInsuranceShown(this.travelInsuranceProvider, this.ancillaryOrder, this.totalAncillaries);
    },
  },
  computed: {
    ...mapState({
      fetchingForPrices: (state) => state.uiModule.fetchingForPrices,
      travelInsurancePrice: (state) => state.bookingModule.travelInsurance.price,
      travelInsuranceError: (state) => state.bookingModule.travelInsurance.error,
    }),
    ...mapGetters({
      travelInsuranceProvider: 'bookingModule/getTravelInsuranceProvider',
      travelInsuranceSelected: 'bookingModule/travelInsuranceSelected',
      hasTravelInsuranceError: 'bookingModule/hasTravelInsuranceError',
    }),
    showWaitingState() {
      return !this.isFetchCompleted && !this.hasTravelInsuranceError;
    },
    standardInsuranceImageUrl() {
      return fhImage('assets/travel-insurance/HasNoInsurance.svg');
    },
    travelInsuranceImageUrl() {
      return this.hasTravelInsuranceError ? fhImage('assets/travel-insurance/DisabledInsurance.svg') : fhImage('assets/travel-insurance/HasInsurance.svg');
    },
  },
};
</script>

<style module lang="scss">
@import '../refund/refund.scss';
</style>
