<template>
  <div>
    <BookingFlowGrid :class="[isMobile ? 'mt20' : 'mt50']">
      <template #col>
        <div id="passengers-details-fields">
          <h4 class="booking-flow-box__header">{{ passengerDetailsTitle }}</h4>
          <template v-if="passengers.length > 0">
            <PassengerDetailsForm
              v-for="passenger in passengers"
              :key="passenger.id"
              :passenger="passenger"
              :savedPassengers="savedPassengers"
              :isIslanderCodeDiscountAvailable="isIslanderCodeDiscountAvailable"
              @input="handlePassengersFormInput"
              @savedPassengerSelected="handleSavedPassengerSelected"
              @onPriceAffectingChange="onPriceAffectingChange"
            />
          </template>
        </div>

        <template v-if="pets.length > 0">
          <PetDetailsForm v-for="pet in pets" :key="pet.id" :pet="pet" @input="handlePetsFormInput" />
        </template>

        <template v-if="vehicles.length > 0">
          <VehicleDetailsForm v-for="vehicle in vehicles" :key="vehicle.uid" :vehicle="vehicle" :savedVehicles="savedVehicles" @input="handleVehiclesFormInput" @savedVehicleSelected="handleSavedVehicleSelected" />
        </template>

        <ContactDetailsForm v-model="contactInfo" @shouldUpdateContactDetails="shouldUpdateContactDetails" />

        <InvoiceDetails v-if="invoiceDetailsEnabled" />

        <TicketCollection v-if="showTicketCollection" />

        <component v-for="(componentName, componentIndex) in ancillaryComponents" :key="componentName" :is="componentName" :ancillaryOrder="componentIndex + 1" :totalAncillaries="ancillaryComponents.length" />

        <FhBookingCart v-if="isGridSingleCol" :isGridSingleCol="true" :isWaiting="isWaiting" @showTripDetailsModal="showTripDetails = true" />

        <CouponInput v-else class="pt24 mb30" />

        <template v-if="isGridSingleCol">
          <CouponInput :isGridSingleCol="true" />
          <PriceMobile />
        </template>

        <PayCta
          v-model="acceptsConditions"
          :isSubscribed="newsletterSubscribe"
          :errorBag="errorBag"
          :isWaiting="isWaiting"
          @showPrivacyModal="showPrivacyModal = true"
          @newsletterSubscriptionInput="onNewsletterSubscriptionInput"
          @showTermsModal="showTermsModal = true"
          @finalCheckout="finalCheckout"
        />
      </template>
      <template #col-sm>
        <FhBookingCart v-if="!isGridSingleCol" :isGridSingleCol="false" :isWaiting="isWaiting" @showTripDetailsModal="showTripDetails = true" />
      </template>
    </BookingFlowGrid>

    <!-- hidden form for embedding checkout data -->
    <div class="hidden" id="checkout-form-container"></div>

    <!-- Trip details modal -->
    <FhModal v-if="showTripDetails" @close="showTripDetails = false" :title="trans('passDet.cart.tripDetails.modal.heading')">
      <template #body>
        <TripDetails v-for="trip in trips" :key="trip.id" :trip="trip" />
      </template>
    </FhModal>

    <!-- Privacy modal -->
    <FhModal v-if="showPrivacyModal" @close="showPrivacyModal = false" :title="trans('privacymodaltitle')">
      <template #body>
        <LoadingSpinner v-if="privacyContent === ''" />
        <div v-else v-html="privacyContent"></div>
      </template>
    </FhModal>

    <!-- Terms modal -->
    <FhModal v-if="showTermsModal" @close="showTermsModal = false" :title="trans('termsmodaltitle')">
      <template #body>
        <LoadingSpinner v-if="termsContent === ''" />
        <div v-else v-html="termsContent"></div>
      </template>
    </FhModal>
  </div>
</template>

<script>
import { nextTick } from 'vue';
import { sortBy as _sortBy } from 'lodash-es';
import emitter from '@/emitter';
import { getPageLanguage, isNull, message } from '@/logic/helpers/utils';
import { makeCombinedBookingIssueRequest } from '@/logic/services/ApiCRS';
import { getBookingTerms } from '@/logic/services/ApiGateway';
import GenericErrorCodes from '@/logic/services/GenericErrorCodes';
import { logException } from '@/logic/services/events/errorLogging';
import ContactInfoModel from '@/logic/models/ContactInfoModel';
import { createCombinedIssueRequests } from '@/logic/services/booking/createCombinedIssueRequests';
import TripsFilterer from '@/logic/filterers/TripsFilterer';
import TicketCollection from '@/components/book/TicketCollection';
import ContactDetailsForm from '@/components/book/ContactDetailsForm';
import PassengerDetailsForm from '@/components/book/PassengerDetailsForm';
import PetDetailsForm from '@/components/book/PetDetailsForm';
import VehicleDetailsForm from '@/components/book/VehicleDetailsForm';
import InvoiceDetails from '@/components/book/invoice/InvoiceDetails';
import RefundOptionsComponent from '@/components/book/refund/RefundOptionsComponent';
import RefundOptionsAltComponent from '@/components/book/refundAlt/RefundOptionsAltComponent';
import TravelInsuranceOptionsComponent from '@/components/book/insurance/TravelInsuranceOptionsComponent';
import TravelInsuranceOptionsAltComponent from '@/components/book/insuranceAlt/TravelInsuranceOptionsAltComponent';
import SupportPlusComponent from '@/components/book/supportPlus/SupportPlusComponent';
import FlexiComponent from '@/components/book/flexi/FlexiComponent';
import SupportPlusAltComponent from '@/components/book/supportPlusAlt/SupportPlusAltComponent';
import PayCta from '@/components/book/PayCta';
import FhBookingCart from '@/components/cart/BookingCart/FhBookingCart';
import TripDetails from '@/components/book/TripDetails/TripDetails';
import CouponInput from '@/components/book/coupon/CouponInput';
import PriceMobile from '@/components/book/PriceMobile/PriceMobile';
import BookingFlowGrid from '@/components/shared/BookingFlowGrid';
import { saveBookingRequest, getSavedBookingRequest, clearSavedBookingRequest } from '@/logic/services/storage/saveBookingRequest';
import { createBookingRequest } from '@/logic/services/booking/createBookingRequest';
import { NAVIGATION_TABS } from '@/store/modules/navigation.module';
import { trans } from '@/filters';

// Vuex
import { mapActions, mapState, mapGetters } from 'vuex';

// Rehydrated state reconcilers
import { reconcilePassenger, reconcileVehicle, reconcilePet, reconcileContactInfo } from './stateReconcilers';
import { scrollToElementId } from '../../logic/dom/scrollTo';
import { addErrorInBag, createError, ERROR_TYPES, removeErrorFromBag } from '../../logic/models/errorBag/errorBag';

import { IsoFromLocale } from '@/logic/helpers/ISOFromLocale';

import { check as ICCheck, submitAllNonValid as submitNonValidIC } from '@/logic/managers/discounts/IslanderCode';
import {
  eventClickedOnBackButton,
  eventWrongBookingDetails,
  eventCheckoutCouponFailed,
  eventCheckoutBookingRestrictionError,
  eventCheckoutBookingValidationError,
  eventCheckoutPricingFailed,
  eventValidationErrorShown,
} from '@/logic/services/events/createBookingEvents';
import { eventCheckoutBookingStep } from '../../logic/services/events/createEcommerceEvents';
import { createTripsForBooking, createPassengersDetailsForBooking, createVehiclesDetailsForBooking, createPetsDetailsForBooking } from '../../logic/services/booking/createTripsForBooking';
import { mergeTripsWithTravelerDetails } from '../../logic/services/booking/mergeTripsWithTravelerDetails';
import { debouncedShort } from '@/logic/helpers/debounce';
import { updateObjectWithProp } from '@/logic/helpers/objectManipulation';
import SavedPassengersMixin from './SavedPassengersMixin';
import SavedVehiclesMixin from './SavedVehiclesMixin';
import { getSortedAncillaries, EXTRA_SERVICES } from '@/logic/BL/extraServices';

const BOOKING_RESTRICTION_ERROR_STATUS = 405;
const BOOKING_VALIDATION_ERROR_STATUS = 406;

export default {
  name: 'BookingApp',
  mixins: [SavedPassengersMixin, SavedVehiclesMixin],
  components: {
    ContactDetailsForm,
    PassengerDetailsForm,
    VehicleDetailsForm,
    InvoiceDetails,
    RefundOptionsComponent,
    RefundOptionsAltComponent,
    TravelInsuranceOptionsComponent,
    TravelInsuranceOptionsAltComponent,
    SupportPlusComponent,
    FlexiComponent,
    SupportPlusAltComponent,
    FhBookingCart,
    TripDetails,
    CouponInput,
    PriceMobile,
    PayCta,
    PetDetailsForm,
    TicketCollection,
    BookingFlowGrid,
  },
  data() {
    return {
      passengers: [],
      savedPassengers: [],
      savedVehicles: [],
      vehicles: [],
      pets: [],
      // error bag holding any errors (local or remote) generated within this section of the booking flow
      errorBag: [],
      // indicates that the user has accepted conditions
      acceptsConditions: false,
      // model for storing user contact details
      contactInfo: new ContactInfoModel(),
      // indicates a cancelled payment on bank gateway
      hasCancelledBooking: false,
      // indicates disabled booking using global fh_disable_booking property
      newsletterSubscribe: false,
      // this flag becomes true when booking request is submitted, and while waiting for server to respond
      isWaitingForRedirection: false,
      showTermsModal: false,
      showPrivacyModal: false,
      privacyContent: '',
      termsContent: '',
      shouldPatchContactDetails: false,
      showTripDetails: false,
      isGridSingleCol: false,
    };
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
  },
  //----------------------------------------------------------------------------
  created() {
    this.onWindowResize();

    window.addEventListener('resize', () => debouncedShort(this.onWindowResize));

    emitter.$on('onSeatsCheckout', ({ trips, tripRequestsCombinations, selectedSpecialOffers }) => {
      // clear the form errors
      this.clearInputErrors();

      // initialize component for current trips
      this.initialize(createTripsForBooking(trips), tripRequestsCombinations, selectedSpecialOffers);

      // show booking tab
      this.enableΝavTab(NAVIGATION_TABS.BOOK);
      this.changeNavTab(NAVIGATION_TABS.BOOK);
    });

    // Reset the cancelledBooking flag, because a fresh search session has been initialized.
    emitter.$on('onSearchPerform', this.resetCancelledBookingFlag);

    emitter.$on('onValidationErrorShown', ({ name, value, error }) => eventValidationErrorShown(name, value, error));
  },
  //----------------------------------------------------------------------------
  async mounted() {
    if (this.isUserDefined) await this.getUserDetails(this.user.id);

    // Get countries for countries list dropdown in PassengerDetailsForm
    await this.getCountries();

    this.setContactInfoPhoneCountryCode();

    // load terms and conditions during component initialization
    this.getTermsAndConditions();

    // if booking is cancelled, the component should generate itself from store
    if (this.$store.state.navigationModule.bookingCancelled === true) {
      this.initializeSavedPassengers();
      this.initializeSavedVehicles();

      let isStoreCorrect = this.loadAppStateFromStorage();
      if (true === isStoreCorrect) {
        this.enableΝavTab(NAVIGATION_TABS.BOOK);
        this.changeNavTab(NAVIGATION_TABS.BOOK);
      } else {
        this.disableNavTabs([NAVIGATION_TABS.BOOK]);
      }
      this.$store.commit('navigationModule/resetCancelledFlag');
    }
  },
  watch: {
    tripPricingErrors: {
      handler() {
        if (this.tripPricingErrors.length === 0) return;
        const extraOffset = 100;
        scrollToElementId('pricingErrorsWrapper', 'smooth', extraOffset); // This applies only for aegean
      },
      deep: true,
    },
    user: {
      handler(newUser, oldUser) {
        this.setUserContactInfo();

        if (newUser.id === oldUser.id) return;
        this.initializeSavedPassengers();
        this.initializeSavedVehicles();
      },
      deep: true,
    },
    async hasSelectedFailedRefundProtect() {
      if (this.hasSelectedFailedRefundProtect) {
        await nextTick();
        this.addLocalError('refundProtect', this.refundProtectError);
      } else {
        this.removeLocalError('refundProtect');
      }
    },
    async hasSelectedFailedTravelInsurance() {
      if (this.hasSelectedFailedTravelInsurance) {
        await nextTick();
        this.addLocalError('travelInsurance', this.travelInsuranceError);
      } else {
        this.removeLocalError('travelInsurance');
      }
    },
    hasInputErrors(newVal) {
      if (!newVal) this.removeLocalError('detailsMissing');
    },
    acceptsConditions(newVal) {
      if (newVal) this.removeLocalError('termsMissing');
    },
    hasSelectedFailedCoupon(newVal) {
      if (!newVal) this.removeLocalError('invalidCoupon');
    },
    hasSelectedFailedSupportPlus(newVal) {
      if (!newVal) this.removeLocalError('supportPlus');
    },
    hasSelectedFailedFlexi(newVal) {
      if (!newVal) this.removeLocalError('flexi');
    },
  },
  computed: {
    ...mapState({
      trips: (state) => state.bookingModule.trips,
      tripRequestsCombinations: (state) => state.bookingModule.tripRequestsCombinations,
      selectedSpecialOffers: (state) => state.bookingModule.selectedSpecialOffers,
      fetchingForPrices: (state) => state.uiModule.fetchingForPrices,
      refundProtectError: (state) => state.bookingModule.refundProtect.error,
      travelInsuranceError: (state) => state.bookingModule.travelInsurance.error,
      supportPlusError: (state) => state.bookingModule.supportPlus.error,
      flexiError: (state) => state.bookingModule.flexi.error,
      invoiceDetailsEnabled: (state) => state.bookingModule.invoiceDetailsEnabled,
      serviceFee: (state) => state.bookingModule.serviceFee,
      ticketDeliveryAmount: (state) => state.bookingModule.ticketDeliveryAmount,
      invoiceDetails: (state) => state.invoiceModule.invoiceDetails,
      isPetsBookingActivated: (state) => state.searchModule.isPetsBookingActivated,
      isSessionExpired: (state) => state.userModule.isSessionExpired,
      user: (state) => state.userModule.user,
      errors: (state) => state.validationModule.errors,
    }),
    ...mapGetters({
      hasUnpricedTrips: 'bookingModule/hasUnpricedTrips',
      tripPricingErrors: 'bookingModule/tripPricingErrors',
      hasTripPricingErrors: 'bookingModule/hasTripPricingErrors',
      getRefundProtectDetails: 'bookingModule/getRefundProtectDetails',
      getTravelInsuranceDetails: 'bookingModule/getTravelInsuranceDetails',
      getSupportPlusDetails: 'bookingModule/getSupportPlusDetails',
      getFlexiDetails: 'bookingModule/getFlexiDetails',
      expectedFinalPrice: 'bookingModule/expectedFinalPrice',
      expectedPriceBeforeDiscount: 'bookingModule/expectedPriceBeforeDiscount',
      overallPriceForTrips: 'bookingModule/overallPriceForTrips',
      couponsForCheckout: 'bookingModule/couponsForCheckout',
      isRefundProtectActivated: 'bookingModule/isRefundProtectActivated',
      isTravelInsuranceActivated: 'bookingModule/isTravelInsuranceActivated',
      isSupportPlusActivated: 'bookingModule/isSupportPlusActivated',
      isFlexiActivated: 'bookingModule/isFlexiActivated',
      hasSelectedFailedCoupon: 'bookingModule/hasSelectedFailedCoupon',
      hasSelectedFailedRefundProtect: 'bookingModule/hasSelectedFailedRefundProtect',
      hasSelectedFailedTravelInsurance: 'bookingModule/hasSelectedFailedTravelInsurance',
      hasSelectedFailedSupportPlus: 'bookingModule/hasSelectedFailedSupportPlus',
      hasSelectedFailedFlexi: 'bookingModule/hasSelectedFailedFlexi',
      hasSelectedFlexi: 'bookingModule/flexiSelected',
      getInvoiceDetails: 'invoiceModule/getInvoiceDetails',
      bookingCancelledReason: 'navigationModule/bookingCancelledReason',
      countriesWithPhoneCode: 'dataModule/countriesWithPhoneCode',
      isUserDefined: 'userModule/isUserDefined',
      hasFlexiError: 'bookingModule/hasFlexiError',
      selectedPortsAbbr: 'searchModule/selectedPortsAbbr',
    }),
    showTicketCollection() {
      return TripsFilterer.hasGreekDepartureTrip(this.trips);
    },
    passengerDetailsTitle() {
      return this.isPetsBookingActivated ? message('passDet.personalDetails.passengersVehiclesPetsHeading') : message('passDet.personalDetails.passengersVehiclesHeading');
    },
    hasErrorInBag() {
      return this.errorBag.length > 0;
    },
    isFerryhopperVariant() {
      return this.globalCobrandedVariant === 'ferryhopper';
    },
    showRefundOptions() {
      return this.isRefundProtectActivated;
    },
    showTravelInsurance() {
      return this.isTravelInsuranceActivated;
    },
    showSupportPlus() {
      return this.isSupportPlusActivated;
    },
    showFlexi() {
      return this.isFlexiActivated;
    },
    isIslanderCodeDiscountAvailable() {
      return ICCheck(this.trips);
    },
    hasInputErrors() {
      return this.errors.length > 0;
    },
    //--------------------------------------------------------------------------
    reservationLeader() {
      if (!this.passengers) return null;
      return this.passengers.find((p) => p.isLeader);
    },
    //--------------------------------------------------------------------------
    isWaiting() {
      return this.isWaitingForRedirection || this.fetchingForPrices;
    },
    ancillaryComponents() {
      // no ancillaries for cobranded variants
      if (!this.isFerryhopperVariant) {
        return [];
      }
      return getSortedAncillaries()
        .filter(({ extraServiceType }) => {
          if (extraServiceType === EXTRA_SERVICES.REFUND_PROTECT) return this.showRefundOptions;
          if (extraServiceType === EXTRA_SERVICES.TRAVEL_INSURANCE) return this.showTravelInsurance;
          if (extraServiceType === EXTRA_SERVICES.SUPPORT_PLUS) return this.showSupportPlus;
          if (extraServiceType === EXTRA_SERVICES.FLEXI) return this.showFlexi;
          return false;
        })
        .map((a) => a.component);
    },
  },
  methods: {
    trans,
    ...mapActions({
      getCountries: 'dataModule/getCountries',
      setFinalBookingProperties: 'bookingModule/setFinalBookingProperties',
      setTripsForBooking: 'bookingModule/setTripsForBooking',
      updateTripPrices: 'bookingModule/updateTripPrices',
      enableΝavTab: 'navigationModule/enableΝavTab',
      changeNavTab: 'navigationModule/changeNavTab',
      disableNavTabs: 'navigationModule/disableNavTabs',
      setUserContactDetails: 'userModule/setUserContactDetails',
      getUserDetails: 'userModule/getUserDetails',
      clearErrors: 'validationModule/clearErrors',
    }),
    handlePassengersFormInput(uid, key, value) {
      this.passengers = this.passengers.map((p) => (p.uid === uid ? updateObjectWithProp(p, key, value) : p));
    },
    handlePetsFormInput(index, key, value) {
      this.pets = this.pets.map((p) => (p.petIndex === index ? updateObjectWithProp(p, key, value) : p));
    },
    handleVehiclesFormInput(uid, key, value) {
      this.vehicles = this.vehicles.map((v) => (v.uid === uid ? updateObjectWithProp(v, key, value) : v));
    },
    onWindowResize() {
      if (window.innerWidth < 992) this.isGridSingleCol = true;
      else this.isGridSingleCol = false;
    },
    shouldUpdateContactDetails(value) {
      this.shouldPatchContactDetails = value;
    },
    setUserContactInfo() {
      if (!this.isUserDefined) return;
      const { email, phoneCode, phone } = this.user.contactDetails;
      const newEmail = this.contactInfo.Email ? this.contactInfo.Email : email;

      let newPhoneCountryCode;
      let newPhone;

      // rules for updating phone country code and phone with user data:
      // - If phone (on the contact form) is empty, fill phone and country code from user account data
      // - If phone is not empty, ignore fetched phone and country code
      if (!this.contactInfo.Phone) {
        newPhoneCountryCode = phoneCode || this.contactInfo.PhoneCountryCode;
        newPhone = phone || this.contactInfo.Phone;
      }

      if (this.contactInfo.Phone) {
        newPhoneCountryCode = this.contactInfo.PhoneCountryCode || undefined;
        newPhone = this.contactInfo.Phone;
      }

      this.contactInfo = reconcileContactInfo({ ...this.contactInfo, email: newEmail, PhoneCountryCode: newPhoneCountryCode, Phone: newPhone });
    },
    setContactInfoPhoneCountryCode() {
      let countryFromLocale = IsoFromLocale(getPageLanguage());
      let targetCountry = this.countriesWithPhoneCode.find((country) => country.code === countryFromLocale);
      if (!isNull(targetCountry)) this.contactInfo.setPhoneCountryCode(targetCountry.phoneCode);
    },
    addLocalError(code, message) {
      this.errorBag = addErrorInBag(this.errorBag, createError(code, message));
    },
    addRemoteError(code, message) {
      this.errorBag = addErrorInBag(this.errorBag, createError(code, message, ERROR_TYPES.REMOTE));
    },
    removeLocalError(code) {
      this.errorBag = removeErrorFromBag(this.errorBag, code);
    },
    /** Resets `this.hasCancelledBooking` to false to hide the payment failure message. */
    resetCancelledBookingFlag() {
      this.hasCancelledBooking = false;
    },
    getTermsAndConditions: function () {
      getBookingTerms((res) => {
        if (res) {
          this.termsContent = res.terms;
          this.privacyContent = res.privacy;
        }
      });
    },
    onBackClicked: function () {
      if (this.hasCancelledBooking === true) {
        this.changeNavTab(NAVIGATION_TABS.SEARCH);
      } else {
        emitter.$emit('onBookingTabBackClick');
        this.changeNavTab(NAVIGATION_TABS.PASSENGERS);
      }
      this.resetCancelledBookingFlag();
      this.disableNavTabs([NAVIGATION_TABS.BOOK]);
      eventClickedOnBackButton();
    },
    /**
     * Persists the App's state by serializing it and saving it in local storage.
     * This state dehydration process removes any state's objects methods.
     * During state rehydration, pass the deserialized state through a reconciler that merges it into the App's state
     * and restores methods to its nested objects, to make sure that the state matches the behavior expected by the App for it to work.
     *
     * Performed, when a user proceeds to payment, before being redirected to the bank,
     * so that they can return to the App at the book phase on an unsuccessful payment.
     */
    saveAppStateInStorage: function () {
      saveBookingRequest({
        passengers: this.passengers,
        savedPassengers: this.savedPassengers,
        savedVehicles: this.savedVehicles,
        vehicles: this.vehicles,
        pets: this.pets,
        acceptsConditions: this.acceptsConditions,
        contactInfo: this.contactInfo,
        newsletterSubscribe: this.newsletterSubscribe,
      });
    },
    /**
     * Rehydrates the app with persisted state obtained from storage. The deserialized state
     * is passed through a reconciler that merges it into the App's state
     * and restores methods to its nested objects, to make sure that the state matches the behavior expected by the App for it to work.
     *
     * Runs when returning from the bank after an unsuccessful payment.
     */
    loadAppStateFromStorage() {
      // HardSet state reconciliation strategy
      try {
        // Obtain the persisted serialized state from storage.
        let savedBookingRequest = getSavedBookingRequest();

        if (isNull(savedBookingRequest)) {
          throw new Error('Unable to find saved booking request in local storage');
        }
        this.hasCancelledBooking = true;
        this.passengers = savedBookingRequest.passengers.map((passenger) => reconcilePassenger(passenger));
        this.savedPassengers = savedBookingRequest.savedPassengers;
        this.savedVehicles = savedBookingRequest.savedVehicles;
        this.vehicles = savedBookingRequest.vehicles.map((vehicle) => reconcileVehicle(vehicle));
        this.pets = savedBookingRequest.pets.map((pet) => reconcilePet(pet));
        this.contactInfo = reconcileContactInfo(savedBookingRequest.contactInfo);
        this.acceptsConditions = savedBookingRequest.acceptsConditions;
        this.newsletterSubscribe = savedBookingRequest.newsletterSubscribe;

        clearSavedBookingRequest();

        // show payment failure error
        this.hasCancelledBooking = true;
        this.addRemoteError('paymentFailed', this.bookingCancelledReason);

        // scroll to checkout button in order for the user to see the error
        nextTick(function () {
          scrollToElementId('booking-prices-breakdown');
        });

        return true;
      } catch (exception) {
        logException('BookingApp:SavedBookingRequest', exception);
        return false;
      }
    },
    //--------------------------------------------------------------------------
    initialize(trips, tripRequestsCombinations, selectedSpecialOffers) {
      this.setUserContactInfo();
      this.initializeSavedPassengers();
      this.initializeSavedVehicles();

      // Calculate initial trip prices in Vuex store. This will ensure that trip prices
      // will be visible even if the repricing call that follows takes some time to finish
      this.setFinalBookingProperties({ tripRequestsCombinations, selectedSpecialOffers });
      this.setTripsForBooking(trips);

      this.passengers = createPassengersDetailsForBooking(trips);
      this.vehicles = createVehiclesDetailsForBooking(trips);
      this.pets = createPetsDetailsForBooking(trips);
    },
    clearInputErrors() {
      this.clearErrors();
      this.errorBag = [];
    },
    clearRemoteErrors() {
      this.errorBag = this.errorBag.filter((e) => e.type !== ERROR_TYPES.REMOTE);
    },
    async onPriceAffectingChange() {
      await this.updateTripPrices({
        passengersDetails: this.passengers,
        vehiclesDetails: this.vehicles,
        petsDetails: this.pets,
        selectedSpecialOffers: this.selectedSpecialOffers,
      });
    },
    async finalCheckout() {
      emitter.$emit('validate');

      this.clearRemoteErrors();
      // Important! Wait until Vue flushes the next update queue in the next event loop tick,
      // so that components (including this component) receive the updated errorbag.
      // If this isn't done, the assertion below fails, although the validator might have found errors.
      await nextTick();

      // function proceed() {
      // CATCH BLOCK
      // if errors are detected, do nothing (error notifications are shown automatically)
      if (this.hasInputErrors) {
        // If errors exist, scroll to the first one. Add an offset (which is the navigation tabs height + 100 pixels)
        // so it stops below the navigation tabs.
        const navigationTabs = document.getElementById('navigationTabs');
        if (navigationTabs) {
          const navigationTabsHeight = navigationTabs.offsetHeight;
          const extraOffset = 100;
          // Sort the errors based on the appearance order of their respective input fields on the DOM
          let sortedErrors = _sortBy(this.errors, (error) => error.name === 'reservationEmail' || error.name === 'countryCode' || error.name === 'reservationPhone' || error.name.includes('invoice'));

          const firstErrorId = sortedErrors[0].name; // Each input has the same id as its vname property

          scrollToElementId(firstErrorId, 'smooth', navigationTabsHeight + extraOffset);
        } else {
          scrollToElementId('passengers-details-fields', 'smooth');
        }

        this.addLocalError('detailsMissing');

        try {
          eventWrongBookingDetails(this.errors[0].name, this.errors[0].message);
        } catch (exception) {
          eventWrongBookingDetails('booking details', exception);
        }
      } else {
        this.removeLocalError('detailsMissing');
      }

      if (this.hasUnpricedTrips) {
        this.addLocalError('nonPricedTrips');
        eventCheckoutPricingFailed();
      } else {
        this.removeLocalError('nonPricedTrips');
      }

      if (this.hasSelectedFailedCoupon) {
        eventCheckoutCouponFailed();
        this.addLocalError('invalidCoupon');
      } else {
        this.removeLocalError('invalidCoupon');
      }

      if (this.hasSelectedFailedRefundProtect) {
        this.addLocalError('refundProtect', this.refundProtectError);
      } else {
        this.removeLocalError('refundProtect');
      }

      if (this.hasSelectedFailedTravelInsurance) {
        this.addLocalError('travelInsurance', this.travelInsuranceError);
      } else {
        this.removeLocalError('travelInsurance');
      }

      if (this.hasSelectedFailedSupportPlus) {
        this.addLocalError('supportPlus', this.supportPlusError);
      } else {
        this.removeLocalError('supportPlus');
      }

      if (this.hasSelectedFailedFlexi) {
        this.addLocalError('flexi', this.flexiError);
      } else {
        this.removeLocalError('flexi');
      }

      // validate for acceptance of terms
      if (this.acceptsConditions !== true) {
        this.addLocalError('termsMissing');
        eventWrongBookingDetails('Terms & Policy', '');
      } else {
        this.removeLocalError('termsMissing');
      }

      if (this.errorBag.length > 0) {
        return;
      }

      this.isWaitingForRedirection = true;

      // If a user exists, make sure JWT tokens are valid by performing a get request to the user data service
      if (this.isUserDefined) await this.getUserDetails(this.user.id);

      if (this.isSessionExpired) return;
      if (this.isUserDefined) {
        const passengerPromises = this.passengers.map(async (p) => await this.patchSavedPassenger(p));
        const vehiclePromises = this.vehicles.map(async (v) => await this.patchSavedVehicle(v));
        await Promise.all(passengerPromises.concat(vehiclePromises));

        // after saved passengers are stored properly using the user API, update local saved passengers
        // to reflect these changes
        this.updateSavedPassengers();
        this.updateSavedVehicles();
      }

      if (this.shouldPatchContactDetails) {
        const { name, surname } = this.user.contactDetails;
        const { Email, PhoneCountryCode, Phone } = this.contactInfo;
        const payload = { name, surname, email: Email, phoneCode: PhoneCountryCode, phone: Phone };
        // If the following request succeeds, user details are updated. Errors are not handled.
        await this.setUserContactDetails(payload);
      }

      // Check if the discount is selected to avoid unnecessary work.
      if (this.isIslanderCodeDiscountAvailable) {
        try {
          // If an IC isn't valid and has surpassed veevalidate, (e.g. the value was entered programmatically),
          // stop payment, submit and return early if something is invalid.
          // This performs a final validation step for islander code values that are not empty, not valid and not blocked by vv.

          // get the first trip that supports resident discounts, if any
          let islanderCodeTrip = TripsFilterer.getIslanderCodeTrip(this.trips);
          // await for all pending validations to be resolved.
          const validations = await submitNonValidIC(this.passengers, islanderCodeTrip);

          // If errors: 1. stop the spinner, 2. scroll into view
          if (validations.length > 0 && validations.find((v) => v.valid === false)) {
            this.isWaitingForRedirection = false;
            scrollToElementId('passengers-details-fields', 'smooth');
            return;
          }
        } catch (error) {
          this.isWaitingForRedirection = false;
          return;
        }
      }

      try {
        // enrich trips with traveling entities details
        const tripsWithDetails = mergeTripsWithTravelerDetails(this.trips, {
          passengersDetails: this.passengers,
          vehiclesDetails: this.vehicles,
          petsDetails: this.pets,
        });

        // build the actual requests that will be send to the API for booking
        const { combinedIssueRequests, tripDescriptions } = createCombinedIssueRequests(tripsWithDetails, this.tripRequestsCombinations, this.contactInfo);

        const bookingRequestPayload = createBookingRequest(
          this.getRefundProtectDetails,
          this.getTravelInsuranceDetails,
          this.getSupportPlusDetails,
          this.getFlexiDetails,
          this.expectedFinalPrice,
          this.expectedPriceBeforeDiscount,
          combinedIssueRequests,
          tripDescriptions,
          this.contactInfo,
          this.serviceFee,
          this.reservationLeader,
          this.couponsForCheckout,
          this.newsletterSubscribe,
          this.getInvoiceDetails,
          this.user
        );

        try {
          // store current state in order to be able to restore it if bank request fails
          this.saveAppStateInStorage();
        } catch (exception) {
          logException('BookingApp:saveAppStateInStorage', exception);
        }

        // generate the issue request, and return an automatically submitted form
        makeCombinedBookingIssueRequest(bookingRequestPayload)
          .then((rawHtmlResponse) => {
            // append repsonse directly to html
            document.getElementById('checkout-form-container').innerHTML = rawHtmlResponse;

            // send checkout to enhanced ecommerce
            eventCheckoutBookingStep(this.expectedFinalPrice, this.trips, this.couponsForCheckout, this.selectedPortsAbbr);

            // submit payment form for redirection
            document.getElementById('fh_paymentForm').submit();
          })
          .catch((error) => {
            this.isWaitingForRedirection = false;
            if (error.status === BOOKING_RESTRICTION_ERROR_STATUS) {
              this.addRemoteError(BOOKING_RESTRICTION_ERROR_STATUS, error.reason);
              eventCheckoutBookingRestrictionError(error.reason);
              return;
            }

            if (error.status === BOOKING_VALIDATION_ERROR_STATUS) {
              this.addRemoteError(BOOKING_VALIDATION_ERROR_STATUS, error.reason);
              eventCheckoutBookingValidationError(error.reason);
              return;
            }

            this.addRemoteError('remoteGenericError', GenericErrorCodes.get('generic'));
            logException('BookingRequestError', { status: error.status, ...error });
          });
      } catch (exception) {
        logException('BookingApp:createBookingRequest', exception);
        this.isWaitingForRedirection = false;
        this.addRemoteError('remoteGenericError', GenericErrorCodes.get('generic'));
      }
    },
    onNewsletterSubscriptionInput(value) {
      this.newsletterSubscribe = value;
    },
  },
};
</script>
