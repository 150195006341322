<template>
  <div class="booking-flow-box">
    <h4 class="booking-flow-box__header">{{ trans('ticketcollectionheader') }}<InfoModalItem :slug="'collect-ferry-tickets'" :class="'ml8'" @openInfoModalClicked="openInfoModalClicked" /></h4>

    <ETicketDescriptionComponent v-if="hasOnlyEticket" />
    <CollectionSpotsComponent v-else />
  </div>
</template>

<script>
import { trans } from '@/filters';
import { eventBookingModalOpened } from '@/logic/services/events/createBookingEvents';
import ETicketDescriptionComponent from '@/components/book/collection/ETicketDescriptionComponent';
import CollectionSpotsComponent from '@/components/book/collection/CollectionSpotsComponent';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'TicketCollection',
  components: {
    ETicketDescriptionComponent,
    CollectionSpotsComponent,
  },
  computed: {
    ...mapState({
      trips: (state) => state.bookingModule.trips,
    }),
    ...mapGetters({
      marketingCompanies: 'bookingModule/marketingCompanies',
    }),
    hasOnlyEticket() {
      return this.trips.every(trip => trip.Details.eTicket);
    },
  },
  methods: {
    trans,
    openInfoModalClicked() {
      eventBookingModalOpened('collect_ferry_tickets');
    },
  },
};
</script>
