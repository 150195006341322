import { orderBy as _orderBy } from 'lodash-es';
import { isDatesOrderInvalid } from '../../helpers/dateUtils';
import { notNull, uniqueId } from '../../helpers/utils';
import settings from '../../../settings';
import {
  hasMissingPorts,
  getMissingPortsError,
  hasDatesMissing,
  getDatesMissingError,
  getInvalidDateOrderError,
  getConsecutivePort,
  getConsecutivePortError,
  getConnectionDetails,
  getNoConnectionPortError,
  getNoDestinationsPort,
  getNoDestinationsPortError,
  getNoProvidedPort,
  getNoProviderPortError
} from './createSearchQueriesError';
import dayjs from '@/logic/services/date/dateService';

/**
 * The simple structure that should be provided to the ResultsApp components in
 * order to initialize and begin fetching results
 *
 * @param {PortModel} origin Origin location
 * @param {PortModel} destination Destination location
 * @param {dayjs} date A dayjs object that is used to re-initialize it (in order to clone)
 * @param {integer} tripSequence The sequence of this trip, eg. 0, 1, 2, 3
 * @param {string} tripMode Can be 'single' or 'multi'
 */

export const createSearchQuery = (origin, destination, date, tripSequence, tripMode) => {
  let dayJsDate = dayjs(date);

  return {
    id: uniqueId(10),
    origin: origin.LocationAbbr,
    originName: origin.short,
    originLongName: origin.alias,
    destination: destination.LocationAbbr,
    destinationLongName: destination.alias,
    destinationName: destination.short,
    date: dayJsDate.format(settings.formats.dayjsToFCRS),
    dateDayjs: dayJsDate,
    unix: dayJsDate.unix(),
    tripSequence: tripSequence,
    mode: tripMode
  };
};

export const createSuccessResponse = (ports, dates, tripCount, searchMode) => {
  let searchQueries = [];

  // create formatted subrequests
  for (let tripIndex = 0; tripIndex < tripCount; tripIndex++) {
    // push the formated original go request
    searchQueries.push(createSearchQuery(ports[tripIndex], ports[tripIndex + 1], dates[tripIndex], tripIndex, searchMode));
  }

  return {
    searchQueries: _orderBy(searchQueries, 'tripSequence'),
    errors: []
  };
};

export const createSearchQueries = (ports, dates, tripCount, searchMode) => {
  let errors = [];
  // check empty input port fields
  if (hasMissingPorts(ports, tripCount)) {
    const portsMissingError = getMissingPortsError(searchMode);
    errors.push(portsMissingError);
  }

  // check for consecutive ports e.g. Port 1: Piraeus -> Port 2: Piraeus
  const consecutivePort = getConsecutivePort(ports);
  if (notNull(consecutivePort) && consecutivePort.short !== '') {
    errors.push(getConsecutivePortError(consecutivePort));
  }

  // check no connected ports
  const { isConnectionPossible, origin, destination } = getConnectionDetails(ports, tripCount);
  if (notNull(isConnectionPossible) && !isConnectionPossible) {
    errors.push(getNoConnectionPortError(origin, destination));
  }

  // check empty dates fields
  if (hasDatesMissing(dates, tripCount)) {
    const datesMissingError = getDatesMissingError(searchMode);
    errors.push(datesMissingError);
  }

  // check invalid date order
  if (isDatesOrderInvalid(dates)) errors.push(getInvalidDateOrderError(searchMode));

  // check for port with no destinations and no parent port
  const noDestinationsPort = getNoDestinationsPort(ports);
  if (notNull(noDestinationsPort) && noDestinationsPort.short !== '') {
    errors.push(getNoDestinationsPortError(noDestinationsPort));
  }
  //}

  //  check for port with no provider and no parent port
  const noProviderPort = getNoProvidedPort(ports);
  if (notNull(noProviderPort) && noProviderPort.short !== '') {
    errors.push(getNoProviderPortError(noProviderPort));
  }

  if (errors.length > 0) return { errors, searchQueries: [] };

  return createSuccessResponse(ports, dates, tripCount, searchMode);
};
