<template>
  <div class="ticket-selection mb24">
    <label class="ticket-selection__label">
      <span>{{ passDescription(passenger) }} </span>
    </label>

    <div class="ticket-selection__grid">
      <div class="ticket-selection__grid-item">
        <!-- Passenger type select -->
        <FhDropdown
          :value="passenger.type"
          :disabled="isOccupied"
          :optionKeyToTrack="'DiscountCategory'"
          :optionKeyToShow="'DiscountDescription'"
          :options="passengerTypes"
          :id="`passengerTypesDropdown-${tripIndex}-${passengerIndex}`"
          :textSize="'small'"
          @input="changePassengerType"
        >
          <template #options="{ select }">
            <FhDropdownOption v-for="(type, typeIndex) in passengerTypes" :key="`types-dropdown-${typeIndex}`" :text="type.DiscountDescription" :index="typeIndex" @click="select(type, typeIndex)" />
          </template>
        </FhDropdown>
      </div>

      <!-- Passenger seat select -->
      <PassengerSeatInput
        class="ticket-selection__grid-item"
        :disabled="isOccupied || isInfant || isSeatSelectionDisabled"
        :seatDropdownOptions="seatDropdownOptions"
        :passenger="passenger"
        :id="`seatTypeDropdown-${tripIndex}-${passengerIndex}`"
        @changePassengerSeat="changePassengerSeat"
      />
    </div>

    <div class="ticket-selection__warnings">
      <div class="mt4" v-if="passenger.isInfant"><i class="fh fh-notification"></i> {{ trans('ticketSelection.infantSeatCabinMessage') }}</div>
      <div class="mt4" v-else-if="passenger.companySpecificDiscount.needsDocument"><i class="fh fh-notification"></i> {{ trans('needstraveldocument') }}</div>
      <div class="mt4" v-if="passenger.seatSelectionMessage !== ''" :data-test="'seatSelectionMessage'">
        <i class="fh fh-notification"></i> {{ trans('ticketSelection.sameCabinToPassengers') }} {{ passenger.seatSelectionMessage }}
      </div>
    </div>

    <div v-if="showExtraDiscounts" class="pt12">
      <PassengerOffersComponent :key="passenger.uid" :disable="isOccupied" :trip="trip" :passenger="passenger" @onLoyaltyChange="onLoyaltyChange" @onResidenceDiscountChange="onResidenceDiscountChange" />
    </div>
  </div>
</template>

<script>
import PassengerOffersComponent from '@/components/seats/PassengerOffersComponent';
import { isNotInfantType, getAdultDiscounts } from '@/logic/filterers/DiscountsFilterer';
import { isInfantPassenger } from '@/logic/filterers/PassengersFilterer';
import { orderBy as _orderBy } from 'lodash-es';
import FhDropdownSeatOption from '@/components/shared/FhDropdown/FhDropdownSeatOption';
import PassengerSeatInput from '@/components/shared/inputs/PassengerSeatInput';
import { trans, passDescription } from '@/filters';
import { mapActions } from 'vuex';
import { isCabinForMany } from '@/logic/filterers/SeatOptionsFilterer';

export default {
  name: 'PassengerSeatingComponent',
  components: {
    PassengerOffersComponent,
    FhDropdownSeatOption,
    PassengerSeatInput,
  },
  props: ['trip', 'tripIndex', 'passengerIndex', 'passenger', 'isOccupied'],
  emits: ['onLoyaltyChange', 'onResidenceDiscountChange'],
  methods: {
    ...mapActions({
      resetSeatSelectionMessage: 'ticketConfigurationModule/resetSeatSelectionMessage',
      applyCabinToPassengers: 'ticketConfigurationModule/applyCabinToPassengers',
      changePassengerAccommodation: 'ticketConfigurationModule/changePassengerAccommodation',
      changePassengerTypeInStore: 'ticketConfigurationModule/changePassengerTypeInStore',
    }),
    trans,
    passDescription,
    changePassengerType(type) {
      this.changePassengerTypeInStore({ discountCategory: type.DiscountCategory, passenger: this.passenger, changedTrip: this.trip });
    },
    changePassengerSeat(seat) {
      this.resetSeatSelectionMessage();

      if (isCabinForMany(seat)) {
        this.applyCabinToPassengers({ selectedSeatOption: seat, passenger: this.passenger, changedTrip: this.trip });
        return;
      }

      this.changePassengerAccommodation({ accommodation: seat, passenger: this.passenger, changedTrip: this.trip });
    },
    onLoyaltyChange(loyalty) {
      this.$emit('onLoyaltyChange', loyalty, this.passenger, this.trip);
    },
    onResidenceDiscountChange(discount) {
      this.$emit('onResidenceDiscountChange', discount, this.passenger, this.trip);
    },
  },
  computed: {
    isNotInfant() {
      return isNotInfantType(this.passenger.type);
    },
    isInfant() {
      return isInfantPassenger(this.passenger);
    },
    isSeatSelectionDisabled() {
      return this.trip.extraAttributes.wantsCampingOnBoard;
    },
    showExtraDiscounts() {
      return this.isNotInfant && (this.trip.loyaltyScheme.supportsLoyalty || this.trip.residenceDiscount.supportsResidenceDiscount);
    },
    passengerTypes() {
      let passengerTypes = this.passenger.isLeader ? getAdultDiscounts(this.trip.passengerDiscounts) : this.trip.passengerDiscounts;
      return _orderBy(passengerTypes, (d) => parseInt(d.DiscountOrder));
    },
    seatDropdownOptions() {
      if (this.isInfant) return this.trip.infantOptions;
      return this.trip.nonInfantOptions;
    },
  },
};
</script>
