<template>
  <h3 class="aegean-booking-flow__tab-header">{{renderedTitle}}</h3>
</template>

<script>
export default {
  name: 'AegeanBookingFlowSectionHeader',
  props: {
    'title': String,
    'order': String
  },
  computed: {
    renderedTitle : function () {
      return this.isMobile ? this.title : `${this.order}. ${this.title}`;
    }
  }
}
</script>
