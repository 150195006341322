/**
 * The TripAnalysisModel is responsible for creating information regarding the
 * exact route of a DirectTripModel, including stops and times
*/
import { each as _each, filter as _filter, minBy as _minBy } from 'lodash-es'
import dayjs from '@/logic/services/date/dateService';
import settings from '@/settings'
import { isNull } from '@/logic/helpers/utils'
import PortsRepository from '@/logic/repositories/PortsRepository'
import { getShortDateFormat, getTripTimeFormat } from '../../helpers/dateUtils'

function TripAnalysisModel(data, details, timings) {

  this.analysis = [];
  try {
    // create meaningful information from the input route data
    this.createStopovers(data);
    // find index of origin and destination in the input route data
    this.findOriginAndDestination(data, details, timings);
    // populate the route analysis
    this.analysis = this.createRouteAnalysis(data, timings);
  } catch (exception) {
    // if something fails, create a fake itinerary analysis that will only
    // contain origin and destination, using the known time details
    this.analysis = this.createMockAnalysis(details, timings);    
  }
  return this.analysis;
}


TripAnalysisModel.prototype = {
  //--------------------------------------------------------------------------
	// convert strings to dayjs objects for each stop, and store index as
	// well as unix str
  createStopovers: function(data) {
    _each(data.route, (stopover, index) => {
      stopover.datetime = dayjs(stopover.datetime, settings.formats.microApiDateTimeFormat);
      stopover.index = index;
      stopover.unix = Number(stopover.datetime.unix());
    });
  },
  //--------------------------------------------------------------------------
	// since origin and arrival locations might exist multiple times in
	// an itinerary (ex some circlular trips), we can identify which one is
	// the actual origin and actual arrival stop, based on proximity to the
	// departure and arrival datetimes of the itinerary
  findOriginAndDestination: function(data, details, timings) {

    // find all occurences of departure and arrival locations in the raw
    // itinerary analysis
    let possibleOrigins = _filter(data.route, {'abbr' : details.DepStation });
    let possibleDestinations = _filter(data.route, {'abbr' : details.ArrStation });

    // departure in unix has already been calculated
    let depUnix = timings.unix;
    // we also need the arrival in unix
    let arrUnix = Number(timings.ArrivalDateTime.unix());

    // find departure location object that is closer to departure unix
		let departureStop = _minBy(possibleOrigins, function(stopover) {
			return Math.abs(stopover.unix - depUnix);
		});

    // find arrival location object that is closer to departure unix
		let arrivalStop = _minBy(possibleDestinations, function(stopover) {
			return Math.abs(stopover.unix - arrUnix);
		});

    // check that both origin and destination actually exist in analysis, to
    // catch possible errors on the input data
    if ((isNull(departureStop)) || (isNull(arrivalStop))) {
      throw 'Incomplete itinerary analysis, the main location objects are missing';
    }

    // save departure & arrival index (position in route array)
    this.departureStopIndex = departureStop.index;
    this.arrivalStopIndex = arrivalStop.index;
  },
  //--------------------------------------------------------------------------
  // The magic happens here. Create the route analysis, and identify the relative
  // position of each stopover (if it is before, between or after the segment)
  createRouteAnalysis: function(data, timings) {

    let analysis = [];
    let before = [];
    let between = [];
    let after = [];

    _each(data.route, (stopover, stopoverIndex) => {

      // get location object by abbreviation
      let location = PortsRepository.getPort(stopover.abbr);

      let isValidDatetime = stopover.datetime.isValid(stopover.datetime);

      // add to analysis array
      let analysisItem = {
        index: stopoverIndex,
        Port: location && location.short || stopover.abbr,
        DepartureDateTime: stopover.datetime,
        DepartureDateStr: isValidDatetime ? stopover.datetime.format(getShortDateFormat()) : '',
        DepartureTimeStr: isValidDatetime ? stopover.datetime.format(getTripTimeFormat()) : '',
        Abbr: stopover.abbr
      };

      // identify position of location with respect to origin & destination
      if (stopoverIndex < this.departureStopIndex) {
        before.push(analysisItem.Abbr);
        analysisItem.position = 'before-origin';
      } else if (stopoverIndex > this.arrivalStopIndex) {
				after.push(analysisItem.Abbr);
				analysisItem.position = 'after-destination';
			} else if (stopoverIndex === this.departureStopIndex) {
				analysisItem.position = 'origin';
        analysisItem.DepartureDateTime = timings.DepartureDateTime;
        analysisItem.DepartureDateStr = timings.DepDateStr;
        analysisItem.DepartureTimeStr = timings.DepTime;
			} else if (stopoverIndex === this.arrivalStopIndex)  {
				analysisItem.position = 'destination';
        analysisItem.DepartureDateTime = timings.ArrivalDateTime;
        analysisItem.DepartureDateStr = timings.ArrDateStr;
        analysisItem.DepartureTimeStr = timings.ArrTime;
			} else {
				between.push(analysisItem.Abbr);
				analysisItem.position = 'between';
			}

      analysis.push(analysisItem);

    });

    return {
			route: analysis,
			before: before,
			between: between,
			after: after
		};
  },
	//--------------------------------------------------------------------------
	// In case something goes wrong while populating the itinerary analysis, we
	// use this function as a fallback and construct a fake analysis, which only
	// includes origin and destination, using the known itinerary times
	createMockAnalysis: function(details, timings) {

		var analysis = [];

		var originPort = PortsRepository.getPort(details.DepStation);
		analysis.push({
			Port: originPort && originPort.short || details.DepStation,
			DepartureDateTime: timings.DepartureDateTime,
			DepartureDateStr: timings.DepartureDateTime.format(getShortDateFormat()),
			DepartureTimeStr: timings.DepartureDateTime.format(getTripTimeFormat()),
			position: 'origin',
			Abbr: details.DepStation
		});

		var destinationPort = PortsRepository.getPort(details.ArrStation);
		analysis.push({
				Port: destinationPort && destinationPort.short || details.ArrStation,
				DepartureDateTime: timings.ArrivalDateTime,
				DepartureDateStr: timings.ArrivalDateTime.format(getShortDateFormat()),
				DepartureTimeStr: timings.ArrivalDateTime.format(getTripTimeFormat()),
				position: 'destination',
				Abbr: details.ArrStation
			});

    return {
			route: analysis,
			before: [],
			between: [],
			after: []
		};
	},
};

export default TripAnalysisModel;
