<template>
  <div class="mb16">
    <div>
      <span class="ticket-collection__method-title">{{ title }}: </span>
      <span>{{ description }}</span>
    </div>
    <div>
      <span v-if="showAddress"><i class="fh fh-marker"></i> {{ address }}</span>
      <span v-if="showAddress && showPhone">, </span>
      <span v-if="showPhone"><i class="fh fh-phone"></i> {{ phone }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CollectionSpotItem',
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String
    },
    address: {
      type: String
    },
    phone: {
      type: String
    }
  },
  computed: {
    showAddress() {
      return !!this.address;
    },
    showPhone() {
      return !!this.phone;
    }
  }
};
</script>
