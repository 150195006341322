<template>
  <input class="form-control price" ref="imask" type="tel" :value="displayValue" @blur="onBlur" @focus="onFocus" @input="onInput" @paste.prevent />
</template>

<script>
import { nextTick } from 'vue';
import { DATE_FORMAT } from '@/logic/helpers/dateUtils';

// imports only factory
import IMask from 'imask/esm/imask';
import 'imask/esm/masked/regexp';
import { message } from '@/logic/helpers/utils';
import { getStringCharAtIndex } from '../../../logic/helpers/stringUtils';

const maskProps = {
  mask: DATE_FORMAT,
  lazy: false,
  blocks: {
    YYYY: {
      mask: '0000',
      placeholderChar: getStringCharAtIndex(message('generic.dateFormat'), 6, 'Y'),
    },
    MM: {
      mask: '00',
      placeholderChar: getStringCharAtIndex(message('generic.dateFormat'), 3, 'M'),
    },
    DD: {
      mask: '00',
      placeholderChar: getStringCharAtIndex(message('generic.dateFormat'), 0, 'D'),
    },
  },
};
var masked = IMask.createMask(maskProps);

export default {
  name: 'MaskedDateInput',
  props: {
    value: String,
    initialPlaceholder: String,
  },
  data() {
    return {
      isInputActive: false,
    };
  },
  emits: ['input', 'focus', 'blur'],
  mounted() {
    IMask(this.$refs.imask, maskProps);
  },
  methods: {
    async onInput($event) {
      await nextTick();
      this.$emit('input', masked.resolve($event.target.value));
    },
    onFocus() {
      this.isInputActive = true;
      this.$emit('focus');
    },
    onBlur() {
      this.isInputActive = false;
      this.$emit('blur');
    },
  },
  computed: {
    placeholder() {
      return this.isInputActive ? 'generic.dateFormat' : this.initialPlaceholder;
    },
    displayValue() {
      return this.value;
    },
  },
};
</script>
