<template>
  <div :class="$style.suggestion">
    <!-- SUGGESTED DATES -->
    <div v-if="showAlternateDates" :class="$style['suggestion__section']">
      <div :class="$style['suggestion__section-text']">{{ trans('suggestedAltDates') }}:</div>
      <div :class="$style['suggestion__section-alt-dates']">
        <button :class="['btn btn-link btn-md', { pr4: hasAltBeforeAndAfterActiveDate }]" v-if="hasAltBeforeActiveDate" @click="updateDateSearch(closestAltBeforeActiveDateObj)">
          {{ closestAltBeforeActiveDate }}
        </button>
        <span v-if="hasAltBeforeAndAfterActiveDate" :class="$style['suggestion__section-alt-dates--separator']"> {{ trans('generic.or') }} </span>
        <button :class="['btn btn-link btn-md', { pl4: hasAltBeforeAndAfterActiveDate }]" v-if="hasAltAfterActiveDate" @click="updateDateSearch(closestAltAfterActiveDateObj)">
          {{ closestAltAfterActiveDate }}
        </button>
      </div>
    </div>
    <!-- SUGGESTED PORTS -->
    <div v-if="showAlternatePorts" :class="$style['suggestion__section']">
      <div :class="$style['suggestion__section-text']" v-if="!showAlternateDates">{{ trans('suggestedSuperportsNoAltDates') }}:</div>
      <div :class="$style['suggestion__section-text']" v-if="showAlternateDates">{{ trans('suggestedSuperportsWithAltDates') }}:</div>
      <button :class="[$style['suggestion__section-alt-ports--link'], 'btn btn-link btn-md']" @click="updatePortsSearch()">
        <div>{{ portFullName(parentOrigin) }}</div>
        &nbsp;
        <div><i class="fh fh-arrow journey-arrow"></i> {{ portFullName(parentDestination) }}</div>
      </button>
    </div>
  </div>
</template>
<script>
import { isNull } from '@/logic/helpers/utils';
import { parentPort } from './resultsAppHelpers';
import { SEARCH_STATUS } from '@/logic/services/results/resultsService';
import settings from '@/settings';
import { trans, portFullName } from '@/filters';

export default {
  name: 'ResultsAppSuggestion',
  props: {
    origin: String,
    destination: String,
    hasDirectResults: Boolean,
    isLoadingDirect: Boolean,
    isChildPortSearch: Boolean,
    closestAlternateDates: Array,
    hasAlternateDates: Boolean,
    directTripsSearchStatus: String
  },
  emits: ['suggestionClicked'],
  methods: {
    trans,
    portFullName,
    updatePortsSearch(date) {
      this.$emit('suggestionClicked', this.parentOrigin, this.parentDestination, date);
    },
    updateDateSearch(date) {
      this.$emit('suggestionClicked', this.origin, this.destination, date);
    }
  },
  computed: {
    hasError() {
      return this.directTripsSearchStatus === SEARCH_STATUS.ERROR;
    },
    parentOrigin() {
      return parentPort(this.origin);
    },
    parentDestination() {
      return parentPort(this.destination);
    },
    showAlternatePorts() {
      return !this.hasDirectResults && !this.isLoadingDirect && this.isChildPortSearch && !this.hasError;
    },
    showAlternateDates() {
      return this.hasAlternateDates && !this.hasDirectResults && !this.isLoadingDirect && !this.hasError;
    },
    hasAltBeforeActiveDate() {
      return !isNull(this.closestAltBeforeActiveDate);
    },
    hasAltAfterActiveDate() {
      return !isNull(this.closestAltAfterActiveDate);
    },
    hasAltBeforeAndAfterActiveDate() {
      return this.hasAltBeforeActiveDate && this.hasAltAfterActiveDate;
    },
    closestAltBeforeActiveDate() {
      return !isNull(this.closestAlternateDates[0]) ? this.$dayjs(this.closestAlternateDates[0]).format(settings.formats.localizedDateFormat) : null;
    },
    closestAltAfterActiveDate() {
      return !isNull(this.closestAlternateDates[1]) ? this.$dayjs(this.closestAlternateDates[1]).format(settings.formats.localizedDateFormat) : null;
    },
    closestAltBeforeActiveDateObj() {
      return this.closestAlternateDates[0];
    },
    closestAltAfterActiveDateObj() {
      return this.closestAlternateDates[1];
    }
  }
};
</script>

<style module lang="scss">
@import '../../assets/sass/results-tab/_results-app-suggestion.scss';
</style>
