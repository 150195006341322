import validateIC from './validateIC';
import { eventIslanderCodeValidated, eventIslanderCodeValidationFailed } from '@/logic/services/events/createBookingEvents';
import GenericErrorCodes from '../../../services/GenericErrorCodes';
import { PASSENGER_OFFER_STATUS } from '../../../BL/common/passengerOffers/passengerOffer';
import emitter from '@/emitter';

export async function submitValidationRequest({ passenger, trip, passengerUid }) {
  if (!passenger || !trip) return;

  try {
    // The response is returned as an array of validation results.
    const [response] = await validateIC(passenger, trip);
    emitter.$emit('islanderDetailsChange', { passengerUid, key: 'islanderCodeMessage', value: response.message });

    if (response.status === true) {
      emitter.$emit('islanderDetailsChange', { passengerUid, key: 'islanderCodeStatus', value: PASSENGER_OFFER_STATUS.VALID });
      emitter.$emit('islanderDetailsChange', { passengerUid, key: 'firstName', value: response.firstName });
      emitter.$emit('islanderDetailsChange', { passengerUid, key: 'lastName', value: response.lastName });
    }

    if (response.status === false) {
      emitter.$emit('islanderDetailsChange', { passengerUid, key: 'islanderCodeStatus', value: PASSENGER_OFFER_STATUS.INVALID });
    }
    eventIslanderCodeValidated(response, passenger.islanderCode);

    return { valid: response.status, message: response.message };
  } catch (error) {
    // TODO: Instead of generic, we should show a proper message to explain and guide user into trying again
    emitter.$emit('islanderDetailsChange', { passengerUid, key: 'islanderCodeMessage', value: GenericErrorCodes.get('generic') });
    emitter.$emit('islanderDetailsChange', { passengerUid, key: 'islanderCodeStatus', value: PASSENGER_OFFER_STATUS.INVALID });

    eventIslanderCodeValidationFailed(GenericErrorCodes.get('generic'), passenger.islanderCode);
  }
}

const getAllNonValidIC = (passengers) => {
  return passengers.filter((p) => {
    return p.islanderCodeEnabled && p.islanderCodeStatus !== PASSENGER_OFFER_STATUS.VALID;
  });
};
/**
 * Creates a submition request for each non-valid non-empty non-vv-violating islander code.
 * @param { object[] } passengers
 * @param {object} trip
 * @returns {Promise<any[]>} A single Promise that settles when each of pending validations is settled, resolving in an array of validation responses.
 */
export function submitAllNonValid(passengers, trip) {
  const submitions = getAllNonValidIC(passengers)
    .map((passenger) => submitValidationRequest({ passenger, trip, passengerUid: passenger.uid }))
    .filter((s) => s !== undefined);

  return Promise.all(submitions);
}
