import { ga4Event } from './EventService';
import { EVENT_TRAVELERS_CLICKED_ACTIONS, EVENT_TRAVELERS_ENTITIES } from '@/logic/services/events/travelersClickedParams';

export const eventStepReached = () => {
  ga4Event('booking_flow_step_reached', {});
};

export const eventTravelersClicked = (traveler, action, extraInfo) => {
  ga4Event('ticket_control_clicked', {
    user_action: `${EVENT_TRAVELERS_ENTITIES[traveler]} ${EVENT_TRAVELERS_CLICKED_ACTIONS[action]}`,
    ...extraInfo,
  });
};

export const eventInfoModalOpened = (modalId, payload) => {
  ga4Event('modal_opened', {
    modal_name: modalId,
    ...payload,
  });
};
