<template>
  <div :class="mainClass" id="navigationTabs">
    <div :class="$style.desktopNavigationTabs__wrapper">
      <div :class="tabClass(tab)" v-for="tab in appTabs" :key="tab.id" @click="onTabClick(tab)" :data-test="`${tab.id}Tab`">
        <FhBackBtn v-if="isBackTab(tab) && !isActiveTabSearch" />
        <span :class="tabTextClass(tab)">{{ trans(`${tab.title}`) }}</span>
        <div :class="$style['desktopNavigationTabs__check-wrapper']">
          <i v-if="tab.completed" :class="checkClass"></i>
        </div>
        <i v-if="showRightArrow(tab)" :class="['fh fh-right-arrow', $style.desktopNavigationTabs__arrow]"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { NAVIGATION_TABS } from '@/store/modules/navigation.module';
import NavigationMixin from '../NavigationMixin.js';
import FhBackBtn from '@/components/shared/FhBackBtn/FhBackBtn';
import { trans } from '@/filters.js';

export default {
  name: 'DesktopNavigationTabs',
  components: {
    FhBackBtn
  },
  mixins: [NavigationMixin],
  computed: {
    ...mapState({
      appTabs: state => state.navigationModule.appTabs,
      activeTab: state => state.navigationModule.activeTab,
      initialLoad: state => state.navigationModule.initialLoad
    }),
    ...mapGetters({
      isActiveTabSearch: 'navigationModule/isActiveTabSearch'
    }),
    mainClass() {
      const extraClass = this.initialLoad ? this.$style['desktopNavigationTabs--transparent'] : '';
      return `${this.$style.desktopNavigationTabs} ${extraClass}`;
    },
    checkClass() {
      let cobrandedClass = this.globalCobrandedVariant === 'easyferry' ? this.$style['desktopNavigationTabs__check--easyferry'] : '';
      return ['fh fh-check', this.$style.desktopNavigationTabs__check, cobrandedClass];
    }
  },
  methods: {
    trans,
    ...mapActions({
      enableΝavTab: 'navigationModule/enableΝavTab',
      changeNavTab: 'navigationModule/changeNavTab',
      disableNavTabs: 'navigationModule/disableNavTabs',
      setCompletedNavTab: 'navigationModule/setCompletedNavTab',
      setInitialLoad: 'navigationModule/setInitialLoad',
      handleNavTabBackClick: 'navigationModule/handleNavTabBackClick'
    }),
    tabClass(tab) {
      const extraClass = tab.enabled ? '' : this.$style['desktopNavigationTabs__tab--inactive'];
      return `${this.$style.desktopNavigationTabs__tab} ${extraClass}`;
    },
    tabTextClass(tab) {
      const extraClass = this.activeTab === tab.id ? this.$style['desktopNavigationTabs__tab-text--active'] : '';
      return `${this.$style['desktopNavigationTabs__tab-text']} ${extraClass} text-ellipsis`;
    },
    isBookTab(tab) {
      return tab.id === NAVIGATION_TABS.BOOK;
    },
    isBackTab(tab) {
      return tab.id === NAVIGATION_TABS.BACK;
    },
    showRightArrow(tab) {
      return !this.isBackTab(tab) && !this.isBookTab(tab);
    },
    onTabClick(tab) {
      if (this.isBackTab(tab)) this.handleNavTabBackClick();
      else this.changeNavTab(tab.id);
    }
  }
};
</script>

<style module lang="scss">
@import './_desktop-navigation-tabs.scss';
</style>
