import TripsFilterer from '@/logic/filterers/TripsFilterer';
import * as types from '../mutation-types';
import { tripsSupportEarlyBooking } from '@/logic/managers/discounts/EarlyBooking';
import { createPricingRequest } from '@/logic/services/pricing/createPricingRequest';
import { getPersistingErrorsBag } from '@/logic/models/errorBag/errorBag';
import { roundPrice, uniqueId } from '@/logic/helpers/utils';
import { createPassengerForSeating } from '@/logic/services/pricing/createPassengerForSeating';
import { createVehicleForSeating } from '@/logic/services/pricing/createVehicleForSeating';
import { changeAccommodationOfPet, changeOwnerOfPet, changeTypeOfPet, createPetForSeating } from '@/logic/services/pricing/createPetForSeating';
import { TRIP_STATUS } from '@/logic/models/trips/TripStatus';
import { resetTripPricingImmutable, setSeatingOptionsImmutable, updateTripBeforePricingImmutable, updateTripPricingImmutable } from '@/logic/models/booking/updateTripPricingImmutable';
import { isPriceableTrip, isPricedTrip, makePricingRequest } from '@/logic/services/pricing/makePricingRequest';
import { createTripForSeating } from '@/logic/models/booking/createTripForSeating';
import { restartArrayFromIndex } from '@/logic/helpers/arrayFunctions';
import { applyEnforcedLoyaltyDiscount, getEnforcedLoyaltyState } from '@/logic/BL/loyaltyCards';
import { applyEnforcedResidenceDiscount, getEnforcedResidenceDiscountState } from '@/logic/BL/residenceDiscount';
import { PASSENGER_OFFER_STATUS, isWaitingValidation } from '@/logic/BL/common/passengerOffers/passengerOffer';
import { isInfantPassenger } from '@/logic/filterers/PassengersFilterer';
import { eventChangedPetType, eventLoyaltyValidationSuccess, eventSelectedMotorhome, eventSeatingTravelersClicked, eventPricingSuccess, eventCabinSelected } from '@/logic/services/events/createSeatingEvents';
import { createPassengerGroupString } from '@/logic/generators/phrases';
import { arrangePassengersInGroups } from '@/logic/filterers/ArrangePassengersInGroups';
import { getTripSeatOptionFromAbbr, isSingleBerth, isCabin } from '@/logic/filterers/SeatOptionsFilterer';
import { isInfantType } from '@/logic/filterers/DiscountsFilterer';
import { createTripCombinations } from '@/logic/filterers/createTripCombinations';
import { PRICING_STATUSES, isSuccessfulPricing } from '@/logic/models/pricing/PricingResponse';
import { eventPricingError } from '@/logic/services/events/createPricingEvents';
import { logException } from '@/logic/services/events/errorLogging';
import emitter from '@/emitter';

export const ticketConfigurationModule = {
  namespaced: true,
  state: {
    isWaitingForPrices: false,
    hasNetworkError: false,
    trips: [],
    tripRequestsCombinations: [],
    selectedSpecialOffers: {
      earlyBooking: false,
    },
  },

  getters: {
    hasEarlyBooking: (state) => tripsSupportEarlyBooking(state.trips),
    hasSelectedEarlyBooking: (state) => state.selectedSpecialOffers.earlyBooking,
    hasVerifiedAvailability: (state) => state.trips.every((trip) => isPricedTrip(trip)),
    isWaitingPassengerOfferValidation: (state) => {
      return !!state.trips.some((trip) => trip.passengers.some((p) => isWaitingValidation(p.loyalty) || isWaitingValidation(p.residenceDiscount)));
    },
    overallPrice: (state) => {
      if (!TripsFilterer.hasVerifiedAvailability(state.trips)) return undefined;
      return roundPrice(TripsFilterer.getTotalPrice(state.trips), 2);
    },
  },

  mutations: {
    [types.SET_TRIPS_FOR_SEATING](state, initialTrips) {
      state.trips = initialTrips.map((trip, tripIndex) => createTripForSeating(trip, tripIndex));
    },
    [types.UPDATE_TRIPS_FOR_SEATING](state, pricedTrips) {
      state.trips = pricedTrips;
    },
    [types.SET_IS_WAITING_FOR_PRICES] (state, value) {
      state.isWaitingForPrices = value;
    },
    [types.SET_HAS_NETWORK_ERROR] (state, value) {
      state.hasNetworkError = value;
    },
    [types.SET_EARLY_BOOKING](state, value) {
      state.selectedSpecialOffers.earlyBooking = value;
    },
    [types.RESET_PRICING_ERRORS](state) {
      state.trips = state.trips.map((trip) => ({
        ...trip,
        pricingErrors: [],
      }));
    },
    [types.RESET_PRICING_STATE](state) {
      state.trips = state.trips.map((t) => ({
        ...t,
        STATUS: TRIP_STATUS.PRICEABLE,
      }));
    },
    [types.ADD_PASSENGER](state) {
      let newId = uniqueId(10);
      state.trips.forEach((trip) => {
        const count = trip.passengers.length;
        let newPassenger = createPassengerForSeating(count, newId);

        // set new passenger seat type
        let defaultAccommodation = { ...trip.defaultAccommodation };
        newPassenger.companySpecificAccommodation = defaultAccommodation;
        newPassenger.selectedAccommodation = defaultAccommodation.ClassAbbr;

        let defaultDiscount = { ...trip.defaultDiscount };
        newPassenger.companySpecificDiscount = defaultDiscount;
        newPassenger.type = defaultDiscount.DiscountCategory;

        trip.passengers.push(newPassenger);
      });
    },
    [types.REMOVE_PASSENGER](state) {
      state.trips.forEach((trip) => {
        trip.passengers.splice(trip.passengers.length - 1, 1);
        eventSeatingTravelersClicked('PASSENGER', 'REMOVED', trip);
      });
    },
    [types.CHANGE_PASSENGER_SEAT](state, { accommodation, passenger, changedTrip }) {
      state.trips = state.trips.map((t) => {
        if (t.id !== changedTrip.id) return t;
        return {
          ...t,
          passengers: t.passengers.map((p) => {
            if (p.id !== passenger.id) return p;
            return {
              ...p,
              companySpecificAccommodation: accommodation ? accommodation : undefined,
              selectedAccommodation: accommodation ? accommodation.ClassAbbr : '',
            };
          }),
        };
      });
    },
    [types.CHANGE_PASSENGER_PROPERTY](state, { passenger, trip, property, value }) {
      state.trips = state.trips.map((t) => {
        if (t.id !== trip.id) return t;
        return {
          ...t,
          passengers: t.passengers.map((p) => {
            if (p.id !== passenger.id) return p;
            return {
              ...p,
              [property]: value,
            };
          }),
        };
      });
    },
    [types.CHANGE_PASSENGER_RESIDENCE_DISCOUNT](state, payload) {
      const { changedTrip, passenger, changes } = payload;

      state.trips = state.trips.map((trip) => {
        if (trip.Details.Company !== changedTrip.Details.Company) return trip;
        const updatedPassengers = trip.passengers.map((p) => {
          if (p.id !== passenger.id) return p;

          const currentResidenceStatus = p.residenceDiscount;
          const newStatus = {
            ...currentResidenceStatus,
            ...changes,
          };

          return {
            ...p,
            residenceDiscount: newStatus,
          };
        });

        const enforcedResidenceDiscountState = getEnforcedResidenceDiscountState(changes, trip.residenceDiscount, updatedPassengers);
        return {
          ...trip,
          passengers: updatedPassengers.map(p => ({
            ...p,
            residenceDiscount: applyEnforcedResidenceDiscount(p.residenceDiscount, enforcedResidenceDiscountState)
          }))
        };
      });
    },
    [types.CHANGE_PASSENGER_LOYALTY_NUMBER](state, payload) {
      const { changedTrip, passenger, changes } = payload;

      state.trips = state.trips.map((trip) => {
        if (trip.Details.Company !== changedTrip.Details.Company) return trip;
        const updatedPassengers = trip.passengers.map((p) => {
          if (p.id !== passenger.id) return p;

          const currentLoyaltyStatus = p.loyalty;
          const newLoyaltyStatus = {
            ...currentLoyaltyStatus,
            ...changes,
          };

          if (newLoyaltyStatus.STATUS === PASSENGER_OFFER_STATUS.VALID) {
            eventLoyaltyValidationSuccess(newLoyaltyStatus.schemeName, trip);
          }

          return {
            ...p,
            loyalty: newLoyaltyStatus,
          };
        });

        const enforcedLoyaltyState = getEnforcedLoyaltyState(changes, trip.loyaltyScheme, updatedPassengers);
        return {
          ...trip,
          passengers: updatedPassengers.map(p => ({
            ...p,
            loyalty: applyEnforcedLoyaltyDiscount(p.loyalty, enforcedLoyaltyState)
          })),
        };
      });
    },
    [types.ADD_VEHICLE](state) {
      const newId = uniqueId(10);
      let passengerIndex = state.trips[0].vehicles.length;
      state.trips.forEach((trip) => {
        const vehiclesCount = trip.vehicles.length;
        const passengersSize = trip.passengers.length;
        // in case we add a vehicle and vehicles are more than passeners set to default passenger 1
        if (passengersSize < vehiclesCount + 1) passengerIndex = 0;
        let newVehicle = createVehicleForSeating(vehiclesCount, newId, passengerIndex);
        let vehicleAccommodation = trip.vehicleAccommodations.find((o) => o.categoryCode === newVehicle.TypeCategory);
        newVehicle.TypeAbbr = vehicleAccommodation.vehicleTypeAbbr;
        newVehicle.isMotorhome = vehicleAccommodation.isMotorhome;
        newVehicle.vehicleCategoryType = vehicleAccommodation.vehicleCategoryType;
        trip.vehicles.push(newVehicle);
      });
    },
    [types.REMOVE_VEHICLE](state) {
      state.trips.forEach((trip) => {
        trip.vehicles.splice(trip.vehicles.length - 1, 1);
        eventSeatingTravelersClicked('VEHICLE', 'REMOVED', trip);
      });
    },
    [types.CHANGE_VEHICLE_PROPERTY](state, { vehicle, trip, property, value }) {
      state.trips = state.trips.map((t) => {
        if (t.id !== trip.id) return t;
        return {
          ...t,
          vehicles: t.vehicles.map((v) => {
            if (v.id !== vehicle.id) return v;
            return {
              ...v,
              [property]: value,
            };
          }),
        };
      });
    },
    [types.CHANGE_VEHICLE_DRIVER](state, { updatedVehicle, tripId }) {
      state.trips = state.trips.map((t) => {
        if (t.id !== tripId) return t;
        return {
          ...t,
          vehicles: t.vehicles.map((v) => {
            if (v.id !== updatedVehicle.id) return v;
            return updatedVehicle;
          }),
        };
      });
    },
    [types.ADD_PET](state) {
      let newId = uniqueId(10);
      state.trips.forEach((trip) => {
        const petsCount = trip.pets.length;
        trip.pets.push(createPetForSeating(trip, petsCount, newId));
      });
    },
    [types.REMOVE_PET](state) {
      state.trips.forEach((trip) => {
        trip.pets.splice(trip.pets.length - 1, 1);
        eventSeatingTravelersClicked('PET', 'REMOVED', trip);
      });
    },
    [types.CHANGE_PET_TYPE](state, payload) {
      const { pet, changedTrip, selectedPetTypeCode } = payload;
      state.trips = state.trips.map((t) => {
        if (t.petPolicy.type !== changedTrip.petPolicy.type) return t;
        eventChangedPetType(t);
        return {
          ...t,
          pets: t.pets.map((p) => {
            if (p.id !== pet.id) return p;
            const selectedPetType = t.PetTypes.find((t) => t.petTypeCode === selectedPetTypeCode);
            const updatedPet = changeTypeOfPet(t.pets[pet.petIndex], selectedPetType);
            return {
              ...p,
              ...updatedPet,
            };
          }),
        };
      });
    },

    /**
     * Change the accomodation type of a pet to all selected trips
     * @param {string} selectedAccommodation
     * @param {Object} pet
     * @param {Object} changedTrip
     */
    [types.CHANGE_PET_ACCOMMODATION](state, payload) {
      const { pet, changedTrip, selectedAccommodation } = payload;
      state.trips = state.trips.map((t) => {
        if (t.id !== changedTrip.id) return t;
        return {
          ...t,
          pets: t.pets.map((p) => {
            if (p.id !== pet.id) return p;
            const updatedPet = changeAccommodationOfPet(pet, selectedAccommodation);
            return {
              ...p,
              ...updatedPet,
            };
          }),
        };
      });
    },
    [types.CHANGE_PET_OWNER](state, payload) {
      const { pet, changedTrip, selectedPassenger } = payload;
      state.trips = state.trips.map((t) => {
        if (t.id !== changedTrip.id) return t;
        return {
          ...t,
          pets: t.pets.map((p) => {
            if (p.id !== pet.id) return p;
            const updatedPet = changeOwnerOfPet(pet, selectedPassenger);
            return {
              ...p,
              ...updatedPet,
            };
          }),
        };
      });
    },
    [types.RESET_SEAT_SELECTION](state, payload) {
      const { trip } = payload;
      state.trips = state.trips.map((t) => {
        if (t.id !== trip.id) return t;
        let defaultSeat = trip.defaultAccommodation;
        let infantsSeat = trip.infantAccommodation;
        return {
          ...t,
          passengers: t.passengers.map((p) => {
            let isInfant = isInfantPassenger(p);
            return {
              ...p,
              selectedAccommodation: isInfant ? infantsSeat.ClassAbbr : defaultSeat.ClassAbbr,
              companySpecificAccommodation: isInfant ? infantsSeat : defaultSeat,
            };
          }),
        };
      });
    },
    [types.SET_SEAT_SELECTION_MESSAGE](state, { changedTrip, passenger, message }) {
      state.trips = state.trips.map((t) => {
        if (t.id !== changedTrip.id) return t;
        return {
          ...t,
          passengers: t.passengers.map((p) => {
            if (p.id !== passenger.id) return p;
            return {
              ...p,
              seatSelectionMessage: message,
            };
          }),
        };
      });
    },
    [types.RESET_SEAT_SELECTION_MESSAGE](state) {
      state.trips = state.trips.map((t) => ({
        ...t,
        passengers: t.passengers.map((p) => ({
          ...p,
          seatSelectionMessage: '',
        })),
      }));
    },
    [types.REGROUP_TRIP_SELECTIONS](state) {
      state.trips = state.trips.map((trip) => {
        let newTrip = resetTripPricingImmutable(trip);

        let { SeatingAnalysis, PricingErrors, ...pricingData } = createPricingRequest(newTrip, state.selectedSpecialOffers);

        newTrip.PricingData = pricingData;
        newTrip.pricingErrors = getPersistingErrorsBag(newTrip.pricingErrors, PricingErrors);
        newTrip.STATUS = newTrip.pricingErrors.length > 0 ? TRIP_STATUS.UNPRICEABLE : TRIP_STATUS.PRICEABLE;

        newTrip.SeatingAnalysis = SeatingAnalysis;
        newTrip = setSeatingOptionsImmutable(newTrip);
        return newTrip;
      });
    },
    async [types.PREPARE_TRIPS_PRICING_COMBINATIONS](state, { disableTripGrouping }) {
      const tripsCombinations = createTripCombinations(state.trips, disableTripGrouping);
      state.tripRequestsCombinations = Object.values(tripsCombinations).map((trips) => {
        return trips.map((trip) => trip.tripIndex);
      });
    },
    async [types.PREPARE_TRIPS_PRICING](state) {
      state.trips = state.trips.map((trip) => {
        let pricingRequest = createPricingRequest(trip, state.selectedSpecialOffers);
        return updateTripBeforePricingImmutable(trip, pricingRequest);
      });
    },
    [types.CHANGE_TRIP_PROPERTY](state, { trip, property, value }) {
      state.trips = state.trips.map((t) => {
        if (t.id !== trip.id) return t;
        return {
          ...t,
          [property]: value,
        };
      });
    },
  },
  actions: {
    setTripsForSeating({ commit, dispatch }, payload) {
      commit(types.SET_TRIPS_FOR_SEATING, payload);
      dispatch('resetAvailabilityAndPrices', true);
    },
    setEarlyBooking({ commit, dispatch }, value) {
      commit(types.SET_EARLY_BOOKING, value);
      dispatch('resetAvailabilityAndPrices', true);
    },
    resetAvailabilityAndPrices({ commit }, resetPricingState) {
      commit(types.RESET_PRICING_ERRORS);
      if (resetPricingState) commit(types.RESET_PRICING_STATE);
    },
    addNewPassenger({ commit, dispatch }, payload) {
      commit(types.ADD_PASSENGER, payload);
      dispatch('regroupTripSelections');
    },
    removeLastPassenger({ commit, dispatch }, payload) {
      commit(types.REMOVE_PASSENGER, payload);
      dispatch('regroupTripSelections');
    },
    changePassengerResidenceDiscount({ commit, dispatch }, payload) {
      commit(types.CHANGE_PASSENGER_RESIDENCE_DISCOUNT, payload);
      dispatch('regroupTripSelections');
    },
    changePassengerLoyaltyNumber({ commit, dispatch }, payload) {
      commit(types.CHANGE_PASSENGER_LOYALTY_NUMBER, payload);
      dispatch('regroupTripSelections');
    },
    addNewVehicle({ commit, dispatch }, payload) {
      commit(types.ADD_VEHICLE, payload);
      dispatch('regroupTripSelections');
    },
    removeLastVehicle({ commit, dispatch }, payload) {
      commit(types.REMOVE_VEHICLE, payload);
      dispatch('regroupTripSelections');
    },
    changeVehicleDriverInStore({ commit, dispatch }, payload) {
      commit(types.CHANGE_VEHICLE_DRIVER, payload);
      dispatch('regroupTripSelections');
    },
    addNewPet({ commit, dispatch }, payload) {
      commit(types.ADD_PET, payload);
      dispatch('regroupTripSelections');
    },
    removeLastPet({ commit, dispatch }, payload) {
      commit(types.REMOVE_PET, payload);
      dispatch('regroupTripSelections');
    },
    changePetType({ commit, dispatch }, payload) {
      commit(types.CHANGE_PET_TYPE, payload);
      dispatch('regroupTripSelections');
    },
    changePetAccommodation({ commit, dispatch }, payload) {
      commit(types.CHANGE_PET_ACCOMMODATION, payload);
      dispatch('regroupTripSelections');
    },
    changePetOwner({ commit, dispatch }, payload) {
      commit(types.CHANGE_PET_OWNER, payload);
      dispatch('regroupTripSelections');
    },
    regroupTripSelections({ commit }) {
      emitter.$emit('onSeatSelectionsChange');
      commit(types.REGROUP_TRIP_SELECTIONS);
    },
    async performPricingWithRetry({ commit, dispatch, rootState, state }) {

      commit(types.PREPARE_TRIPS_PRICING);

      if (state.trips.some((trip) => !isPriceableTrip(trip))) {
        return;
      }

      commit(types.SET_IS_WAITING_FOR_PRICES, true);
      commit(types.SET_HAS_NETWORK_ERROR, false);

      // make initial pricing attempt with trip-grouping enabled
      let pricingResponses = await dispatch('updateTripPrices', { disableTripGrouping: false });
      const hasNetworkError = pricingResponses.some((r) => r.Status === PRICING_STATUSES.CONNECTION_ERROR);
      let hasPricingSuccess = pricingResponses.every((r) => r.Status === PRICING_STATUSES.SUCCESS);

      // in case or error, make secondary attempt with disabled trip grouping
      if (!hasPricingSuccess) {
        pricingResponses = await dispatch('updateTripPrices', { disableTripGrouping: true });
        hasPricingSuccess = pricingResponses.every((r) => r.Status === PRICING_STATUSES.SUCCESS);
      }
        
      const pricedTrips = [];
      pricingResponses.forEach((tripPricingResponse) => {
        const { tripIndex } = tripPricingResponse;
        const unpricedTrip = state.trips[tripIndex];
        pricedTrips[tripIndex] = updateTripPricingImmutable(unpricedTrip, tripPricingResponse);
      });

      commit(types.UPDATE_TRIPS_FOR_SEATING, pricedTrips);
      dispatch('uiModule/changeIsFetchingForPrices', false, { root: true });
      commit(types.SET_HAS_NETWORK_ERROR, hasNetworkError);
      commit(types.SET_IS_WAITING_FOR_PRICES, false);

      if (hasPricingSuccess) {
        eventPricingSuccess({ number_of_trips: state.trips.length, number_of_passengers: rootState.searchModule.passengers });
      } else {
        pricingResponses.filter(r => ! isSuccessfulPricing(r)).forEach((tripPricingResponse) => {
          const { tripIndex } = tripPricingResponse;
          eventPricingError(state.trips[tripIndex], tripPricingResponse, state.trips[tripIndex].provider);
        });
      }
    
      if (hasNetworkError) {
        logException('SeatingApp:Failed to fetch prices', {});
      }
    },
    async updateTripPrices({ commit, state, dispatch }, payload) {

      dispatch('uiModule/changeIsFetchingForPrices', true, { root: true });

      commit(types.PREPARE_TRIPS_PRICING_COMBINATIONS, payload);

      const tripsCombinations = state.tripRequestsCombinations.map((c) => c.map((i) => state.trips[i]));

      // make the pricing requests
      let pricingPromises = tripsCombinations.map(async (trips) => {
        return await makePricingRequest(trips.map(({ tripIndex, PricingData }) => ({ tripIndex, ...PricingData.PricingRequest })));
      });

      // await for all pricing responses
      const pricingResponses = (await Promise.all(pricingPromises)).flat();

      return pricingResponses;
    },
    resetSeatSelectionMessage({ commit }) {
      commit(types.RESET_SEAT_SELECTION_MESSAGE);
    },
    changePassengerAccommodation({ state, commit, dispatch }, payload) {
      commit(types.CHANGE_PASSENGER_SEAT, payload);
      const updatedTrip = state.trips.find((t) => t.id === payload.changedTrip.id);
      if (isCabin(payload.accommodation)) eventCabinSelected(updatedTrip);
      dispatch('updatePassengersInUnfilledCabins', updatedTrip);
      dispatch('regroupTripSelections');
    },
    applyCabinToPassengers({ state, commit, dispatch }, { selectedSeatOption, passenger, changedTrip }) {
      let passengersInCabin = [];
      let rearrangedPassengers = restartArrayFromIndex(changedTrip.passengers, passenger.passengerIndex);
      let nonInfantPassengers = rearrangedPassengers.filter((p) => !p.isInfant);

      nonInfantPassengers.forEach((nonInfantPassenger, index) => {
        if (index + 1 > selectedSeatOption.accommodationCapacity) return;
        commit(types.CHANGE_PASSENGER_SEAT, { accommodation: selectedSeatOption, passenger: nonInfantPassenger, changedTrip });
        passengersInCabin.push(nonInfantPassenger);
      });

      if (nonInfantPassengers.length > 1) commit(types.SET_SEAT_SELECTION_MESSAGE, { changedTrip, passenger, message: createPassengerGroupString(passengersInCabin) });

      const updatedTrip = state.trips.find((t) => t.id === changedTrip.id);
      dispatch('updatePassengersInUnfilledCabins', updatedTrip);
      dispatch('regroupTripSelections');
    },
    updatePassengersInUnfilledCabins({ commit }, trip) {
      let passengerGroups = arrangePassengersInGroups(trip);

      if (passengerGroups.length === 0) return;

      passengerGroups.forEach((group) => {
        let groupFirstPassenger = group[0];
        let selectedSeatOption = getTripSeatOptionFromAbbr(trip, groupFirstPassenger.selectedAccommodation);
        if (!isSingleBerth(selectedSeatOption) && selectedSeatOption.accommodationCapacity > group.length) {
          group.forEach((passenger) => commit(types.CHANGE_PASSENGER_SEAT, { accommodation: undefined, passenger, changedTrip: trip }));
        }
      });
    },
    handleCabinsOnPassengerRemoval({ state, dispatch }) {
      state.trips.forEach((trip) => {
        dispatch('updatePassengersInUnfilledCabins', trip);
        dispatch('regroupTripSelections');
      });
    },
    resetSeatSelection({ commit }, payload) {
      commit(types.RESET_SEAT_SELECTION, payload);
    },
    changePassengerTypeInStore({ state, commit, dispatch }, { discountCategory, passenger, changedTrip }) {
      commit(types.CHANGE_PASSENGER_PROPERTY, { passenger, trip: changedTrip, property: 'isTypeTouched', value: true });

      // Type affects all trips, so we update in a loop based on the passenger index and the new type value (AD, CH, IN)
      // NOTE: the provided value corresponds to DiscountCategory property of a company discount
      state.trips.forEach((trip) => {
        const targetedPassenger = trip.passengers.find((p) => p.id === passenger.id);

        // do not apply the new pass type to companies other than the one of the changedTrip
        if (trip.Details.Company !== changedTrip.Details.Company) return;

        // do not change the type for passengers of other trips that have already been touched
        // (disable this part if we want to sync discounts accross trips)
        if (trip.id !== changedTrip.id && targetedPassenger.isTypeTouched === true) return;

        const wasInfant = isInfantPassenger(targetedPassenger);
        const isInfant = isInfantType(discountCategory);
        const companySpecificDiscount = trip.passengerDiscounts.find((d) => d.DiscountCategory === discountCategory);

        commit(types.CHANGE_PASSENGER_PROPERTY, { passenger: targetedPassenger, trip, property: 'isInfant', value: isInfant });
        commit(types.CHANGE_PASSENGER_PROPERTY, { passenger: targetedPassenger, trip, property: 'type', value: discountCategory });
        commit(types.CHANGE_PASSENGER_PROPERTY, { passenger: targetedPassenger, trip, property: 'companySpecificDiscount', value: companySpecificDiscount });

        if (isInfant === true) {
          // if the new passenger is an infant, assign infant class to him
          const infantAccommodation = { ...trip.infantAccommodation };
          commit(types.CHANGE_PASSENGER_PROPERTY, { passenger: targetedPassenger, trip, property: 'companySpecificAccommodation', value: infantAccommodation });
          commit(types.CHANGE_PASSENGER_PROPERTY, { passenger: targetedPassenger, trip, property: 'selectedAccommodation', value: infantAccommodation.ClassAbbr });
          const targetTripInState = state.trips.find((t) => t.id === trip.id);
          // Since infants do not occupy a separate bed in cabins, we need to update the passengers in any unfilled cabins
          dispatch('updatePassengersInUnfilledCabins', targetTripInState);
          dispatch('resetSeatSelectionMessage');
        }

        if (wasInfant === true) {
          // if changing back from infant, assign default class to the new passenger
          const defaultAccommodation = { ...trip.defaultAccommodation };
          commit(types.CHANGE_PASSENGER_PROPERTY, { passenger: targetedPassenger, trip, property: 'companySpecificAccommodation', value: { ...trip.defaultAccommodation } });
          commit(types.CHANGE_PASSENGER_PROPERTY, { passenger: targetedPassenger, trip, property: 'selectedAccommodation', value: defaultAccommodation.ClassAbbr });
        }
      });

      dispatch('regroupTripSelections');
    },
    changeVehicleTypeInStore({ state, commit, dispatch }, { selectedVehicleType, vehicle }) {
      state.trips.forEach((trip) => {
        // find the vehicle option according to the value provided
        let vehicleAccommodation = trip.vehicleAccommodations.find((v) => v.categoryCode === selectedVehicleType);

        if (!vehicleAccommodation) vehicleAccommodation = trip.vehicleAccommodations.find((v) => v.categoryCode === settings.defaults.defaultVehicleCategory);
        if (vehicleAccommodation.isMotorhome === true) eventSelectedMotorhome(trip, vehicleAccommodation.vehicleTypeAbbr);
        // update TypeCategory and TypeAbbr. Note that even if a vehicle type is not
        // supported for an operator, we still use it's vehicle type category in order for
        // the pricing pipe to identify it, but we use the type abbreviation of the supported type (even
        // with a fallback)
        commit(types.CHANGE_VEHICLE_PROPERTY, { vehicle, trip, property: 'TypeCategory', value: selectedVehicleType });
        commit(types.CHANGE_VEHICLE_PROPERTY, { vehicle, trip, property: 'TypeAbbr', value: vehicleAccommodation.vehicleTypeAbbr });
        commit(types.CHANGE_VEHICLE_PROPERTY, { vehicle, trip, property: 'isMotorhome', value: vehicleAccommodation.isMotorhome });
        commit(types.CHANGE_VEHICLE_PROPERTY, { vehicle, trip, property: 'vehicleCategoryType', value: vehicleAccommodation.vehicleCategoryType });

        // verify that camping on board selection can be persisted after changing the vehicle type,
        // and apply any change both in the vehicle as well as the trip itself
        let persistCampingOnBoard = vehicleAccommodation.hasOnBoardOption && trip.vehicles[vehicle.vehicleIndex].wantsCampingOnBoard;
        commit(types.CHANGE_VEHICLE_PROPERTY, { vehicle, trip, property: 'wantsCampingOnBoard', value: persistCampingOnBoard });
        commit(types.CHANGE_TRIP_PROPERTY, { trip, property: 'extraAttributes', value: { ...trip.extraAttributes, wantsCampingOnBoard: persistCampingOnBoard } });
      });

      dispatch('regroupTripSelections');
    },
    applyCampingOnBoard({ state, commit, dispatch }, { trip, vehicle, value }) {
      commit(types.CHANGE_VEHICLE_PROPERTY, { vehicle, trip, property: 'wantsCampingOnBoard', value });
      commit(types.CHANGE_TRIP_PROPERTY, { trip, property: 'extraAttributes', value: { ...trip.extraAttributes, wantsCampingOnBoard: value } });
      const targetTripInState = state.trips.find((t) => t.id === trip.id);

      if (!value) {
        commit(types.RESET_SEAT_SELECTION, { trip: targetTripInState });
        dispatch('regroupTripSelections');
        return;
      }

      const { passengers, Details, passengerAccommodations } = trip;
      let campingOnBoardAbbreviation;

      if (Details.Company === 'MIN') campingOnBoardAbbreviation = passengers.length > 4 ? 'AB4' : passengers.length < 2 ? 'AB2' : `AB${passengers.length}`;
      if (Details.Company === 'ANSF') campingOnBoardAbbreviation = 'D';
      if (Details.Company === 'MOCK') campingOnBoardAbbreviation = 'D';
      
      const selectedSeatOption = passengerAccommodations.find((o) => o.ClassAbbr === campingOnBoardAbbreviation);
      targetTripInState.passengers.forEach((passenger) => commit(types.CHANGE_PASSENGER_SEAT, { accommodation: selectedSeatOption, passenger, changedTrip: targetTripInState }));

      dispatch('regroupTripSelections');
    },
  },
};
