import { PASSENGER_OFFER_STATUS } from '@/logic/BL/common/passengerOffers/passengerOffer';

export const mergePassengerWithSavedPassenger = (passenger, savedPassenger, islanderDiscountAvailable) => {
  const { timesTravelled, firstName, lastName, gender, birthDateFull, birthplace, documentDateFull, nationality, passport, man, loyaltyCards } = savedPassenger;
  const [birthday, birthmonth, birthyear] = birthDateFull.split('/');
  const [docexpday, docexpmonth, docexpyear] = documentDateFull.split('/');

  return {
    ...passenger,
    timesTravelled,
    loyaltyCards,
    shouldSaveDetails: false,
    isNewPassenger: false,
    firstName,
    lastName,
    gender,
    birthDateFull,
    birthplace,
    documentDateFull,
    nationality,
    passport,
    birthday,
    birthmonth,
    birthyear,
    docexpday,
    docexpmonth,
    docexpyear,
    islanderCode: man,
    islanderCodeEnabled: islanderDiscountAvailable ? man !== '' : false
  };
};

export const getDefaultPassengerDetails = passenger => {
  return {
    ...passenger,
    isNewPassenger: true,
    shouldSaveDetails: false,
    gender: undefined,
    firstName: '',
    lastName: '',
    passport: '',
    birthplace: '',
    nationality: '',
    birthday: '',
    birthmonth: '',
    birthyear: '',
    birthDateFull: '',
    docexpday: '',
    docexpmonth: '',
    docexpyear: '',
    documentDateFull: '',
    islanderCode: '',
    islanderCodeMessage: '',
    islanderCodeStatus: PASSENGER_OFFER_STATUS.UNDETERMINED,
    islanderCodeEnabled: false
  };
};
