<template>
  <div class="booking-flow-box__row">
    <span> {{ text }} </span>
    <i v-if="hasError" class="fh fh-notification"></i>
    <span v-else class="price">
      <span v-if="isDiscount">-</span>{{ currencyStringPre(price) }}
    </span>
  </div>
</template>

<script>
import { currencyStringPre } from '@/filters';

export default {
  name: 'PricesBreakdownItem',
  props: {
    text: {
      type: String
    },
    price: {
      type: Number
    },
    hasError: {
      type: Boolean,
      default: false
    },
    isDiscount: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    currencyStringPre
  }
};
</script>
