/**
 * The complete representation of time information for an itinerary,
 * used throughout the application in various models
 *
 * The inputs must be dayjs objects of departure and arrivalObj
 *
 */
import dayjs from '@/logic/services/date/dateService';
import settings from '@/settings';
import { getPageLanguage } from '@/logic/helpers/utils';
import { durationInMinutes, getLongDateFormat, getShortDateFormat, getTripTimeFormat } from '../../helpers/dateUtils';
import { minutesToDurationStr } from '../../helpers/stringUtils';

/**
 * Datetime related information for an itinerary
 *
 * @param {dayjs.Dayjs} departureObj
 * @param {dayjs.Dayjs} arrivalObj
 * @param {Object} data
 */
export const createTripTimings = (departureObj, arrivalObj, data) => {

  if (false === (dayjs.isDayjs(departureObj) && dayjs.isDayjs(arrivalObj))) {
    throw 'The inputs are not valid dayjs objects';
  }
    
  let currentLanguage = getPageLanguage().toUpperCase();
  
  let { tripDuration } = data;
  if (!tripDuration) {
    tripDuration = durationInMinutes(departureObj, arrivalObj);
  }
  let tripDurationStr = minutesToDurationStr(tripDuration, ' ');

  return {
    DepartureDateTime: departureObj,
    DepDate: departureObj.format(settings.formats.dayjsToFCRS),
    DepDateStr: departureObj.format(getShortDateFormat()),
    DepTime: departureObj.format(getTripTimeFormat()),
    DepDateLocal: departureObj.format(settings.formats.localizedDateFormat),
    DepDateLocalLong: departureObj.format(getLongDateFormat(currentLanguage)),
    ArrivalDateTime: arrivalObj,
    ArrDate: arrivalObj.format(settings.formats.dayjsToFCRS),
    ArrTime: arrivalObj.format(getTripTimeFormat()),
    ArrDateStr: arrivalObj.format(getShortDateFormat()),
    unix: Number(departureObj.unix()),
    duration: tripDuration,
    durationStr: tripDurationStr,
    overnight: arrivalObj.isAfter(departureObj, 'days'),
    waitTime: 0,
  }
}


