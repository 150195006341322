<template>
  <div>
    
    <div class="booking-flow-box__info mb16">{{ trans('portcollectiontitle') }}</div>

    <div v-if="ready">
      <div v-for="(companySpots, collectionSpotsIndex) in sortedPickupSpots" :key="'collection-spot-' + collectionSpotsIndex">
        <div class="mb16">{{ companySpots[0].companyName }}</div>
        <CollectionSpotItem
          v-for="(spot, spotIndex) in companySpots"
          :key="'collection-spot-' + collectionSpotsIndex + '-' + spotIndex"
          :title="spot.LocationTitle"
          :description="spot.LocationDescription"
          :address="spot.LocationAddress"
          :phone="spot.LocationPhone"
        />
      </div>
    </div>

    <LoadingSpinner v-else></LoadingSpinner>

    <p class="notification-text mt8 mb0"><i class="fh fh-notification"></i> {{ trans('portcollectiondescr') }}</p>
  </div>
    
</template>

<script>
import { sortBy as _sortBy, groupBy as _groupBy, indexOf as _indexOf, lastIndexOf as _lastIndexOf } from 'lodash-es';
import { message } from '@/logic/helpers/utils';
import { fetchPickupSpots } from '@/logic/services/ApiCRS';
import CollectionSpotItem from './CollectionSpotItem';
import { mapState, mapGetters } from 'vuex'
import { debouncedShort } from '@/logic/helpers/debounce';
import { trans } from '@/filters';

export default {
  name: 'collection-spots',
  components: {
    CollectionSpotItem
  },
  data() {
    return {
      pickupSpots: [],
      ready: false
    };
  },
  methods: {
    trans,
    debouncedPopulate(n, o) {
      const hasChanges = JSON.stringify(n) !== JSON.stringify(o)
      if (!hasChanges) return false;
      debouncedShort(() => this.fetchPickupSpotsList())
    },
    fetchPickupSpotsList() {
      this.ready = false;
      this.pickupSpots = [];
      if (this.companiesWithoutETicket.length === 0) {
        this.ready = true;
        return;
      }
      fetchPickupSpots(this.companiesWithoutETicket.join(), this.departurePorts.join()).then(response => {
        let pickupSpots = response.pickups.filter(o => {
          return _indexOf(this.departurePorts, o.LocationAbbr) <= _lastIndexOf(this.marketingCompanies, o.Company);
        });
        this.pickupSpots = pickupSpots;
        this.ready = true;
      });  
    },
  },
  watch: {
    departurePorts: {
      handler: 'debouncedPopulate',
      deep: true,
      immediate: true
    },
    companiesWithoutETicket: {
      handler: 'debouncedPopulate',
      deep: true,
      immediate: true
    },
  },
  computed: {
    ...mapState({
      trips: state => state.bookingModule.trips,
    }),
    ...mapGetters({
      departurePorts: 'bookingModule/departurePorts',
      marketingCompanies: 'bookingModule/marketingCompanies'
    }),
    companiesWithoutETicket() {
      return this.trips.filter(t => !t.Details.eTicket).map(t => {
        return t.Details.HasPrefCompany ? t.Details.PrefCompany : t.Details.Company;
      })
    },
    companiesWithETicket() { 
      return this.trips.filter(t => t.Details.eTicket).map(t => {
        return t.Details.HasPrefCompany ? t.Details.PrefCompany : t.Details.Company;
      })
    },
    eTicketsList() {
      return this.companiesWithETicket.map(companyCode => ({
        Company: companyCode,
        LocationAddress: '',
        LocationDescription: message('tab4eticketsingle'),
        LocationTitle: 'e-ticket',
        LocationPhone: ''
      }))
    },
    sortedPickupSpots() {
      // order the complete list (eticket and collection spots) in a keyed array
      // with the order being the same as the order of companies in the trips list
      const unsortedPickupSpots = this.eTicketsList.concat(this.pickupSpots);
      const sortedPickupSpots = _sortBy(unsortedPickupSpots, s => _indexOf(this.marketingCompanies, s.Company), ['ASC']);
      return _groupBy(sortedPickupSpots, s => s.Company);
    }
  }
};
</script>
