<template>
  <div :class="$style.travelerDetailsForm">
    <div :class="travelEntityClass">
      <div class="form-grid__traveler-title px0">
        <span><span v-html="vehicle.vehicleIcon"></span> {{ vehDescription(vehicle) }}</span>
      </div>

      <div class="form-grid__description">{{ vehicle.vehicleDescription }}</div>

      <div class="form-grid__inner-grid mb16" data-test="vehicleDetailsContainer">
        <VehicleDetailsSelection
          v-for="(item, selectionIndex) in filteredSelectionsPerTrip"
          :key="`vehicle-selection-${vehicle.vehicleIndex}-${selectionIndex}`"
          :showIcon="hasDifferentDriversPerTrip"
          :iconUrl="item.companyIcon"
          :description="item.selectionDescription"
          :driverIndex="item.driverIndex"
        />
      </div>

      <div class="form-grid__inputs">
        <template v-if="showSavedVehiclesDropdown">
          <FhDropdown
            :value="selectedSavedVehicle.id"
            :optionKeyToTrack="'id'"
            :optionKeyToShow="'licencePlate'"
            :options="filteredSavedVehicles"
            :id="`saved-vehicles-dropdown-${vehicle.vehicleIndex + 1}`"
            @input="handleClick"
          >
            <template #options="{ select }">
              <div>
                <FhDropdownOption
                  :class="$style['travelerDetailsForm__saved-traveler-option']"
                  v-for="(savedVehicle, index) in filteredSavedVehicles"
                  :key="`saved-vehicles-dropdown-${vehicle.vehicleIndex + 1}-option-${index}`"
                  :text="savedVehicle.licencePlate"
                  :index="index"
                  @click="select(savedVehicle, index)"
                />
              </div>
            </template>
          </FhDropdown>
        </template>

        <div>
          <FhInput
            :model-value="vehicle.licencePlate"
            @update:modelValue="handleInput('licencePlate', $event)"
            :name="`licencePlate-vehicle${vehicle.vehicleIndex + 1}`"
            :id="`licencePlate-vehicle${vehicle.vehicleIndex + 1}`"
            :placeholder="trans('licenceplateplaceholder')"
            rules="required|min:4|max:10|customVehiclePlate"
            :sanitizingFunction="sanitizeLicensePlate"
          />
          <span v-if="!hasLicencePlateError" class="text-light text-small"><i class="fh fh-info mr6"></i>{{ trans('rentalplate') }}</span>
        </div>

        <FhInput
          v-if="requiredDetails.vehicleBrand"
          :model-value="vehicle.vehicleBrand"
          @update:modelValue="handleInput('vehicleBrand', $event)"
          :name="`vehicleBrand-vehicle${vehicle.vehicleIndex + 1}`"
          :id="`vehicleBrand-vehicle${vehicle.vehicleIndex + 1}`"
          :placeholder="trans('vehiclebrandplaceholder')"
          rules="required|min:2|max:40"
          :sanitizingFunction="sanitizeUserInput"
        />

        <FhInput
          v-if="requiredDetails.vehicleBrand"
          :model-value="vehicle.vehicleModel"
          @update:modelValue="handleInput('vehicleModel', $event)"
          :name="`vehicleModel-vehicle${vehicle.vehicleIndex + 1}`"
          :id="`vehicleModel-vehicle${vehicle.vehicleIndex + 1}`"
          :placeholder="trans('vehiclemodelplaceholder')"
          rules="required|min:2|max:40"
          :sanitizingFunction="sanitizeUserInput"
        />
      </div>
    </div>
    <div :class="$style.travelerDetailsForm__toggle" v-if="showSavedVehiclesDropdown">
      <ToggleSwitch :hasFixedHeight="false" :value="vehicle.shouldSaveDetails" :text="toggleSwitchText()" @input="handleToggleClick" />
    </div>
  </div>
</template>

<script>
import VehicleDetailsSelection from '@/components/book/VehicleDetailsSelection/VehicleDetailsSelection';
import { sanitizeLicensePlate, sanitizeUserInput } from '@/logic/helpers/sanitizers';
import { mapState } from 'vuex';
import { arrayUnique } from '@/logic/helpers/arrayFunctions';
import { trans, vehDescription } from '@/filters';
import { getConfigurationValue } from '@/logic/helpers/utils';
import { message } from '@/logic/helpers/utils';
import { NEW_VEHICLE_ID } from '@/logic/adapters/userAdapter';
import { isUserProfileCompatibleVehicleType } from '@/logic/adapters/vehicleTypes';

export default {
  name: 'VehicleDetailsForm',
  components: {
    VehicleDetailsSelection,
  },
  props: {
    vehicle: Object,
    savedVehicles: Array,
  },
  emits: ['input', 'savedVehicleSelected'],
  watch: {
    hasUpdatedSavedVehicleDetails() {
      this.handleInput('shouldSaveDetails', this.hasUpdatedSavedVehicleDetails);
    },
  },
  methods: {
    trans,
    vehDescription,
    sanitizeLicensePlate,
    sanitizeUserInput,
    handleToggleClick(value) {
      this.handleInput('shouldSaveDetails', value);
    },
    handleInput(key, value) {
      this.$emit('input', this.vehicleUid, key, value);
    },
    handleClick(savedVehicle) {
      this.$emit('savedVehicleSelected', savedVehicle.id, this.vehicleUid);
    },
    toggleSwitchText() {
      if (this.selectedSavedVehicle.id === NEW_VEHICLE_ID) return message('passDet.vehicles.saveVehicleSwitch');
      return message('generic.saveChangesToAccount');
    },
  },
  computed: {
    ...mapState({
      trips: (state) => state.bookingModule.trips,
      requiredDetails: (state) => state.bookingModule.vehicleDetailsRequirements,
      errors: (state) => state.validationModule.errors,
    }),
    vehicleUid() {
      return this.vehicle.uid;
    },
    hasDifferentDriversPerTrip() {
      return arrayUnique(this.vehicle.selectionsPerTrip.map((s) => s.driverIndex)).length > 1;
    },
    filteredSelectionsPerTrip() {
      return this.hasDifferentDriversPerTrip ? this.vehicle.selectionsPerTrip : this.vehicle.selectionsPerTrip.slice(0, 1);
    },
    hasLicencePlateError() {
      return this.errors.some((error) => error.name === `licencePlate-vehicle${this.vehicle.vehicleIndex + 1}`);
    },
    filteredSavedVehicles() {
      if ( !isUserProfileCompatibleVehicleType(this.vehicle.vehicleCategoryType)) return [];
      const vehiclesOfSameType = this.savedVehicles.filter((v) => v.type === this.vehicle.vehicleCategoryType || v.type === undefined);
      return vehiclesOfSameType.filter((v) => v.targetVehicleUid === this.vehicleUid || v.targetVehicleUid === '' || v.id === NEW_VEHICLE_ID);
    },
    savedVehiclesEnabled() {
      return getConfigurationValue('active_user_profile_menus', '').includes('vehicles');
    },
    showSavedVehiclesDropdown() {
      return this.filteredSavedVehicles.length > 0 && this.savedVehiclesEnabled;
    },
    defaultSavedVehicle() {
      return this.savedVehicles.find((v) => v.id === NEW_VEHICLE_ID);
    },
    selectedSavedVehicle() {
      return this.filteredSavedVehicles.find((v) => v.targetVehicleUid === this.vehicleUid) || this.defaultSavedVehicle;
    },
    hasUpdatedSavedVehicleDetails() {
      if (this.filteredSavedVehicles.length === 0) return false;
      if (this.selectedSavedVehicle.id === NEW_VEHICLE_ID) return false;
      if (this.vehicle.licencePlate !== this.selectedSavedVehicle.licencePlate) return true;
      if (this.vehicle.vehicleBrand !== this.selectedSavedVehicle.brand) return true;
      if (this.vehicle.vehicleModel !== this.selectedSavedVehicle.model) return true;
      return false;
    },
    travelEntityClass() {
      return this.showSavedVehiclesDropdown ? this.$style['travelerDetailsForm__main--save'] : this.$style.travelerDetailsForm__main;
    },
  },
};
</script>

<style module lang="scss">
@import './traveler-details-form.scss';
</style>
