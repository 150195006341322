<template>
  <div class="journey-with-date">
    <div :class="['journey-with-date__left text-ellipsis', headerLeftClass]">
      <JourneyTitle :ports="ports" paddingClass="pt5" />
    </div>
    <div :class="['journey-with-date__right', { 'journey-with-date__right--w-100': dateFullWidth }]">
      <button @click="previousDay" v-if="showArrows" :class="['journey-with-date__btn pl0', { 'journey-with-date__btn--disabled': previousDayDisabled }]">
        <i class="fh fh-left-arrow journey-with-date__arrow" aria-hidden="true"></i>
      </button>
      <div :class="['journey-with-date__date', { pr10: !showArrows }]">
        <span v-if="localActiveDate">{{ shortDate(localActiveDate) }}</span>
        <slot v-else></slot>
      </div>
      <button @click="nextDay" v-if="showArrows" class="journey-with-date__btn pr0" data-test="journeyWithDateNext">
        <i class="fh fh-right-arrow journey-with-date__arrow" aria-hidden="true"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { debounce } from '@/logic/helpers/debounce';
import { shortDate, portAlias } from '@/filters';
const debounced = debounce((fn) => fn(), 300);

export default {
  name: 'JourneyWithDate',
  props: {
    origin: String,
    destination: String,
    date: Object,
    showArrows: Boolean,
    dateFullWidth: Boolean,
  },
  data: () => ({
    localActiveDate: undefined,
  }),
  emits: ['nextDay', 'previousDay'],
  mounted() {
    this.localActiveDate = this.date;
  },
  methods: {
    shortDate,
    portAlias,
    nextDay() {
      this.localActiveDate = this.localActiveDate.clone().add(1, 'days');
      debounced(() => this.$emit('nextDay', this.localActiveDate));
    },
    previousDay() {
      this.localActiveDate = this.localActiveDate.clone().add(-1, 'days');
      debounced(() => this.$emit('previousDay', this.localActiveDate));
    },
  },
  computed: {
    /**
     * Returns true if the the previous day is before today
     */
    hasPreviousDayLimit() {
      return this.localActiveDate && this.localActiveDate.isAfter(this.$dayjs(), 'days') === false;
    },
    previousDayDisabled() {
      return this.hasPreviousDayLimit;
    },
    headerLeftClass() {
      if (!this.showArrows && this.isMobile) return 'journey-with-date__left--aligned-left';
      return '';
    },
    ports() {
      return [portAlias(this.origin), portAlias(this.destination)];
    },
  },
};
</script>
