import emitter from '@/emitter';
import { NAVIGATION_TABS } from '@/store/modules/navigation.module';
import { nextTick } from 'vue'

export default {
  created() {
    // navigate to a tab when the history changes
    window.addEventListener('popstate', () => {
      // TODO: Find a working model for transitioning between tabs without
      // risking with obsolete tab states (eg. selections that have not been
      // reset etc)
      let currentTabHref = this.activeTab;
      let newTabHref = window.location.href.split('#')[1];
      if (typeof newTabHref === 'undefined') {
        this.changeNavTab(NAVIGATION_TABS.SEARCH);
      } else if (newTabHref !== currentTabHref) {
        this.changeNavTab(newTabHref);
      }
    });

    emitter.$on('onFirstResultCollected', async () => {
      this.enableΝavTab(NAVIGATION_TABS.RESULTS);
      this.changeNavTab(NAVIGATION_TABS.RESULTS);
      await nextTick();
      this.setInitialLoad(false);
      this.removeJumbotrons();
    });

    emitter.$on('ItinerariesSelectionFinished', status => {
      this.setCompletedNavTab({ tabId: NAVIGATION_TABS.RESULTS, completed: status });
    });

    emitter.$on('SeatingPricingFinished', () => {
      this.setCompletedNavTab({ tabId: NAVIGATION_TABS.PASSENGERS, completed: true });
    });

    emitter.$on('onSearchPerform', () => {
      this.setCompletedNavTab({ tabId: NAVIGATION_TABS.SEARCH, completed: true });
      this.disableNavTabs([NAVIGATION_TABS.PASSENGERS, NAVIGATION_TABS.BOOK]);
    });

    emitter.$on('onSearchParametersChange', () => {
      this.setCompletedNavTab({ tabId: NAVIGATION_TABS.SEARCH, completed: false });
      this.disableNavTabs([NAVIGATION_TABS.RESULTS, NAVIGATION_TABS.PASSENGERS, NAVIGATION_TABS.BOOK]);
    });

    emitter.$on('onItineraryDeselection', () => {
      this.disableNavTabs([NAVIGATION_TABS.PASSENGERS, NAVIGATION_TABS.BOOK]);
    });

    emitter.$on('onTripDeselectedFromCart', () => {
      this.disableNavTabs([NAVIGATION_TABS.PASSENGERS, NAVIGATION_TABS.BOOK]);
    });

    emitter.$on('onSeatSelectionsChange', () => {
      this.setCompletedNavTab({ tabId: NAVIGATION_TABS.PASSENGERS, completed: false });
      this.disableNavTabs([NAVIGATION_TABS.BOOK]);
    });
  },
  methods: {
    // Remove companies, mobile app and footer sections
    removeJumbotrons() {
      try {
        let jumboContainer = document.getElementById('jumbo-container');
        if (jumboContainer) {
          jumboContainer.parentNode.removeChild(jumboContainer);
        }
      } catch (exception) {}
    }
  }
};
