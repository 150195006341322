// @ts-check
import { roundPrice } from '@/logic/helpers/utils'
// testing rebase. to be removed..
import { isCabinType, isDeckOrSeatType } from "../filterers/SeatOptionsFilterer"

/**
 * @returns {Accommodation} 
 */
export const inactiveAccommodation = () => {
  return {
    isActive: false,
  }
}

/**
 * @param {any} availability 
 * @param {any} details
 * 
 * @returns {Accommodation}
 */
export const adaptAccommodation = (availability, details) => {

  if (!details) {
    return inactiveAccommodation();
  }

  if (!isKnownAccommodationType(details)) {
    return inactiveAccommodation();
  }

  let accommodationAvailability = adaptAvailabilities(availability);
  if (accommodationAvailability.totalAvailability <= 0) {
    return inactiveAccommodation();
  }

  let accommodationPrices = adaptPrices(availability);
  let accommodationFlags = adaptFlags(availability, details);
  const isRefundable = !accommodationFlags.isOffer && !accommodationFlags.isDiscount;

  return {
    isActive: true,
    CompanyCode: details.company,
    ClassAbbr: availability.abbr,
    defaultFareCode: availability.FareCode || '',
    accommodationCapacity: details.accommodationCapacity,
    // Fixme: make this lowercase and more descriptive
    Category: details.classCategoryDescription || '',
    categoryCode: details.classCategory,
    categoryOrder: parseInt(details.categoryOrder),
    seatDescription: details.className || availability.abbr,
    seatDetails: details.classSubDescription,
    seatType: details.classType,
    isRefundable,
    ...accommodationFlags,
    ...accommodationPrices,
    ...accommodationAvailability
  };

}

/**
 * @param {any} availability 
 */
const adaptAvailabilities = (availability) => {
  let AvailMale = (availability.male > 0) ? availability.male : 0;
  let AvailFemale = (availability.female > 0) ? availability.female : 0;
  let AvailWhole = (availability.whole > 0) ? availability.whole : 0;
  let totalAvailability = Number(AvailWhole) + Number(AvailMale) + Number(AvailFemale)

  return {
    AvailMale: AvailMale,
    AvailFemale: AvailFemale,
    AvailWhole: AvailWhole,
    totalAvailability: totalAvailability
  }
}

/**
 * @param {any} availability 
 * @param {any} details 
 */
const adaptFlags = (availability, details) => {
  return {
    isDiscount: details.isDiscount,
    isOffer: availability.isOffer,
    isInfant: details.isInfant,
    isPet: details.isPet,
    isActive: true,
    singleBerth: details.singleBerth
  }
}


/**
 * FIXME: The `price` property coming from the API is sometimes a string
 * and not a number. Magically, things work, but we need to investigate and fix the issue.
 * @param {{ price: number; }} availability
 */
const adaptPrices = (availability) => {
  let formattedPrice = roundPrice(availability.price / 100.0, 2);
  return {
    price: availability.price,
    ClassAdultBasicPrice: formattedPrice,
    overallPrice: formattedPrice
  }
}

const isKnownAccommodationType = (accommodation) => {
  if (isDeckOrSeatType(accommodation.classType)) return true;
  if (isCabinType(accommodation.classType)) return true;
  return false;
}