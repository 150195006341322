<template>
  <div :class="$style.tripDetails__item">
    <div :class="itemHeaderClass">
      <JourneyTitle :ports="[trip.Details.DepStationName, trip.Details.ArrStationName]" />
      <span>{{ trip.timings.DepDateLocal }}, {{ trip.timings.DepTime }}<i v-if="trip.timings.overnight" class="fh fh-moon ml5"></i></span>
    </div>

    <div :class="$style['tripDetails__item-main']">
      <VesselCard :companyIconUrl="trip.Details.CompanyIcon" :vesselName="trip.Details.VesselName" :companyName="trip.Details.CompanyName" />
      <FhCartSeatingAnalysis :class="[$style['tripDetails__item-analysis'], 'pb14']" :hasError="hasTripPricingErrors" :trip="trip" :isWaiting="false" />
      <TripBoardingMethod :class="$style['tripDetails__item-boarding']" :boardingMethod="tripBoardingMethod" :headerBold="true" />
      <div :class="[$style['tripDetails__item-row'], 'text-medium pt16']">
        <span>{{ trans('tripprice') }}</span>
        <i v-if="hasTripPricingErrors" class="fh fh-notification"></i>
        <span v-else>{{ currencyStringPre(trip.OverallPrice) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import FhCartSeatingAnalysis from '@/components/cart/FhCartSeatingAnalysis';
import { mapGetters } from 'vuex';
import { trans, currencyStringPre } from '@/filters';
import { defineAsyncComponent } from 'vue';

const TripBoardingMethod = defineAsyncComponent(() => import('@/components/shared/TripBoardingMethod/TripBoardingMethod'));

export default {
  name: 'TripDetails',
  components: {
    TripBoardingMethod,
    FhCartSeatingAnalysis,
  },
  props: {
    trip: Object,
  },
  methods: {
    trans,
    currencyStringPre,
  },
  computed: {
    ...mapGetters({
      hasTripPricingErrors: 'bookingModule/hasTripPricingErrors',
    }),
    tripBoardingMethod() {
      return this.trip.boardingMethod;
    },
    itemHeaderClass() {
      const flavorClass = this.globalCobrandedVariant === 'easyferry' ? this.$style['tripDetails__item-header--gray-light'] : '';
      return [this.$style['tripDetails__item-header'], flavorClass];
    },
  },
};
</script>

<style module lang="scss">
@import './trip-details.scss';
</style>
