import { getNextDay } from '@/logic/helpers/dateUtils';
import { getPageLanguage } from '@/logic/helpers/utils';
import { eventAccommodationSearch } from '@/logic/services/events/createSearchEvents';
import portsAsParameters from '@/logic/helpers/portsToBookingComParameters';
import SearchUrlGenerator from '@/logic/models/SearchUrlGenerator';

export default {
  data() {
    return {
      baseSearchUrl: `https://www.booking.com/searchresults.en.html?aid=2247739&`,
      genericGreeceSearchUrl: `https://www.booking.com/searchresults.en.html?region=1716&aid=2247739&`,
      genericNonGreeceSearchUrl: `https://www.booking.com/index.html?aid=2247739&`
    };
  },
  computed: {
    checkoutDate() {
      return this.isRoundTrip || !this.isSingleTrip ? this.datesForSearch[1] : getNextDay(this.datesForSearch[0]);
    },
    accommodationParams() {
      return {
        country: this.portsForSearch[1].country,
        port: this.portsForSearch[1].LocationAbbr,
        port_name: this.portsForSearch[1].short,
        group_adults: this.passengersCount,
        checkin_monthday: this.datesForSearch[0].get('date'),
        checkin_month: this.datesForSearch[0].get('month') + 1,
        checkin_year: this.datesForSearch[0].get('year'),
        checkout_monthday: this.checkoutDate.get('date'),
        checkout_month: this.checkoutDate.get('month') + 1,
        checkout_year: this.checkoutDate.get('year'),
        locale: getPageLanguage(),
        durationInDays: this.checkoutDate.diff(this.datesForSearch[0], 'days'),
        mode: this.searchMode,
        no_rooms: 1
      };
    },
    newFerryhopperUrl() {
      const ports = this.portsForSearch.map(p => p.LocationAbbr);
      const dates = this.searchQueries.map(q => q.date);
      return new SearchUrlGenerator().createAutoSearchUrl(ports, dates, this.passengersCount, this.vehiclesCount, this.petsCount);
    },
    newBookingComUrl() {
      const { port, country, checkin_monthday, checkin_month, checkin_year, checkout_monthday, checkout_month, checkout_year, no_rooms, group_adults } = this.accommodationParams;

      let code = portsAsParameters.get(port);
      if (!code && country !== 'GR') return this.genericNonGreeceSearchUrl;

      let url;
      if (!code) url = new URL(this.genericGreeceSearchUrl);
      else url = new URL(`${this.baseSearchUrl}${code}`);

      let urlParams = new URLSearchParams(url.search);

      urlParams.append('checkin_monthday', checkin_monthday);
      urlParams.append('checkin_month', checkin_month);
      urlParams.append('checkin_year', checkin_year);
      urlParams.append('checkout_monthday', checkout_monthday);
      urlParams.append('checkout_month', checkout_month);
      urlParams.append('checkout_year', checkout_year);
      urlParams.append('no_rooms', no_rooms);
      urlParams.append('group_adults', group_adults);
      url.search = urlParams;

      return url;
    }
  },
  methods: {
    redirectToAccommodation() {
      if (!this.accommodationParams || !this.newFerryhopperUrl) return;

      //redirect to booking.com + open ferryhopper in new window
      if (process.env.NODE_ENV === 'development') {
        //link for development
        window.open(`${window.location.origin}${window.location.pathname}#results${this.newFerryhopperUrl}`, '_blank');
        window.location.replace(this.newBookingComUrl);
      } else {
        //all other environments
        window.open(`${window.location.origin}${this.newFerryhopperUrl}`, '_blank');
        window.location.replace(this.newBookingComUrl);
        this.sendAccommodationSearchEvent(this.accommodationParams);
      }
    },
    sendAccommodationSearchEvent(payload) {
      eventAccommodationSearch(
        {
          type_of_search: payload.mode,
          destination_port_name: payload.port_name,
          destination_port_code: payload.port,
          number_of_passengers: payload.group_adults
        },
        this.portsForSearch
      );
    }
  }
};
