import { isNull, isNullOrEmptyString, message } from "@/logic/helpers/utils"

export const TRIP_UNAVAILABLE_REASONS = {
  UNAVAILABLE_FROM_SOURCE: 'UNAVAILABLE_FROM_SOURCE',
  DEFAULT_ACCOMMODATION_MISSING: 'DEFAULT_ACCOMMODATION_MISSING',
  DEFAULT_DISCOUNT_MISSING: 'DEFAULT_DISCOUNT_MISSING',
  COMPANY_UNSUPPORTED: 'COMPANY_UNSUPPORTED',
  DATE_RESTRICTION: 'DATE_RESTRICTION',
  TIME_LIMIT: 'TIME_LIMIT',
  PAST_TRIP: 'PAST_TRIP',
  INACTIVE_VESSEL: 'INACTIVE_VESSEL',
  CONNECTION_WAIT_TIME: 'CONNECTION_WAIT_TIME',
  GENERIC: 'GENERIC'
}

export const getTripUnavailableReason = (tripUnavailableReason) => {
  
  if (isNull(tripUnavailableReason)) return undefined;

  const { type, message: tripUnavailableMessage } = tripUnavailableReason;
  
  const code = TRIP_UNAVAILABLE_REASONS.hasOwnProperty(type) ? type : TRIP_UNAVAILABLE_REASONS.GENERIC;
  const title = getUnavailableReasonTitle(type);
  const message = isNullOrEmptyString(tripUnavailableMessage) ? getUnavailableReasonMessage(type) : tripUnavailableMessage;

  return {
    code,
    title,
    message
  }
}


const getUnavailableReasonTitle = (type) => {
  switch (type) {
    case TRIP_UNAVAILABLE_REASONS.UNAVAILABLE_FROM_SOURCE:
      return message('unavailableTitle');
    case TRIP_UNAVAILABLE_REASONS.DEFAULT_ACCOMMODATION_MISSING:
      return  message('unavailableTitle');
    case TRIP_UNAVAILABLE_REASONS.DEFAULT_DISCOUNT_MISSING:
      return message('unsupportedTitle');
    case TRIP_UNAVAILABLE_REASONS.COMPANY_UNSUPPORTED:
      return message('unsupportedTitle');
    case TRIP_UNAVAILABLE_REASONS.DATE_RESTRICTION:
      return message('dateRestrictionTitle');
    case TRIP_UNAVAILABLE_REASONS.TIME_LIMIT:
      return message('timeLimitTitle');
    case TRIP_UNAVAILABLE_REASONS.PAST_TRIP:
      return message('pastTripTitle');
    case TRIP_UNAVAILABLE_REASONS.INACTIVE_VESSEL:
      return message('companyRestrictionTitle');
    default:
      return message('genericRestrictionTitle');
  }
}

const getUnavailableReasonMessage = (type) => {
  switch (type) {
    case TRIP_UNAVAILABLE_REASONS.UNAVAILABLE_FROM_SOURCE:
      return message('unavailabletooltip');
    case TRIP_UNAVAILABLE_REASONS.DEFAULT_ACCOMMODATION_MISSING:
      return  message('unavailableDesc');
    case TRIP_UNAVAILABLE_REASONS.DEFAULT_DISCOUNT_MISSING:
      return message('unsupportedDesc');
    default:
      return message('genericRestrictionDesc');
  }
}