<template>
  <div :class="$style.suggestion">
    <div v-if="showAlternateDates" :class="$style['suggestion__section']">
      <div class="pl5">{{ trans('suggestedAltDates') }}:</div>
      <div :class="$style['suggestion__section-alt-dates']">
        <button class="btn btn-link btn-md pr4" @click="updateDateSearch(closestAltBeforeActiveDateObj)">
          {{ closestAltBeforeActiveDate }}
        </button>
        <span v-if="hasAltBeforeActiveDate && hasAltAfterActiveDate" :class="$style['suggestion__section-alt-dates--separator']"> {{ trans('generic.or') }} </span>
        <button class="btn btn-link btn-md pl4" @click="updateDateSearch(closestAltAfterActiveDateObj)">
          {{ closestAltAfterActiveDate }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import ResultsAppSuggestion from '@/components/results/ResultsAppSuggestion';
import { trans } from '@/filters';

export default {
  name: 'ResultsAppSuggestion',
  extends: ResultsAppSuggestion,
  methods: {
    trans
  }
};
</script>

<style module lang="scss">
@import '../../../../assets/sass/results-tab/_results-app-suggestion.scss';
</style>
