import { merge, of } from 'rxjs';
import { getConfigurationNumber, getConfigurationValue } from '../../helpers/utils';

export const SEARCH_STATUS = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  COMPLETED: 'COMPLETED',
};

export const getIndirectTrips$ = (combinedCollection) => {
  return merge(...combinedCollection.getIndirect());
}

export const getDirectTrips$ = (combinedCollection) => {
  return merge(...combinedCollection.getVerifiedTrips());
}

let crsAlternativeDatesFeatureFlag = getConfigurationValue('feature_crs_alternates', false) === '1';
export const getAlternativeDays$ = (combinedCollection) => {
  if (!crsAlternativeDatesFeatureFlag) return of([]);
  return merge(...combinedCollection.getAlternativeDays());
}
