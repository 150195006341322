<template>
  <div class="summary-amount__wrapper">
    <h4 class="summary-amount__amount">€ {{ currency(price) }}</h4>
    <button v-if="globalCobrandedVariant === 'aegeanair'" class="btn btn-dark btn--full-width" @click="onBackClicked">
      {{ trans('back') }}
    </button>
  </div>
</template>

<script>
import { trans, currency } from '@/filters';

export default {
  name: 'BookingSummaryPrice',
  props: {
    price: {
      type: Number,
      required: true,
    },
  },
  emits: ['onBackClicked'],
  methods: {
    trans,
    currency,
    onBackClicked() {
      this.$emit('onBackClicked');
    },
  },
};
</script>
