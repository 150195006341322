import { isAdultType, isInfantType, isNotInfantType } from '@/logic/filterers/DiscountsFilterer'

/**
 * Get adults from a list of passengers
 * @param {import('@/logic/models/Models').PassengerModel[]} passengers 
 */
export const getAdults = (passengers) => passengers.filter(p => isAdultType(p.type));

/**
 * Returns the non-infant passengers for a trip, or directly from a list of passengers
 * @param {Object | array[]} tripOrPassengers
 */
export const getNonInfantPassengers = (tripOrPassengers) => {    
    let passengers = tripOrPassengers.passengers || tripOrPassengers;    
    return passengers.filter(passenger => isNotInfantType(passenger.type));
}

/**
 * Returns the infant passengers for a trip, or directly from a list of passengers
 * @param {Object | array[]} tripOrPassengers
 */
export const getInfantPassengers = (passengers) => passengers.filter(passenger => isInfantType(passenger.type));

/**
 * Returns true if a passenger is infant
 * @param {import('@/logic/models/Models').PassengerModel} passenger 
 */
export const isInfantPassenger = (passenger) => isInfantType(passenger.type);

/**
 * Returns true if a passenger is adult
 * @param {import('@/logic/models/Models').PassengerModel} passenger 
 */
export const isAdultPassenger = (passenger) => isAdultType(passenger.type);