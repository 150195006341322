/**
 * Returns the camping on board seat type for Minoan Lines
 */
const getCampingOnBoardAbbreviation = () => 'D';

/**
 * Returns true if the trip has camping on board availability for a given set of passengers
 */
export const hasCampingOnBoard = (trip, passengers, vehicles) => {
  // // allowed class for camping on board is DECK
  let classTypeForOnBoard = getCampingOnBoardAbbreviation();
  return trip.extraAttributes.hasCampingOnBoard && trip.passengerAccommodations.find(option => option.ClassAbbr === classTypeForOnBoard) !== undefined;
};

/**
 * Returns true if all passengers of a given trip have selections that can
 * be applied in camping on board
 */
export const isCampingOnBoardEnforcable = (trip, passengers, vehicles) => {
  let rules = [vehicles.length === 1];

  // return false if any rule is set to false
  return rules.find(r => r === false) === undefined;
};

/**
 * Returns messages related to the camping on board - all inclusive
 * offer of ANSF
 */
export const getMessages = () => {
  return {
    title: 'cobstandardtitle',
    checkbox: 'cobstandardcheckbox',
    warning: 'cobstandardwarning',
    blocking: 'cobstandardwarningvehicles',
    slug: 'camping-on-board'
  };
};
