/* eslint-disable no-unused-vars */
import settings from '@/settings';
import { isDatesOrderInvalid } from '../../logic/helpers/dateUtils';
import { createPassengersString, createVehiclesString } from '../../logic/generators/phrases';
import { getConfigurationArray, getPageLanguage } from '../../logic/helpers/utils';
import { storageGet, storageSave } from '@/logic/services/storage/Storage';

let petsBookingLocales = getConfigurationArray('addon_pets_booking', []);

export const searchModule = {
  namespaced: true,
  state: {
    passengers: 1,
    vehicles: 0,
    pets: 0,
    tripsCount: 1,
    roundTrip: false,
    mode: 'single',
    selectedPorts: [],
    selectedDates: [],
    searchQueries: [],
    isAutoSearch: false,
    isPetsBookingActivated: petsBookingLocales.includes(getPageLanguage()),
    isSearchingForAccommodation: storageGet('fh.isSearchingForAccommodation', true) || false
  },
  getters: {
    isRoundTripSearch: state => {
      if (state.searchQueries.length !== 2) return false;
      return state.searchQueries[0].destination === state.searchQueries[1].origin &&
        state.searchQueries[1].destination === state.searchQueries[0].origin;
    },
    isAutoSearch: state => state.isAutoSearch,
    searchMode: state => state.mode,
    isRoundTrip: state => state.roundTrip,
    getSelectedDates: state => state.selectedDates,
    canAddDestination: state => state.tripsCount === settings.limits.SEARCH_MAX_TRIPS,
    canRemoveDestination: state => state.mode === 'multi' && state.tripsCount > 2,
    isInvalidDatesSequence: state => {
      return isDatesOrderInvalid(state.selectedDates.slice(0, state.tripsCount));
    },
    tripsCount: state => state.tripsCount,
    destinationsCount: state => (state.mode === 'multi' ? state.tripsCount + 1 : 2),
    passengersCount: state => state.passengers,
    passengersString: state => createPassengersString(state.passengers),
    vehiclesCount: state => state.vehicles,
    vehiclesString: state => createVehiclesString(state.vehicles),
    petsCount: state => state.pets,
    maxPassengersReached: state => state.passengers >= settings.limits.MAXIMUM_PASSENGERS,
    maxVehiclesReached: state => state.vehicles >= settings.limits.MAXIMUM_VEHICLES,
    maxPetsReached: state => state.pets >= settings.limits.MAXIMUM_PETS,
    minPassengersReached: state => state.passengers <= settings.limits.MINIMUM_PASSENGERS,
    minVehiclesReached: state => state.vehicles <= settings.limits.MINIMUM_VEHICLES,
    minPetsReached: state => state.pets <= settings.limits.MINIMUM_PETS,
    selectedPorts: state => state.selectedPorts,
    selectedPortsAbbr: state => state.selectedPorts.map(p => p ? p.LocationAbbr : '')
  },
  mutations: {
    increasePassengerCount(state) {
      if (state.passengers < settings.limits.MAXIMUM_PASSENGERS) state.passengers++;
    },
    decreasePassengerCount(state) {
      if (state.passengers > settings.limits.MINIMUM_PASSENGERS) state.passengers--;
    },
    setPassengersCount(state, count) {
      if (count >= settings.limits.MINIMUM_PASSENGERS && count <= settings.limits.MAXIMUM_PASSENGERS) state.passengers = count;
    },
    increaseVehicleCount(state) {
      if (state.vehicles < settings.limits.MAXIMUM_VEHICLES) state.vehicles++;
    },
    decreaseVehicleCount(state) {
      if (state.vehicles > settings.limits.MINIMUM_VEHICLES) state.vehicles--;
    },
    setVehiclesCount(state, count) {
      if (count >= settings.limits.MINIMUM_VEHICLES && count <= settings.limits.MAXIMUM_VEHICLES) state.vehicles = count;
    },
    increasePetCount(state) {
      if (!state.isPetsBookingActivated) return;
      if (state.pets < settings.limits.MAXIMUM_PETS) state.pets++;
    },
    decreasePetCount(state) {
      if (state.pets > settings.limits.MINIMUM_PETS) state.pets--;
    },
    setPetsCount(state, count) {
      if (!state.isPetsBookingActivated) return;
      if (count >= settings.limits.MINIMUM_PETS && count <= settings.limits.MAXIMUM_PETS) state.pets = count;
    },
    addTrip(state) {
      if (state.tripsCount < settings.limits.SEARCH_MAX_TRIPS) state.tripsCount++;
    },
    removeTrip(state) {
      if (state.tripsCount > settings.limits.SEARCH_MIN_TRIPS) state.tripsCount--;
    },
    setRoundTrip(state, roundTrip) {
      state.roundTrip = roundTrip;
    },
    setSearchMode(state, mode) {
      state.mode = mode;
    },
    setTripsCount(state, count) {
      state.tripsCount = count;
    },
    setSelectedPorts(state, ports) {
      state.selectedPorts = ports
    },
    setSelectedPort(state, { port, portIndex }) {
      state.selectedPorts = state.selectedPorts.map((p, pIndex) => {
        return pIndex === portIndex ? port : p;
      });
    },
    setSelectedDates(state, dates) {
      state.selectedDates = dates
    },
    setSelectedDate(state, { date, dateIndex }) {
      state.selectedDates = state.selectedDates.map((d, dIndex) => {
        return dIndex === dateIndex ? date : d;
      });
    },
    setSearchQueries(state, queries) {
      state.searchQueries = queries
    },
    setAutoSearch(state, autoSearch) {
      state.isAutoSearch = autoSearch;
    },
    setSearchingForAccommodation(state, value) {
      state.isSearchingForAccommodation = value;
      storageSave('fh.isSearchingForAccommodation', value, true);
    }
  },
  actions: {
    addTrip({ commit }) {
      commit('addTrip');
    },
    removeTrip({ commit }) {
      commit('removeTrip');
    },
    increasePassengerCount({ commit }) {
      commit('increasePassengerCount');
    },
    decreasePassengerCount({ commit }) {
      commit('decreasePassengerCount');
    },
    increaseVehicleCount({ commit }) {
      commit('increaseVehicleCount');
    },
    decreaseVehicleCount({ commit }) {
      commit('decreaseVehicleCount');
    },
    increasePetCount({ commit }) {
      commit('increasePetCount');
    },
    decreasePetCount({ commit }) {
      commit('decreasePetCount');
    },
    setRoundTrip({ commit }, roundTrip) {
      commit('setRoundTrip', roundTrip);
      commit('setTripsCount', roundTrip ? 2 : 1);
    },
    setSearchMode({ commit }, mode) {
      commit('setSearchMode', mode);
    },
    setTripsCount({ commit }, count) {
      commit('setTripsCount', count);
    },
    setTripModeSingle({ commit, state }) {
      commit('setSearchMode', 'single');
      commit('setTripsCount', state.roundTrip ? 2 : 1);
    },
    setTripModeMulti({ commit }) {
      commit('setSearchMode', 'multi');
      commit('setTripsCount', 2);
    },
    setPassengersCount({ commit }, count) {
      commit('setPassengersCount', count);
    },
    setVehiclesCount({ commit }, count) {
      commit('setVehiclesCount', count);
    },
    setPetsCount({ commit }, count) {
      commit('setPetsCount', count);
    },
    setSelectedPorts({ commit }, ports) {
      commit('setSelectedPorts', ports);
    },
    setSelectedPort({ commit }, { port, portIndex }) {
      commit('setSelectedPort', { port, portIndex });
    },
    setSelectedDates({ commit }, dates) {
      commit('setSelectedDates', dates);
    },
    setSelectedDate({ commit, state }, { date, dateIndex }) {
      commit('setSelectedDate', { date, dateIndex });
      if (state.mode === 'single' && dateIndex === 1) {
        commit('setRoundTrip', true);
        commit('setTripsCount', 2);
      }
    },
    async initializeSearch({ commit, dispatch }, searchState) {
      const { isAutoSearch, isRoundTrip, selectedPorts, selectedDates, searchMode, tripsCount, passengers, vehicles, pets } = searchState;
      await dispatch('setRoundTrip', isRoundTrip);
      await dispatch('setSelectedPorts', selectedPorts);
      await dispatch('setSelectedDates', selectedDates);
      await dispatch('setSearchMode', searchMode);
      await dispatch('setTripsCount', tripsCount);
      await dispatch('setPassengersCount', passengers);
      await dispatch('setVehiclesCount', vehicles);
      await dispatch('setPetsCount', pets);
      commit('setAutoSearch', isAutoSearch);
    },
    setSearchQueries({ commit }, queries) {
      commit('setSearchQueries', queries);
    },
    setSearchingForAccommodation({ commit }, value) {
      commit('setSearchingForAccommodation', value);
    }
  }
};
