import { setDocumentStyle } from '@/logic/dom/helpers';

export default {
  mounted() {
    const fhAppContainer = document.getElementById('fh-main-app-container');
    if (!fhAppContainer) return
    const fhAppContainerRect = fhAppContainer.getBoundingClientRect();
    const fhAppContainerTop = fhAppContainerRect.top;
    setDocumentStyle('--fh-app-container-top', `${fhAppContainerTop}px`);
  }
};
