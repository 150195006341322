import emitter from '@/emitter';
import { cloneDeep as _cloneDeep } from 'lodash-es';
import { eventStartSearch } from '../../../logic/services/events/createSearchEvents';
import { saveLastSearch } from '../../../logic/services/storage/saveLastSearch';
import { mapActions, mapGetters, mapState } from 'vuex';
import { createSearchQueries } from '../../../logic/models/search/createSearchQueries';
import { SEARCH_ERROR_TYPES } from '@/logic/models/search/createSearchQueriesError';
import { getConfigurationValue } from '@/logic/helpers/utils';

export default {
  data: () => ({
    inputDates: [],
    isDateError: false,
    shouldShowErrors: false,
    searchQueries: [],
    error: {
      errorId: 0,
      errorMessage: '',
      searchErrorType: SEARCH_ERROR_TYPES.NONE
    }
  }),
  created() {
    emitter.$on('onSearchParametersChange', () => {
      this.updateErrorBag();
    });
  },
  computed: {
    ...mapState({
      isSearchingForAccommodation: state => state.searchModule.isSearchingForAccommodation,
      isPetsBookingActivated: state => state.searchModule.isPetsBookingActivated
    }),
    ...mapGetters({
      tripsCount: 'searchModule/tripsCount',
      searchMode: 'searchModule/searchMode',
      isRoundTrip: 'searchModule/isRoundTrip',
      isAutoSearch: 'searchModule/isAutoSearch',
      passengersCount: 'searchModule/passengersCount',
      vehiclesCount: 'searchModule/vehiclesCount',
      petsCount: 'searchModule/petsCount',
      isActiveTabSearch: 'navigationModule/isActiveTabSearch'
    }),
    accommodationServiceFlag() {
      // searching for accommodation is only available via ferryhopper.com
      // 0: off, 1: mobile, 2: desktop, 3: all
      return getConfigurationValue('showAccommodationSwitch', '0');
    },
    showAccommodationSwitchForDesktop() {
      return this.accommodationServiceFlag === '2' || this.accommodationServiceFlag === '3' ? true : false;
    },
    showAccommodationSwitchForMobile() {
      return this.accommodationServiceFlag === '1' || this.accommodationServiceFlag === '3' ? true : false;
    },
    portsForSearch() {
      let selectedPorts = _cloneDeep(this.selectedPorts);
      if (this.searchMode === 'multi') return selectedPorts.slice(0, this.tripsCount + 1);
      return [selectedPorts[0], selectedPorts[1], selectedPorts[0]].slice(0, this.tripsCount + 1);
    },
    datesForSearch() {
      let selectedDates = _cloneDeep(this.selectedDates);
      if (this.searchMode === 'multi') return selectedDates.slice(0, this.tripsCount);
      return selectedDates.slice(0, this.tripsCount);
    },
    errorBag() {
      const searchValidation = createSearchQueries(this.portsForSearch, this.datesForSearch, this.tripsCount, this.searchMode);
      this.searchQueries = searchValidation.searchQueries;
      return searchValidation.errors.length !== 0 ? searchValidation.errors : [];
    },
    shouldRedirectToAccommodation() {
      // if user accessed step 2 directly, don't direct to booking.com
      // or it would cause an infinite loop
      if (this.isAutoSearch) return false;

      if (!this.isSearchingForAccommodation || this.accommodationServiceFlag === '0') return false;

      if (this.isMobile && this.accommodationServiceFlag === '2') return false;

      if (!this.isMobile && this.accommodationServiceFlag === '1') return false;

      return true;
    }
  },
  methods: {
    ...mapActions({
      setSearchingForAccommodation: 'searchModule/setSearchingForAccommodation'
    }),
    updateErrorBag() {
      // Error is set only upon clicking on the search button. If there is no error, that means that search
      // has not been clicked, so do nothing
      if (this.error.errorId === 0) return;
      // If the local error is inside the errorBag, that means that it is not resolved, so do nothing
      const localErrorInErrorBag = this.errorBag.some(err => err.errorId === this.error.errorId);
      if (localErrorInErrorBag) return;
      // In this case, we have a new error which means that the old one has been resolved, so remove it
      this.resetError();
    },
    resetError() {
      this.error = {
        errorId: 0,
        errorMessage: '',
        searchErrorType: SEARCH_ERROR_TYPES.NONE
      };
    },
    onSearchClicked() {
      this.isWaiting = true;

      // on search validation error, show the error, else do nothing
      if (this.errorBag.length > 0) {
        this.isWaiting = false;
        this.error = this.errorBag[0];
        emitter.$emit('onSearchError');
        if (!this.isMobile) this.updateArrowPosition();
        return;
      }

      if (this.shouldRedirectToAccommodation) {
        this.redirectToAccommodation(this.accommodationParams, this.newFerryhopperUrl);
        return;
      }

      this.$store.dispatch('searchModule/setSearchQueries', this.searchQueries).then(() => {
        // raise event for search
        emitter.$emit('onSearchPerform');
        // save the request for future use
        saveLastSearch(this.portsForSearch, this.datesForSearch);
        // create google analytics event for the current search
        eventStartSearch(this.searchMode, this.searchQueries, this.passengersCount);
      });
    }
  }
};
