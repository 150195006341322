<template>
  <div :class="$style.fhBookingCart">
    <div :class="$style.fhBookingCart__header">
      <h5 :class="['m0', $style['fhBookingCart__header-title']]">{{ trans('passDet.priceBreakdown.heading') }}</h5>
    </div>

    <div :class="[$style.fhBookingCart__main, { [$style['fhBookingCart--waiting']]: isWaiting }]" ref="bookingCartMain">
      <div v-if="hasTripPricingErrors && !isGridSingleCol">
        <transition-group tag="div" name="height-fade-out" appear>
          <Notification v-for="(error, errorIndex) in tripPricingErrors" class="error-wrapper mb4" :key="`${error.code}-${errorIndex}`" :message="error.message" />
        </transition-group>
      </div>

      <div v-for="trip in trips" :key="trip.id" :class="tripClass">
        <div :class="$style['fhBookingCart__trip-info']">
          <img class="mr8" height="36" width="36" :src="trip.Details.CompanyIcon" alt="Company icon" />
          <div :class="$style['fhBookingCart__trip-info-text']">
            <JourneyTitle :ports="[trip.Details.DepStationName, trip.Details.ArrStationName]" :classlist="$style['fhBookingCart__trip-stations']" />

            <div :class="$style['fhBookingCart__trip-meta']">
              <span>{{ trip.timings.DepDateLocal }}, {{ trip.timings.DepTime }}<i v-if="trip.timings.overnight" class="fh fh-moon ml5"></i></span>
              <i v-if="hasTripPricingErrors" class="fh fh-notification"></i>
              <span v-else>{{ currencyStringPre(trip.OverallPrice) }}</span>
            </div>
          </div>
        </div>
      </div>

      <div :class="viewDetailsClass" @click="$emit('showTripDetailsModal')">
        {{ trans('passDet.cart.tripDetails.viewTripDetailsCTA') }}
      </div>

      <div :class="isGridSingleCol ? 'py16' : 'pt16'" v-if="!hasTripPricingErrors">
        <PricesBreakdownItem :text="trans('ticketspricinglabel')" :price="overallPriceForTrips" />
        <PricesBreakdownItem v-if="showServiceFeePrice" :text="trans('tab4servicefee')" :price="serviceFeeCost" />
        <PricesBreakdownItem v-if="refundProtectSelected" :text="trans('passDet.cart.prices.refProt')" :price="refundProtectCost" :hasError="hasRefundProtectError" />
        <PricesBreakdownItem v-if="travelInsuranceSelected" :text="trans('passDet.cart.prices.travInsIntam')" :price="travelInsuranceCost" :hasError="hasTravelInsuranceError" />
        <PricesBreakdownItem v-if="supportPlusSelected" :text="trans('generic.supportPlus')" :price="supportPlusCost" :hasError="hasSupportPlusError" />
        <PricesBreakdownItem v-if="flexiSelected" :text="trans('generic.flexiService')" :price="flexiCost" :hasError="hasFlexiError" />
        <PricesBreakdownItem v-if="!isGridSingleCol && overallDiscountForTrips > 0" :text="trans('passDet.cart.priceBreakdown.discountCoupon')" :price="overallDiscountForTrips" :isDiscount="true" />
      </div>
    </div>
    <div class="booking-flow-box__row px12 py16" v-if="!isGridSingleCol">
      <span class="text-medium">{{ trans('overallprice') }}<InfoModalItem :slug="'currency-rate'" :class="'ml8'" @openInfoModalClicked="openInfoModalClicked" /></span>
      <i v-if="hasTripPricingErrors" class="fh fh-notification"></i>
      <span v-else class="text-medium">{{ currencyStringPre(expectedFinalPrice) }}</span>
    </div>
  </div>
</template>

<script>
import PricesBreakdownItem from '@/components/shared/PricesBreakdown/PricesBreakdownItem';
import { scrollToTopOfElement } from '@/logic/dom/scrollTo';
import { mapGetters, mapState } from 'vuex';
import { eventBookingModalOpened } from '@/logic/services/events/createBookingEvents';
import { trans, currencyStringPre } from '@/filters';

export default {
  name: 'FhBookingCart',
  components: {
    PricesBreakdownItem,
  },
  props: {
    isWaiting: Boolean,
    isGridSingleCol: Boolean,
  },
  emits: ['showTripDetailsModal'],
  methods: {
    trans,
    currencyStringPre,
    openInfoModalClicked() {
      eventBookingModalOpened('currency_rate');
    }
  },
  computed: {
    ...mapState({
      trips: (state) => state.bookingModule.trips,
      serviceFeeCost: (state) => state.bookingModule.serviceFee.serviceFeeCost,
      refundProtectError: (state) => state.bookingModule.refundProtect.error,
      travelInsuranceError: (state) => state.bookingModule.travelInsurance.error,
      supportPlusError: (state) => state.bookingModule.supportPlus.error,
      flexiError: (state) => state.bookingModule.flexi.error,
    }),
    ...mapGetters({
      overallDiscountForTrips: 'bookingModule/overallDiscountForTrips',
      overallPriceForTrips: 'bookingModule/overallPriceForTrips',
      expectedFinalPrice: 'bookingModule/expectedFinalPrice',
      refundProtectSelected: 'bookingModule/refundProtectSelected',
      refundProtectCost: 'bookingModule/refundProtectCost',
      hasRefundProtectError: 'bookingModule/hasRefundProtectError',
      travelInsuranceSelected: 'bookingModule/travelInsuranceSelected',
      travelInsuranceCost: 'bookingModule/travelInsuranceCost',
      hasTravelInsuranceError: 'bookingModule/hasTravelInsuranceError',
      supportPlusSelected: 'bookingModule/supportPlusSelected',
      flexiSelected: 'bookingModule/flexiSelected',
      supportPlusCost: 'bookingModule/supportPlusCost',
      flexiCost: 'bookingModule/flexiCost',
      hasSupportPlusError: 'bookingModule/hasSupportPlusError',
      hasFlexiError: 'bookingModule/hasFlexiError',
      tripPricingErrors: 'bookingModule/tripPricingErrors',
      hasTripPricingErrors: 'bookingModule/hasTripPricingErrors',
    }),
    showServiceFeePrice() {
      return this.serviceFeeCost > 0.0;
    },
    viewDetailsClass() {
      let cobrandedClass = this.globalCobrandedVariant === 'easyferry' ? this.$style['fhBookingCart__view-details--easyferry'] : '';
      return [this.$style['fhBookingCart__view-details'], cobrandedClass];
    },
    tripClass() {
      let cobrandedClass = this.globalCobrandedVariant === 'easyferry' ? this.$style['fhBookingCart__trip--gray-light'] : '';
      return [this.$style.fhBookingCart__trip, cobrandedClass];
    },
  },
  watch: {
    hasTripPricingErrors() {
      const bookingCartMain = this.$refs.bookingCartMain;
      if (!bookingCartMain) return;
      if (this.hasTripPricingErrors) scrollToTopOfElement(bookingCartMain, 'smooth');
    },
  },
};
</script>

<style module lang="scss">
@import './fh-booking-cart.scss';
</style>
