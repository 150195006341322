<template>
  <span :class="['fh-dropdown-option', `fh-dropdown-option-${index}`]" @click="handleClick" data-test="fhDropdownOption">{{ text }}</span>
</template>

<script>
export default {
  name: 'FhDropdownOption',
  props: {
    text: String,
    index: Number,
  },
  emits: ['click'],
  methods: {
    handleClick() {
      this.$emit('click');
    },
  },
};
</script>