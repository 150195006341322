
/**
 * @typedef LoyaltyCard
 * 
 * @property {String} schemeName
 * @property {String} docName
 * @property {String} loyaltyNumber
 * @property {boolean} isEnabled
 * @property {String} STATUS
 */

import { createOfferValidationPayload } from "../common/passengerOffers/createOfferValidationPayload";
import { createPassengerOffer, PASSENGER_OFFER_STATUS } from "../common/passengerOffers/passengerOffer";

/**
 * @returns {LoyaltyCard}
 */
export const createPassengerLoyaltyOffer = () => ({
  schemeName: '',
  loyaltyNumber: '',
  ...createPassengerOffer()
});


/**
 * Create loyalty card validation payload
 */
export const createLoyaltyValidationPayload = (loyaltyNumber, trip, passenger) => createOfferValidationPayload(
  { loyaltyNumber: loyaltyNumber },
  trip, passenger
)

/**
 * Returns the loyalty state that should be enforced to all passengers of a trip,
 * depending on the latest change, the scheme specifics (allowSingleUse) and each passengers current state
 * @param {Object} loyaltyChange 
 * @param {Object} loyaltyScheme 
 * @param {Object[]} passengers 
 */
export const getEnforcedLoyaltyState = (loyaltyChange, loyaltyScheme, passengers) => (passengerLoyalty) => {
  const shouldEnforceCommonState = !loyaltyScheme.allowSingleUse;
  const shouldDisableAll = loyaltyChange.isEnabled === false;

  if (shouldEnforceCommonState && shouldDisableAll) return false;

  const shouldEnableAll = !!passengers.find(p => p.loyalty.isEnabled);
  if (shouldEnforceCommonState && shouldEnableAll) return true;

  return passengerLoyalty.isEnabled;
}

export const applyEnforcedLoyaltyDiscount = (loyalty, enforcedStateProvider) => {
  const isEnabled = enforcedStateProvider(loyalty);
  const newNumber = isEnabled ? loyalty.loyaltyNumber : '';
  const newStatus = isEnabled ? loyalty.STATUS : PASSENGER_OFFER_STATUS.UNDETERMINED;
  return {
    ...loyalty,
    STATUS: newStatus,
    loyaltyNumber: newNumber,
    isEnabled
  }
}

  
