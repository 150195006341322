<template>
  <div :class="$style.fhCart">
    <div :class="$style.fhCart__header">
      <h5 :class="['m0', $style['fhCart__header-title']]">{{ trans('cart.heading') }}</h5>
    </div>
    <div :class="[$style.fhCart__main, cartWaitingClass]" ref="seatingCartMain">
      <div :class="$style.fhCart__items">
        <FhSeatingCartItem v-for="(trip, tripIndex) in trips" :key="'fh-seating-cart-item-' + tripIndex" :trip="trip" :isWaiting="isWaiting" />
      </div>
      <EarlyBooking v-if="hasEarlyBooking" :busy="isOccupied" @getEarlyBookingTerms="getEarlyBookingTerms" />
    </div>
    <div :class="$style.fhCart__footer" data-test="fhCartFooter">
      <div v-show="hasVerifiedAvailability" :class="$style.fhCart__price">
        <span :class="[$style.fhCart__overall, $style.fhCart__description]">{{ trans('overallprice') }}</span>
        <span :class="[$style.fhCart__overall, $style.fhCart__sum]">€ {{ currency(overall) }}</span>
      </div>
      <FhCta v-show="hasVerifiedAvailability" id="seatingCartCta" :showArrow="true" @click="onCheckoutClick" data-test="seatingCartCta">{{ trans('proceedtobooking') }}</FhCta>
      <div v-show="!hasVerifiedAvailability">
        <FhCta :isLadda="true" :isWaiting="isWaiting" :disabled="isPricingDisabled" @click="onPricingRequested">{{ trans('checkprices') }}</FhCta>
        <FhCartNotification v-if="hasConnectionError" :message="trans('connectionerror')" :warning="true" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FhSeatingCartItem from '@/components/cart/SeatingCart/FhSeatingCartItem';
import FhCartNotification from '@/components/cart/FhCartNotification';
import FhCartMixin from '@/components/cart/FhCartMixin';
import { scrollToTopOfElement, scrollToElementId } from '@/logic/dom/scrollTo';
import { nextTick } from 'vue';
import { trans, currency } from '@/filters';
import { defineAsyncComponent } from 'vue';

const EarlyBooking = defineAsyncComponent(() => import('@/components/seats/booking-offers/EarlyBooking'));

export default {
  name: 'FhSeatingCart',
  mixins: [FhCartMixin],
  components: {
    FhSeatingCartItem,
    EarlyBooking,
    FhCartNotification,
  },
  props: {
    trips: Array,
    isWaiting: Boolean,
    isOccupied: Boolean,
    overall: Number,
    hasVerifiedAvailability: Boolean,
    isPricingDisabled: Boolean,
    hasConnectionError: Boolean
  },
  emits: ['onCheckoutClick', 'onPricingRequested', 'getEarlyBookingTerms'],
  computed: {
    ...mapGetters({      
      hasEarlyBooking: 'ticketConfigurationModule/hasEarlyBooking'
    }),
    cartWaitingClass() {
      return this.isWaiting ? this.$style['fhCart--waiting'] : '';
    },
  },
  methods: {
    trans,
    currency,
    onCheckoutClick() {
      this.$emit('onCheckoutClick');
    },
    onPricingRequested() {
      this.$emit('onPricingRequested');
    },
    getEarlyBookingTerms() {
      this.$emit('getEarlyBookingTerms');
    },
  },
  watch: {
    hasVerifiedAvailability() {
      if (!this.hasVerifiedAvailability) return;
      if (this.isMobile) nextTick(() => scrollToElementId('seatingCartCta', 'auto'));
      if (this.$refs.seatingCartMain !== undefined) scrollToTopOfElement(this.$refs.seatingCartMain, 'smooth');
    },
  },
};
</script>

<style module lang="scss">
@import '../fh-cart.scss';
</style>
