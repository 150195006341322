export const EVENT_TRAVELERS_CLICKED_ACTIONS = {
  ADDED: 'added',
  REMOVED: 'removed'
};

export const EVENT_TRAVELERS_ENTITIES = {
  PASSENGER: 'Passenger',
  VEHICLE: 'Vehicle',
  PET: 'Pet'
};
