<template>
  <div :id="`aegean-action-bar-${searchIndex}`" class="aegean-action-bar" :class="{ 'aegean-action-bar--mobile': isMobile }">
    <span class="aegean-action-bar__title" v-if="!isMobile">
      <JourneyTitle :ports="ports" classlist="text-ellipsis" />
    </span>

    <button class="btn flex-button aegean-action-bar__button" :disabled="disabled || hasPreviousDayLimit" @click="previousDay">
      <i class="fh fh-left-arrow" aria-hidden="true"></i>
    </button>

    <span class="aegean-action-bar__secondary_title">
      <JourneyTitle :ports="ports" classlist="text-ellipsis text-uppercase" v-if="isMobile" />
      <span>{{ shortDate(date) }}</span>
    </span>

    <button class="btn flex-button aegean-action-bar__button" :disabled="disabled" @click="nextDay">
      <i class="fh fh-right-arrow" aria-hidden="true"></i>
    </button>
  </div>
</template>

<script>
import { getPageLanguage, message } from '@/logic/helpers/utils';
import PortsRepository from '@/logic/repositories/PortsRepository';
import JourneyWithDate from '@/components/shared/JourneyWithDate';
import { shortDate } from '@/filters';

export default {
  name: 'aegean-section-bar-results',
  extends: JourneyWithDate,
  props: {
    searchIndex: Number,
    disabled: Boolean,
  },
  methods: {
    shortDate,
  },
  computed: {
    prettyTitle: function () {
      let originName = PortsRepository.getPort(this.origin);
      originName = (originName && originName.short) || this.origin;

      let destinationName = PortsRepository.getPort(this.destination);
      destinationName = (destinationName && destinationName.short) || this.destination;

      if (getPageLanguage() === 'el') {
        return `${originName} - ${destinationName}`;
      }
      return `${message('from')} ${originName} ${message('to')} ${destinationName}`;
    },
  },
};
</script>
