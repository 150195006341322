import { isPricableOffer } from '../../BL/common/passengerOffers/passengerOffer';
import { isNotInfantType } from '../../filterers/DiscountsFilterer';

//@ts-check

/**
 * @typedef PricingPerPassenger
 * @property {string} ClassAbbr
 * @property {number} PassengerIndex
 * @property {string} PassType
 * @property {string} DiscountCode
 * @property {string} FareCode
 * @property {string} Loyalty
 */

/**
 *
 * @param {string} abbreviation The seat/cabin abbreviation
 * @param {number} passengerIndex The passenger index
 * @param {string} passType The passenger passType code for this seat (PassType prop of DiscountDto)
 * @param {string} discountCode The passenger discountCode for this seat (DiscountCode prop of DiscountDto)
 * @param {string} fareCode  The fare code for this seat (if required)
 * @param {import('@/logic/BL/loyaltyCards').LoyaltyCard} loyaltyCard The loyalty card details of the passenger - optional
 *
 * @returns {PricingPerPassenger}
 */
export const createPricingPerPassenger = (
  passenger,
  useCabinAlias
) => {

  const { companySpecificDiscount, companySpecificAccommodation } = passenger;

  return {
    ClassAbbr: companySpecificAccommodation.ClassAbbr,
    PassengerIndex: passenger.passengerIndex,
    PassType: companySpecificDiscount.PassType,
    Discount: useCabinAlias ? companySpecificDiscount.CabinAlias : companySpecificDiscount.DiscountCode,
    DiscountCategory: companySpecificDiscount.DiscountCategory,
    FareCode: companySpecificAccommodation.defaultFareCode,
    CabinOccupancyMode: '',
    Loyalty: getPassengerLoyalty(passenger, companySpecificDiscount),
    ResidenceNumber: getPassengerResidenceNumber(passenger)
  };
}


const getPassengerResidenceNumber = (passenger) => {
  return isPricableOffer(passenger.residenceDiscount)
    ? passenger.residenceDiscount.residenceNumber
    : '';
}

const getPassengerLoyalty = (passenger, companySpecificDiscount) => {
  return isPricableOffer(passenger.loyalty) && isNotInfantType(companySpecificDiscount.DiscountCategory)
    ? passenger.loyalty.loyaltyNumber
    : '';
}

