<template>
  <div>
    <div :class="[$style.itinerarySkeleton, skeletonModifier, [isMobile ? 'mb10' : 'mb12']]" v-for="(item, index) in items" :key="index">
      <div :class="[$style.itinerarySkeleton__left, 'skeleton-animation']">
        <div :class="$style.itinerarySkeleton__row">
          <div :class="[$style.itinerarySkeleton__item, $style['itinerarySkeleton__item--short'], $style['itinerarySkeleton__item--md']]" />
        </div>
        <div :class="$style.itinerarySkeleton__row">
          <div :class="[$style.itinerarySkeleton__item, $style['itinerarySkeleton__item--tall'], $style['itinerarySkeleton__item--sm'], 'mr8']" />
          <div :class="$style.itinerarySkeleton__column">
            <div :class="['mb4', $style.itinerarySkeleton__item, $style['itinerarySkeleton__item--short'], $style['itinerarySkeleton__item--md']]" />
            <div :class="[$style.itinerarySkeleton__item, $style['itinerarySkeleton__item--short'], $style['itinerarySkeleton__item--md']]" />
          </div>
        </div>
      </div>
      <div v-if="isMobile" :class="[$style.itinerarySkeleton__box, 'skeleton-animation']" />
      <div v-else :class="$style.itinerarySkeleton__right">
        <div :class="[$style.itinerarySkeleton__box, 'skeleton-animation', 'mr50']" />
        <div :class="[$style.itinerarySkeleton__box, 'skeleton-animation']" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ItinerarySkeleton',
  props: {
    items: {
      type: Number,
      default: 1
    }
  },
  computed: {
    skeletonModifier() {
      return this.globalCobrandedVariant === 'aegeanair' ? this.$style['itinerarySkeleton--aegean'] : this.$style['itinerarySkeleton--fh'];
    }
  }
};
</script>

<style module lang="scss">
@import './itinerary-skeleton.scss';
</style>
