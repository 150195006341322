<template>
  <div>
    <div v-show="!isPortsFiltered" :class="['location-selection-list__title', extraClass]" data-test="popularLocationListTitle">{{ portsCategoryTitle }}</div>
    <div
      v-for="(location, locationIndex) in updatedLocations"
      :key="'location-item-' + location.LocationAbbr"
      class="location-selection-list__item"
      :class="{
        'location-selection-list__item--not-connected': !location.isConnectionPossible
      }"
      @click="onListItemClick(location.LocationAbbr, location.alias, locationIndex)"
    >
      <span :class="{ 'location-selection-list__item--highlight': selectedPort === location.LocationAbbr }">{{ location.alias }}</span>
      <span class="location-selection-list__item__country">{{ location.countryName }}</span>
    </div>
  </div>
</template>

<script>
import { message } from '@/logic/helpers/utils';

export default {
  name: 'PopularLocationsSearchList',
  props: {
    locations: Array,
    isPortsFiltered: Boolean,
    isDirectPortsShown: Boolean,
    hasReturnLocations: Boolean,
    siblingPort: Object,
    selectedPort: String
  },
  data: () => ({
    highlightClass: null
  }),
  emits: ['onListItemClick'],
  methods: {
    onListItemClick(abbreviation, alias, locationIndex) {
      this.$emit('onListItemClick', abbreviation, alias, this.eventPortLocationCategoryMsg, locationIndex);
    }
  },
  computed: {
    portsCategoryTitle() {
      if (!this.isDirectPortsShown) return `${message('searchmaskpopularportstitle')}`;
      if (this.isDirectPortsShown) return `${message('searchmaskdirectportstitle')}`;
    },
    extraClass() {
      return this.hasReturnLocations || this.isMobile ? 'mt4' : null;
    },
    updatedLocations() {
      // Upon filtering show children ports
      if (this.isPortsFiltered) return this.locations;
      // Direct ports -> do not show ports that are children ports of the previous input
      if (this.isDirectPortsShown) return this.locations.filter(l => l.parentIsland !== this.siblingPort.LocationAbbr);
      // Popular ports -> show 10
      return this.locations.slice(0, 10);
    },
    eventPortLocationCategoryMsg(){
      if(this.isPortsFiltered) return 'Suggested port selected';
      if(this.isDirectPortsShown) return 'Directly connected port selected';
      return 'Popular port selected';
    }
  }
};
</script>
