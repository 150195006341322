<template>
  <div class="noselect">
    <!-- Indirect routes label -->
    <div class="results-app__status" v-if="showIndirectLabel">
      <span><i class="fh fh-multiple-markers" aria-hidden="true"></i> {{ trans('indirectroutes') }} </span>
    </div>

    <!-- Indirect routes label for searching process -->
    <div class="results-app__status" v-else-if="isLoadingIndirect">
      <span><i class="fh fh-notch3 fh-spin mr5"></i> {{ trans('searchingindirectroutes') }}</span>
    </div>

    <!-- Indirect routes toggle -->
    <div v-else-if="showIndirectsToggle">
      <div class="results-app__footer results-app__footer--status" :class="{ clickable: areIndirectsTogglable }" @click="toggleIndirectResults()">
        <span> <i class="fh fh-multiple-markers" aria-hidden="true"></i> {{ trans('indirectroutes') }} </span>
        <span v-if="areIndirectsTogglable">
          <i class="fh ml10" :class="showIndirectTrips ? 'fh-up-arrow' : 'fh-down-arrow'" aria-hidden="true"></i>
        </span>
      </div>
    </div>

    <!-- No Indirect routes message -->
    <div class="results-app__status" v-else-if="showNoIndirectsMsg">
      <span> <i class="fh fh-multiple-markers" aria-hidden="true"></i> {{ trans('noindirectroutes') }} </span>
    </div>

    <!-- API call error message -->
    <div class="results-app__status results-app__status--suggested" v-else-if="hasError">
      <span class="results-app__status--network-error"> {{ trans('failIndirect') }} </span>
    </div>
  </div>
</template>
<script>
import { SEARCH_STATUS } from '../../logic/services/results/resultsService';
import { trans } from '@/filters';

export default {
  name: 'ResultsAppIndirectStatus',
  props: {
    indirectSearchStatus: {
      type: String,
      required: true,
    },
    hasDirectResults: {
      type: Boolean,
      default: false,
    },
    hasIndirectResults: {
      type: Boolean,
      default: false,
    },
    activeIndirectTripsCount: {
      type: Number,
      required: true,
    },
    showIndirectTrips: {
      type: Boolean,
      default: false,
    },
    hasDirectSuggestions: {
      type: Boolean,
      default: false,
    },
    prefetchedIndirectsCount: {
      type: Number,
      default: 0,
    },
  },
  emits: ['toggleIndirectResults'],
  methods: {
    trans,
    toggleIndirectResults: function () {
      if (this.areIndirectsTogglable) {
        this.$emit('toggleIndirectResults');
      }
    },
  },
  computed: {
    isLoadingIndirect() {
      return this.indirectSearchStatus === SEARCH_STATUS.LOADING;
    },
    showIndirectLabel() {
      const hasOnlyIndirect = !this.hasDirectResults && this.hasIndirectResults;
      return hasOnlyIndirect;
    },
    showIndirectsToggle() {
      const hasBothResults = this.hasDirectResults && this.hasIndirectResults;
      const isNotLoadingIndirects = this.indirectSearchStatus !== SEARCH_STATUS.LOADING;
      const hasNotFailed = this.indirectSearchStatus !== SEARCH_STATUS.FAILED;
      return isNotLoadingIndirects && hasBothResults && hasNotFailed;
    },
    areIndirectsTogglable() {
      return this.activeIndirectTripsCount > 1;
    },
    showNoIndirectsMsg() {
      return this.indirectSearchStatus === SEARCH_STATUS.COMPLETED && this.activeIndirectTripsCount === 0 && this.hasDirectSuggestions && this.prefetchedIndirectsCount > 0;
    },
    hasError() {
      return this.indirectSearchStatus === SEARCH_STATUS.ERROR;
    },
  },
};
</script>
