<template>
  <span :class="mainClass" @click="$emit('click')" data-test="swapButton">
    <i :class="iconClass"></i>
  </span>
</template>

<script>
export default {
  name: 'SearchSwapIconButton',
  props: {
    isDisabled: Boolean,
    flip: Boolean,
    isBig: Boolean,
    hasHover: { type: Boolean, default: true },
  },
  emits: ['click'],
  computed: {
    iconClass() {
      const rotationClass = this.flip ? 'SearchSwapIconButton__icon--flip' : '';
      return `fh fh-double-arrow SearchSwapIconButton__icon ${rotationClass}`;
    },
    mainClass() {
      const disabledClass = this.isDisabled ? 'SearchSwapIconButton--disabled' : '';
      const sizeClass = this.isBig ? 'SearchSwapIconButton--big' : '';
      const hoverClass = this.hasHover ? 'SearchSwapIconButton--withHover' : '';
      return `SearchSwapIconButton ${disabledClass} ${sizeClass} ${hoverClass}`;
    },
  },
};
</script>
