import { httpPost } from '@/logic/services/httpService';
import { getConfigurationValue } from '@/logic/helpers/utils';
import { createValidationRequest } from './createValidationRequest';

export default function validateIC(passenger, trip) {
  const url = `${getConfigurationValue('api')}ferry-providers/validate-mkn`;
  const request = createValidationRequest([passenger], trip);

  return httpPost(url, request);
}
