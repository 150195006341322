import { convertFromCents } from '@/components/mybooking/logic/utils';
import { isNull } from '../../helpers/utils';

export const getTotalNominalPriceForTravelers = travelers => {
  const totalPriceInCents = getTotalPriceInCentsForTravelers(travelers);
  if (isNull(totalPriceInCents)) return undefined;
  return convertFromCents(totalPriceInCents);
};

export const getTotalPriceInCentsForTravelers = travelers => {
  if (travelers.some(t => isNull(t.ticketPriceInCents) || isNaN(t.ticketPriceInCents))) return undefined;
  return parseInt(travelers.reduce((acc, p) => acc + p.ticketPriceInCents, 0));
};
