import * as types from '../mutation-types';
import { isEmpty, message } from '@/logic/helpers/utils';
import { isStatusSuccess, isStatusUnauthorized, isStatusForbidden, isStatusCreated, isStatusNotFound, isServerError, isStatusOffline } from '@/logic/helpers/httpHelpers';
import {
  updateUserContactDetails,
  deleteUserAccount,
  getUserData,
  getUserAuthState,
  getUserTrips,
  importUserBookingData,
  getUserBookingDocument,
  deleteUserBooking,
  savePassengerDetails,
  updatePassengerDetails,
  deletePassengerDetails,
  saveVehicleDetails,
  updateVehicleDetails,
  deleteVehicleDetails,
} from '@/logic/services/user/userServiceGateway';
import { adaptUser } from '@/logic/adapters/userAdapter';
import { eventAddBookingSuccess, eventAddBookingError, eventServerError, eventRemoveTripError, eventDeleteAccountError, eventDeleteAccountSuccess } from '@/logic/services/events/createProfileEvents';
import { logException } from '@/logic/services/events/errorLogging';

export const userModule = {
  namespaced: true,
  state: {
    user: {},
    showSigninSuccess: false,
    isSessionExpired: false,
    userUpdateSuccess: false,
    userAuthState: {},
    patchContactDetailsError: false,
    importBookingError: false,
    hasBookingDocumentError: false,
    hasBookingDeleteError: false,
    userTrips: [],
    userBooking: {},
    hasCRUDError: false,
    hasUserDetailsError: false,
    hasUpdatedPassenger: false,
    hasAddedPassenger: false,
    hasUpdatedVehicle: false,
    hasAddedVehicle: false,
    hasDeletedVehicle: false,
    hasDeletedPassenger: false,
    hasAccountDeleteError: false,
  },
  getters: {
    isUserDefined: (state) => !isEmpty(state.user),
  },
  mutations: {
    [types.SET_USER](state, payload) {
      state.user = isEmpty(payload) ? {} : adaptUser(payload);
    },
    [types.SET_SHOW_SIGNIN_SUCCESS](state, payload) {
      state.showSigninSuccess = payload;
    },
    [types.SET_SESSION_EXPIRED](state, payload) {
      state.isSessionExpired = payload;
    },
    [types.SET_USER_UPDATE_SUCCESS](state, payload) {
      state.userUpdateSuccess = payload;
    },
    [types.SET_USER_AUTH_STATE](state, payload) {
      state.userAuthState = payload;
    },
    [types.SET_PATCH_CONTACT_DETAILS_ERROR](state, payload) {
      state.patchContactDetailsError = payload;
    },
    [types.SET_BOOKING_IMPORT_ERROR](state, payload) {
      state.importBookingError = payload;
    },
    [types.SET_BOOKING_DELETE_ERROR](state, payload) {
      state.hasBookingDeleteError = payload;
    },
    [types.SET_BOOKING_DOCUMENT_ERROR](state, payload) {
      state.hasBookingDocumentError = payload;
    },
    [types.SET_ACCOUNT_DELETE_ERROR](state, payload) {
      state.hasAccountDeleteError = payload;
    },
    [types.SET_USER_TRIPS](state, payload) {
      state.userTrips = payload;
    },
    [types.SET_USER_BOOKING](state, payload) {
      state.userBooking = payload;
    },
    [types.SET_CRUD_ERROR](state, payload) {
      state.hasCRUDError = payload;
    },
    [types.SET_USER_DETAILS_ERROR](state, payload) {
      state.hasUserDetailsError = payload;
    },
    [types.SET_HAS_UPDATED_PASSENGER](state, payload) {
      state.hasUpdatedPassenger = payload;
    },
    [types.SET_HAS_ADDED_PASSENGER](state, payload) {
      state.hasAddedPassenger = payload;
    },
    [types.SET_HAS_UPDATED_VEHICLE](state, payload) {
      state.hasUpdatedVehicle = payload;
    },
    [types.SET_HAS_ADDED_VEHICLE](state, payload) {
      state.hasAddedVehicle = payload;
    },
    [types.SET_HAS_DELETED_PASSENGER](state, payload) {
      state.hasDeletedPassenger = payload;
    },
    [types.SET_HAS_DELETED_VEHICLE](state, payload) {
      state.hasDeletedVehicle = payload;
    },
  },
  actions: {
    setUser({ commit, dispatch }, payload) {
      commit(types.SET_USER, payload);
      dispatch('setSessionExpired', false);
    },
    async getUserDetails({ commit, dispatch }, payload) {
      const { status, data } = await getUserData(payload);

      if (isServerError(status) || isStatusOffline(status)) dispatch('handleServerError', { status, eventLabel: 'get user details' });

      if (isStatusUnauthorized(status) || isStatusForbidden(status)) {
        dispatch('setSessionExpired', true);
        return;
      }

      if (!isStatusSuccess(status)) commit(types.SET_USER_DETAILS_ERROR, true);

      commit(types.SET_USER, data);
      commit(types.SET_USER_DETAILS_ERROR, false);
    },
    async setUserContactDetails({ state, commit, dispatch }, payload) {
      dispatch('uiModule/changeIsLoading', true, { root: true });
      // User data service accepts null for empty phone number
      if (!payload.phone) payload = { ...payload, phone: null };
      const { status, data } = await updateUserContactDetails(state.user.id, payload);

      if (isServerError(status) || isStatusOffline(status)) dispatch('handleServerError', { status, eventLabel: 'set user details' });

      if (isStatusUnauthorized(status) || isStatusForbidden(status)) {
        dispatch('setSessionExpired', true);
        dispatch('uiModule/changeIsLoading', false, { root: true });
        return;
      }

      if (!isStatusSuccess(status)) {
        commit(types.SET_PATCH_CONTACT_DETAILS_ERROR, true);
        dispatch('uiModule/changeIsLoading', false, { root: true });
        return;
      }

      commit(types.SET_PATCH_CONTACT_DETAILS_ERROR, false);
      commit(types.SET_USER, data);
      commit(types.SET_SHOW_SIGNIN_SUCCESS, true);
      commit(types.SET_USER_UPDATE_SUCCESS, true);
      dispatch('uiModule/changeIsLoading', false, { root: true });
    },
    deleteUser({ commit }) {
      commit(types.SET_USER, {});
      commit(types.SET_SHOW_SIGNIN_SUCCESS, false);
    },
    setSessionExpired({ commit, dispatch }, payload) {
      commit(types.SET_SESSION_EXPIRED, payload);
      if (payload === true) dispatch('deleteUser');
    },
    setUserUpdateSuccess({ commit }, payload) {
      commit(types.SET_USER_UPDATE_SUCCESS, payload);
    },
    setShowSigninSuccess({ commit }, payload) {
      commit(types.SET_SHOW_SIGNIN_SUCCESS, payload);
    },
    async fetchUserAuthState({ commit, dispatch }) {
      const { status, data } = await getUserAuthState();

      if (isServerError(status) || isStatusOffline(status)) dispatch('handleServerError', { status, eventLabel: 'get user auth state' });

      if (isStatusUnauthorized(status) || isStatusForbidden(status)) {
        dispatch('setSessionExpired', true);
        return;
      }

      commit(types.SET_USER_AUTH_STATE, data);
    },

    async getUserBooking({ state, commit, dispatch }, payload) {
      dispatch('uiModule/changeIsLoading', true, { root: true });
      const { status, data } = await getUserBookingDocument(state.user.id, payload);

      if (isServerError(status) || isStatusOffline(status)) dispatch('handleServerError', { status, eventLabel: 'get user booking document' });

      if (isStatusUnauthorized(status) || isStatusForbidden(status)) {
        dispatch('setSessionExpired', true);
        dispatch('uiModule/changeIsLoading', false, { root: true });
        return;
      }

      if (isStatusNotFound(status)) {
        dispatch('setBookingDocumentError', true);
        dispatch('uiModule/changeIsLoading', false, { root: true });
        return;
      }

      if (isStatusSuccess(status)) commit(types.SET_USER_BOOKING, data.booking);
      dispatch('setBookingDocumentError', false);
      dispatch('uiModule/changeIsLoading', false, { root: true });
    },

    async fetchUserTrips({ state, commit, dispatch }) {
      dispatch('uiModule/changeIsLoading', true, { root: true });
      const { status, data } = await getUserTrips(state.user.id);

      if (isServerError(status) || isStatusOffline(status)) dispatch('handleServerError', { status, eventLabel: 'get user trips' });

      if (isStatusUnauthorized(status) || isStatusForbidden(status)) {
        dispatch('setSessionExpired', true);
        dispatch('uiModule/changeIsLoading', false, { root: true });
        return;
      }

      if (isStatusSuccess(status)) commit(types.SET_USER_TRIPS, data);
      dispatch('uiModule/changeIsLoading', false, { root: true });
    },
    setCRUDError({ commit }, value) {
      commit(types.SET_CRUD_ERROR, value);
    },
    async savePassengerDetails({ dispatch }, [payload, isActionFromProfile]) {
      const { userId, passengerPayload } = payload;
      dispatch('uiModule/changeIsLoading', true, { root: true });
      const { status, data } = await savePassengerDetails(userId, passengerPayload);
      if (isStatusUnauthorized(status) || isStatusForbidden(status)) {
        dispatch('setSessionExpired', true);
        dispatch('uiModule/changeIsLoading', false, { root: true });
        return;
      }
      if (!isStatusCreated(status)) {
        dispatch('setCRUDError', true);
        dispatch('uiModule/changeIsLoading', false, { root: true });
        return;
      }

      if (isActionFromProfile) dispatch('setHasAddedPassenger', true);

      dispatch('setCRUDError', false);
      dispatch('setUser', data);
      dispatch('uiModule/changeIsLoading', false, { root: true });
    },

    async updatePassengerDetails({ dispatch }, [payload, isActionFromProfile]) {
      const { userId, passengerId, passengerPayload } = payload;
      dispatch('uiModule/changeIsLoading', true, { root: true });
      const { status, data } = await updatePassengerDetails(userId, passengerId, passengerPayload);

      if (isStatusUnauthorized(status) || isStatusForbidden(status)) {
        dispatch('setSessionExpired', true);
        dispatch('uiModule/changeIsLoading', false, { root: true });
        return;
      }

      if (!isStatusSuccess(status)) {
        dispatch('setCRUDError', true);
        dispatch('uiModule/changeIsLoading', false, { root: true });
        return;
      }

      if (isActionFromProfile) dispatch('setHasUpdatedPassenger', true);

      dispatch('setCRUDError', false);
      dispatch('setUser', data);
      dispatch('uiModule/changeIsLoading', false, { root: true });
    },

    async deletePassengerDetails({ dispatch }, payload) {
      const { userId, passengerId } = payload;
      const { status, data } = await deletePassengerDetails(userId, passengerId);
      if (isStatusUnauthorized(status) || isStatusForbidden(status)) {
        dispatch('setSessionExpired', true);
        return;
      }
      if (!isStatusSuccess(status)) {
        dispatch('setCRUDError', true);
        return;
      }
      dispatch('setCRUDError', false);
      dispatch('setHasDeletedPassenger', true);
      dispatch('setUser', data);
    },
    async saveVehicleDetails({ dispatch }, [payload, isActionFromProfile]) {
      const { userId, vehiclePayload } = payload;
      dispatch('uiModule/changeIsLoading', true, { root: true });
      const { status, data } = await saveVehicleDetails(userId, vehiclePayload);
      if (isStatusUnauthorized(status) || isStatusForbidden(status)) {
        dispatch('setSessionExpired', true);
        dispatch('uiModule/changeIsLoading', false, { root: true });
        return;
      }
      if (!isStatusCreated(status)) {
        dispatch('setCRUDError', true);
        dispatch('uiModule/changeIsLoading', false, { root: true });
        return;
      }

      if (isActionFromProfile) dispatch('setHasAddedVehicle', true);

      dispatch('setHasAddedVehicle', true);
      dispatch('setCRUDError', false);
      dispatch('setUser', data);
      dispatch('uiModule/changeIsLoading', false, { root: true });
    },

    async updateVehicleDetails({ dispatch }, [payload, isActionFromProfile]) {
      const { userId, vehicleId, vehiclePayload } = payload;
      dispatch('uiModule/changeIsLoading', true, { root: true });
      const { status, data } = await updateVehicleDetails(userId, vehicleId, vehiclePayload);

      if (isStatusUnauthorized(status) || isStatusForbidden(status)) {
        dispatch('setSessionExpired', true);
        dispatch('uiModule/changeIsLoading', false, { root: true });
        return;
      }

      if (!isStatusSuccess(status)) {
        dispatch('setCRUDError', true);
        dispatch('uiModule/changeIsLoading', false, { root: true });
        return;
      }

      if (isActionFromProfile) dispatch('setHasUpdatedVehicle', true);

      dispatch('setHasUpdatedVehicle', true);
      dispatch('setCRUDError', false);
      dispatch('setUser', data);
      dispatch('uiModule/changeIsLoading', false, { root: true });
    },

    async deleteVehicleDetails({ dispatch }, payload) {
      const { userId, vehicleId } = payload;
      const { status, data } = await deleteVehicleDetails(userId, vehicleId);

      if (isStatusUnauthorized(status) || isStatusForbidden(status)) {
        dispatch('setSessionExpired', true);
        return;
      }
      if (!isStatusSuccess(status)) {
        dispatch('setCRUDError', true);
        return;
      }
      dispatch('setCRUDError', false);
      dispatch('setUser', data);
      dispatch('setHasDeletedVehicle', true);
    },

    async importUserBooking({ state, dispatch }, payload) {
      const { bookingCode, email } = payload;
      const data = {
        bookingCode,
        authentication: {
          method: 'email',
          data: email,
        },
      };
      const { status } = await importUserBookingData(state.user.id, data);
      if (isServerError(status) || isStatusOffline(status)) dispatch('handleServerError', { status, eventLabel: 'import user booking' });

      if (isStatusUnauthorized(status) || isStatusForbidden(status)) {
        dispatch('setSessionExpired', true);
        return;
      }

      if (isStatusNotFound(status)) {
        dispatch('setBookingImportError', true);
        eventAddBookingError(bookingCode, status, message('account.myTrips.addBookingModal.error.bookingNotFound'));
        return;
      }

      if (isStatusSuccess(status)) {
        dispatch('setBookingImportError', false);
        dispatch('fetchUserTrips');
        eventAddBookingSuccess();
      }
    },
    async deleteUserBooking({ state, commit, dispatch }) {
      const { status } = await deleteUserBooking(state.user.id, state.userBooking.bookingCode);

      if (isStatusUnauthorized(status) || isStatusForbidden(status)) {
        dispatch('setSessionExpired', true);
        return;
      }

      if (!isStatusSuccess(status)) {
        dispatch('setBookingDeleteError', true);
        eventRemoveTripError('', status, state.userBooking.bookingCode);
        return;
      }

      commit(types.SET_USER_BOOKING, {});
      dispatch('setBookingDeleteError', false);
    },

    setBookingImportError({ commit }, payload) {
      commit(types.SET_BOOKING_IMPORT_ERROR, payload);
    },
    setBookingDeleteError({ commit }, payload) {
      commit(types.SET_BOOKING_DELETE_ERROR, payload);
    },
    setBookingDocumentError({ commit }, payload) {
      commit(types.SET_BOOKING_DOCUMENT_ERROR, payload);
    },
    setAccountDeleteError({ commit }, payload) {
      commit(types.SET_ACCOUNT_DELETE_ERROR, payload);
    },
    handleServerError({ dispatch }, { status, eventLabel }) {
      dispatch('uiModule/changeIsLoading', false, { root: true });

      if (status) {
        eventServerError(status, eventLabel);
        logException('Profile App: Server error', status);
      }

      throw new Error('Something went wrong');
    },

    async deleteUserAccount({ state, dispatch }) {
      const { status } = await deleteUserAccount(state.user.id);

      if (isStatusUnauthorized(status) || isStatusForbidden(status)) {
        dispatch('setSessionExpired', true);
        return;
      }

      if (!isStatusSuccess(status)) {
        dispatch('setAccountDeleteError', true);
        eventDeleteAccountError(status);
        return;
      }

      dispatch('setAccountDeleteError', false);
      eventDeleteAccountSuccess();
    },
    setHasUpdatedPassenger({ commit }, payload) {
      commit(types.SET_HAS_UPDATED_PASSENGER, payload);
    },
    setHasAddedPassenger({ commit }, payload) {
      commit(types.SET_HAS_ADDED_PASSENGER, payload);
    },
    setHasUpdatedVehicle({ commit }, payload) {
      commit(types.SET_HAS_UPDATED_VEHICLE, payload);
    },
    setHasAddedVehicle({ commit }, payload) {
      commit(types.SET_HAS_ADDED_VEHICLE, payload);
    },
    setHasDeletedPassenger({ commit }, payload) {
      commit(types.SET_HAS_DELETED_PASSENGER, payload);
    },
    setHasDeletedVehicle({ commit }, payload) {
      commit(types.SET_HAS_DELETED_VEHICLE, payload);
    },
  },
};
