import { isNullOrEmptyString } from '@/logic/helpers/utils';
import GenericErrorCodes from '@/logic/services/GenericErrorCodes';

export const REPRICING_ERRORS = Object.freeze({
  COUPON: 'COUPON',
  REFUND: 'REFUND',
  INSURANCE: 'INSURANCE',
  SUPPORT_PLUS: 'SUPPORT_PLUS',
  FLEXI: 'FLEXI'
});

export const getRepricingErrors = payload => {
  let errors = [];

  const { error } = payload || null;
  if (error) errors.push({ error, errorType: REPRICING_ERRORS.COUPON });

  const { refundProtection } = payload || {};
  const { isSuccessful: isRefundProtectionSuccessful } = refundProtection || MOCK_UNUSED_EXTRA_SERVICE;
  if (!isRefundProtectionSuccessful) errors.push(createError(refundProtection.errorMessage, REPRICING_ERRORS.REFUND));

  const { travelInsurance } = payload || {};
  const { isSuccessful: isTravelInsuranceSuccessful } = travelInsurance || MOCK_UNUSED_EXTRA_SERVICE;
  if (!isTravelInsuranceSuccessful) errors.push(createError(travelInsurance.errorMessage, REPRICING_ERRORS.INSURANCE));

  const { supportPlus } = payload || {};
  const { isSuccessful: isSupportPlusSuccessful } = supportPlus || MOCK_UNUSED_EXTRA_SERVICE;
  if (!isSupportPlusSuccessful) errors.push(createError(supportPlus.errorMessage, REPRICING_ERRORS.SUPPORT_PLUS));

  const { flexihopper } = payload || {};
  const { isSuccessful: isFlexiSuccessful } = flexihopper || MOCK_UNUSED_EXTRA_SERVICE;
  if (!isFlexiSuccessful) errors.push(createError(flexihopper.errorMessage, REPRICING_ERRORS.FLEXI));

  return errors;
};

const createError = (errorMessage, errorType) => {
  const error = isNullOrEmptyString(errorMessage) ? GenericErrorCodes.get('generic') : errorMessage;
  return {
    error,
    errorType
  };
};

export const hasRepricingError = payload => {
  const errors = getRepricingErrors(payload);
  return errors.length > 0;
};

const MOCK_UNUSED_EXTRA_SERVICE = { isSuccessful: true };
