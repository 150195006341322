<template>
  <span>
    <span v-if="option.categoryTitle">
      <hr class="m0" />
      <span class="fh-dropdown-option__title">{{ option.categoryTitle }}<br /></span>
    </span>
    <span :class="['fh-dropdown-option', `fh-dropdown-option-${index}`]" @click="handleClick"> {{ option.categoryDescrition }}&nbsp;{{ option.extraDetails }}<br /> </span>
  </span>
</template>

<script>
export default {
  name: 'FhDropdownVehicleOption',
  props: {
    option: Object,
    index: Number
  },
  emits: ['click'],
  methods: {
    handleClick() {
      this.$emit('click');
    }
  }
};
</script>
