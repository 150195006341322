<template>
  <div>
    <FhModal v-if="showModal" @close="showModal = false" :title="trans('results.infoModal.title')">
      <TripModal :trip="tripShownInModal" @close="showModal = false" @select="onModalSelect" />
    </FhModal>

    <JourneyWithDate
      :key="`${origin}-${destination}-trip-${activeDate}`"
      :id="`journey-results-${searchIndex}`"
      :date="activeDate"
      :origin="origin"
      :destination="destination"
      :showArrows="true"
      :dateFullWidth="true"
      @nextDay="nextDay"
      @previousDay="previousDay"
    />

    <div v-if="!hasNoResults">
      <!-- DIRECT TRIPS SECTION -->

      <!-- Direct header status -->
      <transition name="route-animation" appear>
        <ResultsAppStatus
          v-if="!hasSelection"
          :key="`${origin}-${destination}`"
          :isLoadingDirect="isLoadingDirect"
          :isDirectlyConnected="isDirectlyConnected"
          :directTripsTotal="activeDirectTripsCount"
          :hasDirectResults="hasDirectResults"
          :hasIndirectResults="hasIndirectResults"
          :directTripsSearchStatus="directTripsSearchStatus"
          :hasSelection="hasSelection"
        />
      </transition>

      <!-- Alternate ports and dates suggestion -->
      <transition name="route-animation" appear>
        <ResultsAppSuggestion
          v-if="!hasSelection"
          :key="`${origin}-${destination}-suggested`"
          :origin="origin"
          :destination="destination"
          :hasDirectResults="hasDirectResults"
          :isLoadingDirect="isLoadingDirect"
          :isChildPortSearch="isChildPortSearch"
          :closestAlternateDates="closestAlternateDates"
          :hasAlternateDates="hasAlternateDates"
          :directTripsSearchStatus="directTripsSearchStatus"
          @suggestionClicked="suggestionClicked"
        />
      </transition>

      <!-- Direct trips -->
      <div v-if="hasDirectResults">
        <transition-group name="route-animation" tag="div" data-test="directTrips" :class="resultsGroupClass" appear>
          <SelectableItinerary
            v-for="trip in activeDirectTrips"
            :key="trip.id"
            :trip="trip"
            :isSelected="selectedTripId === trip.id"
            @onInfoButtonClick="onShowTripModal(trip)"
            @onSelect="select(trip.id, trip.available)"
          />
        </transition-group>
      </div>
      <div v-if="isLoadingDirect && !hasSelection" :class="resultsGroupClass">
        <ItinerarySkeleton :items="2" />
      </div>
      <!-- END OF DIRECT TRIPS SECTION -->

      <!-- INDIRECT TRIPS SECTION -->
      <!-- Indirect header status -->
      <ResultsAppIndirectStatus
        v-if="!hasSelection && directTripsSearchStatus === 'COMPLETED'"
        :indirectSearchStatus="indirectTripsSearchStatus"
        :hasDirectResults="hasDirectResults"
        :hasIndirectResults="hasIndirectResults"
        :activeIndirectTripsCount="activeIndirectTripsCount"
        :showIndirectTrips="showIndirectTrips"
        :prefetchedIndirectsCount="prefetchedIndirectsCount"
        :hasDirectSuggestions="hasDirectSuggestions"
        @getIndirectResultsClicked="getIndirectResultsClicked()"
        @toggleIndirectResults="toggleIndirectResults()"
      />

      <!-- Indirect trips -->
      <transition-group name="route-animation" tag="div" v-if="showIndirectResults" data-test="inDirectTrips" :class="resultsGroupClass" appear>
        <SelectableItinerary
          v-for="trip in activeIndirectTrips"
          :key="trip.id"
          :trip="trip"
          :isSelected="selectedTripId === trip.id"
          @onInfoButtonClick="onShowTripModal(trip)"
          @onSelect="select(trip.id, trip.available)"
        />
      </transition-group>

      <div v-if="isLoadingIndirect && !hasSelection" :class="resultsGroupClass">
        <IndirectItinerarySkeleton />
      </div>

      <ResultsAppIndirectCta
        v-if="!hasSelection && directTripsSearchStatus === 'COMPLETED'"
        :prefetchedIndirectsCount="prefetchedIndirectsCount"
        :indirectSearchStatus="indirectTripsSearchStatus"
        :activeIndirectTripsCount="activeIndirectTripsCount"
        @getIndirectResultsClicked="getIndirectResultsClicked()"
      />

      <!-- In case of auto-selection, show Indirects CTA -->
      <ResultsAppIndirectCta
        v-else-if="directTripsSearchStatus === 'COMPLETED' && hasSingleDirectResult"
        :prefetchedIndirectsCount="prefetchedIndirectsCount"
        :indirectSearchStatus="indirectTripsSearchStatus"
        :activeIndirectTripsCount="activeIndirectTripsCount"
        @getIndirectResultsClicked="
          resetSelection();
          getIndirectResultsClicked();
        "
      />

      <!-- Show all routes -->
      <ResultsAppDeselectCta v-else-if="hasSelection" @resetSelection="resetSelection" />

      <!-- DIRECT API CALL ERROR BUTTON -->
      <ResultsAppRetryCta v-if="!hasSelection" :status="directTripsSearchStatus" @retry="initializeSearch()" />

      <!-- INDIRECT API CALL ERROR BUTTON -->
      <ResultsAppRetryCta v-if="!hasSelection" :status="indirectTripsSearchStatus" @retry="retryIndirectSearch()" />
    </div>
    <div v-else>
      <ResultsAppNoResults />
    </div>
  </div>
</template>

<script>
import ItinerarySkeleton from '@/components/results/itineraries/ItinerarySkeleton/ItinerarySkeleton';
import IndirectItinerarySkeleton from '@/components/results/itineraries/IndirectItinerarySkeleton/IndirectItinerarySkeleton';
import CombinedTripsCollection from '@/logic/collections/CombinedTripsCollection';
import JourneyWithDate from '@/components/shared/JourneyWithDate';
import { getDirectTrips$, getIndirectTrips$, getAlternativeDays$, SEARCH_STATUS } from '@/logic/services/results/resultsService';
import ResultsAppStatus from '@/components/results/ResultsAppStatus';
import ResultsAppIndirectStatus from '@/components/results/ResultsAppIndirectStatus';
import ResultsAppIndirectCta from '@/components/results/ResultsAppIndirectCta';
import ResultsAppDeselectCta from './ResultsAppDeselectCta.vue';
import ResultsAppRetryCta from '@/components/results/ResultsAppRetryCta';
import emitter from '@/emitter';
import { BehaviorSubject, merge, Subject, throwError, of } from 'rxjs';
import { catchError, takeUntil, tap, filter } from 'rxjs/operators';
import TripsFilterer from '@/logic/filterers/TripsFilterer';
import { uniqBy as _uniqBy } from 'lodash-es';
import {
  eventOpenTripModal,
  eventTripSelectedFromModal,
  eventChangeDate,
  eventGetIndirectsClicked,
  eventResultsFinished,
  eventResultsErrorShown,
  eventAutoselection,
  eventDeselectedAutoselection,
} from '@/logic/services/events/createResultsEvents';
import { ga4ViewItem, userTimingEvent } from '@/logic/services/events/EventService';
import { getUniqueSortedTrips, hasParentPort, calculateClosestAlternateDates } from './resultsAppHelpers';
import { notNull } from '@/logic/helpers/utils';
import { isElementInViewport } from '@/logic/dom/isElementInViewport';
import { scrollToElementId } from '../../logic/dom/scrollTo';
import { isEmpty } from '@/logic/helpers/utils';
import ResultsAppSuggestion from '@/components/results/ResultsAppSuggestion';
import ResultsAppNoResults from '@/components/results/ResultsAppNoResults';
import ResultsEventsMixin from '@/components/results/mixins/ResultsEventsMixin';
import { trans, portFullName } from '@/filters';
import { defineAsyncComponent } from 'vue';

const TripModal = defineAsyncComponent(() => import('@/components/results/modal/TripModal'));

export default {
  name: 'ResultsAppSection',
  props: {
    origin: String,
    destination: String,
    date: Object,
    searchIndex: Number,
  },
  mixins: [ResultsEventsMixin],
  components: {
    ItinerarySkeleton,
    IndirectItinerarySkeleton,
    JourneyWithDate,
    ResultsAppStatus,
    ResultsAppIndirectStatus,
    ResultsAppIndirectCta,
    ResultsAppDeselectCta,
    ResultsAppRetryCta,
    ResultsAppSuggestion,
    ResultsAppNoResults,
    TripModal,
  },
  data() {
    return {
      directTripsSearchStatus: SEARCH_STATUS.IDLE,
      indirectTripsSearchStatus: SEARCH_STATUS.IDLE,
      directTrips: [],
      indirectTrips: [],
      // the active date (search date on component, which changes with the
      // date-changing buttons)
      activeDate: this.$dayjs(),
      hasSelection: false,
      isDirectlyConnected: false,
      isConnectionPossible: false,
      isSuperPortSearch: false,
      selectedTripId: '',
      showIndirectTrips: false,
      collection: undefined,
      // the trip currently shown on modal
      tripShownInModal: undefined,
      // flag becomes true when modal opens
      showModal: false,
      // Subscription to direct trips fetch (observable)
      directSubscription$: undefined,
      // Subscription to indirect trips prefetch (observable)
      indirectPrefetchSubscription$: undefined,
      // Subscription to indirect trips verification (observable)
      indirectVerifySubscription$: undefined,
      // Subject that emits once prefetch of indirects is completed
      prefetchCompletionSubject$: new BehaviorSubject(null),
      // Subscription to prefetch subject
      prefetchCompletionSubscription$: undefined,
      // Array of indirect trip verification observables
      prefetchedIndirects$: [],
      closestAlternateDates: [],
      destroySubject$: new Subject(),
      hasSingleDirectResult: false,
      hasAutoselectedTrip: false,
    };
  },
  emits: ['onResultsCollected', 'onSearchUpdate'],
  created() {
    this.$emit('onResultsCollected', 'onSearchUpdate');
  },
  mounted() {
    this.activeDate = this.date.clone();
    this.initializeSearch();
    emitter.$on('onTripDeselectedFromCart', (index) => {
      if (index === this.searchIndex) {
        this.resetSelectionFromCart();
      }
    });
  },
  beforeUnmount() {
    this.unsubscribe();
    this.destroySubject$.next();
    this.destroySubject$.complete();
  },
  methods: {
    trans,
    portFullName,
    unsubscribe() {
      if (this.directSubscription$) this.directSubscription$.unsubscribe();
      if (this.indirectPrefetchSubscription$) this.indirectPrefetchSubscription$.unsubscribe();
      if (this.indirectVerifySubscription$) this.indirectVerifySubscription$.unsubscribe();
      if (this.prefetchCompletionSubscription$) this.prefetchCompletionSubscription$.unsubscribe();
    },
    initializeSearch() {
      this.unsubscribe();
      this.resetSelection();
      this.directTripsSearchStatus = SEARCH_STATUS.IDLE;
      this.directTrips = [];
      this.indirectTripsSearchStatus = SEARCH_STATUS.IDLE;
      this.prefetchCompletionSubject$.next(SEARCH_STATUS.IDLE);
      this.indirectTrips = [];
      this.closestAlternateDates = [];
      this.prefetchedIndirects$ = [];
      this.collection = new CombinedTripsCollection(this.origin, this.destination, this.activeDate.format('YYYYMMDD'));
      this.isSuperPortSearch = this.collection.isSuperPortSearch;
      this.isDirectlyConnected = this.collection.isDirectlyConnected;
      this.isConnectionPossible = this.collection.isConnectionPossible;
      this.showIndirectTrips = false;
      this.getVerifiedResults();
    },
    checkForSingleDirectResult() {
      if (this.displayableDirectTrips.length === 1) {
        this.hasSingleDirectResult = true;
        if (this.hasSelection) return;
        this.select(this.activeDirectTrips[0].id, this.activeDirectTrips[0].available);
        this.hasAutoselectedTrip = true;
        eventAutoselection(this.activeDirectTrips[0]);
      } else this.hasSingleDirectResult = false;
    },
    onDirectResultsComplete() {
      this.directTripsSearchStatus = SEARCH_STATUS.COMPLETED;
      if (!isEmpty(this.directTrips)) this.closestAlternateDates = calculateClosestAlternateDates(this.directTrips, this.activeDate);
      // fixme: move in function and probably use altenrate dates
      const shouldAutoLoadIndirects = this.activeDirectTripsCount === 0 && !this.hasAlternateDates && !this.isChildPortSearch;
      this.preFetchIndirectResults(shouldAutoLoadIndirects);
      this.checkForSingleDirectResult();

      this.onDirectResultsCompleteEvents();
      //former got results event
    },
    retryIndirectSearch() {
      this.indirectTripsSearchStatus = SEARCH_STATUS.LOADING;
      this.prefetchedIndirects$ = [];
      this.preFetchIndirectResults(true);
    },
    updateSearch(newOrigin, newDestination, newDate) {
      this.$emit('onSearchUpdate', {
        origin: newOrigin,
        destination: newDestination,
        date: newDate || this.activeDate,
        index: this.searchIndex,
        hasSelection: this.hasSelection,
      });
      emitter.$emit('onSearchDatesChange');
    },
    suggestionClicked(newOrigin, newDestination, newDate) {
      if (typeof newDate === 'undefined') {
        this.sendAltPortSelectedEvent(newOrigin, newDestination);
      } else {
        this.sendAltDateSelectedEvent(newDate);
      }

      this.updateSearch(newOrigin, newDestination, newDate);
    },
    getVerifiedResults() {
      let startTime = new Date();
      this.directSubscription$ = merge(getDirectTrips$(this.collection), getAlternativeDays$(this.collection))
        .pipe(
          tap((this.directTripsSearchStatus = SEARCH_STATUS.LOADING)),
          catchError((err) => {
            this.directTripsSearchStatus = SEARCH_STATUS.ERROR;
            eventResultsErrorShown(this.date, this.origin, this.destination, this.resultScenario, err);
            return throwError(() => err);
          })
        )
        .subscribe({
          next: (verifiedTrips) => {
            const verifiedDirectTrips = this.getSortedTripsAndTriggerEvent(
              verifiedTrips.filter((t) => t.isDirectTrip),
              startTime
            );
            this.directTrips = verifiedDirectTrips;
            const verifiedIndirectTrips = verifiedTrips.filter((t) => !t.isDirectTrip);
            if (verifiedIndirectTrips.length > 0) {
              this.indirectTrips = getUniqueSortedTrips([...this.indirectTrips, ...verifiedIndirectTrips]);
              this.showIndirectTrips = true;
            }
          },
          error: () => {},
          complete: () => {
            if (this.directTrips.length === 0) {
              // for ui purposes we need to leave some time for loading state in order to display the spinner
              setTimeout(() => {
                this.onDirectResultsComplete();
              }, 300);
            } else {
              this.onDirectResultsComplete();
            }
          },
        });
    },
    preFetchIndirectResults(shouldAutoLoadIndirects) {
      if (shouldAutoLoadIndirects) {
        this.indirectTripsSearchStatus = SEARCH_STATUS.LOADING;
      }

      const indirectTrips$ = getIndirectTrips$(this.collection);
      this.indirectPrefetchSubscription$ = indirectTrips$
        .pipe(
          catchError((err) => {
            this.prefetchedIndirects$ = [of(new Error(SEARCH_STATUS.ERROR))];
            this.prefetchCompletionSubject$.next(SEARCH_STATUS.COMPLETED);
            return throwError(() => err);
          })
        )
        .subscribe({
          next: (preFetchedIndirectTrips) => {
            this.prefetchedIndirects$ = [...this.prefetchedIndirects$, ...preFetchedIndirectTrips];
          },
          error: (error) => {
            this.indirectTripsSearchStatus = SEARCH_STATUS.ERROR;
            eventResultsErrorShown(this.date, this.origin, this.destination, this.resultScenario, error);
          },
          complete: () => {
            // In case there are no direct trips start automatically fetching indirect trips
            if (shouldAutoLoadIndirects) this.getIndirectResults();
            else eventResultsFinished({ ...this.routeInfo, result_scenario: this.resultScenario });

            if (this.prefetchedIndirects$.length === 0) this.indirectTripsSearchStatus = SEARCH_STATUS.COMPLETED;
            this.prefetchCompletionSubject$.next(SEARCH_STATUS.COMPLETED);
          },
        });
    },
    getIndirectResults() {
      this.showIndirectTrips = true;
      this.indirectTripsSearchStatus = SEARCH_STATUS.LOADING;
      emitter.$emit('resetCartCheckoutError');
      this.prefetchCompletionSubscription$ = this.prefetchCompletionSubject$.pipe(filter(notNull), takeUntil(this.destroySubject$)).subscribe((prefetchStatus) => {
        if (prefetchStatus === SEARCH_STATUS.COMPLETED) {
          this.indirectVerifySubscription$ = merge(...this.prefetchedIndirects$)
            .pipe(
              tap((res) => {
                if (res.message === SEARCH_STATUS.ERROR) {
                  throw new Error(`${SEARCH_STATUS.ERROR} occured in indirect trips`);
                }
              })
            )
            .subscribe({
              next: (verifiedIndirectTrips) => {
                if (verifiedIndirectTrips.length > 0) {
                  this.indirectTrips = getUniqueSortedTrips([...this.indirectTrips, ...verifiedIndirectTrips]);
                }
              },
              error: (err) => {
                this.indirectTripsSearchStatus = SEARCH_STATUS.ERROR;
                eventResultsErrorShown(this.date, this.origin, this.destination, this.resultScenario, err);
              },
              complete: () => {
                this.indirectTripsSearchStatus = SEARCH_STATUS.COMPLETED;
                //former got results event
                this.onIndirectResultsCompleteEvents();
              },
            });
        }
      });
    },
    getIndirectResultsClicked() {
      eventGetIndirectsClicked(this.date, this.origin, this.destination, this.resultScenario);
      this.getIndirectResults();
    },
    toggleIndirectResults() {
      this.showIndirectTrips = !this.showIndirectTrips;
    },
    select(tripId, available) {
      // do not respond to clicks of unavailable trips
      if (!available) return;

      if (this.selectedTripId !== tripId) {
        this.selectedTripId = tripId;
        this.hasSelection = true;
        const selection = [...this.activeDirectTrips, ...this.activeIndirectTrips].find((o) => o.id === this.selectedTripId);
        emitter.$emit('onItinerarySelection', { selection, index: this.searchIndex });
        if (this.hasSingleDirectResult) return;
        const tripHeaderId = `journey-results-${this.searchIndex}`;
        if (!isElementInViewport(tripHeaderId)) scrollToElementId(tripHeaderId, 'smooth');
        this.checkForLabelsEvent(selection);
      } else {
        if (this.hasAutoselectedTrip) {
          const selection = [...this.activeDirectTrips, ...this.activeIndirectTrips].find((o) => o.id === this.selectedTripId);
          this.hasAutoselectedTrip = false;
          eventDeselectedAutoselection(selection);
        }
        this.resetSelection();
      }
    },
    getSortedTripsAndTriggerEvent(directTrips, startTime) {
      const uniqueSortedTrips = getUniqueSortedTrips([...this.directTrips, ...directTrips]);
      if (this.directTrips.length === 0 && uniqueSortedTrips.length > 0) {
        let endTime = new Date();
        userTimingEvent('FirstDirectTrips', `${this.origin}:${this.destination}`, endTime - startTime);
      }
      return uniqueSortedTrips;
    },
    onModalSelect() {
      if (this.tripShownInModal.isDirectTrip) {
        eventTripSelectedFromModal(this.tripShownInModal);
      } else {
        if (this.tripShownInModal.segments) {
          this.tripShownInModal.segments.forEach((segment) => eventTripSelectedFromModal(segment));
        }
      }

      // close the modal
      this.showModal = false;
      if (this.selectedTripId === '') this.select(this.tripShownInModal.id, this.tripShownInModal.available);
    },
    resetSelection() {
      if (this.hasSelection) {
        emitter.$emit('onItineraryDeselection', this.searchIndex);
      }
      this.selectedTripId = '';
      this.hasSelection = false;
    },
    resetSelectionFromCart() {
      this.selectedTripId = '';
      this.hasSelection = false;
    },
    onShowTripModal(trip) {
      this.tripShownInModal = trip;
      this.showModal = true;
      ga4ViewItem(trip, { origin: this.origin, destination: this.destination });
      if (trip.isDirectTrip) {
        eventOpenTripModal(trip);
        return;
      }
      if (!trip.segments || trip.segments.length === 0) return;
      trip.segments.forEach((segment) => eventOpenTripModal(segment));
    },
    //--------------------------------------------------------------------------
    // move forward one day
    nextDay(newDate) {
      this.updateSearch(this.origin, this.destination, newDate);
      eventChangeDate(this.date, this.origin, this.destination, 'Next');
    },
    //--------------------------------------------------------------------------
    // move backwards one day
    previousDay(newDate) {
      this.updateSearch(this.origin, this.destination, newDate);
      eventChangeDate(this.date, this.origin, this.destination, 'Previous');
    },
  },
  watch: {
    activeDirectTripsCount(newValue, oldValue) {
      if (newValue > 0) {
        this.$emit('onResultsCollected');
      }
    },
    activeIndirectTripsCount(newValue, oldValue) {
      if (newValue > 0) {
        this.$emit('onResultsCollected');
      }
    },
    hasAlternateDates(newValue, oldValue) {
      if (newValue) {
        this.$emit('onResultsCollected');
      }
    },
  },
  computed: {
    resultsGroupClass() {
      return this.isMobile ? 'pt8' : 'pt12';
    },
    isLoadingDirect() {
      return this.directTripsSearchStatus === SEARCH_STATUS.LOADING;
    },
    isLoadingIndirect() {
      return this.indirectTripsSearchStatus === SEARCH_STATUS.LOADING;
    },
    showIndirectResults() {
      if (this.activeIndirectTripsCount === 0) return false;
      return this.showIndirectTrips || this.activeDirectTripsCount === 0;
    },
    displayableDirectTrips() {
      const directTrips = TripsFilterer.markFastestTrips(this.directTrips.filter((t) => !t.alternate));
      directTrips.forEach((trip, index) => (trip.productPosition = index + 1));
      return directTrips;
    },
    displayableIndirectTrips() {
      let indirectTrips = this.indirectTrips;
      // When user selects an indirect the computed activeDirectTripsCount is becoming 0.
      // So we need a counter for displayableDirectTrips in order to know that there are direct trips and not flag indirect upon select as FASTEST
      const displayableDirectTripsCount = this.displayableDirectTrips.length;
      if (displayableDirectTripsCount === 0) indirectTrips = TripsFilterer.markFastestTrips(indirectTrips);
      // Sort itineraries (available first)
      const availableIndirectTrips = indirectTrips.filter((trip) => trip.available);
      const unAvailableIndirectTrips = indirectTrips.filter((trip) => !trip.available);
      const allIndirectTrips = [...availableIndirectTrips, ...unAvailableIndirectTrips];
      allIndirectTrips.forEach((trip, index) => {
        trip.productPosition = index + 1;
        trip.segments.forEach((segment) => (segment.productPosition = index + 1));
      });
      return allIndirectTrips;
    },
    activeDirectTripsCount() {
      return this.activeDirectTrips.length;
    },
    activeDirectTrips() {
      if (this.hasSelection) return this.displayableDirectTrips.filter((o) => o.id === this.selectedTripId);
      return this.displayableDirectTrips;
    },
    activeIndirectTripsCount() {
      return this.activeIndirectTrips.length;
    },
    activeIndirectTrips() {
      if (this.hasSelection) return this.displayableIndirectTrips.filter((o) => o.id === this.selectedTripId);
      return this.displayableIndirectTrips;
    },
    prefetchedIndirectsCount() {
      return this.prefetchedIndirects$.length;
    },
    isChildPortSearch() {
      return hasParentPort(this.origin) || hasParentPort(this.destination);
    },
    hasDirectResults() {
      return this.activeDirectTripsCount > 0;
    },
    hasIndirectResults() {
      return this.activeIndirectTripsCount > 0;
    },
    hasAlternateDates() {
      return this.closestAlternateDates.length > 0 && this.closestAlternateDates.some((item) => item !== '' && item !== null);
    },
    hasNoResults() {
      // Early return if results have not loaded yet
      if (this.isLoadingDirect || this.isLoadingIndirect) {
        return false;
      }
      return !this.hasDirectSuggestions && !this.hasIndirectResults;
    },
    // If there are direct trips, or alternate ports or alternate dates
    hasDirectSuggestions() {
      return this.hasDirectResults || this.isChildPortSearch || this.hasAlternateDates;
    },
  },
};
</script>
