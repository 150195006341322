import PortsRepository from '@/logic/repositories/PortsRepository';

export const tripToTripInfo = ({ Details: { DepStation, ArrStation, VesselName, VesselID, Company }, timings: { DepDate } }) => {
  return {
    departure_port_name: PortsRepository.getPortFullName(DepStation),
    destination_port_name: PortsRepository.getPortFullName(ArrStation),
    departure_port_code: DepStation,
    destination_port_code: ArrStation,
    departure_date: DepDate,
    vessel_name: VesselName,
    vessel_code: VesselID,
    ferry_operator_code: Company,
    trip_id: `${DepStation}_${ArrStation}_${DepDate}_${VesselID}_${Company}`
  };
};

export const extendedTripToTripInfo = ({ DepStation, ArrStation, vessel, Company, DepDate }) => {
  return {
    departure_port_name: PortsRepository.getPortFullName(DepStation),
    destination_port_name: PortsRepository.getPortFullName(ArrStation),
    departure_port_code: DepStation,
    destination_port_code: ArrStation,
    departure_date: DepDate,
    vessel_name: '', //there is no vessel name in extended trip
    vessel_code: vessel,
    ferry_operator_code: Company,
    trip_id: `${DepStation}_${ArrStation}_${DepDate}_${vessel}_${Company}`
  };
};

/**
 * Extract trip details from data object used for creating a TripDetailsModel + Vessel name
 * */
export const tripDetailsModelToTripInfo = ({ origin, destination, departure, vesselName, vessel, provider }) => {
  return {
    departure_port_name: PortsRepository.getPortFullName(origin),
    destination_port_name: PortsRepository.getPortFullName(destination),
    departure_port_code: origin,
    destination_port_code: destination,
    departure_date: departure.date,
    vessel_name: vesselName,
    vessel_code: vessel,
    ferry_operator_code: provider,
    trip_id: `${origin}_${destination}_${departure.date}_${vessel}_${provider}`
  };
};
