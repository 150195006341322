<template>
  <span class="info-modal-icon" @click="getFaqInfo()" v-if="isCobrandedPage === false" style="line-height: inherit; font-size: inherit; color: inherit;">
    <span v-if="customTitle !== ''" :class="{ pr5: includeIcon }">{{ customTitle }}</span>
    <i v-if="includeIcon" class="fh" :class="['info-modal-icon__info', customIcon, customClass]"></i>
  </span>
</template>

<script>
import emitter from '@/emitter';
import { getFaqInfo } from '@/logic/services/ApiGateway';

export default {
  name: 'InfoModalItem',
  props: {
    includeIcon: {
      type: Boolean,
      default: true
    },
    slug: {
      type: String,
      required: true
    },
    customIcon: {
      type: String,
      default: 'fh-info'
    },
    customClass: {
      type: String,
      default: ''
    },
    customTitle: {
      type: String,
      default: ''
    }
  },
  emits: ['openInfoModalClicked'],
  created() {
    if (!this.includeIcon && !this.customTitle) throw new Error('InfoModalItem: Missing options');
  },
  methods: {
    getFaqInfo: async function() {
      try {
        await this.$store.dispatch('navigationModule/updateInfoModal', {
          title: '',
          content: ''
        });
        const response = await getFaqInfo(this.slug);
        this.$emit('openInfoModalClicked');
        await this.$store.dispatch('navigationModule/updateInfoModal', {
          title: response.title,
          content: response.content
        });
        emitter.$emit('openInfoModal');
      } catch (error) {
        emitter.$emit('closeInfoModal');
      }
    }
  }
};
</script>
