import GenericErrorCodes from '@/logic/services/GenericErrorCodes';

export const ERROR_TYPES = Object.freeze({
    LOCAL: 'LOCAL',
    REMOTE: 'REMOTE'
})

export const createError = (code, message = undefined, type = ERROR_TYPES.LOCAL, extraArgs) => {
    if (type === ERROR_TYPES.LOCAL) return { code, message: message || GenericErrorCodes.get(code), type };
    return { code, message, type, extraArgs };

}

export const getPersistingErrorsBag = (oldErrors, newErrors) => oldErrors.filter(oldError => isErrorInBag(newErrors, oldError));

export const addErrorInBag = (bag, error) => {
    if (isErrorInBag(bag, error)) return bag;
    return [
        ...bag,
        error
    ]
}

export const removeErrorFromBag = (bag, errorCode) => {
    return bag.filter(e => e.code !== errorCode);
}

export const isErrorInBag = (bag, error) => bag.some(bagError => isSameError(bagError, error));

const isSameError = (newError, oldError) => isSameErrorCode(newError, oldError) && isSameErrorType(newError, oldError);
const isSameErrorCode = (newError, oldError) => newError.code === oldError.code;
const isSameErrorType = (newError, oldError) => newError.type === oldError.type;


