export const createPetForSeating = (trip, petIndex, uid) => {
  const defaultPetAccommodation = getDefaultPetAccommodation(trip);
  const defaultPetType = getDefaultPetType(trip);

  return {
    uid,
    id: `PD-PET-${uid}`,
    petIndex: petIndex,
    ownerIndex: 0,
    // SeasCRSPetType
    petTypeCode: defaultPetType.petTypeCode,
    petTypeDescription: defaultPetType.petTypeDescription,
    // SeasCRSPetAccommodationType
    selectedAccommodation: defaultPetAccommodation.abbr,
    petAccommodationCode: defaultPetAccommodation.petAccommodationCode,
    petAccommodationDescription: defaultPetAccommodation.petAccommodationDescription
  };
};

export const changeTypeOfPet = (pet, newPetType) => {
  return {
    ...pet,
    petTypeCode: newPetType.petTypeCode,
    petTypeDescription: newPetType.petTypeDescription
  };
};

export const changeAccommodationOfPet = (pet, newAccommodation) => {
  return {
    ...pet,
    selectedAccommodation: newAccommodation.abbr,
    petAccommodationCode: newAccommodation.petAccommodationCode,
    petAccommodationDescription: newAccommodation.petAccommodationDescription
  };
};

export const changeOwnerOfPet = (pet, newPassenger) => {
  return {
    ...pet,
    ownerIndex: newPassenger.passengerIndex
  };
};

export const getDefaultPetAccommodation = trip => {
  return trip.petAccommodations.find(a => a.isDefault);
};

export const getDefaultPetType = trip => {
  return trip.PetTypes.find(t => t.isDefaultPet);
};
