<template>
  <div class="booking-flow-box--grey-bg">
    <h4 class="booking-flow-box__header">{{ trans('passDet.FHsupport.title') }}</h4>
    <span class="booking-flow-box__subtitle mb24"><i v-if="!isMobile" class="fh fh-info mr8 mt3" />{{ trans('passDet.FHsupport.subtitle') }}</span>

    <div :class="$style.refund__cards">
      <SelectableCard
        :title="trans('passDet.FHsupport.standard.title')"
        :amount="currencyStringPre(standardSupportPrice)"
        :footerMsg="trans('generic.noExtraCharge')"
        :imageSrc="standardSupportImageUrl"
        :selected="!supportPlusSelected"
        @click="setSupportPlusSelected({ ancillaryMode: supportPlusOptions.STANDARD })"
      >
        <StandardSupport />
      </SelectableCard>

      <div v-show="showWaitingState">
        <SelectableCardSkeleton />
      </div>
      <div v-show="!showWaitingState">
        <SelectableCard
          v-if="supportPlusProvider === ferryhopperProvider"
          :title="trans('passDet.FHsupport.plus.title')"
          :amount="currencyStringPre(supportPlusPrice)"
          :footerMsg="trans('generic.inTotal')"
          :imageSrc="supportPlusImageUrl"
          :selected="supportPlusSelected"
          @click="setSupportPlusSelected({ ancillaryMode: supportPlusOptions.EXTENDED, ancillaryOrder, totalAncillaries, ancillaryUIMode: 'multiple' })"
          :badgeText="trans('generic.recommended')"
          :isLoading="fetchingForPrices"
          :hasError="hasSupportPlusError"
          :errorMsg="supportPlusError"
        >
          <SupportPlus @fetchCompleted="fetchCompleted" />
        </SelectableCard>

        <SelectableCard
          v-if="hasSupportPlusError"
          :title="trans('passDet.FHsupport.plus.title')"
          :amount="currencyStringPre(standardSupportPrice)"
          :footerMsg="trans('generic.inTotal')"
          :imageSrc="fhImage('assets/support-plus/DisabledSupportPlus.svg')"
          :hasError="hasSupportPlusError"
          :errorMsg="supportPlusError"
        >
        </SelectableCard>
      </div>
    </div>
  </div>
</template>

<script>
import SelectableCard from '@/components/shared/SelectableCard/SelectableCard.vue';
import SelectableCardSkeleton from '@/components/shared/SelectableCard/SelectableCardSkeleton.vue';
import SupportPlus from '@/components/book/supportPlus/SupportPlus.vue';
import StandardSupport from '@/components/book/supportPlus/StandardSupport.vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import { EXTRA_SERVICE_OPTIONS, EXTRA_SERVICE_PROVIDERS } from '@/logic/BL/extraServices';
import { trans, currencyStringPre, fhImage } from '@/filters';
import { eventSupportPlusShown } from '@/logic/services/events/createBookingEvents';

export default {
  name: 'SupportPlusComponent',
  components: {
    SelectableCard,
    SelectableCardSkeleton,
    SupportPlus,
    StandardSupport,
  },
  props: {
    ancillaryOrder: {
      type: Number,
    },
    totalAncillaries: {
      type: Number,
    },
  },
  data() {
    return {
      standardSupportPrice: 0,
      supportPlusOptions: EXTRA_SERVICE_OPTIONS,
      ferryhopperProvider: EXTRA_SERVICE_PROVIDERS.FERRYHOPPER,
      isFetchCompleted: false,
    };
  },
  methods: {
    trans,
    currencyStringPre,
    fhImage,
    ...mapActions({
      setSupportPlusSelected: 'bookingModule/setSupportPlusSelected',
    }),
    fetchCompleted(value) {
      this.isFetchCompleted = value;
      if (value) eventSupportPlusShown(this.ancillaryOrder, this.totalAncillaries);
    },
  },
  computed: {
    ...mapState({
      fetchingForPrices: (state) => state.uiModule.fetchingForPrices,
      supportPlusPrice: (state) => state.bookingModule.supportPlus.price,
      supportPlusError: (state) => state.bookingModule.supportPlus.error,
    }),
    ...mapGetters({
      supportPlusProvider: 'bookingModule/getSupportPlusProvider',
      supportPlusSelected: 'bookingModule/supportPlusSelected',
      hasSupportPlusError: 'bookingModule/hasSupportPlusError',
    }),
    showWaitingState() {
      return !this.isFetchCompleted && !this.hasSupportPlusError;
    },
    standardSupportImageUrl() {
      return fhImage('assets/support-plus/HasSaver.svg');
    },
    supportPlusImageUrl() {
      return this.hasSupportPlusError ? fhImage('assets/support-plus/DisabledSupportPlus.svg') : fhImage('assets/support-plus/HasSupportPlus.svg');
    },
  },
};
</script>

<style module lang="scss">
@import '../refund/refund.scss';
</style>
