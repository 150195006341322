import { isPricableOffer } from '../BL/common/passengerOffers/passengerOffer';
import { removeAllSpaces } from '../helpers/stringUtils';
import { convertFromCents } from '@/components/mybooking/logic/utils';

/**
 * In order to reduce the size of stored data, we need to clear (remove) some
 * values that are not used for booking post-processing
 *
 * - Adds details to the bookable item that are required by our APIs
 * - Removes props of a trip that are added during the booking process,
 *   and are not required by the backend
 *
 * @param {Object} trip
 */
export const hydrateTrip = trip => {

  const { 
    InsertionSequence: PassengerInsertionSequence, 
    VehicleInsertionSequence, 
    PetInsertionSequence, 
    PricingRequest 
  } = trip.PricingData;

  const vehicles = trip.vehicles.map((vehicle, vehicleIndex) => {
    const vehiclesPricing = getTripVehiclesPricing(trip);

    // Get the driverIndex from PricingRequest, or default to zero (should never be the case)
    const driverIndex = vehiclesPricing.length > vehicleIndex ? vehiclesPricing[vehicleIndex].DriverIndex : 0;

    return {
      ...hydrateTripVehicle(vehicle),
      driverIndex
    };
  });

  return {
    version: '3.1',
    source: trip.source,
    provider: trip.provider,
    OverallPrice: trip.OverallPrice,
    DiscountFlags: {
      wantsEarlyBooking: PricingRequest.DiscountFlags.wantsEarlyBooking
    },
    subJourney: {
      type: trip.subJourney.type,
      IDScode: trip.subJourney.IDScode
    },
    SeatingAnalysis: trip.SeatingAnalysis.map(hydrateSeatingAnalysis),
    passengers: trip.passengers.map(hydrateTripPassenger),
    PassengerInsertionSequence,
    vehicles,
    VehicleInsertionSequence,
    pets: trip.pets.map(hydrateTripPet),
    PetInsertionSequence,
    Details: hydrateTripDetails(trip),
    tripLabels: hydrateTripLabels(trip),
    timings: hydrateTripTimings(trip),
    extraAttributes: trip.extraAttributes,
    fastest: trip.fastest,
    alternate: trip.alternate
  };
};

const hydrateTripDetails = ({ Details }) => {
  return { 
    DepCountry: Details.DepCountry,
    DepStation: Details.DepStation,
    DepStationName: Details.DepStationName,
    ArrCountry: Details.ArrCountry,
    ArrStation: Details.ArrStation, 
    ArrStationName: Details.ArrStationName,
    Company: Details.Company,
    CompanyName: Details.CompanyName,
    PrefCompany: Details.PrefCompany,
    ParentCompanyName: Details.ParentCompanyName,
    VesselID: Details.VesselID,
    VesselName: Details.VesselName
  }
}


const hydrateTripTimings = ({ timings }) => {
  return {
    unix: timings.unix, 
    DepDate: timings.DepDate, 
    DepTime: timings.DepTime, 
    ArrDate: timings.ArrDate, 
    ArrTime: timings.ArrTime, 
    duration: timings.duration
  }
}

const hydrateTripLabels = ({ tripLabels }) => {
  return (tripLabels || []).map(({ id, category, isForVehicle, isForRoundTrip }) => ({
    id,
    category,
    isForVehicle,
    isForRoundTrip
  }))
}

/**
 *
 * @param {Object} seatingAnalysisItem
 */
const hydrateSeatingAnalysis = seatingAnalysisItem => {
  return {
    type: seatingAnalysisItem.type,
    code: seatingAnalysisItem.code,
    description: seatingAnalysisItem.description,
    icon: seatingAnalysisItem.icon,
    isInfantGroup: seatingAnalysisItem.isInfantGroup,
    quantity: seatingAnalysisItem.quantity
  };
};

/**
 * Remove all undesired properties of passenger without mutation
 * @param {import('@/logic/models/Models').PassengerModel} passenger
 */
const hydrateTripPassenger = passenger => {
  return {
    type: passenger.type,
    gender: passenger.gender,
    isInfant: passenger.isInfant,
    isLeader: passenger.isLeader,
    firstName: passenger.firstName,
    lastName: passenger.lastName,
    passport: passenger.passport,
    birthplace: passenger.birthplace,
    birthday: passenger.birthday,
    birthmonth: passenger.birthmonth,
    birthyear: passenger.birthyear,
    birthDateFull: passenger.birthDateFull,
    docexpday: passenger.docexpday,
    docexpmonth: passenger.docexpmonth,
    docexpyear: passenger.docexpyear,
    documentDateFull: passenger.documentDateFull,
    nationality: passenger.nationality,
    islanderCode: passenger.islanderCodeEnabled ? passenger.islanderCode : '',
    detailedPricing: passenger.detailedPricing,
    ticketPriceInCents: passenger.ticketPriceInCents,
    finalPrice: convertFromCents(passenger.ticketPriceInCents),
    loyaltyCard: passenger.isInfant ? {} : hydratePassengerLoyalty(passenger.loyalty),
    residenceDiscount: hydratePassengerResidenceDiscount(passenger.residenceDiscount)
  };
};

/**
 * @param {LoyaltyCard} loyalty
 */
const hydratePassengerLoyalty = loyalty =>
  isPricableOffer(loyalty)
    ? {
        schemeName: loyalty.schemeName,
        loyaltyNumber: loyalty.loyaltyNumber,
        isValid: isPricableOffer(loyalty)
      }
    : {};

/**
 * @param {ResidenceDiscount} residenceDiscount
 */
const hydratePassengerResidenceDiscount = residenceDiscount =>
  isPricableOffer(residenceDiscount)
    ? {
        schemeName: residenceDiscount.schemeName,
        residenceNumber: residenceDiscount.residenceNumber,
        isValid: isPricableOffer(residenceDiscount)
      }
    : {};

/**
 *
 * @param {object} vehicle
 */
const hydrateTripVehicle = vehicle => {
  return {
    TypeAbbr: vehicle.TypeAbbr,
    TypeCategory: vehicle.TypeCategory,
    ticketPriceInCents: vehicle.ticketPriceInCents,
    finalPrice: convertFromCents(vehicle.ticketPriceInCents),
    licencePlate: removeAllSpaces(vehicle.licencePlate),
    detailedPricing: vehicle.detailedPricing,
    vehicleBrand: vehicle.vehicleBrand,
    vehicleModel: vehicle.vehicleModel,
  };
};

/**
 * TODO: Move this to a more generic place
 * Extract vehicles pricing from trip
 * @param {BookableTripModel} trip
 */
const getTripVehiclesPricing = trip => {
  return trip.PricingData.PricingRequest.ItineraryPricingRequestExt.PricingPerVehicle;
};

const hydrateTripPet = pet => {
  return {
    petId: pet.petId,
    petTypeCode: pet.petTypeCode,
    petAccommodation: pet.selectedAccommodation,
    petAccommodationCode: pet.petAccommodationCode,
    petName: pet.petName,
    ticketPriceInCents: pet.ticketPriceInCents,
    finalPrice: convertFromCents(pet.ticketPriceInCents),
    detailedPricing: pet.detailedPricing,
    petDocument: pet.petDocument,
    ownerIndex: pet.ownerIndex
  };
};
