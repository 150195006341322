import { mapState, mapActions } from 'vuex';
import { getValidationStatusForInput } from '@/logic/validation/validation';
import emitter from '@/emitter';

// IMPORTANT NOTE: this mixin is used for validating user input in components that don't use the FhInput component.
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    rules: {
      type: String,
      required: false,
      default: '',
    },
  },
  beforeUnmount() {
    emitter.$off('validate', this.validate);
    this.removeError({ name: this.name });
  },
  created() {
    emitter.$on('validate', this.validate);
    emitter.$on('validateField', (fieldName) => {
      if (fieldName === this.name) this.validate();
    });
  },
  methods: {
    ...mapActions({
      addError: 'validationModule/addError',
      removeError: 'validationModule/removeError',
    }),
    validate() {
      const validationStatus = getValidationStatusForInput(this.value, this.rules);
      if (!validationStatus.status) this.addError({ name: this.name, message: validationStatus.message });
      else this.removeError({ name: this.name });
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.validationModule.errors,
    }),
  },
};
