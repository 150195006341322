import { ga4Event } from './EventService';

export const eventStartSearch = (mode, searches, passengersCount) => {
  ga4Event('search_performed', {
    type_of_search: mode,
    list_of_port_names: searchesToPorts(searches),
    list_of_port_codes: searchesToPortCodes(searches),
    number_of_trips: searches.length,
    number_of_passengers: passengersCount
  });
};

export const eventQuickSearch = (mode, searches) => {
  ga4Event('recent_search_clicked', {
    type_of_search: mode,
    list_of_port_names: searchesToPorts(searches),
    list_of_port_codes: searchesToPortCodes(searches),
    number_of_trips: searches.length
  });
};

export const eventSearchInputsError = (error, eventNew, searchMode) => {
  ga4Event(eventNew, {
    type_of_search: searchMode
  });
};

export const eventSearchDateOrderError = mode => {
  ga4Event('non_consecutive_dates_selected', {
    type_of_search: mode
  });
};

export const eventSearchPortsError = (error, ports) => {
  //the port that caused the error is always first in the array
  //probable KilledEvent('Input Warning', error, `${ports[0].alias}:${ports[1].alias}`);
};

export const eventNoDestinationsPort = (errorPort, otherPort) => {
  ga4Event('port_with_no_destinations_selected', {
    list_of_port_names: selectedPortsToPorts([errorPort, otherPort]),
    list_of_port_codes: selectedPortsToPortCodes([errorPort, otherPort]),
    error_port_name: errorPort.alias,
    other_port_name: otherPort.alias,
    error_port_code: errorPort.LocationAbbr,
    other_port_code: otherPort.LocationAbbr
  });
};

export const eventNoProviderPort = (errorPort, otherPort) => {
  ga4Event('unsupported_port_selected', {
    list_of_port_names: selectedPortsToPorts([errorPort, otherPort]),
    list_of_port_codes: selectedPortsToPortCodes([errorPort, otherPort]),
    error_port_name: errorPort.alias,
    other_port_name: otherPort.alias,
    error_port_code: errorPort.LocationAbbr,
    other_port_code: otherPort.LocationAbbr
  });
};

export const eventSamePortsError = ({ alias, LocationAbbr }) => {
  ga4Event('same_ports_selected', {
    port_name: alias,
    port_code: LocationAbbr
  });
};

export const eventImpossibleConnection = (origin, destination) => {
  ga4Event('regionally_unconnected_ports_selected', {
    list_of_port_names: `${origin.alias},${destination.alias}`,
    list_of_port_codes: `${origin.LocationAbbr},${destination.LocationAbbr}`,
    departure_port_name: origin.alias,
    destination_port_name: destination.alias,
    departure_port_code: origin.LocationAbbr,
    destination_port_code: destination.LocationAbbr
  });
};

export const eventSearchWrongInput = ({ alias, LocationAbbr }, lastUserInput) => {
  ga4Event('wrong_input_port_suggested', {
    port_name: alias,
    port_code: LocationAbbr,
    field_value: lastUserInput
  });
};

export const eventSearchOneWayClicked = selectedPorts => {
  ga4Event('single_one_way_clicked', {
    list_of_port_names: selectedPortsToPorts(selectedPorts),
    list_of_port_codes: selectedPortsToPortCodes(selectedPorts),
    departure_port_name: selectedPorts[0].alias,
    departure_port_code: selectedPorts[0].LocationAbbr,
    destination_port_name: selectedPorts[1].alias,
    destination_port_code: selectedPorts[1].LocationAbbr
  });
};

export const eventPortSelected = payload => {
  ga4Event('port_selected', payload);
};

export const eventAccommodationSearch = (accommodationDetails, ports) => {
  ga4Event('accommodation_switch_enabled', {
    ...accommodationDetails,
    list_of_port_names: selectedPortsToPorts(ports),
    list_of_port_codes: selectedPortsToPortCodes(ports)
  });
};

export const eventSearchInfoClicked = mode => {
  ga4Event('modal_opened', { modal_name: `Search:destinations_to_travel_with_fh_${mode}` });
};

const selectedPortsToPorts = selectedPorts => {
  //refers to searchModule's selectedPorts
  let ports = selectedPorts.map(p => {
    if (p.short === '') return '';
    return `${p.alias}`;
  });
  ports = ports.join(' | ');
  return ports;
};

const selectedPortsToPortCodes = selectedPorts => {
  //refers to searchModule's selectedPorts
  let ports = selectedPorts.map(p => {
    if (p.short !== '') return `${p.LocationAbbr}`;
  });
  ports = ports.join(',');
  return ports;
};

const searchesToPorts = searches => {
  let origins = searches.map(s => `${s.originLongName}`);
  let finalPort = searches[searches.length - 1];
  origins.push(`${finalPort.destinationLongName}`);
  return origins.join(' | ');
};

const searchesToPortCodes = searches => {
  let origins = searches.map(s => `${s.origin}`);
  let finalPort = searches[searches.length - 1];
  origins.push(`${finalPort.destination}`);
  return origins.join(',');
};
