<template>
  <div :class="$style.refund">
    <div :class="$style.refund__main">
      <div :class="$style.refund__text">
        {{ trans('passDet.refProtBP.extended.explanation.text') }}
        <ul :class="[$style.refund__list, 'mb0']">
          <li class="mb4">{{ trans('passDet.refProtBP.extended.explanation.bullet.1') }}</li>
          <li class="mb4">{{ trans('passDet.refProtBP.extended.explanation.bullet.2') }}</li>
          <li>{{ trans('passDet.refProtBP.extended.explanation.bullet.3') }}</li>
        </ul>
      </div>
      <span :class="[$style.refund__text, 'text-primary']" @click.stop="showExtendedRefundModal = true">{{ trans('generic.viewFullList') }} </span>
    </div>

    <FhModal v-if="showExtendedRefundModal" @close="showExtendedRefundModal = false" :title="trans('passDet.refProt.extended.modal.title')">
      <template #body>
        <div class="fh-loader" v-if="!hasDescription">
          <div class="loader"></div>
        </div>
        <div v-else v-html="refundProtectDescription"></div>
      </template>
    </FhModal>
  </div>
</template>

<script>
import { EXTRA_SERVICES, getExtraServiceWithRetry, EXTRA_SERVICE_PROVIDERS } from '@/logic/BL/extraServices';
import { logException } from '@/logic/services/events/errorLogging';
import { trans } from '@/filters';

export default {
  name: 'BookingProtect',
  data() {
    return {
      refundProtectDescription: '',
      showExtendedRefundModal: false,
    };
  },
  emits: ['fetchCompleted'],
  created() {
    this.$emit('fetchCompleted', false);

    getExtraServiceWithRetry(EXTRA_SERVICES.REFUND_PROTECT, EXTRA_SERVICE_PROVIDERS.BOOKINGPROTECT)
      .then((response) => (this.refundProtectDescription = response.staticHtml))
      .catch((error) => logException('ExtendedRefund', error))
      .finally(() => this.$emit('fetchCompleted', true));
  },
  methods: {
    trans,
  },
  computed: {
    hasDescription() {
      return this.refundProtectDescription !== '';
    },
  },
};
</script>

<style module lang="scss">
@import './refund.scss';
</style>
