import IndirectTripModel from '@/logic/models/trips/IndirectTripModel';
import settings from '@/settings';
import { arrayUnique } from '../../helpers/arrayFunctions';
import { message } from '../../helpers/utils';
import { JOURNEY_MODE } from './journeyModes';
import { TRIP_UNAVAILABLE_REASONS } from './tripUnavailableReasons';


export const createVerifiedIndirectTrip = (verifiedSegments, indirectTripType) => {

  // Check for single company violation
  if (hasSingleCompanyViolation(indirectTripType, verifiedSegments)) {
    return null;
  }

  let indirectTrip = new IndirectTripModel({ indirectTripType });
  indirectTrip.setTripSegments(verifiedSegments);

  // Check for time limitations
  if (hasTimeConflicts(indirectTrip) || hasHugeWaitTime(indirectTrip)) {
    return null;
  }

  // Apply time limitations
  if (hasSmallWaitTime(indirectTrip)) {
    indirectTrip.available = false;
    indirectTrip.unavailableReason = message('waitTimeDesc');
    indirectTrip.unavailableReasonTitle = message('waitTimeTitle');
    indirectTrip.unavailableReasonCode = TRIP_UNAVAILABLE_REASONS.CONNECTION_WAIT_TIME;
  }

  return indirectTrip;
};


const hasSingleCompanyViolation = (indirectTripType, verifiedSegments) => {
  if (indirectTripType !== JOURNEY_MODE.MULTISEGMENT) {
    return false;
  }
  return arrayUnique(verifiedSegments.map(segment => segment.Details.Company)).length > 1;
}

const hasSmallWaitTime = indirectTrip => {
  const minimumAllowedWaitTime = indirectTrip.indirectTripType === JOURNEY_MODE.INDIRECT ? 
    settings.limits.MINIMUM_PORT_WAIT_TIME : settings.limits.MINIMUM_CONJUNCTION_WAIT_TIME;
  return indirectTrip.segments.some((segment, segmentIndex) => {
    if (segmentIndex === 0) return false;
    return segment.timings.waitTime < minimumAllowedWaitTime;
  });
};

const hasTimeConflicts = indirectTrip => {
  return indirectTrip.segments.some(segment => segment.timings.waitTime < 0);
};

const hasHugeWaitTime = indirectTrip => {
  return indirectTrip.segments.some(segment => segment.timings.waitTime > settings.limits.MAXIMUM_PORT_WAIT_TIME);
};
