/**
 * Checks if an element is inside the viewport
 * @param {string} id the element's id
 */
export const isElementInViewport = id => {
  const element = document.getElementById(id);
  if (!element) return false;
  const rect = element.getBoundingClientRect();
  const { top, right, bottom, left } = rect;
  const screenHeight = window.innerHeight || document.documentElement.clientHeight;
  const screenWidth = window.innerWidth || document.documentElement.clientWidth;
  return top >= 0 && left >= 0 && bottom <= screenHeight && right <= screenWidth;
};
