<template>
  <div :class="[$style.priceMobile, { pt16: hasTripPricingErrors }]" ref="priceMobile">
    <div :class="$style.priceMobile__errors">
      <transition-group tag="div" name="height-fade-out" appear>
        <Notification v-for="(error, errorIndex) in tripPricingErrors" class="error-wrapper mb10" :key="`${error.code}-${errorIndex}`" :message="error.message" />
      </transition-group>
    </div>

    <div :class="$style.priceMobile__row">
      <span class="text-medium">{{ trans('overallprice') }}<InfoModalItem :slug="'currency-rate'" :class="'ml8'" @click="openInfoModalClicked" /></span>
      <span class="text-medium">
        <i v-if="hasTripPricingErrors" class="fh fh-notification"></i>
        <span v-else>{{ currencyStringPre(expectedFinalPrice) }}</span>
      </span>
    </div>
    <div :class="$style.priceMobile__fixed" />
  </div>
</template>

<script>
import { trans, currencyStringPre } from '@/filters';
import { mapGetters } from 'vuex';
import { eventBookingModalOpened } from '@/logic/services/events/createBookingEvents';

export default {
  name: 'PriceMobile',
  mounted() {
    this.observeElement();
  },
  updated() {
    this.observeElement();
  },
  methods: {
    trans,
    currencyStringPre,
    observeElement() {
      const el = this.$refs.priceMobile;
      // if the element intercepts the screen (which in this case essentially means when it reaches the bottom of the screen) add
      // a class to increase its padding. Remove that class in any other case. For more details on the IntersectionObserver API
      // see https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      const observer = new IntersectionObserver(([e]) => e.target.classList.toggle(this.$style['priceMobile--big'], e.intersectionRatio < 1), { threshold: [1] });
      if (el) observer.observe(el);
    },
    openInfoModalClicked() {
      eventBookingModalOpened('currency_rate');
    }
  },
  computed: {
    ...mapGetters({
      tripPricingErrors: 'bookingModule/tripPricingErrors',
      hasTripPricingErrors: 'bookingModule/hasTripPricingErrors',
      expectedFinalPrice: 'bookingModule/expectedFinalPrice',
    }),
  },
};
</script>

<style module lang="scss">
@import './price-mobile.scss';
</style>
