<template>
  <div class="booking-flow-box--grey-bg">
    <h4 :class="$style.refundAlt__header" class="booking-flow-box__header">{{ trans('passDet.refProt.title.oneCard') }}</h4>
    <span :class="[$style['refundAlt__header-sub'], 'booking-flow-box__subtitle', 'mb16']"><i v-if="!isMobile" class="fh fh-info mr8 mt3" />{{ trans('passDet.refProt.subtitle') }}</span>

    <div v-show="!showWaitingState">
      <SelectableCardAlt
        v-if="showBPCard"
        :amount="currencyStringPre(refundProtectPrice)"
        :imageSrc="refundProtectImageUrl"
        :selected="refundProtectSelected && !hasRefundProtectError"
        :isLoading="fetchingForPrices"
        :badgeText="trans('generic.recommended')"
        :hasError="hasRefundProtectError"
        :errorMsg="refundProtectError"
        :footerMsg="trans('passDet.refProt.extended.oneCard.radioButton.text')"
        @click="setRefundOptionSelected({ ancillaryMode: refundOptions.EXTENDED, ancillaryOrder, totalAncillaries: totalAncillaries, ancillaryUIMode: 'single' })"
      >
        <BookingProtectAlt @fetchCompleted="fetchCompleted" />
      </SelectableCardAlt>

      <SelectableCardAlt
        v-else-if="showIntCard"
        :amount="currencyStringPre(refundProtectPrice)"
        :imageSrc="refundProtectImageUrl"
        :selected="refundProtectSelected && !hasRefundProtectError"
        :isLoading="fetchingForPrices"
        :badgeText="trans('generic.recommended')"
        :hasError="hasRefundProtectError"
        :errorMsg="refundProtectError"
        :footerMsg="trans('passDet.refProt.extended.oneCard.radioButton.text')"
        @click="setRefundOptionSelected({ ancillaryMode: refundOptions.EXTENDED, ancillaryOrder, totalAncillaries: totalAncillaries, ancillaryUIMode: 'single' })"
      >
        <InteramericanAlt @fetchCompleted="fetchCompleted" />
      </SelectableCardAlt>

      <SelectableCardSimple :selected="!refundProtectSelected" @click="setRefundOptionSelected({ ancillaryMode: refundOptions.STANDARD })" :hasError="hasRefundProtectError">
        <template v-if="isSingleCarrierBooking">
          {{ trans('passDet.refProt.standard.oneCard.radioButton.text.1.one') }}
          <span :class="[$style.refundAlt__text, $style['refundAlt__text-list'], 'text-primary', $style.refundAlt__text__msg]" @click.stop="showStandardSupportModal = true"
            ><InfoModalItem :customTitle="trans('passDet.refProt.standard.oneCard.radioButton.text.2.one')" :includeIcon="false" :slug="'indicative-cancellation-policies'" @openInfoModalClicked="openInfoModalClicked" />
          </span>
          {{ trans('passDet.refProt.standard.oneCard.radioButton.text.3.one') }}
        </template>
        <template v-else>
          {{ trans('passDet.refProt.standard.oneCard.radioButton.text.1.other') }}
          <span :class="[$style.refundAlt__text, $style['refundAlt__text-list'], 'text-primary', $style.refundAlt__text__msg]" @click.stop="showStandardSupportModal = true">
            <InfoModalItem :customTitle="trans('passDet.refProt.standard.oneCard.radioButton.text.2.other')" :includeIcon="false" :slug="'indicative-cancellation-policies'" @openInfoModalClicked="openInfoModalClicked" />
          </span>
          {{ trans('passDet.refProt.standard.oneCard.radioButton.text.3.other') }}
        </template>
      </SelectableCardSimple>
    </div>
    <div v-show="showWaitingState">
      <SelectableCardSkeletonAlt />
      <SelectableCardSimpleSkeleton />
    </div>
  </div>
</template>

<script>
import SelectableCardAlt from '@/components/shared/SelectableCardAlt/SelectableCardAlt.vue';
import SelectableCardSimple from '@/components/shared/SelectableCardSimple/SelectableCardSimple.vue';
import SelectableCardSkeletonAlt from '@/components/shared/SelectableCardAlt/SelectableCardSkeletonAlt.vue';
import SelectableCardSimpleSkeleton from '@/components/shared/SelectableCardSimple/SelectableCardSimpleSkeleton.vue';
import BookingProtectAlt from '@/components/book/refundAlt/BookingProtectAlt';
import InteramericanAlt from '@/components/book/refundAlt/InteramericanAlt';
import { mapActions, mapState, mapGetters } from 'vuex';
import { EXTRA_SERVICE_OPTIONS, EXTRA_SERVICE_PROVIDERS } from '@/logic/BL/extraServices';
import { eventBookingModalOpened, eventRefundProtectShown } from '@/logic/services/events/createBookingEvents';
import { trans, currencyStringPre, fhImage } from '@/filters';

export default {
  name: 'RefundOptionsAltComponent',
  components: {
    SelectableCardAlt,
    SelectableCardSimple,
    SelectableCardSkeletonAlt,
    SelectableCardSimpleSkeleton,
    BookingProtectAlt,
    InteramericanAlt,
  },
  props: {
    ancillaryOrder: {
      type: Number,
    },
    totalAncillaries: {
      type: Number,
    },
  },
  data: () => ({
    interamericanProvider: EXTRA_SERVICE_PROVIDERS.INTERAMERICAN,
    bookingProtectProvider: EXTRA_SERVICE_PROVIDERS.BOOKINGPROTECT,
    refundOptions: EXTRA_SERVICE_OPTIONS,
    isFetchCompleted: false,
  }),
  methods: {
    trans,
    currencyStringPre,
    ...mapActions({
      setRefundOptionSelected: 'bookingModule/setRefundOptionSelected',
    }),
    fetchCompleted(value) {
      this.isFetchCompleted = value;
      if (value) eventRefundProtectShown(this.refundProtectProvider, this.ancillaryOrder, this.totalAncillaries);
    },
    openInfoModalClicked() {
      eventBookingModalOpened('indicative_cancellation_policies');
    },
  },
  computed: {
    ...mapState({
      fetchingForPrices: (state) => state.uiModule.fetchingForPrices,
      refundProtectPrice: (state) => state.bookingModule.refundProtect.price,
      refundProtectError: (state) => state.bookingModule.refundProtect.error,
    }),
    ...mapGetters({
      refundProtectProvider: 'bookingModule/getRefundProtectProvider',
      refundProtectSelected: 'bookingModule/refundProtectSelected',
      hasRefundProtectError: 'bookingModule/hasRefundProtectError',
      marketingCompanies: 'bookingModule/marketingCompanies',
    }),
    isSingleCarrierBooking() {
      return this.marketingCompanies.every((c) => c === this.marketingCompanies[0]);
    },
    showWaitingState() {
      return !this.isFetchCompleted && !this.hasRefundProtectError;
    },
    refundProtectImageUrl() {
      return this.hasRefundProtectError ? fhImage('assets/cancellation-protection/DisabledCancellationProt.svg') : fhImage('assets/cancellation-protection/HasCancellationProt.svg');
    },
    showBPCard() {
      return this.refundProtectProvider === this.bookingProtectProvider || this.hasRefundProtectError;
    },
    showIntCard() {
      return this.refundProtectProvider === this.interamericanProvider || this.hasRefundProtectError;
    },
  },
};
</script>

<style module lang="scss">
@import './refund-alt.scss';
</style>
