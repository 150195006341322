<template>
  <div>
    <FhDropdown
      :id="inputId"
      :hasError="hasError"
      :value="value"
      :placeholder="placeholder"
      :options="nationalities"
      :optionKeyToTrack="'code'"
      :optionKeyToShow="'name'"
      :hasSearch="true"
      :grayPlaceholder="true"
      @click="handleClick"
      @blur="handleBlur"
      @focus="handleFocus"
      @input="changeNationality"
    >
      <template #options="{ select, dropdownOptions, hasInputValue }">
        <FhDropdownNationalityOption
          v-for="(option, optionIndex) in dropdownOptions"
          :key="`nationality-dropdown-${optionIndex}`"
          :option="option"
          :index="optionIndex"
          :hasInputValue="hasInputValue"
          @click="select(option, optionIndex)"
        />
      </template>
    </FhDropdown>
    <span v-if="hasError && !isInputActive" class="text-error">{{ errorMessage }}</span>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import FhDropdownNationalityOption from '@/components/shared/FhDropdown/FhDropdownNationalityOption';
import validationMixin from '@/logic/validation/validationMixin';
import emitter from '@/emitter';

export default {
  name: 'NationalityInput',
  mixins: [validationMixin],
  components: {
    FhDropdownNationalityOption,
  },
  props: {
    inputId: String,
    placeholder: {
      type: String,
      default: '',
    },
  },
  emits: ['input'],
  data() {
    return {
      isInputActive: false,
    };
  },
  methods: {
    ...mapActions({
      addError: 'validationModule/addError',
      removeError: 'validationModule/removeError',
    }),
    handleBlur() {
      this.isInputActive = false;
      this.validate();
    },
    handleFocus() {
      this.isInputActive = true;
      this.removeError({ name: this.name });
    },
    changeNationality(nationality) {
      if (!nationality) return;
      this.$emit('input', nationality.code);
    },
    handleClick() {
      this.removeError({ name: this.name });
    },
  },
  computed: {
    ...mapState({
      nationalities: (state) => state.dataModule.categorizedCountries,
    }),
    hasError() {
      return !this.isInputActive && this.errorMessage !== '';
    },
    errorMessage() {
      const error = this.errors.find((error) => error.name === this.name);
      return error ? error.message : '';
    },
  },
  watch: {
    hasError(newValue, oldValue) {
      if (newValue === false) return;
      emitter.$emit('onValidationErrorShown', { name: this.name, value: this.value, error: this.errors.find((error) => error.name === this.name) });
    },
  },
};
</script>
