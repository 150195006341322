import { 
    hasCampingOnBoard as hasCampingOnBoardMIN, 
    isCampingOnBoardEnforcable as isCampingOnBoardEnforcableMIN,
    getMessages as getMessagesMIN
} from './operators/MIN'

import { 
    hasCampingOnBoard as hasCampingOnBoardANSF, 
    isCampingOnBoardEnforcable as isCampingOnBoardEnforcableANSF,
    getMessages as getMessagesANSF
} from './operators/ANSF'


const hasCampingOnBoard = (trip, passengers, vehicles) => {

    if (trip.Details.Company === 'MIN')
        return hasCampingOnBoardMIN(trip, passengers, vehicles);

    if (trip.Details.Company === 'ANSF')
        return hasCampingOnBoardANSF(trip, passengers, vehicles);

    if (trip.Details.Company === 'MOCK')
        return true;

    return false;
}

const isCampingOnBoardEnforcable = (trip, passengers, vehicles) => {
    
    if (trip.Details.Company === 'MIN')
        return isCampingOnBoardEnforcableMIN(trip, passengers, vehicles);
      
    if (trip.Details.Company === 'ANSF')
        return isCampingOnBoardEnforcableANSF(trip, passengers, vehicles);

    if (trip.Details.Company === 'MOCK')
        return true;
              
    return false;
}

const getCampingOnBoardMessages = (trip) => {

    if (trip.Details.Company === 'MIN')
        return getMessagesMIN();
   
    if (trip.Details.Company === 'ANSF')
        return getMessagesANSF();   
   
    if (trip.Details.Company === 'MOCK')
        return getMessagesANSF();   

    return '';
}

export const CampingOnBoard = {
    isAvailable: hasCampingOnBoard,
    isEnforcable: isCampingOnBoardEnforcable,
    messages: getCampingOnBoardMessages
};