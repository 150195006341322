import { adaptVehiclePayload, NEW_VEHICLE_ID } from '@/logic/adapters/userAdapter';
import { createSavedVehicle, createDefaultSavedVehicle } from '@/logic/adapters/userAdapter';
import { mapActions, mapState } from 'vuex';
import { mergeVehicleWithSavedVehicle, getDefaultVehicleDetails } from '@/logic/adapters/vehiclesAdapter';
import { eventSavedVehicleSelected, eventNewVehicleSelected } from '@/logic/services/events/createBookingEvents';

export default {
  computed: {
    ...mapState({
      errors: (state) => state.validationModule.errors,
    }),
  },
  methods: {
    ...mapActions({
      updateVehicleDetails: 'userModule/updateVehicleDetails',
      saveVehicleDetails: 'userModule/saveVehicleDetails',
      removeError: 'validationModule/removeError',
    }),
    updateSavedVehicles() {
      this.savedVehicles = this.savedVehicles.map((savedVehicle) => {
        if (savedVehicle.id === NEW_VEHICLE_ID) return savedVehicle;
        const targetUserVehicle = this.user.vehicles.find((vehicle) => vehicle.id === savedVehicle.id);
        return { ...createSavedVehicle(targetUserVehicle), targetVehicleUid: savedVehicle.targetVehicleUid };
      });
    },
    initializeSavedVehicles() {
      if (!this.isUserDefined) return;
      this.savedVehicles = this.user.vehicles.map((v, index) => createSavedVehicle(v, index));
      this.savedVehicles.unshift(createDefaultSavedVehicle());
    },
    async patchSavedVehicle(vehicle) {
      if (!vehicle.shouldSaveDetails) return;

      if (vehicle.isNewVehicle) {
        const payload = { userId: this.user.id, vehiclePayload: adaptVehiclePayload(vehicle) };
        await this.saveVehicleDetails([payload, false]);
      } else {
        const selectedSavedVehicle = this.savedVehicles.find((v) => v.targetVehicleUid === vehicle.uid);
        const payload = { userId: this.user.id, vehicleId: selectedSavedVehicle.id, vehiclePayload: adaptVehiclePayload(vehicle) };
        await this.updateVehicleDetails([payload, false]);
      }
    },
    handleSavedVehicleSelected(savedVehicleId, vehicleUid) {
      this.removeSavedVehicleErrors(vehicleUid);
      this.bindVehicleWithSavedVehicle(savedVehicleId, vehicleUid);
      this.reconcileVehicleWithSavedVehicle(savedVehicleId, vehicleUid);
    },
    removeSavedVehicleErrors(vehicleUid) {
      if (this.errors.length === 0) return;
      const selectedVehicle = this.vehicles.find((v) => v.uid === vehicleUid);
      const plateFieldName = `licencePlate-vehicle${selectedVehicle.vehicleIndex + 1}`;
      if (this.errors.some((error) => error.name === plateFieldName)) this.removeError({ name: plateFieldName });
    },
    bindVehicleWithSavedVehicle(savedVehicleId, vehicleUid) {
      this.savedVehicles = this.savedVehicles.map((v) => {
        if (v.id === NEW_VEHICLE_ID) return v;
        if (v.id === savedVehicleId) return { ...v, targetVehicleUid: vehicleUid };
        if (v.targetVehicleUid === vehicleUid) return { ...v, targetVehicleUid: '' };
        return v;
      });
    },
    reconcileVehicleWithSavedVehicle(savedVehicleId, vehicleUid) {
      if (savedVehicleId === NEW_VEHICLE_ID) {
        this.resetVehiclesDetails(vehicleUid);
        eventNewVehicleSelected();
        return;
      }

      eventSavedVehicleSelected();
      const targetSavedVehicle = this.savedVehicles.find((v) => v.id === savedVehicleId);
      this.vehicles = this.vehicles.map((v) => (v.uid === vehicleUid ? mergeVehicleWithSavedVehicle(v, targetSavedVehicle) : v));
    },
    resetVehiclesDetails(vehicleUid) {
      this.vehicles = this.vehicles.map((v) => (v.uid === vehicleUid ? getDefaultVehicleDetails(v) : v));
    },
  },
};
