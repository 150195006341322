import { storageGet, storageSave } from './Storage';
import { logException } from '@/logic/services/events/errorLogging';
import settings from '@/settings';

const SAVE_KEY = 'fh_search_details';

export const saveLastSearch = (ports, dates) => {
  try {
    const data = {
      locations: ports.map(p => p.LocationAbbr),
      dates: dates.map(d => d.format(settings.formats.dayjsToFCRS))
    };
    storageSave(SAVE_KEY, data, true);
  } catch (exception) {
    logException('saveLastSearch', exception);
  }
};

// returns the last search that the user has performed in his mobile
// device (stored in browser local storage)
export const getLastSavedSearch = () => {
  try {
    const lastSavedSearch = storageGet(SAVE_KEY, true);
    return lastSavedSearch
      ? {
          ...lastSavedSearch,
          source: 'STORAGE'
        }
      : null;
  } catch (ex) {
    return null;
  }
};
