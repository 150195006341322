import { isCabin } from '../../../filterers/SeatOptionsFilterer';
import { removeColon } from '../../../helpers/stringUtils';

/**
 * Create loyalty card validation payload
 */
export const createOfferValidationPayload = (payload, trip, passenger) => {
  // get passenger accommodation and discount for carrier
  const { companySpecificDiscount, companySpecificAccommodation } = passenger;

  // the discount code, which differs (in some cases) for cabins
  let discountCode = companySpecificDiscount.DiscountCode;
  if (companySpecificAccommodation && isCabin(companySpecificAccommodation) && !!companySpecificDiscount.CabinAlias) discountCode = companySpecificDiscount.CabinAlias;
  return {
    provider: trip.source,
    origin: trip.Details.DepStation,
    destination: trip.Details.ArrStation,
    departureDate: trip.timings.DepDate,
    departureTime: removeColon(trip.timings.DepTime),
    companyCode: trip.Details.Company,
    vesselId: trip.Details.VesselID,
    extraAttributes: trip.extraAttributes,
    passengers: [
      {
        passengerId: passenger.id,
        accommodationCode: passenger.selectedAccommodation,
        passengerType: companySpecificDiscount.PassType,
        discountCode,
        ...payload,
      },
    ],
  };
};
