// @ts-check
import dayjs from '@/logic/services/date/dateService';
import settings from '@/settings';
import { isNull, notNull, uniqueId } from '@/logic/helpers/utils';
import ClassesRepository from '@/logic/repositories/ClassesRepository';
import { createTripTimings } from '@/logic/models/trips/createTripTimings';
import { getTripSeatsModel } from '@/logic/models/trips/TripSeatsModel';
import { createVehicleOptions, isTripWithGaraze } from '@/logic/models/trips/TripVehiclesModel';
import TripAnalysisModel from '@/logic/models/trips/TripAnalysisModel';
import CompaniesRepository from '@/logic/repositories/CompaniesRepository';
import PetsRepository from '@/logic/repositories/PetsRepository';
import { getDefaultDiscount } from '@/logic/filterers/DiscountsFilterer';
import { defaultExtraAtrributes, defaultExtraRequirements } from '@/logic/BL';
import { TRIP_STATUS } from './TripStatus';
import { createTripOffer } from '../../BL/common/passengerOffers/passengerOffer';
import { createIslanderCodeOffer } from '../../managers/discounts/IslanderCode/create';
import { createIDS } from './createIDS';
import { createPetPolicy } from '@/logic/repositories/PetsRepository';
import { adaptPetOption, adaptPetType, isCabinCompatiblePetOption, isTripCompatiblePetOption } from '@/logic/adapters/petsAdapter';
import { TRIP_UNAVAILABLE_REASONS, getTripUnavailableReason } from './tripUnavailableReasons';
import { JOURNEY_MODE } from './journeyModes';
import { adaptTripLabels } from './adaptTripLabels';
import { createTripDetails } from './createTripDetails';
import { adaptTripMetadata } from './adaptTripMetadata';

/**
 * @param {any} data
 *
 */
function DirectTripModel(data) {
  
  this.isValidDirectTrip = this.validateApiData(data);

  if (this.isValidDirectTrip) {
    this.create(data);
  };
}

/**
 * @memberof DirectTripModel
 */
DirectTripModel.prototype = {

  /**
   * Check data validity and throw error in case something is not as it should
   * @memberof DirectTripModel
   */
  validateApiData(data) {
    
    if (isNull(data)) {
      return false;
    }

    // check that a company is given, otherwise it is not a valid itinerary
    if (!data.company || data.company === '') {
      return false;
    }

    // discard totally unkown companies
    let companyObject = CompaniesRepository.getCompany(data.company);
    if (isNull(companyObject)) {
      return false;
    }

    // check that the company provider is the same as the provider
    // of this itinerary, otherwise discard it
    if (companyObject.provider && companyObject.provider !== data.provider) {
      return false;
    }

    return true;
  },

  /**
   * Populate basic itinerary data
   * @param {any} data
   */
  create(data) {    
    
    // create unique identifier
    this.id = uniqueId(11);

    this.isDirectTrip = true;
    this.STATUS = TRIP_STATUS.INITIAL;

    // save extra attibutes for trip
    if (!data.extraAttributes || typeof data.extraAttributes === 'string') {
      data.extraAttributes = {};
    }
    this.extraAttributes = {
      ...data.extraAttributes,
      ...defaultExtraAtrributes
    };

    // save extra attibutes for trip
    if (!data.extraRequirements || typeof data.extraRequirements === 'string') {
      this.extraRequirements = {};
    }
    this.extraRequirements = {
      ...data.extraRequirements,
      ...defaultExtraRequirements
    };

    // check availability flag instantly (this is set by the server)
    this.available = data.available;      
    // if unavailability is not defined by an unavailableReason, set the default one
    if (!this.available && isNull(data.unavailableReason)) {
      getTripUnavailableReason({ type: TRIP_UNAVAILABLE_REASONS.UNAVAILABLE_FROM_SOURCE})
    }
    
    // create direct trip details
    this.Details = createTripDetails(data);

    // extract datetimes according to our MicroAPI response model
    let departure = dayjs(data.departure.datetime.date, settings.formats.microApiDateTimeFormat);
    let arrival = dayjs(data.arrival.datetime.date, settings.formats.microApiDateTimeFormat);
    this.timings = createTripTimings(departure, arrival, data);

    // create itinerary analysis
    this.tripAnalysis = new TripAnalysisModel(data, this.Details, this.timings);

    this.createAvailabilyDetails(data);

    // array of stops (port names only), which on a direct trip, are only
    // departure and arrival ports
    this.stops = [this.Details.DepStationName, this.Details.ArrStationName];

    // Directly use loyaltyScheme from trip
    this.loyaltyScheme = createTripOffer(data.loyaltyScheme || {});
    this.residenceDiscount = createTripOffer(data.residenceDiscount || {});
    this.islanderDiscount = createTripOffer(createIslanderCodeOffer(CompaniesRepository.supportsResidentCode(this.Details.Company)));

    this.passengerDetailsRequirements = CompaniesRepository.getPassengerDetailsRequirements(this.Details.hasCountries, [this.Details.Company]);
    this.vehicleDetailsRequirements = { vehicleBrand: this.extraRequirements.vehicleBrand };
    this.petDetailsRequirements = { petDocument: CompaniesRepository.isPetDocRequired(this.Details.Company) };

    // Additional info to be displayed in the trip info modal
    this.vesselDetails = undefined;

    // source
    this.source = data.source;
    // provider
    this.provider = data.provider || 'FHForthCRS';
    // set supported flag according to the trip details
    this.supported = this.Details.supported;
    // check if we are dealing with an alternative date
    this.alternate = data.alternate;
    // calculate duration
    this.duration = this.timings.duration;
    // create ids
    this.IDSCode = createIDS(this);
    // mark overnight trips
    this.overnight = this.timings.overnight;
    // when set to false, all passengers should select the same accommodation
    this.allowMultipleAccommodations = data.singleAccommodation !== true;
    this.boardingMethod = CompaniesRepository.getCompanyBoardingMethod(this.Details.Company);

    this.tripAncillaries = {
      supportPlus: CompaniesRepository.getCompany(this.Details.Company).allowsSupportPlus,
      flexi: CompaniesRepository.getCompany(this.Details.Company).allowsFlexi,
    };

    this.subJourney = {
      type: JOURNEY_MODE.DIRECT,
      IDScode: this.IDSCode
    }

    // evaluate restrictions
    this.evaluateRestrictions(data);

    // calculate the price range only on available trips
    if (true === this.available) {
      this.calculatePriceRange();
    }
  },

  /**
   * Create all the necessary fields for availabilities and ticket selections
   * in the booking flow
   * @param {Object} data
   */
  createAvailabilyDetails(data) {
    
    // create seat availability only if the itinerary is available
    this.passengerAccommodations = [];
    this.vehicleAccommodations = [];
    this.petAccommodations = [];
    if (true === this.available) {
      // create seat options
      let seatsModel = getTripSeatsModel(data, this.Details.Company);
      this.passengerAccommodations = seatsModel.bookableOptions;
      this.defaultAccommodation = seatsModel.defaultOption;
      this.infantAccommodation = seatsModel.infantOption;

      // create vehicles availability
      this.vehicleAccommodations = createVehicleOptions(this.Details);
      this.Details.hasGaraze = isTripWithGaraze(data);
    }


    this.bookingRules = {
      requiresFareQuotation: CompaniesRepository.requiresFareQuotation(this.Details.Company),
      requiresPNRSeparation: CompaniesRepository.requiresPNRSeparation(this.Details.Company)
    };

    this.PricesPerCategory = ClassesRepository.getPricesPerCategory(this.passengerAccommodations);
    this.passengerDiscounts = CompaniesRepository.getCompanyDiscounts(this.Details.Company);
    this.defaultDiscount = getDefaultDiscount(this.passengerDiscounts);


    const isTripWithPetCabins = this.passengerAccommodations.some(a => a.isPet);
    this.petAccommodations = (data.petClasses).map((po) => {
      return adaptPetOption(po, PetsRepository.getPetClass(this.Details.Company, po.abbr))
    }).filter(po => isTripCompatiblePetOption(po, data.petPolicy))
      .filter(po => isCabinCompatiblePetOption(po, isTripWithPetCabins));

    const hasDefaultPetAccommodation = this.petAccommodations.find(a => a.isDefault);
    if (! hasDefaultPetAccommodation) {
      this.petAccommodations = [];
    }

    this.PetTypes = PetsRepository.getPetTypes().map((pt) => {
      return adaptPetType(pt);
    }).filter(pt => isTripCompatiblePetOption(pt, data.petPolicy));

    this.petPolicy = createPetPolicy(data.petPolicy || {}, this.petAccommodations, this.PetTypes);

    this.tripLabels = adaptTripLabels(data.labels || [])
    this.metadata = adaptTripMetadata(data.metadata);
  },
  

  setTripAsUnavailable({code, message, title}) {
    this.available = false;
    this.unavailableReason = message;
    this.unavailableReasonCode = code;
    this.unavailableReasonTitle = title;
  },

  /**
   * Update availability flags and messages for a direct trip based on a list of
   * possible restrictions
   */
  evaluateRestrictions(data) {
    // if no default accommodation has been found, then the itinerary is not bookable
    // this check also covers the infant accommodation, since a default will also
    // be assigned to infants in case the original infant suggestion (by the provider)
    // is not present in the passengerAccommodations    
    if (isNull(this.defaultAccommodation) || (false === this.defaultAccommodation.isActive)) {
      this.setTripAsUnavailable(
        getTripUnavailableReason({ type: TRIP_UNAVAILABLE_REASONS.DEFAULT_ACCOMMODATION_MISSING})
      );
    }

    // make trip unavailable when the companies are not supported or a default discount
    // has not been assigned to a company
    if (isNull(this.defaultDiscount)) {
      this.setTripAsUnavailable(
        getTripUnavailableReason({ type: TRIP_UNAVAILABLE_REASONS.DEFAULT_DISCOUNT_MISSING})
      );
      return;
    }

    // Possible restriction 1: API generated
    const apiUnavailableReason = getTripUnavailableReason(data.unavailableReason);
    if (notNull(apiUnavailableReason)) {
      this.setTripAsUnavailable(
        apiUnavailableReason
      );
      return;
    }
  },
  

  /**
   * Calculates minimum prices based on availability
   */

  calculatePriceRange() {
    let minPrice = 0.0;
    let maxPrice = 0.0;

    // to find minimum price, get all classes that are not for infants
    // however, occasionally there might not be such an option:
    // PIR - AEG, Hellenic gives EC for infants, that is also the only option
    // of the itinerary, and hence we should fetch min price from there
    let nonInfantClasses = this.passengerAccommodations.filter(o => o.isInfant === false);

    if (nonInfantClasses.length > 0) {
      minPrice = Math.min(...nonInfantClasses.map(o => Number(o.ClassAdultBasicPrice)));
      maxPrice = Math.max(...nonInfantClasses.map(o => Number(o.ClassAdultBasicPrice)));
    } else {
      // todo: fix this part as occasionally Blue Star Ferries returns
      // an itinerary as being available, while only garaze exists. So we
      // probably need a second check here
      minPrice = Math.min(...this.passengerAccommodations.map(o => Number(o.ClassAdultBasicPrice)));
      maxPrice = Math.max(...this.passengerAccommodations.map(o => Number(o.ClassAdultBasicPrice)));
    }

    if (isNaN(minPrice) || isNaN(maxPrice)) {
      throw new Error('Error in calculating price range for itinerary');
    }

    this.minPrice = minPrice,
    this.maxPrice = maxPrice;
  },
};

export default DirectTripModel;