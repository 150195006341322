<template>
  <div class="booking-flow-box">
    <h4 class="booking-flow-box__header">{{ trans('passDet.billingInfo.title') }}</h4>
    <div class="booking-flow-box__info">{{ trans('passDet.billingInfo.description') }}</div>
    <ToggleSwitch class="mt16" :hasFixedHeight="false" :value="invoiceSelected" :text="trans('passDet.billingInfo.invoice.checkbox')" @input="setInvoiceSelected(!invoiceSelected)" />
    <div v-if="invoiceSelected">
      <InvoiceDetailsForm class="mt16"/>
    </div>
  </div>
</template>

<script>
import { trans } from '@/filters';
import { defineAsyncComponent } from 'vue';
import { mapActions, mapState } from 'vuex';

const InvoiceDetailsForm = defineAsyncComponent(() => import('./InvoiceDetailsForm'));

export default {
  name: 'InvoiceDetails',
  components: {
    InvoiceDetailsForm
  },
  computed: {
    ...mapState({
      invoiceSelected: state => state.invoiceModule.invoiceSelected
    })
  },
  methods: {
    trans,
    ...mapActions({
      setInvoiceSelected: 'invoiceModule/setInvoiceSelected'
    })
  }
};
</script>
