<template>
  <div :class="$style.travelerDetailsForm">
    <div :class="$style.travelerDetailsForm__main">
      <div class="form-grid__traveler-title pl0">
        <span>
          <i class="fh fh-pet"></i>
          <span data-test="petDescription" class="pl4">{{ petDescription(pet) }}</span>
        </span>
      </div>

      <div class="form-grid__inner-grid" data-test="petDetailsContainer">
        <PetDetailsSelection v-for="(item, selectionIndex) in pet.selectionsPerTrip" :key="`pet-selection-${pet.petIndex}-${selectionIndex}`" :iconUrl="item.companyIcon" :description="item.selectionDescription" />
      </div>

      <div class="form-grid__inputs mt16" v-if="requiredDetails.petDocument">
        <FhInput
          :model-value="pet.petDocument"
          @update:modelValue="handleInput('petDocument', $event)"
          :name="`petDocument-${pet.petIndex}`"
          :id="`petDocument-${pet.petIndex}`"
          :placeholder="trans('passDet.pets.petPassport.placeholder')"
          :rules="'required|min:5|max:25'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PetDetailsSelection from '@/components/book/PetDetailsSelection/PetDetailsSelection';
import { mapState } from 'vuex';
import { trans, petDescription } from '@/filters';

export default {
  name: 'PetDetailsForm',
  props: {
    pet: Object,
  },
  components: {
    PetDetailsSelection,
  },
  emits: ['input'],
  methods: {
    trans,
    petDescription,
    handleInput(key, value) {
      this.$emit('input', this.pet.petIndex, key, value);
    },
  },
  computed: {
    ...mapState({
      requiredDetails: (state) => state.bookingModule.petDetailsRequirements,
    }),
  },
};
</script>

<style module lang="scss">
@import './traveler-details-form.scss';
</style>
