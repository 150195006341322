<template>
<div class="results-app__footer results-app__footer--action" @click="resetSelection()" data-test="showAllRoutesCta">
  <span> {{ trans('showallroutes') }} </span>
</div>
</template>

<script>
import { trans } from '@/filters';

export default {
  name: 'ResultsAppDeselectCta',  
  emits: ['resetSelection'],
  methods: {
    trans,
    resetSelection() {
      this.$emit('resetSelection')
    }
  }
}
</script>