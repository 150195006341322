// @ts-check
import settings from '@/settings';

export const createVehicleForSeating = (vehicleIndex, uid, passengerIndex) => {
  return {
    uid,
    id: `PD-VEHICLE-${uid}`,
    ...createVehicleTicket(),
    vehicleIndex,
    driverIndex: passengerIndex,
  };
};

const createVehicleTicket = () => ({
  // The abbreviation of car depending on the operator
  TypeAbbr: '',
  // The category of car (in the form of category ID, eg. 1)
  // depending on our system (defaults to car < 4.25)
  // - this value is operator-agnostic
  TypeCategory: settings.defaults.defaultVehicleCategory,
  // Flag indicating motorhomes
  isMotorhome: false,
  // Flag indicating camping on board
  wantsCampingOnBoard: false,
  // numberic value for height (TODO: understand where this applies)
  height: 199,
  ticketPriceInCents: undefined
});


export const changeDriverOfVehicle = (vehicle, newPassenger) => {
  return {
    ...vehicle,
    driverIndex: newPassenger.passengerIndex
  };
};