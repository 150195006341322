import { groupBy as _groupBy, chunk as _chunk } from 'lodash-es';
import { getTripSeatOptionFromAbbr } from '@/logic/filterers/SeatOptionsFilterer';
import { notNull } from '@/logic/helpers/utils';
import { restartArrayFromIndex } from '@/logic/helpers/arrayFunctions';

/**
 * Arrange passengers in cabin groups. Passengers with no selected accomodation are ignored and each passenger in deck is
 * treated as a single group
 * @param {Object} trip
 */
export const arrangePassengersInGroups = trip => {
  let adultPassengers = trip.passengers.filter(p => !p.isInfant);
  let passengersPerAccomodation = _groupBy(adultPassengers, 'selectedAccommodation');
  let passengerGroups = [];
  let passengerInitiatingSelection = trip.passengers.find(pass => pass.seatSelectionMessage !== '');

  Object.entries(passengersPerAccomodation).forEach(entry => {
    let [abbr, group] = entry;

    if (abbr === '') return;

    let groupSeatOption = getTripSeatOptionFromAbbr(trip, abbr);

    let indexOfPassenger;

    if (notNull(passengerInitiatingSelection)) {
      indexOfPassenger = group.findIndex(passenger => passenger.id === passengerInitiatingSelection.id);
    }

    // This covers the case where the same cabin type is selected for multiple passengers e.g 2 two-bed cabins are
    // selected for 4 passengers
    if (groupSeatOption.accommodationCapacity < group.length) {
      let rearrangedPassengers = indexOfPassenger ? restartArrayFromIndex(group, indexOfPassenger) : group;
      let groupSplit = _chunk(rearrangedPassengers, groupSeatOption.accommodationCapacity);

      groupSplit.forEach(item => passengerGroups.push(item));
      return;
    }

    passengerGroups.push(group);
  });

  return passengerGroups;
};
