<template>
  <div>
    <div class="checkbox">
      <label :class="{ checked: isSubscribed }">
        <input name="newsletter" type="checkbox" :value="isSubscribed" @change="onNewsletterSubscriptionChange" />
        {{ trans('passDet.summary.checkbox.newsletter.text1') }}<button class="btn-link p0" @click="$emit('showPrivacyModal')">{{ trans('passDet.summary.checkbox.newsletter.text2') }}</button
        >{{ trans('passDet.summary.checkbox.newsletter.text3') }}
      </label>
    </div>

    <div class="pt12 pb24">
      <div :class="['checkbox checkbox-terms', { 'checkbox-error': hasTermsError }]">
        <label :class="{ checked: modelValue }">
          <input type="checkbox" :value="modelValue" @change="handleChange" />{{ trans('termscheckbox1') }}
          <button class="btn-link p0" @click="$emit('showPrivacyModal')">{{ trans('termslink1') }}</button>
          {{ trans('termscheckbox2') }}
          <button class="btn-link p0" @click="$emit('showTermsModal')">{{ trans('termslink2') }}</button>
          {{ trans('termscheckbox3') }} ({{ marketingCompaniesNames }}).
        </label>
      </div>
    </div>

    <FhCta :disabled="true" v-show="bookingDisabled">
      <i class="fh fh-unhappy" aria-hidden="true"></i>
    </FhCta>

    <FhCta v-show="!bookingDisabled" @click="$emit('finalCheckout')" :isLadda="true" :isWaiting="isWaiting">
      {{ trans('payandbook') }}
      <span class="ml5 price">
        <span v-if="hasTripPricingErrors">€ &ndash;&nbsp;&ndash;</span>
        <span v-else>{{ currencyStringPre(expectedFinalPrice) }}</span>
      </span>
    </FhCta>
    <transition-group name="height-fade-out" tag="div" appear>
      <Notification v-if="bookingDisabled" class="mt5" :message="bookingDisableNotification" />
      <Notification v-for="error in errorBag" :key="error.code" class="mt5" :message="error.message" />
    </transition-group>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { trans, currencyStringPre } from '@/filters';

export default {
  name: 'PayCta',
  props: {
    isSubscribed: {
      type: Boolean,
      required: true,
    },
    errorBag: Array,
    modelValue: Boolean,
    isWaiting: Boolean,
  },
  emits: ['update:modelValue', 'newsletterSubscriptionInput', 'showPrivacyModal', 'showTermsModal', 'finalCheckout'],
  computed: {
    ...mapState({
      bookingDisabled: (state) => state.navigationModule.bookingDisabled,
      bookingDisableNotification: (state) => state.navigationModule.bookingDisableNotification,
    }),
    ...mapGetters({
      hasTripPricingErrors: 'bookingModule/hasTripPricingErrors',
      marketingCompaniesNames: 'bookingModule/marketingCompaniesNames',
      expectedFinalPrice: 'bookingModule/expectedFinalPrice',
    }),
    hasTermsError() {
      return this.errorBag.some((error) => error.code === 'termsMissing');
    },
  },
  methods: {
    trans,
    currencyStringPre,
    handleChange(e) {
      this.$emit('update:modelValue', e.target.checked);
    },
    onNewsletterSubscriptionChange(event) {
      this.$emit('newsletterSubscriptionInput', event.target.checked);
    },
  },
};
</script>
