<template>
  <div class="contact-details">
    <div :class="mainClass">
      <h4 class="contact-details__header">{{ trans('contactdetails') }}<InfoModalItem :slug="'personal-data-ticket'" class="ml8" @openInfoModalClicked="openInfoModalClicked" /></h4>
      <div class="contact-details__inputs">
        <div class="contact-details__email">
          <FhInput
            name="reservationEmail"
            id="reservationEmail"
            :placeholder="trans('generic.email')"
            :model-value="modelValue.Email"
            @update:modelValue="update('Email', $event.trim())"
            rules="required|email|min:6|max:190"
          />
        </div>
        <div class="contact-details__phone-wrapper">
          <div class="contact-details__phone-inputs">
            <div class="contact-details__phone-country-code">
              <CountryCodeInput
                name="countryCode"
                id="countryCode"
                rules="required"
                :placeholder="trans('passDet.contact.countryCode.placeholder')"
                :value="modelValue.PhoneCountryCode"
                @update:modelValue="update('PhoneCountryCode', $event)"
              />
            </div>
            <FhInput
              class="contact-details__phone"
              name="reservationPhone"
              id="reservationPhone"
              :placeholder="trans('passDet.contact.phone.placeholder')"
              :model-value="modelValue.Phone"
              @update:modelValue="update('Phone', $event)"
              rules="required|numericAllowDashes|customPhone"
              :sanitizingFunction="replaceSpaceWithDash"
              :shouldShowError="hasCountryCodeAndPhoneErrors ? false : true"
            />
          </div>
          <span class="text-error" v-if="hasCountryCodeAndPhoneErrors || hasCountryCodeError">{{ trans('generic.error.requiredField') }}</span>
        </div>
      </div>
    </div>
    <div class="contact-details__toggle" v-if="isUserDefined">
      <ToggleSwitch :hasFixedHeight="false" :value="shouldUpdateContactDetails" :text="trans('passDet.contactDetails.saveChangesSwitch')" @input="handleInput" />
    </div>
  </div>
</template>

<script>
import CountryCodeInput from '@/components/shared/inputs/CountryCodeInput';
import { updateObjectWithProp } from '@/logic/helpers/objectManipulation';
import { mapState, mapGetters } from 'vuex';
import { eventSaveDetailsClicked, eventBookingModalOpened } from '@/logic/services/events/createBookingEvents';
import { trans } from '@/filters';
import { replaceSpaceWithDash } from '@/logic/helpers/stringUtils';

export default {
  name: 'ContactDetailsForm',
  components: {
    CountryCodeInput,
  },
  props: {
    modelValue: Object,
  },
  data: () => ({
    replaceSpaceWithDash: replaceSpaceWithDash,
    shouldUpdateContactDetails: false,
    isSwitchClicked: false,
    firstName: '',
  }),
  emits: ['shouldUpdateContactDetails', 'update:modelValue'],
  computed: {
    ...mapState({
      user: (state) => state.userModule.user,
      errors: (state) => state.validationModule.errors,
    }),
    ...mapGetters({
      isUserDefined: 'userModule/isUserDefined',
    }),
    hasCountryCodeError() {
      return this.errors.some((error) => error.name === 'countryCode');
    },
    hasPhoneError() {
      return this.errors.some((error) => error.name === 'reservationPhone');
    },
    hasCountryCodeAndPhoneErrors() {
      return this.hasCountryCodeError && this.hasPhoneError;
    },
    userHasChangedEmail() {
      if (!this.isUserDefined) return false;
      if (this.modelValue.Email === undefined) return false;
      return this.modelValue.Email !== this.user.contactDetails.email;
    },
    userHasChangedPhoneCode() {
      if (!this.isUserDefined) return false;
      if (this.modelValue.PhoneCountryCode === undefined) return false;
      return this.modelValue.PhoneCountryCode !== this.user.contactDetails.phoneCode;
    },
    userHasChangedPhone() {
      if (!this.isUserDefined) return false;
      if (this.modelValue.Phone === undefined) return false;
      return this.modelValue.Phone !== this.user.contactDetails.phone;
    },
    userHasChangedContactDetails() {
      return this.userHasChangedEmail || this.userHasChangedPhoneCode || this.userHasChangedPhone;
    },
    mainClass() {
      return this.isUserDefined ? 'contact-details__main--save' : 'contact-details__main';
    },
  },
  watch: {
    userHasChangedContactDetails() {
      this.shouldUpdateContactDetails = this.userHasChangedContactDetails;
      this.isSwitchClicked = false;
    },
    shouldUpdateContactDetails() {
      this.$emit('shouldUpdateContactDetails', this.shouldUpdateContactDetails);
      if (this.isSwitchClicked) eventSaveDetailsClicked(this.shouldUpdateContactDetails);
    },
  },
  methods: {
    trans,
    update(key, value) {
      if (this.userHasChangedContactDetails) this.shouldUpdateContactDetails = true;
      this.$emit('update:modelValue', updateObjectWithProp(this.modelValue, key, value));
    },
    handleInput() {
      this.shouldUpdateContactDetails = !this.shouldUpdateContactDetails;
      this.isSwitchClicked = true;
    },
    openInfoModalClicked() {
      eventBookingModalOpened('personal_data_ticket');
    },
  },
};
</script>
