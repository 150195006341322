<template>
  <div class="request-ref-wrapper">
    <div class="request-ref-card" :style="shouldShowImg ? {} : { height: 'auto' }">
      <div class="request-ref-content">
        <img v-if="shouldShowImg" class="request-ref-photo" :src="svgUrl" @error="handleImgError" />

        <div class="request-ref-text">
          <h2>{{ trans('refreshModalTitle') }}</h2>
          <p>{{ trans('refreshModalText') }}</p>
        </div>

        <div>
          <FhCta class="request-ref-btn" @click="handleButtonClick"> {{ trans('refreshModalPrimaryButtonText') }}</FhCta>
          <a :class="`text-8 text--${variant}`" @click="onClickRedirectToHome" href="/"> {{ trans('refreshModalSecondaryButtonText') }} </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ga4Event } from '@/logic/services/events/EventService';
import { trans, currencyStringPre, fhImage } from '@/filters';

export default {
  name: 'RequestRefreshPopup',
  props: {
    variant: {
      type: String,
      default: 'ferryhopper',
    },
  },
  data() {
    return {
      shouldShowImg: true,
    };
  },
  computed: {
    svgUrl() {
      return fhImage(`assets/popups/${this.variant}-refresh-modal.svg`);
    },
  },
  methods: {
    trans,
    currencyStringPre,
    handleButtonClick() {
      ga4Event('refresh_modal_CTA_clicked', {});
      window.location.hash = this.$store.state.navigationModule.resultsHash;
      window.location.reload();
    },
    handleImgError() {
      this.shouldShowImg = false;
    },
    onClickRedirectToHome() {
      ga4Event('refresh_modal_CTA_clicked', {});
    },
  },
};
</script>
