<template>
  <div :class="mainClass">
    <i :class="[$style.fhCartNotification__icon, iconClass]" />
    <div :class="$style.fhCartNotification__message">{{ message }}</div>
  </div>
</template>

<script>
export default {
  name: 'FhCartNotification',
  props: {
    message: {
      type: String,
      required: true,
      default: ''
    },
    warning: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    mainClass() {
      return this.warning ? `${this.$style.fhCartNotification} ${this.$style['fhCartNotification--warning']}` : this.$style.fhCartNotification;
    },
    iconClass() {
      return this.warning ? 'fh fh-notification' : 'fh fh-info';
    }
  }
};
</script>

<style module lang="scss">
@import './fh-cart-notification.scss';
</style>
