<template>
  <div class="results-app__status">
    <span class="results-app__status__text" v-html="content"></span>
    <img class="py10" :src="fhImage('assets/results/AegeanNoRoutesIcon.svg')" v-if="!hasDirectResults && !isLoadingDirect && !hasError" />
  </div>
</template>

<script>
import ResultsAppStatus from '@/components/results/ResultsAppStatus';

export default {
  name: 'results-app__status-bar',
  extends: ResultsAppStatus,
};
</script>
