import emitter from '@/emitter';
import CartManager from '@/logic/managers/CartManager';
import { eventTripAddToCart, eventTripRemoveFromCart } from '@/logic/services/events/createCartEvents';
import { notNull } from '@/logic/helpers/utils';
import { scrollToElementId } from '@/logic/dom/scrollTo';
import { ga4Event } from '@/logic/services/events/EventService';
import { brazeEventAddToCart } from '@/logic/services/crm/brazeEvents';
import { startBrazeSession } from '@/logic/services/crm/braze';
import { mapActions, mapGetters } from 'vuex';
import { getActiveRoundTripOfferLabel } from './activeCartLabels';

export default {
  props: {
    tripsCount: Number,
  },
  data: () => ({
    cart: CartManager,
  }),
  created() {
    this.cart.expectedTripsCount = this.tripsCount;

    emitter.$on('onCartReset', () => {
      this.cart.reset();
      this.cart.expectedTripsCount = this.tripsCount;
    });

    emitter.$on('onItinerarySelection', ({ selection, index }) => {
      const addedTrip = this.cart.addTrip(selection, index);
      eventTripAddToCart(addedTrip, { origin: this.selectedPortsAbbr[index], destination: this.selectedPortsAbbr[index + 1] });
    });

    emitter.$on('onItineraryDeselection', (index) => {
      const removedTrip = this.cart.removeTrip(index);
      eventTripRemoveFromCart(removedTrip, { origin: this.selectedPortsAbbr[index], destination: this.selectedPortsAbbr[index + 1] });
    });
  },
  watch: {
    tripsCount(newVal) {
      this.cart.expectedTripsCount = newVal;
    },
    tripSelectionFinished() {
      if (!this.tripSelectionFinished) emitter.$emit('ItinerariesSelectionFinished', false);
    },
  },
  computed: {
    ...mapGetters({
      isRoundTripSearch: 'searchModule/isRoundTripSearch',
      selectedPortsAbbr: 'searchModule/selectedPortsAbbr',
    }),
    tripSelectionFinished() {
      return this.cart.selectedTripsCount !== 0 && this.cart.selectedTripsCount === this.tripsCount;
    },
    overallPrice() {
      return this.cart.overallPrice;
    },
    hasGarageLimitation() {
      return this.$store.state.searchModule.vehicles > 0 && this.cart.hasGarage === false;
    },
    hasPetsLimitation() {
      return this.$store.state.searchModule.pets > 0 && this.cart.hasPets === false;
    },
    hasWarningsOnCheckout() {
      return (
        this.cart.checkSelectionCount() === false || this.cart.hasTimeOverlaps === true || this.cart.hasReachedMaxTrips === true || this.hasGarageLimitation || this.hasPetsLimitation || this.bookingDisabled || false
      );
    },
    bookingDisabled() {
      return this.$store.state.navigationModule.bookingDisabled;
    },
    bookingDisableNotification() {
      return this.$store.state.navigationModule.bookingDisableNotification;
    },
    showTimeOverlapsWarning() {
      return this.cart.hasTimeOverlaps;
    },
    showMaxTripsWarning() {
      return this.cart.hasReachedMaxTrips;
    },
    showGarageWarning() {
      return this.hasGarageLimitation;
    },
    showPetsWarning() {
      return this.hasPetsLimitation;
    },
    showDateWarning() {
      return this.cart.hasCloseDateTrip;
    },
    roundTripOfferLabel() {
      if (!this.isRoundTripSearch) return null;
      // TECHNICAL DEPT: Changes on cart.selectedTrips does not trigger a re-execution of this
      // computed value. For this reason, we use the cart.seletedTripsCount that is dynamic.
      // This needs fixing in the future.
      return getActiveRoundTripOfferLabel(this.cart.selectedTrips, this.cart.selectedTripsCount);
    },
  },
  methods: {
    ...mapActions({
      setTimer: 'navigationModule/setTimer',
    }),
    tripDefined(trip) {
      return notNull(trip);
    },
    onTripDeselected(index) {
      const removedTrip = this.cart.removeTrip(index);
      eventTripRemoveFromCart(removedTrip, { origin: this.selectedPortsAbbr[index], destination: this.selectedPortsAbbr[index + 1] });
      emitter.$emit('onTripDeselectedFromCart', index);
    },
    onCheckoutClicked() {
      if (!this.tripSelectionFinished) {
        this.hasCheckoutError = true;
        if (this.isMobile) scrollToElementId('results-cart', 'smooth');
        ga4Event('not_all_trips_selected', {
          error_description: `${this.cart.selectedTripsCount}_of_${this.tripsCount}_selected`,
        });
        return;
      }

      if (this.hasWarningsOnCheckout) return;

      // setTimer for asking to refresh page modal
      this.setTimer();
      let trips = this.cart.getFlatTrips();

      // The braze sdk is initialized on the Results App, using the user's consent for marketing cookies (see braze.js).
      // We call startBrazeSession here to cover the following edge case: a user proceeds to a later step e.g. Seating, Booking
      // without accepting or declining cookies, but then returns to the Results App, accepts cookies and selects
      // a new itinerary. In that case, we need to initialize the braze sdk in order to register an add to cart event.
      startBrazeSession();

      brazeEventAddToCart(trips);

      emitter.$emit('onCartCheckout', trips);
      emitter.$emit('ItinerariesSelectionFinished', true);
    },
  },
};
