<template>
  <div>
    <!-- cart heading (desktop devices) -->
    <div class="aegean-simple-bar" v-if="!isMobile">
      <span>{{ trans('carttitle') }}</span>
    </div>
    <div>
      <div id="cart-selections-list-multi" v-if="!isMobile">
        <!-- empty cart placeholder -->
        <div class="cart-selection-item" style="margin: 4px 0px 0px 0px" :data-before="trans('notripsselected')" v-if="isCartEmpty"></div>

        <div v-for="(tripIndex, index) in tripsCount" class="cart-selection-item" :data-before="placeholder(tripIndex)" :key="'cart-item-' + index">
          <AegeanCartItem :trip="cart.selectedTrips[tripIndex - 1]" @onTripDeselected="onTripDeselected(tripIndex - 1)"></AegeanCartItem>
        </div>
      </div>

      <div class="cart-item-box" v-if="!isMobile">
        <div class="cart-item-heading final clearfix">
          <span class="cart-price-text cart-price-label pull-left">{{ trans('aegeanlabeltotalprice') }}</span>
          <span class="cart-price-text cart-price-value pull-right" v-html="aegeanCurrency(cart.overallPrice)"></span>
        </div>
      </div>

      <CheckoutButtonsComponent class="mb10" :disable="hasWarningsOnCheckout" :backButtonText="trans('back')" :nextButtonText="trans('continue')" @onBackClicked="onBackClicked" @onNextClicked="onCheckoutClicked" />

      <div v-if="bookingDisabled">
        <CartNotification :message="bookingDisableNotification" :level="'danger'" />
      </div>
      <div v-else>
        <CartNotification v-if="showTimeOverlapsWarning" :message="trans('conflictingconnections')" :level="'danger'" />
        <CartNotification v-if="showMaxTripsWarning" :message="trans('triplimitswarning')" :level="'danger'" />
        <CartNotification v-if="showGarageWarning" :message="trans('novehiclesupport')" :level="'danger'" />
        <CartNotification v-if="showDateWarning" :message="trans('closetripdatewarning')" :level="'danger'" />
      </div>
    </div>
  </div>
</template>

<script>
import emitter from '@/emitter';
import AegeanCartItem from '@/affiliates/aegeanair/components/results/cart/AegeanCartItem';
import FhResultsCartMixin from '@/components/cart/ResultsCart/FhResultsCartMixin';
import CheckoutButtonsComponent from '@/affiliates/aegeanair/components/shared/CheckoutButtons/CheckoutButtonsComponent';
import CartNotification from '@/affiliates/aegeanair/components/results/cart/CartNotification.vue';
import { NAVIGATION_TABS } from '@/store/modules/navigation.module';
import { message } from '@/logic/helpers/utils';
import { trans, aegeanCurrency } from '@/filters';
// Vuex
import { mapActions } from 'vuex';

export default {
  name: 'ResultsCart',
  components: {
    AegeanCartItem,
    CheckoutButtonsComponent,
    CartNotification,
  },
  mixins: [FhResultsCartMixin],
  computed: {
    isCartEmpty() {
      return this.cart.selectedTripsCount === 0;
    },
  },
  methods: {
    trans,
    aegeanCurrency,
    ...mapActions({
      changeNavTab: 'navigationModule/changeNavTab',
      disableNavTabs: 'navigationModule/disableNavTabs',
    }),
    placeholder(index) {
      return `${message('cartplaceholder')} ${index}`;
    },
    onBackClicked() {
      if (this.cart.getSelectionCount() > 0) {
        this.cart.deselectAll();
        this.cart = this.cart;
        this.$forceUpdate();
        for (let i = 0; i < this.cart.expectedTripsCount; i++) {
          emitter.$emit('onTripDeselectedFromCart', i);
        }
      } else {
        this.cart.reset();
        this.cart = this.cart;
        this.$forceUpdate();
        this.changeNavTab(NAVIGATION_TABS.SEARCH);
        this.disableNavTabs([NAVIGATION_TABS.RESULTS]);
      }
    },
  },
};
</script>
