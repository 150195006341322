<template>
  <div class="container booking-flow__tab">
    <!-- book section header -->
    <AegeanBookingFlowSectionHeader :order="'3'" :title="trans('tabbooktitle')" id="book-tab-top" />

    <transition-group name="height-fade-out" id="pricingErrorsWrapper" tag="div" appear>
      <Notification
        v-for="(error, errorIndex) in tripPricingErrors"
        class="error-wrapper mb10"
        :key="`${error.code}-${errorIndex}`"
        :message="error.message"
        :id="`trip-header-${errorIndex}`"
        :data-trip-header-id="`trip-header-${errorIndex}`"
      />
    </transition-group>

    <!-- booking review -->
    <BookingSummary v-if="!isMobile" @onBackClicked="onBackClicked()"> </BookingSummary>

    <div v-if="isMobile">
      <div v-if="trips && trips.length > 0">
        <div v-for="(trip, tripIndex) in trips" class="cart-selection-item" :key="'cart-item-' + tripIndex">
          <AegeanCartItem :trip="trip" :showOverallPrice="true"></AegeanCartItem>
        </div>
      </div>
    </div>

    <!-- Passengers and vehicles -->
    <div class="row" id="passengers-details-fields">
      <div class="col-xs-12">
        <hr class="mb0" />
      </div>
      <div class="col-sm-8 fp-main-column">
        <div class="aegean-booking-tab-heading mb20">
          <span class="title pull-left" :class="{ pl12: isMobile }">{{ trans('passDet.personalDetails.passengersVehiclesHeading') }}</span>
        </div>

        <div v-if="passengers.length > 0" :class="['mb15', { px15: isMobile }]">
          <PassengerDetailsForm
            v-for="passenger in passengers"
            :key="passenger.id"
            :passenger="passenger"
            :savedPassengers="savedPassengers"
            @input="handlePassengersFormInput"
            @onPriceAffectingChange="onPriceAffectingChange"
          />
        </div>

        <div v-if="vehicles.length > 0" :class="['mb15', { px15: isMobile }]">
          <div v-for="(vehicle, vehicleIndex) in vehicles" :key="'vehicle-details-' + vehicleIndex">
            <VehicleDetailsForm class="mb15" :vehicle="vehicle" :savedVehicles="savedVehicles" :vehicleIndex="vehicleIndex" @input="handleVehiclesFormInput" @savedVehicleSelected="handleSavedVehicleSelected" />
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-4">
        <AegeanContactDetailsForm class="contact-details-container" v-model="contactInfo" />
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12">
        <hr class="mb0" />
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12">
        <TicketCollection />
      </div>
    </div>

    <!-- Pay and book -->
    <div class="row">
      <div class="col-xs-12">
        <InvoiceDetails />
      </div>
      <div class="col-xs-12">
        <hr />
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-8 fp-main-column">
        <div class="form-group">
          <div class="checkbox checkbox-terms">
            <label :class="{ checked: acceptsConditions }">
              <input type="checkbox" v-model="acceptsConditions" />{{ trans('termscheckbox1') }}
              <button class="btn-link p0" @click="showPrivacyModal = true">{{ trans('termslink1') }}</button>
              {{ trans('termscheckbox2') }}
              <button class="btn-link p0" @click="showTermsModal = true">{{ trans('termslink2') }}</button>
              {{ trans('termscheckbox3') }} ({{ marketingCompaniesNames }}).
            </label>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-4">
        <div class="cart-container">
          <div class="aegean-cart-item thin">
            <div class="aegean-cart-item-price-row clearfix">
              <span class="cart-price-text cart-price-label pull-left">{{ trans('ticketspricinglabel') }}</span>
              <span class="cart-price-text cart-price-value pull-right" v-html="aegeanCurrency(overallPriceForTrips)"></span>
            </div>
          </div>
          <div class="aegean-cart-item thin" v-if="serviceFeeCost > 0.0">
            <div class="aegean-cart-item-price-row clearfix">
              <span class="cart-price-text cart-price-label pull-left"> {{ trans('tab4servicefee') }}</span>
              <span class="cart-price-text cart-price-value pull-right" v-html="aegeanCurrency(serviceFeeCost)"></span>
            </div>
          </div>
          <div class="cart-item-box">
            <div class="cart-item-heading final clearfix">
              <span class="cart-price-text cart-price-label pull-left">{{ trans('aegeanlabeltotalprice') }}</span>
              <!-- expectedPriceBeforeDiscount equals expectedFinalPrice since we do not support coupons in aegean -->
              <span class="cart-price-text cart-price-value pull-right" v-html="aegeanCurrency(expectedFinalPrice)"></span>
            </div>
          </div>
        </div>

        <div id="booking-checkout-container">
          <CheckoutSingleButtonComponent :disable="true" v-if="bookingDisabled">
            <slot><i class="fh fh-unhappy" aria-hidden="true"></i></slot>
          </CheckoutSingleButtonComponent>

          <CheckoutSingleButtonComponent v-else @onCheckoutClick="finalCheckout" :isLadda="true" :isWaiting="isWaiting">
            <slot>{{ trans('payandbook') }}</slot>
          </CheckoutSingleButtonComponent>

          <ul class="list-unstyled">
            <li v-if="bookingDisabled">
              <small class="text-danger">{{ bookingDisableNotification }}</small>
            </li>
            <li v-for="error in errorBag" :key="error.code">
              <small class="text-danger">{{ error.message }}</small>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- hidden form for embeddin checkout data -->
    <div id="checkout-form-container" class="hidden"></div>

    <!-- Privacy modal -->
    <FhModal v-if="showPrivacyModal" @close="showPrivacyModal = false" :title="trans('privacymodaltitle')">
      <template #body>
        <LoadingSpinner v-if="privacyContent === ''" />
        <div v-else v-html="privacyContent"></div>
      </template>
    </FhModal>

    <!-- Terms modal -->
    <FhModal v-if="showTermsModal" @close="showTermsModal = false" :title="trans('termsmodaltitle')">
      <template #body>
        <LoadingSpinner v-if="termsContent === ''" />
        <div v-else v-html="termsContent"></div>
      </template>
    </FhModal>
  </div>
</template>

<script>
import BookingApp from '@/components/book/BookingApp';
import AegeanBookingFlowSectionHeader from '@/affiliates/aegeanair/components/shared/BookingFlowSectionHeader';
import AegeanCartItem from '@/affiliates/aegeanair/components/results/cart/AegeanCartItem';
import InvoiceDetails from '@/components/book/invoice/InvoiceDetails';
import CheckoutSingleButtonComponent from '@/affiliates/aegeanair/components/shared/CheckoutButtons/CheckoutSingleButtonComponent';
import AegeanContactDetailsForm from '@/affiliates/aegeanair/components/book/AegeanContactDetailsForm';
import BookingSummary from '@/affiliates/aegeanair/components/book/summary/BookingSummary';
import { mapState, mapGetters } from 'vuex';
import { trans, aegeanCurrency } from '@/filters';

export default {
  extends: BookingApp,
  components: {
    AegeanBookingFlowSectionHeader,
    AegeanCartItem,
    InvoiceDetails,
    CheckoutSingleButtonComponent,
    AegeanContactDetailsForm,
    BookingSummary,
  },
  methods: {
    trans,
    aegeanCurrency,
  },
  computed: {
    ...mapState({
      serviceFeeCost: (state) => state.bookingModule.serviceFee.serviceFeeCost,
      ticketDeliveryAmount: (state) => state.bookingModule.ticketDeliveryAmount,
    }),
    ...mapGetters({
      marketingCompaniesNames: 'bookingModule/marketingCompaniesNames',
    }),
    bookingDisabled() {
      return this.$store.state.navigationModule.bookingDisabled;
    },
    bookingDisableNotification() {
      return this.$store.state.navigationModule.bookingDisableNotification;
    },
  },
};
</script>
