import { isJsonPropTrue, notNull } from '@/logic/helpers/utils';

/**
 * Converts discount objects that we receive from the API into usable
 * structures
 * @param {Object} discount
 * @typedef DiscountDto
 *
 * @return {DiscountDto}
 */
export const adaptDiscount = discount => {
  return {
    CompanyAbbr: adaptCompanyAbbr(discount),
    PassType: adaptPassType(discount),
    DiscountCode: adaptDiscountCode(discount),
    CabinAlias: adaptCabinAlias(discount),
    VehicleAlias: adaptVehicleAlias(discount),
    DiscountType: adaptDiscountType(discount),
    DiscountCategory: adaptDiscountCategory(discount),
    DiscountOrder: adaptDiscountOrder(discount),
    DiscountDescription: adaptDiscountDescription(discount),
    needsDocument: adaptNeedsDocument(discount),
    enabled: adaptEnabled(discount),
    isDefaultDiscount: adaptIsDefaultDiscount(discount),
    isAssignedSeat: adaptIsAssignedSeat(discount),
    isAssignedTicket: adaptIsAssignedTicket(discount),
    discountPercentage: adaptDiscountPercentage(discount)
  };
};

const adaptCabinAlias = discount => { 
  if ((discount.CabinAlias !== '') && (notNull(discount.CabinAlias) === true)) {
    return discount.CabinAlias;
  }
  return discount.DiscountCode;
}

const adaptVehicleAlias = discount => { 
  if ((discount.vehicleAlias !== '') && (notNull(discount.vehicleAlias) === true)) {
    return discount.vehicleAlias;
  }
  return discount.DiscountCode;
}

const adaptCompanyAbbr = discount => discount.CompanyAbbr;

const adaptDiscountCode = discount => discount.DiscountCode;

const adaptDiscountType = discount => discount.DiscountType;

const adaptPassType = discount => discount.PassType;

const adaptEnabled = discount => isJsonPropTrue(discount.enabled);

const adaptDiscountCategory = discount => discount.DiscountCategory;

const adaptDiscountDescription = discount => discount.DiscountDescription;

const adaptDiscountOrder = discount => discount.DiscountOrder;

const adaptIsAssignedSeat = discount => isJsonPropTrue(discount.isAssignedSeat);

const adaptIsAssignedTicket = discount =>
  isJsonPropTrue(discount.isAssignedTicket);

const adaptNeedsDocument = discount => isJsonPropTrue(discount.needsDocument);

const adaptIsDefaultDiscount = discount => isJsonPropTrue(discount.isDefaultDiscount);

const adaptDiscountPercentage = discount => {
  return isTruthy(discount.discountPercentage) ? Number(discount.discountPercentage) : 0;
}

const isTruthy = prop => !!prop;