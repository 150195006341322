<template>
  <div>
    <div :class="$style.fhSeatingCartTraveler" v-if="isPassenger">
      <div :class="$style.fhSeatingCartTraveler__content">
        <span v-html="item.icon" class="mr15"></span>
        <div :class="$style.fhSeatingCartTraveler__seat">
          <span>{{ item.occupantsString }}</span>
          <span :class="$style['fhSeatingCartTraveler__seat-occupants']">{{ item.description }}</span>
        </div>
      </div>
      <div :class="$style.fhSeatingCartTraveler__price">
        <i v-if="hasError" class="fh fh-notification"></i>
        <span v-else-if="itemPrice">{{ currencyStringPre(itemPrice) }}</span>
      </div>
    </div>

    <div :class="$style.fhSeatingCartTraveler" v-if="isPet">
      <div :class="$style.fhSeatingCartTraveler__content">
        <i class="fh fh-pet mr15 mt6"></i>
        <div :class="$style.fhSeatingCartTraveler__seat" data-test="seatingCartPet">
          <span data-test="seatingCartPetTitle">{{ petItemTitle }}</span>
          <span :class="$style['fhSeatingCartTraveler__seat-occupants']">{{ item.description }}</span>
        </div>
      </div>
      <i v-if="hasError" class="fh fh-notification"></i>
      <div v-else :class="$style.fhSeatingCartTraveler__price">
        <span v-if="isOfflineTicket"><i class="fh fh-notification"></i></span>
        <span v-else>{{ currencyStringPre(itemPrice) }}</span>
      </div>
    </div>

    <div :class="$style.fhSeatingCartTraveler" v-if="isVehicle">
      <div :class="$style.fhSeatingCartTraveler__content">
        <span v-html="item.icon" class="mr15"></span>
        <div :class="$style.fhSeatingCartTraveler__seat">
          <span>{{ item.description }}</span>
        </div>
      </div>
      <i v-if="hasError" class="fh fh-notification"></i>
      <span v-else-if="itemPrice">{{ currencyStringPre(itemPrice) }}</span>
    </div>
  </div>
</template>

<script>
import { message } from '@/logic/helpers/utils';
import { currencyStringPre } from '@/filters';

export default {
  name: 'FhSeatingCartTraveler',
  props: {
    item: Object,
    isOfflineTicket: Boolean,
    isPassenger: {
      type: Boolean,
      default: false,
    },
    isPet: {
      type: Boolean,
      default: false,
    },
    isVehicle: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    currencyStringPre
  },
  computed: {
    itemPrice() {
      return this.item.itemPrice;
    },
    petItemTitle() {
      return `${message('generic.pet.capitalised.one')} ${this.item.occupantIndexes[0] + 1}`;
    },
  },
};
</script>

<style module lang="scss">
@import './fh-seating-cart-traveler.scss';
</style>
