import { removeColon } from '../../helpers/stringUtils';
import DirectTripModel from './DirectTripModel';
import DirectTripModelLight from './DirectTripModelLight';


/**
 * Creates a unique IDS (identifier) code from a DirectTripModel
 * This function allows to detect duplicate itineraries etc
 * @param {DirectTripModel|DirectTripModelLight} itinerary
 */
export const createIDS = itinerary => {
  const { Details, timings } = itinerary;
  return (
    Details.DepStation +
    Details.ArrStation +
    Details.Company +
    timings.DepDate +
    removeColon(timings.DepTime) +
    timings.ArrDate +
    removeColon(timings.ArrTime) +
    '-' + Details.VesselID
  );
};

export const compareIDS = (first, second) => {
  return removeVesselFromIDS(first) === removeVesselFromIDS(second);
}

const removeVesselFromIDS = (idsCode) => idsCode.split('-')[0];