import { groupBy as _groupBy, keys as _keys, uniqBy as _uniqBy, orderBy as _orderBy } from 'lodash-es';
import { getConfigurationValue, isNull, message } from '@/logic/helpers/utils';
import { adaptCompany } from '@/logic/adapters/companiesAdapter';
import { adaptDiscount } from '@/logic/adapters/discountsAdapter';
import { adaptVehicles, adaptCompanyVehicles } from '@/logic/adapters/vehiclesAdapter';
import { adaptVehicleCategories } from '@/logic/adapters/vehicleCategoriesAdapter';
import { arrayUnique } from '@/logic/helpers/arrayFunctions';
import { adaptVessel } from '../adapters/vesselAdapter';
import { getΒoardingMethodIcon } from '@/logic/services/BoardingMethodsIcons';
import { getDefaultDiscount } from '../filterers/DiscountsFilterer';

let companiesMap = [];
let boardingMethodsMap = [];
let vesselsMap = [];
let vehicleTypesMap = [];
let vehicleTypesCategories = [];

export default {
  
  initialize: function(data) {

    if (data.boardingMethods) {
      this.mapBoardingMethods(data.boardingMethods);
    }

    this.addCompanies(data.companies || []);
    this.addVessels(data.vessels || []);
    this.addVehicleTypeCategories(data.vehicleCategories || []);
    this.addVehicles(data.vehicles || []);
    this.addDiscounts(data.discounts || []);

  },

  //--------------------------------------------------------------------------
  companyOverrides: function(company) {
    return company;
  },

  addCompanies: function(companies) {
    companies
      .map(c => adaptCompany(c))
      .forEach(company => {
        if (!companiesMap[company.CompanyAbbr]) {
          companiesMap[company.CompanyAbbr] = {
            ...company,
            boardingMethod: boardingMethodsMap[company.CompanyAbbr]
          };
        }
      });
  },

  /**
   * Map vessels into CompanyAbbr:VesselID - VesselName combination
   */
  addVessels: function(vessels) {
    vessels
      .map(v => adaptVessel(v))
      .forEach(v => {
        if (v.PrefCompany !== null) {
          v.PrefCompany = companiesMap[v.PrefCompany];
        }
        vesselsMap[v.identifier] = v;
      });
  },

  /**
   * Save vehicle categories locally
   * @param {any[]} categories
   */
  addVehicleTypeCategories: function(categories) {
    vehicleTypesCategories = adaptVehicleCategories(categories);
  },

  /**
   * Add vehicles to existing list without overwriting
   */
  addVehicles: function(vehicles) {
    // convert vehicles to meaningful objects
    let grouppedVehicles = _groupBy(adaptVehicles(vehicles, vehicleTypesCategories), 'company');
    let companyCodes = _keys(grouppedVehicles);
    companyCodes.forEach(code => {
      vehicleTypesMap[code] = vehicleTypesMap[code] || adaptCompanyVehicles(grouppedVehicles[code]);
    });
  },

  /**
   * Add discounts to existing list
   */
  addDiscounts: function(discounts) {
    // map serve-fetched discounts to better structures and remove disabled ones
    discounts = discounts.map(d => adaptDiscount(d)).filter(d => d.enabled);

    // get the company codes included in the response
    let companyCodes = _keys(_groupBy(discounts, 'CompanyAbbr'));
    companyCodes.forEach(code => {
      // do nothing if the company code does not correspond to a known object
      if (!companiesMap[code]) return;
      // update the company discounts with fresh data
      companiesMap[code].discounts = _uniqBy(
        discounts
          .filter(d => d.enabled === true) // TODO: This is a duplicate filter
          .filter(d => d.CompanyAbbr === code),
        'DiscountCategory'
      );
    });
  },

  /**
   *
   * @param {any[]} data
   */
  mapBoardingMethods: function(data) {
    // create an array of key=>values for the existing boarding methods
    let boardingMethodKeys = [];

    data.boardingMethods.forEach(method => {
      const { id, method_key, description } = method;
      boardingMethodKeys[id] = {
        boardingMethodKey: method_key,
        boardingMethodDescription: description,
        boardingMethodIcon: getΒoardingMethodIcon(method_key)
      };
    });
    
    // for each of the assigned boarding methods, find the equivalent key and
    // set it for the company
    _orderBy(data.companyBoardingMethods, o => Number(o.priority)).forEach(method => {
      if ( ! boardingMethodsMap[method.company]) {
        boardingMethodsMap[method.company] = boardingMethodKeys[method.boardingMethod];
      }
    });
  },

  /**
   * Get a company by code (abbreviation)
   * @param {String} code
   */
  getCompany: function(code) {
    let companyOverridenCode = this.companyOverrides(code);
    return companiesMap[companyOverridenCode];
  },

  /**
   * Retunr the name of a company according to abbreviation
   * @param {String} companyCode
   */
  getCompanyName: function(companyCode) {
    let companyObject = this.getCompany(companyCode);

    if (isNull(companyObject)) {
      throw `Missing company ${companyCode}`;
    }

    return companyObject.CompanyName;
  },

  /**
   * Return the company boarding method string
   * @param {String} company
   */
  getCompanyBoardingMethod: function(company) {
    let companyObject = this.getCompany(company);
    return (
      (companyObject && companyObject.boardingMethod) || {
        boardingMethodDescription: message('BOARDING_METHOD_PAPER_COUPON_SITE_DESCRIPTION'),
        boardingMethodKey: 'BOARDING_METHOD_PAPER_COUPON'
      }
    );
  },

  /**
   * Return a vessel object using company abbreviation and vessel code
   * If the vessel is for any reason not registered in our data, we still
   * proceed by showing a mock vessel with name equal company name
   * @param {String} companyCode
   * @param {String} vesselCode
   */
  getCompanyVessel: function(companyCode, vesselCode) {
    companyCode = this.companyOverrides(companyCode);
    let vessel = vesselsMap[`${companyCode}:${vesselCode}`];
    if (vessel) {
      return vessel;
    } else {
      let company = this.getCompanyName(companyCode);
      return {
        VesselName: company,
        PrefCompany: null,
        Company: companyCode,
        isUnknown: true
      };
    }
  },

  //--------------------------------------------------------------------------
  getCompanyVehicles: function(company) {
    return vehicleTypesMap[this.companyOverrides(company)];
  },

  //--------------------------------------------------------------------------
  getVehicleCategory: function(categoryCode) {
    return vehicleTypesCategories[categoryCode];
  },

  //--------------------------------------------------------------------------
  isSupported: function(company) {
    return this.getCompany(company).supported;
  },

  //--------------------------------------------------------------------------
  hasETicket: function(company) {
    return this.getCompany(company).hasElectronicTicket;
  },

  isPetDocRequired: function(company) {
    return this.getCompany(company).isPetDocumentRequired;
  },

  //------------------------------------------------------------------------------
  getOperatorIcon: function(operatorAbbr) {
    let cdn = getConfigurationValue('cdn', 'https://images.ferryhopper.com/');
    return `${cdn}companies/optimized/${operatorAbbr}-min.png`;
  },

  //------------------------------------------------------------------------------
  // returns the prefered icon for a vessel, based on preferred or actual company
  // and our cdn url
  // create preferable icon from CDN : /companies/optimized/abbreviation-min.png
  // this is quite dangerous in case something changes in the cdn, but since
  // we have a fallback of showing our image placeholder, it is ok
  getPreferredIcon: function(vessel) {
    let prefIcon = vessel.PrefCompany !== null ? this.getOperatorIcon(vessel.PrefCompany.CompanyAbbr) : this.getOperatorIcon(vessel.Company);
    return prefIcon;
  },

  //----------------------------------------------------------------------------
  // returns the preferred company name of a vessel, based on pref or actual company
  getPreferredCompanyName: function(vessel) {
    let prefName = vessel.PrefCompany !== null ? this.getCompanyName(vessel.PrefCompany.CompanyAbbr) : this.getCompanyName(vessel.Company);
    return prefName;
  },

  //----------------------------------------------------------------------------
  // returns the name of the company owing a vessel, regardless of what the pref
  // company is
  getParentCompanyName: function(vessel) {
    let parentName = this.getCompanyName(vessel.Company);
    return parentName;
  },

  //----------------------------------------------------------------------------
  getCompanyDiscounts: function(companyCode) {
    return this.getCompany(companyCode).discounts;
  },

  // //-----------------------------------------------------------------------------
  // // todo: This functionality is obsolete since we do not longer have discounts inside the
  // // itineraries. However, this function implementation is better than the one we currently
  // // have in the addDiscounts function, so we should probably consider a refactoring
  // addCompanyDiscounts: function (company, newDiscounts) {
  //   let currentDiscounts = companiesMap[company].discounts;
  //   newDiscounts.filter((d) => d.enabled).forEach((newDiscount) => {
  //     if (!currentDiscounts.find((existingDiscount) => existingDiscount.DiscountCategory === newDiscount.DiscountCategory)) {
  //       currentDiscounts.push(newDiscount);
  //     }
  //   })
  //   companiesMap[company].discounts = currentDiscounts;
  // },

  //--------------------------------------------------------------------------
  requiresFareQuotation: function(companyCode) {
    return this.getCompany(companyCode).requiresFareQuotation;
  },

  /**
   * Returns true if a specific company requires separation of PNRs in the booking flow.
   * Separated PNRs means that each trip of this company will be handled as a single standalone
   * trip during the pricing requests, as well as the issuing request
   * @param {String} companyCode
   */
  requiresPNRSeparation: function(companyCode) {
    const company = this.getCompany(companyCode);
    return company.separatePNRs;
  },

  hasDomesticMotorhomes: function(companyCode) {
    return this.getCompany(companyCode).allowsDomesticMotorhomes;
  },

  /**
   * Returns true if a company supports resident code
   * @param {string} companyCode
   * @returns {boolean}
   * @TypeError Cannot read property 'supportsResidentCode' of undefined.
   *
   * This runtime exception is thrown if the requested company is not a map entry.
   * Return false to prevent this.
   */
  supportsResidentCode: function(companyCode) {
    const companyFromMap = this.getCompany(companyCode);
    return companyFromMap ? companyFromMap.supportsResidentCode : false;
  },

  /**
   * Returns true if a requirement is needed form a list of companies, taking
   * into account domestic & international flags
   *
   * Domestic has a higher weight than international, hence we first check this
   *
   * @param {bool} isInternationalTrip
   * @param {Array} companiesList
   * @param {string} requirementName
   */
  getPassengerDetailsRequirement: function(isInternationalTrip, companiesList, requirementName) {
    // check if the specific requirement is needed regardless of domestic/international trip
    let isRequiredAlways = companiesList.some(c => c[`domestic${requirementName}`]);

    if (isRequiredAlways) {
      return isRequiredAlways;
    }

    // else return only requirements for domestic/international
    let isRequiredOnInternational = companiesList.some(c => c[`international${requirementName}`]);

    return isRequiredOnInternational && isInternationalTrip;
  },

  /**
   * Returns an object that defines which passenger details should be requested, based on
   * a list of company codes and countries
   * @param {Array} countries
   * @param {Array} companyCodes
   */
  getPassengerDetailsRequirements: function(countries, companyCodes) {
    // check if a country change occurs in the trip
    let isInternationalTrip = arrayUnique(countries).length > 1;

    // map company codes into company objects
    let companiesList = arrayUnique(companyCodes).map(code => this.getCompany(code, countries));

    // return the four essential flags
    return {
      birthday: this.getPassengerDetailsRequirement(isInternationalTrip, companiesList, 'Birthday'),
      birthplace: this.getPassengerDetailsRequirement(isInternationalTrip, companiesList, 'Birthplace'),
      nationality: this.getPassengerDetailsRequirement(isInternationalTrip, companiesList, 'Nationality'),
      document: this.getPassengerDetailsRequirement(isInternationalTrip, companiesList, 'Passport'),
      docExpiration: this.getPassengerDetailsRequirement(isInternationalTrip, companiesList, 'DocExpiration')
    };
  },

  /**
   * Return the discount structure for a given passenger type for a company
   *
   * @param {string} categoryType
   * @param {string} companyCode
   */
  getCompanyDiscountByCagegory(categoryType, companyCode) {
    let companyPassType = this.getCompanyDiscounts(companyCode).find(discount => {
      return discount.DiscountCategory === categoryType && discount.enabled === true;
    });

    return companyPassType || getDefaultDiscount(this.getCompanyDiscounts(companyCode));
  }
};
