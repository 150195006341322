<template>
  <div class="summary-trip-wrapper">
    <h4 class="summary-trip__date">{{ trip.timings.DepDateLocalLong }}</h4>
    <div class="summary-trip">
      <div class="summary-trip__row--top">
        <JourneyTitle :ports="[trip.Details.DepStationName, trip.Details.ArrStationName]" classlist="summary-trip__locations" :cropText="false" />
      </div>
      <div class="summary-trip__row--bottom">
        <BookingSummaryVessel :company="trip.Details.CompanyName" :vessel="trip.Details.VesselName" :icon="trip.Details.CompanyIcon" />
        <div class="summary-trip__section">
          <span class="summary-trip__price price">€ {{ currency(trip.OverallPrice) }}</span>
          <button class="btn btn-sm btn-link summary-trip__toggle" @click="toggle()">
            <i class="fh fh-down-arrow summary-trip__toggle-arrow" :class="{ expanded: expanded }"></i>
          </button>
        </div>
      </div>
    </div>
    <BookingSummaryItemDetails :trip="trip" v-if="expanded" />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import BookingSummaryVessel from './BookingSummaryVessel';
import { currency } from '@/filters';

const BookingSummaryItemDetails = defineAsyncComponent(() => import('./BookingSummaryItemDetails'));

export default {
  name: 'BookingSummaryItem',
  components: {
    BookingSummaryVessel,
    BookingSummaryItemDetails
  },
  props: ['trip', 'tripIndex'],
  data: function () {
    return {
      expanded: false,
    };
  },
  methods: {
    currency,
    toggle: function () {
      this.expanded = !this.expanded;
    },
  },
};
</script>
