<template>
  <BookingFlowGrid :class="[isMobile ? 'mt20' : 'mt50']">
    <template #col v-if="ready">
      <ResultsAppSection
        :class="[isMobile ? 'pb20' : 'pb40']"
        v-for="(tripRequest, tripRequestIndex) in tripSearchRequests"
        :key="`${tripRequestIndex}-${tripRequest.origin}-${tripRequest.destination}-${tripRequest.date}`"
        :origin="tripRequest.origin"
        :destination="tripRequest.destination"
        :date="tripRequest.date"
        :searchIndex="tripRequestIndex"
        @onSearchUpdate="onSearchUpdate"
        @onResultsCollected="onResultsCollected"
      />
    </template>
    <template #col-sm>
      <FhResultsCart :tripsCount="tripSearchRequests.length" />
    </template>
  </BookingFlowGrid>
</template>

<script>
import { nextTick } from 'vue';
import emitter from '@/emitter';
import FhResultsCart from '@/components/cart/ResultsCart/FhResultsCart';
import ResultsAppSection from '@/components/results/ResultsAppSection';
import { startBrazeSession } from '@/logic/services/crm/braze';
import BookingFlowGrid from '@/components/shared/BookingFlowGrid';

export default {
  name: 'ResultsApp',
  components: {
    FhResultsCart,
    ResultsAppSection,
    BookingFlowGrid,
  },
  data: () => ({
    ready: false,
    hasResults: false,
    tripSearchRequests: [],
  }),
  created() {
    emitter.$on('onSearchParametersChange', () => {
      emitter.$emit('onCartReset');
      this.tripSearchRequests = [];
      this.ready = false;
      this.hasResults = false;
    });

    emitter.$on('onSearchPerform', () => {
      emitter.$emit('onCartReset');
      this.ready = false;
      this.hasResults = false;

      // extract the trip requests
      let newTrips = this.$store.state.searchModule.searchQueries.map((subRequest) => ({
        origin: subRequest.origin,
        destination: subRequest.destination,
        date: subRequest.dateDayjs,
      }));

      // update component
      nextTick(() => {
        this.tripSearchRequests = newTrips;
        this.ready = true;
      });
    });
  },
  methods: {
    onSearchUpdate(data) {
      const { origin, destination, date, index, hasSelection } = data;
      if (hasSelection) emitter.$emit('onItineraryDeselection', index);
      this.tripSearchRequests[index].origin = origin;
      this.tripSearchRequests[index].destination = destination;
      this.tripSearchRequests[index].date = date;
    },
    onResultsCollected() {
      if (false === this.hasResults) {
        this.hasResults = true;
        emitter.$emit('onFirstResultCollected');
      }
    },
  },
  watch: {
    ready() {
      if (!this.ready) return;
      startBrazeSession();
    },
  },
};
</script>
