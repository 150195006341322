/**
 * A collection of state reconcilers that implement a HardSet strategy (take a state and return it as the new state, discarding any old state),
 * and make sure that the new state matches the App's expected state and behavior for it to work, by restoring the required methods to the state's nested objects.
 * Used when rehydrating the App's state from storage.
 */
import ContactInfoModel from '@/logic/models/ContactInfoModel';


/** @param {ContactInfoModel} info */
export const reconcileContactInfo = info => new ContactInfoModel(info);

/** 
 * @param {any} passenger 
 */
export const reconcilePassenger = passenger => passenger;

/**
 * @param {any} vehicle 
 */
export const reconcileVehicle = vehicle => vehicle;

/**
 * @param {any} pet 
 */
export const reconcilePet = pet => pet;
