import * as types from '../mutation-types';

export const uiModule = {
  namespaced: true,
  state: {
    fetchingForPrices: false,
    couponCodeInputVisible: false,
    isLoading: false
  },
  mutations: {
    [types.SET_IS_FETCHING_FOR_PRICES](state, payload) {
      state.fetchingForPrices = payload;
    },
    [types.SET_COUPON_INPUT_VISIBILITY](state, payload) {
      state.couponCodeInputVisible = payload;
    },
    [types.SET_IS_LOADING](state, payload) {
      state.isLoading = payload;
  },
  },
  actions: {
    changeIsFetchingForPrices({ commit }, payload) {
      commit(types.SET_IS_FETCHING_FOR_PRICES, payload);
    },
    changeCouponInputVisibility({ commit }, payload) {
      commit(types.SET_COUPON_INPUT_VISIBILITY, payload);
    },
    changeIsLoading({ commit }, payload) {
      commit(types.SET_IS_LOADING, payload);
  },
  }
};
