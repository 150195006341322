import { logException } from '@/logic/services/events/errorLogging';
import smoothscroll from 'smoothscroll-polyfill';

// kick off the polyfill!
smoothscroll.polyfill();

/**
 * Scrolls the window to a specific element smoothly
 * @param {String} selector The CSS selector of the element.
 */
export const scrollToElementClass = (selector, options) => {
  document.querySelector(`.${selector}`).scrollIntoView(options || {});
};

/**
 * Scrolls the window to a specific element
 * @param {String} id The id of the element.
 * @param {String} behavior The scrolling behavior. Possible options are smooth and auto.
 * @param {Number} offset Any extra offset to subtract from the scrolling.
 */
export const scrollToElementId = (id, behavior = 'auto', offset = 0) => {
  const target = document.getElementById(id);
  if (!target) {
    logException('ScrollToElement', { elementId: id, behavior });
    return;
  }
  const top = target.getBoundingClientRect().top + window.scrollY - offset;
  const options = { top, behavior };

  window.scrollTo(options);
};

/**
 * Scrolls to the top of a scrollable element
 * @param {HTMLElement} element The element.
 * @param {String} behavior The scrolling behavior. Possible options are smooth and auto.
 */
export const scrollToTopOfElement = (element, behavior = 'auto') => element.scrollTo({ top: 0, behavior });
