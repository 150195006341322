/**
 * @typedef PricingPerPet
 * @property {string} PetAbbr
 * @property {string} PetType
 * @property {string} FareCode
 * @property {number} PetIndex
 * @property {number} ownerIndex
 */

export const createPricingPerPet = pet => {
  return {
    PetAbbr: pet.selectedAccommodation,
    PetType: pet.petTypeCode,
    FareCode: '',
    PetIndex: pet.petIndex,
    ownerIndex: pet.ownerIndex
  };
};
