<template>
  <div class="fh-dropdown-search">
    <input ref="dropdownInput" class="fh-dropdown-search__input" type="text" :value="modelValue[optionKeyToShow]" @input="handleInput" @focus="handleFocus" @blur="handleBlur" @keydown.enter="handleEnter" />
    <div v-if="showError" class="fh-dropdown-search__error">{{ trans('component.dropdown.search.noMatch') }} {{ modelValue[optionKeyToShow] }}</div>
  </div>
</template>

<script>
import { trans } from '@/filters';
import { updateObjectWithProp } from '@/logic/helpers/objectManipulation';

export default {
  name: 'FhDropdownSearch',
  props: {
    modelValue: Object,
    optionKeyToShow: String,
    showError: Boolean,
  },
  emits: ['update:modelValue', 'focus', 'blur', 'enter'],
  mounted() {
    this.$refs.dropdownInput.focus();
  },
  methods: {
    trans,
    handleInput(e) {
      this.$emit('update:modelValue', updateObjectWithProp(this.modelValue, this.optionKeyToShow, e.target.value));
    },
    handleFocus() {
      this.$emit('focus');
    },
    handleBlur() {
      this.$emit('blur');
    },
    handleEnter() {
      this.$emit('enter');
    },
  },
};
</script>
