import { message, notNull, isNull } from '../../helpers/utils';
import { eventSearchPortsError, eventNoDestinationsPort, eventNoProviderPort } from '../../services/events/createSearchEvents';
import { orderBy as _orderBy } from 'lodash-es';
import { eventImpossibleConnection, eventSearchInputsError, eventSearchDateOrderError, eventSamePortsError } from '../../services/events/createSearchEvents';
import PortsRepository from '../../repositories/PortsRepository';
import dayjs from '@/logic/services/date/dateService';

export const SEARCH_ERROR_TYPES = { NONE: 'NONE', WARNING: 'WARNING', NOTIFICATION: 'NOTIFICATION' };

export const createErrorResponse = (errorKey, searchErrorType, errorId = null) => ({
  errorMessage: message(errorKey),
  searchErrorType,
  errorId
});

// SEARCH INPUT ERRORS
// Missing ports
export const hasMissingPorts = (ports, tripCount) => {
  const arePortsInvalid = ports.some(s => isNull(s) || !s.LocationAbbr);
  const arePortsMissing = ports.length <= tripCount;
  return arePortsInvalid || arePortsMissing;
};

export const getMissingPortsError = searchMode => {
  eventSearchInputsError('Missing ports', 'port_missing', searchMode);
  return createErrorResponse('missingports', SEARCH_ERROR_TYPES.WARNING, 1);
};

// Consecutive ports
export const getConsecutivePort = ports => {
  const selectedPorts = ports.filter(p => p.short !== '');
  const consecutivePort = selectedPorts.find((_, index) => {
    const origin = ports[index];
    const destination = ports[index + 1];
    return notNull(origin) && notNull(destination) ? origin.LocationAbbr === destination.LocationAbbr : null;
  });

  return consecutivePort;
};

export const getConsecutivePortError = consecutivePort => {
  eventSamePortsError(consecutivePort);
  return createErrorResponse('sameconsecutiveports', SEARCH_ERROR_TYPES.WARNING, 2);
};

// No destinations ports
export const getNoDestinationsPort = ports => {
  return ports.find((p, index) => {
    // send no destinations event despite if it has parent island
    if (p.short !== '' && isNull(p.destinations)) {
      const siblingPort = ports[index - 1] || ports[index + 1];
      //the port with no destinations that caused the error should always be first in the array
      eventSearchPortsError('Port with no destinations', [p, siblingPort]);
      eventNoDestinationsPort(p, siblingPort);
    }
    return p.short !== '' && isNull(p.destinations) && isNull(p.parentIsland);
  });
};

export const getNoDestinationsPortError = noDestinationsPort => {
  let errorResponse = createErrorResponse('searchmasknoPortDestinations', SEARCH_ERROR_TYPES.NOTIFICATION, 6);
  return { ...errorResponse, errorMessage: `${errorResponse.errorMessage} ${noDestinationsPort.alias}`, errorId: 6 };
};

// No provider ports
export const getNoProvidedPort = ports => {
  return ports.find((p, index) => {
    // send no providers event despite if it has parent island
    if (p.short !== '' && isNull(p.providers)) {
      const siblingPort = ports[index - 1] || ports[index + 1];
      //the port with no provider that caused the error should always be first in the array
      eventSearchPortsError('Port with no provider', [p, siblingPort]);
      eventNoProviderPort(p, siblingPort);
    }
    return p.short !== '' && isNull(p.providers) && isNull(p.parentIsland);
  });
};

export const getNoProviderPortError = noProviderPort => {
  let errorResponse = createErrorResponse('searchmasknoProviderPort1', SEARCH_ERROR_TYPES.NOTIFICATION, 7);
  return { ...errorResponse, errorMessage: `${errorResponse.errorMessage} ${noProviderPort.alias}. ${message('searchmasknoProviderPort2')}`, errorId: 7 };
};

// No connection ports (e.g. other region)
export const getConnectionDetails = (ports, tripCount) => {
  for (let tripIndex = 1; tripIndex <= tripCount; tripIndex++) {
    const origin = ports[tripIndex - 1];
    const destination = ports[tripIndex];
    if (isNull(origin.LocationAbbr) || isNull(destination.LocationAbbr)) return { isConnectionPossible: null, origin, destination };
    const isConnectionPossible = PortsRepository.isConnectionPossible(origin.LocationAbbr, destination.LocationAbbr);
    return { isConnectionPossible, origin, destination };
  }
};

export const getNoConnectionPortError = (origin, destination) => {
  eventImpossibleConnection(origin, destination);
  return createErrorResponse('noconnectionpossiblewarning', SEARCH_ERROR_TYPES.WARNING, 3);
};

// Missing dates
export const hasDatesMissing = (dates, tripCount) => {
  const hasDatesMissing = dates.some(d => !dayjs.isDayjs(d));
  return hasDatesMissing || dates.length < tripCount;
};

export const getDatesMissingError = searchMode => {
  eventSearchInputsError('Missing dates', 'date_missing', searchMode);
  return createErrorResponse('missingdates', SEARCH_ERROR_TYPES.WARNING, 4);
};

// Invalid dates order
export const getInvalidDateOrderError = searchMode => {
  eventSearchDateOrderError(searchMode);
  return createErrorResponse('wrongdateorder', SEARCH_ERROR_TYPES.WARNING, 5);
};
