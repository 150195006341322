import { find as _find, maxBy as _maxBy, groupBy as _groupBy, each as _each } from 'lodash-es'
import { arrayUnique } from '@/logic/helpers/arrayFunctions';
import { isPricedTrip } from '../services/pricing/makePricingRequest';
import { createIslanderCodeOffer } from '../managers/discounts/IslanderCode/create';
import { isNull } from '@/logic/helpers/utils';

export default {

  /**
   * Given a list of trips, it returns the array of companies 
   * that support islander discount.
   * @param {DirectTripModel[]} trips 
   */
  getIslanderCodeCompanies(trips) {
    const companiesSupportingIslanderCode = trips
      .filter(trip => trip.islanderDiscount.supportsIslanderCode)
      .map(trip => trip.Details.Company);

    // Return the collection, discarding duplicate values.
    return arrayUnique(companiesSupportingIslanderCode);
  },

  /**
   * Returns a single trip that supports islander code from a given list of trips 
   * OR a mock object that always fails validation, if no trip found.
   * @param { Object[] } trips 
   * @returns { Object } The trip that supports the islander code discount 
   */
  getIslanderCodeTrip(trips) {
    // Get the companies that support the discount.
    const islanderCodeCompanies = this.getIslanderCodeCompanies(trips);

    let islanderCodeTrip;
    if (islanderCodeCompanies.length > 0)
      islanderCodeTrip = trips.find(trip => trip.Details.Company === islanderCodeCompanies[0]);

    // If (islanderCodeCompanies.length > 0) is false OR if trips.find() returns `undefined`,
    // then islanderCodeTrip is `undefined`. In that case, return the mock.
    const mock = { Details: { Company: 'NOCOM' }, provider: 'NOPRO', islanderCode: createIslanderCodeOffer(false) };
    return islanderCodeTrip || mock;
  },


  /**
 * Attaches a 'fastest' flag to the fastest trips without mutating the object
 * @param {DirectTripModel | IndirectTripModel} trips 
 */
  markFastestTrips(trips) {
    
    if (trips.length === 0) return trips;

    let minDuration;
    let minDurationIDS = [];
    trips.forEach(trip => {
      if ((isNull(minDuration)) || (trip.duration <= minDuration)) {
        minDuration = trip.duration;
      }
    });

    minDurationIDS = trips.filter(trip => trip.duration === minDuration).map(trip => trip.id);

    if ((minDurationIDS.length < trips.length) && (minDurationIDS.length < 3)) {
      return trips.map((trip) => {
        return { ...trip, 'fastest': minDurationIDS.includes(trip.id) }
      });
    } else {
      return trips.map((trip) => {
        return { ...trip, 'fastest': false }
      });
    }
  },

  /**
   * Returns the common vehicle options between an array of trips
   * @param {DirectTripModel[]} trips 
   */
  findCommonVehicles(trips) {

    let maxOptions = this.findMaxVehicleOptions(trips);

    _each(maxOptions, (option) => {
      _each(trips, (trip) => {
        let exists = _find(trip.vehicleAccommodations, { 'categoryCode': option.categoryCode });
        if (typeof exists === 'undefined') {
          option.isCommonInTrips = false;
        }
      })
    });

    return maxOptions.filter((o) => o.isCommonInTrips !== false);
  },

  /**
   * Get the maximum set of supported vehicle options in a list of selected trips
   * @param {DirectTripModel[]} trips 
   */
  findMaxVehicleOptions(trips) {
    let maxOptions = _maxBy(trips, (trip) => trip.vehicleAccommodations.length).vehicleAccommodations;
    return maxOptions;
  },

  /**
   * Returns true, if even one of the trips does not allow vehicles
   * @param {DirectTripModel[]} trips 
   */
  hasVehicleRestrictions(trips) {
    return trips.filter((trip) => {
      return (trip.Details.hasGaraze === false) || (trip.vehicleAccommodations.length === 0);
    }).length > 0;
  },

  /**
   * Returns true if all trips in the array have verified availability
   * @param {Object[]} trips 
   */
  hasVerifiedAvailability(trips) {
    return trips.length > 0 && trips.every(trip => isPricedTrip(trip));
  },

  /**
   * Returns the total price for a list of trips
   * @param {Object[]} trips 
   */
  getTotalPrice(trips) {
    let overall = 0.0;
    trips.forEach(trip => overall += trip.OverallPrice);
    return overall;
  },
  

  

  /**
   * Returns the list of marketing company codes in an array of trips
   */
  getMarketingCompanies(trips) {
    return trips.map(trip => {
      return trip.Details.HasPrefCompany ? trip.Details.PrefCompany : trip.Details.Company
    });
  },

  /**
   * Returns the list of owning company codes in an array of trips
   */
  getOwningCompanies(trips) {
    return trips.map(trip => trip.Details.Company);
  },

  /**
   * Returns the list of operating company names included in an array of trips
   */
  getMarketingCompanyNames(trips) {
    return trips.map(trip => trip.Details.CompanyName);
  },

  /**
   * Returns the list of departure ports included in an array of trips
   */
  getDeparturePorts(trips) {
    return trips.map(trip => trip.Details.DepStation);
  },

  /**
   * Returns the list of countries inlcuded in an array of trips
   */
  getInvolvedCountries(trips) {
    return trips.reduce((acc, trip) => {
      return [
        ...acc,
        ...trip.Details.hasCountries
      ]
    }, []);
  },

  hasGreekDepartureTrip(trips) {
    if (!trips) {
      return false;
    }
    return trips.some(trip => trip.Details && trip.Details.DepCountry === 'GR');
  },
}
