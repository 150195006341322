import { isDeckOrSeatType, isCabinType, isBedType } from '../filterers/SeatOptionsFilterer';

/**
 * Get groups of passengers (by index) that occupy a cabin, given a capacity
 * Eg. if a cabin has capacity of 2 and the passenger indexes list is [1 3 4 7]
 * then the groups will be [[1 3], [4 7]]
 * 
 * @param {import('@/logic/models/Models').PassengerModel[]} passengers 
 * @param {number} capacity 
 */
const getPassengerGroups = (passengers, capacity) => {
  const groups = [];
  let index = 0;
  while (index < passengers.length) {
    groups.push(passengers.slice(index, capacity + index));
    index += capacity;
  }
  return groups;
}

/**
 * @param {import('@/logic/models/pricing/ItineraryPricingRequestExtModel').ItineraryPricingExt[]} pricingReqExt 
 * @param {number} index 
 */
const getPassengerPricingByIndex = (pricingReqExt, index) => {
  return pricingReqExt.PricingPerPassenger.find(p => p.PassengerIndex === index);
}

const fareCodeForDeckOrSeat = (index) => `B0${index}`;
const fareCodeForBed = (index) => `B0${index}`;
const fareCodeForCabin = (index) => `C0${index}`;

/**
 * Applies fare quotation to a pricing request for companies that
 * follow the fare quotation logic
 * @param {ItineraryPricingExtModel} pricingRequest 
 */
export const applyFareCodes = (pricingRequest) => {
  
  let insertionIndex = 1;

  pricingRequest.ClassAnalysis.forEach(analysisItem => {

    if (isDeckOrSeatType(analysisItem.Category)) {
      analysisItem.PassengerIndexes.forEach((pIndex) => { 
        // we increase fare code for each passenger
        let fareCode = fareCodeForDeckOrSeat(insertionIndex++);
        getPassengerPricingByIndex(pricingRequest, pIndex).FareCode = fareCode;
      })
    } 
    
    if (isBedType(analysisItem.Category)) {      
      analysisItem.PassengerIndexes.forEach((pIndex) => { 
        let fareCode = fareCodeForBed(insertionIndex);
        getPassengerPricingByIndex(pricingRequest, pIndex).FareCode = fareCode;
      })
      // we increase fare code for each analysis item
      insertionIndex++;
    }

    if (isCabinType(analysisItem.Category)) {
      getPassengerGroups(analysisItem.PassengerIndexes, analysisItem.Capacity).forEach((group) => {
        group.forEach((pIndex) => { 
          let fareCode = fareCodeForCabin(insertionIndex);
          getPassengerPricingByIndex(pricingRequest, pIndex).FareCode = fareCode;
        })
        // we increase fare code for each filled cabin
        insertionIndex++;
      });
    }

  })

  return insertionIndex;
}