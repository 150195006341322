import { createSavedPassenger, createDefaultSavedPassenger, adaptPassengerPayload } from '@/logic/adapters/userAdapter';
import { mapActions, mapState } from 'vuex';
import { eventSavedPassengerSelected, eventNewPassengerSelected } from '@/logic/services/events/createBookingEvents';
import { mergePassengerWithSavedPassenger, getDefaultPassengerDetails } from '@/logic/adapters/passengersAdapter';

export default {
  computed: {
    ...mapState({
      errors: (state) => state.validationModule.errors,
    }),
  },
  methods: {
    ...mapActions({
      updatePassengerDetails: 'userModule/updatePassengerDetails',
      savePassengerDetails: 'userModule/savePassengerDetails',
      removeError: 'validationModule/removeError',
    }),
    updateSavedPassengers() {
      this.savedPassengers = this.savedPassengers.map((savedPassenger) => {
        if (savedPassenger.id === 'newPassengerId') return savedPassenger;
        const targetUserPassenger = this.user.passengers.find((passenger) => passenger.id === savedPassenger.id);
        // maintain the targetPassengerUid property so the saved passenger dropdown can maintain the proper selection
        return { ...createSavedPassenger(targetUserPassenger), targetPassengerUid: savedPassenger.targetPassengerUid };
      });
    },
    initializeSavedPassengers() {
      if (!this.isUserDefined) return;
      this.savedPassengers = this.user.passengers.map((p, index) => createSavedPassenger(p, index));
      this.savedPassengers.unshift(createDefaultSavedPassenger());
    },
    async patchSavedPassenger(passenger) {
      if (!passenger.shouldSaveDetails) return;

      if (passenger.isNewPassenger) {
        // If a new passenger is selected store his details
        const payload = { userId: this.user.id, passengerPayload: adaptPassengerPayload(passenger) };
        await this.savePassengerDetails([payload, false]); // Errors are not handled
      } else {
        // In any other case, update an already existing saved passenger
        const selectedSavedPassenger = this.savedPassengers.find((p) => p.targetPassengerUid === passenger.uid);
        const payload = { userId: this.user.id, passengerId: selectedSavedPassenger.id, passengerPayload: adaptPassengerPayload(passenger) };
        await this.updatePassengerDetails([payload, false]); // Errors are not handled
      }
    },
    handleSavedPassengerSelected(savedPassengerId, passengerUid) {
      this.removeSavedPassengerErrors(passengerUid);
      this.bindPassengerWithSavedPassenger(savedPassengerId, passengerUid);
      this.reconcilePassengerWithSavedPassenger(savedPassengerId, passengerUid);
    },
    removeSavedPassengerErrors(passengerUid) {
      if (this.errors.length === 0) return;
      const selectedPassenger = this.passengers.find((p) => p.uid === passengerUid);
      const passengerFieldNames = [
        `gender${selectedPassenger.passengerIndex + 1}`,
        `name-passenger${selectedPassenger.passengerIndex + 1}`,
        `last-name-passenger${selectedPassenger.passengerIndex + 1}`,
        `birthplace-passenger${selectedPassenger.passengerIndex + 1}`,
        `birthDate-passenger${selectedPassenger.passengerIndex + 1}`,
        `nationality-passenger${selectedPassenger.passengerIndex + 1}`,
        `passport-passenger${selectedPassenger.passengerIndex + 1}`,
        `docDate-passenger${selectedPassenger.passengerIndex + 1}`,
        `islanderCode${selectedPassenger.passengerIndex + 1}`
      ];

      passengerFieldNames.forEach((name) => {
        if (this.errors.some((error) => error.name === name)) this.removeError({ name: name });
      });
    },
    bindPassengerWithSavedPassenger(savedPassengerId, passengerUid) {
      // Use targetPassengerUid to bind every saved passenger with the passenger that selected him
      this.savedPassengers = this.savedPassengers.map((p) => {
        if (p.id === 'newPassengerId') return p;
        if (p.id === savedPassengerId) return { ...p, targetPassengerUid: passengerUid };
        if (p.targetPassengerUid === passengerUid) return { ...p, targetPassengerUid: '' };
        return p;
      });
    },
    reconcilePassengerWithSavedPassenger(savedPassengerId, passengerUid) {
      // Update passenger properties with the respective ones from the selected saved passenger.
      // Every time a selection is made, set the shouldSaveDetails property to false.

      if (savedPassengerId === 'newPassengerId') {
        this.resetPassengerDetails(passengerUid);
        eventNewPassengerSelected();
        return;
      }

      eventSavedPassengerSelected();

      const targetSavedPassenger = this.savedPassengers.find((p) => p.id === savedPassengerId);

      this.passengers = this.passengers.map((p) => (p.uid === passengerUid ? mergePassengerWithSavedPassenger(p, targetSavedPassenger, this.isIslanderCodeDiscountAvailable) : p));
    },
    resetPassengerDetails(passengerUid) {
      this.passengers = this.passengers.map((p) => (p.uid === passengerUid ? getDefaultPassengerDetails(p) : p));
    },
  },
};
