/**
 * This class is the only endpoint between ResultsAppSection and our API.
 * Given a set of origin/destination/date parameters, it decides if this is
 * a single call (port to port), multiple calls (island to port, port to island
 * or island to island) and creates the corresponding Queries (Direct & Multi
 * trip collections)
 *
 * Even if the ports are not even connected to each other, it will perform
 * return an empty promise for direct trips and promises for indirect trips.
 */
import settings from '@/settings'
import PortsRepository from '@/logic/repositories/PortsRepository';
import DirectTripsCollection from '@/logic/collections/DirectTripsCollection'
import IndirectTripsCollection from '@/logic/collections/IndirectTripsCollection'
import { catchError, filter, from, map, tap } from 'rxjs';
import { fetchCRSAlternativeDates } from '../services/ApiCRS';
import { alternativeDateToFakeTrip } from './alternativeDateToFakeTrip';
import { notNull } from '../helpers/utils';

/**
 * @constructor
 * @param  {[string]} origin      [Location abbreviation]
 * @param  {[string]} destination [Location abbreviation]
 * @param  {[string]} date        [Date in YYYYMMDD format]
 */
let Collection = function (origin, destination, date) {
  
  this.origin = origin;
  this.destination = destination;
  this.date = date;

  // create the collections responsible for the actual fetching
  this.directCollections = [];
  this.indirectCollections = [];

  if (!PortsRepository.isConnectionPossible(this.origin, this.destination)) {
    this.portToPortQueries = [];
    this.isDirectlyConnected = false;
    this.isConnectionPossible = false;
    return;
  }

  this.isDirectlyConnected = PortsRepository.isDirectlyConnected(this.origin, this.destination);
  this.isConnectionPossible = true;  

  // create the subqueries requires to get results
  this.portToPortQueries = PortsRepository.getSearchAnalysis(this.origin, this.destination, true);
  // identify island search by counting the number of port-port queries
  this.isSuperPortSearch = this.portToPortQueries.length > 1;

  // create the collections
  this.createCollections();
};

Collection.prototype = {
  // creates the initial direct and indirect collections that will fetch the
  // results from our API
  createCollections() {
    this.directCollections = this.portToPortQueries.map(q => {
      return new DirectTripsCollection(q.or, q.de, this.date);
    })

    // If the indirect trips feature flag is turned off, do not add 
    // relevant collections
    if (settings.features.DISABLE_INDIRECT_TRIPS === true) {
      this.indirectCollections = [];
    } else {
      this.indirectCollections = this.portToPortQueries.map(q => {
        return new IndirectTripsCollection(q.or, q.de, this.date);
      });
    }
  },
  
  // initiate direct fetch return the corresponding promises returns Observable<DirectTripModel[]>
  getVerifiedTrips() {
    return this.directCollections.map(c => c.get());
  },
  
  // initiate indirect fetch return the corresponding promises returns Observable<IndirectTripVerification[]>
  getIndirect() {
    return this.indirectCollections.map(c => c.get());
  },

  // initiate alternative fetch return the corresponding promises returns Observable<DirectTripModel[]>
  // with properties alternate: true and timings: { DepartureDateTime: alternativeDateMoment }
  getAlternativeDays() {
    return this.portToPortQueries.map(query => {
      const { or: origin, de: destination } = query;
      return from(fetchCRSAlternativeDates(origin, destination, this.date)).pipe(
        map(({departures}) => departures.map(alternativeDateToFakeTrip).filter(alt => notNull(alt))),
        filter(trips => notNull(trips)),
        catchError(() => {
          // Alternative dates are non-critical functionality so we gracefully handle failures in fetching
          return [];
        }) 
      );
    })
  }
};

export default Collection;
