import { searchModule } from '../../store/modules/search.module';
import { getConfigurationValue, getPageLanguage } from '../helpers/utils';
import { httpGet, httpPost } from '../services/httpService';
import { from, timer } from 'rxjs';
import { delay, mergeMap, shareReplay, repeatWhen, takeUntil, takeWhile, tap, distinctUntilChanged } from 'rxjs/operators';
import settings from '../../settings';
import CompaniesRepository from '../repositories/CompaniesRepository';
import ClassesRepository from '../repositories/ClassesRepository';
import PetsRepository from '../repositories/PetsRepository';
import { logException } from '../services/events/errorLogging';



// create availability request payload
const createRequestPayload = (depStation, arrStation, depDate, providers) => {
  const { passengers, vehicles, pets } = searchModule.state;
  return {
    depStation,
    arrStation,
    depDate,
    passengers,
    vehicles,
    pets,
    providers,
    lang: getPageLanguage()
  };
};

// Post for availability results
const fetchDirectItineraries = ({ depStation, arrStation, depDate, providers }) => {
  const microRoot = getConfigurationValue('api', '');
  const availabilityUrl = `${microRoot}ferry-providers/availability/`;
  return httpPost(availabilityUrl, createRequestPayload(depStation, arrStation, depDate, providers));
};

export const pollDirectItineraries = requestId => {
  const microRoot = getConfigurationValue('api', '');
  const pollingUrl = `${microRoot}ferry-providers/results/${requestId}?lang=${getPageLanguage()}`;
  return from(httpGet(pollingUrl)).pipe(distinctUntilChanged());
};

// Predication taht checks if a polling should stop because it has been fullfiled
const isNotFinalResponse = response => {
  const { resultCount, expectedResults } = response;
  return resultCount < expectedResults;
};

// Predicate that checks if a polling should stop due to limitations
const isPollingLimitNotReached = counter => {
  return counter < 40;
};

// precalculated polling thresholds
const quarterThreshold = parseInt(settings.api.POLLING_MAX_RETRIES * 0.25);
const middThreshold = parseInt(settings.api.POLLING_MAX_RETRIES * 0.5);
const upperThreshold = parseInt(settings.api.POLLING_MAX_RETRIES * 0.75);

// Calculates the polling interval based on current retry counter
export const getPollingInterval = retryCount => {
  if (retryCount === 0) return settings.api.POLLING_INITIAL_DELAY;
  if (retryCount <= quarterThreshold) return settings.api.POLLING_INTERVAL;
  if (retryCount <= middThreshold) return settings.api.POLLING_INTERVAL + 500;
  if (retryCount <= upperThreshold) return settings.api.POLLING_INTERVAL + 1000;
  return settings.api.POLLING_INTERVAL + 2000;
};

// Side-effect to update repositories on each response
const updateGlobalRepositories = data => {
  const { companies, vessels, discounts, vehicles, classes, pets } = data;
  CompaniesRepository.addCompanies(companies);
  CompaniesRepository.addVessels(vessels);
  CompaniesRepository.addDiscounts(discounts);
  CompaniesRepository.addVehicles(vehicles);
  ClassesRepository.addClasses(classes);
  PetsRepository.addPetClasses(pets);
};

// Returns an observable of direct trips given a query
const getDirectTripsObservable = (searchQuery) => {
  const timer$ = timer(60000); // one minute
  
  let pollingCounter = 0;

  // defer will allow using the retry
  // const pollingObservable = defer(() => fetchDirectItineraries(searchQuery)).pipe(
  const pollingObservable = from(fetchDirectItineraries(searchQuery)).pipe(  
    tap(
      () => {},
      error => logException('Polling:DirectAvailability', error)
    ),
    // Make http call
    mergeMap(({ requestId }) => pollDirectItineraries(requestId)),
    // Repeat after delay
    repeatWhen(notify$ => notify$.pipe(delay(getPollingInterval(pollingCounter)))),
    // Retry 2 more times if http fails
    // retry(2),
    // Execute side-effect
    tap(data => updateGlobalRepositories(data)),
    // Increase the polling counter
    tap(() => pollingCounter++),
    // Repeat polling if conditions are not met
    takeWhile(r => {
      return isNotFinalResponse(r) && isPollingLimitNotReached(pollingCounter);
    }, true),
    // Stop polling if timer$ expires
    takeUntil(timer$),
    shareReplay({
      bufferSize: 1,
      refCount: true
    }),
  );

  return pollingObservable;
};

// Two minutes local cache
const LOCAL_CACHE_EXPIRATION_TIME = 12000; // twelve seconds
class TripsCache {

  constructor() {
    this.localCache = {};
  }

  /**
   * Converts a search query into a cache key
   */
  queryToKey({ depStation, arrStation, depDate, providers }) {    
    const { passengers, vehicles, pets } = searchModule.state;
    const suffix = `${passengers}${vehicles}${pets}`;
    return `${depStation}${arrStation}${depDate}${providers.length}${suffix}`;
  }

  /**
   * Returns a cached observable of direct trips for a given search query
   * If no cached observable is found, it creates a new one
   */
  getTripsWithCaching(searchQuery) {

    let trips$ = this.getCachedQuery(searchQuery);
    if (trips$) return trips$;

    return this.createCachedQuery(searchQuery);
  }

  /**
   * Creates and saves an observable of direct trips into the cache.
   * Along with the observable, it creates a timer. When the timer expires,
   * the cached observable is also marked as expired, in order for new consumers to not
   * subscribe to it.
   */
  createCachedQuery(searchQuery) {  
    let cacheKey = this.queryToKey(searchQuery);  

    const timer$ = timer(LOCAL_CACHE_EXPIRATION_TIME);
    const trips$ = getDirectTripsObservable(searchQuery);
    this.localCache[cacheKey] = {
      trips$,
      timer$,
      isExpired: false
    }

    timer$.subscribe(() => {
      this.localCache[cacheKey].isExpired = true;
    });

    trips$.subscribe({   
      next: (trips) => {},   
      error: (err) => { 
        this.localCache[cacheKey].isExpired = true; 
      },
      complete: () => {}
    });
    
    return this.localCache[cacheKey].trips$;
  }

  /**
   * Returns queries that are saved in cache, if these exist.
   * If they don't exist, or have expired, it returns undefined
   */
  getCachedQuery(searchQuery) {    

    let cacheKey = this.queryToKey(searchQuery);

    // return undefined if nothing is cached
    let cached = this.localCache[cacheKey];    
    if (!cached) return undefined;

    // return undefined if the cached items have expired
    const { trips$, isExpired } = cached;
    
    if (isExpired) {
      return undefined;
    }

    return trips$;
  }
}

let tripsCache = new TripsCache();

export const getDirectTrips$ = (depStation, arrStation, depDate, providers) => {
  return tripsCache.getTripsWithCaching({
    depStation,
    arrStation,
    depDate,
    providers
  });
};
