import { isNull, notNull } from "@/logic/helpers/utils"

export const adaptTripLabels = labels => {
    if (isNull(labels)) return [];

    return labels.map(({ id, label, description, isForRoundTrip, isForVehicle, category }) => {
        const isKnownCategory = !!LABEL_CATEGORIES[category];
        if (!isKnownCategory) return null;
        return {
            id,
            label,
            description,
            isForVehicle,
            isForRoundTrip,
            category: LABEL_CATEGORIES[category]
        }
    }).filter(notNull).sort((a, b) => {
        return labelsOrder.indexOf(a.category) - labelsOrder.indexOf(b.category)
    })
}

export const LABEL_CATEGORIES = {
    GENERIC: 'generic',
    OFFER: 'offer',
    AMENITY: 'amenity',
    COMPARISON: 'comparison'
}

const labelsOrder = [
    LABEL_CATEGORIES.GENERIC,
    LABEL_CATEGORIES.COMPARISON,
    LABEL_CATEGORIES.AMENITY,
    LABEL_CATEGORIES.OFFER
] 


