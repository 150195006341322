// @ts-check
// This module acts as a pipe
// It calculates in near real-time the seating schema for a group of Passengers and Vehicles
// based on their sex, age and desired seating type
import { sortBy as _sortBy, groupBy as _groupBy, keys as _keys, cloneDeep as _cloneDeep, chunk as _chunk, flatten as _flatten } from 'lodash-es';
import settings from '@/settings';
import { message, notNull } from '@/logic/helpers/utils';
import ExceededAvailabilityCheck from '@/logic/pipes/ExceededAvailabilityCheck';
import SeatingAnalysisModel from '@/logic/models/pricing/SeatingAnalysisModel';
import ItineraryPricingRequestExtModel from '@/logic/models/pricing/ItineraryPricingRequestExtModel';
import { selectGenderForSingleSeat } from '@/logic/pipes/helpers/selectGenderForSingleSeat';

import { isAdultType } from '@/logic/filterers/DiscountsFilterer';

import { applyFareCodes } from './FareQuotation';

import { createSeatingAnalysisCabinDescription, createSingleBerthAnalysisDescription } from '@/logic/generators/phrases';
import { isCabinType, isDeckOrSeatType, isPetSeat } from '../filterers/SeatOptionsFilterer';
import { getInfantPassengers, getNonInfantPassengers } from '../filterers/PassengersFilterer';
import { isNonPricableOffer } from '../BL/common/passengerOffers/passengerOffer';
import { hasMultipleAccommodations } from '../filterers/PricingRequestFilterer';
import { TICKET_VIOLATIONS } from '../services/pricing/makePricingRequest';
import { createError } from '../models/errorBag/errorBag';
import { restartArrayFromIndex } from '@/logic/helpers/arrayFunctions';
import { unfilledSeat } from '@/logic/models/trips/TripSeatsModel';
import { hasDuplicates } from '@/logic/helpers/arrayFunctions';
import { hasSelectedPetCabin } from './hasSelectedPetCabin';
import { hasSelectedPetInCabin } from './hasSelectedPetInCabin';
import {hasSelectedPetInVehicle} from './hasSelectedPetInVehicle';

let itineraryPricingRequestExt = new ItineraryPricingRequestExtModel();
let SeatingAnalysis = new SeatingAnalysisModel();
let InsertionSequence;
let VehicleInsertionSequence;
let PetInsertionSequence;
let tripPassengers;
let extraAttributes = {};
let pricingErrors = [];

/**
 * Constructor
 */
function Pipe() {}

/**
 * @param {Object} itinerary
 */
Pipe.begin = function(itinerary) {
  pricingErrors = [];
  InsertionSequence = [];
  VehicleInsertionSequence = [];
  PetInsertionSequence = [];
  tripPassengers = [];

  // create a new instance of the pricing model
  itineraryPricingRequestExt = new ItineraryPricingRequestExtModel();
  SeatingAnalysis = new SeatingAnalysisModel();

  // load itinerary to pricing request model
  itineraryPricingRequestExt.createFromItinerary(itinerary);

  extraAttributes = itinerary.extraAttributes;

  return this;
};

/**
 * @param {Object} itinerary
 * @param {object} passengersList
 * @param {object} vehiclesList
 * @param petsList
 */
Pipe.process = function(itinerary, passengersList, vehiclesList, petsList) {
  let pipe = this;

  // find passengers in trip that are not childs or infants
  let adults = passengersList.filter(o => isAdultType(o.type));

  // raise non-blocking error early
  const passengersWithOfferError = passengersList.filter(p => isNonPricableOffer(p.loyalty) || isNonPricableOffer(p.residenceDiscount));
  if (passengersWithOfferError.length > 0) {
    //@ts-ignore
    pricingErrors.push(createError(TICKET_VIOLATIONS.PASSENGER_OFFER_VIOLATION));
  }

  // checks if at least an adult exists in the trip
  if (adults.length === 0) {
    pricingErrors.push(createError(TICKET_VIOLATIONS.NO_ADULTS));
  }

  // checks that adult passengers are at least equal to the number of vehicles
  if (adults.length < vehiclesList.length) {
    pricingErrors.push(createError(TICKET_VIOLATIONS.MANY_VEHICLES));
  }

  // populate the vehicles first, passing the first adult as driver in
  // order to calculate the applicable discounts
  if (vehiclesList.length > 0) {
    // checks if the same passenger is assigned more than one time in a different vehicle
    const hasSamePassengersAssignedToVehicle = hasDuplicates(vehiclesList.map(v => v.driverIndex));
    if (hasSamePassengersAssignedToVehicle) pricingErrors.push(createError(TICKET_VIOLATIONS.SAME_PASSENGER_ASSIGNED_TO_VEHICLES));

    this.assignVehicleTickets(itinerary, vehiclesList, passengersList);
  }

  if (petsList.length > 0) {
    this.assingPetTickets(petsList);

    let hasSelectedPetCabinForPassenger = hasSelectedPetCabin(passengersList, itinerary);
    let hasSelectedPetInCabinForPet = hasSelectedPetInCabin(petsList, itinerary);

    if (hasSelectedPetCabinForPassenger && !hasSelectedPetInCabinForPet) {
      pricingErrors.push(createError(TICKET_VIOLATIONS.PET_CABIN_WITHOUT_PET_VIOLATION));
    }

    if (hasSelectedPetInCabinForPet && !hasSelectedPetCabinForPassenger) {
      pricingErrors.push(createError(TICKET_VIOLATIONS.PET_IN_CABIN_WITHOUT_CABIN_VIOLATION));
    }

    if (hasSelectedPetInVehicle(petsList, itinerary) && vehiclesList.length === 0) {
      pricingErrors.push(createError(TICKET_VIOLATIONS.PET_IN_VEHICLE_WITHOUT_VEHICLE));
    }
  }

  // sort passengers by seat type
  tripPassengers = _sortBy(passengersList, 'selectedAccommodation');

  // group passengers by seatType
  let grouppedPassengers = _groupBy(tripPassengers, 'selectedAccommodation');

  // get abbreviations of requested classes
  let requestedClassesCodes = _keys(grouppedPassengers);

  // Loop through all the requested abbreviations
  requestedClassesCodes.forEach(abbreviation => {
    // get the class model from abbreviation
    let accommodation = itinerary.passengerAccommodations.find(accommodation => accommodation.ClassAbbr === abbreviation) || unfilledSeat;

    if (isPetSeat(accommodation) && petsList.length === 0) {
      pipe.assignNoSeatSelected(grouppedPassengers[abbreviation]);
    }

    if (accommodation.seatType === '') {
      pipe.assignNoSeatSelected(grouppedPassengers[abbreviation]);
    }

    // If seat type corresponds to a seat or deck, simply ask it as a single seat
    if (isDeckOrSeatType(accommodation.seatType)) {
      pipe.assignSingleAccommodations(itinerary, grouppedPassengers[abbreviation], accommodation);
    }

    if (isCabinType(accommodation.seatType)) {
      // Else, if it is a cabin
      let passengersForCabin = _sortBy(grouppedPassengers[abbreviation]);

      // Find the passenger who selected the cabin for many
      const passengerInitiatingSelection = passengersForCabin.find(pass => pass.seatSelectionMessage !== '');
      let rearrangedPassengers;

      if (notNull(passengerInitiatingSelection)) {
        // Rearrange passengersForCabin based on the passenger who selected the cabin. We use the actual array index of
        // the passenger object here, not the passenger's passengerIndex. This is necessary in cases where cabin selections
        // overlap. For example there are 4 passengers, the first selects a 2 bed cabin (whole), the third selects deck and
        // the forth selects again a 2 bed cabin (whole). In this scenario, the passengersForCabin array will contain 3 items
        // i.e [{ passengerIndex: 0 }, { passengerIndex: 1 }, { passengerIndex: 3 }] so to split based on the passenger who selected the
        // cabin we need the third object's index;
        let indexOfPassenger = passengersForCabin.findIndex(passenger => passenger.id === passengerInitiatingSelection.id);
        rearrangedPassengers = restartArrayFromIndex(passengersForCabin, indexOfPassenger);
      } else {
        rearrangedPassengers = passengersForCabin;
      }

      /*
       * Creates an array of cabins, where passengers are split into groups equal to cabin capacity.
       * If passengers can’t be split evenly, the remaining ones will be assigned to single berths.
       */
      const cabinCapacity = accommodation.accommodationCapacity;
      // const passengerForCabinGroups = _chunk(passengersForCabin, cabinCapacity);
      let passengerForCabinGroups = _chunk(rearrangedPassengers, cabinCapacity);
      const filledCabinsGroups = passengerForCabinGroups.filter(g => g.length === cabinCapacity);
      const singleBerthGroups = passengerForCabinGroups.filter(g => g.length !== cabinCapacity);

      if (filledCabinsGroups.length > 0) {
        pipe.assignFullCabins(filledCabinsGroups, accommodation);
      }

      if (singleBerthGroups.length > 0) {
        pipe.assignSingleBerths(singleBerthGroups[0], accommodation);
        if (accommodation.singleBerth === false) {
          pricingErrors.push(createError(TICKET_VIOLATIONS.CABIN_CAPACITY_VIOLATION));
        }
      }
    }
  });

  return this;
};

/**
 * @param {any[]} vehicles
 * @param {any[]} passengers
 */
Pipe.assignVehicleTickets = function(trip, vehicles, passengers) {
  vehicles.forEach(vehicle => {
    const driver = passengers.find(p => p.passengerIndex === vehicle.driverIndex) || passengers[0];

    let vehicleTypeAbbr = vehicle.TypeAbbr;
    // find vehicle type model based on vehicle type category
    var vehicleTypeModel = trip.vehicleAccommodations.find(o => o.categoryCode === vehicle.TypeCategory);

    // if the model is not found (eg we selected a vehicle type not supported by the
    // operator, we switch to the default), and we 'fake' the description
    // todo: this functionality should be reconsidered as the user might feel that
    // he books something different than what he actually booked
    if (!vehicleTypeModel) {
      vehicleTypeModel = trip.vehicleAccommodations.find(o => o.categoryCode === settings.defaults.defaultVehicleCategory);
    }

    // apply camping on board when this is asked
    if (vehicle.wantsCampingOnBoard && vehicleTypeModel.onBoardOption) {
      vehicleTypeModel = vehicleTypeModel.onBoardOption;
      vehicleTypeAbbr = vehicleTypeModel.vehicleTypeAbbr;
    }

    // Add pricing per vehicle
    itineraryPricingRequestExt.addPricePerVehicle(vehicleTypeAbbr, vehicleTypeModel.meters, driver.companySpecificDiscount.VehicleAlias, driver);

    // Add vehicle to seating analysis
    SeatingAnalysis.addVehicle(vehicleTypeAbbr, vehicleTypeModel.icon, vehicleTypeModel.categoryDescrition, vehicle);

    // Add vehicle to insertion sequence
    VehicleInsertionSequence.push(vehicle.vehicleIndex);
  });
};

Pipe.assingPetTickets = function(pets) {
  pets.forEach(pet => {
    // Add pricing per pet
    itineraryPricingRequestExt.addPricingPerPet(pet);
    // Add pet to insertion sequence
    PetInsertionSequence.push(pet.petIndex);

    // Add pet to seating analysis,
    SeatingAnalysis.addPet(pet.selectedAccommodation, pet.petAccommodationDescription, pet);
  });
};

Pipe.assignNoSeatSelected = function(passengers) {
  SeatingAnalysis.addPassenger(settings.constants.NO_SELECTION, passengers.length, settings.icons.seats.NO_SELECTION, message('ticketSelection.cart.noSelection'), passengers);
};

/**
 * @param {any[]} passengers
 * @param {Accommodation} accommodation
 */
Pipe.assignSingleAccommodations = function(itinerary, passengers, accommodation) {
  var pipe = this;
  var abbreviation = accommodation.ClassAbbr;

  // ask this class x times, as the number of passengers requiring it
  // TODO: CHECK IF GENDER SEPARATION SHOULD BE PERFORMED HERE AS WELL
  // NOW, WE ASK ALL OF THEM AS MALES, SINCE IT IS JUT A SEAT AND GENDER
  // SHOULD NOT CHANGE THINGS
  /*
  TClassAnalysis.push({
  	ClassAbbr: abbreviation,
  	ClassResType: 'M',
  	Quantity: requested,
  	UpDownBed: ''
  });
  */

  // separate infants from other passengers, since infants need to be asked
  // in their own way
  let infants = getInfantPassengers(passengers);
  var nonInfants = getNonInfantPassengers(passengers);

  // add each passenger pricing request for this class, using
  // fare code when applicable
  if (nonInfants.length > 0) {
    // add passengers to pricing request
    pipe.assignSingleSeats(nonInfants, accommodation);

    SeatingAnalysis.addPassenger(abbreviation, nonInfants.length, settings.icons.seats[accommodation.seatType], accommodation.seatDescription, nonInfants);
  }

  // and then, add each passenger pricing request for this class, using
  // fare code when applicable
  if (infants.length > 0) {

    // add infants to pricing request
    pipe.assignSingleSeats(infants, accommodation);

    // add infants to seating analysis regardless of whether a ticket is assigned or not
    SeatingAnalysis.addPassenger(abbreviation, infants.length, settings.icons.seats.INFANT, message('infantfare'), infants);
  }

  return this;
};

/**
 * @param {object[]} passengers
 * @param {Accommodation} accommodation
 */
Pipe.assignSingleSeats = function(passengers, accommodation) {

  let abbreviation = accommodation.ClassAbbr;

  // Get probable gender for group.
  // If we are lucky, they will all be the same. If not though, we use the most
  // probable gender in order to add all of them on the same classAnalysis item (hence, seated next to each other)
  // TODO: This gender ambiguity (and the binary restrictin) is obviously a dark spot that we need to enlighten at some point.
  // The binary selection (sometimes even in favor of a particular gender) does not represent either our culture or mentality as Ferryhopper.
  // A solution could be to add as many classAnalysis items as the genders involved, but this would probably result in
  // people seating far from each other. Another solution to be not to send the gender, and use another value (eg. 'W' that
  // we use for cabins, but we need to convince the providers for that).
  const probableGender = selectGenderForSingleSeat(passengers);

  // add an item on the ClassAnalysis
  itineraryPricingRequestExt.addClassAnalysis(abbreviation, probableGender, passengers.length, passengers, accommodation.seatType, 1);

  passengers.forEach(passenger => {
    // add each person in the PricingPerPassenger array
    itineraryPricingRequestExt.addPricePerPassenger(
      passenger,
      false
    );

    // add passenger index to insertion sequence array
    InsertionSequence.push(passenger.passengerIndex);
  });
};

/**
 * @param {any[]} cabinGroups
 * @param {Accommodation} accommodation
 */
Pipe.assignFullCabins = function(cabinGroups, accommodation) {
  // get abbreviation for
  var abbreviation = accommodation.ClassAbbr;
  
  // ask this class x times, as the number of passengers requiring it
  itineraryPricingRequestExt.addClassAnalysis(abbreviation, 'W', cabinGroups.length, _flatten(cabinGroups), settings.constants.CABIN, accommodation.accommodationCapacity);

  // Process each chunk separately as requiring a full cabin,
  cabinGroups.forEach(passengers => {
    // and then, add each passenger pricing request for this class, using
    // fare code when applicable
    passengers.forEach(passenger => {
      itineraryPricingRequestExt.addPricePerPassenger(
        passenger,
        true
      );

      InsertionSequence.push(passenger.passengerIndex);
    });

    SeatingAnalysis.addPassenger(abbreviation, 1, settings.icons.seats.CABIN, createSeatingAnalysisCabinDescription(accommodation), passengers);
  });

  return this;
};

/**
 * @param {import('@/logic/models/Models').PassengerModel[]} passengersGroup
 * @param {Accommodation} accommodation
 */
Pipe.assignSingleBerths = function(passengersGroup, accommodation) {
  const abbreviation = accommodation.ClassAbbr;
  const passengersPerGender = _groupBy(passengersGroup, p => p.gender);
  const passengerGenders = _keys(passengersPerGender);

  passengerGenders.forEach(gender => {
    itineraryPricingRequestExt.addClassAnalysis(abbreviation, gender, passengersPerGender[gender].length, passengersPerGender[gender], settings.constants.BED, 1);

    passengersPerGender[gender].forEach(passenger => {
      itineraryPricingRequestExt.addPricePerPassenger(
        passenger,
        true
      );

      InsertionSequence.push(passenger.passengerIndex);
    });

    SeatingAnalysis.addPassenger(abbreviation, passengersPerGender[gender].length, settings.icons.seats.CABIN, createSingleBerthAnalysisDescription(accommodation), passengersPerGender[gender]);
  });

  return this;
};

/**
 * Produces the final outcome of this pipe, creating the structures that we can send to the
 * API in order to perform a pricing request
 */
Pipe.getPricingRequest = function(trip, { wantsEarlyBooking }) {
  // this type of sorting will push infants after adults and vehicles at the bottom
  let finalSeatingAnalysis = SeatingAnalysis.getSortedSeatingAnalysis();

  // check for accommodationCapacity errors on the selected seats
  let capacityError = ExceededAvailabilityCheck.validateSelections(finalSeatingAnalysis, trip.passengerAccommodations);

  if (finalSeatingAnalysis.some(item => item.code === settings.constants.NO_SELECTION)) {
    pricingErrors.push(createError(TICKET_VIOLATIONS.NO_SEAT_SELECTION));
  }

  if (capacityError.hasExceededAvailabilityError === true) {
    pricingErrors.push(createError(TICKET_VIOLATIONS.ACCOMMODATION_AVAILABILITY_VIOLATION));
  }

  if (!trip.allowMultipleAccommodations && hasMultipleAccommodations(itineraryPricingRequestExt)) {
    pricingErrors.push(createError(TICKET_VIOLATIONS.SINGLE_ACCOMMODATION_VIOLATION));
  }

  // apply fare codes at the end of the proccess for companies that follow
  // a fare quotation logic - This logic will be moved to API at some point, this is why we keep
  // it isolated
  if (trip.bookingRules.requiresFareQuotation) {
    applyFareCodes(itineraryPricingRequestExt);
  }

  return {
    SeatingAnalysis: finalSeatingAnalysis,
    PricingRequest: {
      ItineraryPricingRequestExt: itineraryPricingRequestExt,
      extraAttributes,
      DiscountFlags: {
        wantsEarlyBooking
      }
    },
    InsertionSequence,
    VehicleInsertionSequence,
    PetInsertionSequence,
    PricingErrors: pricingErrors
  };
};

export default Pipe;
