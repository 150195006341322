// @ts-check
import { cloneDeep as _cloneDeep } from 'lodash-es'
import settings from '@/settings'
import { getPageLanguage } from '@/logic/helpers/utils'
import CompaniesRepository from '@/logic/repositories/CompaniesRepository'
import { adaptClasses } from '../adapters/classesAdapter'
import { adaptAccommodation } from '../adapters/accommodationAdapter'

let classCategories = [];
let classesMap = [];

/**
 * Convert company and class to unique company:code key
 * @param {string} code The company abbreviation
 * @param {string} abbr The class abbreviation
 */
const classKey = (code, abbr) => `${code}:${abbr}`;
/**
 * Convert company class to unique company:code key
 * @param {any} c 
 */
const companyClassKey = (c) => classKey(c.company, c.ClassAbbr);

export default {

  //--------------------------------------------------------------------------
  initialize: function(data) {
    // store class categories for future use
    this.addClassCategories(data.classCategories || []);
    // load classes (if the array is not empty)
    this.addClasses(data.classes || []);
  },

  addClassCategories(categories) {
    classCategories = categories;
  },

  //--------------------------------------------------------------------------
  // store classes in CompanyAbbr:ClassAbbr key array
  addClasses: function(classes) {
    adaptClasses(classes, classCategories, getPageLanguage()).forEach(companyClass => {
      classesMap[companyClassKey(companyClass)] = companyClass;
    });
  },

  /**
   * Returns a class object by company code and class abbreviation
   * @param {string} companyCode 
   * @param {string} abbreviation 
   */
  getClassDetails: function(companyCode, abbreviation) {
    let overridenCompanyCode = CompaniesRepository.companyOverrides(companyCode);
    return classesMap[classKey(overridenCompanyCode, abbreviation)];
  },

  /**
   * hydrates a class object with more usable information
   * @param {string} companyCode
   * @param {object} acommodationAvailability 
   */
  createAccommodation: function(companyCode, acommodationAvailability) {
    let accommodationDetails = _cloneDeep(this.getClassDetails(companyCode, acommodationAvailability.abbr)); 
    return adaptAccommodation(acommodationAvailability, accommodationDetails);   
  },

  /**
   * converts an array of classes into separate categories, sorted by price,
   * and having removed those that are for solely for infants
   * @param {Accommodation[]} seatOptions
   * 
   * @returns {{decks?: number; seats?: number; cabins?: number;}}
   */
  getPricesPerCategory: function(seatOptions) {
    return {
      decks: this.getLowestPricePerCategory(seatOptions, settings.constants.DECK),
      seats: this.getLowestPricePerCategory(seatOptions, settings.constants.SEAT),
      cabins: this.getLowestPricePerCategory(seatOptions, settings.constants.CABIN)
    };
  },

  getLowestPricePerCategory: function (seatOptions, category) {
    const filteredOptions = seatOptions.filter((o) => {
      return o.isInfant === false && o.seatType === category;
    });

    if (filteredOptions.length === 0) return undefined;

    return Math.min(...filteredOptions.map(o => Number(o.ClassAdultBasicPrice)));
  },
};
