<template>
  <div v-if="!option.isInfant">
    <div v-if="option.categoryTitle">
      <hr class="m0" />
      <span class="fh-dropdown-option__title">{{ option.categoryTitle }}<br /></span>
    </div>
    <div :class="['fh-dropdown-option', `fh-dropdown-option-${index}`]" @click="handleClick">
      <span v-if="!option.isInfant" class="fh-dropdown-option__fixed-w">{{ currencyStringPre(option.overallPrice) }}</span>
      <div :data-test="'seatsDropdownOptionDesc'">
        <span>{{ option.seatDescription }}</span>
        <span v-if="showSeatDetails">&nbsp;({{ option.seatDetails }})</span>
        <span class="text-accent" v-if="option.totalAvailability < 9">&nbsp;{{ option.totalAvailability }} {{ remainingSeatsString(option.totalAvailability) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { currencyStringPre, remainingSeatsString } from '@/filters';

export default {
  name: 'FhDropdownSeatOption',
  props: {
    option: Object,
    index: Number,
  },
  emits: ['click'],
  methods: {
    currencyStringPre,
    remainingSeatsString,
    handleClick() {
      this.$emit('click');
    },
  },
  computed: {
    showSeatDetails() {
      return !!this.option.seatDetails;
    },
  },
};
</script>
