import { TRIP_STATUS } from "../trips/TripStatus"

export const createTripForSeating = (trip, tripIndex) => {
    return {
        ...trip,
        tripIndex,
        PricingData: {},
        SeatingAnalysis: [],
        passengers: [],
        vehicles: [],
        pets: [],
        OverallPrice: undefined,
        totalTicketsPriceInCents: undefined,
        STATUS: TRIP_STATUS.INITIAL,
        pricingErrors: [],
        nonInfantOptions: [],
        infantOptions: [],
    }
}