<template>
  <div>
    <div :class="['location-selection-list__title', { pt14: isMobile }]" data-test="returnLocationListTitle">{{ trans('searchmaskreturnportstitle') }}</div>
    <div v-for="(location, locationIndex) in locations" :key="'location-item-' + location.LocationAbbr" class="location-selection-list__item" @click="onListItemClick(location.LocationAbbr, location.alias, locationIndex)" data-test="locationList">
      <span :class="{ 'location-selection-list__item--highlight': selectedPort === location.LocationAbbr }" data-test="returnPort">{{ location.alias }}</span>
      <span class="location-selection-list__item__country">{{ location.countryName }}</span>
    </div>
  </div>
</template>

<script>
import { trans } from '@/filters';

export default {
  name: 'ReturnLocationsSearchList',
  props: {
    locations: Array,
    selectedPort: String
  },
  emits: ['onListItemClick'],
  methods: {
    trans,
    onListItemClick(abbreviation, alias, locationIndex) {
      this.$emit('onListItemClick', abbreviation, alias, 'Return port selected', locationIndex);
    }
  }
};
</script>
