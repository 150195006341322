<template>
  <div :class="selectableCardClass" @click="handleClick">
    <div :class="[$style.selectableCard__header, disabledClass]">
      <h5 class="text-medium mt0 mb0">{{ title }}</h5>
      <div v-if="badgeText !== '' && !hasError" :class="$style.selectableCard__badge">{{ badgeText }}</div>
    </div>
    <div :class="$style.selectableCard__main">
      <div :class="$style.selectableCard__image"><img :src="imageSrc" /></div>
      <div v-if="hasError" :class="$style.selectableCard__error">
        <i class="fh fh-notification mr10"></i>
        <p class="m0">{{ errorMsg }}</p>
      </div>
      <slot v-else></slot>
    </div>
    <div :class="$style.selectableCard__footer">
      <div :class="disabledClass">
        <i v-if="isLoading" class="fh fh-notch3 fh-spin mr8"></i>
        <span v-else :class="$style.selectableCard__amount">{{ amount }}</span>
        <span :class="$style['selectableCard__footer-msg']">{{ footerMsg }}</span>
      </div>
      <FhRadio :checked="selected" :disabled="isLoading" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectableCard',
  props: {
    selected: {
      type: Boolean,
      default: false
    },
    badgeText: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      required: true
    },
    amount: {
      type: String,
      default: ''
    },
    footerMsg: {
      type: String,
      default: ''
    },
    imageSrc: {
      type: String,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: ''
    }
  },
  emits: ['click'],
  methods: {
    handleClick() {
      this.$emit('click');
    }
  },
  computed: {
    selectableCardClass() {
      const selectedClass = this.selected ? this.$style['selectableCard--selected'] : '';
      const noEventsClass = this.hasError ? this.$style['selectableCard--no-events'] : '';
      return `${this.$style.selectableCard} ${selectedClass} ${noEventsClass}`;
    },
    disabledClass() {
      return this.hasError ? this.$style['selectableCard--disabled'] : '';
    }
  }
};
</script>

<style module lang="scss">
@import './selectable-card.scss';
</style>
