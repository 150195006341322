export const VEHICLE_TYPES = {
    CAR: 'CAR',
    MOTORBIKE: 'MOTORBIKE',
    MOTORHOME: 'MOTORHOME',
    TRAILER: 'TRAILER',
    BICYCLE: 'BICYCLE',
    CAMPINGONBOARD: 'CAMPINGONBOARD',
    OTHER: 'OTHER',
  };

export const USER_VEHICLE_TYPES = [
    VEHICLE_TYPES.CAR,
    VEHICLE_TYPES.MOTORBIKE,
    VEHICLE_TYPES.MOTORHOME
];

export const isUserProfileCompatibleVehicleType = (vehicleType) => {
    return USER_VEHICLE_TYPES.includes(vehicleType);
}