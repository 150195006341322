/**
 * Creates a hash for a trip that dictates whether the trip will be grouped
 * with others or not.
 * The current properties taken into account are:
 * - Trip company
 * - Number of non-seated infants
 * - The support of early booking discount
 * @param {DirectTripModel} trip 
 */
export const createGenericHashForTrip = trip => {
  const companyPart = trip.Details.Company;
  const earlyBkPart =
    trip.extraAttributes && trip.extraAttributes.earlyBookingOffer ? 'E' : '';
  return `${companyPart}${earlyBkPart}`;
};

/**
 * Unique hash ensures that no common hash will be generated between two different trips
 * @param {DirectTripModel} trip 
 */
export const createUniqueHashForTrip = trip => `${trip.Details.Company}${trip.id}`;
