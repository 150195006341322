<template>
  <div :class="$style.refundAlt">
    <div :class="$style.refundAlt__text">
      {{ trans('passDet.refProtIntam.ext.explanation.text.1') }}
    </div>
    <div :class="$style['refundAlt__text--bullets']">
      <ul class="pl16">
        <li class="mb4">{{ trans('passDet.refProtIntam.ext.explanation.bullet.1') }}</li>
        <li class="mb4">{{ trans('passDet.refProtIntam.ext.explanation.bullet.2') }}</li>
        <li>{{ trans('passDet.refProtIntam.ext.explanation.bullet.3') }}</li>
      </ul>
    </div>
    <span :class="[$style.refundAlt__text, $style['refundAlt__text-list'], 'text-primary']" @click.stop="showExtendedRefundModal = true">{{ trans('generic.viewFullList') }} </span>

    <FhModal v-if="showExtendedRefundModal" @close="showExtendedRefundModal = false" :title="trans('passDet.refProtIntam.ext.modal.title')">
      <template #body>
        <div class="fh-loader" v-if="!hasDescription">
          <div class="loader"></div>
        </div>
        <div v-else v-html="refundProtectDescription"></div>
      </template>
    </FhModal>
  </div>
</template>

<script>
import { EXTRA_SERVICES, getExtraServiceWithRetry, EXTRA_SERVICE_PROVIDERS } from '@/logic/BL/extraServices';
import { logException } from '@/logic/services/events/errorLogging';
import { trans } from '@/filters';

export default {
  name: 'InteramericanAlt',
  data: () => ({
    refundProtectDescription: '',
    showExtendedRefundModal: false,
  }),
  emits: ['fetchCompleted'],
  created() {
    this.$emit('fetchCompleted', false);

    getExtraServiceWithRetry(EXTRA_SERVICES.REFUND_PROTECT, EXTRA_SERVICE_PROVIDERS.INTERAMERICAN)
      .then((response) => (this.refundProtectDescription = response.staticHtml))
      .catch((error) => logException('ExtendedRefund', error))
      .finally(() => this.$emit('fetchCompleted', true));
  },
  methods: {
    trans,
  },
  computed: {
    hasDescription() {
      return this.refundProtectDescription !== '';
    },
  },
};
</script>

<style module lang="scss">
@import './refund-alt.scss';
</style>
