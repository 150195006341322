<template>
  <transition tag="div" name="fade" v-if="showCampingOnBoardOffer" appear>
    <div :class="$style.campingOnBboard">
      <div :class="$style.campingOnBboard__header">
        <ToggleSwitch :disabled="isComponentDisabled" :hasFixedHeight="false" :value="wantsCampingOnBoard" @input="handleInput" />
        <span :class="$style['campingOnBboard__header-text']">{{ trans(`${campingOnBoardMessages.title}`) }}</span>
      </div>
      <div class="mt12">{{ trans(`${campingOnBoardMessages.warning}`) }}</div>
      <div :class="[$style.campingOnBboard__notification, 'mt10']" v-if="!isCampingOnBoardEnforcable"><i class="fh fh-notification"></i> {{ trans(`${campingOnBoardMessages.blocking}`) }}</div>
    </div>
  </transition>
</template>

<script>
import { eventSelectedCOB } from '@/logic/services/events/createSeatingEvents';
import { CampingOnBoard } from '@/logic/BL';
import { trans } from '@/filters';
import { mapActions } from 'vuex';

export default {
  name: 'CampingOnBoardBox',
  props: ['trip', 'vehicle', 'selectedVehicleOption', 'isDisabled'],
  watch: {
    isCampingOnBoardEnforcable(newValue, oldValue) {
      if (newValue === false) {
        this.applyCampingOnBoard({ trip: this.trip, vehicle: this.vehicle, value: false });
      }
    },
  },
  computed: {
    isComponentDisabled() {
      return this.isDisabled || !this.isCampingOnBoardEnforcable;
    },
    wantsCampingOnBoard() {
      return this.vehicle.wantsCampingOnBoard;
    },
    hasCampingOnBoard() {
      return CampingOnBoard.isAvailable(this.trip, this.trip.passengers, this.trip.vehicles) && this.selectedVehicleOption.hasOnBoardOption;
    },
    isCampingOnBoardEnforcable() {
      return CampingOnBoard.isEnforcable(this.trip, this.trip.passengers, this.trip.vehicles);
    },
    campingOnBoardMessages() {
      return CampingOnBoard.messages(this.trip);
    },
    campingOnBoardWarning() {
      return this.isCampingOnBoardEnforcable ? this.campingOnBoardMessages.warning : this.campingOnBoardMessages.blocking;
    },
    showCampingOnBoardOffer() {
      return CampingOnBoard.isAvailable(this.trip, this.trip.passengers, this.trip.vehicles) && this.selectedVehicleOption.hasOnBoardOption;
    },
  },
  methods: {
    ...mapActions({
      applyCampingOnBoard: 'ticketConfigurationModule/applyCampingOnBoard',
    }),
    trans,
    handleInput(value) {
      this.applyCampingOnBoard({ trip: this.trip, vehicle: this.vehicle, value });
      if (value) eventSelectedCOB(this.trip);
    },
  },
};
</script>

<style module lang="scss">
@import './camping-on-board.scss';
</style>
