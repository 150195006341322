import ClassesRepository from "../repositories/ClassesRepository";
import CompaniesRepository from "../repositories/CompaniesRepository";
import PetsRepository from "../repositories/PetsRepository";
import { fetchOnDemandCRS } from "./ApiCRS";

export const CRS_DEFAULT_ITEMS = [
  'classCategories',
  'vehiclesCategories',
  'petTypes',
  'boardingMethods'
];

/** Fetch default crs data and initialize basic repositories */
export const initializeDefaultCRS = () => fetchOnDemandCRS(CRS_DEFAULT_ITEMS).then(data => {
  CompaniesRepository.initialize(data);
  ClassesRepository.initialize(data);
  PetsRepository.initialize(data);
  
  return true;
});