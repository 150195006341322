import { removeAllSpaces, removeColon } from '@/logic/helpers/stringUtils';
import { sanitizeBookingName, sanitizeBookingPhone } from '@/logic/helpers/sanitizers';
import settings from '@/settings';
import { isPricableOffer } from '../../BL/common/passengerOffers/passengerOffer';
import { isNotInfantType } from '../../filterers/DiscountsFilterer';
import { trimAndUppercase } from '../../helpers/stringUtils';

export const createTripIssueRequest = (trip, contactInfo) => {
  const { InsertionSequence, VehicleInsertionSequence, PetInsertionSequence, PricingRequest } = trip.PricingData;

  const { ItineraryPricingRequestExt } = PricingRequest;

  const { PricingPerPassenger, PricingPerVehicle, PricingPerPet, ClassAnalysis } = ItineraryPricingRequestExt;

  const reservationLeader = trip.passengers.find(p => p.isLeader) || trip.passengers[0];

  return {
    IssueRequest: {
      TReserveIssueRequest: {
        ...initializeIssueRequest(trip, contactInfo, reservationLeader),
        ReserveIssuePerPassenger: createTripIssueRequestsForPassengers(PricingPerPassenger, InsertionSequence, trip),
        ReserveIssuePerVehicle: createTripIssueRequestsForVehicles(PricingPerVehicle, VehicleInsertionSequence, trip),
        ReserveIssuePerPet: createTripIssueRequestsForPets(PricingPerPet, PetInsertionSequence, trip),
        ClassAnalysis
      }
    }
  };
};

const createTripIssueRequestsForPassengers = (PricingPerPassenger, insertionSequence, trip) => {
  return PricingPerPassenger.map((passengerPricing, passengerIndex) => {
    const passengerWithDetails = trip.passengers[insertionSequence[passengerIndex]];

    // Send back the updated fare code that we received from the pricing response
    // TODO: This check is probably obsolete - find out how we solved it on app
    //       but what we probably do is that we always keep fares for all companies
    const fareCode = settings.crs.keepFareCodeCompanies.includes(trip.Details.Company) ? trip.PricingData.PricingResponse.PricesPerPassenger[passengerIndex].FareCode : passengerPricing.FareCode;

    return {
      ...passengerPricing,
      ...createIssuePerPassenger(trip, passengerWithDetails, fareCode),
      Loyalty: getLoyaltyNumberForTicket(passengerWithDetails),
      ResidenceNumber: getResidenceNumberForTicket(passengerWithDetails)
    };
  });
};

const createTripIssueRequestsForVehicles = (PricingPerVehicle, insertionSequence, trip) => {
  return PricingPerVehicle.map((vehiclePricing, vehicleIndex) => {
    const driverWithDetails = trip.passengers[vehiclePricing.DriverIndex];
    const vehicleWithDetails = trip.vehicles[insertionSequence[vehicleIndex]];

    return {
      ...vehiclePricing,
      ...createIssuePerVehicle(vehicleWithDetails, driverWithDetails, trip.Details.Company),
      Loyalty: getLoyaltyNumberForTicket(driverWithDetails)
    };
  });
};

const createTripIssueRequestsForPets = (PricingPerPet, insertionSequence, trip) => {
  return PricingPerPet.map((petPricing, petIndex) => {
    const petWithDetails = trip.pets[insertionSequence[petIndex]];
    return { 
      ...petPricing, 
      petDocument: petWithDetails.petDocument 
    };
  });
};

const initializeIssueRequest = (trip, contactInfo, reservationLeader) => ({
  DepDate: trip.timings.DepDate,
  Deptime: removeColon(trip.timings.DepTime),
  DepStation: trip.Details.DepStation,
  ArrStation: trip.Details.ArrStation,
  Company: trip.Details.Company,
  VesselID: trip.Details.VesselID,
  Remarks: '',
  Email: contactInfo.Email,
  LeaderName: sanitizeBookingName(`${reservationLeader.firstName} ${reservationLeader.lastName}`),
  Phone: sanitizeBookingPhone(`${contactInfo.PhoneCountryCode}${contactInfo.Phone}`),
  Mobile: sanitizeBookingPhone(`${contactInfo.PhoneCountryCode}${contactInfo.Phone}`),
  CRSReservationCode: '',
  Address: '',
  City: '',
  ZipCode: '',
  DeliveryAddr: '',
  DeliveryCity: '',
  DeliveryZipCode: '',
  Amount: '',
  ApprovalCode: '',
  TransactionCode: '',
  PayType: '',
  CardType: '',
  DeliveryType: '',
  AddFees: '',
  ReservationCountry: '',
  DeliveryCountry: '',
  TravelIns: '',
  ReservationPrice: '',
  TravelInsDoc: '',
  SpecialCode: '',
  PromoCode: ''
});

const createIssuePerPassenger = (trip, passenger, fareCode) => {
  let passengerInitial = trimAndUppercase(passenger.firstName);
  let passengerName = trimAndUppercase(passenger.lastName);

  // FIXME: Is this still relevant? If so, lets move it to API
  if (trip.Details.Company === 'ATC') {
    passengerInitial = removeAllSpaces(passengerInitial);
    passengerName = removeAllSpaces(passengerName);
  }

  return {
    PassSex: passenger.gender,
    PassInitial: passengerInitial,
    PassName: passengerName,
    FareCode: fareCode,
    Nationality: trimAndUppercase(passenger.nationality),
    BirthPlace: trimAndUppercase(passenger.birthplace),
    BirthDate: passengerBirthDate(passenger),
    DocumentType: '',
    DocumentNo: trimAndUppercase(passenger.passport),
    DocExpire: docExpirationDate(passenger),
    MKNNo: getIslanderCodeForPassenger(trip, passenger),
    PassSSR: '',
    Residence: '',
    AddFees: ''
  };
};

const createIssuePerVehicle = (vehicle, driver, company) => {
  let driverFirstName = trimAndUppercase(driver.firstName);
  let driverLastName = trimAndUppercase(driver.lastName);

  // FIXME: Is this still relevant? If so, lets move it to API
  if (company === 'ATC') {
    driverFirstName = removeAllSpaces(driverFirstName);
    driverLastName = removeAllSpaces(driverLastName);
  }

  return {
    PlateNo: removeAllSpaces(vehicle.licencePlate),
    DriverName: driverFirstName,
    DriverSurName: driverLastName,
    Nationality: driver.nationality,
    Brand: vehicle.vehicleBrand,
    Model: vehicle.vehicleModel,
    Trailer: 'N',
    SpecialType: '',
    AddFees: ''
  };
};

export function getIslanderCodeForPassenger(trip, passenger) {
  return trip.islanderDiscount.supportsIslanderCode ? passenger.islanderCode : '';
}

export function getResidenceNumberForTicket(passengerTicket) {
  return isPricableOffer(passengerTicket.residenceDiscount) && isNotInfantType(passengerTicket.type) ? passengerTicket.residenceDiscount.residenceNumber : '';
}

export function getLoyaltyNumberForTicket(passengerTicket) {
  return isPricableOffer(passengerTicket.loyalty) && isNotInfantType(passengerTicket.type) ? passengerTicket.loyalty.loyaltyNumber : '';
}

const passengerBirthDate = passenger => {
  return `${passenger.birthyear}${passenger.birthmonth}${passenger.birthday}`;
};

const docExpirationDate = passenger => {
  return `${passenger.docexpyear}${passenger.docexpmonth}${passenger.docexpday}`;
};
