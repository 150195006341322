<template>
  <div :id="id" :class="$style.dateInput">
    <div class="has-feedback" :class="{ 'has-warning': hasError }">
      <MaskedDateInput :value="value" :initialPlaceholder="placeholder" @input="setBirthDate" @focus="onFocus" @blur="onBlur" />
      <i v-if="hasError" :class="[$style.birthDateInput__icon, 'fh fh-notification text-danger']"> </i>
      <input v-if="showHint" :class="['form-control', $style.birthDateInput__hidden, { 'input-with-border-radius': hasBorderRadius }]" readonly="true" :placeholder="placeholder" :value="formattedBirthDate" />
    </div>
    <span v-if="hasError && !isInputActive" class="text-error">{{ errorMessage }}</span>
  </div>
</template>

<script>
import MaskedDateInput from '@/components/shared/inputs/MaskedDateInput';
import { isValidBirthDate, isValidExpDate, LOCALISED_DATE_FORMAT, DATE_FORMAT } from '@/logic/helpers/dateUtils';
import { mapActions } from 'vuex';
import emitter from '@/emitter';
import settings from '@/settings';
import validationMixin from '@/logic/validation/validationMixin';

export default {
  name: 'DateInput',
  mixins: [validationMixin],
  components: {
    MaskedDateInput,
  },
  props: {
    placeholder: String,
    isBirthDateInput: {
      type: Boolean,
      default: true,
    },
    isDocDateInput: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
  },
  emits: ['input'],
  data() {
    return {
      isInputActive: false,
    };
  },
  methods: {
    ...mapActions({
      addError: 'validationModule/addError',
      removeError: 'validationModule/removeError',
    }),
    setBirthDate(value) {
      this.$emit('input', value);
    },
    onBlur() {
      this.isInputActive = false;
      this.validate();
    },
    onFocus() {
      this.isInputActive = true;
      this.removeError({ name: this.name });
    },
  },
  computed: {
    errorMessage() {
      const error = this.errors.find((error) => error.name === this.name);
      return error ? error.message : '';
    },
    hasError() {
      return this.errorMessage !== '';
    },
    showHint() {
      return !this.isInputActive;
    },
    isValidDate() {
      if (this.isBirthDateInput) return isValidBirthDate(this.value);
      if (this.isDocDateInput) return isValidExpDate(this.value);
      return false;
    },
    formattedBirthDate() {
      if (this.isValidDate) {
        let dayjsDate = this.$dayjs(this.value, DATE_FORMAT);
        return dayjsDate.format(settings.formats.birthdayFormat);
      } else {
        return this.value !== LOCALISED_DATE_FORMAT ? this.value : '';
      }
    },
    hasBorderRadius() {
      return this.globalCobrandedVariant === 'easyferry';
    },
  },
  watch: {
    hasError(newValue, oldValue) {
      if (newValue === false) return;
      emitter.$emit('onValidationErrorShown', { name: this.name, value: this.value, error: this.errors.find((error) => error.name === this.name) });
    },
  },
};
</script>

<style module lang="scss">
@import './date-input.scss';
</style>
