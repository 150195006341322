<template>
<div class="aegean-cart-item text-danger" v-if="showErrorMessage">
    {{ pricingErrorMessage }}
</div>
</template>

<script>
import { isPriceErrorTrip } from '../../../../logic/services/pricing/makePricingRequest'
export default {
    name: 'AegeanPricingError',
    props: {
        trip: Object
    },
    computed: {
        showErrorMessage() {
            return isPriceErrorTrip(this.trip);
        },
        pricingErrorMessage() {
            if (this.trip.pricingErrors.length === 0) return '';
            return this.trip.pricingErrors[0].message;
        }
    }
}
</script>