<template>
  <div :class="$style.petSeatingComponent">
    <div v-if="!isPetPolicyNotAllowed" class="mb16">
      <div v-if="isPetPolicyOffline || isPetPolicyNoTicket">
        <i v-if="isPetPolicyOffline" class="fh fh-notification"></i>
        <span class="ticket-selection__label" :class="isPetPolicyOffline ? 'pl10' : 'pl0'">{{ petDescription(pet) }}:&nbsp;</span>
        <span :class="$style.petSeatingComponent__msg">{{ trip.petPolicy.ticketDescription }}</span>
      </div>
      <div v-else>
        <label for="pet-type-select" class="ticket-selection__label pl0"> {{ petDescription(pet) }} </label>
        <div :class="$style.petSeatingComponent__grid">
          <div :class="$style['petSeatingComponent__grid-item']" id="pet-type-select">
            <FhDropdown
              :value="pet.petTypeCode"
              :disabled="isOccupied"
              :optionKeyToTrack="'petTypeCode'"
              :optionKeyToShow="'petTypeDescription'"
              :options="petTypes"
              :id="`petsDropdown-${tripIndex}-${pet.petIndex}`"
              :textSize="'small'"
              @input="changePetType"
            >
              <template #options="{ select }">
                <hr class="m0" />
                <span class="fh-dropdown-option__title">{{ trans('ticketSelection.pets.sizeDropdown.title') }}<br /></span>
                <FhDropdownOption v-for="(type, typeIndex) in petTypes" :key="`pets-dropdown-${typeIndex}`" :text="type.petTypeDescription" :index="typeIndex" @click="select(type, typeIndex)" />
              </template>
            </FhDropdown>
          </div>

          <div :class="$style['petSeatingComponent__grid-item']">
            <FhDropdown
              :value="pet.ownerIndex"
              :valueType="'Number'"
              :disabled="false"
              :optionKeyToTrack="'passengerIndex'"
              :optionKeyToShow="'passengerDescription'"
              :options="passengersList"
              :id="`petOwnerDropdown-${tripIndex}-${pet.petIndex}`"
              :textSize="'small'"
              data-test="petAssignedPassengerDropdown"
              @input="changePetOwner"
            >
              <template #options="{ select }">
                <hr class="m0" />
                <div class="fh-dropdown-option__title">{{ trans('ticketSelection.pets.passengerDropdown.title') }}</div>
                <FhDropdownOption
                  v-for="(passenger, passengerIndex) in passengersList"
                  :key="`passenger-dropdown-${passengerIndex}`"
                  :text="passenger.passengerDescription"
                  :index="passenger.passengerIndex"
                  @click="select(passenger, passengerIndex)"
                />
              </template>
            </FhDropdown>
          </div>

          <div :class="$style['petSeatingComponent__grid-item']">
            <FhDropdown
              :value="pet.petAccommodationCode"
              :disabled="isOccupied"
              :optionKeyToTrack="'petAccommodationCode'"
              :optionKeyToShow="'petAccommodationDescription'"
              :options="petAccommodations"
              :id="`petsAccomDropdown-${tripIndex}-${pet.petIndex}`"
              :textSize="'small'"
              @input="changePetAccommodation"
            >
              <template #options="{ select }">
                <hr class="m0" />
                <div class="fh-dropdown-option__title">{{ trans('ticketSelection.pets.travelOptionsDropdown.title') }}</div>
                <FhDropdownOption
                  v-for="(accommodation, accommodationIndex) in petAccommodations"
                  :key="`pets-accom-dropdown-${accommodationIndex}`"
                  :text="accommodation.petAccommodationDescription"
                  :index="accommodationIndex"
                  @click="select(accommodation, accommodationIndex)"
                />
              </template>
            </FhDropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PetAccomodationSelect from '@/components/seats/selectors/PetAccomodationSelect';
import { hasSelectedPetCabin } from '@/logic/pipes/hasSelectedPetCabin';
import { PET_POLICIES } from '@/logic/repositories/PetsRepository';
import { trans, petDescription, passDescription } from '@/filters';
import { eventPetCabinSelected } from '@/logic/services/events/createSeatingEvents';
import { mapActions } from 'vuex';

export default {
  name: 'PetSeatingComponent',
  components: {
    PetAccomodationSelect,
  },
  props: {
    trip: Object,
    tripIndex: Number,
    pet: Object,
    petTypes: Array,
    petAccommodations: Array,
    isOccupied: Boolean,
    passengers: Array,
  },
  created() {
    if (this.tripHasPassengerWithPetCabin) this.changePetAccommodation(this.petAccommodations.find((acc) => acc.isPetInCabin));
  },
  watch: {
    passengersList: {
      handler(newList) {
        // Default to first passenger when the currently defined owner is removed
        const ownerExists = newList.some((p) => p.passengerIndex === this.pet.ownerIndex);
        if (!ownerExists) this.changePetOwner(newList[0]);
      },
      deep: true,
    },
    tripHasPassengerWithPetCabin() {
      let petAccommodation;
      if (this.tripHasPassengerWithPetCabin) petAccommodation = this.petAccommodations.find((acc) => acc.isPetInCabin);
      else petAccommodation = this.petAccommodations.find((acc) => acc.isDefault);
      this.changePetAccommodation(petAccommodation);
    },
  },
  methods: {
    ...mapActions({
      changePetTypeState: 'ticketConfigurationModule/changePetType',
      changePetAccommodationState: 'ticketConfigurationModule/changePetAccommodation',
      changePetOwnerState: 'ticketConfigurationModule/changePetOwner',
    }),
    trans,
    petDescription,
    passDescription,
    changePetAccommodation(selectedAccommodation) {
      this.changePetAccommodationState({ selectedAccommodation, pet: this.pet, changedTrip: this.trip });
      if (selectedAccommodation.isPetInCabin) eventPetCabinSelected(this.trip);
    },
    changePetType(type) {
      this.changePetTypeState({ changedTrip: this.trip, pet: this.pet, selectedPetTypeCode: type.petTypeCode });
    },
    changePetOwner(selectedPassenger) {
      this.changePetOwnerState({ selectedPassenger, pet: this.pet, changedTrip: this.trip });
    },
  },
  computed: {
    tripHasPassengerWithPetCabin() {
      return hasSelectedPetCabin(this.trip.passengers, this.trip);
    },
    isPetPolicyOffline() {
      return this.trip.petPolicy.type === PET_POLICIES.OFFLINE;
    },
    isPetPolicyNoTicket() {
      return this.trip.petPolicy.type === PET_POLICIES.NOTICKET;
    },
    isPetPolicyNotAllowed() {
      return this.trip.petPolicy.type === PET_POLICIES.NOTALLOWED;
    },
    passengersList() {
      return this.passengers.map((p) => ({
        passengerIndex: p.passengerIndex,
        passengerDescription: passDescription(p),
      }));
    },
  },
};
</script>

<style module lang="scss">
@import 'pet-seating-component.scss';
</style>
