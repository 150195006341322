<template>
  <div class="ticket-selection mb16">
    <label class="ticket-selection__label"> {{ vehDescription(vehicle) }} </label>
    <div class="ticket-selection__grid">
      <div class="ticket-selection__grid-item">
        <FhDropdown
          :disabled="isOccupied"
          :optionKeyToTrack="'categoryCode'"
          :optionKeyToShow="'categoryDescrition'"
          :optionExtraKeyToShow="'extraDetails'"
          :options="vehicleOptionsForDropdown"
          :value="vehicle.TypeCategory"
          :id="`vehiclesDropdown-${tripIndex}-${vehicle.vehicleIndex}`"
          :textSize="'small'"
          @input="changeVehicleType"
        >
          <template #options="{ select }">
            <FhDropdownVehicleOption
              v-for="(vehicle, vehicleIndex) in vehicleOptionsForDropdown"
              :key="`vehicle-dropdown-${vehicleIndex}`"
              class="pt5"
              :option="vehicle"
              :index="vehicleIndex"
              @click="select(vehicle, vehicleIndex)"
            />
          </template>
        </FhDropdown>
      </div>
      <div class="ticket-selection__grid-item">
        <FhDropdown
          :value="vehicle.driverIndex"
          :valueType="'Number'"
          :disabled="false"
          :optionKeyToTrack="'passengerIndex'"
          :optionKeyToShow="'passengerDescription'"
          :options="passengersList"
          :id="`vehicleAssignedPassengerDropdown-${tripIndex}-${vehicle.vehicleIndex}`"
          :textSize="'small'"
          @input="changeVehicleDriver"
        >
          <template #options="{ select }">
            <hr class="m0" />
            <div class="fh-dropdown-option__title">{{ trans('ticketSelection.vehicles.passengerDropdown.title') }}</div>
            <FhDropdownOption
              v-for="(passenger, passengerIndex) in passengersList"
              :key="`passenger-dropdown-${passengerIndex}`"
              :text="passenger.passengerDescription"
              :index="passengerIndex"
              @click="select(passenger, passengerIndex)"
            />
          </template>
        </FhDropdown>
      </div>
    </div>
    <div class="mt10">
      <CampingOnBoardBox :trip="trip" :vehicle="vehicle" :selectedVehicleOption="selectedVehicleOption" :isDisabled="isOccupied" />
    </div>
  </div>
</template>

<script>
import FhDropdownVehicleOption from '@/components/shared/FhDropdown/FhDropdownVehicleOption';
import VehicleIcon from '@/components/seats/VehicleIcon';
import CampingOnBoardBox from '@/components/seats/selectors/CampingOnBoardBox';
import { cloneDeep as _cloneDeep } from 'lodash-es';
import { message } from '@/logic/helpers/utils';
import { trans, vehDescription, passDescription } from '@/filters';
import { changeDriverOfVehicle } from '@/logic/services/pricing/createVehicleForSeating';
import { mapActions } from 'vuex';

export default {
  name: 'VehicleSeatingComponent',
  components: {
    VehicleIcon,
    CampingOnBoardBox,
    FhDropdownVehicleOption,
  },
  props: ['trip', 'vehicle', 'vehicleOptions', 'isOccupied', 'passengers', 'tripIndex'],
  watch: {
    passengersList: {
      handler(newList) {
        // Default to first passenger when the currently defined owner is removed
        const driverExists = newList.some((p) => p.passengerIndex === this.vehicle.driverIndex);
        if (!driverExists) this.changeVehicleDriver(newList[0]);
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      changeVehicleTypeInStore: 'ticketConfigurationModule/changeVehicleTypeInStore',
      changeVehicleDriverInStore: 'ticketConfigurationModule/changeVehicleDriverInStore',
    }),
    trans,
    vehDescription,
    passDescription,
    changeVehicleType(type) {
      this.changeVehicleTypeInStore({ selectedVehicleType: type.categoryCode, vehicle: this.vehicle });
    },
    changeVehicleDriver(selectedPassenger) {
      const updatedVehicle = changeDriverOfVehicle(this.vehicle, selectedPassenger);
      this.changeVehicleDriverInStore({ updatedVehicle, tripId: this.trip.id });
    },
  },
  computed: {
    isMotorhome() {
      return this.vehicle.isMotorhome;
    },
    selectedVehicleOption() {
      return this.trip.vehicleAccommodations.find((o) => o.categoryCode === this.vehicle.TypeCategory);
    },
    vehicleOptionsForDropdown() {
      let options = _cloneDeep(this.vehicleOptions);

      let cars = options
        .filter((v) => v.isCar)
        .map((item, index) => {
          return index === 0 ? { ...item, categoryTitle: message('carscategory') } : item;
        });
      let motorbikes = options
        .filter((v) => v.isMotorbike)
        .map((item, index) => {
          return index === 0 ? { ...item, categoryTitle: message('motorbikescategory') } : item;
        });
      let motorhomes = options
        .filter((v) => v.isMotorhome)
        .map((item, index) => {
          return index === 0 ? { ...item, categoryTitle: message('motorhomescategory') } : item;
        });
      let bicycles = options
        .filter((v) => v.isBicycle)
        .map((item, index) => {
          return index === 0 ? { ...item, categoryTitle: message('bicyclescategory') } : item;
        });

      return [...cars, ...motorbikes, ...motorhomes, ...bicycles];
    },
    passengersList() {
      return this.passengers.map((p) => ({
        passengerIndex: p.passengerIndex,
        passengerDescription: passDescription(p),
      }));
    },
  },
};
</script>
