import { message } from '@/logic/helpers/utils';

export const addLabelsToNationalityOptions = (countriesList) => {
  const { allCountries = [], popularCountries = [] } = countriesList;

  for (let index = 0; index < popularCountries.length; index++) {
    if (index === 0) popularCountries[index].categoryTitle = message('frequentSelectionsLabel');
  }

  for (let index = 0; index < allCountries.length; index++) {
    if (index === 0) allCountries[index].categoryTitle = message('allCountriesLabel');
    if (allCountries[index].phoneCode !== null) allCountries[index].phoneCode = `+${allCountries[index].phoneCode}`;
  }

  return popularCountries.concat(allCountries);
}