<template>
  <div class="container booking-flow__tab">
    <!-- passenger section header -->
    <AegeanBookingFlowSectionHeader :order="'1'" :title="trans('tabselecttitle')" id="results-tab-top" />
    <div class="row">
      <!-- Main column -->
      <div class="col-xs-12 col-md-8 booking-flow__results-column">
        <div v-if="ready">
          <template v-for="(tripRequest, tripRequestIndex) in tripSearchRequests" :key="`results-section-${tripRequestIndex}-${tripRequest.origin}-${tripRequest.destination}-${tripRequest.date}`">
            <ResultsAppSection
              :origin="tripRequest.origin"
              :destination="tripRequest.destination"
              :date="tripRequest.date"
              :searchIndex="tripRequestIndex"
              @onSearchUpdate="onSearchUpdate"
              @onResultsCollected="onResultsCollected"
            >
            </ResultsAppSection>
          </template>
        </div>
      </div>

      <div class="col-xs-12 col-md-4 booking-flow__side-column">
        <AegeanCart :tripsCount="tripSearchRequests.length"></AegeanCart>
      </div>
    </div>
  </div>
</template>

<script>
import ResultsApp from '@/components/results/ResultsApp';
import AegeanBookingFlowSectionHeader from '@/affiliates/aegeanair/components/shared/BookingFlowSectionHeader';
import ResultsAppSection from '@/affiliates/aegeanair/components/results/ResultsAppSection';
import AegeanCart from '@/affiliates/aegeanair/components/results/cart/AegeanCart';
import { trans } from '@/filters';

export default {
  name: 'AegeanResultsApp',
  extends: ResultsApp,
  components: {
    AegeanBookingFlowSectionHeader,
    ResultsAppSection,
    AegeanCart,
  },
  methods: {
    trans,
  },
};
</script>
