<template>
  <div :class="$style.refund">
    <div :class="$style.refund__main">
      <div :class="$style.refund__text">
        {{ trans('passDet.ticketFlexibility.standard.explanation.text') }}
      </div>
    </div>
  </div>
</template>

<script>
import { trans } from '@/filters';

export default {
  name: 'StandardSupport',
  methods: {
    trans
  }
};
</script>

<style module lang="scss">
@import '../refund/refund.scss';
</style>
