<template>
  <div :class="$style.fhResultsCartItem">
    <div v-for="(segment, index) in segments" :key="index" :class="$style.fhResultsCartItem__wrapper">
      <template v-if="index === 0">
        <div :class="$style.fhResultsCartItem__journey">
          <JourneyTitle :ports="[departureLocation, arrivalLocation]" :classlist="$style['fhResultsCartItem__trip-stations']" :cropText="false" />
          <i :class="['fh fh-close', $style.fhResultsCartItem__close]" aria-hidden="true" @click="onTripDeselected()" data-test="cartTripDeselect"></i>
        </div>
        <div :class="$style.fhResultsCartItem__timings">
          <span :class="$style.fhResultsCartItem__timing">{{ segment.timings.DepDateLocal }}, {{ segment.timings.DepTime }}</span>
          <i v-if="segment.timings.overnight || trip.overnight" class="fh fh-moon ml5"></i>
        </div>
      </template>
      <VesselCard :companyIconUrl="segment.Details.CompanyIcon" :vesselName="segment.Details.VesselName" :companyName="segment.Details.CompanyName" />
    </div>
    <Notification v-if="showLabel" :warning="false" :hasIcon="false" :success="true" :class="'mb8'" :message="labelText" :hasRadius="true" />
  </div>
</template>

<script>
export default {
  name: 'FhResultsCartItem',
  props: {
    trip: Object,
    label: Object,
    showLabel: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onTripDeselected'],
  computed: {
    isIndirect() {
      return this.trip && this.trip.isDirectTrip === false;
    },
    segments() {
      if (this.isIndirect === false) return [this.trip];
      return this.trip.segments;
    },
    departureLocation() {
      return this.segments[0].Details.DepStationName;
    },
    arrivalLocation() {
      return this.segments[this.segments.length - 1].Details.ArrStationName;
    },
    labelText() {
      if (!this.label) return '';
      return this.label.description;
    },
  },
  methods: {
    onTripDeselected() {
      this.$emit('onTripDeselected');
    },
  },
};
</script>

<style module lang="scss">
@import './fh-results-cart-item.scss';
</style>
