/**
 *
 * @param { (...args) => any } callback A callback to be executed later
 * @param { Number } delay The delay after which the callback shall be executed.
 * @returns { (...args: any[]) => Promise } The debounced callback..
 *
 * Deploy some closure magic to call a function only after a certain timespan has elapsed without calling it again.
 *
 * When invoked, the debounced function immediately cancels the timeout established by the preceding call, preventing the
 * `callback` from reaching the Callback Queue.
 * Closure allows the returned inner function to have access to timeoutID, clearing and reassigning it.
 */
export const debounce = (callback, delay = 250) => {
  let timeoutID;

  return (...args) => {
    clearTimeout(timeoutID);
    timeoutID = setTimeout(() => callback(...args), delay);
  };
};


// Debounce function for user inputs
export const debouncedShort = debounce(fn => fn(), 200);
export const debouncedLong = debounce(fn => fn(), 1000);
