import { isJsonPropTrue, isNull } from './../helpers/utils';

export const isTripCompatiblePetOption = ({ petTravelPolicy }, tripPetPolicy) => {
  return petTravelPolicy === tripPetPolicy.type;
}

export const isCabinCompatiblePetOption = ({ isPetInCabin }, isTripWithPetCabins) => {
  return isPetInCabin ? isTripWithPetCabins : true
}

/**
 * Convert and validate pet types that we receive from the API into usable structures
 * @param {any} petType
 */
export const adaptPetType = (petType) => {
  const isInvalid = isNull(petType) || isNull(petType.petTypeCode);
  if (isInvalid) return { isInvalid: true };

  const {
    isDefaultPet,
    maxWeight,
    minWeight,
    petTypeCode,
    petTypeDescription,
    petTravelPolicy
  } = petType;

  return {
    isDefaultPet: isJsonPropTrue(isDefaultPet),
    maxWeight,
    minWeight,
    petTypeCode,
    petTypeDescription,
    petTravelPolicy,
    isInvalid
  };
}

export const adaptPetAccommodation = (petAccommodation) => {
  
  const {
    abbr,
    companyAbbr,
    isDefault,
    petTravelPolicy,
    petAccommodationCode,
    description
  } = petAccommodation;

  return {
    abbr,
    companyAbbr,
    isDefault: isJsonPropTrue(isDefault),
    petTravelPolicy,
    petAccommodationCode,
    petAccommodationDescription: description
  };
}

/**
 * Convert and validate pet classes that we receive from the API into usable structures
 * @param {any} petClasses
 */
export const adaptPetOption = (petAvailability, petAccommodation) => {
  const isInvalid = isNull(petAvailability) || isNull(petAvailability.abbr) || isNull(petAccommodation);
  if (isInvalid) return { isInvalid: true };

  const {
    abbr: availabilityAbbr,
    price,
  } = petAvailability;

  const {
    abbr: accommodationAbbr,
    isDefault,
    petTravelPolicy,
    petAccommodationCode,
    petAccommodationDescription
  } = petAccommodation;

  if (availabilityAbbr !== accommodationAbbr) return { isInvalid: true };

  return {
    abbr: availabilityAbbr,
    price,
    isDefault: isJsonPropTrue(isDefault),
    isPetInCabin: petAccommodationCode === 'PETIC',
    isPetInVehicle: petAccommodationCode === 'PETIV',
    petAccommodationCode,
    petAccommodationDescription,
    petTravelPolicy,
    isInvalid
  };
}