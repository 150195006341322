import { orderBy as _orderBy, groupBy as _groupBy, keys as _keys, cloneDeep as _cloneDeep } from 'lodash-es';

export const getCategorizedSeats = seats => {
  // group resulting array of seat options based on category
  let categorizedSeats = _groupBy(seats, seat => toCategoryAccommodationCode(seat));

  let finalArray = [];

  for (const [categoryCode, accommodations] of Object.entries(categorizedSeats)) {
    
    let sortedByPrice = _cloneDeep(_orderBy(accommodations, o => parseInt(o.price)));
    
    if (sortedByPrice.length === 0) return;

    sortedByPrice[0].categoryTitle = sortedByPrice[0].Category;
    finalArray = [...finalArray, ...sortedByPrice];
  }

  return finalArray;
};

const toCategoryAccommodationCode = (accommodation) => {
  return parseInt(`${accommodation.categoryOrder}${accommodation.categoryCode}`);
}
