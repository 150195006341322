<template>
  <div class="location-selection-list scrollbar-not-visible">
    <div
      v-for="location in locations"
      :key="'location-item-' + location.LocationAbbr"
      class="location-selection-list__item"
      :class="{ 'location-selection-list__item--not-connected': !location.isConnectionPossible }"
      @click="onListItemClick(location)"
    >
      {{ location.alias }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AegeanLocationSearchList',
  props: {
    locations: Array
  },
  emits: ['onListItemClick'],
  methods: {
    onListItemClick(location) {
      this.$emit('onListItemClick', location);
    }
  }
};
</script>
