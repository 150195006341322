import { ga4Event } from './EventService';
import { TRIP_UNAVAILABLE_REASONS } from '../../models/trips/tripUnavailableReasons';
import { JOURNEY_MODE } from '@/logic/models/trips/journeyModes';
import { tripToTripInfo, extendedTripToTripInfo, tripDetailsModelToTripInfo } from './sharedAdapters';
import { portFullName } from '@/filters';

export const eventUnavailableTrip = (trip) => {
  ga4Event('unavailable_trip_shown', {
    error_description: unavailableAction(trip.unavailableReasonCode),
    ...tripToTripInfo(trip),
  });
};

export const eventResultsFinished = (payload) => {
  ga4Event('results_call_finished', payload);
};

export const eventOpenTripModal = (trip) => {
  ga4Event('modal_opened', {
    modal_name: 'Results:trip_info',
    trip_type: getTripType(trip),
    ...tripToTripInfo(trip),
  });
};

export const eventTripSelectedFromModal = (trip) => {
  ga4Event('details_modal_trip_selected', {
    trip_type: getTripType(trip),
    ...tripToTripInfo(trip),
  });
};

export const eventChangeDate = (date, origin, destination, direction) => {
  ga4Event('date_changed_through_arrows', {
    user_action: `${direction} day arrow selected`,
    ...getRouteInfo(date, origin, destination),
  });
};

export const eventGetIndirectsClicked = (date, origin, destination, resultScenario) => {
  ga4Event('search_for_indirects_through_CTA', {
    result_scenario: resultScenario,
    ...getRouteInfo(date, origin, destination),
  });
};

export const eventErrorUnknownVessel = (trip) => {
  ga4Event('vessel_error_shown', {
    error_description: 'vessel_unknown',
    ...tripDetailsModelToTripInfo(trip),
  });
};

export const eventErrorWrongVesselDetails = (trip) => {
  ga4Event('vessel_error_shown', {
    error_description: 'vessel_details_wrong',
    ...extendedTripToTripInfo(trip),
  });
};

export const eventErrorMissingVesselDetails = (trip) => {
  ga4Event('vessel_error_shown', {
    error_description: 'vessel_details_missing',
    ...extendedTripToTripInfo(trip),
  });
};

export const eventResultsErrorShown = (date, origin, destination, resultScenario, errorDescription) => {
  ga4Event('results_error_shown', {
    result_scenario: resultScenario,
    ...getRouteInfo(date, origin, destination),
    error_description: errorDescription,
  });
};

export const eventAltPortSelected = (date, origin, destination, resultScenario, portCode, portName) => {
  ga4Event('altPort_selected', {
    result_scenario: resultScenario,
    ...getRouteInfo(date, origin, destination),
    port_code: portCode,
    port_name: portName,
  });
};

export const eventAltDateSelected = (date, origin, destination, resultScenario, userAction) => {
  ga4Event('altDate_selected', {
    result_scenario: resultScenario,
    ...getRouteInfo(date, origin, destination),
    user_action: userAction,
  });
};

export const eventAutoselection = (trip) => {
  ga4Event('trip_auto_selected', { ...tripToTripInfo(trip) });
};

export const eventDeselectedAutoselection = (trip) => {
  ga4Event('deselect_auto_selected_trip', { ...tripToTripInfo(trip) });
};

const getTripType = (trip) => {
  if (trip.isDirectTrip) return 'direct';
  if (trip.indirectTripType === JOURNEY_MODE.MULTISEGMENT) return 'conjunction';
  return 'indirect';
};

const unavailableAction = (code) => {
  switch (code) {
    case TRIP_UNAVAILABLE_REASONS.UNAVAILABLE_FROM_SOURCE:
    case TRIP_UNAVAILABLE_REASONS.DEFAULT_ACCOMMODATION_MISSING:
      return 'Availability';
    case TRIP_UNAVAILABLE_REASONS.DEFAULT_DISCOUNT_MISSING:
      return 'Default discount missing';
    case TRIP_UNAVAILABLE_REASONS.COMPANY_UNSUPPORTED:
      return 'Unsupported';
    case TRIP_UNAVAILABLE_REASONS.DATE_RESTRICTION:
      return 'Date Restricted';
    case TRIP_UNAVAILABLE_REASONS.TIME_LIMIT:
      return 'Time Limit';
    case TRIP_UNAVAILABLE_REASONS.CONNECTION_WAIT_TIME:
      return 'Connection wait time';
    case TRIP_UNAVAILABLE_REASONS.INACTIVE_VESSEL:
      return 'Inactive vessel';
    default:
      return `Unknown reason ${code}`;
  }
};

const getRouteInfo = (date, origin, destination) => {
  const departureDate = date ? date.format('YYYYMMDD') : '';

  return {
    departure_port_name: portFullName(origin),
    destination_port_name: portFullName(destination),
    departure_port_code: origin,
    destination_port_code: destination,
    departure_date: departureDate,
    route_id: `${origin}_${destination}_${departureDate}`,
  };
};
