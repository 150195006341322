import { getPageLanguage } from '@/logic/helpers/utils';
import { last as _last } from 'lodash-es';

const URL_ENCODED_COMMA = '%2C';

/**
 * This function is responsible for generating search urls for widgets etc
 * @constructor
 */
function SearchUrlGenerator() {
  return this;
}

SearchUrlGenerator.prototype = {
  //----------------------------------------------------------------------------
  // create a url to fill the input searchboxes
  createPrefillUrl: function(ports) {
    let portsString = ports.join(',');
    return `/${getPageLanguage()}/?initial=${portsString}`;
  },
  //----------------------------------------------------------------------------
  // create a url to force an automatic search
  createAutoSearchUrl(ports, dates, passengersCount, vehiclesCount, petsCount) {
    if (typeof passengersCount === 'undefined') {
      passengersCount = 1;
    }

    if (typeof vehiclesCount === 'undefined') {
      vehiclesCount = 0;
    }

    if (typeof petsCount === 'undefined') {
      petsCount = 0;
    }

    let portsString = ports.join(URL_ENCODED_COMMA);
    let datesString = dates.join(URL_ENCODED_COMMA);

    return `/${getPageLanguage()}/?itinerary=${portsString}&dates=${datesString}&passengers=${passengersCount}&vehicles=${vehiclesCount}&pets=${petsCount}`;
  },
  createUrlFromSearches: function(searches) {
    // all trip dates in an array
    let dates = searches.map(t => t.date);
    // all trip origins plus the final destination in an array
    let ports = searches.map(t => t.origin);
    ports.push(_last(searches).destination);
    return `?itinerary=${ports.join(URL_ENCODED_COMMA)}&dates=${dates.join(URL_ENCODED_COMMA)}`;
  }
};

export default SearchUrlGenerator;
