<template>
  <div class="results-app__status">
    <div class="results-app__status__text pb20">{{ trans('noDirectsOrIndirectsOrSuggestions') }}</div>
    <div><img :src="fhImage('assets/results/AgeanNoRoutesFerryIcon.svg')" /></div>
  </div>
</template>

<script>
import { trans, fhImage } from '@/filters';

export default {
  name: 'ResultsAppNoResults',
  methods: {
    trans,
    fhImage,
  },
};
</script>
