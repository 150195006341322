<template>
  <div class="booking-flow-box--grey-bg">
    <h4 class="booking-flow-box__header">{{ trans('passDet.refProt.title') }}</h4>
    <span class="booking-flow-box__subtitle mb24"><i v-if="!isMobile" class="fh fh-info mr8 mt3" />{{ trans('passDet.refProt.subtitle') }}</span>
    <div :class="$style.refund__cards">
      <SelectableCard
        :title="trans('passDet.refProt.standard.title')"
        :amount="currencyStringPre(standardProtectPrice)"
        :footerMsg="trans('generic.noExtraCharge')"
        :imageSrc="standardProtectImageUrl"
        :selected="!this.refundProtectSelected"
        @click="setRefundOptionSelected({ ancillaryMode: refundOptions.STANDARD })"
      >
        <StandardRefund />
      </SelectableCard>

      <div v-show="showWaitingState">
        <SelectableCardSkeleton />
      </div>
      <!-- we use v-show to always render the component for the fetch -->
      <div v-show="!showWaitingState">
        <SelectableCard
          v-if="refundProtectProvider === bookingProtectProvider"
          :title="trans('passDet.refProt.extended.title')"
          :amount="currencyStringPre(refundProtectPrice)"
          :footerMsg="trans('generic.inTotal')"
          :imageSrc="refundProtectImageUrl"
          :badgeText="trans('generic.recommended')"
          :selected="this.refundProtectSelected"
          @click="setRefundOptionSelected({ ancillaryMode: refundOptions.EXTENDED, ancillaryOrder, totalAncillaries, ancillaryUIMode: 'multiple' })"
          :isLoading="fetchingForPrices"
          :hasError="hasRefundProtectError"
          :errorMsg="refundProtectError"
        >
          <BookingProtect @fetchCompleted="fetchCompleted" />
        </SelectableCard>

        <SelectableCard
          v-if="refundProtectProvider === interamericanProvider"
          :title="trans('passDet.refProt.extended.title')"
          :amount="currencyStringPre(refundProtectPrice)"
          :footerMsg="trans('generic.inTotal')"
          :imageSrc="refundProtectImageUrl"
          :badgeText="trans('generic.recommended')"
          :selected="this.refundProtectSelected"
          @click="setRefundOptionSelected({ ancillaryMode: refundOptions.EXTENDED, ancillaryOrder, totalAncillaries, ancillaryUIMode: 'multiple' })"
          :isLoading="fetchingForPrices"
          :hasError="hasRefundProtectError"
          :errorMsg="refundProtectError"
        >
          <Interamerican @fetchCompleted="fetchCompleted" />
        </SelectableCard>

        <SelectableCard
          v-if="hasRefundProtectError"
          :title="trans('passDet.refProt.extended.title')"
          :amount="currencyStringPre(standardProtectPrice)"
          :footerMsg="trans('generic.inTotal')"
          :imageSrc="fhImage('assets/cancellation-protection/DisabledCancellationProt.svg')"
          :hasError="hasRefundProtectError"
          :errorMsg="refundProtectError"
        >
        </SelectableCard>
      </div>
    </div>
  </div>
</template>

<script>
import SelectableCard from '@/components/shared/SelectableCard/SelectableCard.vue';
import SelectableCardSkeleton from '@/components/shared/SelectableCard/SelectableCardSkeleton.vue';
import StandardRefund from '@/components/book/refund/StandardRefund';
import BookingProtect from '@/components/book/refund/BookingProtect';
import Interamerican from '@/components/book/refund/Interamerican';
import { mapActions, mapState, mapGetters } from 'vuex';
import { EXTRA_SERVICE_OPTIONS, EXTRA_SERVICE_PROVIDERS } from '@/logic/BL/extraServices';
import { trans, currencyStringPre, fhImage } from '@/filters';
import { eventRefundProtectShown } from '@/logic/services/events/createBookingEvents';

export default {
  name: 'RefundOptionsComponent',
  components: {
    SelectableCard,
    SelectableCardSkeleton,
    StandardRefund,
    BookingProtect,
    Interamerican,
  },
  props: {
    ancillaryOrder: {
      type: Number,
    },
    totalAncillaries: {
      type: Number,
    },
  },
  data: () => ({
    interamericanProvider: EXTRA_SERVICE_PROVIDERS.INTERAMERICAN,
    bookingProtectProvider: EXTRA_SERVICE_PROVIDERS.BOOKINGPROTECT,
    refundOptions: EXTRA_SERVICE_OPTIONS,
    standardProtectPrice: 0,
    isFetchCompleted: false,
  }),
  methods: {
    trans,
    currencyStringPre,
    fhImage,
    ...mapActions({
      setRefundOptionSelected: 'bookingModule/setRefundOptionSelected',
    }),
    fetchCompleted(value) {
      this.isFetchCompleted = value;
      if (value) eventRefundProtectShown(this.refundProtectProvider, this.ancillaryOrder, this.totalAncillaries);
    },
  },
  computed: {
    ...mapState({
      fetchingForPrices: (state) => state.uiModule.fetchingForPrices,
      refundProtectPrice: (state) => state.bookingModule.refundProtect.price,
      refundProtectError: (state) => state.bookingModule.refundProtect.error,
    }),
    ...mapGetters({
      refundProtectProvider: 'bookingModule/getRefundProtectProvider',
      refundProtectSelected: 'bookingModule/refundProtectSelected',
      hasRefundProtectError: 'bookingModule/hasRefundProtectError',
    }),
    showWaitingState() {
      return !this.isFetchCompleted && !this.hasRefundProtectError;
    },
    standardProtectImageUrl() {
      return fhImage('assets/cancellation-protection/HasNoCancellationProt.svg');
    },
    refundProtectImageUrl() {
      return this.hasRefundProtectError ? fhImage('assets/cancellation-protection/DisabledCancellationProt.svg') : fhImage('assets/cancellation-protection/HasCancellationProt.svg');
    },
  },
};
</script>

<style module lang="scss">
@import './refund.scss';
</style>
