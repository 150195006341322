<template>
  <div class="vessel-with-icon">
    <img class="vessel-with-icon__logo mr5" :src="icon" :alt="company" />
    <div class="vessel-with-icon__name">{{ vessel }}</div>
  </div>
</template>
<script>
export default {
  name: 'BookingSummaryVessel',
  props: ['company', 'vessel', 'icon'],
};
</script>
