import { nextTick } from 'vue';
import emitter from '@/emitter';
import { addClassToElement, removeClassFromElement, getElement, nodesExist } from './dom';
import { isNull } from '@/logic/helpers/utils';

const optionClass = 'fh-dropdown-option';

export default {
  data() {
    return {
      activeOptionIndex: 0,
    };
  },
  created() {
    this.checkForDropdownValue();
    emitter.$on('onItineraryDeselection', this.resetActiveOption);
    emitter.$on('onSearchPerform', this.resetActiveOption);
  },
  updated() {
    this.checkForDropdownValue();
  },
  unmounted() {
    emitter.$off('onItineraryDeselection', this.resetActiveOption);
    emitter.$off('onSearchPerform', this.resetActiveOption);
    this.removeEventListeners();
  },
  computed: {
    activeOptionClass() {
      return `${optionClass}--active`;
    },
  },
  methods: {
    checkForDropdownValue() {
      if (this.valueType === 'String' && this.value === '') this.activeOptionIndex = null;
      if (this.valueType === 'Number' && isNull(this.value)) this.activeOptionIndex = null;

      this.options.forEach((option, index) => {
        if (option[this.optionKeyToTrack] === this.value) this.setSelectedOptionIndex(index);
        else removeClassFromElement(this.optionByIndex(index), this.activeOptionClass);
      });
    },
    async handleDropdownToggle() {
      await nextTick();

      if (!nodesExist(`.${optionClass}-0`)) return;
      // If a selected option exists, scroll to that option
      if (this.activeOptionIndex !== 0 && this.activeOptionIndex !== null) {
        addClassToElement(this.optionByIndex(this.activeOptionIndex), this.activeOptionClass);
        this.scrollDropdownOptionsWrapper(this.activeOptionTop() - this.offsetDistance());
      }

      if (this.activeOptionIndex === 0) {
        addClassToElement(getElement(`.${optionClass}-0`), this.activeOptionClass);
      }

      document.addEventListener('click', this.handleUserEvent);
      document.addEventListener('keydown', this.handleUserEvent);
      this.checkSpaceForDropdown();
    },
    handleUserEvent(e) {
      if (e.keyCode === 40) {
        e.preventDefault();
        this.onKeyDown();
        return;
      }

      if (e.keyCode === 38) {
        e.preventDefault();
        this.onKeyUp();
        return;
      }

      if (e.keyCode === 13) {
        e.preventDefault();
        this.onKeyEnter();
        return;
      }

      if (e.key === 'Escape') {
        e.preventDefault();
        this.onLoseFocus();
        return;
      }

      // If the click comes from anywhere besides the dropdown search input, it is assumed that the user
      // has either clicked on a dropdown option or on the document, so close the dropdown
      const fhDropdownInput = getElement('.fh-dropdown-search__input');
      if (fhDropdownInput && fhDropdownInput.contains(e.target)) return;

      const dropdown = getElement(`#${this.id}`);
      if (dropdown && dropdown.contains(e.target)) return;      

      this.onLoseFocus();
    },
    onKeyDown() {
      if (!nodesExist(`.${optionClass}`)) return;

      if (this.activeOptionIndex === null) {
        this.activeOptionIndex = -1;
        addClassToElement(this.optionByIndex(0), this.activeOptionClass);
      }

      // If the user scrolls down to the last option, remove the active class from the current option,
      // add it to the next (in this case the first option) and scroll to the top of the dropdown options list.
      if (this.activeOptionIndex === this.computedOptions.length - 1) {
        removeClassFromElement(this.optionByIndex(this.activeOptionIndex), this.activeOptionClass);
        this.resetActiveOption();
        addClassToElement(this.optionByIndex(0), this.activeOptionClass);
        this.dropdownOptionsWrapper().scrollTop = 0;
        return;
      }

      this.incrementActiveOptionIndex();

      // When the down arrow key is pressed, remove the active class from the current option and
      // add it to the next.

      addClassToElement(this.optionByIndex(this.activeOptionIndex), this.activeOptionClass);
      const beforeActiveOption = this.optionByIndex(this.activeOptionIndex - 1);
      if (beforeActiveOption) removeClassFromElement(beforeActiveOption, this.activeOptionClass);

      // If the user has reached beyond highlithed option 5 (zero based counting), start scrolling the
      // dropdown options (5 is arbitrary. Scrolling starts after this option is reached, in order
      // to simulate the "push down" effect). The amount of scrolling is the distance of the highlighted
      // option from the top of the dropdown minus 4 times the option height (4 is arbitrary).

      const optionIsHidden = this.activeOptionTop() + this.activeOption().offsetHeight > this.dropdownOptionsWrapper().offsetHeight;

      if (this.activeOptionIndex > 5 || optionIsHidden) {
        this.scrollDropdownOptionsWrapper(this.activeOptionTop() - this.offsetDistance());
      }
    },
    onKeyUp() {
      if (!nodesExist(`.${optionClass}`)) return;

      if (this.activeOptionIndex === null) {
        this.activeOptionIndex = this.computedOptions.length - 1;
        addClassToElement(this.optionByIndex(this.computedOptions.length - 1), this.activeOptionClass);
        const dropdownOptionsLength = document.querySelectorAll(`.${optionClass}`).length;
        this.dropdownOptionsWrapper().scrollTop = this.activeOption().offsetHeight * dropdownOptionsLength;
        return;
      }

      if (this.activeOptionIndex === 0) {
        removeClassFromElement(this.optionByIndex(0), this.activeOptionClass);
        addClassToElement(this.optionByIndex(this.computedOptions.length - 1), this.activeOptionClass);
        this.activeOptionIndex = this.computedOptions.length - 1;
        const dropdownOptionsLength = document.querySelectorAll(`.${optionClass}`).length;
        this.dropdownOptionsWrapper().scrollTop = this.activeOption().offsetHeight * dropdownOptionsLength;
        return;
      }

      removeClassFromElement(this.optionByIndex(this.activeOptionIndex), this.activeOptionClass);
      const beforeActiveOption = this.optionByIndex(this.activeOptionIndex - 1);
      if (beforeActiveOption) addClassToElement(beforeActiveOption, this.activeOptionClass);

      this.decrementActiveOptionIndex();

      const optionIsHidden = this.activeOptionTop() < 0;

      if (this.activeOptionIndex < this.computedOptions.length - 5 || optionIsHidden) {
        this.scrollDropdownOptionsWrapper(this.activeOptionTop() - this.offsetDistance());
      }
    },
    onKeyEnter() {
      if (this.showInputError) return;
      this.select(this.computedOptions[this.activeOptionIndex]);
      this.setSelectedOptionIndex(this.activeOptionIndex);
      this.removeEventListeners();
    },
    onLoseFocus() {
      this.showDropdown = false;
      this.removeEventListeners();
    },
    removeEventListeners() {
      document.removeEventListener('click', this.handleUserEvent);
      document.removeEventListener('keydown', this.handleUserEvent);
    },
    resetActiveOption() {
      this.activeOptionIndex = 0;
    },
    checkSpaceForDropdown() {
      if (this.dropdownContentUp) {
        addClassToElement(this.dropdownContent(), this.$style[`fhDropdown__content--up`]);
        return;
      }
      const dropdown = getElement(`#${this.id}`);
      if (!dropdown) return;
      const rect = dropdown.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const dropdownDistanceFromBottom = windowHeight - rect.bottom;
      const directionSuffix = dropdownDistanceFromBottom < this.dropdownContent().offsetHeight ? 'up' : 'down';
      addClassToElement(this.dropdownContent(), this.$style[`fhDropdown__content--${directionSuffix}`]);
    },
    activeOption() {
      return getElement(`.${this.activeOptionClass}`);
    },
    optionByIndex(index) {
      return getElement(`.${optionClass}-${index}`);
    },
    dropdownContent() {
      return this.$refs.dropdownContent;
    },
    dropdownOptionsWrapper() {
      return this.$refs.dropdownOptionsWrapper;
    },
    offsetDistance() {
      return 4 * this.activeOption().offsetHeight;
    },
    scrollDropdownOptionsWrapper(distance) {
      this.dropdownOptionsWrapper().scrollTop = this.dropdownOptionsWrapper().scrollTop + distance;
    },
    activeOptionTop() {
      let dropdownOptionsRectTop = this.dropdownOptionsWrapper().getBoundingClientRect().top;
      let activeOptionRectTop = this.activeOption().getBoundingClientRect().top;

      return activeOptionRectTop - dropdownOptionsRectTop;
    },
    incrementActiveOptionIndex() {
      this.activeOptionIndex++;
    },
    decrementActiveOptionIndex() {
      this.activeOptionIndex--;
    },
    async setSelectedOptionIndex(index) {
      await nextTick();
      this.activeOptionIndex = index;
    },
  },
};
