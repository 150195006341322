// @ts-check
import { sortBy as _sortBy, maxBy as _maxBy } from 'lodash-es';
import settings from '@/settings';
import { message } from '@/logic/helpers/utils';
import ClassesRepository from '@/logic/repositories/ClassesRepository';
import { inactiveAccommodation } from '@/logic/adapters/accommodationAdapter';
import { isDeckOrSeatType } from '../../filterers/SeatOptionsFilterer';

/**
 * Create a mock infant seat that will be automatically selected when
 * passenger type changes to infant
 *
 * @param {Accommodation[]} accommodations
 * @param {string} suggestedInfantAbbreviationByCarrier
 * @param {Accommodation} defaultAccommodation
 * @returns {Accommodation}
 */
export const createInfantSeat = (
  accommodations,
  suggestedInfantAbbreviationByCarrier,
  defaultAccommodation
) => {

  // find the seat option suggested by the provider for this itinerary
  // for companies that have an InfantSeatAbbreviation which does not correspond to an
  // existing seat type (eg. ATC does this on the PIR:AEG routes where infant suggestion is an empty string), 
  // use the default accommodation instead
  // this decision is part of our Business Logic and facilitates seat selection for infants
  // This can occur in many ways:
  // 1. Infant suggestion is null or empty string
  // 2. Infant suggestion is an abbreviation that does not exist the available accommodations of a trip
  // 3. Infant suggestion is an abbreviation that has been filtered out during our availability adaptations
  // In these cases, we assign the default trip accommodations to infants, but we modify some of it's properties
  // to match our ui logic
  let infantSeatSuggestion = accommodations.find(s => s.ClassAbbr === suggestedInfantAbbreviationByCarrier) || defaultAccommodation;

  return {
    ...infantSeatSuggestion,
    InfantAbbr: infantSeatSuggestion.ClassAbbr,
    seatType: settings.constants.SEAT,
    AvailWhole: 9,
    seatDescription: message('infantfare'),
    Category: message('infantfare'),
    seatDetails: '',
    categoryOrder: 25,
    ClassAdultBasicPrice: 0,
    overallPrice: 0,
    isInfant: true
  };
};

/**
 * Returns the default seat abbreviation for a list of available abbreviations,
 * following a business logic that excludes infant seats, cabins (if possible) and discounts (if possible)
 * This function is used in order to identify the automatically assigned (default) seat for a given trip
 *
 * @param {Accommodation[]} passengerAccommodations
 * @returns {Accommodation}
 */
export const getDefaultSeat = passengerAccommodations => {
  // filter out infant seats (seats that are specifically used solely for infant passengers)
  let nonInfantOptions = getBookableSeats(passengerAccommodations);

  // filter out cabins and discounts
  let nonCabinOptions = nonInfantOptions
    .filter(o => isDeckOrSeatType(o.seatType))
    .filter(o => o.isDiscount === false);

  // get safe options and sort by ticket price
  let defaultOptions = _sortBy(
    nonCabinOptions.length > 0 ? nonCabinOptions : nonInfantOptions,
    o => o.ClassAdultBasicPrice
  );

  // if "safe" default options are found, find the one with lowest price and maximum availability
  if (defaultOptions.length > 0) {
    // get the lowest price from the first array element (since these are sorted)
    let lowestPrice = defaultOptions[0].ClassAdultBasicPrice;
    let lowestPriceOptions = defaultOptions.filter(
      o => o.ClassAdultBasicPrice === lowestPrice
    );
    let defaultSeat = _maxBy(lowestPriceOptions, 'totalAvailability');
    return defaultSeat;
  }

  // return undefined when default seat cannot be found
  return inactiveAccommodation();
};

/**
 * Returns an array of bookable seat options, filtering out infant classes and zero-price tickets
 * that are non bookable
 *
 * @param {Accommodation[]} passengerAccommodations
 * @returns {Accommodation[]}
 */
export const getBookableSeats = passengerAccommodations => {
  return passengerAccommodations.filter(s => s.price > 0.0).filter(
    s => s.isInfant === false
  );
};

/**
 * Transforms raw itinerary classes array to meaningful objects
 * @param {any[]} options
 * @param {String} companyCode
 */
export const adaptSeatOptions = (options, companyCode) => {

  const seatOptions = options
    .map(o => ClassesRepository.createAccommodation(companyCode, o))
    .filter(o => o.isActive);

  return _sortBy(seatOptions, o => o.price).reverse();
};

/**
 * Creates a model for seat options given raw itinerary data and a company code
 * It returns the complete list of seat options, the default seat option and the infant seat option
 * These two might be undefined
 *
 * @param {any} rawData
 * @param {String} companyCode
 *
 * @returns {{bookableOptions: Accommodation[]; infantOptions?: Accommodation[]; defaultOption: Accommodation; infantOption: Accommodation}}
 */
export const getTripSeatsModel = (rawData, companyCode) => {
  let suggestedInfantAbbreviation = rawData.infants;
  let allSeatOptions = adaptSeatOptions(rawData.classes, companyCode);
  let BookableSeatOptions = getBookableSeats(allSeatOptions);

  // find default is if passengerAccommodations are not an empty array
  let defaultSeat =
    BookableSeatOptions.length > 0
      ? getDefaultSeat(BookableSeatOptions)
      : inactiveAccommodation();

  let infantSeat = defaultSeat.isActive
    ? createInfantSeat(allSeatOptions, suggestedInfantAbbreviation, defaultSeat)
    : inactiveAccommodation();

  // add infant seat to bookable seat options
  if (infantSeat.isActive) {
    BookableSeatOptions.push(infantSeat);
  }

  return {
    bookableOptions: BookableSeatOptions,
    defaultOption: defaultSeat,
    infantOption: infantSeat
  };
};

export const unfilledSeat = {
  type: settings.constants.PASSENGER,
  seatType: '',
  seatDescription: ''
};
