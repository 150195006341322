import { storageGet } from '../storage/Storage';
import { isEmpty } from '@/logic/helpers/utils';

export const createBookingRequest = (
  refundProtection,
  travelInsurance,
  supportPlus,
  flexi,
  expectedPrice,
  expectedPriceBeforeDiscount,
  combinedIssueRequests,
  tripDescriptions,
  contactInfo,
  serviceFeeDetails,
  reservationLeader,
  couponDetails,
  subscribeToNewsletter,
  invoiceDetails,
  user
) => {
  let payload = {
    expectedPrice,
    expectedPriceBeforeDiscount: expectedPriceBeforeDiscount,
    couponDetails: couponDetails,
    combinedIssueRequests: combinedIssueRequests,
    bookingDescription: createCouponEligibleTripDescriptions(tripDescriptions, couponDetails),
    contactInfo: createContactInfo(contactInfo, reservationLeader),
    serviceFeeDetails,
    newsletter: createNewsletterSubscription(contactInfo, reservationLeader, subscribeToNewsletter),
    affiliationDetails: {
      affiliation: window.ferryhopper.affiliate
    }
  };

  if (refundProtection) payload = { ...payload, refundProtection };
  if (travelInsurance) payload = { ...payload, travelInsurance };
  if (supportPlus) payload = { ...payload, supportPlus };
  if (flexi) payload = { ...payload, flexihopper: flexi };
  if (invoiceDetails) payload = { ...payload, invoiceDetails };
  payload = { ...payload, user: createUserForPayload(user) };

  return payload;
};

export const createUserForPayload = user => {
  if (!isEmpty(user)) return { userId: user.id, clientId: null };
  const guestUserId = storageGet('fh.guestUserId', true);
  if (guestUserId) return { userId: null, clientId: guestUserId };
};

export const createCouponEligibleTripDescriptions = (tripDescriptions, couponDetails) => {
  if (!couponDetails || couponDetails.length === 0) {
    return tripDescriptions;
  }
  return tripDescriptions.map((tripDescription, tripDescriptionIndex) => ({
    ...tripDescription,
    couponDetails: couponDetails[tripDescriptionIndex]
  }));
};

export const createContactInfo = (contactInfo, reservationLeader) => {
  return {
    ...contactInfo,
    LeaderName: `${reservationLeader.firstName} ${reservationLeader.lastName}`,
    Phone: `${contactInfo.PhoneCountryCode}${contactInfo.Phone}`
  };
};

export const createNewsletterSubscription = (contactInfo, reservationLeader, subscribeToNewsletter) => ({
  subscribe: subscribeToNewsletter,
  email: contactInfo.Email,
  name: reservationLeader.firstName,
  surname: reservationLeader.lastName,
  gender: reservationLeader.gender
});
