import { ga4Event } from './EventService';

export const eventGoToMyBooking = () => {
  ga4Event('go_to_my_booking_clicked', {});
};

export const eventAddBooking = () => {
  ga4Event('add_booking_clicked', {});
};

export const eventAddBookingSuccess = () => {
  ga4Event('booking_successfully_added', {});
};

export const eventAddBookingError = (bookingCode, status, errorMsg) => {
  ga4Event('add_booking_error_shown', {
    error_code: status,
    FH_code: bookingCode,
    error_description: errorMsg
  });
};

export const eventBookingPricesExpanded = () => {
  ga4Event('booking_price_breakdown_clicked', {});
};

export const eventServerError = (status, label) => {
  ga4Event('error_5xx_received', {
    error_code: status,
    error_description: !status ? 'Offline' : 'Server error',
  });
};

export const eventRemoveTripClicked = () => {
  ga4Event('remove_trip_clicked', {});
};

export const eventRemoveTripSuccess = () => {
  ga4Event('trip_successfully_removed', {});
};

export const eventRemoveTripError = (errorDescription, errorCode, FhCode) => {
  ga4Event('remove_trip_error_shown', {
    error_description: errorDescription,
    error_code: errorCode,
    FH_code: FhCode,
  });
};

export const eventDeleteAccountSuccess = () => {
  ga4Event('account_deleted', {});
};

export const eventDeleteAccountError = (errorCode) => {
  ga4Event('account_deletion_error_shown', {
    error_code: errorCode,
  });
};
