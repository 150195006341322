<template>
  <div>
    <FhDropdown
      :id="id"
      :hasError="hasError"
      :disabled="disabled"
      :optionKeyToTrack="'ClassAbbr'"
      :optionKeyToShow="'seatDescription'"
      :optionExtraKeyToShow="'seatDetails'"
      :commaSeparated="true"
      :options="seatDropdownOptions"
      :value="passenger.selectedAccommodation"
      :textSize="'small'"
      :placeholder="seatsDropdownPlaceholder"
      @input="changePassengerSeat"
      :data-test="'seatsDropdown'"
    >
      <template #options="{ select }">
        <FhDropdownSeatOption v-for="(option, optionIndex) in seatDropdownOptions" :key="`seat-dropdown-${optionIndex}`" :option="option" :index="optionIndex" @click="select(option, optionIndex)" />
      </template>
    </FhDropdown>
    <span class="text-error" v-show="hasError">{{ trans('generic.error.requiredField') }}</span>
  </div>
</template>

<script>
import FhDropdownSeatOption from '@/components/shared/FhDropdown/FhDropdownSeatOption';
import { message } from '@/logic/helpers/utils';
import { trans } from '@/filters';
import emitter from '@/emitter';

export default {
  name: 'PassengerSeatInput',
  components: {
    FhDropdownSeatOption,
  },
  data() {
    return {
      hasError: false,
    };
  },
  props: {
    id: String,
    disabled: Boolean,
    seatDropdownOptions: Array,
    passenger: Object,
  },
  emits: ['changePassengerSeat'],
  created() {
    emitter.$on('checkForUnfilledSeats', () => {
      if (this.passenger.selectedAccommodation === '') this.hasError = true;
    });
  },
  methods: {
    trans,
    changePassengerSeat(seat) {
      this.$emit('changePassengerSeat', seat);
    },
  },
  computed: {
    seatsDropdownPlaceholder() {
      return message('ticketSelection.seatPlaceholder.selectSeatCabin');
    },
  },
  watch: {
    passenger: {
      deep: true,
      handler() {
        if (this.passenger.selectedAccommodation !== '') this.hasError = false;
      },
    },
  },
};
</script>
