<template>
  <div class="ticket-collection__eticket">
    <i class="fh fh-eTicket mt5 mr10"></i>
    <div>{{ trans('collectonlyetickets') }}</div>
  </div>
</template>

<script>
import { trans } from '@/filters';

export default {
  name: 'ETicketDescriptionComponent',
  methods: {
    trans
  }
};
</script>
