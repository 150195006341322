<template>
  <div :class="[$style.selectableCardSimpleSkeleton]">
    <div :class="[$style.selectableCardSimpleSkeleton__item, $style.selectableCardSimpleSkeleton__footer, 'skeleton-animation']"></div>
  </div>
</template>

<script>
export default {
  name: 'SelectableCardSimpleSkeleton'
};
</script>

<style module lang="scss">
@import './selectable-card-simple-skeleton.scss';
</style>
