import settings from '@/settings';

export const selectGenderForSingleSeat = passengers => {
  const passengerGenders = passengers.reduce(
    (acc, passenger) => ({
      males:
        passenger.gender === settings.constants.GENDERS.MALE
          ? acc.males + 1
          : acc.males,
      females:
        passenger.gender === settings.constants.GENDERS.FEMALE
          ? acc.females + 1
          : acc.females
    }),
    { males: 0, females: 0 }
  );

  const probableGender =
    passengerGenders.males >= passengerGenders.females
      ? settings.constants.GENDERS.MALE
      : settings.constants.GENDERS.FEMALE;
  return probableGender;
};
