/**
 * @typedef {Object} TripWithCoupon
 * @property {string} id
 * @property {string} DepDate
 * @property {string} DepStation
 * @property {string} ArrStation
 * @property {string} DepCountry
 * @property {string} ArrCountry
 * @property {string} TripPrice
 * @property {string} CouponCode
 * @property {string} PriceWithDiscount
 * @property {string} Discount
 */

import { dayjsToEnforcedUtcISO86 } from "../../helpers/dateUtils";

/**
 * @typedef CouponValidation
 * @property {string} status 
 * @property {string} msg 
 */

export const createSimpleTrip = (trip) => ({
    id: trip.id,
    DepDate: trip.timings.DepDate,
    departureDateTime: dayjsToEnforcedUtcISO86(trip.timings.DepartureDateTime),
    arrivalDateTime: dayjsToEnforcedUtcISO86(trip.timings.ArrivalDateTime),
    DepStation: trip.Details.DepStation,
    ArrStation: trip.Details.ArrStation,
    Company: trip.Details.Company,
    Vessel: trip.Details.VesselID,
    DepCountry: trip.Details.DepCountry,
    ArrCountry: trip.Details.ArrCountry,
    passengersCount: trip.passengers ? trip.passengers.length : 0,
    vehiclesCount: trip.vehicles ? trip.vehicles.length: 0,
    petsCount: trip.pets ? trip.pets.length : 0
});

/**
 * Create a trip with coupon details
 * @param {Object} trip 
 * @param {string} couponCode
 *  
 * @returns {TripWithCoupon}
 */
export const createTripWithCoupon = (trip, couponCode) => {
    const hasEarlyBooking = isTripWithEarlyBooking(trip);
    return {
        ...createSimpleTrip(trip),
        TripPrice: trip.OverallPrice.toString(),
        ticketsPriceInCents: trip.totalTicketsPriceInCents,
        passengerTickets: trip.passengers.map(({passengerIndex, ticketPriceInCents, companySpecificAccommodation}) => ({
            passengerIndex,
            ticketPriceInCents,
            isRefundable: (companySpecificAccommodation.isRefundable && !hasEarlyBooking)
        })),
        vehicleTickets: trip.vehicles.map(({vehicleIndex, ticketPriceInCents}) => ({
            vehicleIndex,
            ticketPriceInCents,
            isRefundable: !hasEarlyBooking
        })),
        petTickets: trip.pets.map(({petIndex, ticketPriceInCents}) => ({
            petIndex,
            ticketPriceInCents,
            isRefundable: !hasEarlyBooking
        })),
        CouponCode: couponCode,
        PriceWithDiscount: trip.OverallPrice.toString(),
        Discount: '0'
    }
};


/**
 * 
 * @param {TripWithCoupon[]} tripsWithCoupons 
 * @param {string} couponCode 
 * @returns {TripWithCoupon[]}
 */
 export const applyCouponToTrips = (tripsWithCoupons, couponCode) => {
    return tripsWithCoupons.map(trip => ({
        ...trip,
        CouponCode: couponCode,
        Discount: '0',
        PriceWithDiscount: trip.TripPrice
    }));
}

/**
 * 
 * @param {TripWithCoupon} trip 
 * @param {TripWithCoupon} tripWithAppliedCoupon 
 * @returns {TripWithCoupon}
 */
export const updateTripWithCoupon = (trip, tripWithAppliedCoupon) => ({
    ...trip,
    PriceWithDiscount: tripWithAppliedCoupon.PriceWithDiscount,
    Discount: tripWithAppliedCoupon.Discount
});

/**
 * 
 * @param {string} status 
 * @param {string} msg 
 * @returns {CouponValidation}
 */
export const createCouponValidation = (status, msg) => ({
    status,
    msg: msg || ''
})

const isTripWithEarlyBooking = trip => {
    try {
        return trip.PricingData.PricingRequest.DiscountFlags.wantsEarlyBooking;
    } catch {
        return false;
    }
}
