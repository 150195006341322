<template>
  <div class="has-feedback" :class="{ 'has-error': isError }">
    <input
      :class="['form-control form-control--small', extraClass, { 'input-with-border-radius': hasBorderRadius }]"
      :type="inputType"
      :placeholder="placeholder"
      :value="value"
      :maxlength="maxInputLength"
      :disabled="disabled || isValidating"
      @input="onInputChange"
      @keyup.enter="onInputChange"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    />
    <i class="fh form-control-feedback" :class="iconClass"></i>
  </div>
</template>

<script>
export default {
  name: 'InputWithValidationIndicator',
  props: {
    inputType: {
      type: String,
      default: 'text'
    },
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    isValidating: {
      type: Boolean,
      default: false
    },
    isSuccess: {
      type: Boolean,
      default: false
    },
    isError: {
      type: Boolean,
      default: false
    },
    maxInputLength: {
      type: Number,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hasBorderRadius: {
      type: Boolean,
      default: false
    },
    extraClass: {
      type: String,
      default: ''
    }
  },
  emits: ['input', 'focus', 'blur'],
  methods: {
    onInputChange(event) {
      this.$emit('input', event.target.value);
    }
  },
  computed: {
    iconClass() {
      if (this.isValidating) return 'fh-notch fh-spin';
      if (this.isSuccess && this.globalCobrandedVariant === 'easyferry') return 'fh-check';
      if (this.isSuccess) return 'fh-check text-success';
      if (this.isError) return 'fh-notification';
      return '';
    }
  }
};
</script>
