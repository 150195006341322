<template>
  <span :class="$style.vehicleDetailsSelection">
    <span data-test="vehicleDetailsText">
      <img height="14" width="14" :src="iconUrl" alt="Company icon" class="mr8 mb2" data-test="providerIcon" v-if="showIcon" />
      <span :class="$style.vehicleDetailsSelection__description">{{ description }}</span>
      <span :class="$style.vehicleDetailsSelection__description">,&nbsp;</span>
      <span :class="$style.vehicleDetailsSelection__description">{{ trans('passDet.vehicleDetails.assignedPassenger') }}{{ trans('generic.passenger.capitalised.masculine.one') }} {{ driverIndex + 1 }}</span>
    </span>
  </span>
</template>

<script>
import { trans } from '@/filters';

export default {
  name: 'VehicleDetailsSelection',
  props: {
    showIcon: Boolean,
    iconUrl: String,
    description: String,
    driverIndex: Number
  },
  methods: {
    trans
  }
};
</script>

<style module lang="scss">
@import './vehicle-details-selection.scss';
</style>
